import React, { useState } from 'react'
import { Button, Form, Input, message, Popconfirm, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { post } from 'util/http';
import module from 'util/module';
import { getMetaLabel } from 'util/meta';

import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import DictSelect from "component/DictSelect";


const Page = () => {
    const [param, setParam] = useState({
        searchName: ""
    });
    const [formParam, setFormParam] = useState({
        visible: false,
        moduleId: ""
    });
    const [versionParam, setVersionParam] = useState({
        visible: false,
        moduleCode: "-1"
    });

    const [searchForm] = Form.useForm();

    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }}>
                <Form.Item name="type" >
                    <DictSelect dictCode={"MODULE_TYPE"} placeholder="模块类型" style={{ width: 180 }} />
                </Form.Item>
                <Form.Item name="searchName" >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="关键词"
                    />
                </Form.Item>
                <Space>
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>

                </Space>


            </Form>

            <SearchTable url={module.cloud + "/public/module/search"}
                param={param}
                columns={[
                    {
                        title: '类型',
                        dataIndex: 'type',
                        key: 'type',
                        render: (_, record) => {
                            return getMetaLabel('MODULE_TYPE', _);
                        }

                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: 'code',
                        dataIndex: 'code',
                        key: 'code',
                    }, {
                        title: '最新版本',
                        dataIndex: 'latestVersion',
                        key: 'latestVersion',
                    }, {
                        title: '范围',
                        dataIndex: 'scope',
                        key: 'scope',
                        render: (_, record) => {
                            return getMetaLabel('MODULE_SCOPE', _);
                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setVersionParam({
                                        moduleCode: record.code,
                                        visible: true
                                    })
                                }}>文档</a>

                            </Space>
                        )
                    }
                ]} />

        </div>
    );
}

addPage("/public/module", Page);

export default Page;