import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
    Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem, { DateField } from 'component/MetaFormItem';
import RichTextEditor from 'component/RichTextEditor'
import ImageUpload from 'component/ImageUpload'
import DictSelect from 'component/DictSelect'

import SearchTree from 'component/SearchTree';
import TextArea from 'antd/lib/input/TextArea';

const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "cmsArticle"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });


    useEffect(() => {
        post(module.app + '/meta/v1.0/get-by-code', { code: metaCode })
            .then(res => {
                setMeta(res.data);
            })
    }, [])
    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post(module.app + '/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                pageForm.resetFields();
            }
        }


    }, [param])

    return (<Drawer title="创建项目"
        placement="right"
        width={'55%'}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button onClick={() => {
                    setParam({ ...param, visible: false })
                }}>取消</Button>


                <Button type="primary" onClick={() => {
                    pageForm.validateFields().then((data) => {
                        data.typeName = typeName;
                        data.channelCode = "help";
                        data.fullChannelCode = "help";
                        data.channelTitle = "帮助文档";
                        data.parentCode = "-1";
                        data.showOrder = 1;
                        const url = module.app + '/meta/v1.0/data/save';
                        post(url, {
                            metaCode: metaCode,
                            datas: data
                        }).then(res => {
                            setParam({ ...param, visible: false });
                            reload();
                            message.info("保存成功");
                        })
                    })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={pageForm}
            //  layout="vertical"
            hideRequiredMark
            labelCol={{
                style: { width: 120 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>


            <Row gutter={16}>
                <Col span={24}>


                    <Form.Item name='title' label="标题"
                        rules={[{ required: true, message: '请输入标题' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='outsideUrl' label="外部链接"
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item name='content' label="内容">
                        <RichTextEditor />
                    </Form.Item>
                </Col>
            </Row>

        </Form>
    </Drawer>)
}

export default Page;