import React, { useState } from 'react'
import { Button, Form, Input, message, Popconfirm, Space, Modal, Col, Row, } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import { getMetaLabel } from 'util/meta';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import CodeMirrorBox from 'component/CodeMirrorBox';
import LocationDrawer from './location'
const { TextArea } = Input;

const Page = () => {

  const [pageForm] = Form.useForm();

  const [serverParam, setServerParam] = useState({ clusterCode: "-1" });
  const [serverCode, setServerCode] = useState("-1");

  const [pageStatus, setPageStatus] = useState({
    clusterCode: null,
    clusterId: null,
    drawerId: null
  })

  const [formParam, setFormParam] = useState({
    visible: false,
    clusterId: ""
  });

  const [domainParam, setDomainParam] = useState({
    visible: false,
    applicationCode: "-1",
    clusterCode: "-1"
  })


  const [moduleParam, setModuleParam] = useState({
    visible: false,
    clusterCode: "-1"
  });

  const [param, setParam] = useState({
    metaCode: "nginxServerDomain",
    searchName: "", size: 10, current: 1
  });
  const [searchForm] = Form.useForm();

  return (
    <div>
      <Form form={searchForm} layout="inline" style={{ padding: 15 }}>
        <Form.Item
          name="searchName"
        >
          <Input
            style={{ width: '100%' }}
            placeholder="请输入名称"
          />
        </Form.Item>
        <Space>
          <Button onClick={() => {
            setParam(searchForm.getFieldsValue())
          }}>搜索</Button>
          <Button type="primary" onClick={() => {
            setFormParam({ visible: true })
            pageForm.resetFields();
          }}>创建</Button>

        </Space>


      </Form>

      <SearchTable url={"/api/nginx/server/v1.0/domain/search"}
        param={param}
        columns={[
          {
            title: '实例名称',
            dataIndex: 'serverName',
            key: 'serverName',
          }, {
            title: '域名',
            dataIndex: 'domain',
            key: 'domain',
          }, {
            title: '端口',
            dataIndex: 'port',
            key: 'port',
          }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
          }, {
            title: '操作',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
              <Space size="middle">
                <a onClick={() => {
                  setFormParam({ visible: true })
                  pageForm.resetFields();
                  pageForm.setFieldsValue(record);
                  setServerParam({
                    clusterCode: record.clusterCode
                  })
                }}>修改</a>
                <a onClick={() => {
                  setDomainParam({
                    visible: true,
                    serverCode: record.serverCode,
                    domain: record.domain
                  })
                }}>转发配置</a>
                <Popconfirm
                  title="你确认删除此数据么?"
                  onConfirm={() => {
                    post('/api/nginx/server/v1.0/domain/delete',
                      { id: record.id })
                      .then(res => {
                        setParam({ ...param });
                        message.info("删除成功");
                      })
                  }}
                  okText="确认"
                  cancelText="取消">
                  <a href="#">删除</a>
                </Popconfirm>

              </Space>
            )
          }
        ]} />

      <LocationDrawer setParam={setDomainParam} param={domainParam} />


      <Modal width={860} title="添加域名" visible={formParam.visible} onOk={() => {
        const data = pageForm.getFieldsValue();
        const url = data.id ? '/api/nginx/server/v1.0/domain/update'
          : '/api/nginx/server/v1.0/domain/create';
        post(url, data)
          .then(res => {
            message.info("操作成功");
            setFormParam({ ...formParam, visible: false });
            setParam({ ...param });
          })

      }} onCancel={() => { setFormParam({ ...formParam, visible: false }) }}>

        <Form form={pageForm} layout="horizontal" hideRequiredMark
          labelCol={{
            style: { width: 80 }
          }}
          initialValues={{
            items: []
          }}
        >
          <Form.Item style={{ display: 'none' }}
            name="id">
            <Input type="hidden"
            />
          </Form.Item>
          <Form.Item style={{ display: 'none' }}
            name="applicationCode">
            <Input type="hidden"
            />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="clusterCode"
                label="所属集群"
                rules={[{ required: true, message: '请选择集群' }]}
              >
                <SearchSelect url={"/api/nginx/cluster/v1.0/search"} labelCode="name" valueCode="code" onChange={(res) => {
                  setServerParam({
                    clusterCode: res
                  })
                }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="domain"
                label="域名"
                rules={[{ required: true, message: '请输入域名' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="serverCode"
                label="所属实例"
              >
                <SearchSelect url={"/api/nginx/server/v1.0/search"} labelCode="name" valueCode="code" param={serverParam} optionDatas={[{
                  label: "新建实例",
                  value: ""
                }]} onChange={(res) => {
                  setServerCode(res);
                }} />
              </Form.Item>
            </Col>

            <Col span={12}>
              {serverCode == "" && <Form.Item
                name="serverName"
                label="实例名称"
              >
                <Input />
              </Form.Item>}

            </Col>
            <Col span={12}>
              <Form.Item
                name="protocol"
                label="协议"
                rules={[{ required: true, message: '请输入协议' }]}
              >
                <DictSelect dictCode="HTTP_PROTOCOL" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="port"
                label="端口"
                rules={[{ required: true, message: '请输入端口' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="sslCode"
                label="ssl证书"
                rules={[{ required: true, message: '请输入ssl证书' }]}
              >
                <SearchSelect url="/api/app/meta/v1.0/data/search" param={{
                  "metaCode": "nginxSsl"
                }} />
              </Form.Item>
            </Col>


            <Col span={24}>

              <Form.Item
                name="config"
                label="配置"
              >
                <TextArea style={{ height: 200 }} />
              </Form.Item>
            </Col>

          </Row>

        </Form>


      </Modal >
    </div >
  );
}
addPage("/nginx/domain/manager", Page);


export default Page;