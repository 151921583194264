const AuthUtil = {
    isInWeixin: () => {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        } else {
            return false;
        }
    },
    getTokenId: () => {
        //TODO use appId
        const name = "ailawyer.token";
        var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        if ((arr = window.document.cookie.match(reg))) {
            return unescape(arr[2]);
        } else {
            return null;
        }
    },
    hasLogin: () => {
        if (window.LOGIN_INFO == null) {
            return false;
        } else if (window.LOGIN_INFO.code == null || window.LOGIN_INFO.code == '') {
            return false;
        } else {
            return true;
        }
    },
    getLoginUrl: () => {
        if (window.APP_CONFIG.webLoginUrl) {
            return window.APP_CONFIG.webLoginUrl;
        } else {
            return "/login";
        }
    },
    goLogin: (targetUrl) => {
        if (AuthUtil.isInWeixin()) {
            console.log("in weixin")
            if (targetUrl == null) {
                window.location.href = "/api/weixin/auth/v1.0/auth/login?bind=true&bindUrl=/bind"
            } else {
                window.location.href = "/api/weixin/auth/v1.0/auth/login?bind=true&bindUrl=/bind&targetUrl=" + targetUrl;
            }
        } else {
            if (targetUrl == null) {
                window.location.href = AuthUtil.getLoginUrl()
            } else {
                window.location.href = AuthUtil.getLoginUrl() + "?url=" + encodeURIComponent(targetUrl)
            }
        }
    }
}

export default AuthUtil;