import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Image, Switch } from 'antd';
import { post, get } from 'util/http';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import FormModal from './manager-form.js';

import { getMetaLabel } from 'util/meta';
import moment from 'moment'




const Page = () => {

    const metaCode = "cmsTemplate";
    const [param, setParam] = useState({
        metaCode: metaCode,
        params: [{
            attrCode: 'status',
            operator: "=",
            value: "approved"
        }],
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "updateTime",
            order: "desc"
        }, {
            attrCode: "pageCount",
            order: "desc"
        }]
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });
    const [searchForm] = Form.useForm();

    const changeShowOrder = (id, checked) => {
        post('/api/app/meta/v1.0/data/save', {
            metaCode: metaCode,
            datas: {
                id: id,
                showOrder: checked ? 1 : 0
            }
        }).then(res => {
            message.info("操作成功");
            reload();
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.column != null) {
            setParam({
                ...param,
                ...pagination,
                orders: [{
                    attrCode: sorter.column.dataIndex,
                    order: sorter.order == "ascend" ? "asc" : "desc"
                }]
            })
        } else {
            setParam({
                ...param,
                ...pagination,
                orders: [{
                    attrCode: "showOrder",
                    order: "desc"
                }, {
                    attrCode: "updateTime",
                    order: "desc"
                }, {
                    attrCode: "pageCount",
                    order: "desc"
                }]
            })
        }
    }

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'name',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >


                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                        onPressEnter={reload}
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({
                            id: '',
                            title: "新增应用",
                            visible: true
                        })
                    }}>创建</Button>

                </Space>


            </Form>

            <SearchTable url={"/api/app/meta/v1.0/data/search"}
                onChange={handleTableChange}
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                        width: 200,
                        render: (_, record) => {
                            return <a onClick={() => {
                                window.open("/api/cms/public/template/view?code=" + record.code)
                            }}>{_}</a>
                        }
                    },
                    {
                        title: '图片',
                        dataIndex: 'img',
                        key: 'img',
                        width: 200,
                        render: (_, record) => {
                            return (<Image src={_} width={200} />)
                        }
                    },
                    {
                        title: '类型',
                        dataIndex: 'type',
                        key: 'type',
                        width: 120,
                        render: (_, record) => {
                            return getMetaLabel('TEMPLATE_TYPE', _);
                        }
                    }, {
                        title: '标签',
                        dataIndex: 'tags',
                        width: 120,
                        key: 'tags'
                    }, {
                        title: '页面',
                        dataIndex: 'pageCount',
                        key: 'pageCount',
                        width: 80,
                        sorter: (a, b) => a.pageCount - b.pageCount,

                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: 'status',
                        width: 80,
                        render: (_, record) => {
                            return getMetaLabel('TEMPLATE_STATUS', _);
                        }
                    },
                    {
                        title: '更新时间',
                        dataIndex: 'updateTime',
                        key: 'updateTime',
                        width: 200,
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        },
                        sorter: (a, b) => a.updateTime - b.updateTime,
                    }, {
                        title: '置顶',
                        dataIndex: 'showOrder',
                        key: 'showOrder',
                        width: 80,
                        render: (_, record) => {
                            return (<Switch
                                checked={_ == 1}
                                onChange={(checked) => {
                                    changeShowOrder(record.id, checked)
                                }}></Switch>)
                        },
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    post('/api/cms/template/v1.0/unzip', {
                                        code: record.code
                                    }).then(res => {
                                        message.info("发布成功");
                                    })
                                }}>发布</a>
                                <a onClick={() => {
                                    get('/local/edit?code=' + record.code, {
                                    }, {
                                        ignoreParse: true
                                    }).then(res => {
                                        message.info("开始本地编辑");
                                    })
                                }}>本地编辑</a>
                                <a onClick={() => {
                                    get('/local/update?code=' + record.code, {
                                    }, {
                                        ignoreParse: true
                                    }).then(res => {
                                        message.info("开始本地上传");
                                    })
                                }}>本地上传</a>
                                <a onClick={() => {
                                    post('/api/cms/template/v1.0/download', {
                                        code: record.code
                                    }).then(res => {
                                        window.open(res.data);
                                    })
                                }}>下载</a>
                                <a onClick={() => {
                                    setFormParam({
                                        id: record.id,
                                        title: "修改应用",
                                        visible: true
                                    })
                                }}>修改</a>

                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post('/api/app/meta/v1.0/data/delete', {
                                            metaCode: metaCode,
                                            id: record.id
                                        }).then(res => {
                                            setParam({ ...param });
                                            message.info("删除成功");
                                        })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />
            <FormModal param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/cms/template/manager", Page);

export default Page;