import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
    Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem'
import { addPage } from 'component/DynamicRotes';

const Page = ({
}) => {


    const [pageForm] = Form.useForm();

    const submitForm = () => {
        pageForm.validateFields().then((values) => {
            post(module.auth + "/auth/v1.0/password/reset", values)
                .then(res => {
                    message.info("重置成功");
                })
        })
    }

    useEffect(() => {


    }, [])

    return (<>
        <Form form={pageForm}
            layout="horizontal"
            labelCol={{
                style: { width: 80 }
            }}
            style={{
                width: 400,
                padding: 20
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name='password' label="旧密码"  >
                <Input type={"password"} />
            </Form.Item>
            <Form.Item name='newPassword' label="新密码"  >
                <Input type={"password"} />
            </Form.Item>
            <Form.Item name='confirmPassword' label="确认密码"  >
                <Input type={"password"} />
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
                <Button type='primary' onClick={submitForm}>提交</Button>
            </div>

        </Form>
    </>)
}
addPage("/password-reset", Page)
export default Page;