import React, { useEffect, useState } from 'react'
import { Table, Button, Input, Space, Popconfirm, Drawer, Form, Col, Row, Select, message, Breadcrumb } from 'antd';
import EditTable from 'component/EditTable';
import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import ImageUpload from 'component/ImageUpload';
import RichTextEditor from 'component/RichTextEditor'




import { post } from 'util/http';
import module from 'util/module';
const { Option } = Select;
const { TextArea } = Input;

const TemplateForm = ({
    applicationCode,
    drawerId,
    setParam,
    setDrawerId
}) => {

    const [application, setApplication] = useState({});

    const [step, setStep] = useState(1);

    const [templateForm] = Form.useForm();


    useEffect(() => {
        if (applicationCode != null && drawerId == 'templateForm') {
            templateForm.setFieldsValue({
                sourceApplicationCode: applicationCode
            });
        }
    }, [applicationCode, drawerId])

    return (<Drawer title="发布模板"
        placement="right"
        width={1060}
        maskClosable={false}
        onClose={() => { setDrawerId({}) }}
        visible={drawerId == 'templateForm'}
        extra={
            <Space>
                <Button onClick={() => { setDrawerId({}) }}>取消</Button>
                <Button type="primary" onClick={() => {
                    templateForm.validateFields().then((data) => {
                        post(module.cloud + '/template/v1.0/create', data)
                            .then(res => {
                                setParam({});
                                setDrawerId({})
                                message.info("发布成功");
                            })
                    })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={templateForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="sourceApplicationCode">
                <Input type="hidden"
                />
            </Form.Item>

            <div style={step == 1 ? {} : { display: "none" }}>
                <Form.Item
                    name="industryCode"
                    label="所属行业"
                    rules={[{ required: true, message: '请选择行业' }]}
                >
                    <DictSelect dictCode={"INDUSTRY_CODE"} />
                </Form.Item>

                <Form.Item
                    name="scope"
                    label="使用范围"
                    rules={[{ required: true, message: '请选择范围' }]}
                >
                    <DictSelect dictCode={"SCOPE"} />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="名称"
                >
                    <Input
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    name="picture"
                    label="图片"
                >
                    <ImageUpload ></ImageUpload>
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="简介"
                >
                    <TextArea
                        style={{ width: '100%', height: 200 }}
                    />
                </Form.Item>

            </div>

            <div style={step == 2 ? {} : { display: "none" }}>
                <Form.Item
                    name="images"
                    label="实例图片"
                >
                    <ImageUpload maxCount={10}></ImageUpload>
                </Form.Item>
                <Form.Item
                    name="content"
                    label="详细介绍"
                >
                    <RichTextEditor />
                </Form.Item>
            </div>

            <div style={{ textAlign: 'center' }}>
                <Space>
                    {step == 2 && <Button onClick={() => { setStep(1) }}>上一步</Button>}
                    {step == 1 && <Button onClick={() => { setStep(2) }}>下一步</Button>}
                </Space>
            </div>

        </Form>
    </Drawer >)
}

export default TemplateForm;