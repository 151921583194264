import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Image } from 'antd';
import { post } from 'util/http';
import module from 'util/module';

import { useNavigate } from 'react-router-dom';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import moment from 'moment'
import FormDrawer from './apply-form';



import { getMetaLabel } from 'util/meta';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "ailawyerLawyerApply";
    const [param, setParam] = useState({
        params: [{
            attrCode: "status",
            operator: "=",
            value: "paySuccess"
        }],
        metaCode: metaCode,
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }]
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });

    const [searchForm] = Form.useForm();
    const [chatParam, setChatParam] = useState({
        metaCode: "aiChatContent",
        visible: false
    })

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'name',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }



    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                </Space>

            </Form>

            <SearchTable url={"/api/app/meta/v1.0/data/search"}
                param={param}
                columns={[
                    {
                        title: '头像',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => {
                            return (<Image src={"/api/ac/public/headimg/" + record.creatorCode} width={40} />)
                        }
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '身份证号',
                        dataIndex: 'idCardCode',
                        key: 'idCardCode',
                    },
                    {
                        title: '执业单位',
                        dataIndex: 'companyName',
                        key: 'companyName',
                    },
                    {
                        title: '执业编号',
                        dataIndex: 'certificateCode',
                        key: 'certificateCode',
                    },
                    {
                        title: '执业证书',
                        dataIndex: 'certificateImage',
                        key: 'certificateImage',
                        render: (_, record) => {
                            return (<Image src={_} width={40} />)
                        }
                    },
                    {
                        title: '申请时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD")
                        }
                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: 'status',
                    },
                    {
                        title: '备注',
                        dataIndex: 'remark',
                        key: 'remark',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setFormParam({
                                        id: record.id,
                                        visible: true
                                    })
                                }}>详情</a>
                            </Space>
                        )
                    }
                ]} />
            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />

        </div >
    );
}
addPage("/ailawyer/lawyer/apply", Page);
export default Page;