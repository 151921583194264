import React, { useCallback, useEffect, useState } from 'react'
import { Input, Avatar, Drawer, message, Col, Row, Button } from 'antd';
import { post } from 'util/http';
import { v4 as uuidv4 } from 'uuid';
import ImChat from 'util/Im';
import AuthUtil from 'util/auth'


if (window.LOGIN_INFO) {
    ImChat.login({
        loginAccount: window.LOGIN_INFO.code
    })
}

const SearchTable = ({
    param,
    setParam,
    keyCode = "id",
    pushMsg,
    simplePagination = false,
    ...nextProps
}) => {
    const [msg, setMsg] = useState('');

    const [chatHistoy, setChatHistoy] = useState({
        records: []
    });

    const appendRecord = (data) => {
        if (chatHistoy.records.length == 0 || data.msgId != chatHistoy.records[chatHistoy.records.length - 1].msgId) {
            chatHistoy.records.push(data)
        } else {
            const lastMsg = chatHistoy.records[chatHistoy.records.length - 1];
            lastMsg.content = lastMsg.content + data.content;
        }
        setChatHistoy({ ...chatHistoy });
    }

    const resetChatHistory = (datas) => {
        chatHistoy.records = datas;
        setChatHistoy({ ...chatHistoy });
    }

    const clearChatHistory = () => {
        chatHistoy.records = [];
        setChatHistoy({ ...chatHistoy });
    }

    const getHeadimg = (msg) => {
        if (msg.headimg != null) {
            return msg.headimg;
        } else if (msg.creatorCode != null) {
            return "/api/ac/public/headimg/" + msg.creatorCode;
        } else {
            return "/robot.png"
        }
    }


    useEffect(() => {
        if (!param.visible) {
            clearChatHistory();
        } else {
            if (param.chatCode != '') {
                ImChat.onChat(param.chatCode, (msg) => {
                    appendRecord(msg)
                });
                post('/api/ai/chat/history/get', {
                    chatCode: param.chatCode,
                }).then(res => {
                    const newRecords = [];
                    res.data.forEach((item) => {
                        newRecords.push(JSON.parse(item));
                    })
                    resetChatHistory(newRecords);
                })
            }
        }
    }, [param.visible])



    return (
        <Drawer title="会话详情"
            placement="right"
            width={660}
            maskClosable={false}
            onClose={() => {
                setParam({ ...param, visible: false })
            }}
            visible={param.visible}
        >
            <div style={{ paddingBottom: '80px' }}>
                {chatHistoy.records.map((v) =>
                    <div
                        className={v.source == "Ai" ? 'flex-row-left' : 'flex-row-reverse-right'}
                        key={v.msgId}
                        style={{ padding: "0px 0px 20px 0px" }}>
                        <div style={{ width: 80 }}>
                            <Avatar style={{ margin: '0px 10px', }} icon={<img src={getHeadimg(v)} />} />
                        </div>
                        <div style={{ maxWidth: 450 }}>
                            {v.content}
                        </div>
                    </div>
                )}
            </div>
        </Drawer>
    );
}
export default SearchTable;
