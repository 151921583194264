import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Empty } from 'antd';
import { addPage } from 'component/DynamicRotes.js';

const Page = () => {
    return (
        <div>
            <Empty
                style={{ paddingTop: 200 }}
                description="首页">
            </Empty>
        </div >
    );
}
addPage("/home", Page);
export default Page;