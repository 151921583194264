import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
    Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem';
import regionData from 'util/region';
import SearchTree from 'component/SearchTree';

const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "xspaceRoom"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });


    useEffect(() => {
        post('/api/app/public/meta/get', { code: metaCode })
            .then(res => {
                setMeta(res.data);
            })
    }, [])
    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post('/api/app/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                pageForm.resetFields();
            }
        }


    }, [param])

    return (<Drawer title="创建工程"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button onClick={() => {
                    setParam({ ...param, visible: false })
                }}>取消</Button>
                <Button type="primary" onClick={() => {
                    pageForm.validateFields().then((data) => {
                        data.typeName = typeName;
                        const url = '/api/app/meta/v1.0/data/save';
                        post(url, {
                            metaCode: metaCode,
                            datas: data
                        }).then(res => {
                            setParam({ ...param, visible: false });
                            reload();
                            message.info("保存成功");
                        })
                    })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name='typeName' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item name='fullTypeName' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item name='fullTypeCode' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item name='regionName' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item name='fullRegionName' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item name='fullRegionCode' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            {meta.attrs.map((item) => {
                return item.isSystem == 2 &&
                    (
                        <Form.Item
                            name={item.attrCode}
                            label={item.attrName}
                            key={item.attrCode}
                            rules={[{ required: item.isRequired == 1, message: '请输入' + item.attrName }]}
                        >
                            <MetaFormItem
                                pageForm={pageForm}
                                metaAttr={item}
                                onChange={(_, v, extra) => {
                                    if (item.attrCode == 'typeCode') {
                                        pageForm.setFieldsValue({
                                            typeName: extra.triggerNode.props.data.name,
                                            fullTypeName: extra.triggerNode.props.data.fullNames,
                                            fullTypeCode: extra.triggerNode.props.data.fullCodes
                                        })
                                    }
                                    if (item.attrCode == 'regionCode') {
                                        pageForm.setFieldsValue({
                                            regionName: extra.triggerNode.props.data.name,
                                            fullRegionName: extra.triggerNode.props.data.fullNames,
                                            fullRegionCode: extra.triggerNode.props.data.fullCodes
                                        })
                                    }
                                }}
                            />
                        </Form.Item>
                    )
            })}
        </Form>
    </Drawer>)
}

export default Page;