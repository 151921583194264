import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import { post } from 'util/http';
import module from 'util/module';
import moment from 'moment'
const { TextArea } = Input;


const Page = ({
    param,
    setParam,
    reload,
}) => {

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();

    return (<Drawer title="积分记录"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
            reload();
        }}
        visible={param.visible}
    >

        <Modal width={460} title="添加积分" visible={param.modalVisible} onOk={() => {
            const data = pageForm.getFieldsValue();
            data.memberCode = param.memberCode;
            data.ruleCode = "platformGrant";
            const url = '/api/crm/member/v1.0/points/create';
            post(url, data)
                .then(res => {
                    message.info("创建成功");
                    setParam({ ...param, modalVisible: false })
                })

        }} onCancel={() => { setParam({ ...param, modalVisible: false }) }}>

            <Form form={pageForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 80 }
                }}
                initialValues={{
                    items: []
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>


                        <Form.Item
                            name="points"
                            label="积分"
                            rules={[{ required: true, message: '请输入积分' }]}
                        >
                            <Input type='number' />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>


        </Modal>

        <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
            {/* <Form.Item
                name="searchName"
            >
                <Input
                    style={{ width: '100%' }}
                    placeholder="请输入名称"
                />
            </Form.Item> */}
            <Space >
                {/* <Button onClick={() => {
                    setParam({ ...param, ...searchForm.getFieldsValue() })
                }}>搜索</Button> */}
                <Button type="primary" onClick={() => {
                    pageForm.resetFields();
                    setParam({ ...param, modalVisible: true })
                }}>添加积分</Button>
            </Space>
        </Form>

        <SearchTable url={"/api/app/meta/v1.0/data/search"}
            param={param}
            columns={[
                {
                    title: '积分记录',
                    dataIndex: 'ruleName',
                    key: 'ruleName',
                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                    render: (_, record) => {
                        return moment(_).format("yyyy-MM-DD HH:mm:ss")
                    }
                }
            ]} />
    </Drawer>)
}

export default Page;