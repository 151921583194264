import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
    Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem';
import CodeMirrorBox from 'component/CodeMirrorBox.js';
import SearchTree from 'component/SearchTree';
const { TextArea } = Input;

const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "bmsBattery"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });


    useEffect(() => {

    }, [])
    useEffect(() => {
        if (param.visible) {
            if (param.code && param.code != '') {
                post('/api/cron/job/get', {
                    code: param.code
                }).then(res => {
                    const data = res.data;
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...data
                    });
                })
            } else {
                pageForm.resetFields();
            }
        }


    }, [param])

    return (<Drawer title="创建定时任务"
        placement="right"
        width={860}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button onClick={() => {
                    setParam({ ...param, visible: false })
                }}>取消</Button>
                <Button type="primary" onClick={() => {
                    pageForm.validateFields().then((data) => {
                        const url = data.code != null && data.code != '' ? '/api/cron/job/update' : '/api/cron/job/create';
                        post(url, data).then(res => {
                            setParam({ ...param, visible: false });
                            reload();
                            message.info("创建成功");
                        })
                    })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 120 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item name='code' style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Form.Item name='name' label="名称" >
                <Input />
            </Form.Item>
            <Form.Item name='cron' label="表达式" >
                <Input />
            </Form.Item>
            <Form.Item name='className' label="处理类" >
                <Input />
            </Form.Item>
            <Form.Item name='param' label="参数" >
                <TextArea />
            </Form.Item>

        </Form>
    </Drawer>)
}

export default Page;