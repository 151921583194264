import './App.css';
import './pages/index';

import './apps/ailawyer'
import DynamicRoutes from 'component/DynamicRotes';
import React from 'react';

function App() {

  return (
    <DynamicRoutes />
  );
}

export default App;
