import React, { useEffect, useState } from 'react'
import { Table, Button, Input, Space, Popconfirm, Drawer, Form, Col, Row, Select, message, Breadcrumb } from 'antd';
import EditTable from 'component/EditTable';
import SearchSelect from 'component/SearchSelect';
import { post } from 'util/http';
import module from 'util/module';
const { Option } = Select;
const { TextArea } = Input;

const PageForm = ({
    applicationId,
    drawerId,
    setParam,
    setDrawerId
}) => {

    const [application, setApplication] = useState({
    });


    const [pageForm] = Form.useForm();


    useEffect(() => {
        if ('pageForm' == drawerId) {
            setApplication({});
            pageForm.resetFields();
        }
        if (applicationId != null && drawerId == 'pageForm') {
            post(module.cloud + '/application/v1.0/get', { id: applicationId })
                .then(res => {
                    setApplication(res.data);
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
        }
    }, [applicationId, drawerId])

    return (<Drawer title="创建应用"
        placement="right"
        width={660}
        maskClosable={false}
        onClose={() => { setDrawerId({}) }}
        visible={drawerId == 'pageForm'}
        extra={
            <Space>
                <Button onClick={() => { setDrawerId({}) }}>取消</Button>
                <Button type="primary" onClick={() => {
                    const param = pageForm.getFieldsValue();
                    const url = param.id ? module.cloud + '/application/v1.0/update' : module.cloud + '/application/v1.0/create';
                    post(url, param)
                        .then(res => {
                            setParam({});
                            setDrawerId({})
                            message.info("保存成功");
                        })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item style={{ display: 'none' }}
                        name="id">
                        <Input type="hidden"
                        />
                    </Form.Item>

                    <Form.Item
                        name="clusterCode"
                        label="所属集群"
                        rules={[{ required: true, message: '请选择集群' }]}
                    >
                        <SearchSelect url={module.cloud + "/cluster/v1.0/search"} labelCode="name" valueCode="code"
                            disabled={application.id != null}
                        />
                    </Form.Item>

                </Col>
                <Col span={24}>
                    <Form.Item
                        name="appId"
                        label="appId"
                        rules={[{ required: true, message: '请输入appId' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入appId"
                            disabled={application.id != null}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"

                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="domain"
                        label="域名"
                    >
                        <Input
                            style={{ width: '100%' }}

                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Drawer>)
}

export default PageForm;