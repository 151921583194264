import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, Select, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import FormDrawer from './form.js'
import SearchTable from 'component/SearchTable.js';
import { addPage, setBreadcrumbs } from 'component/DynamicRotes.js';
const Page = () => {
    const [metaStatus, setMetaStatus] = useState({
        metaId: null,
        drawerId: null
    })

    const [formParam, setFormParam] = useState({
        visible: false,
        clusterId: ""
    });

    const [param, setParam] = useState({ searchName: "", size: 10, current: 1 });
    const [searchForm] = Form.useForm();
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({ dataSourceId: '', visible: true })
                    }}>创建</Button>
                </Space>


            </Form>

            <SearchTable url={module.app + "/data_source/v1.0/search"}
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: 'code',
                        dataIndex: 'code',
                        key: 'code',
                    }, {
                        title: 'ip',
                        dataIndex: 'ip',
                        key: 'ip',
                    }, {
                        title: 'db',
                        dataIndex: 'db',
                        key: 'db',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setFormParam({ dataSourceId: record.id, visible: true })
                                }}>修改</a>

                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.app + '/data_source/v1.0/delete', { id: record.id })
                                            .then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />

            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }}></FormDrawer>
        </div >
    );
}
addPage("/app/data_source/manager", Page);
export default Page;