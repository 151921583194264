import React, { useCallback, useEffect, useState } from 'react'
import { Select } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import "antd/dist/antd.css";

const MetaSelect = ({
    value,
    onChange,
    dictCode,
    labelInValue = false,
    mode,
    ...nextProps
}) => {
    const [options, setOptions] = useState([]);

    const loadOptions = useCallback(() => {
        post(module.app + "/dict/v1.0/get-by-code", { code: dictCode })
            .then(res => {
                const newOptions = res.data.items.map((item) => {
                    return {
                        label: item.itemLabel,
                        value: item.itemValue
                    }
                })
                setOptions(newOptions);
            })
    }, [])

    useEffect(() => {
        loadOptions();
    }, [dictCode])

    return (<Select value={value} onChange={onChange} mode={mode} labelInValue={labelInValue}
        options={options} allowClear={true} {...nextProps} >
    </Select >)
}

export default MetaSelect;