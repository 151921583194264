import React, { useEffect, useState } from 'react'
import { Table, Button, Input, Space, Popconfirm, Drawer, Form, Col, Row, Select, message, Breadcrumb, Tree } from 'antd';
import EditTable from 'component/EditTable';
import SearchSelect from 'component/SearchSelect';
import AsyncTree from 'component/AsyncTree';

import { addPage } from 'component/DynamicRotes.js';
import { post } from 'util/http';
const { Option } = Select;
const { TextArea } = Input;

const PageForm = ({
    applicationId,
    drawerId,
    setParam,
    setDrawerId
}) => {

    const [application, setApplication] = useState({});



    const [pageForm] = Form.useForm();

    useEffect(() => {
        pageForm.setFieldsValue({
            "path": "/demo/src/pages/app/menu/debug.js",
            "content": "console.log('222'); export default () => { console.log(22); return <div>a</div> }"
        });

    }, [])

    return (<div title="创建应用" >

        <Row>
            <Col span={6}>
                <AsyncTree url="/dev/v1.0/file/tree"
                    titleCode="fileName"
                    valueCode="path"
                    isLeafCode="isFile"
                    style={{ height: 800 }}
                    onSelect={(selectedKeys) => {
                        post("/dev/v1.0/file/content/get", { path: selectedKeys[0] })
                            .then(res => {
                                pageForm.setFieldsValue({
                                    "path": selectedKeys[0],
                                    "content": res.data
                                });
                            })
                    }}
                ></AsyncTree>
            </Col>
            <Col span={18}>
                <Form form={pageForm} layout="horizontal" hideRequiredMark
                    labelCol={{
                        style: { width: 80 }
                    }}
                    initialValues={{
                        attrs: []
                    }}
                >
                    <Row gutter={16}>

                        <Col span={24}>
                            <Form.Item
                                name="path"
                                label="路径"
                                rules={[{ required: true, message: '请输入appId' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入appId"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>

                            <Form.Item
                                name="content"
                                label="内容"
                                rules={[{ required: true, message: '请输入名称' }]}
                            >
                                <TextArea
                                    style={{ width: '100%', height: 400 }}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={24} style={{ textAlign: "center" }}>

                            <Space >
                                <Button type="primary" onClick={() => {
                                    const param = pageForm.getFieldsValue();
                                    post("/dev/v1.0/file/save", param)
                                        .then(res => {
                                            message.info("保存成功");
                                        })
                                }}>保存</Button>
                            </Space>


                        </Col>


                    </Row>
                </Form>
            </Col>
        </Row>



    </div>)
}

addPage("/dev/fe/manager", PageForm);
export default PageForm;