import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import TreeTable from 'component/TreeTable'
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import moment from 'moment'

import { getMetaLabel } from 'util/meta';
import SyncTree from 'component/SyncTree.js';
const Page = () => {

    const metaCode = "devopsService";
    const [param, setParam] = useState({
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "name"
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });
    const [searchForm] = Form.useForm();

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.fullTypeCode != null) {
            newParam.push({
                attrCode: 'fullTypeCode',
                operator: "like",
                value: datas.fullTypeCode + "%"
            })
        }
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'title',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.status != null) {
            newParam.push({
                attrCode: 'status',
                operator: "=",
                value: datas.status
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >


                <Form.Item
                    name="status"
                >
                    <DictSelect
                        dictCode={"CMS_STATUS"}
                        style={{ width: 100 }}
                        placeholder="状态"
                    />
                </Form.Item>

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({
                            id: '',
                            visible: true
                        })
                    }}>创建</Button>
                </Space>


            </Form>


            <TreeTable url={module.app + "/meta/v1.0/data/tree"}
                keyCode="code"
                param={param}
                columns={[
                    {
                        title: '标题',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '简介',
                        dataIndex: ['datas', 'intro'],
                        key: 'intro',
                    },
                    {
                        title: '状态',
                        dataIndex: ['datas', 'status'],
                        key: 'status',
                        render: (_, record) => {
                            return getMetaLabel('CMS_STATUS', _);
                        }
                    }, {
                        title: '排序',
                        dataIndex: ['datas', 'showOrder'],
                        key: 'showOrder',
                    }, {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setFormParam({
                                        id: record.datas.id,
                                        visible: true
                                    })
                                }}>修改</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.app + '/meta/v1.0/data/delete', {
                                            metaCode: metaCode,
                                            id: record.datas.id
                                        }).then(res => {
                                            setParam({ ...param });
                                            message.info("删除成功");
                                        })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />
            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/devops/service/manager", Page);

export default Page;