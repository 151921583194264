import React, { useState } from 'react'
import { Button, Form, Input, message, Popconfirm, Space } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import { getMetaLabel } from 'util/meta';
import yaml from 'js-yaml'

import K8sSearchTable from 'component/K8sSearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import DictSelect from 'component/DictSelect';
import SearchSelect from 'component/SearchSelect';
import FormDrawer from './form.js'
import { useNavigate, useLocation } from 'react-router-dom';
import lodash from 'lodash'

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const Page = () => {
  const query = useQuery();
  const clusterCode = query.get("cluster") ? query.get("cluster") : "test";
  const [param, setParam] = useState({
    clusterCode: clusterCode,
    namespace: "",
    searchName: ""
  });
  const [formParam, setFormParam] = useState({
    visible: false,
    moduleId: ""
  });
  const [versionParam, setVersionParam] = useState({
    visible: false,
    moduleCode: "-1"
  });

  const [searchForm] = Form.useForm();

  return (
    <div>
      <Form form={searchForm} layout="inline" style={{ padding: 15 }}>
        <Form.Item
          name="status"
        >
          <SearchSelect
            url={"/api/k8s/namespace/v1.0/list"}
            dataCode="items"
            labelCode={["metadata", "name"]}
            valueCode={["metadata", "name"]}
            param={{
              clusterCode: "test"
            }}
            style={{ width: 180 }}
            placeholder="命名空间"
            onChange={(v) => {
              setParam({ ...param, namespace: v })
            }}
          ></SearchSelect>
        </Form.Item>
        <Form.Item name="searchName" >
          <Input
            style={{ width: '100%' }}
            placeholder="请输入名称"
          />
        </Form.Item>
        <Space>
          <Button onClick={() => {
            setParam({ ...param })
          }}>搜索</Button>
          <Button type="primary" onClick={() => {
            setFormParam({
              clusterCode: clusterCode,
              visible: true
            })
          }}>创建</Button>
        </Space>


      </Form>

      <K8sSearchTable url={"/api/k8s/deployment/v1.0/list"}
        param={param}
        columns={[
          {
            title: '名称',
            dataIndex: ['metadata', 'name'],
            key: 'metadataName',
          }, {
            title: 'Labels',
            dataIndex: ['metadata', 'labels'],
            key: 'metadataLabels',
            render: (_, record) => {
              return JSON.stringify(_);
            }
          },
          {
            title: 'Selector',
            dataIndex: ['spec', 'selector', 'matchLabels'],
            key: 'sepcSelectorMatchLabels',
            render: (_, record) => {
              return JSON.stringify(_);
            }
          },
          {
            title: '运行/期望Pod数量',
            dataIndex: ['status', 'availableReplicas'],
            key: 'statusReplicas',
            render: (_, record) => {
              return _ + "/" + record.spec.replicas;
            }
          }, {
            title: '操作',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
              <Space size="middle">
                {/* <a onClick={() => {
                  setVersionParam({
                    moduleCode: record.code,
                    visible: true
                  })
                }}>更新Pod数量</a> */}
                <a onClick={() => {
                  var json = lodash.cloneDeep(record)
                  delete json.metadata.annotations;
                  delete json.metadata.managedFields;
                  delete json.metadata.creationTimestamp;
                  delete json.status;
                  json.apiVersion = "apps/v1";
                  json.kind = "Deployment";
                  setFormParam({
                    clusterCode: clusterCode,
                    name: json.metadata.name,
                    namespace: json.metadata.namespace,
                    yaml: yaml.dump(json),
                    visible: true
                  })
                }}>更新Pod配置</a>

                <Popconfirm
                  title="你确认删除此数据么?"
                  onConfirm={() => {
                    post('/api/k8s/deployment/v1.0/delete',
                      {
                        clusterCode: clusterCode,
                        name: record.metadata.name,
                        namespace: record.metadata.namespace
                      })
                      .then(res => {
                        setParam({ ...param });
                        message.info("删除成功");
                      })
                  }}
                  okText="确认"
                  cancelText="取消">
                  <a href="#">删除</a>
                </Popconfirm>

              </Space>
            )
          }
        ]} />
      <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />


    </div >
  );
}

addPage("/k8s/deployment/manager", Page);

export default Page;