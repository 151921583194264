import React, { useEffect, useState } from 'react'
import { Button, Input, Space, Drawer, Form, Col, Row, Select, message } from 'antd';
import SearchTree from 'component/SearchTree';
import { post } from 'util/http';
import module from 'util/module';
const { Option } = Select;

const DictForm = ({
    orgId,
    drawerId,
    setParam,
    setDrawerId
}) => {

    const [orgForm] = Form.useForm();

    useEffect(() => {
        if ('orgForm' == drawerId) {
            orgForm.resetFields();
        }
        if (orgId != null && drawerId == 'orgForm') {
            post(module.ac + '/org/v1.0/get', { id: orgId })
                .then(res => {
                    orgForm.setFieldsValue(res.data);
                })
        }
    }, [orgId, drawerId])

    return (<Drawer title="创建组织机构"
        placement="right"
        width={660}
        maskClosable={false}
        onClose={() => { setDrawerId({}) }}
        visible={drawerId == 'orgForm'}
        extra={
            <Space>
                <Button onClick={() => { setDrawerId({}) }}>取消</Button>
                <Button type="primary" onClick={() => {
                    const param = orgForm.getFieldsValue();
                    const newParam = { ...param, authorities: [] };
                    newParam.isAuthority = param.isAuthority ? 1 : 0;
                    param.accounts && param.accounts.map(item => {
                        newParam.authorities.push({ type: 'account', authorityId: item.value, authorityName: item.label });
                    })
                    const url = newParam.id ? module.ac + '/org/v1.0/update' : module.ac + '/org/v1.0/create';
                    post(url, newParam)
                        .then(res => {
                            setParam({});
                            setDrawerId({})
                            message.info("保存成功");
                        })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={orgForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                items: []
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="parentCode"
                        label="父节点"
                        rules={[{ required: true, message: '请选择父节点' }]}
                    >
                        <SearchTree url={module.ac + "/org/v1.0/tree"} placeholder="请选择父节点" valueCode="code" />

                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item style={{ display: 'none' }}
                        name="id">
                        <Input type="hidden"
                        />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"
                        />
                    </Form.Item>

                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="code"
                        label="机构代码"
                        rules={[{ required: true, message: '请输入机构代码' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入机构代码"
                        />
                    </Form.Item>
                </Col>

            </Row>

        </Form>
    </Drawer>)
}

export default DictForm;