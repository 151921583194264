import React, { useCallback, useEffect, useState } from 'react'
import { Table, Pagination } from 'antd';
import { post } from 'util/http';


const TreeTable = ({
    columns,
    url,
    param,
    keyCode = "id",
    ...nextProps
}) => {
    const [pageData, setPageData] = useState([]);

    const loadData = useCallback((url, param) => {
        post(url, param || { size: 1 })
            .then(res => {
                if (Array.isArray(res.data)) {
                    setPageData(res.data);
                } else {
                    setPageData([res.data]);
                }
            })
    }, [param])

    useEffect(() => {
        loadData(url, param);
    }, [param])


    return (
        <>
            <Table dataSource={pageData} columns={columns}  {...nextProps}
                pagination={false}
                rowKey={record => record[keyCode]}
            >
            </Table >

        </>
    );
}

export default TreeTable;