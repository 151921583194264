import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, Select, message, Drawer } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { post } from 'util/http';
import module from 'util/module';
import PageForm from './form.js'

import SearchTable from 'component/SearchTable.js';
import CopyDrawer from './copy';
import ModuleDrawer from './module';
import ConfigDrawer from './config';
import TemplateDrawer from './template'
import DomainDrawer from './domain'

import { addPage } from 'component/DynamicRotes.js';
const Page = () => {
    const [pageStatus, setPageStatus] = useState({
        clusterCode: null,
        applicationCode: null,
        applicationId: null,
        drawerId: null
    })
    const [param, setParam] = useState({ searchName: "", size: 10, current: 1 });
    const [searchForm] = Form.useForm();

    const [moduleParam, setModuleParam] = useState({
        visible: false,
        applicationCode: "-1",
        clusterCode: "-1"
    })

    const [configParam, setConfigParam] = useState({
        visible: false,
        applicationCode: "-1",
        clusterCode: "-1"
    })

    const [domainParam, setDomainParam] = useState({
        visible: false,
        applicationCode: "-1",
        clusterCode: "-1"
    })



    const navigate = useNavigate();
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setPageStatus({
                            applicationId: null,
                            drawerId: 'pageForm'
                        })
                    }}>创建</Button>
                </Space>


            </Form>

            <SearchTable url={module.cloud + "/application/v1.0/search"}
                param={param}
                columns={[
                    {
                        title: '所属集群',
                        dataIndex: 'clusterName',
                        key: 'clusterName',
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: 'appId',
                        dataIndex: 'appId',
                        key: 'appId',
                    }, {
                        title: '应用code',
                        dataIndex: 'code',
                        key: 'code',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">

                                <a onClick={() => {
                                    setModuleParam({
                                        visible: true,
                                        clusterCode: record.clusterCode,
                                        applicationCode: record.code,
                                        domain: record.domain
                                    })
                                }}>模块</a>


                                <a onClick={() => {
                                    setDomainParam({
                                        visible: true,
                                        clusterCode: record.clusterCode,
                                        applicationCode: record.code,
                                    })
                                }}>站点</a>

                                <a onClick={() => {
                                    setConfigParam({
                                        visible: true,
                                        clusterCode: record.clusterCode,
                                        applicationCode: record.code,
                                    })
                                }}>配置</a>

                                <a onClick={() => {
                                    setPageStatus({
                                        applicationCode: record.code,
                                        drawerId: 'copyForm'
                                    })
                                }}>复制</a>

                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.cloud + '/application/v1.0/delete', { id: record.id })
                                            .then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                                <a onClick={() => {

                                    setPageStatus({
                                        applicationCode: record.code,
                                        drawerId: 'templateForm'
                                    })
                                }}>发布模板</a>

                            </Space>
                        )
                    }
                ]} />
            <PageForm applicationId={pageStatus.applicationId} drawerId={pageStatus.drawerId} setDrawerId={(val) => { setPageStatus(val) }} setParam={setParam} />

            <TemplateDrawer applicationCode={pageStatus.applicationCode} drawerId={pageStatus.drawerId} setDrawerId={(val) => { setPageStatus(val) }} setParam={setParam} />

            <CopyDrawer applicationCode={pageStatus.applicationCode} drawerId={pageStatus.drawerId} setPageStatus={setPageStatus} setParam={setParam} />

            <ModuleDrawer setParam={setModuleParam} param={moduleParam} />

            <ConfigDrawer setParam={setConfigParam} param={configParam} />

            <DomainDrawer setParam={setDomainParam} param={domainParam} />
        </div >
    );
}

addPage("/cloud/application/manager", Page);

export default Page;