import React, { useState, useEffect } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Drawer, Image } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import moment from 'moment'

import { getMetaLabel } from 'util/meta';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Page = ({
    param,
    setParam,
}) => {
    const navigate = useNavigate();
    const metaCode = "xspaceRoom";
    const [pageForm] = Form.useForm();
    const [searchParam, setSearchParam] = useState({
        metaCode: metaCode,
        params: [{
            attrCode: "spaceCode",
            operator: "=",
            value: param.spaceCode
        }],
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }]
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });
    const [searchForm] = Form.useForm();



    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (param.spaceCode != null) {
            newParam.push({
                attrCode: 'spaceCode',
                operator: "=",
                value: param.spaceCode
            })
        }
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'title',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.status != null) {
            newParam.push({
                attrCode: 'status',
                operator: "=",
                value: datas.status
            })
        }
        setSearchParam({
            ...searchParam,
            params: newParam
        })
    }

    useEffect(() => {
        if (param.visible) {
            if (param.spaceCode != '') {
                reload();
            }
        }
    }, [param])

    const toppingProject = (id, showOrder) => {
        post('/api/recruit/job/v1.0/topping', {
            id: id,
            showOrder: showOrder
        }, {
            loading: true
        }).then(res => {
            setParam({ ...param });
        })
    }
    return (
        <Drawer title="房间列表"
            placement="right"
            width={960}
            maskClosable={false}
            onClose={() => {
                setParam({ ...param, visible: false })
            }}
            visible={param.visible}
            extra={
                <Space>
                    <Button onClick={() => {
                        setParam({ ...param, visible: false })
                    }}>关闭</Button>
                </Space>
            }
        >
            <div>
                <Form form={searchForm} layout="inline" style={{ padding: 15 }} >


                    <Form.Item
                        name="searchName"
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"
                        />
                    </Form.Item>
                    <Space >
                        <Button onClick={() => {
                            reload();
                        }}>搜索</Button>
                    </Space>


                </Form>

                <SearchTable url={"/api/app/public/meta/data/search"}
                    param={searchParam}
                    columns={[
                        {
                            title: '名称',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: '图片',
                            dataIndex: 'nameImg',
                            key: 'nameImg',
                            render: (_, record) => {
                                return (<Image src={_} width={40} />)
                            }
                        },
                        {
                            title: '备注',
                            dataIndex: 'remark',
                            key: 'remark',
                        }, {
                            title: '价格',
                            dataIndex: 'price',
                            key: 'price',
                        }, {
                            title: '状态',
                            dataIndex: 'status',
                            key: 'status',
                            render: (_, record) => {
                                return _ == 'true' ? '启用' : '停用';
                            }
                        },
                        {
                            title: 'vip专享',
                            dataIndex: 'vipRoom',
                            key: 'vipRoom',
                            render: (_, record) => {
                                return _ == 'true' ? '是' : '否';
                            }
                        }, {
                            title: '创建时间',
                            dataIndex: 'createTime',
                            key: 'createTime',
                            render: (_, record) => {
                                return moment(_).format("yyyy-MM-DD HH:mm:ss")
                            }
                        }, {
                            title: '操作',
                            dataIndex: 'name',
                            key: 'name',
                            render: (_, record) => (
                                <Space size="middle">
                                    {/* <a onClick={() => {
                                        setFormParam({
                                            id: record.id,
                                            visible: true
                                        })
                                    }}>修改</a>
                                    <Popconfirm
                                        title="你确认删除此数据么?"
                                        onConfirm={() => {
                                            post(module.app + '/meta/v1.0/data/delete', {
                                                metaCode: metaCode,
                                                id: record.id
                                            }).then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                        }}
                                        okText="确认"
                                        cancelText="取消" >
                                        <a href="#">删除</a>
                                    </Popconfirm> */}

                                </Space>
                            )
                        }
                    ]} />
                <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
            </div >
        </Drawer>

    );
}
export default Page;