import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import { post } from 'util/http';
import module from 'util/module';
import moment from 'moment'
import { getMetaLabel } from 'util/meta';
const { TextArea } = Input;


const Page = ({
    param,
    setParam,
    reload,
}) => {

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();

    const [questions, setQuestions] = useState([]);

    return (<Drawer title="协议订单"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
    >

        <Modal width={860} title="协议制作" visible={param.questionVisible}
            onCancel={() => {
                setParam({ ...param, questionVisible: false })
            }}
            footer={[
                <Button key="cancel" onClick={() => {
                    setParam({ ...param, questionVisible: false })
                }}>
                    取消
                </Button>,
                <Button key="save" type="primary" onClick={() => {
                    const data = pageForm.getFieldsValue();
                    const url = '/api/ailawyer/protocol/order/save';
                    post(url, {
                        protocolOrderCode: param.protocolOrderCode,
                        answers: data
                    }).then(res => {
                        message.info("保存成功");
                    })
                }}>
                    保存
                </Button>,
                <Button
                    type="primary"
                    key="submit"
                    onClick={() => {
                        const data = pageForm.getFieldsValue();
                        const url = '/api/ailawyer/protocol/order/submit';
                        post(url, {
                            protocolOrderCode: param.protocolOrderCode,
                            answers: data
                        }).then(res => {
                            message.info("提交成功");
                            setParam({ ...param, questionVisible: false })
                        })

                    }}
                >
                    提交
                </Button>,
            ]}
        >

            <Form form={pageForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 120 }
                }}
                initialValues={{
                    items: []
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        {questions.map((item, index) => (
                            <Form.Item
                                name={item.question}
                                label={item.question}
                                value={item.answer}
                                key={index}
                            >
                                <Input value={item.answer} />
                            </Form.Item>
                        ))}
                    </Col>
                </Row>

            </Form>


        </Modal>

        <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
            <Space >
                <Button type="primary" onClick={() => {
                    post('/api/ailawyer/protocol/order/create', {
                        protocolCode: param.protocolCode
                    }).then(res => {
                        setParam({ ...param });
                        message.info("创建成功");
                    })
                }}>申请制作</Button>
            </Space>
        </Form>

        <SearchTable url={"/api/app/meta/v1.0/data/search"}
            param={param}
            columns={[
                {
                    title: '申请人',
                    dataIndex: 'creatorCode',
                    key: 'creatorCode',
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    render: (_, record) => {
                        return getMetaLabel('PROTOCOL_ORDER_STATUS', _);
                    }
                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                    render: (_, record) => {
                        return moment(_).format("yyyy-MM-DD HH:mm:ss")
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'code',
                    key: 'code',
                    render: (_, record) => (
                        <Space size="middle">
                            {record.status == 'finish' && <a onClick={() => {
                                console.log(record.resultFile);
                                post('/api/app/file/v1.0/file/download', {
                                    fileCode: record.resultFile
                                }).then(res => {
                                    window.open(res.data);
                                })
                            }}>下载</a>}
                            {record.status == 'create' && <a onClick={() => {
                                // post('/api/ailawyer/protocol/order/init', {
                                //     protocolOrderCode: record.code
                                // }).then(res => {
                                //     setParam({ ...param });
                                //     message.info("初始化成功");
                                // })
                                alert("待实现")
                            }}>支付</a>}
                            {record.status == 'paySuccess' && <a onClick={() => {
                                post('/api/ailawyer/protocol/order/answer/list', {
                                    protocolOrderCode: record.code
                                }).then(res => {
                                    setParam({ ...param, questionVisible: true, protocolOrderCode: record.code });
                                    setQuestions(res.data.questions);
                                    const questionMap = {};
                                    res.data.questions.forEach(item => {
                                        questionMap[item.question] = item.answer;
                                    })
                                    pageForm.setFieldsValue(questionMap);
                                })
                            }}>回答</a>}
                            <Popconfirm
                                title="你确认删除?"
                                onConfirm={() => {
                                    post('/api/ailawyer/protocol/order/delete', {
                                        protocolOrderCode: record.code
                                    }).then(res => {
                                        setParam({ ...param });
                                        message.info("删除成功");
                                    })
                                }}
                                okText="确认"
                                cancelText="取消" >
                                <a href="#">删除</a>
                            </Popconfirm>

                        </Space>
                    )

                }
            ]} />
    </Drawer>)
}

export default Page;