import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import DictSelect from "../../../component/DictSelect";

const { Option } = Select;
const { TextArea } = Input;

const Page = ({
  param,
  setParam,
  reload
}) => {


  const [pageForm] = Form.useForm();

  useEffect(() => {
    if (param.visible) {
      if (param.dataSourceId != '') {
        post(module.app + '/data_source/v1.0/get', { id: param.dataSourceId })
          .then(res => {
            pageForm.setFieldsValue({
              ...res.data
            });
          })
      } else {
        pageForm.resetFields();
        // pageForm.setFieldsValue({
        //   "name": "paifu",
        //   "driverClassName": "com.mysql.cj.jdbc.Driver",
        //   "ip": "localhost",
        //   "port": "3306",
        //   "username": "root",
        //   "password": "root",
        //   "db": "paifu",
        //   "config": "useUnicode=true&characterEncoding=utf8&autoReconnect=true&allowMultiQueries=true&useSSL=false"
        // });
      }
    }
  }, [param])

  return (<Drawer title="创建数据源"
    placement="right"
    width={660}
    maskClosable={false}
    onClose={() => {
      setParam({ ...param, visible: false })
    }}
    visible={param.visible}
    extra={
      <Space>
        <Button onClick={() => {
          setParam({ ...param, visible: false })
        }}>取消</Button>
        <Button type="primary" onClick={() => {
          const data = pageForm.getFieldsValue();
          const url = data.id ? module.app + '/data_source/v1.0/update'
            : module.app + '/data_source/v1.0/create';
          post(url, data)
            .then(res => {
              setParam({ ...param, visible: false });
              reload();
              message.info("保存成功");
            })
        }}>
          确认
        </Button>
      </Space>
    }
  >
    <Form form={pageForm} layout="horizontal" hideRequiredMark
      labelCol={{
        style: { width: 80 }
      }}
      initialValues={{
        attrs: []
      }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item style={{ display: 'none' }}
            name="id">
            <Input type="hidden"
            />
          </Form.Item>
          <Form.Item
            name="name"
            label="名称"
            rules={[{ required: true, message: '请输入名称' }]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入名称"
            />
          </Form.Item>

        </Col>
        <Col span={24}>
          <Form.Item
            name="driverClassName"
            label="驱动"
          >
            <DictSelect dictCode={"DRIVER_CLASS_NAME"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="ip"
            label="ip"
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入ip"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="port"
            label="端口"
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入端口"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="db"
            label="数据库"
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入数据库"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="username"
            label="用户名"
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入用户名"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="password"
            label="密码"
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入密码"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="config"
            label="配置"
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入配置"
            />
          </Form.Item>
        </Col>
      </Row>


    </Form>
  </Drawer>)
}

export default Page;