import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import TreeTable from 'component/TreeTable'
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';

import { getMetaLabel } from 'util/meta';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "ecOrder";
    const [param, setParam] = useState({
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "title",
        orders: [{
            attrCode: "createTime",
            order: "desc"
        }],
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });
    const [searchForm] = Form.useForm();

    const [pointsParam, setPointsParam] = useState({
        metaCode: "crmMemberPoints",
        visible: false
    })

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];

        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'code',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.memberName != null) {
            newParam.push({
                attrCode: 'memberName',
                operator: "like",
                value: "%" + datas.memberName + "%"
            })
        }
        if (datas.status != null) {
            newParam.push({
                attrCode: 'status',
                operator: "=",
                value: datas.status
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >

                <Form.Item name="searchName" >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入订单编号"
                    />
                </Form.Item>
                <Form.Item name="memberName" >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="会员"
                    />
                </Form.Item>
                <Form.Item
                    name="status"
                >
                    <DictSelect dictCode={"EC_ORDER_STATUS"} style={{ width: 100 }} placeholder="订单状态" />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                </Space>

            </Form>

            <SearchTable url={module.app + "/meta/v1.0/data/search"}
                param={param}
                columns={[
                    {
                        title: '订单编号',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: '订单描述',
                        dataIndex: 'description',
                        key: 'description',
                    },
                    {
                        title: '会员',
                        dataIndex: 'memberName',
                        key: 'memberName',
                    },
                    {
                        title: '订单金额',
                        dataIndex: 'totalAmount',
                        key: 'totalAmount',
                        render: (_, record) => {
                            return (_) + "元"
                        }
                    },
                    {
                        title: '实际金额',
                        dataIndex: 'realAmount',
                        key: 'realAmount',
                        render: (_, record) => {
                            return (_) + "元"
                        }
                    },
                    {
                        title: '订单状态',
                        dataIndex: 'status',
                        key: 'status',
                        render: (_, record) => {
                            return getMetaLabel('EC_ORDER_STATUS', _);
                        }
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    },
                    , {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">

                                {record.status == 'paySuccess' && <Popconfirm
                                    title="你确认退款么?"
                                    onConfirm={() => {
                                        post('/api/ec/order/v1.0/refund', {
                                            orderCode: record.code,
                                        }).then(res => {
                                            setParam({ ...param });
                                            message.info("操作成功");
                                        })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">退款</a>
                                </Popconfirm>}

                            </Space>
                        )
                    }
                ]} />
            < FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/ec/order/manager", Page);

export default Page;