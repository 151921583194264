import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Modal,
    Tabs,
    Space,
    Popconfirm
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem';
import FileUpload from 'component/FileUpload';
import DictSelect from 'component/DictSelect';
import regionData from 'util/region';
import ImageUpload from 'component/ImageUpload';
import TextArea from 'antd/lib/input/TextArea';
import ButtonGroup from 'antd/lib/button/button-group';
import SearchTable from 'component/SearchTable.js';
import { set } from 'lodash';
import moment from 'moment'


const Page = ({
    param,
    setParam,
    reload
}) => {


    const [aiRobot, setAiRobot] = useState({});

    const [questionParam, setQuestionParam] = useState({
        questionVisible: false,
        metaCode: "ailawyerRobotQuestion",
        params: [{
            attrCode: 'robotCode',
            operator: "=",
            value: "-1"
        }],
    });

    const [fileParam, setFileParam] = useState({
        metaCode: "aiRobotKnowledge",
        params: [{
            attrCode: 'robotCode',
            operator: "=",
            value: "-1"
        }, {
            attrCode: 'type',
            operator: "=",
            value: "document"
        }, {
            attrCode: 'category',
            operator: "=",
            value: "document"
        }],
    });

    const [urlParam, setUrlParam] = useState({
        metaCode: "aiRobotKnowledge",
        params: [{
            attrCode: 'robotCode',
            operator: "=",
            value: "-1"
        }, {
            attrCode: 'type',
            operator: "=",
            value: "url"
        }, {
            attrCode: 'category',
            operator: "=",
            value: "url"
        }],
    });

    const [lawParam, setLawParam] = useState({
        lawVisible: false,
        metaCode: "aiRobotKnowledge",
        params: [{
            attrCode: 'robotCode',
            operator: "=",
            value: "-1"
        }, {
            attrCode: 'category',
            operator: "=",
            value: "law"
        }],
    });


    const metaCode = "ailawyerProtocol"

    const [baseForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });


    useEffect(() => {
        post(module.app + '/meta/v1.0/get-by-code', { code: metaCode })
            .then(res => {
                setMeta(res.data);
            })
    }, [])

    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post('/api/app/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    baseForm.resetFields();
                    baseForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                baseForm.resetFields();
            }
        }


    }, [param])

    return (<Drawer title="Ai律师详情"
        placement="right"
        width={1024}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button type="primary" onClick={() => {
                    baseForm.validateFields().then((data) => {
                        const url = '/api/app/meta/v1.0/data/save';
                        post(url, {
                            metaCode: metaCode,
                            datas: data
                        }).then(res => {
                            setParam({ ...param, visible: false });
                            message.info("创建成功");
                        })
                    })
                }}>保存</Button>

            </Space>
        }
    >
        <Form form={baseForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 120, fontSize: 14, fontWeight: 'bold' }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>
            {meta.attrs.map((item) => {
                return item.isSystem == 2 &&
                    (
                        <Form.Item
                            name={item.attrCode}
                            label={item.attrName}
                            key={item.attrCode}
                            rules={[{ required: item.isRequired == 1, message: '请输入' + item.attrName }]}
                        >
                            <MetaFormItem
                                pageForm={baseForm}
                                metaAttr={item}
                            />
                        </Form.Item>
                    )
            })}

        </Form>




    </Drawer >)
}

export default Page;