
const cloudApiMap = {
    tencent: {
        createParam: (data) => {
            const chargeTypeMap = {
                CHARGE_MONTH: "PREPAID",
                CHARGE_HOUR: "POSTPAID_BY_HOUR"
            }
            const createConfig = {
                InstanceChargeType: chargeTypeMap[data.chargetType],
                InstanceType: data.packagesCode,
                ImageId: data.imageCode,
                Placement: {
                    Zone: data.zoneCode
                }
            }
            return {
                cloudCode: data.cloudCode,
                accountCode: data.accountCode,
                config: JSON.stringify(createConfig)
            }
        }
    }
}
const cloudUtil = {
    getCloudApi: (cloudCode) => {
        return cloudApiMap[cloudCode];
    }
}
export default cloudUtil;