import React, { useCallback, useState, useMemo } from 'react';
import { Table, Popconfirm, Form, Typography, Space, Button } from 'antd';
import EditableCell from './EditTableCell';
import './EditTable.css';


const EditTable = ({
    value = [],
    columns = [],
    onChange,
    rowSave = function () { return true; },
    rowDel = function () { return true; }
}) => {

    const [editTableForm] = Form.useForm();
    const [editData, setEditStatus] = useState({
        isAdd: false,
        key: -1
    });


    const newColumns = [
        ...columns,
        {
            title: '操作',
            width: 120,
            ellipsis: true,
            dataIndex: 'operation',
            render: (_, record, index) => {
                const editable = isEditing(index);
                return editable ? (
                    <Space>
                        <Typography.Link onClick={() => save(record, index)} >
                            保存
                        </Typography.Link>
                        <Typography.Link onClick={() => { cancel(record, index) }} >
                            取消
                        </Typography.Link>
                    </Space>
                ) : (
                    <Space>
                        <Typography.Link disabled={editData.key !== -1} onClick={() => {
                            edit(record, index)
                        }}>
                            编辑
                        </Typography.Link>
                        <Popconfirm
                            title="你确认删除此数据么?"
                            onConfirm={() => { del(record, index) }}
                            okText="确认"
                            cancelText="取消" >
                            <a href="#" disabled={editData.key !== -1}>删除</a>
                        </Popconfirm>
                    </Space >
                );
            },
        }
    ];

    const isEditing = (index) => index === editData.key;

    const edit = (record, index) => {
        editTableForm.setFieldsValue({
            ...record,
        });
        setEditStatus({
            isAdd: false,
            key: index
        });
    };

    const cancel = useCallback((record, index) => {
        if (editData.isAdd) {
            del(record, index);
        } else {
            setEditStatus({
                isAdd: false,
                key: -1
            });
        }
    }, [value]);

    const add = useCallback(() => {
        const newData = [...value, {}];
        editTableForm.resetFields();
        onChange(newData);
        setEditStatus({
            isAdd: true,
            key: newData.length - 1
        });
    }, [value]);

    const del = useCallback((record, index) => {
        try {
            if (index > -1) {
                const newData = [...value];
                const item = newData[index];
                newData.splice(index, 1);
                if (rowDel(item, newData)) {
                    onChange(newData);
                }
                setEditStatus({
                    isAdd: false,
                    key: -1
                });
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }, [value]);

    const save = useCallback(async (record, index) => {
        try {
            const row = await editTableForm.validateFields();
            const newData = [...value];
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
            } else {
                newData.push(row);
            }
            if (rowSave(row, newData)) {
                onChange(newData);
            }
            setEditStatus({
                isAdd: false,
                key: -1
            });

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }, [value]);


    const mergedColumns = newColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record, index) => ({
                record,
                editType: col.editType,
                editRules: col.editRules,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(index),
            }),
        };
    });
    return (
        <Form form={editTableForm} component={false} >
            <Space style={{ paddingBottom: 15 }}>
                <Button type="primary" onClick={add} disabled={editData.key !== -1}> 新增 </Button>
            </Space>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={value}
                columns={mergedColumns}
                rowKey={(row, index) => index}
                rowClassName="editable-row"
                pagination={false}
            />
        </Form>
    );
};

export default EditTable;
