import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Avatar } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import { getMetaLabel } from 'util/meta';
import moment from 'moment'
import Approvaling from './approvaling'

const Page = () => {

    const metaCode = "recruitWorker";
    const [param, setParam] = useState({
        metaCode: metaCode,
        searchAttrs: ["title", "content"],
        orders: [{
            attrCode: "createTime",
            order: "desc"
        }]
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });
    const [searchForm] = Form.useForm();

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.fullTypeCode != null) {
            newParam.push({
                attrCode: 'fullTypeCode',
                operator: "like",
                value: datas.fullTypeCode + "%"
            })
        }
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'name',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.status != null) {
            newParam.push({
                attrCode: 'status',
                operator: "=",
                value: datas.status
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }

    const toppingWorker = (id, showOrder) => {
        post('/api/recruit/worker/v1.0/topping', {
            id: id,
            showOrder: showOrder
        }, {
            loading: true
        }).then(res => {
            setParam({ ...param });
        })
    }

    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >

                <Form.Item
                    name="fullTypeCode"
                >
                    <SearchTree
                        url={"/api/app/public/meta/data/tree"}
                        param={{ metaCode: 'recruitWorkerType', showRoot: false }}
                        valueCode='fullCodes'
                        style={{ width: 200 }}
                        placeholder="工种类别"
                    />
                </Form.Item>

                <Form.Item
                    name="status"
                >
                    <DictSelect
                        dictCode={"WORKER_STATUS"}
                        style={{ width: 100 }}
                        placeholder="状态"
                    />
                </Form.Item>

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({
                            id: '',
                            visible: true
                        })
                    }}>创建</Button>
                </Space>


            </Form>

            <SearchTable url={"/api/app/public/meta/data/search"}
                param={param}
                columns={[

                    {
                        title: '头像',
                        dataIndex: 'headImage',
                        key: 'headImage',
                        render: (_, record) => {
                            return (<Avatar src={_} />)
                        }
                    },
                    {
                        title: '工种类别',
                        dataIndex: 'fullTypeName',
                        key: 'fullTypeName',
                    },
                    {
                        title: '姓名',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '地区',
                        dataIndex: 'fullRegionName',
                        key: 'fullRegionName',
                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: 'status',
                        render: (_, record) => {
                            return getMetaLabel('WORKER_STATUS', _);
                        }
                    }, {
                        title: '关键字',
                        dataIndex: 'tags',
                        key: 'tags',
                    }, {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '置顶',
                        dataIndex: 'showOrder',
                        key: 'showOrder',
                        render: (_, record) => {
                            if (_ == 2147483647) {
                                return <Space>
                                    是
                                    <a onClick={() => {
                                        toppingWorker(record.id, 0);
                                    }}>取消置顶</a>
                                </Space>;
                            } else {
                                return <Space>
                                    否
                                    <a onClick={() => {
                                        toppingWorker(record.id, 2147483647);
                                    }}>置顶</a>
                                </Space>;
                            }
                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setFormParam({
                                        id: record.id,
                                        visible: true
                                    })
                                }}>修改</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.app + '/meta/v1.0/data/delete', {
                                            metaCode: metaCode,
                                            id: record.id
                                        }).then(res => {
                                            setParam({ ...param });
                                            message.info("删除成功");
                                        })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />
            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/recruit/worker/manager", Page);
export default Page;