import { EventEmitter } from 'events';
import AuthUtil from './auth'


const mqtt = window.mqtt;
const emitter = new EventEmitter();

const eventMap = {};

const initImChat = ({
    connectUrl,
    tokenId,
    config
}) => {
    const options = {
        clean: true, // true: 清除会话, false: 保留会话
        connectTimeout: 4000, // 超时时间
        clientId: "client- " + new Date().getTime() + '', // 客户端标识
        username: "123",
        password: "123",
        ...config
    };
    const client = mqtt.connect(connectUrl, options)

    client.on('reconnect', (error) => {
        console.log('正在重连:', error)
    })

    client.on('error', (error) => {
        console.log('连接失败:', error)
    })

    client.on('message', (topic, message) => {
        const msg = JSON.parse(message.toString());
        if (topic.startsWith("token/reply/")) {
            emitter.emit(msg.chatCode, msg);
        }
    })

    client.subscribe("token/reply/" + tokenId, { qos: 0 }, (error) => {
        if (!error) {
            console.log('chat订阅成功:' + "token/reply/" + tokenId)
        } else {
            console.log('订阅失败')
        }
    })


    return client;

}
const ImChat = {
    login: async ({
        loginAccount
    }) => {
        const tokenId = AuthUtil.getTokenId();
        if (tokenId != null) {
            ImChat.client = initImChat({
                connectUrl: 'wss://qd1e8f39.ala.cn-hangzhou.emqxsl.cn:8084/mqtt',
                // connectUrl: 'wss://mp.ostrip.xihecloud.net/mqtt',
                tokenId: tokenId,
                config: {}
            })
        };
    },
    onChat: (chatCode, callback) => {
        if (eventMap[chatCode] == null) {
            emitter.on(chatCode, callback);
            eventMap[chatCode] = callback;
        }
    },
}

export default ImChat;

