import React, { useEffect, useState } from 'react'
import { Button, Input, Space, Drawer, Form, Col, Row, Popconfirm, message, Modal } from 'antd';
import EditTable from 'component/EditTable';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem'
import "antd/dist/antd.css";

const DataList = ({
    param,
    setParam,
}) => {


    const [formParam, setFormParam] = useState({
    });

    const [pageForm] = Form.useForm();

    const [meta, setMeta] = useState({
        attrs: []
    });


    useEffect(() => {
        if (param.metaCode != null) {
            post(module.app + '/meta/v1.0/get-by-code', {
                code: param.metaCode
            }).then(res => {
                setMeta(res.data);
            })
        }
    }, [param.metaCode])


    return (<Drawer title="数据"
        placement="right"
        width={1024}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        extra={
            <Space>
                <Button onClick={() => { }} type="link" >下载模板</Button>
                <Button type="primary" onClick={() => {
                }}>
                    批量导入
                </Button>
            </Space>
        }
        visible={param.visible}
    >

        <Space >
            <Button type="primary" onClick={() => {
                setFormParam({ ...formParam, visible: true });
                pageForm.resetFields();
            }}>创建</Button>

        </Space>
        <SearchTable url={module.app + "/meta/v1.0/data/search"}
            param={param}
            columns={[
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                }, {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                }, {
                    title: '操作',
                    dataIndex: 'name',
                    key: 'name',
                    render: (_, record) => (
                        <Space size="middle">
                            <a onClick={() => {
                                setFormParam({ ...formParam, visible: true });
                                pageForm.resetFields();
                                pageForm.setFieldsValue(record);
                            }}>修改</a>

                            <Popconfirm
                                title="你确认删除此数据么?"
                                onConfirm={() => {
                                    post(module.app + '/meta/v1.0/data/delete', {
                                        metaCode: param.metaCode,
                                        id: record.id
                                    })
                                        .then(res => {
                                            setParam({ ...param })
                                            message.info("删除成功");
                                        })
                                }}
                                okText="确认"
                                cancelText="取消" >
                                <a href="#">删除</a>
                            </Popconfirm>

                        </Space>
                    )
                }
            ]} />


        <Modal title="修改数据"
            visible={formParam.visible}
            width={900}
            onOk={() => {
                pageForm.validateFields().then((data) => {
                    post(module.app + '/meta/v1.0/data/save', { datas: data, metaCode: param.metaCode })
                        .then(res => {
                            setParam({ ...param });
                            setFormParam({ ...formParam, visible: false })
                        })
                })
            }} onCancel={() => {
                setFormParam({ ...formParam, visible: false })
            }}>
            <Form form={pageForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 80 }
                }}
                initialValues={{
                    attrs: []
                }}
            >
                <Row gutter={16}>
                    {meta.attrs.map((item) => {
                        return item.isSystem == 2 &&
                            (
                                <Col span={12} key={item.attrCode}>
                                    <Form.Item
                                        name={item.attrCode}
                                        label={item.attrName}
                                        key={item.attrCode}
                                        rules={[{ required: item.isRequired == 1, message: '请输入' + item.attrName }]}
                                    >
                                        <MetaFormItem
                                            pageForm={pageForm}
                                            metaAttr={item}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                    })}

                </Row>
            </Form>
        </Modal>
    </Drawer>)
}

export default DataList;