import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchSelect from "component/SearchSelect";
import CodeMirrorBox from 'component/CodeMirrorBox.js';

const { Option } = Select;
const { TextArea } = Input;

const Page = ({
  param,
  setParam,
  reload
}) => {

  const [pageForm] = Form.useForm();
  const [yaml, setYaml] = useState("")
  useEffect(() => {
    pageForm.resetFields();
    if (param.visible) {
      pageForm.setFieldsValue(param);
      setYaml(param.yaml);
    }
  }, [param])

  return (<Drawer title="Deployment管理"
    placement="right"
    width={960}
    maskClosable={false}
    onClose={() => {
      setParam({ ...param, visible: false })
    }}
    visible={param.visible}
    extra={
      <Space>
        <Button onClick={() => {
          setParam({ ...param, visible: false })
        }}>取消</Button>
        <Button type="primary" onClick={() => {
          pageForm.validateFields().then((data) => {
            const url = data.name == null ? '/api/k8s/service/v1.0/create' : '/api/k8s/service/v1.0/update';
            post(url, {
              ...data,
              force: true
            }).then(res => {
              setParam({ ...param, visible: false });
              reload();
              message.info("操作成功");
            })
          })

        }}>
          确认
        </Button>
      </Space>
    }
  >
    <Form form={pageForm} layout="horizontal" hideRequiredMark
      labelCol={{
        style: { width: 80 }
      }}
      initialValues={{
        attrs: []
      }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item style={{ display: 'none' }}
            name="name">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="clusterCode"
            label="集群"
            style={{ display: "none" }}
            rules={[{ required: true, message: '请选择集群' }]}
          >
            <SearchSelect
              url={"/api/app/meta/v1.0/data/search"}
              param={{
                metaCode: "k8sCluster"
              }}
            ></SearchSelect>
          </Form.Item>

        </Col>
        <Col span={24}>
          <Form.Item
            name="namespace"
            label="命名空间"
            rules={[{ required: true, message: '请输入命名空间' }]}
          >
            <SearchSelect
              url={"/api/k8s/namespace/v1.0/list"}
              dataCode="items"
              labelCode={["metadata", "name"]}
              valueCode={["metadata", "name"]}
              param={{
                clusterCode: "test"
              }}
              placeholder="命名空间"
            ></SearchSelect>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="yaml"
            label="yaml"
          >
            <CodeMirrorBox
              defaultValue={param.yaml}
            > </CodeMirrorBox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </Drawer>)
}

export default Page;