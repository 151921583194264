import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, Select, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import ConfigForm from './form.js'
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
const Dict = () => {
    const [pageStatus, setPageStatus] = useState({
        configId: null,
        drawerId: null
    })
    const [param, setParam] = useState({ searchName: "", size: 10, current: 1 });
    const [searchForm] = Form.useForm();
    console.log("debug 执行多次");
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setPageStatus({
                            configId: null,
                            drawerId: 'configForm'
                        })
                    }}>创建</Button>
                </Space>


            </Form>

            <SearchTable url="/api/app/config/v1.0/search"
                param={param}
                columns={[
                    {
                        title: '配置项',
                        dataIndex: 'code',
                        key: 'code',
                    }, {
                        title: '备注',
                        dataIndex: 'remark',
                        key: 'remark',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setPageStatus({
                                        configId: record.id,
                                        drawerId: 'configForm'
                                    })
                                }}>修改</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.app + '/config/v1.0/delete', { id: record.id })
                                            .then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />

            <ConfigForm configId={pageStatus.configId} drawerId={pageStatus.drawerId} setDrawerId={(val) => { setPageStatus(val) }} setParam={setParam} />
        </div >
    );
}
addPage("/app/config/manager", Dict);
export default Dict;