const regionData = {
    "status": 0,
    "msg": "成功",
    "data": [
        {
            "id": "110100",
            "code": "110100",
            "name": "北京市",
            "parentCode": "-1",
            "fullCodes": "110100",
            "fullNames": "北京市",
            "children": [
                {
                    "id": "110101",
                    "code": "110101",
                    "name": "东城区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110101",
                    "fullNames": "北京市,东城区",
                    "children": []
                },
                {
                    "id": "110102",
                    "code": "110102",
                    "name": "西城区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110102",
                    "fullNames": "北京市,西城区",
                    "children": []
                },
                {
                    "id": "110103",
                    "code": "110103",
                    "name": "崇文区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110103",
                    "fullNames": "北京市,崇文区",
                    "children": []
                },
                {
                    "id": "110104",
                    "code": "110104",
                    "name": "宣武区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110104",
                    "fullNames": "北京市,宣武区",
                    "children": []
                },
                {
                    "id": "110105",
                    "code": "110105",
                    "name": "朝阳区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110105",
                    "fullNames": "北京市,朝阳区",
                    "children": []
                },
                {
                    "id": "110106",
                    "code": "110106",
                    "name": "丰台区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110106",
                    "fullNames": "北京市,丰台区",
                    "children": []
                },
                {
                    "id": "110107",
                    "code": "110107",
                    "name": "石景山区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110107",
                    "fullNames": "北京市,石景山区",
                    "children": []
                },
                {
                    "id": "110108",
                    "code": "110108",
                    "name": "海淀区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110108",
                    "fullNames": "北京市,海淀区",
                    "children": []
                },
                {
                    "id": "110109",
                    "code": "110109",
                    "name": "门头沟区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110109",
                    "fullNames": "北京市,门头沟区",
                    "children": []
                },
                {
                    "id": "110111",
                    "code": "110111",
                    "name": "房山区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110111",
                    "fullNames": "北京市,房山区",
                    "children": []
                },
                {
                    "id": "110112",
                    "code": "110112",
                    "name": "通州区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110112",
                    "fullNames": "北京市,通州区",
                    "children": []
                },
                {
                    "id": "110113",
                    "code": "110113",
                    "name": "顺义区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110113",
                    "fullNames": "北京市,顺义区",
                    "children": []
                },
                {
                    "id": "110114",
                    "code": "110114",
                    "name": "昌平区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110114",
                    "fullNames": "北京市,昌平区",
                    "children": []
                },
                {
                    "id": "110115",
                    "code": "110115",
                    "name": "大兴区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110115",
                    "fullNames": "北京市,大兴区",
                    "children": []
                },
                {
                    "id": "110116",
                    "code": "110116",
                    "name": "怀柔区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110116",
                    "fullNames": "北京市,怀柔区",
                    "children": []
                },
                {
                    "id": "110117",
                    "code": "110117",
                    "name": "平谷区",
                    "parentCode": "110100",
                    "fullCodes": "110100,110117",
                    "fullNames": "北京市,平谷区",
                    "children": []
                },
                {
                    "id": "110228",
                    "code": "110228",
                    "name": "密云县",
                    "parentCode": "110100",
                    "fullCodes": "110100,110228",
                    "fullNames": "北京市,密云县",
                    "children": []
                },
                {
                    "id": "110229",
                    "code": "110229",
                    "name": "延庆县",
                    "parentCode": "110100",
                    "fullCodes": "110100,110229",
                    "fullNames": "北京市,延庆县",
                    "children": []
                }
            ]
        },
        {
            "id": "120100",
            "code": "120100",
            "name": "天津市",
            "parentCode": "-1",
            "fullCodes": "120100",
            "fullNames": "天津市",
            "children": [
                {
                    "id": "120101",
                    "code": "120101",
                    "name": "和平区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120101",
                    "fullNames": "天津市,和平区",
                    "children": []
                },
                {
                    "id": "120102",
                    "code": "120102",
                    "name": "河东区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120102",
                    "fullNames": "天津市,河东区",
                    "children": []
                },
                {
                    "id": "120103",
                    "code": "120103",
                    "name": "河西区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120103",
                    "fullNames": "天津市,河西区",
                    "children": []
                },
                {
                    "id": "120104",
                    "code": "120104",
                    "name": "南开区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120104",
                    "fullNames": "天津市,南开区",
                    "children": []
                },
                {
                    "id": "120105",
                    "code": "120105",
                    "name": "河北区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120105",
                    "fullNames": "天津市,河北区",
                    "children": []
                },
                {
                    "id": "120106",
                    "code": "120106",
                    "name": "红桥区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120106",
                    "fullNames": "天津市,红桥区",
                    "children": []
                },
                {
                    "id": "120107",
                    "code": "120107",
                    "name": "塘沽区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120107",
                    "fullNames": "天津市,塘沽区",
                    "children": []
                },
                {
                    "id": "120108",
                    "code": "120108",
                    "name": "汉沽区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120108",
                    "fullNames": "天津市,汉沽区",
                    "children": []
                },
                {
                    "id": "120109",
                    "code": "120109",
                    "name": "大港区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120109",
                    "fullNames": "天津市,大港区",
                    "children": []
                },
                {
                    "id": "120110",
                    "code": "120110",
                    "name": "东丽区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120110",
                    "fullNames": "天津市,东丽区",
                    "children": []
                },
                {
                    "id": "120111",
                    "code": "120111",
                    "name": "西青区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120111",
                    "fullNames": "天津市,西青区",
                    "children": []
                },
                {
                    "id": "120112",
                    "code": "120112",
                    "name": "津南区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120112",
                    "fullNames": "天津市,津南区",
                    "children": []
                },
                {
                    "id": "120113",
                    "code": "120113",
                    "name": "北辰区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120113",
                    "fullNames": "天津市,北辰区",
                    "children": []
                },
                {
                    "id": "120114",
                    "code": "120114",
                    "name": "武清区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120114",
                    "fullNames": "天津市,武清区",
                    "children": []
                },
                {
                    "id": "120115",
                    "code": "120115",
                    "name": "宝坻区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120115",
                    "fullNames": "天津市,宝坻区",
                    "children": []
                },
                {
                    "id": "120116",
                    "code": "120116",
                    "name": "滨海新区",
                    "parentCode": "120100",
                    "fullCodes": "120100,120116",
                    "fullNames": "天津市,滨海新区",
                    "children": []
                },
                {
                    "id": "120221",
                    "code": "120221",
                    "name": "宁河县",
                    "parentCode": "120100",
                    "fullCodes": "120100,120221",
                    "fullNames": "天津市,宁河县",
                    "children": []
                },
                {
                    "id": "120223",
                    "code": "120223",
                    "name": "静海县",
                    "parentCode": "120100",
                    "fullCodes": "120100,120223",
                    "fullNames": "天津市,静海县",
                    "children": []
                },
                {
                    "id": "120225",
                    "code": "120225",
                    "name": "蓟县",
                    "parentCode": "120100",
                    "fullCodes": "120100,120225",
                    "fullNames": "天津市,蓟县",
                    "children": []
                }
            ]
        },
        {
            "id": "130000",
            "code": "130000",
            "name": "河北省",
            "parentCode": "-1",
            "fullCodes": "130000",
            "fullNames": "河北省",
            "children": [
                {
                    "id": "130100",
                    "code": "130100",
                    "name": "石家庄市",
                    "parentCode": "130000",
                    "fullCodes": "130000,130100",
                    "fullNames": "河北省,石家庄市",
                    "children": [
                        {
                            "id": "130102",
                            "code": "130102",
                            "name": "长安区",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130102",
                            "fullNames": "河北省,石家庄市,长安区",
                            "children": []
                        },
                        {
                            "id": "130103",
                            "code": "130103",
                            "name": "桥东区",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130103",
                            "fullNames": "河北省,石家庄市,桥东区",
                            "children": []
                        },
                        {
                            "id": "130104",
                            "code": "130104",
                            "name": "桥西区",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130104",
                            "fullNames": "河北省,石家庄市,桥西区",
                            "children": []
                        },
                        {
                            "id": "130105",
                            "code": "130105",
                            "name": "新华区",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130105",
                            "fullNames": "河北省,石家庄市,新华区",
                            "children": []
                        },
                        {
                            "id": "130107",
                            "code": "130107",
                            "name": "井陉矿区",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130107",
                            "fullNames": "河北省,石家庄市,井陉矿区",
                            "children": []
                        },
                        {
                            "id": "130108",
                            "code": "130108",
                            "name": "裕华区",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130108",
                            "fullNames": "河北省,石家庄市,裕华区",
                            "children": []
                        },
                        {
                            "id": "130121",
                            "code": "130121",
                            "name": "井陉县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130121",
                            "fullNames": "河北省,石家庄市,井陉县",
                            "children": []
                        },
                        {
                            "id": "130123",
                            "code": "130123",
                            "name": "正定县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130123",
                            "fullNames": "河北省,石家庄市,正定县",
                            "children": []
                        },
                        {
                            "id": "130124",
                            "code": "130124",
                            "name": "栾城县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130124",
                            "fullNames": "河北省,石家庄市,栾城县",
                            "children": []
                        },
                        {
                            "id": "130125",
                            "code": "130125",
                            "name": "行唐县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130125",
                            "fullNames": "河北省,石家庄市,行唐县",
                            "children": []
                        },
                        {
                            "id": "130126",
                            "code": "130126",
                            "name": "灵寿县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130126",
                            "fullNames": "河北省,石家庄市,灵寿县",
                            "children": []
                        },
                        {
                            "id": "130127",
                            "code": "130127",
                            "name": "高邑县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130127",
                            "fullNames": "河北省,石家庄市,高邑县",
                            "children": []
                        },
                        {
                            "id": "130128",
                            "code": "130128",
                            "name": "深泽县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130128",
                            "fullNames": "河北省,石家庄市,深泽县",
                            "children": []
                        },
                        {
                            "id": "130129",
                            "code": "130129",
                            "name": "赞皇县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130129",
                            "fullNames": "河北省,石家庄市,赞皇县",
                            "children": []
                        },
                        {
                            "id": "130130",
                            "code": "130130",
                            "name": "无极县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130130",
                            "fullNames": "河北省,石家庄市,无极县",
                            "children": []
                        },
                        {
                            "id": "130131",
                            "code": "130131",
                            "name": "平山县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130131",
                            "fullNames": "河北省,石家庄市,平山县",
                            "children": []
                        },
                        {
                            "id": "130132",
                            "code": "130132",
                            "name": "元氏县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130132",
                            "fullNames": "河北省,石家庄市,元氏县",
                            "children": []
                        },
                        {
                            "id": "130133",
                            "code": "130133",
                            "name": "赵县",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130133",
                            "fullNames": "河北省,石家庄市,赵县",
                            "children": []
                        },
                        {
                            "id": "130181",
                            "code": "130181",
                            "name": "辛集市",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130181",
                            "fullNames": "河北省,石家庄市,辛集市",
                            "children": []
                        },
                        {
                            "id": "130182",
                            "code": "130182",
                            "name": "藁城市",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130182",
                            "fullNames": "河北省,石家庄市,藁城市",
                            "children": []
                        },
                        {
                            "id": "130183",
                            "code": "130183",
                            "name": "晋州市",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130183",
                            "fullNames": "河北省,石家庄市,晋州市",
                            "children": []
                        },
                        {
                            "id": "130184",
                            "code": "130184",
                            "name": "新乐市",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130184",
                            "fullNames": "河北省,石家庄市,新乐市",
                            "children": []
                        },
                        {
                            "id": "130185",
                            "code": "130185",
                            "name": "鹿泉市",
                            "parentCode": "130100",
                            "fullCodes": "130000,130100,130185",
                            "fullNames": "河北省,石家庄市,鹿泉市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "130200",
                    "code": "130200",
                    "name": "唐山市",
                    "parentCode": "130000",
                    "fullCodes": "130000,130200",
                    "fullNames": "河北省,唐山市",
                    "children": [
                        {
                            "id": "130202",
                            "code": "130202",
                            "name": "路南区",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130202",
                            "fullNames": "河北省,唐山市,路南区",
                            "children": []
                        },
                        {
                            "id": "130203",
                            "code": "130203",
                            "name": "路北区",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130203",
                            "fullNames": "河北省,唐山市,路北区",
                            "children": []
                        },
                        {
                            "id": "130204",
                            "code": "130204",
                            "name": "古冶区",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130204",
                            "fullNames": "河北省,唐山市,古冶区",
                            "children": []
                        },
                        {
                            "id": "130205",
                            "code": "130205",
                            "name": "开平区",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130205",
                            "fullNames": "河北省,唐山市,开平区",
                            "children": []
                        },
                        {
                            "id": "130207",
                            "code": "130207",
                            "name": "丰南区",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130207",
                            "fullNames": "河北省,唐山市,丰南区",
                            "children": []
                        },
                        {
                            "id": "130208",
                            "code": "130208",
                            "name": "丰润区",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130208",
                            "fullNames": "河北省,唐山市,丰润区",
                            "children": []
                        },
                        {
                            "id": "130223",
                            "code": "130223",
                            "name": "滦县",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130223",
                            "fullNames": "河北省,唐山市,滦县",
                            "children": []
                        },
                        {
                            "id": "130224",
                            "code": "130224",
                            "name": "滦南县",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130224",
                            "fullNames": "河北省,唐山市,滦南县",
                            "children": []
                        },
                        {
                            "id": "130225",
                            "code": "130225",
                            "name": "乐亭县",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130225",
                            "fullNames": "河北省,唐山市,乐亭县",
                            "children": []
                        },
                        {
                            "id": "130227",
                            "code": "130227",
                            "name": "迁西县",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130227",
                            "fullNames": "河北省,唐山市,迁西县",
                            "children": []
                        },
                        {
                            "id": "130229",
                            "code": "130229",
                            "name": "玉田县",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130229",
                            "fullNames": "河北省,唐山市,玉田县",
                            "children": []
                        },
                        {
                            "id": "130230",
                            "code": "130230",
                            "name": "唐海县",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130230",
                            "fullNames": "河北省,唐山市,唐海县",
                            "children": []
                        },
                        {
                            "id": "130281",
                            "code": "130281",
                            "name": "遵化市",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130281",
                            "fullNames": "河北省,唐山市,遵化市",
                            "children": []
                        },
                        {
                            "id": "130283",
                            "code": "130283",
                            "name": "迁安市",
                            "parentCode": "130200",
                            "fullCodes": "130000,130200,130283",
                            "fullNames": "河北省,唐山市,迁安市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "130300",
                    "code": "130300",
                    "name": "秦皇岛市",
                    "parentCode": "130000",
                    "fullCodes": "130000,130300",
                    "fullNames": "河北省,秦皇岛市",
                    "children": [
                        {
                            "id": "130302",
                            "code": "130302",
                            "name": "海港区",
                            "parentCode": "130300",
                            "fullCodes": "130000,130300,130302",
                            "fullNames": "河北省,秦皇岛市,海港区",
                            "children": []
                        },
                        {
                            "id": "130303",
                            "code": "130303",
                            "name": "山海关区",
                            "parentCode": "130300",
                            "fullCodes": "130000,130300,130303",
                            "fullNames": "河北省,秦皇岛市,山海关区",
                            "children": []
                        },
                        {
                            "id": "130304",
                            "code": "130304",
                            "name": "北戴河区",
                            "parentCode": "130300",
                            "fullCodes": "130000,130300,130304",
                            "fullNames": "河北省,秦皇岛市,北戴河区",
                            "children": []
                        },
                        {
                            "id": "130321",
                            "code": "130321",
                            "name": "青龙满族自治县",
                            "parentCode": "130300",
                            "fullCodes": "130000,130300,130321",
                            "fullNames": "河北省,秦皇岛市,青龙满族自治县",
                            "children": []
                        },
                        {
                            "id": "130322",
                            "code": "130322",
                            "name": "昌黎县",
                            "parentCode": "130300",
                            "fullCodes": "130000,130300,130322",
                            "fullNames": "河北省,秦皇岛市,昌黎县",
                            "children": []
                        },
                        {
                            "id": "130323",
                            "code": "130323",
                            "name": "抚宁县",
                            "parentCode": "130300",
                            "fullCodes": "130000,130300,130323",
                            "fullNames": "河北省,秦皇岛市,抚宁县",
                            "children": []
                        },
                        {
                            "id": "130324",
                            "code": "130324",
                            "name": "卢龙县",
                            "parentCode": "130300",
                            "fullCodes": "130000,130300,130324",
                            "fullNames": "河北省,秦皇岛市,卢龙县",
                            "children": []
                        },
                        {
                            "id": "130399",
                            "code": "130399",
                            "name": "经济技术开发区",
                            "parentCode": "130300",
                            "fullCodes": "130000,130300,130399",
                            "fullNames": "河北省,秦皇岛市,经济技术开发区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "130400",
                    "code": "130400",
                    "name": "邯郸市",
                    "parentCode": "130000",
                    "fullCodes": "130000,130400",
                    "fullNames": "河北省,邯郸市",
                    "children": [
                        {
                            "id": "130402",
                            "code": "130402",
                            "name": "邯山区",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130402",
                            "fullNames": "河北省,邯郸市,邯山区",
                            "children": []
                        },
                        {
                            "id": "130403",
                            "code": "130403",
                            "name": "丛台区",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130403",
                            "fullNames": "河北省,邯郸市,丛台区",
                            "children": []
                        },
                        {
                            "id": "130404",
                            "code": "130404",
                            "name": "复兴区",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130404",
                            "fullNames": "河北省,邯郸市,复兴区",
                            "children": []
                        },
                        {
                            "id": "130406",
                            "code": "130406",
                            "name": "峰峰矿区",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130406",
                            "fullNames": "河北省,邯郸市,峰峰矿区",
                            "children": []
                        },
                        {
                            "id": "130421",
                            "code": "130421",
                            "name": "邯郸县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130421",
                            "fullNames": "河北省,邯郸市,邯郸县",
                            "children": []
                        },
                        {
                            "id": "130423",
                            "code": "130423",
                            "name": "临漳县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130423",
                            "fullNames": "河北省,邯郸市,临漳县",
                            "children": []
                        },
                        {
                            "id": "130424",
                            "code": "130424",
                            "name": "成安县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130424",
                            "fullNames": "河北省,邯郸市,成安县",
                            "children": []
                        },
                        {
                            "id": "130425",
                            "code": "130425",
                            "name": "大名县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130425",
                            "fullNames": "河北省,邯郸市,大名县",
                            "children": []
                        },
                        {
                            "id": "130426",
                            "code": "130426",
                            "name": "涉县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130426",
                            "fullNames": "河北省,邯郸市,涉县",
                            "children": []
                        },
                        {
                            "id": "130427",
                            "code": "130427",
                            "name": "磁县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130427",
                            "fullNames": "河北省,邯郸市,磁县",
                            "children": []
                        },
                        {
                            "id": "130428",
                            "code": "130428",
                            "name": "肥乡县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130428",
                            "fullNames": "河北省,邯郸市,肥乡县",
                            "children": []
                        },
                        {
                            "id": "130429",
                            "code": "130429",
                            "name": "永年县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130429",
                            "fullNames": "河北省,邯郸市,永年县",
                            "children": []
                        },
                        {
                            "id": "130430",
                            "code": "130430",
                            "name": "邱县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130430",
                            "fullNames": "河北省,邯郸市,邱县",
                            "children": []
                        },
                        {
                            "id": "130431",
                            "code": "130431",
                            "name": "鸡泽县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130431",
                            "fullNames": "河北省,邯郸市,鸡泽县",
                            "children": []
                        },
                        {
                            "id": "130432",
                            "code": "130432",
                            "name": "广平县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130432",
                            "fullNames": "河北省,邯郸市,广平县",
                            "children": []
                        },
                        {
                            "id": "130433",
                            "code": "130433",
                            "name": "馆陶县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130433",
                            "fullNames": "河北省,邯郸市,馆陶县",
                            "children": []
                        },
                        {
                            "id": "130434",
                            "code": "130434",
                            "name": "魏县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130434",
                            "fullNames": "河北省,邯郸市,魏县",
                            "children": []
                        },
                        {
                            "id": "130435",
                            "code": "130435",
                            "name": "曲周县",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130435",
                            "fullNames": "河北省,邯郸市,曲周县",
                            "children": []
                        },
                        {
                            "id": "130481",
                            "code": "130481",
                            "name": "武安市",
                            "parentCode": "130400",
                            "fullCodes": "130000,130400,130481",
                            "fullNames": "河北省,邯郸市,武安市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "130500",
                    "code": "130500",
                    "name": "邢台市",
                    "parentCode": "130000",
                    "fullCodes": "130000,130500",
                    "fullNames": "河北省,邢台市",
                    "children": [
                        {
                            "id": "130502",
                            "code": "130502",
                            "name": "桥东区",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130502",
                            "fullNames": "河北省,邢台市,桥东区",
                            "children": []
                        },
                        {
                            "id": "130503",
                            "code": "130503",
                            "name": "桥西区",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130503",
                            "fullNames": "河北省,邢台市,桥西区",
                            "children": []
                        },
                        {
                            "id": "130521",
                            "code": "130521",
                            "name": "邢台县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130521",
                            "fullNames": "河北省,邢台市,邢台县",
                            "children": []
                        },
                        {
                            "id": "130522",
                            "code": "130522",
                            "name": "临城县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130522",
                            "fullNames": "河北省,邢台市,临城县",
                            "children": []
                        },
                        {
                            "id": "130523",
                            "code": "130523",
                            "name": "内丘县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130523",
                            "fullNames": "河北省,邢台市,内丘县",
                            "children": []
                        },
                        {
                            "id": "130524",
                            "code": "130524",
                            "name": "柏乡县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130524",
                            "fullNames": "河北省,邢台市,柏乡县",
                            "children": []
                        },
                        {
                            "id": "130525",
                            "code": "130525",
                            "name": "隆尧县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130525",
                            "fullNames": "河北省,邢台市,隆尧县",
                            "children": []
                        },
                        {
                            "id": "130526",
                            "code": "130526",
                            "name": "任县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130526",
                            "fullNames": "河北省,邢台市,任县",
                            "children": []
                        },
                        {
                            "id": "130527",
                            "code": "130527",
                            "name": "南和县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130527",
                            "fullNames": "河北省,邢台市,南和县",
                            "children": []
                        },
                        {
                            "id": "130528",
                            "code": "130528",
                            "name": "宁晋县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130528",
                            "fullNames": "河北省,邢台市,宁晋县",
                            "children": []
                        },
                        {
                            "id": "130529",
                            "code": "130529",
                            "name": "巨鹿县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130529",
                            "fullNames": "河北省,邢台市,巨鹿县",
                            "children": []
                        },
                        {
                            "id": "130530",
                            "code": "130530",
                            "name": "新河县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130530",
                            "fullNames": "河北省,邢台市,新河县",
                            "children": []
                        },
                        {
                            "id": "130531",
                            "code": "130531",
                            "name": "广宗县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130531",
                            "fullNames": "河北省,邢台市,广宗县",
                            "children": []
                        },
                        {
                            "id": "130532",
                            "code": "130532",
                            "name": "平乡县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130532",
                            "fullNames": "河北省,邢台市,平乡县",
                            "children": []
                        },
                        {
                            "id": "130533",
                            "code": "130533",
                            "name": "威县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130533",
                            "fullNames": "河北省,邢台市,威县",
                            "children": []
                        },
                        {
                            "id": "130534",
                            "code": "130534",
                            "name": "清河县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130534",
                            "fullNames": "河北省,邢台市,清河县",
                            "children": []
                        },
                        {
                            "id": "130535",
                            "code": "130535",
                            "name": "临西县",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130535",
                            "fullNames": "河北省,邢台市,临西县",
                            "children": []
                        },
                        {
                            "id": "130581",
                            "code": "130581",
                            "name": "南宫市",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130581",
                            "fullNames": "河北省,邢台市,南宫市",
                            "children": []
                        },
                        {
                            "id": "130582",
                            "code": "130582",
                            "name": "沙河市",
                            "parentCode": "130500",
                            "fullCodes": "130000,130500,130582",
                            "fullNames": "河北省,邢台市,沙河市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "130600",
                    "code": "130600",
                    "name": "保定市",
                    "parentCode": "130000",
                    "fullCodes": "130000,130600",
                    "fullNames": "河北省,保定市",
                    "children": [
                        {
                            "id": "130602",
                            "code": "130602",
                            "name": "新市区",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130602",
                            "fullNames": "河北省,保定市,新市区",
                            "children": []
                        },
                        {
                            "id": "130603",
                            "code": "130603",
                            "name": "北市区",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130603",
                            "fullNames": "河北省,保定市,北市区",
                            "children": []
                        },
                        {
                            "id": "130604",
                            "code": "130604",
                            "name": "南市区",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130604",
                            "fullNames": "河北省,保定市,南市区",
                            "children": []
                        },
                        {
                            "id": "130621",
                            "code": "130621",
                            "name": "满城县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130621",
                            "fullNames": "河北省,保定市,满城县",
                            "children": []
                        },
                        {
                            "id": "130622",
                            "code": "130622",
                            "name": "清苑县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130622",
                            "fullNames": "河北省,保定市,清苑县",
                            "children": []
                        },
                        {
                            "id": "130623",
                            "code": "130623",
                            "name": "涞水县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130623",
                            "fullNames": "河北省,保定市,涞水县",
                            "children": []
                        },
                        {
                            "id": "130624",
                            "code": "130624",
                            "name": "阜平县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130624",
                            "fullNames": "河北省,保定市,阜平县",
                            "children": []
                        },
                        {
                            "id": "130625",
                            "code": "130625",
                            "name": "徐水县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130625",
                            "fullNames": "河北省,保定市,徐水县",
                            "children": []
                        },
                        {
                            "id": "130626",
                            "code": "130626",
                            "name": "定兴县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130626",
                            "fullNames": "河北省,保定市,定兴县",
                            "children": []
                        },
                        {
                            "id": "130627",
                            "code": "130627",
                            "name": "唐县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130627",
                            "fullNames": "河北省,保定市,唐县",
                            "children": []
                        },
                        {
                            "id": "130628",
                            "code": "130628",
                            "name": "高阳县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130628",
                            "fullNames": "河北省,保定市,高阳县",
                            "children": []
                        },
                        {
                            "id": "130629",
                            "code": "130629",
                            "name": "容城县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130629",
                            "fullNames": "河北省,保定市,容城县",
                            "children": []
                        },
                        {
                            "id": "130630",
                            "code": "130630",
                            "name": "涞源县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130630",
                            "fullNames": "河北省,保定市,涞源县",
                            "children": []
                        },
                        {
                            "id": "130631",
                            "code": "130631",
                            "name": "望都县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130631",
                            "fullNames": "河北省,保定市,望都县",
                            "children": []
                        },
                        {
                            "id": "130632",
                            "code": "130632",
                            "name": "安新县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130632",
                            "fullNames": "河北省,保定市,安新县",
                            "children": []
                        },
                        {
                            "id": "130633",
                            "code": "130633",
                            "name": "易县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130633",
                            "fullNames": "河北省,保定市,易县",
                            "children": []
                        },
                        {
                            "id": "130634",
                            "code": "130634",
                            "name": "曲阳县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130634",
                            "fullNames": "河北省,保定市,曲阳县",
                            "children": []
                        },
                        {
                            "id": "130635",
                            "code": "130635",
                            "name": "蠡县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130635",
                            "fullNames": "河北省,保定市,蠡县",
                            "children": []
                        },
                        {
                            "id": "130636",
                            "code": "130636",
                            "name": "顺平县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130636",
                            "fullNames": "河北省,保定市,顺平县",
                            "children": []
                        },
                        {
                            "id": "130637",
                            "code": "130637",
                            "name": "博野县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130637",
                            "fullNames": "河北省,保定市,博野县",
                            "children": []
                        },
                        {
                            "id": "130638",
                            "code": "130638",
                            "name": "雄县",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130638",
                            "fullNames": "河北省,保定市,雄县",
                            "children": []
                        },
                        {
                            "id": "130681",
                            "code": "130681",
                            "name": "涿州市",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130681",
                            "fullNames": "河北省,保定市,涿州市",
                            "children": []
                        },
                        {
                            "id": "130682",
                            "code": "130682",
                            "name": "定州市",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130682",
                            "fullNames": "河北省,保定市,定州市",
                            "children": []
                        },
                        {
                            "id": "130683",
                            "code": "130683",
                            "name": "安国市",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130683",
                            "fullNames": "河北省,保定市,安国市",
                            "children": []
                        },
                        {
                            "id": "130684",
                            "code": "130684",
                            "name": "高碑店市",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130684",
                            "fullNames": "河北省,保定市,高碑店市",
                            "children": []
                        },
                        {
                            "id": "130698",
                            "code": "130698",
                            "name": "高开区",
                            "parentCode": "130600",
                            "fullCodes": "130000,130600,130698",
                            "fullNames": "河北省,保定市,高开区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "130700",
                    "code": "130700",
                    "name": "张家口市",
                    "parentCode": "130000",
                    "fullCodes": "130000,130700",
                    "fullNames": "河北省,张家口市",
                    "children": [
                        {
                            "id": "130702",
                            "code": "130702",
                            "name": "桥东区",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130702",
                            "fullNames": "河北省,张家口市,桥东区",
                            "children": []
                        },
                        {
                            "id": "130703",
                            "code": "130703",
                            "name": "桥西区",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130703",
                            "fullNames": "河北省,张家口市,桥西区",
                            "children": []
                        },
                        {
                            "id": "130705",
                            "code": "130705",
                            "name": "宣化区",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130705",
                            "fullNames": "河北省,张家口市,宣化区",
                            "children": []
                        },
                        {
                            "id": "130706",
                            "code": "130706",
                            "name": "下花园区",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130706",
                            "fullNames": "河北省,张家口市,下花园区",
                            "children": []
                        },
                        {
                            "id": "130721",
                            "code": "130721",
                            "name": "宣化县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130721",
                            "fullNames": "河北省,张家口市,宣化县",
                            "children": []
                        },
                        {
                            "id": "130722",
                            "code": "130722",
                            "name": "张北县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130722",
                            "fullNames": "河北省,张家口市,张北县",
                            "children": []
                        },
                        {
                            "id": "130723",
                            "code": "130723",
                            "name": "康保县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130723",
                            "fullNames": "河北省,张家口市,康保县",
                            "children": []
                        },
                        {
                            "id": "130724",
                            "code": "130724",
                            "name": "沽源县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130724",
                            "fullNames": "河北省,张家口市,沽源县",
                            "children": []
                        },
                        {
                            "id": "130725",
                            "code": "130725",
                            "name": "尚义县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130725",
                            "fullNames": "河北省,张家口市,尚义县",
                            "children": []
                        },
                        {
                            "id": "130726",
                            "code": "130726",
                            "name": "蔚县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130726",
                            "fullNames": "河北省,张家口市,蔚县",
                            "children": []
                        },
                        {
                            "id": "130727",
                            "code": "130727",
                            "name": "阳原县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130727",
                            "fullNames": "河北省,张家口市,阳原县",
                            "children": []
                        },
                        {
                            "id": "130728",
                            "code": "130728",
                            "name": "怀安县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130728",
                            "fullNames": "河北省,张家口市,怀安县",
                            "children": []
                        },
                        {
                            "id": "130729",
                            "code": "130729",
                            "name": "万全县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130729",
                            "fullNames": "河北省,张家口市,万全县",
                            "children": []
                        },
                        {
                            "id": "130730",
                            "code": "130730",
                            "name": "怀来县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130730",
                            "fullNames": "河北省,张家口市,怀来县",
                            "children": []
                        },
                        {
                            "id": "130731",
                            "code": "130731",
                            "name": "涿鹿县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130731",
                            "fullNames": "河北省,张家口市,涿鹿县",
                            "children": []
                        },
                        {
                            "id": "130732",
                            "code": "130732",
                            "name": "赤城县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130732",
                            "fullNames": "河北省,张家口市,赤城县",
                            "children": []
                        },
                        {
                            "id": "130733",
                            "code": "130733",
                            "name": "崇礼县",
                            "parentCode": "130700",
                            "fullCodes": "130000,130700,130733",
                            "fullNames": "河北省,张家口市,崇礼县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "130800",
                    "code": "130800",
                    "name": "承德市",
                    "parentCode": "130000",
                    "fullCodes": "130000,130800",
                    "fullNames": "河北省,承德市",
                    "children": [
                        {
                            "id": "130802",
                            "code": "130802",
                            "name": "双桥区",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130802",
                            "fullNames": "河北省,承德市,双桥区",
                            "children": []
                        },
                        {
                            "id": "130803",
                            "code": "130803",
                            "name": "双滦区",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130803",
                            "fullNames": "河北省,承德市,双滦区",
                            "children": []
                        },
                        {
                            "id": "130804",
                            "code": "130804",
                            "name": "鹰手营子矿区",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130804",
                            "fullNames": "河北省,承德市,鹰手营子矿区",
                            "children": []
                        },
                        {
                            "id": "130821",
                            "code": "130821",
                            "name": "承德县",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130821",
                            "fullNames": "河北省,承德市,承德县",
                            "children": []
                        },
                        {
                            "id": "130822",
                            "code": "130822",
                            "name": "兴隆县",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130822",
                            "fullNames": "河北省,承德市,兴隆县",
                            "children": []
                        },
                        {
                            "id": "130823",
                            "code": "130823",
                            "name": "平泉县",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130823",
                            "fullNames": "河北省,承德市,平泉县",
                            "children": []
                        },
                        {
                            "id": "130824",
                            "code": "130824",
                            "name": "滦平县",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130824",
                            "fullNames": "河北省,承德市,滦平县",
                            "children": []
                        },
                        {
                            "id": "130825",
                            "code": "130825",
                            "name": "隆化县",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130825",
                            "fullNames": "河北省,承德市,隆化县",
                            "children": []
                        },
                        {
                            "id": "130826",
                            "code": "130826",
                            "name": "丰宁满族自治县",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130826",
                            "fullNames": "河北省,承德市,丰宁满族自治县",
                            "children": []
                        },
                        {
                            "id": "130827",
                            "code": "130827",
                            "name": "宽城满族自治县",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130827",
                            "fullNames": "河北省,承德市,宽城满族自治县",
                            "children": []
                        },
                        {
                            "id": "130828",
                            "code": "130828",
                            "name": "围场满族蒙古族自治县",
                            "parentCode": "130800",
                            "fullCodes": "130000,130800,130828",
                            "fullNames": "河北省,承德市,围场满族蒙古族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "130900",
                    "code": "130900",
                    "name": "沧州市",
                    "parentCode": "130000",
                    "fullCodes": "130000,130900",
                    "fullNames": "河北省,沧州市",
                    "children": [
                        {
                            "id": "130902",
                            "code": "130902",
                            "name": "新华区",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130902",
                            "fullNames": "河北省,沧州市,新华区",
                            "children": []
                        },
                        {
                            "id": "130903",
                            "code": "130903",
                            "name": "运河区",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130903",
                            "fullNames": "河北省,沧州市,运河区",
                            "children": []
                        },
                        {
                            "id": "130921",
                            "code": "130921",
                            "name": "沧县",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130921",
                            "fullNames": "河北省,沧州市,沧县",
                            "children": []
                        },
                        {
                            "id": "130922",
                            "code": "130922",
                            "name": "青县",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130922",
                            "fullNames": "河北省,沧州市,青县",
                            "children": []
                        },
                        {
                            "id": "130923",
                            "code": "130923",
                            "name": "东光县",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130923",
                            "fullNames": "河北省,沧州市,东光县",
                            "children": []
                        },
                        {
                            "id": "130924",
                            "code": "130924",
                            "name": "海兴县",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130924",
                            "fullNames": "河北省,沧州市,海兴县",
                            "children": []
                        },
                        {
                            "id": "130925",
                            "code": "130925",
                            "name": "盐山县",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130925",
                            "fullNames": "河北省,沧州市,盐山县",
                            "children": []
                        },
                        {
                            "id": "130926",
                            "code": "130926",
                            "name": "肃宁县",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130926",
                            "fullNames": "河北省,沧州市,肃宁县",
                            "children": []
                        },
                        {
                            "id": "130927",
                            "code": "130927",
                            "name": "南皮县",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130927",
                            "fullNames": "河北省,沧州市,南皮县",
                            "children": []
                        },
                        {
                            "id": "130928",
                            "code": "130928",
                            "name": "吴桥县",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130928",
                            "fullNames": "河北省,沧州市,吴桥县",
                            "children": []
                        },
                        {
                            "id": "130929",
                            "code": "130929",
                            "name": "献县",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130929",
                            "fullNames": "河北省,沧州市,献县",
                            "children": []
                        },
                        {
                            "id": "130930",
                            "code": "130930",
                            "name": "孟村回族自治县",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130930",
                            "fullNames": "河北省,沧州市,孟村回族自治县",
                            "children": []
                        },
                        {
                            "id": "130981",
                            "code": "130981",
                            "name": "泊头市",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130981",
                            "fullNames": "河北省,沧州市,泊头市",
                            "children": []
                        },
                        {
                            "id": "130982",
                            "code": "130982",
                            "name": "任丘市",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130982",
                            "fullNames": "河北省,沧州市,任丘市",
                            "children": []
                        },
                        {
                            "id": "130983",
                            "code": "130983",
                            "name": "黄骅市",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130983",
                            "fullNames": "河北省,沧州市,黄骅市",
                            "children": []
                        },
                        {
                            "id": "130984",
                            "code": "130984",
                            "name": "河间市",
                            "parentCode": "130900",
                            "fullCodes": "130000,130900,130984",
                            "fullNames": "河北省,沧州市,河间市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "131000",
                    "code": "131000",
                    "name": "廊坊市",
                    "parentCode": "130000",
                    "fullCodes": "130000,131000",
                    "fullNames": "河北省,廊坊市",
                    "children": [
                        {
                            "id": "131002",
                            "code": "131002",
                            "name": "安次区",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131002",
                            "fullNames": "河北省,廊坊市,安次区",
                            "children": []
                        },
                        {
                            "id": "131003",
                            "code": "131003",
                            "name": "广阳区",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131003",
                            "fullNames": "河北省,廊坊市,广阳区",
                            "children": []
                        },
                        {
                            "id": "131022",
                            "code": "131022",
                            "name": "固安县",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131022",
                            "fullNames": "河北省,廊坊市,固安县",
                            "children": []
                        },
                        {
                            "id": "131023",
                            "code": "131023",
                            "name": "永清县",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131023",
                            "fullNames": "河北省,廊坊市,永清县",
                            "children": []
                        },
                        {
                            "id": "131024",
                            "code": "131024",
                            "name": "香河县",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131024",
                            "fullNames": "河北省,廊坊市,香河县",
                            "children": []
                        },
                        {
                            "id": "131025",
                            "code": "131025",
                            "name": "大城县",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131025",
                            "fullNames": "河北省,廊坊市,大城县",
                            "children": []
                        },
                        {
                            "id": "131026",
                            "code": "131026",
                            "name": "文安县",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131026",
                            "fullNames": "河北省,廊坊市,文安县",
                            "children": []
                        },
                        {
                            "id": "131028",
                            "code": "131028",
                            "name": "大厂回族自治县",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131028",
                            "fullNames": "河北省,廊坊市,大厂回族自治县",
                            "children": []
                        },
                        {
                            "id": "131051",
                            "code": "131051",
                            "name": "开发区",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131051",
                            "fullNames": "河北省,廊坊市,开发区",
                            "children": []
                        },
                        {
                            "id": "131052",
                            "code": "131052",
                            "name": "燕郊经济技术开发区",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131052",
                            "fullNames": "河北省,廊坊市,燕郊经济技术开发区",
                            "children": []
                        },
                        {
                            "id": "131081",
                            "code": "131081",
                            "name": "霸州市",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131081",
                            "fullNames": "河北省,廊坊市,霸州市",
                            "children": []
                        },
                        {
                            "id": "131082",
                            "code": "131082",
                            "name": "三河市",
                            "parentCode": "131000",
                            "fullCodes": "130000,131000,131082",
                            "fullNames": "河北省,廊坊市,三河市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "131100",
                    "code": "131100",
                    "name": "衡水市",
                    "parentCode": "130000",
                    "fullCodes": "130000,131100",
                    "fullNames": "河北省,衡水市",
                    "children": [
                        {
                            "id": "131102",
                            "code": "131102",
                            "name": "桃城区",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131102",
                            "fullNames": "河北省,衡水市,桃城区",
                            "children": []
                        },
                        {
                            "id": "131121",
                            "code": "131121",
                            "name": "枣强县",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131121",
                            "fullNames": "河北省,衡水市,枣强县",
                            "children": []
                        },
                        {
                            "id": "131122",
                            "code": "131122",
                            "name": "武邑县",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131122",
                            "fullNames": "河北省,衡水市,武邑县",
                            "children": []
                        },
                        {
                            "id": "131123",
                            "code": "131123",
                            "name": "武强县",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131123",
                            "fullNames": "河北省,衡水市,武强县",
                            "children": []
                        },
                        {
                            "id": "131124",
                            "code": "131124",
                            "name": "饶阳县",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131124",
                            "fullNames": "河北省,衡水市,饶阳县",
                            "children": []
                        },
                        {
                            "id": "131125",
                            "code": "131125",
                            "name": "安平县",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131125",
                            "fullNames": "河北省,衡水市,安平县",
                            "children": []
                        },
                        {
                            "id": "131126",
                            "code": "131126",
                            "name": "故城县",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131126",
                            "fullNames": "河北省,衡水市,故城县",
                            "children": []
                        },
                        {
                            "id": "131127",
                            "code": "131127",
                            "name": "景县",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131127",
                            "fullNames": "河北省,衡水市,景县",
                            "children": []
                        },
                        {
                            "id": "131128",
                            "code": "131128",
                            "name": "阜城县",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131128",
                            "fullNames": "河北省,衡水市,阜城县",
                            "children": []
                        },
                        {
                            "id": "131181",
                            "code": "131181",
                            "name": "冀州市",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131181",
                            "fullNames": "河北省,衡水市,冀州市",
                            "children": []
                        },
                        {
                            "id": "131182",
                            "code": "131182",
                            "name": "深州市",
                            "parentCode": "131100",
                            "fullCodes": "130000,131100,131182",
                            "fullNames": "河北省,衡水市,深州市",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "140000",
            "code": "140000",
            "name": "山西省",
            "parentCode": "-1",
            "fullCodes": "140000",
            "fullNames": "山西省",
            "children": [
                {
                    "id": "140100",
                    "code": "140100",
                    "name": "太原市",
                    "parentCode": "140000",
                    "fullCodes": "140000,140100",
                    "fullNames": "山西省,太原市",
                    "children": [
                        {
                            "id": "140105",
                            "code": "140105",
                            "name": "小店区",
                            "parentCode": "140100",
                            "fullCodes": "140000,140100,140105",
                            "fullNames": "山西省,太原市,小店区",
                            "children": []
                        },
                        {
                            "id": "140106",
                            "code": "140106",
                            "name": "迎泽区",
                            "parentCode": "140100",
                            "fullCodes": "140000,140100,140106",
                            "fullNames": "山西省,太原市,迎泽区",
                            "children": []
                        },
                        {
                            "id": "140107",
                            "code": "140107",
                            "name": "杏花岭区",
                            "parentCode": "140100",
                            "fullCodes": "140000,140100,140107",
                            "fullNames": "山西省,太原市,杏花岭区",
                            "children": []
                        },
                        {
                            "id": "140108",
                            "code": "140108",
                            "name": "尖草坪区",
                            "parentCode": "140100",
                            "fullCodes": "140000,140100,140108",
                            "fullNames": "山西省,太原市,尖草坪区",
                            "children": []
                        },
                        {
                            "id": "140109",
                            "code": "140109",
                            "name": "万柏林区",
                            "parentCode": "140100",
                            "fullCodes": "140000,140100,140109",
                            "fullNames": "山西省,太原市,万柏林区",
                            "children": []
                        },
                        {
                            "id": "140110",
                            "code": "140110",
                            "name": "晋源区",
                            "parentCode": "140100",
                            "fullCodes": "140000,140100,140110",
                            "fullNames": "山西省,太原市,晋源区",
                            "children": []
                        },
                        {
                            "id": "140121",
                            "code": "140121",
                            "name": "清徐县",
                            "parentCode": "140100",
                            "fullCodes": "140000,140100,140121",
                            "fullNames": "山西省,太原市,清徐县",
                            "children": []
                        },
                        {
                            "id": "140122",
                            "code": "140122",
                            "name": "阳曲县",
                            "parentCode": "140100",
                            "fullCodes": "140000,140100,140122",
                            "fullNames": "山西省,太原市,阳曲县",
                            "children": []
                        },
                        {
                            "id": "140123",
                            "code": "140123",
                            "name": "娄烦县",
                            "parentCode": "140100",
                            "fullCodes": "140000,140100,140123",
                            "fullNames": "山西省,太原市,娄烦县",
                            "children": []
                        },
                        {
                            "id": "140181",
                            "code": "140181",
                            "name": "古交市",
                            "parentCode": "140100",
                            "fullCodes": "140000,140100,140181",
                            "fullNames": "山西省,太原市,古交市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "140200",
                    "code": "140200",
                    "name": "大同市",
                    "parentCode": "140000",
                    "fullCodes": "140000,140200",
                    "fullNames": "山西省,大同市",
                    "children": [
                        {
                            "id": "140202",
                            "code": "140202",
                            "name": "城区",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140202",
                            "fullNames": "山西省,大同市,城区",
                            "children": []
                        },
                        {
                            "id": "140203",
                            "code": "140203",
                            "name": "矿区",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140203",
                            "fullNames": "山西省,大同市,矿区",
                            "children": []
                        },
                        {
                            "id": "140211",
                            "code": "140211",
                            "name": "南郊区",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140211",
                            "fullNames": "山西省,大同市,南郊区",
                            "children": []
                        },
                        {
                            "id": "140212",
                            "code": "140212",
                            "name": "新荣区",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140212",
                            "fullNames": "山西省,大同市,新荣区",
                            "children": []
                        },
                        {
                            "id": "140221",
                            "code": "140221",
                            "name": "阳高县",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140221",
                            "fullNames": "山西省,大同市,阳高县",
                            "children": []
                        },
                        {
                            "id": "140222",
                            "code": "140222",
                            "name": "天镇县",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140222",
                            "fullNames": "山西省,大同市,天镇县",
                            "children": []
                        },
                        {
                            "id": "140223",
                            "code": "140223",
                            "name": "广灵县",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140223",
                            "fullNames": "山西省,大同市,广灵县",
                            "children": []
                        },
                        {
                            "id": "140224",
                            "code": "140224",
                            "name": "灵丘县",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140224",
                            "fullNames": "山西省,大同市,灵丘县",
                            "children": []
                        },
                        {
                            "id": "140225",
                            "code": "140225",
                            "name": "浑源县",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140225",
                            "fullNames": "山西省,大同市,浑源县",
                            "children": []
                        },
                        {
                            "id": "140226",
                            "code": "140226",
                            "name": "左云县",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140226",
                            "fullNames": "山西省,大同市,左云县",
                            "children": []
                        },
                        {
                            "id": "140227",
                            "code": "140227",
                            "name": "大同县",
                            "parentCode": "140200",
                            "fullCodes": "140000,140200,140227",
                            "fullNames": "山西省,大同市,大同县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "140300",
                    "code": "140300",
                    "name": "阳泉市",
                    "parentCode": "140000",
                    "fullCodes": "140000,140300",
                    "fullNames": "山西省,阳泉市",
                    "children": [
                        {
                            "id": "140302",
                            "code": "140302",
                            "name": "城区",
                            "parentCode": "140300",
                            "fullCodes": "140000,140300,140302",
                            "fullNames": "山西省,阳泉市,城区",
                            "children": []
                        },
                        {
                            "id": "140303",
                            "code": "140303",
                            "name": "矿区",
                            "parentCode": "140300",
                            "fullCodes": "140000,140300,140303",
                            "fullNames": "山西省,阳泉市,矿区",
                            "children": []
                        },
                        {
                            "id": "140311",
                            "code": "140311",
                            "name": "郊区",
                            "parentCode": "140300",
                            "fullCodes": "140000,140300,140311",
                            "fullNames": "山西省,阳泉市,郊区",
                            "children": []
                        },
                        {
                            "id": "140321",
                            "code": "140321",
                            "name": "平定县",
                            "parentCode": "140300",
                            "fullCodes": "140000,140300,140321",
                            "fullNames": "山西省,阳泉市,平定县",
                            "children": []
                        },
                        {
                            "id": "140322",
                            "code": "140322",
                            "name": "盂县",
                            "parentCode": "140300",
                            "fullCodes": "140000,140300,140322",
                            "fullNames": "山西省,阳泉市,盂县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "140400",
                    "code": "140400",
                    "name": "长治市",
                    "parentCode": "140000",
                    "fullCodes": "140000,140400",
                    "fullNames": "山西省,长治市",
                    "children": [
                        {
                            "id": "140421",
                            "code": "140421",
                            "name": "长治县",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140421",
                            "fullNames": "山西省,长治市,长治县",
                            "children": []
                        },
                        {
                            "id": "140423",
                            "code": "140423",
                            "name": "襄垣县",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140423",
                            "fullNames": "山西省,长治市,襄垣县",
                            "children": []
                        },
                        {
                            "id": "140424",
                            "code": "140424",
                            "name": "屯留县",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140424",
                            "fullNames": "山西省,长治市,屯留县",
                            "children": []
                        },
                        {
                            "id": "140425",
                            "code": "140425",
                            "name": "平顺县",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140425",
                            "fullNames": "山西省,长治市,平顺县",
                            "children": []
                        },
                        {
                            "id": "140426",
                            "code": "140426",
                            "name": "黎城县",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140426",
                            "fullNames": "山西省,长治市,黎城县",
                            "children": []
                        },
                        {
                            "id": "140427",
                            "code": "140427",
                            "name": "壶关县",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140427",
                            "fullNames": "山西省,长治市,壶关县",
                            "children": []
                        },
                        {
                            "id": "140428",
                            "code": "140428",
                            "name": "长子县",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140428",
                            "fullNames": "山西省,长治市,长子县",
                            "children": []
                        },
                        {
                            "id": "140429",
                            "code": "140429",
                            "name": "武乡县",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140429",
                            "fullNames": "山西省,长治市,武乡县",
                            "children": []
                        },
                        {
                            "id": "140430",
                            "code": "140430",
                            "name": "沁县",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140430",
                            "fullNames": "山西省,长治市,沁县",
                            "children": []
                        },
                        {
                            "id": "140431",
                            "code": "140431",
                            "name": "沁源县",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140431",
                            "fullNames": "山西省,长治市,沁源县",
                            "children": []
                        },
                        {
                            "id": "140481",
                            "code": "140481",
                            "name": "潞城市",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140481",
                            "fullNames": "山西省,长治市,潞城市",
                            "children": []
                        },
                        {
                            "id": "140482",
                            "code": "140482",
                            "name": "城区",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140482",
                            "fullNames": "山西省,长治市,城区",
                            "children": []
                        },
                        {
                            "id": "140483",
                            "code": "140483",
                            "name": "郊区",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140483",
                            "fullNames": "山西省,长治市,郊区",
                            "children": []
                        },
                        {
                            "id": "140484",
                            "code": "140484",
                            "name": "高新区",
                            "parentCode": "140400",
                            "fullCodes": "140000,140400,140484",
                            "fullNames": "山西省,长治市,高新区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "140500",
                    "code": "140500",
                    "name": "晋城市",
                    "parentCode": "140000",
                    "fullCodes": "140000,140500",
                    "fullNames": "山西省,晋城市",
                    "children": [
                        {
                            "id": "140502",
                            "code": "140502",
                            "name": "城区",
                            "parentCode": "140500",
                            "fullCodes": "140000,140500,140502",
                            "fullNames": "山西省,晋城市,城区",
                            "children": []
                        },
                        {
                            "id": "140521",
                            "code": "140521",
                            "name": "沁水县",
                            "parentCode": "140500",
                            "fullCodes": "140000,140500,140521",
                            "fullNames": "山西省,晋城市,沁水县",
                            "children": []
                        },
                        {
                            "id": "140522",
                            "code": "140522",
                            "name": "阳城县",
                            "parentCode": "140500",
                            "fullCodes": "140000,140500,140522",
                            "fullNames": "山西省,晋城市,阳城县",
                            "children": []
                        },
                        {
                            "id": "140524",
                            "code": "140524",
                            "name": "陵川县",
                            "parentCode": "140500",
                            "fullCodes": "140000,140500,140524",
                            "fullNames": "山西省,晋城市,陵川县",
                            "children": []
                        },
                        {
                            "id": "140525",
                            "code": "140525",
                            "name": "泽州县",
                            "parentCode": "140500",
                            "fullCodes": "140000,140500,140525",
                            "fullNames": "山西省,晋城市,泽州县",
                            "children": []
                        },
                        {
                            "id": "140581",
                            "code": "140581",
                            "name": "高平市",
                            "parentCode": "140500",
                            "fullCodes": "140000,140500,140581",
                            "fullNames": "山西省,晋城市,高平市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "140600",
                    "code": "140600",
                    "name": "朔州市",
                    "parentCode": "140000",
                    "fullCodes": "140000,140600",
                    "fullNames": "山西省,朔州市",
                    "children": [
                        {
                            "id": "140602",
                            "code": "140602",
                            "name": "朔城区",
                            "parentCode": "140600",
                            "fullCodes": "140000,140600,140602",
                            "fullNames": "山西省,朔州市,朔城区",
                            "children": []
                        },
                        {
                            "id": "140603",
                            "code": "140603",
                            "name": "平鲁区",
                            "parentCode": "140600",
                            "fullCodes": "140000,140600,140603",
                            "fullNames": "山西省,朔州市,平鲁区",
                            "children": []
                        },
                        {
                            "id": "140621",
                            "code": "140621",
                            "name": "山阴县",
                            "parentCode": "140600",
                            "fullCodes": "140000,140600,140621",
                            "fullNames": "山西省,朔州市,山阴县",
                            "children": []
                        },
                        {
                            "id": "140622",
                            "code": "140622",
                            "name": "应县",
                            "parentCode": "140600",
                            "fullCodes": "140000,140600,140622",
                            "fullNames": "山西省,朔州市,应县",
                            "children": []
                        },
                        {
                            "id": "140623",
                            "code": "140623",
                            "name": "右玉县",
                            "parentCode": "140600",
                            "fullCodes": "140000,140600,140623",
                            "fullNames": "山西省,朔州市,右玉县",
                            "children": []
                        },
                        {
                            "id": "140624",
                            "code": "140624",
                            "name": "怀仁县",
                            "parentCode": "140600",
                            "fullCodes": "140000,140600,140624",
                            "fullNames": "山西省,朔州市,怀仁县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "140700",
                    "code": "140700",
                    "name": "晋中市",
                    "parentCode": "140000",
                    "fullCodes": "140000,140700",
                    "fullNames": "山西省,晋中市",
                    "children": [
                        {
                            "id": "140702",
                            "code": "140702",
                            "name": "榆次区",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140702",
                            "fullNames": "山西省,晋中市,榆次区",
                            "children": []
                        },
                        {
                            "id": "140721",
                            "code": "140721",
                            "name": "榆社县",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140721",
                            "fullNames": "山西省,晋中市,榆社县",
                            "children": []
                        },
                        {
                            "id": "140722",
                            "code": "140722",
                            "name": "左权县",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140722",
                            "fullNames": "山西省,晋中市,左权县",
                            "children": []
                        },
                        {
                            "id": "140723",
                            "code": "140723",
                            "name": "和顺县",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140723",
                            "fullNames": "山西省,晋中市,和顺县",
                            "children": []
                        },
                        {
                            "id": "140724",
                            "code": "140724",
                            "name": "昔阳县",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140724",
                            "fullNames": "山西省,晋中市,昔阳县",
                            "children": []
                        },
                        {
                            "id": "140725",
                            "code": "140725",
                            "name": "寿阳县",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140725",
                            "fullNames": "山西省,晋中市,寿阳县",
                            "children": []
                        },
                        {
                            "id": "140726",
                            "code": "140726",
                            "name": "太谷县",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140726",
                            "fullNames": "山西省,晋中市,太谷县",
                            "children": []
                        },
                        {
                            "id": "140727",
                            "code": "140727",
                            "name": "祁县",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140727",
                            "fullNames": "山西省,晋中市,祁县",
                            "children": []
                        },
                        {
                            "id": "140728",
                            "code": "140728",
                            "name": "平遥县",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140728",
                            "fullNames": "山西省,晋中市,平遥县",
                            "children": []
                        },
                        {
                            "id": "140729",
                            "code": "140729",
                            "name": "灵石县",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140729",
                            "fullNames": "山西省,晋中市,灵石县",
                            "children": []
                        },
                        {
                            "id": "140781",
                            "code": "140781",
                            "name": "介休市",
                            "parentCode": "140700",
                            "fullCodes": "140000,140700,140781",
                            "fullNames": "山西省,晋中市,介休市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "140800",
                    "code": "140800",
                    "name": "运城市",
                    "parentCode": "140000",
                    "fullCodes": "140000,140800",
                    "fullNames": "山西省,运城市",
                    "children": [
                        {
                            "id": "140802",
                            "code": "140802",
                            "name": "盐湖区",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140802",
                            "fullNames": "山西省,运城市,盐湖区",
                            "children": []
                        },
                        {
                            "id": "140821",
                            "code": "140821",
                            "name": "临猗县",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140821",
                            "fullNames": "山西省,运城市,临猗县",
                            "children": []
                        },
                        {
                            "id": "140822",
                            "code": "140822",
                            "name": "万荣县",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140822",
                            "fullNames": "山西省,运城市,万荣县",
                            "children": []
                        },
                        {
                            "id": "140823",
                            "code": "140823",
                            "name": "闻喜县",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140823",
                            "fullNames": "山西省,运城市,闻喜县",
                            "children": []
                        },
                        {
                            "id": "140824",
                            "code": "140824",
                            "name": "稷山县",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140824",
                            "fullNames": "山西省,运城市,稷山县",
                            "children": []
                        },
                        {
                            "id": "140825",
                            "code": "140825",
                            "name": "新绛县",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140825",
                            "fullNames": "山西省,运城市,新绛县",
                            "children": []
                        },
                        {
                            "id": "140826",
                            "code": "140826",
                            "name": "绛县",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140826",
                            "fullNames": "山西省,运城市,绛县",
                            "children": []
                        },
                        {
                            "id": "140827",
                            "code": "140827",
                            "name": "垣曲县",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140827",
                            "fullNames": "山西省,运城市,垣曲县",
                            "children": []
                        },
                        {
                            "id": "140828",
                            "code": "140828",
                            "name": "夏县",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140828",
                            "fullNames": "山西省,运城市,夏县",
                            "children": []
                        },
                        {
                            "id": "140829",
                            "code": "140829",
                            "name": "平陆县",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140829",
                            "fullNames": "山西省,运城市,平陆县",
                            "children": []
                        },
                        {
                            "id": "140830",
                            "code": "140830",
                            "name": "芮城县",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140830",
                            "fullNames": "山西省,运城市,芮城县",
                            "children": []
                        },
                        {
                            "id": "140881",
                            "code": "140881",
                            "name": "永济市",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140881",
                            "fullNames": "山西省,运城市,永济市",
                            "children": []
                        },
                        {
                            "id": "140882",
                            "code": "140882",
                            "name": "河津市",
                            "parentCode": "140800",
                            "fullCodes": "140000,140800,140882",
                            "fullNames": "山西省,运城市,河津市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "140900",
                    "code": "140900",
                    "name": "忻州市",
                    "parentCode": "140000",
                    "fullCodes": "140000,140900",
                    "fullNames": "山西省,忻州市",
                    "children": [
                        {
                            "id": "140902",
                            "code": "140902",
                            "name": "忻府区",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140902",
                            "fullNames": "山西省,忻州市,忻府区",
                            "children": []
                        },
                        {
                            "id": "140921",
                            "code": "140921",
                            "name": "定襄县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140921",
                            "fullNames": "山西省,忻州市,定襄县",
                            "children": []
                        },
                        {
                            "id": "140922",
                            "code": "140922",
                            "name": "五台县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140922",
                            "fullNames": "山西省,忻州市,五台县",
                            "children": []
                        },
                        {
                            "id": "140923",
                            "code": "140923",
                            "name": "代县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140923",
                            "fullNames": "山西省,忻州市,代县",
                            "children": []
                        },
                        {
                            "id": "140924",
                            "code": "140924",
                            "name": "繁峙县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140924",
                            "fullNames": "山西省,忻州市,繁峙县",
                            "children": []
                        },
                        {
                            "id": "140925",
                            "code": "140925",
                            "name": "宁武县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140925",
                            "fullNames": "山西省,忻州市,宁武县",
                            "children": []
                        },
                        {
                            "id": "140926",
                            "code": "140926",
                            "name": "静乐县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140926",
                            "fullNames": "山西省,忻州市,静乐县",
                            "children": []
                        },
                        {
                            "id": "140927",
                            "code": "140927",
                            "name": "神池县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140927",
                            "fullNames": "山西省,忻州市,神池县",
                            "children": []
                        },
                        {
                            "id": "140928",
                            "code": "140928",
                            "name": "五寨县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140928",
                            "fullNames": "山西省,忻州市,五寨县",
                            "children": []
                        },
                        {
                            "id": "140929",
                            "code": "140929",
                            "name": "岢岚县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140929",
                            "fullNames": "山西省,忻州市,岢岚县",
                            "children": []
                        },
                        {
                            "id": "140930",
                            "code": "140930",
                            "name": "河曲县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140930",
                            "fullNames": "山西省,忻州市,河曲县",
                            "children": []
                        },
                        {
                            "id": "140931",
                            "code": "140931",
                            "name": "保德县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140931",
                            "fullNames": "山西省,忻州市,保德县",
                            "children": []
                        },
                        {
                            "id": "140932",
                            "code": "140932",
                            "name": "偏关县",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140932",
                            "fullNames": "山西省,忻州市,偏关县",
                            "children": []
                        },
                        {
                            "id": "140981",
                            "code": "140981",
                            "name": "原平市",
                            "parentCode": "140900",
                            "fullCodes": "140000,140900,140981",
                            "fullNames": "山西省,忻州市,原平市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "141000",
                    "code": "141000",
                    "name": "临汾市",
                    "parentCode": "140000",
                    "fullCodes": "140000,141000",
                    "fullNames": "山西省,临汾市",
                    "children": [
                        {
                            "id": "141002",
                            "code": "141002",
                            "name": "尧都区",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141002",
                            "fullNames": "山西省,临汾市,尧都区",
                            "children": []
                        },
                        {
                            "id": "141021",
                            "code": "141021",
                            "name": "曲沃县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141021",
                            "fullNames": "山西省,临汾市,曲沃县",
                            "children": []
                        },
                        {
                            "id": "141022",
                            "code": "141022",
                            "name": "翼城县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141022",
                            "fullNames": "山西省,临汾市,翼城县",
                            "children": []
                        },
                        {
                            "id": "141023",
                            "code": "141023",
                            "name": "襄汾县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141023",
                            "fullNames": "山西省,临汾市,襄汾县",
                            "children": []
                        },
                        {
                            "id": "141024",
                            "code": "141024",
                            "name": "洪洞县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141024",
                            "fullNames": "山西省,临汾市,洪洞县",
                            "children": []
                        },
                        {
                            "id": "141025",
                            "code": "141025",
                            "name": "古县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141025",
                            "fullNames": "山西省,临汾市,古县",
                            "children": []
                        },
                        {
                            "id": "141026",
                            "code": "141026",
                            "name": "安泽县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141026",
                            "fullNames": "山西省,临汾市,安泽县",
                            "children": []
                        },
                        {
                            "id": "141027",
                            "code": "141027",
                            "name": "浮山县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141027",
                            "fullNames": "山西省,临汾市,浮山县",
                            "children": []
                        },
                        {
                            "id": "141028",
                            "code": "141028",
                            "name": "吉县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141028",
                            "fullNames": "山西省,临汾市,吉县",
                            "children": []
                        },
                        {
                            "id": "141029",
                            "code": "141029",
                            "name": "乡宁县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141029",
                            "fullNames": "山西省,临汾市,乡宁县",
                            "children": []
                        },
                        {
                            "id": "141030",
                            "code": "141030",
                            "name": "大宁县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141030",
                            "fullNames": "山西省,临汾市,大宁县",
                            "children": []
                        },
                        {
                            "id": "141031",
                            "code": "141031",
                            "name": "隰县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141031",
                            "fullNames": "山西省,临汾市,隰县",
                            "children": []
                        },
                        {
                            "id": "141032",
                            "code": "141032",
                            "name": "永和县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141032",
                            "fullNames": "山西省,临汾市,永和县",
                            "children": []
                        },
                        {
                            "id": "141033",
                            "code": "141033",
                            "name": "蒲县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141033",
                            "fullNames": "山西省,临汾市,蒲县",
                            "children": []
                        },
                        {
                            "id": "141034",
                            "code": "141034",
                            "name": "汾西县",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141034",
                            "fullNames": "山西省,临汾市,汾西县",
                            "children": []
                        },
                        {
                            "id": "141081",
                            "code": "141081",
                            "name": "侯马市",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141081",
                            "fullNames": "山西省,临汾市,侯马市",
                            "children": []
                        },
                        {
                            "id": "141082",
                            "code": "141082",
                            "name": "霍州市",
                            "parentCode": "141000",
                            "fullCodes": "140000,141000,141082",
                            "fullNames": "山西省,临汾市,霍州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "141100",
                    "code": "141100",
                    "name": "吕梁市",
                    "parentCode": "140000",
                    "fullCodes": "140000,141100",
                    "fullNames": "山西省,吕梁市",
                    "children": [
                        {
                            "id": "141102",
                            "code": "141102",
                            "name": "离石区",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141102",
                            "fullNames": "山西省,吕梁市,离石区",
                            "children": []
                        },
                        {
                            "id": "141121",
                            "code": "141121",
                            "name": "文水县",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141121",
                            "fullNames": "山西省,吕梁市,文水县",
                            "children": []
                        },
                        {
                            "id": "141122",
                            "code": "141122",
                            "name": "交城县",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141122",
                            "fullNames": "山西省,吕梁市,交城县",
                            "children": []
                        },
                        {
                            "id": "141123",
                            "code": "141123",
                            "name": "兴县",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141123",
                            "fullNames": "山西省,吕梁市,兴县",
                            "children": []
                        },
                        {
                            "id": "141124",
                            "code": "141124",
                            "name": "临县",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141124",
                            "fullNames": "山西省,吕梁市,临县",
                            "children": []
                        },
                        {
                            "id": "141125",
                            "code": "141125",
                            "name": "柳林县",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141125",
                            "fullNames": "山西省,吕梁市,柳林县",
                            "children": []
                        },
                        {
                            "id": "141126",
                            "code": "141126",
                            "name": "石楼县",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141126",
                            "fullNames": "山西省,吕梁市,石楼县",
                            "children": []
                        },
                        {
                            "id": "141127",
                            "code": "141127",
                            "name": "岚县",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141127",
                            "fullNames": "山西省,吕梁市,岚县",
                            "children": []
                        },
                        {
                            "id": "141128",
                            "code": "141128",
                            "name": "方山县",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141128",
                            "fullNames": "山西省,吕梁市,方山县",
                            "children": []
                        },
                        {
                            "id": "141129",
                            "code": "141129",
                            "name": "中阳县",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141129",
                            "fullNames": "山西省,吕梁市,中阳县",
                            "children": []
                        },
                        {
                            "id": "141130",
                            "code": "141130",
                            "name": "交口县",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141130",
                            "fullNames": "山西省,吕梁市,交口县",
                            "children": []
                        },
                        {
                            "id": "141181",
                            "code": "141181",
                            "name": "孝义市",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141181",
                            "fullNames": "山西省,吕梁市,孝义市",
                            "children": []
                        },
                        {
                            "id": "141182",
                            "code": "141182",
                            "name": "汾阳市",
                            "parentCode": "141100",
                            "fullCodes": "140000,141100,141182",
                            "fullNames": "山西省,吕梁市,汾阳市",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "150000",
            "code": "150000",
            "name": "内蒙古自治区",
            "parentCode": "-1",
            "fullCodes": "150000",
            "fullNames": "内蒙古自治区",
            "children": [
                {
                    "id": "150100",
                    "code": "150100",
                    "name": "呼和浩特市",
                    "parentCode": "150000",
                    "fullCodes": "150000,150100",
                    "fullNames": "内蒙古自治区,呼和浩特市",
                    "children": [
                        {
                            "id": "150102",
                            "code": "150102",
                            "name": "新城区",
                            "parentCode": "150100",
                            "fullCodes": "150000,150100,150102",
                            "fullNames": "内蒙古自治区,呼和浩特市,新城区",
                            "children": []
                        },
                        {
                            "id": "150103",
                            "code": "150103",
                            "name": "回民区",
                            "parentCode": "150100",
                            "fullCodes": "150000,150100,150103",
                            "fullNames": "内蒙古自治区,呼和浩特市,回民区",
                            "children": []
                        },
                        {
                            "id": "150104",
                            "code": "150104",
                            "name": "玉泉区",
                            "parentCode": "150100",
                            "fullCodes": "150000,150100,150104",
                            "fullNames": "内蒙古自治区,呼和浩特市,玉泉区",
                            "children": []
                        },
                        {
                            "id": "150105",
                            "code": "150105",
                            "name": "赛罕区",
                            "parentCode": "150100",
                            "fullCodes": "150000,150100,150105",
                            "fullNames": "内蒙古自治区,呼和浩特市,赛罕区",
                            "children": []
                        },
                        {
                            "id": "150121",
                            "code": "150121",
                            "name": "土默特左旗",
                            "parentCode": "150100",
                            "fullCodes": "150000,150100,150121",
                            "fullNames": "内蒙古自治区,呼和浩特市,土默特左旗",
                            "children": []
                        },
                        {
                            "id": "150122",
                            "code": "150122",
                            "name": "托克托县",
                            "parentCode": "150100",
                            "fullCodes": "150000,150100,150122",
                            "fullNames": "内蒙古自治区,呼和浩特市,托克托县",
                            "children": []
                        },
                        {
                            "id": "150123",
                            "code": "150123",
                            "name": "和林格尔县",
                            "parentCode": "150100",
                            "fullCodes": "150000,150100,150123",
                            "fullNames": "内蒙古自治区,呼和浩特市,和林格尔县",
                            "children": []
                        },
                        {
                            "id": "150124",
                            "code": "150124",
                            "name": "清水河县",
                            "parentCode": "150100",
                            "fullCodes": "150000,150100,150124",
                            "fullNames": "内蒙古自治区,呼和浩特市,清水河县",
                            "children": []
                        },
                        {
                            "id": "150125",
                            "code": "150125",
                            "name": "武川县",
                            "parentCode": "150100",
                            "fullCodes": "150000,150100,150125",
                            "fullNames": "内蒙古自治区,呼和浩特市,武川县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "150200",
                    "code": "150200",
                    "name": "包头市",
                    "parentCode": "150000",
                    "fullCodes": "150000,150200",
                    "fullNames": "内蒙古自治区,包头市",
                    "children": [
                        {
                            "id": "150202",
                            "code": "150202",
                            "name": "东河区",
                            "parentCode": "150200",
                            "fullCodes": "150000,150200,150202",
                            "fullNames": "内蒙古自治区,包头市,东河区",
                            "children": []
                        },
                        {
                            "id": "150203",
                            "code": "150203",
                            "name": "昆都仑区",
                            "parentCode": "150200",
                            "fullCodes": "150000,150200,150203",
                            "fullNames": "内蒙古自治区,包头市,昆都仑区",
                            "children": []
                        },
                        {
                            "id": "150204",
                            "code": "150204",
                            "name": "青山区",
                            "parentCode": "150200",
                            "fullCodes": "150000,150200,150204",
                            "fullNames": "内蒙古自治区,包头市,青山区",
                            "children": []
                        },
                        {
                            "id": "150205",
                            "code": "150205",
                            "name": "石拐区",
                            "parentCode": "150200",
                            "fullCodes": "150000,150200,150205",
                            "fullNames": "内蒙古自治区,包头市,石拐区",
                            "children": []
                        },
                        {
                            "id": "150206",
                            "code": "150206",
                            "name": "白云矿区",
                            "parentCode": "150200",
                            "fullCodes": "150000,150200,150206",
                            "fullNames": "内蒙古自治区,包头市,白云矿区",
                            "children": []
                        },
                        {
                            "id": "150207",
                            "code": "150207",
                            "name": "九原区",
                            "parentCode": "150200",
                            "fullCodes": "150000,150200,150207",
                            "fullNames": "内蒙古自治区,包头市,九原区",
                            "children": []
                        },
                        {
                            "id": "150221",
                            "code": "150221",
                            "name": "土默特右旗",
                            "parentCode": "150200",
                            "fullCodes": "150000,150200,150221",
                            "fullNames": "内蒙古自治区,包头市,土默特右旗",
                            "children": []
                        },
                        {
                            "id": "150222",
                            "code": "150222",
                            "name": "固阳县",
                            "parentCode": "150200",
                            "fullCodes": "150000,150200,150222",
                            "fullNames": "内蒙古自治区,包头市,固阳县",
                            "children": []
                        },
                        {
                            "id": "150223",
                            "code": "150223",
                            "name": "达尔罕茂明安联合旗",
                            "parentCode": "150200",
                            "fullCodes": "150000,150200,150223",
                            "fullNames": "内蒙古自治区,包头市,达尔罕茂明安联合旗",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "150300",
                    "code": "150300",
                    "name": "乌海市",
                    "parentCode": "150000",
                    "fullCodes": "150000,150300",
                    "fullNames": "内蒙古自治区,乌海市",
                    "children": [
                        {
                            "id": "150302",
                            "code": "150302",
                            "name": "海勃湾区",
                            "parentCode": "150300",
                            "fullCodes": "150000,150300,150302",
                            "fullNames": "内蒙古自治区,乌海市,海勃湾区",
                            "children": []
                        },
                        {
                            "id": "150303",
                            "code": "150303",
                            "name": "海南区",
                            "parentCode": "150300",
                            "fullCodes": "150000,150300,150303",
                            "fullNames": "内蒙古自治区,乌海市,海南区",
                            "children": []
                        },
                        {
                            "id": "150304",
                            "code": "150304",
                            "name": "乌达区",
                            "parentCode": "150300",
                            "fullCodes": "150000,150300,150304",
                            "fullNames": "内蒙古自治区,乌海市,乌达区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "150400",
                    "code": "150400",
                    "name": "赤峰市",
                    "parentCode": "150000",
                    "fullCodes": "150000,150400",
                    "fullNames": "内蒙古自治区,赤峰市",
                    "children": [
                        {
                            "id": "150402",
                            "code": "150402",
                            "name": "红山区",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150402",
                            "fullNames": "内蒙古自治区,赤峰市,红山区",
                            "children": []
                        },
                        {
                            "id": "150403",
                            "code": "150403",
                            "name": "元宝山区",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150403",
                            "fullNames": "内蒙古自治区,赤峰市,元宝山区",
                            "children": []
                        },
                        {
                            "id": "150404",
                            "code": "150404",
                            "name": "松山区",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150404",
                            "fullNames": "内蒙古自治区,赤峰市,松山区",
                            "children": []
                        },
                        {
                            "id": "150421",
                            "code": "150421",
                            "name": "阿鲁科尔沁旗",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150421",
                            "fullNames": "内蒙古自治区,赤峰市,阿鲁科尔沁旗",
                            "children": []
                        },
                        {
                            "id": "150422",
                            "code": "150422",
                            "name": "巴林左旗",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150422",
                            "fullNames": "内蒙古自治区,赤峰市,巴林左旗",
                            "children": []
                        },
                        {
                            "id": "150423",
                            "code": "150423",
                            "name": "巴林右旗",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150423",
                            "fullNames": "内蒙古自治区,赤峰市,巴林右旗",
                            "children": []
                        },
                        {
                            "id": "150424",
                            "code": "150424",
                            "name": "林西县",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150424",
                            "fullNames": "内蒙古自治区,赤峰市,林西县",
                            "children": []
                        },
                        {
                            "id": "150425",
                            "code": "150425",
                            "name": "克什克腾旗",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150425",
                            "fullNames": "内蒙古自治区,赤峰市,克什克腾旗",
                            "children": []
                        },
                        {
                            "id": "150426",
                            "code": "150426",
                            "name": "翁牛特旗",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150426",
                            "fullNames": "内蒙古自治区,赤峰市,翁牛特旗",
                            "children": []
                        },
                        {
                            "id": "150428",
                            "code": "150428",
                            "name": "喀喇沁旗",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150428",
                            "fullNames": "内蒙古自治区,赤峰市,喀喇沁旗",
                            "children": []
                        },
                        {
                            "id": "150429",
                            "code": "150429",
                            "name": "宁城县",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150429",
                            "fullNames": "内蒙古自治区,赤峰市,宁城县",
                            "children": []
                        },
                        {
                            "id": "150430",
                            "code": "150430",
                            "name": "敖汉旗",
                            "parentCode": "150400",
                            "fullCodes": "150000,150400,150430",
                            "fullNames": "内蒙古自治区,赤峰市,敖汉旗",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "150500",
                    "code": "150500",
                    "name": "通辽市",
                    "parentCode": "150000",
                    "fullCodes": "150000,150500",
                    "fullNames": "内蒙古自治区,通辽市",
                    "children": [
                        {
                            "id": "150502",
                            "code": "150502",
                            "name": "科尔沁区",
                            "parentCode": "150500",
                            "fullCodes": "150000,150500,150502",
                            "fullNames": "内蒙古自治区,通辽市,科尔沁区",
                            "children": []
                        },
                        {
                            "id": "150521",
                            "code": "150521",
                            "name": "科尔沁左翼中旗",
                            "parentCode": "150500",
                            "fullCodes": "150000,150500,150521",
                            "fullNames": "内蒙古自治区,通辽市,科尔沁左翼中旗",
                            "children": []
                        },
                        {
                            "id": "150522",
                            "code": "150522",
                            "name": "科尔沁左翼后旗",
                            "parentCode": "150500",
                            "fullCodes": "150000,150500,150522",
                            "fullNames": "内蒙古自治区,通辽市,科尔沁左翼后旗",
                            "children": []
                        },
                        {
                            "id": "150523",
                            "code": "150523",
                            "name": "开鲁县",
                            "parentCode": "150500",
                            "fullCodes": "150000,150500,150523",
                            "fullNames": "内蒙古自治区,通辽市,开鲁县",
                            "children": []
                        },
                        {
                            "id": "150524",
                            "code": "150524",
                            "name": "库伦旗",
                            "parentCode": "150500",
                            "fullCodes": "150000,150500,150524",
                            "fullNames": "内蒙古自治区,通辽市,库伦旗",
                            "children": []
                        },
                        {
                            "id": "150525",
                            "code": "150525",
                            "name": "奈曼旗",
                            "parentCode": "150500",
                            "fullCodes": "150000,150500,150525",
                            "fullNames": "内蒙古自治区,通辽市,奈曼旗",
                            "children": []
                        },
                        {
                            "id": "150526",
                            "code": "150526",
                            "name": "扎鲁特旗",
                            "parentCode": "150500",
                            "fullCodes": "150000,150500,150526",
                            "fullNames": "内蒙古自治区,通辽市,扎鲁特旗",
                            "children": []
                        },
                        {
                            "id": "150581",
                            "code": "150581",
                            "name": "霍林郭勒市",
                            "parentCode": "150500",
                            "fullCodes": "150000,150500,150581",
                            "fullNames": "内蒙古自治区,通辽市,霍林郭勒市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "150600",
                    "code": "150600",
                    "name": "鄂尔多斯市",
                    "parentCode": "150000",
                    "fullCodes": "150000,150600",
                    "fullNames": "内蒙古自治区,鄂尔多斯市",
                    "children": [
                        {
                            "id": "150602",
                            "code": "150602",
                            "name": "东胜区",
                            "parentCode": "150600",
                            "fullCodes": "150000,150600,150602",
                            "fullNames": "内蒙古自治区,鄂尔多斯市,东胜区",
                            "children": []
                        },
                        {
                            "id": "150621",
                            "code": "150621",
                            "name": "达拉特旗",
                            "parentCode": "150600",
                            "fullCodes": "150000,150600,150621",
                            "fullNames": "内蒙古自治区,鄂尔多斯市,达拉特旗",
                            "children": []
                        },
                        {
                            "id": "150622",
                            "code": "150622",
                            "name": "准格尔旗",
                            "parentCode": "150600",
                            "fullCodes": "150000,150600,150622",
                            "fullNames": "内蒙古自治区,鄂尔多斯市,准格尔旗",
                            "children": []
                        },
                        {
                            "id": "150623",
                            "code": "150623",
                            "name": "鄂托克前旗",
                            "parentCode": "150600",
                            "fullCodes": "150000,150600,150623",
                            "fullNames": "内蒙古自治区,鄂尔多斯市,鄂托克前旗",
                            "children": []
                        },
                        {
                            "id": "150624",
                            "code": "150624",
                            "name": "鄂托克旗",
                            "parentCode": "150600",
                            "fullCodes": "150000,150600,150624",
                            "fullNames": "内蒙古自治区,鄂尔多斯市,鄂托克旗",
                            "children": []
                        },
                        {
                            "id": "150625",
                            "code": "150625",
                            "name": "杭锦旗",
                            "parentCode": "150600",
                            "fullCodes": "150000,150600,150625",
                            "fullNames": "内蒙古自治区,鄂尔多斯市,杭锦旗",
                            "children": []
                        },
                        {
                            "id": "150626",
                            "code": "150626",
                            "name": "乌审旗",
                            "parentCode": "150600",
                            "fullCodes": "150000,150600,150626",
                            "fullNames": "内蒙古自治区,鄂尔多斯市,乌审旗",
                            "children": []
                        },
                        {
                            "id": "150627",
                            "code": "150627",
                            "name": "伊金霍洛旗",
                            "parentCode": "150600",
                            "fullCodes": "150000,150600,150627",
                            "fullNames": "内蒙古自治区,鄂尔多斯市,伊金霍洛旗",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "150700",
                    "code": "150700",
                    "name": "呼伦贝尔市",
                    "parentCode": "150000",
                    "fullCodes": "150000,150700",
                    "fullNames": "内蒙古自治区,呼伦贝尔市",
                    "children": [
                        {
                            "id": "150702",
                            "code": "150702",
                            "name": "海拉尔区",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150702",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,海拉尔区",
                            "children": []
                        },
                        {
                            "id": "150721",
                            "code": "150721",
                            "name": "阿荣旗",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150721",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,阿荣旗",
                            "children": []
                        },
                        {
                            "id": "150722",
                            "code": "150722",
                            "name": "莫力达瓦达斡尔族自治旗",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150722",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,莫力达瓦达斡尔族自治旗",
                            "children": []
                        },
                        {
                            "id": "150723",
                            "code": "150723",
                            "name": "鄂伦春自治旗",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150723",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,鄂伦春自治旗",
                            "children": []
                        },
                        {
                            "id": "150724",
                            "code": "150724",
                            "name": "鄂温克族自治旗",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150724",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,鄂温克族自治旗",
                            "children": []
                        },
                        {
                            "id": "150725",
                            "code": "150725",
                            "name": "陈巴尔虎旗",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150725",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,陈巴尔虎旗",
                            "children": []
                        },
                        {
                            "id": "150726",
                            "code": "150726",
                            "name": "新巴尔虎左旗",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150726",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,新巴尔虎左旗",
                            "children": []
                        },
                        {
                            "id": "150727",
                            "code": "150727",
                            "name": "新巴尔虎右旗",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150727",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,新巴尔虎右旗",
                            "children": []
                        },
                        {
                            "id": "150781",
                            "code": "150781",
                            "name": "满洲里市",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150781",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,满洲里市",
                            "children": []
                        },
                        {
                            "id": "150782",
                            "code": "150782",
                            "name": "牙克石市",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150782",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,牙克石市",
                            "children": []
                        },
                        {
                            "id": "150783",
                            "code": "150783",
                            "name": "扎兰屯市",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150783",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,扎兰屯市",
                            "children": []
                        },
                        {
                            "id": "150784",
                            "code": "150784",
                            "name": "额尔古纳市",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150784",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,额尔古纳市",
                            "children": []
                        },
                        {
                            "id": "150785",
                            "code": "150785",
                            "name": "根河市",
                            "parentCode": "150700",
                            "fullCodes": "150000,150700,150785",
                            "fullNames": "内蒙古自治区,呼伦贝尔市,根河市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "150800",
                    "code": "150800",
                    "name": "巴彦淖尔市",
                    "parentCode": "150000",
                    "fullCodes": "150000,150800",
                    "fullNames": "内蒙古自治区,巴彦淖尔市",
                    "children": [
                        {
                            "id": "150802",
                            "code": "150802",
                            "name": "临河区",
                            "parentCode": "150800",
                            "fullCodes": "150000,150800,150802",
                            "fullNames": "内蒙古自治区,巴彦淖尔市,临河区",
                            "children": []
                        },
                        {
                            "id": "150821",
                            "code": "150821",
                            "name": "五原县",
                            "parentCode": "150800",
                            "fullCodes": "150000,150800,150821",
                            "fullNames": "内蒙古自治区,巴彦淖尔市,五原县",
                            "children": []
                        },
                        {
                            "id": "150822",
                            "code": "150822",
                            "name": "磴口县",
                            "parentCode": "150800",
                            "fullCodes": "150000,150800,150822",
                            "fullNames": "内蒙古自治区,巴彦淖尔市,磴口县",
                            "children": []
                        },
                        {
                            "id": "150823",
                            "code": "150823",
                            "name": "乌拉特前旗",
                            "parentCode": "150800",
                            "fullCodes": "150000,150800,150823",
                            "fullNames": "内蒙古自治区,巴彦淖尔市,乌拉特前旗",
                            "children": []
                        },
                        {
                            "id": "150824",
                            "code": "150824",
                            "name": "乌拉特中旗",
                            "parentCode": "150800",
                            "fullCodes": "150000,150800,150824",
                            "fullNames": "内蒙古自治区,巴彦淖尔市,乌拉特中旗",
                            "children": []
                        },
                        {
                            "id": "150825",
                            "code": "150825",
                            "name": "乌拉特后旗",
                            "parentCode": "150800",
                            "fullCodes": "150000,150800,150825",
                            "fullNames": "内蒙古自治区,巴彦淖尔市,乌拉特后旗",
                            "children": []
                        },
                        {
                            "id": "150826",
                            "code": "150826",
                            "name": "杭锦后旗",
                            "parentCode": "150800",
                            "fullCodes": "150000,150800,150826",
                            "fullNames": "内蒙古自治区,巴彦淖尔市,杭锦后旗",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "150900",
                    "code": "150900",
                    "name": "乌兰察布市",
                    "parentCode": "150000",
                    "fullCodes": "150000,150900",
                    "fullNames": "内蒙古自治区,乌兰察布市",
                    "children": [
                        {
                            "id": "150902",
                            "code": "150902",
                            "name": "集宁区",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150902",
                            "fullNames": "内蒙古自治区,乌兰察布市,集宁区",
                            "children": []
                        },
                        {
                            "id": "150921",
                            "code": "150921",
                            "name": "卓资县",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150921",
                            "fullNames": "内蒙古自治区,乌兰察布市,卓资县",
                            "children": []
                        },
                        {
                            "id": "150922",
                            "code": "150922",
                            "name": "化德县",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150922",
                            "fullNames": "内蒙古自治区,乌兰察布市,化德县",
                            "children": []
                        },
                        {
                            "id": "150923",
                            "code": "150923",
                            "name": "商都县",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150923",
                            "fullNames": "内蒙古自治区,乌兰察布市,商都县",
                            "children": []
                        },
                        {
                            "id": "150924",
                            "code": "150924",
                            "name": "兴和县",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150924",
                            "fullNames": "内蒙古自治区,乌兰察布市,兴和县",
                            "children": []
                        },
                        {
                            "id": "150925",
                            "code": "150925",
                            "name": "凉城县",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150925",
                            "fullNames": "内蒙古自治区,乌兰察布市,凉城县",
                            "children": []
                        },
                        {
                            "id": "150926",
                            "code": "150926",
                            "name": "察哈尔右翼前旗",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150926",
                            "fullNames": "内蒙古自治区,乌兰察布市,察哈尔右翼前旗",
                            "children": []
                        },
                        {
                            "id": "150927",
                            "code": "150927",
                            "name": "察哈尔右翼中旗",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150927",
                            "fullNames": "内蒙古自治区,乌兰察布市,察哈尔右翼中旗",
                            "children": []
                        },
                        {
                            "id": "150928",
                            "code": "150928",
                            "name": "察哈尔右翼后旗",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150928",
                            "fullNames": "内蒙古自治区,乌兰察布市,察哈尔右翼后旗",
                            "children": []
                        },
                        {
                            "id": "150929",
                            "code": "150929",
                            "name": "四子王旗",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150929",
                            "fullNames": "内蒙古自治区,乌兰察布市,四子王旗",
                            "children": []
                        },
                        {
                            "id": "150981",
                            "code": "150981",
                            "name": "丰镇市",
                            "parentCode": "150900",
                            "fullCodes": "150000,150900,150981",
                            "fullNames": "内蒙古自治区,乌兰察布市,丰镇市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "152200",
                    "code": "152200",
                    "name": "兴安盟",
                    "parentCode": "150000",
                    "fullCodes": "150000,152200",
                    "fullNames": "内蒙古自治区,兴安盟",
                    "children": [
                        {
                            "id": "152201",
                            "code": "152201",
                            "name": "乌兰浩特市",
                            "parentCode": "152200",
                            "fullCodes": "150000,152200,152201",
                            "fullNames": "内蒙古自治区,兴安盟,乌兰浩特市",
                            "children": []
                        },
                        {
                            "id": "152202",
                            "code": "152202",
                            "name": "阿尔山市",
                            "parentCode": "152200",
                            "fullCodes": "150000,152200,152202",
                            "fullNames": "内蒙古自治区,兴安盟,阿尔山市",
                            "children": []
                        },
                        {
                            "id": "152221",
                            "code": "152221",
                            "name": "科尔沁右翼前旗",
                            "parentCode": "152200",
                            "fullCodes": "150000,152200,152221",
                            "fullNames": "内蒙古自治区,兴安盟,科尔沁右翼前旗",
                            "children": []
                        },
                        {
                            "id": "152222",
                            "code": "152222",
                            "name": "科尔沁右翼中旗",
                            "parentCode": "152200",
                            "fullCodes": "150000,152200,152222",
                            "fullNames": "内蒙古自治区,兴安盟,科尔沁右翼中旗",
                            "children": []
                        },
                        {
                            "id": "152223",
                            "code": "152223",
                            "name": "扎赉特旗",
                            "parentCode": "152200",
                            "fullCodes": "150000,152200,152223",
                            "fullNames": "内蒙古自治区,兴安盟,扎赉特旗",
                            "children": []
                        },
                        {
                            "id": "152224",
                            "code": "152224",
                            "name": "突泉县",
                            "parentCode": "152200",
                            "fullCodes": "150000,152200,152224",
                            "fullNames": "内蒙古自治区,兴安盟,突泉县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "152500",
                    "code": "152500",
                    "name": "锡林郭勒盟",
                    "parentCode": "150000",
                    "fullCodes": "150000,152500",
                    "fullNames": "内蒙古自治区,锡林郭勒盟",
                    "children": [
                        {
                            "id": "152501",
                            "code": "152501",
                            "name": "二连浩特市",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152501",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,二连浩特市",
                            "children": []
                        },
                        {
                            "id": "152502",
                            "code": "152502",
                            "name": "锡林浩特市",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152502",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,锡林浩特市",
                            "children": []
                        },
                        {
                            "id": "152522",
                            "code": "152522",
                            "name": "阿巴嘎旗",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152522",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,阿巴嘎旗",
                            "children": []
                        },
                        {
                            "id": "152523",
                            "code": "152523",
                            "name": "苏尼特左旗",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152523",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,苏尼特左旗",
                            "children": []
                        },
                        {
                            "id": "152524",
                            "code": "152524",
                            "name": "苏尼特右旗",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152524",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,苏尼特右旗",
                            "children": []
                        },
                        {
                            "id": "152525",
                            "code": "152525",
                            "name": "东乌珠穆沁旗",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152525",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,东乌珠穆沁旗",
                            "children": []
                        },
                        {
                            "id": "152526",
                            "code": "152526",
                            "name": "西乌珠穆沁旗",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152526",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,西乌珠穆沁旗",
                            "children": []
                        },
                        {
                            "id": "152527",
                            "code": "152527",
                            "name": "太仆寺旗",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152527",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,太仆寺旗",
                            "children": []
                        },
                        {
                            "id": "152528",
                            "code": "152528",
                            "name": "镶黄旗",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152528",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,镶黄旗",
                            "children": []
                        },
                        {
                            "id": "152529",
                            "code": "152529",
                            "name": "正镶白旗",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152529",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,正镶白旗",
                            "children": []
                        },
                        {
                            "id": "152530",
                            "code": "152530",
                            "name": "正蓝旗",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152530",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,正蓝旗",
                            "children": []
                        },
                        {
                            "id": "152531",
                            "code": "152531",
                            "name": "多伦县",
                            "parentCode": "152500",
                            "fullCodes": "150000,152500,152531",
                            "fullNames": "内蒙古自治区,锡林郭勒盟,多伦县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "152900",
                    "code": "152900",
                    "name": "阿拉善盟",
                    "parentCode": "150000",
                    "fullCodes": "150000,152900",
                    "fullNames": "内蒙古自治区,阿拉善盟",
                    "children": [
                        {
                            "id": "152921",
                            "code": "152921",
                            "name": "阿拉善左旗",
                            "parentCode": "152900",
                            "fullCodes": "150000,152900,152921",
                            "fullNames": "内蒙古自治区,阿拉善盟,阿拉善左旗",
                            "children": []
                        },
                        {
                            "id": "152922",
                            "code": "152922",
                            "name": "阿拉善右旗",
                            "parentCode": "152900",
                            "fullCodes": "150000,152900,152922",
                            "fullNames": "内蒙古自治区,阿拉善盟,阿拉善右旗",
                            "children": []
                        },
                        {
                            "id": "152923",
                            "code": "152923",
                            "name": "额济纳旗",
                            "parentCode": "152900",
                            "fullCodes": "150000,152900,152923",
                            "fullNames": "内蒙古自治区,阿拉善盟,额济纳旗",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "210000",
            "code": "210000",
            "name": "辽宁省",
            "parentCode": "-1",
            "fullCodes": "210000",
            "fullNames": "辽宁省",
            "children": [
                {
                    "id": "210100",
                    "code": "210100",
                    "name": "沈阳市",
                    "parentCode": "210000",
                    "fullCodes": "210000,210100",
                    "fullNames": "辽宁省,沈阳市",
                    "children": [
                        {
                            "id": "210102",
                            "code": "210102",
                            "name": "和平区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210102",
                            "fullNames": "辽宁省,沈阳市,和平区",
                            "children": []
                        },
                        {
                            "id": "210103",
                            "code": "210103",
                            "name": "沈河区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210103",
                            "fullNames": "辽宁省,沈阳市,沈河区",
                            "children": []
                        },
                        {
                            "id": "210104",
                            "code": "210104",
                            "name": "大东区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210104",
                            "fullNames": "辽宁省,沈阳市,大东区",
                            "children": []
                        },
                        {
                            "id": "210105",
                            "code": "210105",
                            "name": "皇姑区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210105",
                            "fullNames": "辽宁省,沈阳市,皇姑区",
                            "children": []
                        },
                        {
                            "id": "210106",
                            "code": "210106",
                            "name": "铁西区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210106",
                            "fullNames": "辽宁省,沈阳市,铁西区",
                            "children": []
                        },
                        {
                            "id": "210111",
                            "code": "210111",
                            "name": "苏家屯区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210111",
                            "fullNames": "辽宁省,沈阳市,苏家屯区",
                            "children": []
                        },
                        {
                            "id": "210112",
                            "code": "210112",
                            "name": "东陵区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210112",
                            "fullNames": "辽宁省,沈阳市,东陵区",
                            "children": []
                        },
                        {
                            "id": "210113",
                            "code": "210113",
                            "name": "新城子区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210113",
                            "fullNames": "辽宁省,沈阳市,新城子区",
                            "children": []
                        },
                        {
                            "id": "210114",
                            "code": "210114",
                            "name": "于洪区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210114",
                            "fullNames": "辽宁省,沈阳市,于洪区",
                            "children": []
                        },
                        {
                            "id": "210122",
                            "code": "210122",
                            "name": "辽中县",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210122",
                            "fullNames": "辽宁省,沈阳市,辽中县",
                            "children": []
                        },
                        {
                            "id": "210123",
                            "code": "210123",
                            "name": "康平县",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210123",
                            "fullNames": "辽宁省,沈阳市,康平县",
                            "children": []
                        },
                        {
                            "id": "210124",
                            "code": "210124",
                            "name": "法库县",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210124",
                            "fullNames": "辽宁省,沈阳市,法库县",
                            "children": []
                        },
                        {
                            "id": "210181",
                            "code": "210181",
                            "name": "新民市",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210181",
                            "fullNames": "辽宁省,沈阳市,新民市",
                            "children": []
                        },
                        {
                            "id": "210182",
                            "code": "210182",
                            "name": "浑南新区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210182",
                            "fullNames": "辽宁省,沈阳市,浑南新区",
                            "children": []
                        },
                        {
                            "id": "210183",
                            "code": "210183",
                            "name": "张士开发区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210183",
                            "fullNames": "辽宁省,沈阳市,张士开发区",
                            "children": []
                        },
                        {
                            "id": "210184",
                            "code": "210184",
                            "name": "沈北新区",
                            "parentCode": "210100",
                            "fullCodes": "210000,210100,210184",
                            "fullNames": "辽宁省,沈阳市,沈北新区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "210200",
                    "code": "210200",
                    "name": "大连市",
                    "parentCode": "210000",
                    "fullCodes": "210000,210200",
                    "fullNames": "辽宁省,大连市",
                    "children": [
                        {
                            "id": "210202",
                            "code": "210202",
                            "name": "中山区",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210202",
                            "fullNames": "辽宁省,大连市,中山区",
                            "children": []
                        },
                        {
                            "id": "210203",
                            "code": "210203",
                            "name": "西岗区",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210203",
                            "fullNames": "辽宁省,大连市,西岗区",
                            "children": []
                        },
                        {
                            "id": "210204",
                            "code": "210204",
                            "name": "沙河口区",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210204",
                            "fullNames": "辽宁省,大连市,沙河口区",
                            "children": []
                        },
                        {
                            "id": "210211",
                            "code": "210211",
                            "name": "甘井子区",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210211",
                            "fullNames": "辽宁省,大连市,甘井子区",
                            "children": []
                        },
                        {
                            "id": "210212",
                            "code": "210212",
                            "name": "旅顺口区",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210212",
                            "fullNames": "辽宁省,大连市,旅顺口区",
                            "children": []
                        },
                        {
                            "id": "210213",
                            "code": "210213",
                            "name": "金州区",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210213",
                            "fullNames": "辽宁省,大连市,金州区",
                            "children": []
                        },
                        {
                            "id": "210224",
                            "code": "210224",
                            "name": "长海县",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210224",
                            "fullNames": "辽宁省,大连市,长海县",
                            "children": []
                        },
                        {
                            "id": "210251",
                            "code": "210251",
                            "name": "开发区",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210251",
                            "fullNames": "辽宁省,大连市,开发区",
                            "children": []
                        },
                        {
                            "id": "210281",
                            "code": "210281",
                            "name": "瓦房店市",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210281",
                            "fullNames": "辽宁省,大连市,瓦房店市",
                            "children": []
                        },
                        {
                            "id": "210282",
                            "code": "210282",
                            "name": "普兰店市",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210282",
                            "fullNames": "辽宁省,大连市,普兰店市",
                            "children": []
                        },
                        {
                            "id": "210283",
                            "code": "210283",
                            "name": "庄河市",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210283",
                            "fullNames": "辽宁省,大连市,庄河市",
                            "children": []
                        },
                        {
                            "id": "210297",
                            "code": "210297",
                            "name": "岭前区",
                            "parentCode": "210200",
                            "fullCodes": "210000,210200,210297",
                            "fullNames": "辽宁省,大连市,岭前区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "210300",
                    "code": "210300",
                    "name": "鞍山市",
                    "parentCode": "210000",
                    "fullCodes": "210000,210300",
                    "fullNames": "辽宁省,鞍山市",
                    "children": [
                        {
                            "id": "210302",
                            "code": "210302",
                            "name": "铁东区",
                            "parentCode": "210300",
                            "fullCodes": "210000,210300,210302",
                            "fullNames": "辽宁省,鞍山市,铁东区",
                            "children": []
                        },
                        {
                            "id": "210303",
                            "code": "210303",
                            "name": "铁西区",
                            "parentCode": "210300",
                            "fullCodes": "210000,210300,210303",
                            "fullNames": "辽宁省,鞍山市,铁西区",
                            "children": []
                        },
                        {
                            "id": "210304",
                            "code": "210304",
                            "name": "立山区",
                            "parentCode": "210300",
                            "fullCodes": "210000,210300,210304",
                            "fullNames": "辽宁省,鞍山市,立山区",
                            "children": []
                        },
                        {
                            "id": "210311",
                            "code": "210311",
                            "name": "千山区",
                            "parentCode": "210300",
                            "fullCodes": "210000,210300,210311",
                            "fullNames": "辽宁省,鞍山市,千山区",
                            "children": []
                        },
                        {
                            "id": "210321",
                            "code": "210321",
                            "name": "台安县",
                            "parentCode": "210300",
                            "fullCodes": "210000,210300,210321",
                            "fullNames": "辽宁省,鞍山市,台安县",
                            "children": []
                        },
                        {
                            "id": "210323",
                            "code": "210323",
                            "name": "岫岩满族自治县",
                            "parentCode": "210300",
                            "fullCodes": "210000,210300,210323",
                            "fullNames": "辽宁省,鞍山市,岫岩满族自治县",
                            "children": []
                        },
                        {
                            "id": "210351",
                            "code": "210351",
                            "name": "高新区",
                            "parentCode": "210300",
                            "fullCodes": "210000,210300,210351",
                            "fullNames": "辽宁省,鞍山市,高新区",
                            "children": []
                        },
                        {
                            "id": "210381",
                            "code": "210381",
                            "name": "海城市",
                            "parentCode": "210300",
                            "fullCodes": "210000,210300,210381",
                            "fullNames": "辽宁省,鞍山市,海城市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "210400",
                    "code": "210400",
                    "name": "抚顺市",
                    "parentCode": "210000",
                    "fullCodes": "210000,210400",
                    "fullNames": "辽宁省,抚顺市",
                    "children": [
                        {
                            "id": "210402",
                            "code": "210402",
                            "name": "新抚区",
                            "parentCode": "210400",
                            "fullCodes": "210000,210400,210402",
                            "fullNames": "辽宁省,抚顺市,新抚区",
                            "children": []
                        },
                        {
                            "id": "210403",
                            "code": "210403",
                            "name": "东洲区",
                            "parentCode": "210400",
                            "fullCodes": "210000,210400,210403",
                            "fullNames": "辽宁省,抚顺市,东洲区",
                            "children": []
                        },
                        {
                            "id": "210404",
                            "code": "210404",
                            "name": "望花区",
                            "parentCode": "210400",
                            "fullCodes": "210000,210400,210404",
                            "fullNames": "辽宁省,抚顺市,望花区",
                            "children": []
                        },
                        {
                            "id": "210411",
                            "code": "210411",
                            "name": "顺城区",
                            "parentCode": "210400",
                            "fullCodes": "210000,210400,210411",
                            "fullNames": "辽宁省,抚顺市,顺城区",
                            "children": []
                        },
                        {
                            "id": "210421",
                            "code": "210421",
                            "name": "抚顺县",
                            "parentCode": "210400",
                            "fullCodes": "210000,210400,210421",
                            "fullNames": "辽宁省,抚顺市,抚顺县",
                            "children": []
                        },
                        {
                            "id": "210422",
                            "code": "210422",
                            "name": "新宾满族自治县",
                            "parentCode": "210400",
                            "fullCodes": "210000,210400,210422",
                            "fullNames": "辽宁省,抚顺市,新宾满族自治县",
                            "children": []
                        },
                        {
                            "id": "210423",
                            "code": "210423",
                            "name": "清原满族自治县",
                            "parentCode": "210400",
                            "fullCodes": "210000,210400,210423",
                            "fullNames": "辽宁省,抚顺市,清原满族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "210500",
                    "code": "210500",
                    "name": "本溪市",
                    "parentCode": "210000",
                    "fullCodes": "210000,210500",
                    "fullNames": "辽宁省,本溪市",
                    "children": [
                        {
                            "id": "210502",
                            "code": "210502",
                            "name": "平山区",
                            "parentCode": "210500",
                            "fullCodes": "210000,210500,210502",
                            "fullNames": "辽宁省,本溪市,平山区",
                            "children": []
                        },
                        {
                            "id": "210503",
                            "code": "210503",
                            "name": "溪湖区",
                            "parentCode": "210500",
                            "fullCodes": "210000,210500,210503",
                            "fullNames": "辽宁省,本溪市,溪湖区",
                            "children": []
                        },
                        {
                            "id": "210504",
                            "code": "210504",
                            "name": "明山区",
                            "parentCode": "210500",
                            "fullCodes": "210000,210500,210504",
                            "fullNames": "辽宁省,本溪市,明山区",
                            "children": []
                        },
                        {
                            "id": "210505",
                            "code": "210505",
                            "name": "南芬区",
                            "parentCode": "210500",
                            "fullCodes": "210000,210500,210505",
                            "fullNames": "辽宁省,本溪市,南芬区",
                            "children": []
                        },
                        {
                            "id": "210521",
                            "code": "210521",
                            "name": "本溪满族自治县",
                            "parentCode": "210500",
                            "fullCodes": "210000,210500,210521",
                            "fullNames": "辽宁省,本溪市,本溪满族自治县",
                            "children": []
                        },
                        {
                            "id": "210522",
                            "code": "210522",
                            "name": "桓仁满族自治县",
                            "parentCode": "210500",
                            "fullCodes": "210000,210500,210522",
                            "fullNames": "辽宁省,本溪市,桓仁满族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "210600",
                    "code": "210600",
                    "name": "丹东市",
                    "parentCode": "210000",
                    "fullCodes": "210000,210600",
                    "fullNames": "辽宁省,丹东市",
                    "children": [
                        {
                            "id": "210602",
                            "code": "210602",
                            "name": "元宝区",
                            "parentCode": "210600",
                            "fullCodes": "210000,210600,210602",
                            "fullNames": "辽宁省,丹东市,元宝区",
                            "children": []
                        },
                        {
                            "id": "210603",
                            "code": "210603",
                            "name": "振兴区",
                            "parentCode": "210600",
                            "fullCodes": "210000,210600,210603",
                            "fullNames": "辽宁省,丹东市,振兴区",
                            "children": []
                        },
                        {
                            "id": "210604",
                            "code": "210604",
                            "name": "振安区",
                            "parentCode": "210600",
                            "fullCodes": "210000,210600,210604",
                            "fullNames": "辽宁省,丹东市,振安区",
                            "children": []
                        },
                        {
                            "id": "210624",
                            "code": "210624",
                            "name": "宽甸满族自治县",
                            "parentCode": "210600",
                            "fullCodes": "210000,210600,210624",
                            "fullNames": "辽宁省,丹东市,宽甸满族自治县",
                            "children": []
                        },
                        {
                            "id": "210681",
                            "code": "210681",
                            "name": "东港市",
                            "parentCode": "210600",
                            "fullCodes": "210000,210600,210681",
                            "fullNames": "辽宁省,丹东市,东港市",
                            "children": []
                        },
                        {
                            "id": "210682",
                            "code": "210682",
                            "name": "凤城市",
                            "parentCode": "210600",
                            "fullCodes": "210000,210600,210682",
                            "fullNames": "辽宁省,丹东市,凤城市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "210700",
                    "code": "210700",
                    "name": "锦州市",
                    "parentCode": "210000",
                    "fullCodes": "210000,210700",
                    "fullNames": "辽宁省,锦州市",
                    "children": [
                        {
                            "id": "210702",
                            "code": "210702",
                            "name": "古塔区",
                            "parentCode": "210700",
                            "fullCodes": "210000,210700,210702",
                            "fullNames": "辽宁省,锦州市,古塔区",
                            "children": []
                        },
                        {
                            "id": "210703",
                            "code": "210703",
                            "name": "凌河区",
                            "parentCode": "210700",
                            "fullCodes": "210000,210700,210703",
                            "fullNames": "辽宁省,锦州市,凌河区",
                            "children": []
                        },
                        {
                            "id": "210711",
                            "code": "210711",
                            "name": "太和区",
                            "parentCode": "210700",
                            "fullCodes": "210000,210700,210711",
                            "fullNames": "辽宁省,锦州市,太和区",
                            "children": []
                        },
                        {
                            "id": "210726",
                            "code": "210726",
                            "name": "黑山县",
                            "parentCode": "210700",
                            "fullCodes": "210000,210700,210726",
                            "fullNames": "辽宁省,锦州市,黑山县",
                            "children": []
                        },
                        {
                            "id": "210727",
                            "code": "210727",
                            "name": "义县",
                            "parentCode": "210700",
                            "fullCodes": "210000,210700,210727",
                            "fullNames": "辽宁省,锦州市,义县",
                            "children": []
                        },
                        {
                            "id": "210781",
                            "code": "210781",
                            "name": "凌海市",
                            "parentCode": "210700",
                            "fullCodes": "210000,210700,210781",
                            "fullNames": "辽宁省,锦州市,凌海市",
                            "children": []
                        },
                        {
                            "id": "210782",
                            "code": "210782",
                            "name": "北镇市",
                            "parentCode": "210700",
                            "fullCodes": "210000,210700,210782",
                            "fullNames": "辽宁省,锦州市,北镇市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "210800",
                    "code": "210800",
                    "name": "营口市",
                    "parentCode": "210000",
                    "fullCodes": "210000,210800",
                    "fullNames": "辽宁省,营口市",
                    "children": [
                        {
                            "id": "210802",
                            "code": "210802",
                            "name": "站前区",
                            "parentCode": "210800",
                            "fullCodes": "210000,210800,210802",
                            "fullNames": "辽宁省,营口市,站前区",
                            "children": []
                        },
                        {
                            "id": "210803",
                            "code": "210803",
                            "name": "西市区",
                            "parentCode": "210800",
                            "fullCodes": "210000,210800,210803",
                            "fullNames": "辽宁省,营口市,西市区",
                            "children": []
                        },
                        {
                            "id": "210804",
                            "code": "210804",
                            "name": "鲅鱼圈区",
                            "parentCode": "210800",
                            "fullCodes": "210000,210800,210804",
                            "fullNames": "辽宁省,营口市,鲅鱼圈区",
                            "children": []
                        },
                        {
                            "id": "210811",
                            "code": "210811",
                            "name": "老边区",
                            "parentCode": "210800",
                            "fullCodes": "210000,210800,210811",
                            "fullNames": "辽宁省,营口市,老边区",
                            "children": []
                        },
                        {
                            "id": "210881",
                            "code": "210881",
                            "name": "盖州市",
                            "parentCode": "210800",
                            "fullCodes": "210000,210800,210881",
                            "fullNames": "辽宁省,营口市,盖州市",
                            "children": []
                        },
                        {
                            "id": "210882",
                            "code": "210882",
                            "name": "大石桥市",
                            "parentCode": "210800",
                            "fullCodes": "210000,210800,210882",
                            "fullNames": "辽宁省,营口市,大石桥市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "210900",
                    "code": "210900",
                    "name": "阜新市",
                    "parentCode": "210000",
                    "fullCodes": "210000,210900",
                    "fullNames": "辽宁省,阜新市",
                    "children": [
                        {
                            "id": "210902",
                            "code": "210902",
                            "name": "海州区",
                            "parentCode": "210900",
                            "fullCodes": "210000,210900,210902",
                            "fullNames": "辽宁省,阜新市,海州区",
                            "children": []
                        },
                        {
                            "id": "210903",
                            "code": "210903",
                            "name": "新邱区",
                            "parentCode": "210900",
                            "fullCodes": "210000,210900,210903",
                            "fullNames": "辽宁省,阜新市,新邱区",
                            "children": []
                        },
                        {
                            "id": "210904",
                            "code": "210904",
                            "name": "太平区",
                            "parentCode": "210900",
                            "fullCodes": "210000,210900,210904",
                            "fullNames": "辽宁省,阜新市,太平区",
                            "children": []
                        },
                        {
                            "id": "210905",
                            "code": "210905",
                            "name": "清河门区",
                            "parentCode": "210900",
                            "fullCodes": "210000,210900,210905",
                            "fullNames": "辽宁省,阜新市,清河门区",
                            "children": []
                        },
                        {
                            "id": "210911",
                            "code": "210911",
                            "name": "细河区",
                            "parentCode": "210900",
                            "fullCodes": "210000,210900,210911",
                            "fullNames": "辽宁省,阜新市,细河区",
                            "children": []
                        },
                        {
                            "id": "210921",
                            "code": "210921",
                            "name": "阜新蒙古族自治县",
                            "parentCode": "210900",
                            "fullCodes": "210000,210900,210921",
                            "fullNames": "辽宁省,阜新市,阜新蒙古族自治县",
                            "children": []
                        },
                        {
                            "id": "210922",
                            "code": "210922",
                            "name": "彰武县",
                            "parentCode": "210900",
                            "fullCodes": "210000,210900,210922",
                            "fullNames": "辽宁省,阜新市,彰武县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "211000",
                    "code": "211000",
                    "name": "辽阳市",
                    "parentCode": "210000",
                    "fullCodes": "210000,211000",
                    "fullNames": "辽宁省,辽阳市",
                    "children": [
                        {
                            "id": "211002",
                            "code": "211002",
                            "name": "白塔区",
                            "parentCode": "211000",
                            "fullCodes": "210000,211000,211002",
                            "fullNames": "辽宁省,辽阳市,白塔区",
                            "children": []
                        },
                        {
                            "id": "211003",
                            "code": "211003",
                            "name": "文圣区",
                            "parentCode": "211000",
                            "fullCodes": "210000,211000,211003",
                            "fullNames": "辽宁省,辽阳市,文圣区",
                            "children": []
                        },
                        {
                            "id": "211004",
                            "code": "211004",
                            "name": "宏伟区",
                            "parentCode": "211000",
                            "fullCodes": "210000,211000,211004",
                            "fullNames": "辽宁省,辽阳市,宏伟区",
                            "children": []
                        },
                        {
                            "id": "211005",
                            "code": "211005",
                            "name": "弓长岭区",
                            "parentCode": "211000",
                            "fullCodes": "210000,211000,211005",
                            "fullNames": "辽宁省,辽阳市,弓长岭区",
                            "children": []
                        },
                        {
                            "id": "211011",
                            "code": "211011",
                            "name": "太子河区",
                            "parentCode": "211000",
                            "fullCodes": "210000,211000,211011",
                            "fullNames": "辽宁省,辽阳市,太子河区",
                            "children": []
                        },
                        {
                            "id": "211021",
                            "code": "211021",
                            "name": "辽阳县",
                            "parentCode": "211000",
                            "fullCodes": "210000,211000,211021",
                            "fullNames": "辽宁省,辽阳市,辽阳县",
                            "children": []
                        },
                        {
                            "id": "211081",
                            "code": "211081",
                            "name": "灯塔市",
                            "parentCode": "211000",
                            "fullCodes": "210000,211000,211081",
                            "fullNames": "辽宁省,辽阳市,灯塔市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "211100",
                    "code": "211100",
                    "name": "盘锦市",
                    "parentCode": "210000",
                    "fullCodes": "210000,211100",
                    "fullNames": "辽宁省,盘锦市",
                    "children": [
                        {
                            "id": "211102",
                            "code": "211102",
                            "name": "双台子区",
                            "parentCode": "211100",
                            "fullCodes": "210000,211100,211102",
                            "fullNames": "辽宁省,盘锦市,双台子区",
                            "children": []
                        },
                        {
                            "id": "211103",
                            "code": "211103",
                            "name": "兴隆台区",
                            "parentCode": "211100",
                            "fullCodes": "210000,211100,211103",
                            "fullNames": "辽宁省,盘锦市,兴隆台区",
                            "children": []
                        },
                        {
                            "id": "211121",
                            "code": "211121",
                            "name": "大洼县",
                            "parentCode": "211100",
                            "fullCodes": "210000,211100,211121",
                            "fullNames": "辽宁省,盘锦市,大洼县",
                            "children": []
                        },
                        {
                            "id": "211122",
                            "code": "211122",
                            "name": "盘山县",
                            "parentCode": "211100",
                            "fullCodes": "210000,211100,211122",
                            "fullNames": "辽宁省,盘锦市,盘山县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "211200",
                    "code": "211200",
                    "name": "铁岭市",
                    "parentCode": "210000",
                    "fullCodes": "210000,211200",
                    "fullNames": "辽宁省,铁岭市",
                    "children": [
                        {
                            "id": "211202",
                            "code": "211202",
                            "name": "银州区",
                            "parentCode": "211200",
                            "fullCodes": "210000,211200,211202",
                            "fullNames": "辽宁省,铁岭市,银州区",
                            "children": []
                        },
                        {
                            "id": "211204",
                            "code": "211204",
                            "name": "清河区",
                            "parentCode": "211200",
                            "fullCodes": "210000,211200,211204",
                            "fullNames": "辽宁省,铁岭市,清河区",
                            "children": []
                        },
                        {
                            "id": "211221",
                            "code": "211221",
                            "name": "铁岭县",
                            "parentCode": "211200",
                            "fullCodes": "210000,211200,211221",
                            "fullNames": "辽宁省,铁岭市,铁岭县",
                            "children": []
                        },
                        {
                            "id": "211223",
                            "code": "211223",
                            "name": "西丰县",
                            "parentCode": "211200",
                            "fullCodes": "210000,211200,211223",
                            "fullNames": "辽宁省,铁岭市,西丰县",
                            "children": []
                        },
                        {
                            "id": "211224",
                            "code": "211224",
                            "name": "昌图县",
                            "parentCode": "211200",
                            "fullCodes": "210000,211200,211224",
                            "fullNames": "辽宁省,铁岭市,昌图县",
                            "children": []
                        },
                        {
                            "id": "211281",
                            "code": "211281",
                            "name": "调兵山市",
                            "parentCode": "211200",
                            "fullCodes": "210000,211200,211281",
                            "fullNames": "辽宁省,铁岭市,调兵山市",
                            "children": []
                        },
                        {
                            "id": "211282",
                            "code": "211282",
                            "name": "开原市",
                            "parentCode": "211200",
                            "fullCodes": "210000,211200,211282",
                            "fullNames": "辽宁省,铁岭市,开原市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "211300",
                    "code": "211300",
                    "name": "朝阳市",
                    "parentCode": "210000",
                    "fullCodes": "210000,211300",
                    "fullNames": "辽宁省,朝阳市",
                    "children": [
                        {
                            "id": "211302",
                            "code": "211302",
                            "name": "双塔区",
                            "parentCode": "211300",
                            "fullCodes": "210000,211300,211302",
                            "fullNames": "辽宁省,朝阳市,双塔区",
                            "children": []
                        },
                        {
                            "id": "211303",
                            "code": "211303",
                            "name": "龙城区",
                            "parentCode": "211300",
                            "fullCodes": "210000,211300,211303",
                            "fullNames": "辽宁省,朝阳市,龙城区",
                            "children": []
                        },
                        {
                            "id": "211321",
                            "code": "211321",
                            "name": "朝阳县",
                            "parentCode": "211300",
                            "fullCodes": "210000,211300,211321",
                            "fullNames": "辽宁省,朝阳市,朝阳县",
                            "children": []
                        },
                        {
                            "id": "211322",
                            "code": "211322",
                            "name": "建平县",
                            "parentCode": "211300",
                            "fullCodes": "210000,211300,211322",
                            "fullNames": "辽宁省,朝阳市,建平县",
                            "children": []
                        },
                        {
                            "id": "211324",
                            "code": "211324",
                            "name": "喀喇沁左翼蒙古族自治县",
                            "parentCode": "211300",
                            "fullCodes": "210000,211300,211324",
                            "fullNames": "辽宁省,朝阳市,喀喇沁左翼蒙古族自治县",
                            "children": []
                        },
                        {
                            "id": "211381",
                            "code": "211381",
                            "name": "北票市",
                            "parentCode": "211300",
                            "fullCodes": "210000,211300,211381",
                            "fullNames": "辽宁省,朝阳市,北票市",
                            "children": []
                        },
                        {
                            "id": "211382",
                            "code": "211382",
                            "name": "凌源市",
                            "parentCode": "211300",
                            "fullCodes": "210000,211300,211382",
                            "fullNames": "辽宁省,朝阳市,凌源市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "211400",
                    "code": "211400",
                    "name": "葫芦岛市",
                    "parentCode": "210000",
                    "fullCodes": "210000,211400",
                    "fullNames": "辽宁省,葫芦岛市",
                    "children": [
                        {
                            "id": "211402",
                            "code": "211402",
                            "name": "连山区",
                            "parentCode": "211400",
                            "fullCodes": "210000,211400,211402",
                            "fullNames": "辽宁省,葫芦岛市,连山区",
                            "children": []
                        },
                        {
                            "id": "211403",
                            "code": "211403",
                            "name": "龙港区",
                            "parentCode": "211400",
                            "fullCodes": "210000,211400,211403",
                            "fullNames": "辽宁省,葫芦岛市,龙港区",
                            "children": []
                        },
                        {
                            "id": "211404",
                            "code": "211404",
                            "name": "南票区",
                            "parentCode": "211400",
                            "fullCodes": "210000,211400,211404",
                            "fullNames": "辽宁省,葫芦岛市,南票区",
                            "children": []
                        },
                        {
                            "id": "211421",
                            "code": "211421",
                            "name": "绥中县",
                            "parentCode": "211400",
                            "fullCodes": "210000,211400,211421",
                            "fullNames": "辽宁省,葫芦岛市,绥中县",
                            "children": []
                        },
                        {
                            "id": "211422",
                            "code": "211422",
                            "name": "建昌县",
                            "parentCode": "211400",
                            "fullCodes": "210000,211400,211422",
                            "fullNames": "辽宁省,葫芦岛市,建昌县",
                            "children": []
                        },
                        {
                            "id": "211481",
                            "code": "211481",
                            "name": "兴城市",
                            "parentCode": "211400",
                            "fullCodes": "210000,211400,211481",
                            "fullNames": "辽宁省,葫芦岛市,兴城市",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "220000",
            "code": "220000",
            "name": "吉林省",
            "parentCode": "-1",
            "fullCodes": "220000",
            "fullNames": "吉林省",
            "children": [
                {
                    "id": "220100",
                    "code": "220100",
                    "name": "长春市",
                    "parentCode": "220000",
                    "fullCodes": "220000,220100",
                    "fullNames": "吉林省,长春市",
                    "children": [
                        {
                            "id": "220102",
                            "code": "220102",
                            "name": "南关区",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220102",
                            "fullNames": "吉林省,长春市,南关区",
                            "children": []
                        },
                        {
                            "id": "220103",
                            "code": "220103",
                            "name": "宽城区",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220103",
                            "fullNames": "吉林省,长春市,宽城区",
                            "children": []
                        },
                        {
                            "id": "220104",
                            "code": "220104",
                            "name": "朝阳区",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220104",
                            "fullNames": "吉林省,长春市,朝阳区",
                            "children": []
                        },
                        {
                            "id": "220105",
                            "code": "220105",
                            "name": "二道区",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220105",
                            "fullNames": "吉林省,长春市,二道区",
                            "children": []
                        },
                        {
                            "id": "220106",
                            "code": "220106",
                            "name": "绿园区",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220106",
                            "fullNames": "吉林省,长春市,绿园区",
                            "children": []
                        },
                        {
                            "id": "220112",
                            "code": "220112",
                            "name": "双阳区",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220112",
                            "fullNames": "吉林省,长春市,双阳区",
                            "children": []
                        },
                        {
                            "id": "220122",
                            "code": "220122",
                            "name": "农安县",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220122",
                            "fullNames": "吉林省,长春市,农安县",
                            "children": []
                        },
                        {
                            "id": "220181",
                            "code": "220181",
                            "name": "九台市",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220181",
                            "fullNames": "吉林省,长春市,九台市",
                            "children": []
                        },
                        {
                            "id": "220182",
                            "code": "220182",
                            "name": "榆树市",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220182",
                            "fullNames": "吉林省,长春市,榆树市",
                            "children": []
                        },
                        {
                            "id": "220183",
                            "code": "220183",
                            "name": "德惠市",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220183",
                            "fullNames": "吉林省,长春市,德惠市",
                            "children": []
                        },
                        {
                            "id": "220184",
                            "code": "220184",
                            "name": "高新技术产业开发区",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220184",
                            "fullNames": "吉林省,长春市,高新技术产业开发区",
                            "children": []
                        },
                        {
                            "id": "220185",
                            "code": "220185",
                            "name": "汽车产业开发区",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220185",
                            "fullNames": "吉林省,长春市,汽车产业开发区",
                            "children": []
                        },
                        {
                            "id": "220186",
                            "code": "220186",
                            "name": "经济技术开发区",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220186",
                            "fullNames": "吉林省,长春市,经济技术开发区",
                            "children": []
                        },
                        {
                            "id": "220187",
                            "code": "220187",
                            "name": "净月旅游开发区",
                            "parentCode": "220100",
                            "fullCodes": "220000,220100,220187",
                            "fullNames": "吉林省,长春市,净月旅游开发区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "220200",
                    "code": "220200",
                    "name": "吉林市",
                    "parentCode": "220000",
                    "fullCodes": "220000,220200",
                    "fullNames": "吉林省,吉林市",
                    "children": [
                        {
                            "id": "220202",
                            "code": "220202",
                            "name": "昌邑区",
                            "parentCode": "220200",
                            "fullCodes": "220000,220200,220202",
                            "fullNames": "吉林省,吉林市,昌邑区",
                            "children": []
                        },
                        {
                            "id": "220203",
                            "code": "220203",
                            "name": "龙潭区",
                            "parentCode": "220200",
                            "fullCodes": "220000,220200,220203",
                            "fullNames": "吉林省,吉林市,龙潭区",
                            "children": []
                        },
                        {
                            "id": "220204",
                            "code": "220204",
                            "name": "船营区",
                            "parentCode": "220200",
                            "fullCodes": "220000,220200,220204",
                            "fullNames": "吉林省,吉林市,船营区",
                            "children": []
                        },
                        {
                            "id": "220211",
                            "code": "220211",
                            "name": "丰满区",
                            "parentCode": "220200",
                            "fullCodes": "220000,220200,220211",
                            "fullNames": "吉林省,吉林市,丰满区",
                            "children": []
                        },
                        {
                            "id": "220221",
                            "code": "220221",
                            "name": "永吉县",
                            "parentCode": "220200",
                            "fullCodes": "220000,220200,220221",
                            "fullNames": "吉林省,吉林市,永吉县",
                            "children": []
                        },
                        {
                            "id": "220281",
                            "code": "220281",
                            "name": "蛟河市",
                            "parentCode": "220200",
                            "fullCodes": "220000,220200,220281",
                            "fullNames": "吉林省,吉林市,蛟河市",
                            "children": []
                        },
                        {
                            "id": "220282",
                            "code": "220282",
                            "name": "桦甸市",
                            "parentCode": "220200",
                            "fullCodes": "220000,220200,220282",
                            "fullNames": "吉林省,吉林市,桦甸市",
                            "children": []
                        },
                        {
                            "id": "220283",
                            "code": "220283",
                            "name": "舒兰市",
                            "parentCode": "220200",
                            "fullCodes": "220000,220200,220283",
                            "fullNames": "吉林省,吉林市,舒兰市",
                            "children": []
                        },
                        {
                            "id": "220284",
                            "code": "220284",
                            "name": "磐石市",
                            "parentCode": "220200",
                            "fullCodes": "220000,220200,220284",
                            "fullNames": "吉林省,吉林市,磐石市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "220300",
                    "code": "220300",
                    "name": "四平市",
                    "parentCode": "220000",
                    "fullCodes": "220000,220300",
                    "fullNames": "吉林省,四平市",
                    "children": [
                        {
                            "id": "220302",
                            "code": "220302",
                            "name": "铁西区",
                            "parentCode": "220300",
                            "fullCodes": "220000,220300,220302",
                            "fullNames": "吉林省,四平市,铁西区",
                            "children": []
                        },
                        {
                            "id": "220303",
                            "code": "220303",
                            "name": "铁东区",
                            "parentCode": "220300",
                            "fullCodes": "220000,220300,220303",
                            "fullNames": "吉林省,四平市,铁东区",
                            "children": []
                        },
                        {
                            "id": "220322",
                            "code": "220322",
                            "name": "梨树县",
                            "parentCode": "220300",
                            "fullCodes": "220000,220300,220322",
                            "fullNames": "吉林省,四平市,梨树县",
                            "children": []
                        },
                        {
                            "id": "220323",
                            "code": "220323",
                            "name": "伊通满族自治县",
                            "parentCode": "220300",
                            "fullCodes": "220000,220300,220323",
                            "fullNames": "吉林省,四平市,伊通满族自治县",
                            "children": []
                        },
                        {
                            "id": "220381",
                            "code": "220381",
                            "name": "公主岭市",
                            "parentCode": "220300",
                            "fullCodes": "220000,220300,220381",
                            "fullNames": "吉林省,四平市,公主岭市",
                            "children": []
                        },
                        {
                            "id": "220382",
                            "code": "220382",
                            "name": "双辽市",
                            "parentCode": "220300",
                            "fullCodes": "220000,220300,220382",
                            "fullNames": "吉林省,四平市,双辽市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "220400",
                    "code": "220400",
                    "name": "辽源市",
                    "parentCode": "220000",
                    "fullCodes": "220000,220400",
                    "fullNames": "吉林省,辽源市",
                    "children": [
                        {
                            "id": "220402",
                            "code": "220402",
                            "name": "龙山区",
                            "parentCode": "220400",
                            "fullCodes": "220000,220400,220402",
                            "fullNames": "吉林省,辽源市,龙山区",
                            "children": []
                        },
                        {
                            "id": "220403",
                            "code": "220403",
                            "name": "西安区",
                            "parentCode": "220400",
                            "fullCodes": "220000,220400,220403",
                            "fullNames": "吉林省,辽源市,西安区",
                            "children": []
                        },
                        {
                            "id": "220421",
                            "code": "220421",
                            "name": "东丰县",
                            "parentCode": "220400",
                            "fullCodes": "220000,220400,220421",
                            "fullNames": "吉林省,辽源市,东丰县",
                            "children": []
                        },
                        {
                            "id": "220422",
                            "code": "220422",
                            "name": "东辽县",
                            "parentCode": "220400",
                            "fullCodes": "220000,220400,220422",
                            "fullNames": "吉林省,辽源市,东辽县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "220500",
                    "code": "220500",
                    "name": "通化市",
                    "parentCode": "220000",
                    "fullCodes": "220000,220500",
                    "fullNames": "吉林省,通化市",
                    "children": [
                        {
                            "id": "220502",
                            "code": "220502",
                            "name": "东昌区",
                            "parentCode": "220500",
                            "fullCodes": "220000,220500,220502",
                            "fullNames": "吉林省,通化市,东昌区",
                            "children": []
                        },
                        {
                            "id": "220503",
                            "code": "220503",
                            "name": "二道江区",
                            "parentCode": "220500",
                            "fullCodes": "220000,220500,220503",
                            "fullNames": "吉林省,通化市,二道江区",
                            "children": []
                        },
                        {
                            "id": "220521",
                            "code": "220521",
                            "name": "通化县",
                            "parentCode": "220500",
                            "fullCodes": "220000,220500,220521",
                            "fullNames": "吉林省,通化市,通化县",
                            "children": []
                        },
                        {
                            "id": "220523",
                            "code": "220523",
                            "name": "辉南县",
                            "parentCode": "220500",
                            "fullCodes": "220000,220500,220523",
                            "fullNames": "吉林省,通化市,辉南县",
                            "children": []
                        },
                        {
                            "id": "220524",
                            "code": "220524",
                            "name": "柳河县",
                            "parentCode": "220500",
                            "fullCodes": "220000,220500,220524",
                            "fullNames": "吉林省,通化市,柳河县",
                            "children": []
                        },
                        {
                            "id": "220581",
                            "code": "220581",
                            "name": "梅河口市",
                            "parentCode": "220500",
                            "fullCodes": "220000,220500,220581",
                            "fullNames": "吉林省,通化市,梅河口市",
                            "children": []
                        },
                        {
                            "id": "220582",
                            "code": "220582",
                            "name": "集安市",
                            "parentCode": "220500",
                            "fullCodes": "220000,220500,220582",
                            "fullNames": "吉林省,通化市,集安市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "220600",
                    "code": "220600",
                    "name": "白山市",
                    "parentCode": "220000",
                    "fullCodes": "220000,220600",
                    "fullNames": "吉林省,白山市",
                    "children": [
                        {
                            "id": "220602",
                            "code": "220602",
                            "name": "八道江区",
                            "parentCode": "220600",
                            "fullCodes": "220000,220600,220602",
                            "fullNames": "吉林省,白山市,八道江区",
                            "children": []
                        },
                        {
                            "id": "220621",
                            "code": "220621",
                            "name": "抚松县",
                            "parentCode": "220600",
                            "fullCodes": "220000,220600,220621",
                            "fullNames": "吉林省,白山市,抚松县",
                            "children": []
                        },
                        {
                            "id": "220622",
                            "code": "220622",
                            "name": "靖宇县",
                            "parentCode": "220600",
                            "fullCodes": "220000,220600,220622",
                            "fullNames": "吉林省,白山市,靖宇县",
                            "children": []
                        },
                        {
                            "id": "220623",
                            "code": "220623",
                            "name": "长白朝鲜族自治县",
                            "parentCode": "220600",
                            "fullCodes": "220000,220600,220623",
                            "fullNames": "吉林省,白山市,长白朝鲜族自治县",
                            "children": []
                        },
                        {
                            "id": "220625",
                            "code": "220625",
                            "name": "江源县",
                            "parentCode": "220600",
                            "fullCodes": "220000,220600,220625",
                            "fullNames": "吉林省,白山市,江源县",
                            "children": []
                        },
                        {
                            "id": "220681",
                            "code": "220681",
                            "name": "临江市",
                            "parentCode": "220600",
                            "fullCodes": "220000,220600,220681",
                            "fullNames": "吉林省,白山市,临江市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "220700",
                    "code": "220700",
                    "name": "松原市",
                    "parentCode": "220000",
                    "fullCodes": "220000,220700",
                    "fullNames": "吉林省,松原市",
                    "children": [
                        {
                            "id": "220702",
                            "code": "220702",
                            "name": "宁江区",
                            "parentCode": "220700",
                            "fullCodes": "220000,220700,220702",
                            "fullNames": "吉林省,松原市,宁江区",
                            "children": []
                        },
                        {
                            "id": "220721",
                            "code": "220721",
                            "name": "前郭尔罗斯蒙古族自治县",
                            "parentCode": "220700",
                            "fullCodes": "220000,220700,220721",
                            "fullNames": "吉林省,松原市,前郭尔罗斯蒙古族自治县",
                            "children": []
                        },
                        {
                            "id": "220722",
                            "code": "220722",
                            "name": "长岭县",
                            "parentCode": "220700",
                            "fullCodes": "220000,220700,220722",
                            "fullNames": "吉林省,松原市,长岭县",
                            "children": []
                        },
                        {
                            "id": "220723",
                            "code": "220723",
                            "name": "乾安县",
                            "parentCode": "220700",
                            "fullCodes": "220000,220700,220723",
                            "fullNames": "吉林省,松原市,乾安县",
                            "children": []
                        },
                        {
                            "id": "220724",
                            "code": "220724",
                            "name": "扶余县",
                            "parentCode": "220700",
                            "fullCodes": "220000,220700,220724",
                            "fullNames": "吉林省,松原市,扶余县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "220800",
                    "code": "220800",
                    "name": "白城市",
                    "parentCode": "220000",
                    "fullCodes": "220000,220800",
                    "fullNames": "吉林省,白城市",
                    "children": [
                        {
                            "id": "220802",
                            "code": "220802",
                            "name": "洮北区",
                            "parentCode": "220800",
                            "fullCodes": "220000,220800,220802",
                            "fullNames": "吉林省,白城市,洮北区",
                            "children": []
                        },
                        {
                            "id": "220821",
                            "code": "220821",
                            "name": "镇赉县",
                            "parentCode": "220800",
                            "fullCodes": "220000,220800,220821",
                            "fullNames": "吉林省,白城市,镇赉县",
                            "children": []
                        },
                        {
                            "id": "220822",
                            "code": "220822",
                            "name": "通榆县",
                            "parentCode": "220800",
                            "fullCodes": "220000,220800,220822",
                            "fullNames": "吉林省,白城市,通榆县",
                            "children": []
                        },
                        {
                            "id": "220881",
                            "code": "220881",
                            "name": "洮南市",
                            "parentCode": "220800",
                            "fullCodes": "220000,220800,220881",
                            "fullNames": "吉林省,白城市,洮南市",
                            "children": []
                        },
                        {
                            "id": "220882",
                            "code": "220882",
                            "name": "大安市",
                            "parentCode": "220800",
                            "fullCodes": "220000,220800,220882",
                            "fullNames": "吉林省,白城市,大安市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "222400",
                    "code": "222400",
                    "name": "延边朝鲜族自治州",
                    "parentCode": "220000",
                    "fullCodes": "220000,222400",
                    "fullNames": "吉林省,延边朝鲜族自治州",
                    "children": [
                        {
                            "id": "222401",
                            "code": "222401",
                            "name": "延吉市",
                            "parentCode": "222400",
                            "fullCodes": "220000,222400,222401",
                            "fullNames": "吉林省,延边朝鲜族自治州,延吉市",
                            "children": []
                        },
                        {
                            "id": "222402",
                            "code": "222402",
                            "name": "图们市",
                            "parentCode": "222400",
                            "fullCodes": "220000,222400,222402",
                            "fullNames": "吉林省,延边朝鲜族自治州,图们市",
                            "children": []
                        },
                        {
                            "id": "222403",
                            "code": "222403",
                            "name": "敦化市",
                            "parentCode": "222400",
                            "fullCodes": "220000,222400,222403",
                            "fullNames": "吉林省,延边朝鲜族自治州,敦化市",
                            "children": []
                        },
                        {
                            "id": "222404",
                            "code": "222404",
                            "name": "珲春市",
                            "parentCode": "222400",
                            "fullCodes": "220000,222400,222404",
                            "fullNames": "吉林省,延边朝鲜族自治州,珲春市",
                            "children": []
                        },
                        {
                            "id": "222405",
                            "code": "222405",
                            "name": "龙井市",
                            "parentCode": "222400",
                            "fullCodes": "220000,222400,222405",
                            "fullNames": "吉林省,延边朝鲜族自治州,龙井市",
                            "children": []
                        },
                        {
                            "id": "222406",
                            "code": "222406",
                            "name": "和龙市",
                            "parentCode": "222400",
                            "fullCodes": "220000,222400,222406",
                            "fullNames": "吉林省,延边朝鲜族自治州,和龙市",
                            "children": []
                        },
                        {
                            "id": "222424",
                            "code": "222424",
                            "name": "汪清县",
                            "parentCode": "222400",
                            "fullCodes": "220000,222400,222424",
                            "fullNames": "吉林省,延边朝鲜族自治州,汪清县",
                            "children": []
                        },
                        {
                            "id": "222426",
                            "code": "222426",
                            "name": "安图县",
                            "parentCode": "222400",
                            "fullCodes": "220000,222400,222426",
                            "fullNames": "吉林省,延边朝鲜族自治州,安图县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "230000",
            "code": "230000",
            "name": "黑龙江省",
            "parentCode": "-1",
            "fullCodes": "230000",
            "fullNames": "黑龙江省",
            "children": [
                {
                    "id": "230100",
                    "code": "230100",
                    "name": "哈尔滨市",
                    "parentCode": "230000",
                    "fullCodes": "230000,230100",
                    "fullNames": "黑龙江省,哈尔滨市",
                    "children": [
                        {
                            "id": "230102",
                            "code": "230102",
                            "name": "道里区",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230102",
                            "fullNames": "黑龙江省,哈尔滨市,道里区",
                            "children": []
                        },
                        {
                            "id": "230103",
                            "code": "230103",
                            "name": "南岗区",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230103",
                            "fullNames": "黑龙江省,哈尔滨市,南岗区",
                            "children": []
                        },
                        {
                            "id": "230104",
                            "code": "230104",
                            "name": "道外区",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230104",
                            "fullNames": "黑龙江省,哈尔滨市,道外区",
                            "children": []
                        },
                        {
                            "id": "230106",
                            "code": "230106",
                            "name": "香坊区",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230106",
                            "fullNames": "黑龙江省,哈尔滨市,香坊区",
                            "children": []
                        },
                        {
                            "id": "230107",
                            "code": "230107",
                            "name": "动力区",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230107",
                            "fullNames": "黑龙江省,哈尔滨市,动力区",
                            "children": []
                        },
                        {
                            "id": "230108",
                            "code": "230108",
                            "name": "平房区",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230108",
                            "fullNames": "黑龙江省,哈尔滨市,平房区",
                            "children": []
                        },
                        {
                            "id": "230109",
                            "code": "230109",
                            "name": "松北区",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230109",
                            "fullNames": "黑龙江省,哈尔滨市,松北区",
                            "children": []
                        },
                        {
                            "id": "230111",
                            "code": "230111",
                            "name": "呼兰区",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230111",
                            "fullNames": "黑龙江省,哈尔滨市,呼兰区",
                            "children": []
                        },
                        {
                            "id": "230123",
                            "code": "230123",
                            "name": "依兰县",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230123",
                            "fullNames": "黑龙江省,哈尔滨市,依兰县",
                            "children": []
                        },
                        {
                            "id": "230124",
                            "code": "230124",
                            "name": "方正县",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230124",
                            "fullNames": "黑龙江省,哈尔滨市,方正县",
                            "children": []
                        },
                        {
                            "id": "230125",
                            "code": "230125",
                            "name": "宾县",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230125",
                            "fullNames": "黑龙江省,哈尔滨市,宾县",
                            "children": []
                        },
                        {
                            "id": "230126",
                            "code": "230126",
                            "name": "巴彦县",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230126",
                            "fullNames": "黑龙江省,哈尔滨市,巴彦县",
                            "children": []
                        },
                        {
                            "id": "230127",
                            "code": "230127",
                            "name": "木兰县",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230127",
                            "fullNames": "黑龙江省,哈尔滨市,木兰县",
                            "children": []
                        },
                        {
                            "id": "230128",
                            "code": "230128",
                            "name": "通河县",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230128",
                            "fullNames": "黑龙江省,哈尔滨市,通河县",
                            "children": []
                        },
                        {
                            "id": "230129",
                            "code": "230129",
                            "name": "延寿县",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230129",
                            "fullNames": "黑龙江省,哈尔滨市,延寿县",
                            "children": []
                        },
                        {
                            "id": "230181",
                            "code": "230181",
                            "name": "阿城市",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230181",
                            "fullNames": "黑龙江省,哈尔滨市,阿城市",
                            "children": []
                        },
                        {
                            "id": "230182",
                            "code": "230182",
                            "name": "双城市",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230182",
                            "fullNames": "黑龙江省,哈尔滨市,双城市",
                            "children": []
                        },
                        {
                            "id": "230183",
                            "code": "230183",
                            "name": "尚志市",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230183",
                            "fullNames": "黑龙江省,哈尔滨市,尚志市",
                            "children": []
                        },
                        {
                            "id": "230184",
                            "code": "230184",
                            "name": "五常市",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230184",
                            "fullNames": "黑龙江省,哈尔滨市,五常市",
                            "children": []
                        },
                        {
                            "id": "230185",
                            "code": "230185",
                            "name": "阿城市",
                            "parentCode": "230100",
                            "fullCodes": "230000,230100,230185",
                            "fullNames": "黑龙江省,哈尔滨市,阿城市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "230200",
                    "code": "230200",
                    "name": "齐齐哈尔市",
                    "parentCode": "230000",
                    "fullCodes": "230000,230200",
                    "fullNames": "黑龙江省,齐齐哈尔市",
                    "children": [
                        {
                            "id": "230202",
                            "code": "230202",
                            "name": "龙沙区",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230202",
                            "fullNames": "黑龙江省,齐齐哈尔市,龙沙区",
                            "children": []
                        },
                        {
                            "id": "230203",
                            "code": "230203",
                            "name": "建华区",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230203",
                            "fullNames": "黑龙江省,齐齐哈尔市,建华区",
                            "children": []
                        },
                        {
                            "id": "230204",
                            "code": "230204",
                            "name": "铁锋区",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230204",
                            "fullNames": "黑龙江省,齐齐哈尔市,铁锋区",
                            "children": []
                        },
                        {
                            "id": "230205",
                            "code": "230205",
                            "name": "昂昂溪区",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230205",
                            "fullNames": "黑龙江省,齐齐哈尔市,昂昂溪区",
                            "children": []
                        },
                        {
                            "id": "230206",
                            "code": "230206",
                            "name": "富拉尔基区",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230206",
                            "fullNames": "黑龙江省,齐齐哈尔市,富拉尔基区",
                            "children": []
                        },
                        {
                            "id": "230207",
                            "code": "230207",
                            "name": "碾子山区",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230207",
                            "fullNames": "黑龙江省,齐齐哈尔市,碾子山区",
                            "children": []
                        },
                        {
                            "id": "230208",
                            "code": "230208",
                            "name": "梅里斯达斡尔族区",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230208",
                            "fullNames": "黑龙江省,齐齐哈尔市,梅里斯达斡尔族区",
                            "children": []
                        },
                        {
                            "id": "230221",
                            "code": "230221",
                            "name": "龙江县",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230221",
                            "fullNames": "黑龙江省,齐齐哈尔市,龙江县",
                            "children": []
                        },
                        {
                            "id": "230223",
                            "code": "230223",
                            "name": "依安县",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230223",
                            "fullNames": "黑龙江省,齐齐哈尔市,依安县",
                            "children": []
                        },
                        {
                            "id": "230224",
                            "code": "230224",
                            "name": "泰来县",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230224",
                            "fullNames": "黑龙江省,齐齐哈尔市,泰来县",
                            "children": []
                        },
                        {
                            "id": "230225",
                            "code": "230225",
                            "name": "甘南县",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230225",
                            "fullNames": "黑龙江省,齐齐哈尔市,甘南县",
                            "children": []
                        },
                        {
                            "id": "230227",
                            "code": "230227",
                            "name": "富裕县",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230227",
                            "fullNames": "黑龙江省,齐齐哈尔市,富裕县",
                            "children": []
                        },
                        {
                            "id": "230229",
                            "code": "230229",
                            "name": "克山县",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230229",
                            "fullNames": "黑龙江省,齐齐哈尔市,克山县",
                            "children": []
                        },
                        {
                            "id": "230230",
                            "code": "230230",
                            "name": "克东县",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230230",
                            "fullNames": "黑龙江省,齐齐哈尔市,克东县",
                            "children": []
                        },
                        {
                            "id": "230231",
                            "code": "230231",
                            "name": "拜泉县",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230231",
                            "fullNames": "黑龙江省,齐齐哈尔市,拜泉县",
                            "children": []
                        },
                        {
                            "id": "230281",
                            "code": "230281",
                            "name": "讷河市",
                            "parentCode": "230200",
                            "fullCodes": "230000,230200,230281",
                            "fullNames": "黑龙江省,齐齐哈尔市,讷河市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "230300",
                    "code": "230300",
                    "name": "鸡西市",
                    "parentCode": "230000",
                    "fullCodes": "230000,230300",
                    "fullNames": "黑龙江省,鸡西市",
                    "children": [
                        {
                            "id": "230302",
                            "code": "230302",
                            "name": "鸡冠区",
                            "parentCode": "230300",
                            "fullCodes": "230000,230300,230302",
                            "fullNames": "黑龙江省,鸡西市,鸡冠区",
                            "children": []
                        },
                        {
                            "id": "230303",
                            "code": "230303",
                            "name": "恒山区",
                            "parentCode": "230300",
                            "fullCodes": "230000,230300,230303",
                            "fullNames": "黑龙江省,鸡西市,恒山区",
                            "children": []
                        },
                        {
                            "id": "230304",
                            "code": "230304",
                            "name": "滴道区",
                            "parentCode": "230300",
                            "fullCodes": "230000,230300,230304",
                            "fullNames": "黑龙江省,鸡西市,滴道区",
                            "children": []
                        },
                        {
                            "id": "230305",
                            "code": "230305",
                            "name": "梨树区",
                            "parentCode": "230300",
                            "fullCodes": "230000,230300,230305",
                            "fullNames": "黑龙江省,鸡西市,梨树区",
                            "children": []
                        },
                        {
                            "id": "230306",
                            "code": "230306",
                            "name": "城子河区",
                            "parentCode": "230300",
                            "fullCodes": "230000,230300,230306",
                            "fullNames": "黑龙江省,鸡西市,城子河区",
                            "children": []
                        },
                        {
                            "id": "230307",
                            "code": "230307",
                            "name": "麻山区",
                            "parentCode": "230300",
                            "fullCodes": "230000,230300,230307",
                            "fullNames": "黑龙江省,鸡西市,麻山区",
                            "children": []
                        },
                        {
                            "id": "230321",
                            "code": "230321",
                            "name": "鸡东县",
                            "parentCode": "230300",
                            "fullCodes": "230000,230300,230321",
                            "fullNames": "黑龙江省,鸡西市,鸡东县",
                            "children": []
                        },
                        {
                            "id": "230381",
                            "code": "230381",
                            "name": "虎林市",
                            "parentCode": "230300",
                            "fullCodes": "230000,230300,230381",
                            "fullNames": "黑龙江省,鸡西市,虎林市",
                            "children": []
                        },
                        {
                            "id": "230382",
                            "code": "230382",
                            "name": "密山市",
                            "parentCode": "230300",
                            "fullCodes": "230000,230300,230382",
                            "fullNames": "黑龙江省,鸡西市,密山市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "230400",
                    "code": "230400",
                    "name": "鹤岗市",
                    "parentCode": "230000",
                    "fullCodes": "230000,230400",
                    "fullNames": "黑龙江省,鹤岗市",
                    "children": [
                        {
                            "id": "230402",
                            "code": "230402",
                            "name": "向阳区",
                            "parentCode": "230400",
                            "fullCodes": "230000,230400,230402",
                            "fullNames": "黑龙江省,鹤岗市,向阳区",
                            "children": []
                        },
                        {
                            "id": "230403",
                            "code": "230403",
                            "name": "工农区",
                            "parentCode": "230400",
                            "fullCodes": "230000,230400,230403",
                            "fullNames": "黑龙江省,鹤岗市,工农区",
                            "children": []
                        },
                        {
                            "id": "230404",
                            "code": "230404",
                            "name": "南山区",
                            "parentCode": "230400",
                            "fullCodes": "230000,230400,230404",
                            "fullNames": "黑龙江省,鹤岗市,南山区",
                            "children": []
                        },
                        {
                            "id": "230405",
                            "code": "230405",
                            "name": "兴安区",
                            "parentCode": "230400",
                            "fullCodes": "230000,230400,230405",
                            "fullNames": "黑龙江省,鹤岗市,兴安区",
                            "children": []
                        },
                        {
                            "id": "230406",
                            "code": "230406",
                            "name": "东山区",
                            "parentCode": "230400",
                            "fullCodes": "230000,230400,230406",
                            "fullNames": "黑龙江省,鹤岗市,东山区",
                            "children": []
                        },
                        {
                            "id": "230407",
                            "code": "230407",
                            "name": "兴山区",
                            "parentCode": "230400",
                            "fullCodes": "230000,230400,230407",
                            "fullNames": "黑龙江省,鹤岗市,兴山区",
                            "children": []
                        },
                        {
                            "id": "230421",
                            "code": "230421",
                            "name": "萝北县",
                            "parentCode": "230400",
                            "fullCodes": "230000,230400,230421",
                            "fullNames": "黑龙江省,鹤岗市,萝北县",
                            "children": []
                        },
                        {
                            "id": "230422",
                            "code": "230422",
                            "name": "绥滨县",
                            "parentCode": "230400",
                            "fullCodes": "230000,230400,230422",
                            "fullNames": "黑龙江省,鹤岗市,绥滨县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "230500",
                    "code": "230500",
                    "name": "双鸭山市",
                    "parentCode": "230000",
                    "fullCodes": "230000,230500",
                    "fullNames": "黑龙江省,双鸭山市",
                    "children": [
                        {
                            "id": "230502",
                            "code": "230502",
                            "name": "尖山区",
                            "parentCode": "230500",
                            "fullCodes": "230000,230500,230502",
                            "fullNames": "黑龙江省,双鸭山市,尖山区",
                            "children": []
                        },
                        {
                            "id": "230503",
                            "code": "230503",
                            "name": "岭东区",
                            "parentCode": "230500",
                            "fullCodes": "230000,230500,230503",
                            "fullNames": "黑龙江省,双鸭山市,岭东区",
                            "children": []
                        },
                        {
                            "id": "230505",
                            "code": "230505",
                            "name": "四方台区",
                            "parentCode": "230500",
                            "fullCodes": "230000,230500,230505",
                            "fullNames": "黑龙江省,双鸭山市,四方台区",
                            "children": []
                        },
                        {
                            "id": "230506",
                            "code": "230506",
                            "name": "宝山区",
                            "parentCode": "230500",
                            "fullCodes": "230000,230500,230506",
                            "fullNames": "黑龙江省,双鸭山市,宝山区",
                            "children": []
                        },
                        {
                            "id": "230521",
                            "code": "230521",
                            "name": "集贤县",
                            "parentCode": "230500",
                            "fullCodes": "230000,230500,230521",
                            "fullNames": "黑龙江省,双鸭山市,集贤县",
                            "children": []
                        },
                        {
                            "id": "230522",
                            "code": "230522",
                            "name": "友谊县",
                            "parentCode": "230500",
                            "fullCodes": "230000,230500,230522",
                            "fullNames": "黑龙江省,双鸭山市,友谊县",
                            "children": []
                        },
                        {
                            "id": "230523",
                            "code": "230523",
                            "name": "宝清县",
                            "parentCode": "230500",
                            "fullCodes": "230000,230500,230523",
                            "fullNames": "黑龙江省,双鸭山市,宝清县",
                            "children": []
                        },
                        {
                            "id": "230524",
                            "code": "230524",
                            "name": "饶河县",
                            "parentCode": "230500",
                            "fullCodes": "230000,230500,230524",
                            "fullNames": "黑龙江省,双鸭山市,饶河县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "230600",
                    "code": "230600",
                    "name": "大庆市",
                    "parentCode": "230000",
                    "fullCodes": "230000,230600",
                    "fullNames": "黑龙江省,大庆市",
                    "children": [
                        {
                            "id": "230602",
                            "code": "230602",
                            "name": "萨尔图区",
                            "parentCode": "230600",
                            "fullCodes": "230000,230600,230602",
                            "fullNames": "黑龙江省,大庆市,萨尔图区",
                            "children": []
                        },
                        {
                            "id": "230603",
                            "code": "230603",
                            "name": "龙凤区",
                            "parentCode": "230600",
                            "fullCodes": "230000,230600,230603",
                            "fullNames": "黑龙江省,大庆市,龙凤区",
                            "children": []
                        },
                        {
                            "id": "230604",
                            "code": "230604",
                            "name": "让胡路区",
                            "parentCode": "230600",
                            "fullCodes": "230000,230600,230604",
                            "fullNames": "黑龙江省,大庆市,让胡路区",
                            "children": []
                        },
                        {
                            "id": "230605",
                            "code": "230605",
                            "name": "红岗区",
                            "parentCode": "230600",
                            "fullCodes": "230000,230600,230605",
                            "fullNames": "黑龙江省,大庆市,红岗区",
                            "children": []
                        },
                        {
                            "id": "230606",
                            "code": "230606",
                            "name": "大同区",
                            "parentCode": "230600",
                            "fullCodes": "230000,230600,230606",
                            "fullNames": "黑龙江省,大庆市,大同区",
                            "children": []
                        },
                        {
                            "id": "230621",
                            "code": "230621",
                            "name": "肇州县",
                            "parentCode": "230600",
                            "fullCodes": "230000,230600,230621",
                            "fullNames": "黑龙江省,大庆市,肇州县",
                            "children": []
                        },
                        {
                            "id": "230622",
                            "code": "230622",
                            "name": "肇源县",
                            "parentCode": "230600",
                            "fullCodes": "230000,230600,230622",
                            "fullNames": "黑龙江省,大庆市,肇源县",
                            "children": []
                        },
                        {
                            "id": "230623",
                            "code": "230623",
                            "name": "林甸县",
                            "parentCode": "230600",
                            "fullCodes": "230000,230600,230623",
                            "fullNames": "黑龙江省,大庆市,林甸县",
                            "children": []
                        },
                        {
                            "id": "230624",
                            "code": "230624",
                            "name": "杜尔伯特蒙古族自治县",
                            "parentCode": "230600",
                            "fullCodes": "230000,230600,230624",
                            "fullNames": "黑龙江省,大庆市,杜尔伯特蒙古族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "230700",
                    "code": "230700",
                    "name": "伊春市",
                    "parentCode": "230000",
                    "fullCodes": "230000,230700",
                    "fullNames": "黑龙江省,伊春市",
                    "children": [
                        {
                            "id": "230702",
                            "code": "230702",
                            "name": "伊春区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230702",
                            "fullNames": "黑龙江省,伊春市,伊春区",
                            "children": []
                        },
                        {
                            "id": "230703",
                            "code": "230703",
                            "name": "南岔区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230703",
                            "fullNames": "黑龙江省,伊春市,南岔区",
                            "children": []
                        },
                        {
                            "id": "230704",
                            "code": "230704",
                            "name": "友好区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230704",
                            "fullNames": "黑龙江省,伊春市,友好区",
                            "children": []
                        },
                        {
                            "id": "230705",
                            "code": "230705",
                            "name": "西林区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230705",
                            "fullNames": "黑龙江省,伊春市,西林区",
                            "children": []
                        },
                        {
                            "id": "230706",
                            "code": "230706",
                            "name": "翠峦区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230706",
                            "fullNames": "黑龙江省,伊春市,翠峦区",
                            "children": []
                        },
                        {
                            "id": "230707",
                            "code": "230707",
                            "name": "新青区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230707",
                            "fullNames": "黑龙江省,伊春市,新青区",
                            "children": []
                        },
                        {
                            "id": "230708",
                            "code": "230708",
                            "name": "美溪区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230708",
                            "fullNames": "黑龙江省,伊春市,美溪区",
                            "children": []
                        },
                        {
                            "id": "230709",
                            "code": "230709",
                            "name": "金山屯区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230709",
                            "fullNames": "黑龙江省,伊春市,金山屯区",
                            "children": []
                        },
                        {
                            "id": "230710",
                            "code": "230710",
                            "name": "五营区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230710",
                            "fullNames": "黑龙江省,伊春市,五营区",
                            "children": []
                        },
                        {
                            "id": "230711",
                            "code": "230711",
                            "name": "乌马河区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230711",
                            "fullNames": "黑龙江省,伊春市,乌马河区",
                            "children": []
                        },
                        {
                            "id": "230712",
                            "code": "230712",
                            "name": "汤旺河区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230712",
                            "fullNames": "黑龙江省,伊春市,汤旺河区",
                            "children": []
                        },
                        {
                            "id": "230713",
                            "code": "230713",
                            "name": "带岭区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230713",
                            "fullNames": "黑龙江省,伊春市,带岭区",
                            "children": []
                        },
                        {
                            "id": "230714",
                            "code": "230714",
                            "name": "乌伊岭区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230714",
                            "fullNames": "黑龙江省,伊春市,乌伊岭区",
                            "children": []
                        },
                        {
                            "id": "230715",
                            "code": "230715",
                            "name": "红星区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230715",
                            "fullNames": "黑龙江省,伊春市,红星区",
                            "children": []
                        },
                        {
                            "id": "230716",
                            "code": "230716",
                            "name": "上甘岭区",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230716",
                            "fullNames": "黑龙江省,伊春市,上甘岭区",
                            "children": []
                        },
                        {
                            "id": "230722",
                            "code": "230722",
                            "name": "嘉荫县",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230722",
                            "fullNames": "黑龙江省,伊春市,嘉荫县",
                            "children": []
                        },
                        {
                            "id": "230781",
                            "code": "230781",
                            "name": "铁力市",
                            "parentCode": "230700",
                            "fullCodes": "230000,230700,230781",
                            "fullNames": "黑龙江省,伊春市,铁力市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "230800",
                    "code": "230800",
                    "name": "佳木斯市",
                    "parentCode": "230000",
                    "fullCodes": "230000,230800",
                    "fullNames": "黑龙江省,佳木斯市",
                    "children": [
                        {
                            "id": "230802",
                            "code": "230802",
                            "name": "永红区",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230802",
                            "fullNames": "黑龙江省,佳木斯市,永红区",
                            "children": []
                        },
                        {
                            "id": "230803",
                            "code": "230803",
                            "name": "向阳区",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230803",
                            "fullNames": "黑龙江省,佳木斯市,向阳区",
                            "children": []
                        },
                        {
                            "id": "230804",
                            "code": "230804",
                            "name": "前进区",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230804",
                            "fullNames": "黑龙江省,佳木斯市,前进区",
                            "children": []
                        },
                        {
                            "id": "230805",
                            "code": "230805",
                            "name": "东风区",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230805",
                            "fullNames": "黑龙江省,佳木斯市,东风区",
                            "children": []
                        },
                        {
                            "id": "230811",
                            "code": "230811",
                            "name": "郊区",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230811",
                            "fullNames": "黑龙江省,佳木斯市,郊区",
                            "children": []
                        },
                        {
                            "id": "230822",
                            "code": "230822",
                            "name": "桦南县",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230822",
                            "fullNames": "黑龙江省,佳木斯市,桦南县",
                            "children": []
                        },
                        {
                            "id": "230826",
                            "code": "230826",
                            "name": "桦川县",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230826",
                            "fullNames": "黑龙江省,佳木斯市,桦川县",
                            "children": []
                        },
                        {
                            "id": "230828",
                            "code": "230828",
                            "name": "汤原县",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230828",
                            "fullNames": "黑龙江省,佳木斯市,汤原县",
                            "children": []
                        },
                        {
                            "id": "230833",
                            "code": "230833",
                            "name": "抚远县",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230833",
                            "fullNames": "黑龙江省,佳木斯市,抚远县",
                            "children": []
                        },
                        {
                            "id": "230881",
                            "code": "230881",
                            "name": "同江市",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230881",
                            "fullNames": "黑龙江省,佳木斯市,同江市",
                            "children": []
                        },
                        {
                            "id": "230882",
                            "code": "230882",
                            "name": "富锦市",
                            "parentCode": "230800",
                            "fullCodes": "230000,230800,230882",
                            "fullNames": "黑龙江省,佳木斯市,富锦市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "230900",
                    "code": "230900",
                    "name": "七台河市",
                    "parentCode": "230000",
                    "fullCodes": "230000,230900",
                    "fullNames": "黑龙江省,七台河市",
                    "children": [
                        {
                            "id": "230902",
                            "code": "230902",
                            "name": "新兴区",
                            "parentCode": "230900",
                            "fullCodes": "230000,230900,230902",
                            "fullNames": "黑龙江省,七台河市,新兴区",
                            "children": []
                        },
                        {
                            "id": "230903",
                            "code": "230903",
                            "name": "桃山区",
                            "parentCode": "230900",
                            "fullCodes": "230000,230900,230903",
                            "fullNames": "黑龙江省,七台河市,桃山区",
                            "children": []
                        },
                        {
                            "id": "230904",
                            "code": "230904",
                            "name": "茄子河区",
                            "parentCode": "230900",
                            "fullCodes": "230000,230900,230904",
                            "fullNames": "黑龙江省,七台河市,茄子河区",
                            "children": []
                        },
                        {
                            "id": "230921",
                            "code": "230921",
                            "name": "勃利县",
                            "parentCode": "230900",
                            "fullCodes": "230000,230900,230921",
                            "fullNames": "黑龙江省,七台河市,勃利县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "231000",
                    "code": "231000",
                    "name": "牡丹江市",
                    "parentCode": "230000",
                    "fullCodes": "230000,231000",
                    "fullNames": "黑龙江省,牡丹江市",
                    "children": [
                        {
                            "id": "231002",
                            "code": "231002",
                            "name": "东安区",
                            "parentCode": "231000",
                            "fullCodes": "230000,231000,231002",
                            "fullNames": "黑龙江省,牡丹江市,东安区",
                            "children": []
                        },
                        {
                            "id": "231003",
                            "code": "231003",
                            "name": "阳明区",
                            "parentCode": "231000",
                            "fullCodes": "230000,231000,231003",
                            "fullNames": "黑龙江省,牡丹江市,阳明区",
                            "children": []
                        },
                        {
                            "id": "231004",
                            "code": "231004",
                            "name": "爱民区",
                            "parentCode": "231000",
                            "fullCodes": "230000,231000,231004",
                            "fullNames": "黑龙江省,牡丹江市,爱民区",
                            "children": []
                        },
                        {
                            "id": "231005",
                            "code": "231005",
                            "name": "西安区",
                            "parentCode": "231000",
                            "fullCodes": "230000,231000,231005",
                            "fullNames": "黑龙江省,牡丹江市,西安区",
                            "children": []
                        },
                        {
                            "id": "231024",
                            "code": "231024",
                            "name": "东宁县",
                            "parentCode": "231000",
                            "fullCodes": "230000,231000,231024",
                            "fullNames": "黑龙江省,牡丹江市,东宁县",
                            "children": []
                        },
                        {
                            "id": "231025",
                            "code": "231025",
                            "name": "林口县",
                            "parentCode": "231000",
                            "fullCodes": "230000,231000,231025",
                            "fullNames": "黑龙江省,牡丹江市,林口县",
                            "children": []
                        },
                        {
                            "id": "231081",
                            "code": "231081",
                            "name": "绥芬河市",
                            "parentCode": "231000",
                            "fullCodes": "230000,231000,231081",
                            "fullNames": "黑龙江省,牡丹江市,绥芬河市",
                            "children": []
                        },
                        {
                            "id": "231083",
                            "code": "231083",
                            "name": "海林市",
                            "parentCode": "231000",
                            "fullCodes": "230000,231000,231083",
                            "fullNames": "黑龙江省,牡丹江市,海林市",
                            "children": []
                        },
                        {
                            "id": "231084",
                            "code": "231084",
                            "name": "宁安市",
                            "parentCode": "231000",
                            "fullCodes": "230000,231000,231084",
                            "fullNames": "黑龙江省,牡丹江市,宁安市",
                            "children": []
                        },
                        {
                            "id": "231085",
                            "code": "231085",
                            "name": "穆棱市",
                            "parentCode": "231000",
                            "fullCodes": "230000,231000,231085",
                            "fullNames": "黑龙江省,牡丹江市,穆棱市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "231100",
                    "code": "231100",
                    "name": "黑河市",
                    "parentCode": "230000",
                    "fullCodes": "230000,231100",
                    "fullNames": "黑龙江省,黑河市",
                    "children": [
                        {
                            "id": "231102",
                            "code": "231102",
                            "name": "爱辉区",
                            "parentCode": "231100",
                            "fullCodes": "230000,231100,231102",
                            "fullNames": "黑龙江省,黑河市,爱辉区",
                            "children": []
                        },
                        {
                            "id": "231121",
                            "code": "231121",
                            "name": "嫩江县",
                            "parentCode": "231100",
                            "fullCodes": "230000,231100,231121",
                            "fullNames": "黑龙江省,黑河市,嫩江县",
                            "children": []
                        },
                        {
                            "id": "231123",
                            "code": "231123",
                            "name": "逊克县",
                            "parentCode": "231100",
                            "fullCodes": "230000,231100,231123",
                            "fullNames": "黑龙江省,黑河市,逊克县",
                            "children": []
                        },
                        {
                            "id": "231124",
                            "code": "231124",
                            "name": "孙吴县",
                            "parentCode": "231100",
                            "fullCodes": "230000,231100,231124",
                            "fullNames": "黑龙江省,黑河市,孙吴县",
                            "children": []
                        },
                        {
                            "id": "231181",
                            "code": "231181",
                            "name": "北安市",
                            "parentCode": "231100",
                            "fullCodes": "230000,231100,231181",
                            "fullNames": "黑龙江省,黑河市,北安市",
                            "children": []
                        },
                        {
                            "id": "231182",
                            "code": "231182",
                            "name": "五大连池市",
                            "parentCode": "231100",
                            "fullCodes": "230000,231100,231182",
                            "fullNames": "黑龙江省,黑河市,五大连池市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "231200",
                    "code": "231200",
                    "name": "绥化市",
                    "parentCode": "230000",
                    "fullCodes": "230000,231200",
                    "fullNames": "黑龙江省,绥化市",
                    "children": [
                        {
                            "id": "231202",
                            "code": "231202",
                            "name": "北林区",
                            "parentCode": "231200",
                            "fullCodes": "230000,231200,231202",
                            "fullNames": "黑龙江省,绥化市,北林区",
                            "children": []
                        },
                        {
                            "id": "231221",
                            "code": "231221",
                            "name": "望奎县",
                            "parentCode": "231200",
                            "fullCodes": "230000,231200,231221",
                            "fullNames": "黑龙江省,绥化市,望奎县",
                            "children": []
                        },
                        {
                            "id": "231222",
                            "code": "231222",
                            "name": "兰西县",
                            "parentCode": "231200",
                            "fullCodes": "230000,231200,231222",
                            "fullNames": "黑龙江省,绥化市,兰西县",
                            "children": []
                        },
                        {
                            "id": "231223",
                            "code": "231223",
                            "name": "青冈县",
                            "parentCode": "231200",
                            "fullCodes": "230000,231200,231223",
                            "fullNames": "黑龙江省,绥化市,青冈县",
                            "children": []
                        },
                        {
                            "id": "231224",
                            "code": "231224",
                            "name": "庆安县",
                            "parentCode": "231200",
                            "fullCodes": "230000,231200,231224",
                            "fullNames": "黑龙江省,绥化市,庆安县",
                            "children": []
                        },
                        {
                            "id": "231225",
                            "code": "231225",
                            "name": "明水县",
                            "parentCode": "231200",
                            "fullCodes": "230000,231200,231225",
                            "fullNames": "黑龙江省,绥化市,明水县",
                            "children": []
                        },
                        {
                            "id": "231226",
                            "code": "231226",
                            "name": "绥棱县",
                            "parentCode": "231200",
                            "fullCodes": "230000,231200,231226",
                            "fullNames": "黑龙江省,绥化市,绥棱县",
                            "children": []
                        },
                        {
                            "id": "231281",
                            "code": "231281",
                            "name": "安达市",
                            "parentCode": "231200",
                            "fullCodes": "230000,231200,231281",
                            "fullNames": "黑龙江省,绥化市,安达市",
                            "children": []
                        },
                        {
                            "id": "231282",
                            "code": "231282",
                            "name": "肇东市",
                            "parentCode": "231200",
                            "fullCodes": "230000,231200,231282",
                            "fullNames": "黑龙江省,绥化市,肇东市",
                            "children": []
                        },
                        {
                            "id": "231283",
                            "code": "231283",
                            "name": "海伦市",
                            "parentCode": "231200",
                            "fullCodes": "230000,231200,231283",
                            "fullNames": "黑龙江省,绥化市,海伦市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "232700",
                    "code": "232700",
                    "name": "大兴安岭地区",
                    "parentCode": "230000",
                    "fullCodes": "230000,232700",
                    "fullNames": "黑龙江省,大兴安岭地区",
                    "children": [
                        {
                            "id": "232721",
                            "code": "232721",
                            "name": "呼玛县",
                            "parentCode": "232700",
                            "fullCodes": "230000,232700,232721",
                            "fullNames": "黑龙江省,大兴安岭地区,呼玛县",
                            "children": []
                        },
                        {
                            "id": "232722",
                            "code": "232722",
                            "name": "塔河县",
                            "parentCode": "232700",
                            "fullCodes": "230000,232700,232722",
                            "fullNames": "黑龙江省,大兴安岭地区,塔河县",
                            "children": []
                        },
                        {
                            "id": "232723",
                            "code": "232723",
                            "name": "漠河县",
                            "parentCode": "232700",
                            "fullCodes": "230000,232700,232723",
                            "fullNames": "黑龙江省,大兴安岭地区,漠河县",
                            "children": []
                        },
                        {
                            "id": "232724",
                            "code": "232724",
                            "name": "加格达奇区",
                            "parentCode": "232700",
                            "fullCodes": "230000,232700,232724",
                            "fullNames": "黑龙江省,大兴安岭地区,加格达奇区",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "310000",
            "code": "310000",
            "name": "上海市",
            "parentCode": "-1",
            "fullCodes": "310000",
            "fullNames": "上海市",
            "children": [
                {
                    "id": "310101",
                    "code": "310101",
                    "name": "黄浦区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310101",
                    "fullNames": "上海市,黄浦区",
                    "children": []
                },
                {
                    "id": "310103",
                    "code": "310103",
                    "name": "卢湾区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310103",
                    "fullNames": "上海市,卢湾区",
                    "children": []
                },
                {
                    "id": "310104",
                    "code": "310104",
                    "name": "徐汇区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310104",
                    "fullNames": "上海市,徐汇区",
                    "children": []
                },
                {
                    "id": "310105",
                    "code": "310105",
                    "name": "长宁区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310105",
                    "fullNames": "上海市,长宁区",
                    "children": []
                },
                {
                    "id": "310106",
                    "code": "310106",
                    "name": "静安区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310106",
                    "fullNames": "上海市,静安区",
                    "children": []
                },
                {
                    "id": "310107",
                    "code": "310107",
                    "name": "普陀区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310107",
                    "fullNames": "上海市,普陀区",
                    "children": []
                },
                {
                    "id": "310108",
                    "code": "310108",
                    "name": "闸北区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310108",
                    "fullNames": "上海市,闸北区",
                    "children": []
                },
                {
                    "id": "310109",
                    "code": "310109",
                    "name": "虹口区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310109",
                    "fullNames": "上海市,虹口区",
                    "children": []
                },
                {
                    "id": "310110",
                    "code": "310110",
                    "name": "杨浦区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310110",
                    "fullNames": "上海市,杨浦区",
                    "children": []
                },
                {
                    "id": "310112",
                    "code": "310112",
                    "name": "闵行区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310112",
                    "fullNames": "上海市,闵行区",
                    "children": []
                },
                {
                    "id": "310113",
                    "code": "310113",
                    "name": "宝山区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310113",
                    "fullNames": "上海市,宝山区",
                    "children": []
                },
                {
                    "id": "310114",
                    "code": "310114",
                    "name": "嘉定区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310114",
                    "fullNames": "上海市,嘉定区",
                    "children": []
                },
                {
                    "id": "310115",
                    "code": "310115",
                    "name": "浦东新区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310115",
                    "fullNames": "上海市,浦东新区",
                    "children": []
                },
                {
                    "id": "310116",
                    "code": "310116",
                    "name": "金山区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310116",
                    "fullNames": "上海市,金山区",
                    "children": []
                },
                {
                    "id": "310117",
                    "code": "310117",
                    "name": "松江区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310117",
                    "fullNames": "上海市,松江区",
                    "children": []
                },
                {
                    "id": "310118",
                    "code": "310118",
                    "name": "青浦区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310118",
                    "fullNames": "上海市,青浦区",
                    "children": []
                },
                {
                    "id": "310119",
                    "code": "310119",
                    "name": "南汇区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310119",
                    "fullNames": "上海市,南汇区",
                    "children": []
                },
                {
                    "id": "310120",
                    "code": "310120",
                    "name": "奉贤区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310120",
                    "fullNames": "上海市,奉贤区",
                    "children": []
                },
                {
                    "id": "310152",
                    "code": "310152",
                    "name": "川沙区",
                    "parentCode": "310000",
                    "fullCodes": "310000,310152",
                    "fullNames": "上海市,川沙区",
                    "children": []
                },
                {
                    "id": "310230",
                    "code": "310230",
                    "name": "崇明县",
                    "parentCode": "310000",
                    "fullCodes": "310000,310230",
                    "fullNames": "上海市,崇明县",
                    "children": []
                }
            ]
        },
        {
            "id": "320000",
            "code": "320000",
            "name": "江苏省",
            "parentCode": "-1",
            "fullCodes": "320000",
            "fullNames": "江苏省",
            "children": [
                {
                    "id": "320100",
                    "code": "320100",
                    "name": "南京市",
                    "parentCode": "320000",
                    "fullCodes": "320000,320100",
                    "fullNames": "江苏省,南京市",
                    "children": [
                        {
                            "id": "320102",
                            "code": "320102",
                            "name": "玄武区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320102",
                            "fullNames": "江苏省,南京市,玄武区",
                            "children": []
                        },
                        {
                            "id": "320103",
                            "code": "320103",
                            "name": "白下区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320103",
                            "fullNames": "江苏省,南京市,白下区",
                            "children": []
                        },
                        {
                            "id": "320104",
                            "code": "320104",
                            "name": "秦淮区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320104",
                            "fullNames": "江苏省,南京市,秦淮区",
                            "children": []
                        },
                        {
                            "id": "320105",
                            "code": "320105",
                            "name": "建邺区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320105",
                            "fullNames": "江苏省,南京市,建邺区",
                            "children": []
                        },
                        {
                            "id": "320106",
                            "code": "320106",
                            "name": "鼓楼区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320106",
                            "fullNames": "江苏省,南京市,鼓楼区",
                            "children": []
                        },
                        {
                            "id": "320107",
                            "code": "320107",
                            "name": "下关区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320107",
                            "fullNames": "江苏省,南京市,下关区",
                            "children": []
                        },
                        {
                            "id": "320111",
                            "code": "320111",
                            "name": "浦口区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320111",
                            "fullNames": "江苏省,南京市,浦口区",
                            "children": []
                        },
                        {
                            "id": "320113",
                            "code": "320113",
                            "name": "栖霞区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320113",
                            "fullNames": "江苏省,南京市,栖霞区",
                            "children": []
                        },
                        {
                            "id": "320114",
                            "code": "320114",
                            "name": "雨花台区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320114",
                            "fullNames": "江苏省,南京市,雨花台区",
                            "children": []
                        },
                        {
                            "id": "320115",
                            "code": "320115",
                            "name": "江宁区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320115",
                            "fullNames": "江苏省,南京市,江宁区",
                            "children": []
                        },
                        {
                            "id": "320116",
                            "code": "320116",
                            "name": "六合区",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320116",
                            "fullNames": "江苏省,南京市,六合区",
                            "children": []
                        },
                        {
                            "id": "320124",
                            "code": "320124",
                            "name": "溧水县",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320124",
                            "fullNames": "江苏省,南京市,溧水县",
                            "children": []
                        },
                        {
                            "id": "320125",
                            "code": "320125",
                            "name": "高淳县",
                            "parentCode": "320100",
                            "fullCodes": "320000,320100,320125",
                            "fullNames": "江苏省,南京市,高淳县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "320200",
                    "code": "320200",
                    "name": "无锡市",
                    "parentCode": "320000",
                    "fullCodes": "320000,320200",
                    "fullNames": "江苏省,无锡市",
                    "children": [
                        {
                            "id": "320202",
                            "code": "320202",
                            "name": "崇安区",
                            "parentCode": "320200",
                            "fullCodes": "320000,320200,320202",
                            "fullNames": "江苏省,无锡市,崇安区",
                            "children": []
                        },
                        {
                            "id": "320203",
                            "code": "320203",
                            "name": "南长区",
                            "parentCode": "320200",
                            "fullCodes": "320000,320200,320203",
                            "fullNames": "江苏省,无锡市,南长区",
                            "children": []
                        },
                        {
                            "id": "320204",
                            "code": "320204",
                            "name": "北塘区",
                            "parentCode": "320200",
                            "fullCodes": "320000,320200,320204",
                            "fullNames": "江苏省,无锡市,北塘区",
                            "children": []
                        },
                        {
                            "id": "320205",
                            "code": "320205",
                            "name": "锡山区",
                            "parentCode": "320200",
                            "fullCodes": "320000,320200,320205",
                            "fullNames": "江苏省,无锡市,锡山区",
                            "children": []
                        },
                        {
                            "id": "320206",
                            "code": "320206",
                            "name": "惠山区",
                            "parentCode": "320200",
                            "fullCodes": "320000,320200,320206",
                            "fullNames": "江苏省,无锡市,惠山区",
                            "children": []
                        },
                        {
                            "id": "320211",
                            "code": "320211",
                            "name": "滨湖区",
                            "parentCode": "320200",
                            "fullCodes": "320000,320200,320211",
                            "fullNames": "江苏省,无锡市,滨湖区",
                            "children": []
                        },
                        {
                            "id": "320281",
                            "code": "320281",
                            "name": "江阴市",
                            "parentCode": "320200",
                            "fullCodes": "320000,320200,320281",
                            "fullNames": "江苏省,无锡市,江阴市",
                            "children": []
                        },
                        {
                            "id": "320282",
                            "code": "320282",
                            "name": "宜兴市",
                            "parentCode": "320200",
                            "fullCodes": "320000,320200,320282",
                            "fullNames": "江苏省,无锡市,宜兴市",
                            "children": []
                        },
                        {
                            "id": "320296",
                            "code": "320296",
                            "name": "新区",
                            "parentCode": "320200",
                            "fullCodes": "320000,320200,320296",
                            "fullNames": "江苏省,无锡市,新区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "320300",
                    "code": "320300",
                    "name": "徐州市",
                    "parentCode": "320000",
                    "fullCodes": "320000,320300",
                    "fullNames": "江苏省,徐州市",
                    "children": [
                        {
                            "id": "320302",
                            "code": "320302",
                            "name": "鼓楼区",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320302",
                            "fullNames": "江苏省,徐州市,鼓楼区",
                            "children": []
                        },
                        {
                            "id": "320303",
                            "code": "320303",
                            "name": "云龙区",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320303",
                            "fullNames": "江苏省,徐州市,云龙区",
                            "children": []
                        },
                        {
                            "id": "320304",
                            "code": "320304",
                            "name": "九里区",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320304",
                            "fullNames": "江苏省,徐州市,九里区",
                            "children": []
                        },
                        {
                            "id": "320305",
                            "code": "320305",
                            "name": "贾汪区",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320305",
                            "fullNames": "江苏省,徐州市,贾汪区",
                            "children": []
                        },
                        {
                            "id": "320311",
                            "code": "320311",
                            "name": "泉山区",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320311",
                            "fullNames": "江苏省,徐州市,泉山区",
                            "children": []
                        },
                        {
                            "id": "320321",
                            "code": "320321",
                            "name": "丰县",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320321",
                            "fullNames": "江苏省,徐州市,丰县",
                            "children": []
                        },
                        {
                            "id": "320322",
                            "code": "320322",
                            "name": "沛县",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320322",
                            "fullNames": "江苏省,徐州市,沛县",
                            "children": []
                        },
                        {
                            "id": "320323",
                            "code": "320323",
                            "name": "铜山县",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320323",
                            "fullNames": "江苏省,徐州市,铜山县",
                            "children": []
                        },
                        {
                            "id": "320324",
                            "code": "320324",
                            "name": "睢宁县",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320324",
                            "fullNames": "江苏省,徐州市,睢宁县",
                            "children": []
                        },
                        {
                            "id": "320381",
                            "code": "320381",
                            "name": "新沂市",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320381",
                            "fullNames": "江苏省,徐州市,新沂市",
                            "children": []
                        },
                        {
                            "id": "320382",
                            "code": "320382",
                            "name": "邳州市",
                            "parentCode": "320300",
                            "fullCodes": "320000,320300,320382",
                            "fullNames": "江苏省,徐州市,邳州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "320400",
                    "code": "320400",
                    "name": "常州市",
                    "parentCode": "320000",
                    "fullCodes": "320000,320400",
                    "fullNames": "江苏省,常州市",
                    "children": [
                        {
                            "id": "320402",
                            "code": "320402",
                            "name": "天宁区",
                            "parentCode": "320400",
                            "fullCodes": "320000,320400,320402",
                            "fullNames": "江苏省,常州市,天宁区",
                            "children": []
                        },
                        {
                            "id": "320404",
                            "code": "320404",
                            "name": "钟楼区",
                            "parentCode": "320400",
                            "fullCodes": "320000,320400,320404",
                            "fullNames": "江苏省,常州市,钟楼区",
                            "children": []
                        },
                        {
                            "id": "320405",
                            "code": "320405",
                            "name": "戚墅堰区",
                            "parentCode": "320400",
                            "fullCodes": "320000,320400,320405",
                            "fullNames": "江苏省,常州市,戚墅堰区",
                            "children": []
                        },
                        {
                            "id": "320411",
                            "code": "320411",
                            "name": "新北区",
                            "parentCode": "320400",
                            "fullCodes": "320000,320400,320411",
                            "fullNames": "江苏省,常州市,新北区",
                            "children": []
                        },
                        {
                            "id": "320412",
                            "code": "320412",
                            "name": "武进区",
                            "parentCode": "320400",
                            "fullCodes": "320000,320400,320412",
                            "fullNames": "江苏省,常州市,武进区",
                            "children": []
                        },
                        {
                            "id": "320481",
                            "code": "320481",
                            "name": "溧阳市",
                            "parentCode": "320400",
                            "fullCodes": "320000,320400,320481",
                            "fullNames": "江苏省,常州市,溧阳市",
                            "children": []
                        },
                        {
                            "id": "320482",
                            "code": "320482",
                            "name": "金坛市",
                            "parentCode": "320400",
                            "fullCodes": "320000,320400,320482",
                            "fullNames": "江苏省,常州市,金坛市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "320500",
                    "code": "320500",
                    "name": "苏州市",
                    "parentCode": "320000",
                    "fullCodes": "320000,320500",
                    "fullNames": "江苏省,苏州市",
                    "children": [
                        {
                            "id": "320502",
                            "code": "320502",
                            "name": "沧浪区",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320502",
                            "fullNames": "江苏省,苏州市,沧浪区",
                            "children": []
                        },
                        {
                            "id": "320503",
                            "code": "320503",
                            "name": "平江区",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320503",
                            "fullNames": "江苏省,苏州市,平江区",
                            "children": []
                        },
                        {
                            "id": "320504",
                            "code": "320504",
                            "name": "金阊区",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320504",
                            "fullNames": "江苏省,苏州市,金阊区",
                            "children": []
                        },
                        {
                            "id": "320505",
                            "code": "320505",
                            "name": "虎丘区",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320505",
                            "fullNames": "江苏省,苏州市,虎丘区",
                            "children": []
                        },
                        {
                            "id": "320506",
                            "code": "320506",
                            "name": "吴中区",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320506",
                            "fullNames": "江苏省,苏州市,吴中区",
                            "children": []
                        },
                        {
                            "id": "320507",
                            "code": "320507",
                            "name": "相城区",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320507",
                            "fullNames": "江苏省,苏州市,相城区",
                            "children": []
                        },
                        {
                            "id": "320581",
                            "code": "320581",
                            "name": "常熟市",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320581",
                            "fullNames": "江苏省,苏州市,常熟市",
                            "children": []
                        },
                        {
                            "id": "320582",
                            "code": "320582",
                            "name": "张家港市",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320582",
                            "fullNames": "江苏省,苏州市,张家港市",
                            "children": []
                        },
                        {
                            "id": "320583",
                            "code": "320583",
                            "name": "昆山市",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320583",
                            "fullNames": "江苏省,苏州市,昆山市",
                            "children": []
                        },
                        {
                            "id": "320584",
                            "code": "320584",
                            "name": "吴江市",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320584",
                            "fullNames": "江苏省,苏州市,吴江市",
                            "children": []
                        },
                        {
                            "id": "320585",
                            "code": "320585",
                            "name": "太仓市",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320585",
                            "fullNames": "江苏省,苏州市,太仓市",
                            "children": []
                        },
                        {
                            "id": "320594",
                            "code": "320594",
                            "name": "新区",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320594",
                            "fullNames": "江苏省,苏州市,新区",
                            "children": []
                        },
                        {
                            "id": "320595",
                            "code": "320595",
                            "name": "园区",
                            "parentCode": "320500",
                            "fullCodes": "320000,320500,320595",
                            "fullNames": "江苏省,苏州市,园区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "320600",
                    "code": "320600",
                    "name": "南通市",
                    "parentCode": "320000",
                    "fullCodes": "320000,320600",
                    "fullNames": "江苏省,南通市",
                    "children": [
                        {
                            "id": "320602",
                            "code": "320602",
                            "name": "崇川区",
                            "parentCode": "320600",
                            "fullCodes": "320000,320600,320602",
                            "fullNames": "江苏省,南通市,崇川区",
                            "children": []
                        },
                        {
                            "id": "320611",
                            "code": "320611",
                            "name": "港闸区",
                            "parentCode": "320600",
                            "fullCodes": "320000,320600,320611",
                            "fullNames": "江苏省,南通市,港闸区",
                            "children": []
                        },
                        {
                            "id": "320612",
                            "code": "320612",
                            "name": "通州区",
                            "parentCode": "320600",
                            "fullCodes": "320000,320600,320612",
                            "fullNames": "江苏省,南通市,通州区",
                            "children": []
                        },
                        {
                            "id": "320621",
                            "code": "320621",
                            "name": "海安县",
                            "parentCode": "320600",
                            "fullCodes": "320000,320600,320621",
                            "fullNames": "江苏省,南通市,海安县",
                            "children": []
                        },
                        {
                            "id": "320623",
                            "code": "320623",
                            "name": "如东县",
                            "parentCode": "320600",
                            "fullCodes": "320000,320600,320623",
                            "fullNames": "江苏省,南通市,如东县",
                            "children": []
                        },
                        {
                            "id": "320681",
                            "code": "320681",
                            "name": "启东市",
                            "parentCode": "320600",
                            "fullCodes": "320000,320600,320681",
                            "fullNames": "江苏省,南通市,启东市",
                            "children": []
                        },
                        {
                            "id": "320682",
                            "code": "320682",
                            "name": "如皋市",
                            "parentCode": "320600",
                            "fullCodes": "320000,320600,320682",
                            "fullNames": "江苏省,南通市,如皋市",
                            "children": []
                        },
                        {
                            "id": "320683",
                            "code": "320683",
                            "name": "通州市",
                            "parentCode": "320600",
                            "fullCodes": "320000,320600,320683",
                            "fullNames": "江苏省,南通市,通州市",
                            "children": []
                        },
                        {
                            "id": "320684",
                            "code": "320684",
                            "name": "海门市",
                            "parentCode": "320600",
                            "fullCodes": "320000,320600,320684",
                            "fullNames": "江苏省,南通市,海门市",
                            "children": []
                        },
                        {
                            "id": "320693",
                            "code": "320693",
                            "name": "开发区",
                            "parentCode": "320600",
                            "fullCodes": "320000,320600,320693",
                            "fullNames": "江苏省,南通市,开发区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "320700",
                    "code": "320700",
                    "name": "连云港市",
                    "parentCode": "320000",
                    "fullCodes": "320000,320700",
                    "fullNames": "江苏省,连云港市",
                    "children": [
                        {
                            "id": "320703",
                            "code": "320703",
                            "name": "连云区",
                            "parentCode": "320700",
                            "fullCodes": "320000,320700,320703",
                            "fullNames": "江苏省,连云港市,连云区",
                            "children": []
                        },
                        {
                            "id": "320705",
                            "code": "320705",
                            "name": "新浦区",
                            "parentCode": "320700",
                            "fullCodes": "320000,320700,320705",
                            "fullNames": "江苏省,连云港市,新浦区",
                            "children": []
                        },
                        {
                            "id": "320706",
                            "code": "320706",
                            "name": "海州区",
                            "parentCode": "320700",
                            "fullCodes": "320000,320700,320706",
                            "fullNames": "江苏省,连云港市,海州区",
                            "children": []
                        },
                        {
                            "id": "320721",
                            "code": "320721",
                            "name": "赣榆县",
                            "parentCode": "320700",
                            "fullCodes": "320000,320700,320721",
                            "fullNames": "江苏省,连云港市,赣榆县",
                            "children": []
                        },
                        {
                            "id": "320722",
                            "code": "320722",
                            "name": "东海县",
                            "parentCode": "320700",
                            "fullCodes": "320000,320700,320722",
                            "fullNames": "江苏省,连云港市,东海县",
                            "children": []
                        },
                        {
                            "id": "320723",
                            "code": "320723",
                            "name": "灌云县",
                            "parentCode": "320700",
                            "fullCodes": "320000,320700,320723",
                            "fullNames": "江苏省,连云港市,灌云县",
                            "children": []
                        },
                        {
                            "id": "320724",
                            "code": "320724",
                            "name": "灌南县",
                            "parentCode": "320700",
                            "fullCodes": "320000,320700,320724",
                            "fullNames": "江苏省,连云港市,灌南县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "320800",
                    "code": "320800",
                    "name": "淮安市",
                    "parentCode": "320000",
                    "fullCodes": "320000,320800",
                    "fullNames": "江苏省,淮安市",
                    "children": [
                        {
                            "id": "320802",
                            "code": "320802",
                            "name": "清河区",
                            "parentCode": "320800",
                            "fullCodes": "320000,320800,320802",
                            "fullNames": "江苏省,淮安市,清河区",
                            "children": []
                        },
                        {
                            "id": "320803",
                            "code": "320803",
                            "name": "楚州区",
                            "parentCode": "320800",
                            "fullCodes": "320000,320800,320803",
                            "fullNames": "江苏省,淮安市,楚州区",
                            "children": []
                        },
                        {
                            "id": "320804",
                            "code": "320804",
                            "name": "淮阴区",
                            "parentCode": "320800",
                            "fullCodes": "320000,320800,320804",
                            "fullNames": "江苏省,淮安市,淮阴区",
                            "children": []
                        },
                        {
                            "id": "320811",
                            "code": "320811",
                            "name": "清浦区",
                            "parentCode": "320800",
                            "fullCodes": "320000,320800,320811",
                            "fullNames": "江苏省,淮安市,清浦区",
                            "children": []
                        },
                        {
                            "id": "320826",
                            "code": "320826",
                            "name": "涟水县",
                            "parentCode": "320800",
                            "fullCodes": "320000,320800,320826",
                            "fullNames": "江苏省,淮安市,涟水县",
                            "children": []
                        },
                        {
                            "id": "320829",
                            "code": "320829",
                            "name": "洪泽县",
                            "parentCode": "320800",
                            "fullCodes": "320000,320800,320829",
                            "fullNames": "江苏省,淮安市,洪泽县",
                            "children": []
                        },
                        {
                            "id": "320830",
                            "code": "320830",
                            "name": "盱眙县",
                            "parentCode": "320800",
                            "fullCodes": "320000,320800,320830",
                            "fullNames": "江苏省,淮安市,盱眙县",
                            "children": []
                        },
                        {
                            "id": "320831",
                            "code": "320831",
                            "name": "金湖县",
                            "parentCode": "320800",
                            "fullCodes": "320000,320800,320831",
                            "fullNames": "江苏省,淮安市,金湖县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "320900",
                    "code": "320900",
                    "name": "盐城市",
                    "parentCode": "320000",
                    "fullCodes": "320000,320900",
                    "fullNames": "江苏省,盐城市",
                    "children": [
                        {
                            "id": "320902",
                            "code": "320902",
                            "name": "亭湖区",
                            "parentCode": "320900",
                            "fullCodes": "320000,320900,320902",
                            "fullNames": "江苏省,盐城市,亭湖区",
                            "children": []
                        },
                        {
                            "id": "320903",
                            "code": "320903",
                            "name": "盐都区",
                            "parentCode": "320900",
                            "fullCodes": "320000,320900,320903",
                            "fullNames": "江苏省,盐城市,盐都区",
                            "children": []
                        },
                        {
                            "id": "320921",
                            "code": "320921",
                            "name": "响水县",
                            "parentCode": "320900",
                            "fullCodes": "320000,320900,320921",
                            "fullNames": "江苏省,盐城市,响水县",
                            "children": []
                        },
                        {
                            "id": "320922",
                            "code": "320922",
                            "name": "滨海县",
                            "parentCode": "320900",
                            "fullCodes": "320000,320900,320922",
                            "fullNames": "江苏省,盐城市,滨海县",
                            "children": []
                        },
                        {
                            "id": "320923",
                            "code": "320923",
                            "name": "阜宁县",
                            "parentCode": "320900",
                            "fullCodes": "320000,320900,320923",
                            "fullNames": "江苏省,盐城市,阜宁县",
                            "children": []
                        },
                        {
                            "id": "320924",
                            "code": "320924",
                            "name": "射阳县",
                            "parentCode": "320900",
                            "fullCodes": "320000,320900,320924",
                            "fullNames": "江苏省,盐城市,射阳县",
                            "children": []
                        },
                        {
                            "id": "320925",
                            "code": "320925",
                            "name": "建湖县",
                            "parentCode": "320900",
                            "fullCodes": "320000,320900,320925",
                            "fullNames": "江苏省,盐城市,建湖县",
                            "children": []
                        },
                        {
                            "id": "320981",
                            "code": "320981",
                            "name": "东台市",
                            "parentCode": "320900",
                            "fullCodes": "320000,320900,320981",
                            "fullNames": "江苏省,盐城市,东台市",
                            "children": []
                        },
                        {
                            "id": "320982",
                            "code": "320982",
                            "name": "大丰市",
                            "parentCode": "320900",
                            "fullCodes": "320000,320900,320982",
                            "fullNames": "江苏省,盐城市,大丰市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "321000",
                    "code": "321000",
                    "name": "扬州市",
                    "parentCode": "320000",
                    "fullCodes": "320000,321000",
                    "fullNames": "江苏省,扬州市",
                    "children": [
                        {
                            "id": "321002",
                            "code": "321002",
                            "name": "广陵区",
                            "parentCode": "321000",
                            "fullCodes": "320000,321000,321002",
                            "fullNames": "江苏省,扬州市,广陵区",
                            "children": []
                        },
                        {
                            "id": "321003",
                            "code": "321003",
                            "name": "邗江区",
                            "parentCode": "321000",
                            "fullCodes": "320000,321000,321003",
                            "fullNames": "江苏省,扬州市,邗江区",
                            "children": []
                        },
                        {
                            "id": "321011",
                            "code": "321011",
                            "name": "维扬区",
                            "parentCode": "321000",
                            "fullCodes": "320000,321000,321011",
                            "fullNames": "江苏省,扬州市,维扬区",
                            "children": []
                        },
                        {
                            "id": "321023",
                            "code": "321023",
                            "name": "宝应县",
                            "parentCode": "321000",
                            "fullCodes": "320000,321000,321023",
                            "fullNames": "江苏省,扬州市,宝应县",
                            "children": []
                        },
                        {
                            "id": "321081",
                            "code": "321081",
                            "name": "仪征市",
                            "parentCode": "321000",
                            "fullCodes": "320000,321000,321081",
                            "fullNames": "江苏省,扬州市,仪征市",
                            "children": []
                        },
                        {
                            "id": "321084",
                            "code": "321084",
                            "name": "高邮市",
                            "parentCode": "321000",
                            "fullCodes": "320000,321000,321084",
                            "fullNames": "江苏省,扬州市,高邮市",
                            "children": []
                        },
                        {
                            "id": "321088",
                            "code": "321088",
                            "name": "江都市",
                            "parentCode": "321000",
                            "fullCodes": "320000,321000,321088",
                            "fullNames": "江苏省,扬州市,江都市",
                            "children": []
                        },
                        {
                            "id": "321092",
                            "code": "321092",
                            "name": "经济开发区",
                            "parentCode": "321000",
                            "fullCodes": "320000,321000,321092",
                            "fullNames": "江苏省,扬州市,经济开发区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "321100",
                    "code": "321100",
                    "name": "镇江市",
                    "parentCode": "320000",
                    "fullCodes": "320000,321100",
                    "fullNames": "江苏省,镇江市",
                    "children": [
                        {
                            "id": "321102",
                            "code": "321102",
                            "name": "京口区",
                            "parentCode": "321100",
                            "fullCodes": "320000,321100,321102",
                            "fullNames": "江苏省,镇江市,京口区",
                            "children": []
                        },
                        {
                            "id": "321111",
                            "code": "321111",
                            "name": "润州区",
                            "parentCode": "321100",
                            "fullCodes": "320000,321100,321111",
                            "fullNames": "江苏省,镇江市,润州区",
                            "children": []
                        },
                        {
                            "id": "321112",
                            "code": "321112",
                            "name": "丹徒区",
                            "parentCode": "321100",
                            "fullCodes": "320000,321100,321112",
                            "fullNames": "江苏省,镇江市,丹徒区",
                            "children": []
                        },
                        {
                            "id": "321181",
                            "code": "321181",
                            "name": "丹阳市",
                            "parentCode": "321100",
                            "fullCodes": "320000,321100,321181",
                            "fullNames": "江苏省,镇江市,丹阳市",
                            "children": []
                        },
                        {
                            "id": "321182",
                            "code": "321182",
                            "name": "扬中市",
                            "parentCode": "321100",
                            "fullCodes": "320000,321100,321182",
                            "fullNames": "江苏省,镇江市,扬中市",
                            "children": []
                        },
                        {
                            "id": "321183",
                            "code": "321183",
                            "name": "句容市",
                            "parentCode": "321100",
                            "fullCodes": "320000,321100,321183",
                            "fullNames": "江苏省,镇江市,句容市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "321200",
                    "code": "321200",
                    "name": "泰州市",
                    "parentCode": "320000",
                    "fullCodes": "320000,321200",
                    "fullNames": "江苏省,泰州市",
                    "children": [
                        {
                            "id": "321202",
                            "code": "321202",
                            "name": "海陵区",
                            "parentCode": "321200",
                            "fullCodes": "320000,321200,321202",
                            "fullNames": "江苏省,泰州市,海陵区",
                            "children": []
                        },
                        {
                            "id": "321203",
                            "code": "321203",
                            "name": "高港区",
                            "parentCode": "321200",
                            "fullCodes": "320000,321200,321203",
                            "fullNames": "江苏省,泰州市,高港区",
                            "children": []
                        },
                        {
                            "id": "321281",
                            "code": "321281",
                            "name": "兴化市",
                            "parentCode": "321200",
                            "fullCodes": "320000,321200,321281",
                            "fullNames": "江苏省,泰州市,兴化市",
                            "children": []
                        },
                        {
                            "id": "321282",
                            "code": "321282",
                            "name": "靖江市",
                            "parentCode": "321200",
                            "fullCodes": "320000,321200,321282",
                            "fullNames": "江苏省,泰州市,靖江市",
                            "children": []
                        },
                        {
                            "id": "321283",
                            "code": "321283",
                            "name": "泰兴市",
                            "parentCode": "321200",
                            "fullCodes": "320000,321200,321283",
                            "fullNames": "江苏省,泰州市,泰兴市",
                            "children": []
                        },
                        {
                            "id": "321284",
                            "code": "321284",
                            "name": "姜堰市",
                            "parentCode": "321200",
                            "fullCodes": "320000,321200,321284",
                            "fullNames": "江苏省,泰州市,姜堰市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "321300",
                    "code": "321300",
                    "name": "宿迁市",
                    "parentCode": "320000",
                    "fullCodes": "320000,321300",
                    "fullNames": "江苏省,宿迁市",
                    "children": [
                        {
                            "id": "321302",
                            "code": "321302",
                            "name": "宿城区",
                            "parentCode": "321300",
                            "fullCodes": "320000,321300,321302",
                            "fullNames": "江苏省,宿迁市,宿城区",
                            "children": []
                        },
                        {
                            "id": "321311",
                            "code": "321311",
                            "name": "宿豫区",
                            "parentCode": "321300",
                            "fullCodes": "320000,321300,321311",
                            "fullNames": "江苏省,宿迁市,宿豫区",
                            "children": []
                        },
                        {
                            "id": "321322",
                            "code": "321322",
                            "name": "沭阳县",
                            "parentCode": "321300",
                            "fullCodes": "320000,321300,321322",
                            "fullNames": "江苏省,宿迁市,沭阳县",
                            "children": []
                        },
                        {
                            "id": "321323",
                            "code": "321323",
                            "name": "泗阳县",
                            "parentCode": "321300",
                            "fullCodes": "320000,321300,321323",
                            "fullNames": "江苏省,宿迁市,泗阳县",
                            "children": []
                        },
                        {
                            "id": "321324",
                            "code": "321324",
                            "name": "泗洪县",
                            "parentCode": "321300",
                            "fullCodes": "320000,321300,321324",
                            "fullNames": "江苏省,宿迁市,泗洪县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "330000",
            "code": "330000",
            "name": "浙江省",
            "parentCode": "-1",
            "fullCodes": "330000",
            "fullNames": "浙江省",
            "children": [
                {
                    "id": "330100",
                    "code": "330100",
                    "name": "杭州市",
                    "parentCode": "330000",
                    "fullCodes": "330000,330100",
                    "fullNames": "浙江省,杭州市",
                    "children": [
                        {
                            "id": "330102",
                            "code": "330102",
                            "name": "上城区",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330102",
                            "fullNames": "浙江省,杭州市,上城区",
                            "children": []
                        },
                        {
                            "id": "330103",
                            "code": "330103",
                            "name": "下城区",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330103",
                            "fullNames": "浙江省,杭州市,下城区",
                            "children": []
                        },
                        {
                            "id": "330104",
                            "code": "330104",
                            "name": "江干区",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330104",
                            "fullNames": "浙江省,杭州市,江干区",
                            "children": []
                        },
                        {
                            "id": "330105",
                            "code": "330105",
                            "name": "拱墅区",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330105",
                            "fullNames": "浙江省,杭州市,拱墅区",
                            "children": []
                        },
                        {
                            "id": "330106",
                            "code": "330106",
                            "name": "西湖区",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330106",
                            "fullNames": "浙江省,杭州市,西湖区",
                            "children": []
                        },
                        {
                            "id": "330108",
                            "code": "330108",
                            "name": "滨江区",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330108",
                            "fullNames": "浙江省,杭州市,滨江区",
                            "children": []
                        },
                        {
                            "id": "330109",
                            "code": "330109",
                            "name": "萧山区",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330109",
                            "fullNames": "浙江省,杭州市,萧山区",
                            "children": []
                        },
                        {
                            "id": "330110",
                            "code": "330110",
                            "name": "余杭区",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330110",
                            "fullNames": "浙江省,杭州市,余杭区",
                            "children": []
                        },
                        {
                            "id": "330122",
                            "code": "330122",
                            "name": "桐庐县",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330122",
                            "fullNames": "浙江省,杭州市,桐庐县",
                            "children": []
                        },
                        {
                            "id": "330127",
                            "code": "330127",
                            "name": "淳安县",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330127",
                            "fullNames": "浙江省,杭州市,淳安县",
                            "children": []
                        },
                        {
                            "id": "330182",
                            "code": "330182",
                            "name": "建德市",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330182",
                            "fullNames": "浙江省,杭州市,建德市",
                            "children": []
                        },
                        {
                            "id": "330183",
                            "code": "330183",
                            "name": "富阳市",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330183",
                            "fullNames": "浙江省,杭州市,富阳市",
                            "children": []
                        },
                        {
                            "id": "330185",
                            "code": "330185",
                            "name": "临安市",
                            "parentCode": "330100",
                            "fullCodes": "330000,330100,330185",
                            "fullNames": "浙江省,杭州市,临安市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "330200",
                    "code": "330200",
                    "name": "宁波市",
                    "parentCode": "330000",
                    "fullCodes": "330000,330200",
                    "fullNames": "浙江省,宁波市",
                    "children": [
                        {
                            "id": "330203",
                            "code": "330203",
                            "name": "海曙区",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330203",
                            "fullNames": "浙江省,宁波市,海曙区",
                            "children": []
                        },
                        {
                            "id": "330204",
                            "code": "330204",
                            "name": "江东区",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330204",
                            "fullNames": "浙江省,宁波市,江东区",
                            "children": []
                        },
                        {
                            "id": "330205",
                            "code": "330205",
                            "name": "江北区",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330205",
                            "fullNames": "浙江省,宁波市,江北区",
                            "children": []
                        },
                        {
                            "id": "330206",
                            "code": "330206",
                            "name": "北仑区",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330206",
                            "fullNames": "浙江省,宁波市,北仑区",
                            "children": []
                        },
                        {
                            "id": "330211",
                            "code": "330211",
                            "name": "镇海区",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330211",
                            "fullNames": "浙江省,宁波市,镇海区",
                            "children": []
                        },
                        {
                            "id": "330212",
                            "code": "330212",
                            "name": "鄞州区",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330212",
                            "fullNames": "浙江省,宁波市,鄞州区",
                            "children": []
                        },
                        {
                            "id": "330225",
                            "code": "330225",
                            "name": "象山县",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330225",
                            "fullNames": "浙江省,宁波市,象山县",
                            "children": []
                        },
                        {
                            "id": "330226",
                            "code": "330226",
                            "name": "宁海县",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330226",
                            "fullNames": "浙江省,宁波市,宁海县",
                            "children": []
                        },
                        {
                            "id": "330281",
                            "code": "330281",
                            "name": "余姚市",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330281",
                            "fullNames": "浙江省,宁波市,余姚市",
                            "children": []
                        },
                        {
                            "id": "330282",
                            "code": "330282",
                            "name": "慈溪市",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330282",
                            "fullNames": "浙江省,宁波市,慈溪市",
                            "children": []
                        },
                        {
                            "id": "330283",
                            "code": "330283",
                            "name": "奉化市",
                            "parentCode": "330200",
                            "fullCodes": "330000,330200,330283",
                            "fullNames": "浙江省,宁波市,奉化市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "330300",
                    "code": "330300",
                    "name": "温州市",
                    "parentCode": "330000",
                    "fullCodes": "330000,330300",
                    "fullNames": "浙江省,温州市",
                    "children": [
                        {
                            "id": "330302",
                            "code": "330302",
                            "name": "鹿城区",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330302",
                            "fullNames": "浙江省,温州市,鹿城区",
                            "children": []
                        },
                        {
                            "id": "330303",
                            "code": "330303",
                            "name": "龙湾区",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330303",
                            "fullNames": "浙江省,温州市,龙湾区",
                            "children": []
                        },
                        {
                            "id": "330304",
                            "code": "330304",
                            "name": "瓯海区",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330304",
                            "fullNames": "浙江省,温州市,瓯海区",
                            "children": []
                        },
                        {
                            "id": "330322",
                            "code": "330322",
                            "name": "洞头县",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330322",
                            "fullNames": "浙江省,温州市,洞头县",
                            "children": []
                        },
                        {
                            "id": "330324",
                            "code": "330324",
                            "name": "永嘉县",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330324",
                            "fullNames": "浙江省,温州市,永嘉县",
                            "children": []
                        },
                        {
                            "id": "330326",
                            "code": "330326",
                            "name": "平阳县",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330326",
                            "fullNames": "浙江省,温州市,平阳县",
                            "children": []
                        },
                        {
                            "id": "330327",
                            "code": "330327",
                            "name": "苍南县",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330327",
                            "fullNames": "浙江省,温州市,苍南县",
                            "children": []
                        },
                        {
                            "id": "330328",
                            "code": "330328",
                            "name": "文成县",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330328",
                            "fullNames": "浙江省,温州市,文成县",
                            "children": []
                        },
                        {
                            "id": "330329",
                            "code": "330329",
                            "name": "泰顺县",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330329",
                            "fullNames": "浙江省,温州市,泰顺县",
                            "children": []
                        },
                        {
                            "id": "330381",
                            "code": "330381",
                            "name": "瑞安市",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330381",
                            "fullNames": "浙江省,温州市,瑞安市",
                            "children": []
                        },
                        {
                            "id": "330382",
                            "code": "330382",
                            "name": "乐清市",
                            "parentCode": "330300",
                            "fullCodes": "330000,330300,330382",
                            "fullNames": "浙江省,温州市,乐清市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "330400",
                    "code": "330400",
                    "name": "嘉兴市",
                    "parentCode": "330000",
                    "fullCodes": "330000,330400",
                    "fullNames": "浙江省,嘉兴市",
                    "children": [
                        {
                            "id": "330402",
                            "code": "330402",
                            "name": "南湖区",
                            "parentCode": "330400",
                            "fullCodes": "330000,330400,330402",
                            "fullNames": "浙江省,嘉兴市,南湖区",
                            "children": []
                        },
                        {
                            "id": "330411",
                            "code": "330411",
                            "name": "秀洲区",
                            "parentCode": "330400",
                            "fullCodes": "330000,330400,330411",
                            "fullNames": "浙江省,嘉兴市,秀洲区",
                            "children": []
                        },
                        {
                            "id": "330421",
                            "code": "330421",
                            "name": "嘉善县",
                            "parentCode": "330400",
                            "fullCodes": "330000,330400,330421",
                            "fullNames": "浙江省,嘉兴市,嘉善县",
                            "children": []
                        },
                        {
                            "id": "330424",
                            "code": "330424",
                            "name": "海盐县",
                            "parentCode": "330400",
                            "fullCodes": "330000,330400,330424",
                            "fullNames": "浙江省,嘉兴市,海盐县",
                            "children": []
                        },
                        {
                            "id": "330481",
                            "code": "330481",
                            "name": "海宁市",
                            "parentCode": "330400",
                            "fullCodes": "330000,330400,330481",
                            "fullNames": "浙江省,嘉兴市,海宁市",
                            "children": []
                        },
                        {
                            "id": "330482",
                            "code": "330482",
                            "name": "平湖市",
                            "parentCode": "330400",
                            "fullCodes": "330000,330400,330482",
                            "fullNames": "浙江省,嘉兴市,平湖市",
                            "children": []
                        },
                        {
                            "id": "330483",
                            "code": "330483",
                            "name": "桐乡市",
                            "parentCode": "330400",
                            "fullCodes": "330000,330400,330483",
                            "fullNames": "浙江省,嘉兴市,桐乡市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "330500",
                    "code": "330500",
                    "name": "湖州市",
                    "parentCode": "330000",
                    "fullCodes": "330000,330500",
                    "fullNames": "浙江省,湖州市",
                    "children": [
                        {
                            "id": "330502",
                            "code": "330502",
                            "name": "吴兴区",
                            "parentCode": "330500",
                            "fullCodes": "330000,330500,330502",
                            "fullNames": "浙江省,湖州市,吴兴区",
                            "children": []
                        },
                        {
                            "id": "330503",
                            "code": "330503",
                            "name": "南浔区",
                            "parentCode": "330500",
                            "fullCodes": "330000,330500,330503",
                            "fullNames": "浙江省,湖州市,南浔区",
                            "children": []
                        },
                        {
                            "id": "330521",
                            "code": "330521",
                            "name": "德清县",
                            "parentCode": "330500",
                            "fullCodes": "330000,330500,330521",
                            "fullNames": "浙江省,湖州市,德清县",
                            "children": []
                        },
                        {
                            "id": "330522",
                            "code": "330522",
                            "name": "长兴县",
                            "parentCode": "330500",
                            "fullCodes": "330000,330500,330522",
                            "fullNames": "浙江省,湖州市,长兴县",
                            "children": []
                        },
                        {
                            "id": "330523",
                            "code": "330523",
                            "name": "安吉县",
                            "parentCode": "330500",
                            "fullCodes": "330000,330500,330523",
                            "fullNames": "浙江省,湖州市,安吉县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "330600",
                    "code": "330600",
                    "name": "绍兴市",
                    "parentCode": "330000",
                    "fullCodes": "330000,330600",
                    "fullNames": "浙江省,绍兴市",
                    "children": [
                        {
                            "id": "330602",
                            "code": "330602",
                            "name": "越城区",
                            "parentCode": "330600",
                            "fullCodes": "330000,330600,330602",
                            "fullNames": "浙江省,绍兴市,越城区",
                            "children": []
                        },
                        {
                            "id": "330621",
                            "code": "330621",
                            "name": "绍兴县",
                            "parentCode": "330600",
                            "fullCodes": "330000,330600,330621",
                            "fullNames": "浙江省,绍兴市,绍兴县",
                            "children": []
                        },
                        {
                            "id": "330624",
                            "code": "330624",
                            "name": "新昌县",
                            "parentCode": "330600",
                            "fullCodes": "330000,330600,330624",
                            "fullNames": "浙江省,绍兴市,新昌县",
                            "children": []
                        },
                        {
                            "id": "330681",
                            "code": "330681",
                            "name": "诸暨市",
                            "parentCode": "330600",
                            "fullCodes": "330000,330600,330681",
                            "fullNames": "浙江省,绍兴市,诸暨市",
                            "children": []
                        },
                        {
                            "id": "330682",
                            "code": "330682",
                            "name": "上虞市",
                            "parentCode": "330600",
                            "fullCodes": "330000,330600,330682",
                            "fullNames": "浙江省,绍兴市,上虞市",
                            "children": []
                        },
                        {
                            "id": "330683",
                            "code": "330683",
                            "name": "嵊州市",
                            "parentCode": "330600",
                            "fullCodes": "330000,330600,330683",
                            "fullNames": "浙江省,绍兴市,嵊州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "330700",
                    "code": "330700",
                    "name": "金华市",
                    "parentCode": "330000",
                    "fullCodes": "330000,330700",
                    "fullNames": "浙江省,金华市",
                    "children": [
                        {
                            "id": "330702",
                            "code": "330702",
                            "name": "婺城区",
                            "parentCode": "330700",
                            "fullCodes": "330000,330700,330702",
                            "fullNames": "浙江省,金华市,婺城区",
                            "children": []
                        },
                        {
                            "id": "330703",
                            "code": "330703",
                            "name": "金东区",
                            "parentCode": "330700",
                            "fullCodes": "330000,330700,330703",
                            "fullNames": "浙江省,金华市,金东区",
                            "children": []
                        },
                        {
                            "id": "330723",
                            "code": "330723",
                            "name": "武义县",
                            "parentCode": "330700",
                            "fullCodes": "330000,330700,330723",
                            "fullNames": "浙江省,金华市,武义县",
                            "children": []
                        },
                        {
                            "id": "330726",
                            "code": "330726",
                            "name": "浦江县",
                            "parentCode": "330700",
                            "fullCodes": "330000,330700,330726",
                            "fullNames": "浙江省,金华市,浦江县",
                            "children": []
                        },
                        {
                            "id": "330727",
                            "code": "330727",
                            "name": "磐安县",
                            "parentCode": "330700",
                            "fullCodes": "330000,330700,330727",
                            "fullNames": "浙江省,金华市,磐安县",
                            "children": []
                        },
                        {
                            "id": "330781",
                            "code": "330781",
                            "name": "兰溪市",
                            "parentCode": "330700",
                            "fullCodes": "330000,330700,330781",
                            "fullNames": "浙江省,金华市,兰溪市",
                            "children": []
                        },
                        {
                            "id": "330782",
                            "code": "330782",
                            "name": "义乌市",
                            "parentCode": "330700",
                            "fullCodes": "330000,330700,330782",
                            "fullNames": "浙江省,金华市,义乌市",
                            "children": []
                        },
                        {
                            "id": "330783",
                            "code": "330783",
                            "name": "东阳市",
                            "parentCode": "330700",
                            "fullCodes": "330000,330700,330783",
                            "fullNames": "浙江省,金华市,东阳市",
                            "children": []
                        },
                        {
                            "id": "330784",
                            "code": "330784",
                            "name": "永康市",
                            "parentCode": "330700",
                            "fullCodes": "330000,330700,330784",
                            "fullNames": "浙江省,金华市,永康市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "330800",
                    "code": "330800",
                    "name": "衢州市",
                    "parentCode": "330000",
                    "fullCodes": "330000,330800",
                    "fullNames": "浙江省,衢州市",
                    "children": [
                        {
                            "id": "330802",
                            "code": "330802",
                            "name": "柯城区",
                            "parentCode": "330800",
                            "fullCodes": "330000,330800,330802",
                            "fullNames": "浙江省,衢州市,柯城区",
                            "children": []
                        },
                        {
                            "id": "330803",
                            "code": "330803",
                            "name": "衢江区",
                            "parentCode": "330800",
                            "fullCodes": "330000,330800,330803",
                            "fullNames": "浙江省,衢州市,衢江区",
                            "children": []
                        },
                        {
                            "id": "330822",
                            "code": "330822",
                            "name": "常山县",
                            "parentCode": "330800",
                            "fullCodes": "330000,330800,330822",
                            "fullNames": "浙江省,衢州市,常山县",
                            "children": []
                        },
                        {
                            "id": "330824",
                            "code": "330824",
                            "name": "开化县",
                            "parentCode": "330800",
                            "fullCodes": "330000,330800,330824",
                            "fullNames": "浙江省,衢州市,开化县",
                            "children": []
                        },
                        {
                            "id": "330825",
                            "code": "330825",
                            "name": "龙游县",
                            "parentCode": "330800",
                            "fullCodes": "330000,330800,330825",
                            "fullNames": "浙江省,衢州市,龙游县",
                            "children": []
                        },
                        {
                            "id": "330881",
                            "code": "330881",
                            "name": "江山市",
                            "parentCode": "330800",
                            "fullCodes": "330000,330800,330881",
                            "fullNames": "浙江省,衢州市,江山市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "330900",
                    "code": "330900",
                    "name": "舟山市",
                    "parentCode": "330000",
                    "fullCodes": "330000,330900",
                    "fullNames": "浙江省,舟山市",
                    "children": [
                        {
                            "id": "330902",
                            "code": "330902",
                            "name": "定海区",
                            "parentCode": "330900",
                            "fullCodes": "330000,330900,330902",
                            "fullNames": "浙江省,舟山市,定海区",
                            "children": []
                        },
                        {
                            "id": "330903",
                            "code": "330903",
                            "name": "普陀区",
                            "parentCode": "330900",
                            "fullCodes": "330000,330900,330903",
                            "fullNames": "浙江省,舟山市,普陀区",
                            "children": []
                        },
                        {
                            "id": "330921",
                            "code": "330921",
                            "name": "岱山县",
                            "parentCode": "330900",
                            "fullCodes": "330000,330900,330921",
                            "fullNames": "浙江省,舟山市,岱山县",
                            "children": []
                        },
                        {
                            "id": "330922",
                            "code": "330922",
                            "name": "嵊泗县",
                            "parentCode": "330900",
                            "fullCodes": "330000,330900,330922",
                            "fullNames": "浙江省,舟山市,嵊泗县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "331000",
                    "code": "331000",
                    "name": "台州市",
                    "parentCode": "330000",
                    "fullCodes": "330000,331000",
                    "fullNames": "浙江省,台州市",
                    "children": [
                        {
                            "id": "331002",
                            "code": "331002",
                            "name": "椒江区",
                            "parentCode": "331000",
                            "fullCodes": "330000,331000,331002",
                            "fullNames": "浙江省,台州市,椒江区",
                            "children": []
                        },
                        {
                            "id": "331003",
                            "code": "331003",
                            "name": "黄岩区",
                            "parentCode": "331000",
                            "fullCodes": "330000,331000,331003",
                            "fullNames": "浙江省,台州市,黄岩区",
                            "children": []
                        },
                        {
                            "id": "331004",
                            "code": "331004",
                            "name": "路桥区",
                            "parentCode": "331000",
                            "fullCodes": "330000,331000,331004",
                            "fullNames": "浙江省,台州市,路桥区",
                            "children": []
                        },
                        {
                            "id": "331021",
                            "code": "331021",
                            "name": "玉环县",
                            "parentCode": "331000",
                            "fullCodes": "330000,331000,331021",
                            "fullNames": "浙江省,台州市,玉环县",
                            "children": []
                        },
                        {
                            "id": "331022",
                            "code": "331022",
                            "name": "三门县",
                            "parentCode": "331000",
                            "fullCodes": "330000,331000,331022",
                            "fullNames": "浙江省,台州市,三门县",
                            "children": []
                        },
                        {
                            "id": "331023",
                            "code": "331023",
                            "name": "天台县",
                            "parentCode": "331000",
                            "fullCodes": "330000,331000,331023",
                            "fullNames": "浙江省,台州市,天台县",
                            "children": []
                        },
                        {
                            "id": "331024",
                            "code": "331024",
                            "name": "仙居县",
                            "parentCode": "331000",
                            "fullCodes": "330000,331000,331024",
                            "fullNames": "浙江省,台州市,仙居县",
                            "children": []
                        },
                        {
                            "id": "331081",
                            "code": "331081",
                            "name": "温岭市",
                            "parentCode": "331000",
                            "fullCodes": "330000,331000,331081",
                            "fullNames": "浙江省,台州市,温岭市",
                            "children": []
                        },
                        {
                            "id": "331082",
                            "code": "331082",
                            "name": "临海市",
                            "parentCode": "331000",
                            "fullCodes": "330000,331000,331082",
                            "fullNames": "浙江省,台州市,临海市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "331100",
                    "code": "331100",
                    "name": "丽水市",
                    "parentCode": "330000",
                    "fullCodes": "330000,331100",
                    "fullNames": "浙江省,丽水市",
                    "children": [
                        {
                            "id": "331102",
                            "code": "331102",
                            "name": "莲都区",
                            "parentCode": "331100",
                            "fullCodes": "330000,331100,331102",
                            "fullNames": "浙江省,丽水市,莲都区",
                            "children": []
                        },
                        {
                            "id": "331121",
                            "code": "331121",
                            "name": "青田县",
                            "parentCode": "331100",
                            "fullCodes": "330000,331100,331121",
                            "fullNames": "浙江省,丽水市,青田县",
                            "children": []
                        },
                        {
                            "id": "331122",
                            "code": "331122",
                            "name": "缙云县",
                            "parentCode": "331100",
                            "fullCodes": "330000,331100,331122",
                            "fullNames": "浙江省,丽水市,缙云县",
                            "children": []
                        },
                        {
                            "id": "331123",
                            "code": "331123",
                            "name": "遂昌县",
                            "parentCode": "331100",
                            "fullCodes": "330000,331100,331123",
                            "fullNames": "浙江省,丽水市,遂昌县",
                            "children": []
                        },
                        {
                            "id": "331124",
                            "code": "331124",
                            "name": "松阳县",
                            "parentCode": "331100",
                            "fullCodes": "330000,331100,331124",
                            "fullNames": "浙江省,丽水市,松阳县",
                            "children": []
                        },
                        {
                            "id": "331125",
                            "code": "331125",
                            "name": "云和县",
                            "parentCode": "331100",
                            "fullCodes": "330000,331100,331125",
                            "fullNames": "浙江省,丽水市,云和县",
                            "children": []
                        },
                        {
                            "id": "331126",
                            "code": "331126",
                            "name": "庆元县",
                            "parentCode": "331100",
                            "fullCodes": "330000,331100,331126",
                            "fullNames": "浙江省,丽水市,庆元县",
                            "children": []
                        },
                        {
                            "id": "331127",
                            "code": "331127",
                            "name": "景宁畲族自治县",
                            "parentCode": "331100",
                            "fullCodes": "330000,331100,331127",
                            "fullNames": "浙江省,丽水市,景宁畲族自治县",
                            "children": []
                        },
                        {
                            "id": "331181",
                            "code": "331181",
                            "name": "龙泉市",
                            "parentCode": "331100",
                            "fullCodes": "330000,331100,331181",
                            "fullNames": "浙江省,丽水市,龙泉市",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "340000",
            "code": "340000",
            "name": "安徽省",
            "parentCode": "-1",
            "fullCodes": "340000",
            "fullNames": "安徽省",
            "children": [
                {
                    "id": "340100",
                    "code": "340100",
                    "name": "合肥市",
                    "parentCode": "340000",
                    "fullCodes": "340000,340100",
                    "fullNames": "安徽省,合肥市",
                    "children": [
                        {
                            "id": "340102",
                            "code": "340102",
                            "name": "瑶海区",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,340102",
                            "fullNames": "安徽省,合肥市,瑶海区",
                            "children": []
                        },
                        {
                            "id": "340103",
                            "code": "340103",
                            "name": "庐阳区",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,340103",
                            "fullNames": "安徽省,合肥市,庐阳区",
                            "children": []
                        },
                        {
                            "id": "340104",
                            "code": "340104",
                            "name": "蜀山区",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,340104",
                            "fullNames": "安徽省,合肥市,蜀山区",
                            "children": []
                        },
                        {
                            "id": "340111",
                            "code": "340111",
                            "name": "包河区",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,340111",
                            "fullNames": "安徽省,合肥市,包河区",
                            "children": []
                        },
                        {
                            "id": "340121",
                            "code": "340121",
                            "name": "长丰县",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,340121",
                            "fullNames": "安徽省,合肥市,长丰县",
                            "children": []
                        },
                        {
                            "id": "340122",
                            "code": "340122",
                            "name": "肥东县",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,340122",
                            "fullNames": "安徽省,合肥市,肥东县",
                            "children": []
                        },
                        {
                            "id": "340123",
                            "code": "340123",
                            "name": "肥西县",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,340123",
                            "fullNames": "安徽省,合肥市,肥西县",
                            "children": []
                        },
                        {
                            "id": "340151",
                            "code": "340151",
                            "name": "高新区",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,340151",
                            "fullNames": "安徽省,合肥市,高新区",
                            "children": []
                        },
                        {
                            "id": "340191",
                            "code": "340191",
                            "name": "中区",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,340191",
                            "fullNames": "安徽省,合肥市,中区",
                            "children": []
                        },
                        {
                            "id": "341400",
                            "code": "341400",
                            "name": "巢湖市",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,341400",
                            "fullNames": "安徽省,合肥市,巢湖市",
                            "children": []
                        },
                        {
                            "id": "341402",
                            "code": "341402",
                            "name": "居巢区",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,341402",
                            "fullNames": "安徽省,合肥市,居巢区",
                            "children": []
                        },
                        {
                            "id": "341421",
                            "code": "341421",
                            "name": "庐江县",
                            "parentCode": "340100",
                            "fullCodes": "340000,340100,341421",
                            "fullNames": "安徽省,合肥市,庐江县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "340200",
                    "code": "340200",
                    "name": "芜湖市",
                    "parentCode": "340000",
                    "fullCodes": "340000,340200",
                    "fullNames": "安徽省,芜湖市",
                    "children": [
                        {
                            "id": "340202",
                            "code": "340202",
                            "name": "镜湖区",
                            "parentCode": "340200",
                            "fullCodes": "340000,340200,340202",
                            "fullNames": "安徽省,芜湖市,镜湖区",
                            "children": []
                        },
                        {
                            "id": "340203",
                            "code": "340203",
                            "name": "弋江区",
                            "parentCode": "340200",
                            "fullCodes": "340000,340200,340203",
                            "fullNames": "安徽省,芜湖市,弋江区",
                            "children": []
                        },
                        {
                            "id": "340207",
                            "code": "340207",
                            "name": "鸠江区",
                            "parentCode": "340200",
                            "fullCodes": "340000,340200,340207",
                            "fullNames": "安徽省,芜湖市,鸠江区",
                            "children": []
                        },
                        {
                            "id": "340208",
                            "code": "340208",
                            "name": "三山区",
                            "parentCode": "340200",
                            "fullCodes": "340000,340200,340208",
                            "fullNames": "安徽省,芜湖市,三山区",
                            "children": []
                        },
                        {
                            "id": "340221",
                            "code": "340221",
                            "name": "芜湖县",
                            "parentCode": "340200",
                            "fullCodes": "340000,340200,340221",
                            "fullNames": "安徽省,芜湖市,芜湖县",
                            "children": []
                        },
                        {
                            "id": "340222",
                            "code": "340222",
                            "name": "繁昌县",
                            "parentCode": "340200",
                            "fullCodes": "340000,340200,340222",
                            "fullNames": "安徽省,芜湖市,繁昌县",
                            "children": []
                        },
                        {
                            "id": "340223",
                            "code": "340223",
                            "name": "南陵县",
                            "parentCode": "340200",
                            "fullCodes": "340000,340200,340223",
                            "fullNames": "安徽省,芜湖市,南陵县",
                            "children": []
                        },
                        {
                            "id": "341422",
                            "code": "341422",
                            "name": "无为县",
                            "parentCode": "340200",
                            "fullCodes": "340000,340200,341422",
                            "fullNames": "安徽省,芜湖市,无为县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "340300",
                    "code": "340300",
                    "name": "蚌埠市",
                    "parentCode": "340000",
                    "fullCodes": "340000,340300",
                    "fullNames": "安徽省,蚌埠市",
                    "children": [
                        {
                            "id": "340302",
                            "code": "340302",
                            "name": "龙子湖区",
                            "parentCode": "340300",
                            "fullCodes": "340000,340300,340302",
                            "fullNames": "安徽省,蚌埠市,龙子湖区",
                            "children": []
                        },
                        {
                            "id": "340303",
                            "code": "340303",
                            "name": "蚌山区",
                            "parentCode": "340300",
                            "fullCodes": "340000,340300,340303",
                            "fullNames": "安徽省,蚌埠市,蚌山区",
                            "children": []
                        },
                        {
                            "id": "340304",
                            "code": "340304",
                            "name": "禹会区",
                            "parentCode": "340300",
                            "fullCodes": "340000,340300,340304",
                            "fullNames": "安徽省,蚌埠市,禹会区",
                            "children": []
                        },
                        {
                            "id": "340311",
                            "code": "340311",
                            "name": "淮上区",
                            "parentCode": "340300",
                            "fullCodes": "340000,340300,340311",
                            "fullNames": "安徽省,蚌埠市,淮上区",
                            "children": []
                        },
                        {
                            "id": "340321",
                            "code": "340321",
                            "name": "怀远县",
                            "parentCode": "340300",
                            "fullCodes": "340000,340300,340321",
                            "fullNames": "安徽省,蚌埠市,怀远县",
                            "children": []
                        },
                        {
                            "id": "340322",
                            "code": "340322",
                            "name": "五河县",
                            "parentCode": "340300",
                            "fullCodes": "340000,340300,340322",
                            "fullNames": "安徽省,蚌埠市,五河县",
                            "children": []
                        },
                        {
                            "id": "340323",
                            "code": "340323",
                            "name": "固镇县",
                            "parentCode": "340300",
                            "fullCodes": "340000,340300,340323",
                            "fullNames": "安徽省,蚌埠市,固镇县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "340400",
                    "code": "340400",
                    "name": "淮南市",
                    "parentCode": "340000",
                    "fullCodes": "340000,340400",
                    "fullNames": "安徽省,淮南市",
                    "children": [
                        {
                            "id": "340402",
                            "code": "340402",
                            "name": "大通区",
                            "parentCode": "340400",
                            "fullCodes": "340000,340400,340402",
                            "fullNames": "安徽省,淮南市,大通区",
                            "children": []
                        },
                        {
                            "id": "340403",
                            "code": "340403",
                            "name": "田家庵区",
                            "parentCode": "340400",
                            "fullCodes": "340000,340400,340403",
                            "fullNames": "安徽省,淮南市,田家庵区",
                            "children": []
                        },
                        {
                            "id": "340404",
                            "code": "340404",
                            "name": "谢家集区",
                            "parentCode": "340400",
                            "fullCodes": "340000,340400,340404",
                            "fullNames": "安徽省,淮南市,谢家集区",
                            "children": []
                        },
                        {
                            "id": "340405",
                            "code": "340405",
                            "name": "八公山区",
                            "parentCode": "340400",
                            "fullCodes": "340000,340400,340405",
                            "fullNames": "安徽省,淮南市,八公山区",
                            "children": []
                        },
                        {
                            "id": "340406",
                            "code": "340406",
                            "name": "潘集区",
                            "parentCode": "340400",
                            "fullCodes": "340000,340400,340406",
                            "fullNames": "安徽省,淮南市,潘集区",
                            "children": []
                        },
                        {
                            "id": "340421",
                            "code": "340421",
                            "name": "凤台县",
                            "parentCode": "340400",
                            "fullCodes": "340000,340400,340421",
                            "fullNames": "安徽省,淮南市,凤台县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "340500",
                    "code": "340500",
                    "name": "马鞍山市",
                    "parentCode": "340000",
                    "fullCodes": "340000,340500",
                    "fullNames": "安徽省,马鞍山市",
                    "children": [
                        {
                            "id": "340502",
                            "code": "340502",
                            "name": "金家庄区",
                            "parentCode": "340500",
                            "fullCodes": "340000,340500,340502",
                            "fullNames": "安徽省,马鞍山市,金家庄区",
                            "children": []
                        },
                        {
                            "id": "340503",
                            "code": "340503",
                            "name": "花山区",
                            "parentCode": "340500",
                            "fullCodes": "340000,340500,340503",
                            "fullNames": "安徽省,马鞍山市,花山区",
                            "children": []
                        },
                        {
                            "id": "340504",
                            "code": "340504",
                            "name": "雨山区",
                            "parentCode": "340500",
                            "fullCodes": "340000,340500,340504",
                            "fullNames": "安徽省,马鞍山市,雨山区",
                            "children": []
                        },
                        {
                            "id": "340521",
                            "code": "340521",
                            "name": "当涂县",
                            "parentCode": "340500",
                            "fullCodes": "340000,340500,340521",
                            "fullNames": "安徽省,马鞍山市,当涂县",
                            "children": []
                        },
                        {
                            "id": "341423",
                            "code": "341423",
                            "name": "含山县",
                            "parentCode": "340500",
                            "fullCodes": "340000,340500,341423",
                            "fullNames": "安徽省,马鞍山市,含山县",
                            "children": []
                        },
                        {
                            "id": "341424",
                            "code": "341424",
                            "name": "和县",
                            "parentCode": "340500",
                            "fullCodes": "340000,340500,341424",
                            "fullNames": "安徽省,马鞍山市,和县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "340600",
                    "code": "340600",
                    "name": "淮北市",
                    "parentCode": "340000",
                    "fullCodes": "340000,340600",
                    "fullNames": "安徽省,淮北市",
                    "children": [
                        {
                            "id": "340602",
                            "code": "340602",
                            "name": "杜集区",
                            "parentCode": "340600",
                            "fullCodes": "340000,340600,340602",
                            "fullNames": "安徽省,淮北市,杜集区",
                            "children": []
                        },
                        {
                            "id": "340603",
                            "code": "340603",
                            "name": "相山区",
                            "parentCode": "340600",
                            "fullCodes": "340000,340600,340603",
                            "fullNames": "安徽省,淮北市,相山区",
                            "children": []
                        },
                        {
                            "id": "340604",
                            "code": "340604",
                            "name": "烈山区",
                            "parentCode": "340600",
                            "fullCodes": "340000,340600,340604",
                            "fullNames": "安徽省,淮北市,烈山区",
                            "children": []
                        },
                        {
                            "id": "340621",
                            "code": "340621",
                            "name": "濉溪县",
                            "parentCode": "340600",
                            "fullCodes": "340000,340600,340621",
                            "fullNames": "安徽省,淮北市,濉溪县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "340700",
                    "code": "340700",
                    "name": "铜陵市",
                    "parentCode": "340000",
                    "fullCodes": "340000,340700",
                    "fullNames": "安徽省,铜陵市",
                    "children": [
                        {
                            "id": "340702",
                            "code": "340702",
                            "name": "铜官山区",
                            "parentCode": "340700",
                            "fullCodes": "340000,340700,340702",
                            "fullNames": "安徽省,铜陵市,铜官山区",
                            "children": []
                        },
                        {
                            "id": "340703",
                            "code": "340703",
                            "name": "狮子山区",
                            "parentCode": "340700",
                            "fullCodes": "340000,340700,340703",
                            "fullNames": "安徽省,铜陵市,狮子山区",
                            "children": []
                        },
                        {
                            "id": "340711",
                            "code": "340711",
                            "name": "郊区",
                            "parentCode": "340700",
                            "fullCodes": "340000,340700,340711",
                            "fullNames": "安徽省,铜陵市,郊区",
                            "children": []
                        },
                        {
                            "id": "340721",
                            "code": "340721",
                            "name": "铜陵县",
                            "parentCode": "340700",
                            "fullCodes": "340000,340700,340721",
                            "fullNames": "安徽省,铜陵市,铜陵县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "340800",
                    "code": "340800",
                    "name": "安庆市",
                    "parentCode": "340000",
                    "fullCodes": "340000,340800",
                    "fullNames": "安徽省,安庆市",
                    "children": [
                        {
                            "id": "340802",
                            "code": "340802",
                            "name": "迎江区",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340802",
                            "fullNames": "安徽省,安庆市,迎江区",
                            "children": []
                        },
                        {
                            "id": "340803",
                            "code": "340803",
                            "name": "大观区",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340803",
                            "fullNames": "安徽省,安庆市,大观区",
                            "children": []
                        },
                        {
                            "id": "340811",
                            "code": "340811",
                            "name": "宜秀区",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340811",
                            "fullNames": "安徽省,安庆市,宜秀区",
                            "children": []
                        },
                        {
                            "id": "340822",
                            "code": "340822",
                            "name": "怀宁县",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340822",
                            "fullNames": "安徽省,安庆市,怀宁县",
                            "children": []
                        },
                        {
                            "id": "340823",
                            "code": "340823",
                            "name": "枞阳县",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340823",
                            "fullNames": "安徽省,安庆市,枞阳县",
                            "children": []
                        },
                        {
                            "id": "340824",
                            "code": "340824",
                            "name": "潜山县",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340824",
                            "fullNames": "安徽省,安庆市,潜山县",
                            "children": []
                        },
                        {
                            "id": "340825",
                            "code": "340825",
                            "name": "太湖县",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340825",
                            "fullNames": "安徽省,安庆市,太湖县",
                            "children": []
                        },
                        {
                            "id": "340826",
                            "code": "340826",
                            "name": "宿松县",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340826",
                            "fullNames": "安徽省,安庆市,宿松县",
                            "children": []
                        },
                        {
                            "id": "340827",
                            "code": "340827",
                            "name": "望江县",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340827",
                            "fullNames": "安徽省,安庆市,望江县",
                            "children": []
                        },
                        {
                            "id": "340828",
                            "code": "340828",
                            "name": "岳西县",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340828",
                            "fullNames": "安徽省,安庆市,岳西县",
                            "children": []
                        },
                        {
                            "id": "340881",
                            "code": "340881",
                            "name": "桐城市",
                            "parentCode": "340800",
                            "fullCodes": "340000,340800,340881",
                            "fullNames": "安徽省,安庆市,桐城市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "341000",
                    "code": "341000",
                    "name": "黄山市",
                    "parentCode": "340000",
                    "fullCodes": "340000,341000",
                    "fullNames": "安徽省,黄山市",
                    "children": [
                        {
                            "id": "341002",
                            "code": "341002",
                            "name": "屯溪区",
                            "parentCode": "341000",
                            "fullCodes": "340000,341000,341002",
                            "fullNames": "安徽省,黄山市,屯溪区",
                            "children": []
                        },
                        {
                            "id": "341003",
                            "code": "341003",
                            "name": "黄山区",
                            "parentCode": "341000",
                            "fullCodes": "340000,341000,341003",
                            "fullNames": "安徽省,黄山市,黄山区",
                            "children": []
                        },
                        {
                            "id": "341004",
                            "code": "341004",
                            "name": "徽州区",
                            "parentCode": "341000",
                            "fullCodes": "340000,341000,341004",
                            "fullNames": "安徽省,黄山市,徽州区",
                            "children": []
                        },
                        {
                            "id": "341021",
                            "code": "341021",
                            "name": "歙县",
                            "parentCode": "341000",
                            "fullCodes": "340000,341000,341021",
                            "fullNames": "安徽省,黄山市,歙县",
                            "children": []
                        },
                        {
                            "id": "341022",
                            "code": "341022",
                            "name": "休宁县",
                            "parentCode": "341000",
                            "fullCodes": "340000,341000,341022",
                            "fullNames": "安徽省,黄山市,休宁县",
                            "children": []
                        },
                        {
                            "id": "341023",
                            "code": "341023",
                            "name": "黟县",
                            "parentCode": "341000",
                            "fullCodes": "340000,341000,341023",
                            "fullNames": "安徽省,黄山市,黟县",
                            "children": []
                        },
                        {
                            "id": "341024",
                            "code": "341024",
                            "name": "祁门县",
                            "parentCode": "341000",
                            "fullCodes": "340000,341000,341024",
                            "fullNames": "安徽省,黄山市,祁门县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "341100",
                    "code": "341100",
                    "name": "滁州市",
                    "parentCode": "340000",
                    "fullCodes": "340000,341100",
                    "fullNames": "安徽省,滁州市",
                    "children": [
                        {
                            "id": "341102",
                            "code": "341102",
                            "name": "琅琊区",
                            "parentCode": "341100",
                            "fullCodes": "340000,341100,341102",
                            "fullNames": "安徽省,滁州市,琅琊区",
                            "children": []
                        },
                        {
                            "id": "341103",
                            "code": "341103",
                            "name": "南谯区",
                            "parentCode": "341100",
                            "fullCodes": "340000,341100,341103",
                            "fullNames": "安徽省,滁州市,南谯区",
                            "children": []
                        },
                        {
                            "id": "341122",
                            "code": "341122",
                            "name": "来安县",
                            "parentCode": "341100",
                            "fullCodes": "340000,341100,341122",
                            "fullNames": "安徽省,滁州市,来安县",
                            "children": []
                        },
                        {
                            "id": "341124",
                            "code": "341124",
                            "name": "全椒县",
                            "parentCode": "341100",
                            "fullCodes": "340000,341100,341124",
                            "fullNames": "安徽省,滁州市,全椒县",
                            "children": []
                        },
                        {
                            "id": "341125",
                            "code": "341125",
                            "name": "定远县",
                            "parentCode": "341100",
                            "fullCodes": "340000,341100,341125",
                            "fullNames": "安徽省,滁州市,定远县",
                            "children": []
                        },
                        {
                            "id": "341126",
                            "code": "341126",
                            "name": "凤阳县",
                            "parentCode": "341100",
                            "fullCodes": "340000,341100,341126",
                            "fullNames": "安徽省,滁州市,凤阳县",
                            "children": []
                        },
                        {
                            "id": "341181",
                            "code": "341181",
                            "name": "天长市",
                            "parentCode": "341100",
                            "fullCodes": "340000,341100,341181",
                            "fullNames": "安徽省,滁州市,天长市",
                            "children": []
                        },
                        {
                            "id": "341182",
                            "code": "341182",
                            "name": "明光市",
                            "parentCode": "341100",
                            "fullCodes": "340000,341100,341182",
                            "fullNames": "安徽省,滁州市,明光市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "341200",
                    "code": "341200",
                    "name": "阜阳市",
                    "parentCode": "340000",
                    "fullCodes": "340000,341200",
                    "fullNames": "安徽省,阜阳市",
                    "children": [
                        {
                            "id": "341202",
                            "code": "341202",
                            "name": "颍州区",
                            "parentCode": "341200",
                            "fullCodes": "340000,341200,341202",
                            "fullNames": "安徽省,阜阳市,颍州区",
                            "children": []
                        },
                        {
                            "id": "341203",
                            "code": "341203",
                            "name": "颍东区",
                            "parentCode": "341200",
                            "fullCodes": "340000,341200,341203",
                            "fullNames": "安徽省,阜阳市,颍东区",
                            "children": []
                        },
                        {
                            "id": "341204",
                            "code": "341204",
                            "name": "颍泉区",
                            "parentCode": "341200",
                            "fullCodes": "340000,341200,341204",
                            "fullNames": "安徽省,阜阳市,颍泉区",
                            "children": []
                        },
                        {
                            "id": "341221",
                            "code": "341221",
                            "name": "临泉县",
                            "parentCode": "341200",
                            "fullCodes": "340000,341200,341221",
                            "fullNames": "安徽省,阜阳市,临泉县",
                            "children": []
                        },
                        {
                            "id": "341222",
                            "code": "341222",
                            "name": "太和县",
                            "parentCode": "341200",
                            "fullCodes": "340000,341200,341222",
                            "fullNames": "安徽省,阜阳市,太和县",
                            "children": []
                        },
                        {
                            "id": "341225",
                            "code": "341225",
                            "name": "阜南县",
                            "parentCode": "341200",
                            "fullCodes": "340000,341200,341225",
                            "fullNames": "安徽省,阜阳市,阜南县",
                            "children": []
                        },
                        {
                            "id": "341226",
                            "code": "341226",
                            "name": "颍上县",
                            "parentCode": "341200",
                            "fullCodes": "340000,341200,341226",
                            "fullNames": "安徽省,阜阳市,颍上县",
                            "children": []
                        },
                        {
                            "id": "341282",
                            "code": "341282",
                            "name": "界首市",
                            "parentCode": "341200",
                            "fullCodes": "340000,341200,341282",
                            "fullNames": "安徽省,阜阳市,界首市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "341300",
                    "code": "341300",
                    "name": "宿州市",
                    "parentCode": "340000",
                    "fullCodes": "340000,341300",
                    "fullNames": "安徽省,宿州市",
                    "children": [
                        {
                            "id": "341302",
                            "code": "341302",
                            "name": "埇桥区",
                            "parentCode": "341300",
                            "fullCodes": "340000,341300,341302",
                            "fullNames": "安徽省,宿州市,埇桥区",
                            "children": []
                        },
                        {
                            "id": "341321",
                            "code": "341321",
                            "name": "砀山县",
                            "parentCode": "341300",
                            "fullCodes": "340000,341300,341321",
                            "fullNames": "安徽省,宿州市,砀山县",
                            "children": []
                        },
                        {
                            "id": "341322",
                            "code": "341322",
                            "name": "萧县",
                            "parentCode": "341300",
                            "fullCodes": "340000,341300,341322",
                            "fullNames": "安徽省,宿州市,萧县",
                            "children": []
                        },
                        {
                            "id": "341323",
                            "code": "341323",
                            "name": "灵璧县",
                            "parentCode": "341300",
                            "fullCodes": "340000,341300,341323",
                            "fullNames": "安徽省,宿州市,灵璧县",
                            "children": []
                        },
                        {
                            "id": "341324",
                            "code": "341324",
                            "name": "泗县",
                            "parentCode": "341300",
                            "fullCodes": "340000,341300,341324",
                            "fullNames": "安徽省,宿州市,泗县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "341500",
                    "code": "341500",
                    "name": "六安市",
                    "parentCode": "340000",
                    "fullCodes": "340000,341500",
                    "fullNames": "安徽省,六安市",
                    "children": [
                        {
                            "id": "341502",
                            "code": "341502",
                            "name": "金安区",
                            "parentCode": "341500",
                            "fullCodes": "340000,341500,341502",
                            "fullNames": "安徽省,六安市,金安区",
                            "children": []
                        },
                        {
                            "id": "341503",
                            "code": "341503",
                            "name": "裕安区",
                            "parentCode": "341500",
                            "fullCodes": "340000,341500,341503",
                            "fullNames": "安徽省,六安市,裕安区",
                            "children": []
                        },
                        {
                            "id": "341521",
                            "code": "341521",
                            "name": "寿县",
                            "parentCode": "341500",
                            "fullCodes": "340000,341500,341521",
                            "fullNames": "安徽省,六安市,寿县",
                            "children": []
                        },
                        {
                            "id": "341522",
                            "code": "341522",
                            "name": "霍邱县",
                            "parentCode": "341500",
                            "fullCodes": "340000,341500,341522",
                            "fullNames": "安徽省,六安市,霍邱县",
                            "children": []
                        },
                        {
                            "id": "341523",
                            "code": "341523",
                            "name": "舒城县",
                            "parentCode": "341500",
                            "fullCodes": "340000,341500,341523",
                            "fullNames": "安徽省,六安市,舒城县",
                            "children": []
                        },
                        {
                            "id": "341524",
                            "code": "341524",
                            "name": "金寨县",
                            "parentCode": "341500",
                            "fullCodes": "340000,341500,341524",
                            "fullNames": "安徽省,六安市,金寨县",
                            "children": []
                        },
                        {
                            "id": "341525",
                            "code": "341525",
                            "name": "霍山县",
                            "parentCode": "341500",
                            "fullCodes": "340000,341500,341525",
                            "fullNames": "安徽省,六安市,霍山县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "341600",
                    "code": "341600",
                    "name": "亳州市",
                    "parentCode": "340000",
                    "fullCodes": "340000,341600",
                    "fullNames": "安徽省,亳州市",
                    "children": [
                        {
                            "id": "341602",
                            "code": "341602",
                            "name": "谯城区",
                            "parentCode": "341600",
                            "fullCodes": "340000,341600,341602",
                            "fullNames": "安徽省,亳州市,谯城区",
                            "children": []
                        },
                        {
                            "id": "341621",
                            "code": "341621",
                            "name": "涡阳县",
                            "parentCode": "341600",
                            "fullCodes": "340000,341600,341621",
                            "fullNames": "安徽省,亳州市,涡阳县",
                            "children": []
                        },
                        {
                            "id": "341622",
                            "code": "341622",
                            "name": "蒙城县",
                            "parentCode": "341600",
                            "fullCodes": "340000,341600,341622",
                            "fullNames": "安徽省,亳州市,蒙城县",
                            "children": []
                        },
                        {
                            "id": "341623",
                            "code": "341623",
                            "name": "利辛县",
                            "parentCode": "341600",
                            "fullCodes": "340000,341600,341623",
                            "fullNames": "安徽省,亳州市,利辛县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "341700",
                    "code": "341700",
                    "name": "池州市",
                    "parentCode": "340000",
                    "fullCodes": "340000,341700",
                    "fullNames": "安徽省,池州市",
                    "children": [
                        {
                            "id": "341702",
                            "code": "341702",
                            "name": "贵池区",
                            "parentCode": "341700",
                            "fullCodes": "340000,341700,341702",
                            "fullNames": "安徽省,池州市,贵池区",
                            "children": []
                        },
                        {
                            "id": "341721",
                            "code": "341721",
                            "name": "东至县",
                            "parentCode": "341700",
                            "fullCodes": "340000,341700,341721",
                            "fullNames": "安徽省,池州市,东至县",
                            "children": []
                        },
                        {
                            "id": "341722",
                            "code": "341722",
                            "name": "石台县",
                            "parentCode": "341700",
                            "fullCodes": "340000,341700,341722",
                            "fullNames": "安徽省,池州市,石台县",
                            "children": []
                        },
                        {
                            "id": "341723",
                            "code": "341723",
                            "name": "青阳县",
                            "parentCode": "341700",
                            "fullCodes": "340000,341700,341723",
                            "fullNames": "安徽省,池州市,青阳县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "341800",
                    "code": "341800",
                    "name": "宣城市",
                    "parentCode": "340000",
                    "fullCodes": "340000,341800",
                    "fullNames": "安徽省,宣城市",
                    "children": [
                        {
                            "id": "341802",
                            "code": "341802",
                            "name": "宣州区",
                            "parentCode": "341800",
                            "fullCodes": "340000,341800,341802",
                            "fullNames": "安徽省,宣城市,宣州区",
                            "children": []
                        },
                        {
                            "id": "341821",
                            "code": "341821",
                            "name": "郎溪县",
                            "parentCode": "341800",
                            "fullCodes": "340000,341800,341821",
                            "fullNames": "安徽省,宣城市,郎溪县",
                            "children": []
                        },
                        {
                            "id": "341822",
                            "code": "341822",
                            "name": "广德县",
                            "parentCode": "341800",
                            "fullCodes": "340000,341800,341822",
                            "fullNames": "安徽省,宣城市,广德县",
                            "children": []
                        },
                        {
                            "id": "341823",
                            "code": "341823",
                            "name": "泾县",
                            "parentCode": "341800",
                            "fullCodes": "340000,341800,341823",
                            "fullNames": "安徽省,宣城市,泾县",
                            "children": []
                        },
                        {
                            "id": "341824",
                            "code": "341824",
                            "name": "绩溪县",
                            "parentCode": "341800",
                            "fullCodes": "340000,341800,341824",
                            "fullNames": "安徽省,宣城市,绩溪县",
                            "children": []
                        },
                        {
                            "id": "341825",
                            "code": "341825",
                            "name": "旌德县",
                            "parentCode": "341800",
                            "fullCodes": "340000,341800,341825",
                            "fullNames": "安徽省,宣城市,旌德县",
                            "children": []
                        },
                        {
                            "id": "341881",
                            "code": "341881",
                            "name": "宁国市",
                            "parentCode": "341800",
                            "fullCodes": "340000,341800,341881",
                            "fullNames": "安徽省,宣城市,宁国市",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "350000",
            "code": "350000",
            "name": "福建省",
            "parentCode": "-1",
            "fullCodes": "350000",
            "fullNames": "福建省",
            "children": [
                {
                    "id": "350100",
                    "code": "350100",
                    "name": "福州市",
                    "parentCode": "350000",
                    "fullCodes": "350000,350100",
                    "fullNames": "福建省,福州市",
                    "children": [
                        {
                            "id": "350102",
                            "code": "350102",
                            "name": "鼓楼区",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350102",
                            "fullNames": "福建省,福州市,鼓楼区",
                            "children": []
                        },
                        {
                            "id": "350103",
                            "code": "350103",
                            "name": "台江区",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350103",
                            "fullNames": "福建省,福州市,台江区",
                            "children": []
                        },
                        {
                            "id": "350104",
                            "code": "350104",
                            "name": "仓山区",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350104",
                            "fullNames": "福建省,福州市,仓山区",
                            "children": []
                        },
                        {
                            "id": "350105",
                            "code": "350105",
                            "name": "马尾区",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350105",
                            "fullNames": "福建省,福州市,马尾区",
                            "children": []
                        },
                        {
                            "id": "350111",
                            "code": "350111",
                            "name": "晋安区",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350111",
                            "fullNames": "福建省,福州市,晋安区",
                            "children": []
                        },
                        {
                            "id": "350121",
                            "code": "350121",
                            "name": "闽侯县",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350121",
                            "fullNames": "福建省,福州市,闽侯县",
                            "children": []
                        },
                        {
                            "id": "350122",
                            "code": "350122",
                            "name": "连江县",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350122",
                            "fullNames": "福建省,福州市,连江县",
                            "children": []
                        },
                        {
                            "id": "350123",
                            "code": "350123",
                            "name": "罗源县",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350123",
                            "fullNames": "福建省,福州市,罗源县",
                            "children": []
                        },
                        {
                            "id": "350124",
                            "code": "350124",
                            "name": "闽清县",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350124",
                            "fullNames": "福建省,福州市,闽清县",
                            "children": []
                        },
                        {
                            "id": "350125",
                            "code": "350125",
                            "name": "永泰县",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350125",
                            "fullNames": "福建省,福州市,永泰县",
                            "children": []
                        },
                        {
                            "id": "350128",
                            "code": "350128",
                            "name": "平潭县",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350128",
                            "fullNames": "福建省,福州市,平潭县",
                            "children": []
                        },
                        {
                            "id": "350181",
                            "code": "350181",
                            "name": "福清市",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350181",
                            "fullNames": "福建省,福州市,福清市",
                            "children": []
                        },
                        {
                            "id": "350182",
                            "code": "350182",
                            "name": "长乐市",
                            "parentCode": "350100",
                            "fullCodes": "350000,350100,350182",
                            "fullNames": "福建省,福州市,长乐市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "350200",
                    "code": "350200",
                    "name": "厦门市",
                    "parentCode": "350000",
                    "fullCodes": "350000,350200",
                    "fullNames": "福建省,厦门市",
                    "children": [
                        {
                            "id": "350203",
                            "code": "350203",
                            "name": "思明区",
                            "parentCode": "350200",
                            "fullCodes": "350000,350200,350203",
                            "fullNames": "福建省,厦门市,思明区",
                            "children": []
                        },
                        {
                            "id": "350205",
                            "code": "350205",
                            "name": "海沧区",
                            "parentCode": "350200",
                            "fullCodes": "350000,350200,350205",
                            "fullNames": "福建省,厦门市,海沧区",
                            "children": []
                        },
                        {
                            "id": "350206",
                            "code": "350206",
                            "name": "湖里区",
                            "parentCode": "350200",
                            "fullCodes": "350000,350200,350206",
                            "fullNames": "福建省,厦门市,湖里区",
                            "children": []
                        },
                        {
                            "id": "350211",
                            "code": "350211",
                            "name": "集美区",
                            "parentCode": "350200",
                            "fullCodes": "350000,350200,350211",
                            "fullNames": "福建省,厦门市,集美区",
                            "children": []
                        },
                        {
                            "id": "350212",
                            "code": "350212",
                            "name": "同安区",
                            "parentCode": "350200",
                            "fullCodes": "350000,350200,350212",
                            "fullNames": "福建省,厦门市,同安区",
                            "children": []
                        },
                        {
                            "id": "350213",
                            "code": "350213",
                            "name": "翔安区",
                            "parentCode": "350200",
                            "fullCodes": "350000,350200,350213",
                            "fullNames": "福建省,厦门市,翔安区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "350300",
                    "code": "350300",
                    "name": "莆田市",
                    "parentCode": "350000",
                    "fullCodes": "350000,350300",
                    "fullNames": "福建省,莆田市",
                    "children": [
                        {
                            "id": "350302",
                            "code": "350302",
                            "name": "城厢区",
                            "parentCode": "350300",
                            "fullCodes": "350000,350300,350302",
                            "fullNames": "福建省,莆田市,城厢区",
                            "children": []
                        },
                        {
                            "id": "350303",
                            "code": "350303",
                            "name": "涵江区",
                            "parentCode": "350300",
                            "fullCodes": "350000,350300,350303",
                            "fullNames": "福建省,莆田市,涵江区",
                            "children": []
                        },
                        {
                            "id": "350304",
                            "code": "350304",
                            "name": "荔城区",
                            "parentCode": "350300",
                            "fullCodes": "350000,350300,350304",
                            "fullNames": "福建省,莆田市,荔城区",
                            "children": []
                        },
                        {
                            "id": "350305",
                            "code": "350305",
                            "name": "秀屿区",
                            "parentCode": "350300",
                            "fullCodes": "350000,350300,350305",
                            "fullNames": "福建省,莆田市,秀屿区",
                            "children": []
                        },
                        {
                            "id": "350322",
                            "code": "350322",
                            "name": "仙游县",
                            "parentCode": "350300",
                            "fullCodes": "350000,350300,350322",
                            "fullNames": "福建省,莆田市,仙游县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "350400",
                    "code": "350400",
                    "name": "三明市",
                    "parentCode": "350000",
                    "fullCodes": "350000,350400",
                    "fullNames": "福建省,三明市",
                    "children": [
                        {
                            "id": "350402",
                            "code": "350402",
                            "name": "梅列区",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350402",
                            "fullNames": "福建省,三明市,梅列区",
                            "children": []
                        },
                        {
                            "id": "350403",
                            "code": "350403",
                            "name": "三元区",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350403",
                            "fullNames": "福建省,三明市,三元区",
                            "children": []
                        },
                        {
                            "id": "350421",
                            "code": "350421",
                            "name": "明溪县",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350421",
                            "fullNames": "福建省,三明市,明溪县",
                            "children": []
                        },
                        {
                            "id": "350423",
                            "code": "350423",
                            "name": "清流县",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350423",
                            "fullNames": "福建省,三明市,清流县",
                            "children": []
                        },
                        {
                            "id": "350424",
                            "code": "350424",
                            "name": "宁化县",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350424",
                            "fullNames": "福建省,三明市,宁化县",
                            "children": []
                        },
                        {
                            "id": "350425",
                            "code": "350425",
                            "name": "大田县",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350425",
                            "fullNames": "福建省,三明市,大田县",
                            "children": []
                        },
                        {
                            "id": "350426",
                            "code": "350426",
                            "name": "尤溪县",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350426",
                            "fullNames": "福建省,三明市,尤溪县",
                            "children": []
                        },
                        {
                            "id": "350427",
                            "code": "350427",
                            "name": "沙县",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350427",
                            "fullNames": "福建省,三明市,沙县",
                            "children": []
                        },
                        {
                            "id": "350428",
                            "code": "350428",
                            "name": "将乐县",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350428",
                            "fullNames": "福建省,三明市,将乐县",
                            "children": []
                        },
                        {
                            "id": "350429",
                            "code": "350429",
                            "name": "泰宁县",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350429",
                            "fullNames": "福建省,三明市,泰宁县",
                            "children": []
                        },
                        {
                            "id": "350430",
                            "code": "350430",
                            "name": "建宁县",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350430",
                            "fullNames": "福建省,三明市,建宁县",
                            "children": []
                        },
                        {
                            "id": "350481",
                            "code": "350481",
                            "name": "永安市",
                            "parentCode": "350400",
                            "fullCodes": "350000,350400,350481",
                            "fullNames": "福建省,三明市,永安市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "350500",
                    "code": "350500",
                    "name": "泉州市",
                    "parentCode": "350000",
                    "fullCodes": "350000,350500",
                    "fullNames": "福建省,泉州市",
                    "children": [
                        {
                            "id": "350502",
                            "code": "350502",
                            "name": "鲤城区",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350502",
                            "fullNames": "福建省,泉州市,鲤城区",
                            "children": []
                        },
                        {
                            "id": "350503",
                            "code": "350503",
                            "name": "丰泽区",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350503",
                            "fullNames": "福建省,泉州市,丰泽区",
                            "children": []
                        },
                        {
                            "id": "350504",
                            "code": "350504",
                            "name": "洛江区",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350504",
                            "fullNames": "福建省,泉州市,洛江区",
                            "children": []
                        },
                        {
                            "id": "350505",
                            "code": "350505",
                            "name": "泉港区",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350505",
                            "fullNames": "福建省,泉州市,泉港区",
                            "children": []
                        },
                        {
                            "id": "350521",
                            "code": "350521",
                            "name": "惠安县",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350521",
                            "fullNames": "福建省,泉州市,惠安县",
                            "children": []
                        },
                        {
                            "id": "350524",
                            "code": "350524",
                            "name": "安溪县",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350524",
                            "fullNames": "福建省,泉州市,安溪县",
                            "children": []
                        },
                        {
                            "id": "350525",
                            "code": "350525",
                            "name": "永春县",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350525",
                            "fullNames": "福建省,泉州市,永春县",
                            "children": []
                        },
                        {
                            "id": "350526",
                            "code": "350526",
                            "name": "德化县",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350526",
                            "fullNames": "福建省,泉州市,德化县",
                            "children": []
                        },
                        {
                            "id": "350527",
                            "code": "350527",
                            "name": "金门县",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350527",
                            "fullNames": "福建省,泉州市,金门县",
                            "children": []
                        },
                        {
                            "id": "350581",
                            "code": "350581",
                            "name": "石狮市",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350581",
                            "fullNames": "福建省,泉州市,石狮市",
                            "children": []
                        },
                        {
                            "id": "350582",
                            "code": "350582",
                            "name": "晋江市",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350582",
                            "fullNames": "福建省,泉州市,晋江市",
                            "children": []
                        },
                        {
                            "id": "350583",
                            "code": "350583",
                            "name": "南安市",
                            "parentCode": "350500",
                            "fullCodes": "350000,350500,350583",
                            "fullNames": "福建省,泉州市,南安市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "350600",
                    "code": "350600",
                    "name": "漳州市",
                    "parentCode": "350000",
                    "fullCodes": "350000,350600",
                    "fullNames": "福建省,漳州市",
                    "children": [
                        {
                            "id": "350602",
                            "code": "350602",
                            "name": "芗城区",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350602",
                            "fullNames": "福建省,漳州市,芗城区",
                            "children": []
                        },
                        {
                            "id": "350603",
                            "code": "350603",
                            "name": "龙文区",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350603",
                            "fullNames": "福建省,漳州市,龙文区",
                            "children": []
                        },
                        {
                            "id": "350622",
                            "code": "350622",
                            "name": "云霄县",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350622",
                            "fullNames": "福建省,漳州市,云霄县",
                            "children": []
                        },
                        {
                            "id": "350623",
                            "code": "350623",
                            "name": "漳浦县",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350623",
                            "fullNames": "福建省,漳州市,漳浦县",
                            "children": []
                        },
                        {
                            "id": "350624",
                            "code": "350624",
                            "name": "诏安县",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350624",
                            "fullNames": "福建省,漳州市,诏安县",
                            "children": []
                        },
                        {
                            "id": "350625",
                            "code": "350625",
                            "name": "长泰县",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350625",
                            "fullNames": "福建省,漳州市,长泰县",
                            "children": []
                        },
                        {
                            "id": "350626",
                            "code": "350626",
                            "name": "东山县",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350626",
                            "fullNames": "福建省,漳州市,东山县",
                            "children": []
                        },
                        {
                            "id": "350627",
                            "code": "350627",
                            "name": "南靖县",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350627",
                            "fullNames": "福建省,漳州市,南靖县",
                            "children": []
                        },
                        {
                            "id": "350628",
                            "code": "350628",
                            "name": "平和县",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350628",
                            "fullNames": "福建省,漳州市,平和县",
                            "children": []
                        },
                        {
                            "id": "350629",
                            "code": "350629",
                            "name": "华安县",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350629",
                            "fullNames": "福建省,漳州市,华安县",
                            "children": []
                        },
                        {
                            "id": "350681",
                            "code": "350681",
                            "name": "龙海市",
                            "parentCode": "350600",
                            "fullCodes": "350000,350600,350681",
                            "fullNames": "福建省,漳州市,龙海市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "350700",
                    "code": "350700",
                    "name": "南平市",
                    "parentCode": "350000",
                    "fullCodes": "350000,350700",
                    "fullNames": "福建省,南平市",
                    "children": [
                        {
                            "id": "350702",
                            "code": "350702",
                            "name": "延平区",
                            "parentCode": "350700",
                            "fullCodes": "350000,350700,350702",
                            "fullNames": "福建省,南平市,延平区",
                            "children": []
                        },
                        {
                            "id": "350721",
                            "code": "350721",
                            "name": "顺昌县",
                            "parentCode": "350700",
                            "fullCodes": "350000,350700,350721",
                            "fullNames": "福建省,南平市,顺昌县",
                            "children": []
                        },
                        {
                            "id": "350722",
                            "code": "350722",
                            "name": "浦城县",
                            "parentCode": "350700",
                            "fullCodes": "350000,350700,350722",
                            "fullNames": "福建省,南平市,浦城县",
                            "children": []
                        },
                        {
                            "id": "350723",
                            "code": "350723",
                            "name": "光泽县",
                            "parentCode": "350700",
                            "fullCodes": "350000,350700,350723",
                            "fullNames": "福建省,南平市,光泽县",
                            "children": []
                        },
                        {
                            "id": "350724",
                            "code": "350724",
                            "name": "松溪县",
                            "parentCode": "350700",
                            "fullCodes": "350000,350700,350724",
                            "fullNames": "福建省,南平市,松溪县",
                            "children": []
                        },
                        {
                            "id": "350725",
                            "code": "350725",
                            "name": "政和县",
                            "parentCode": "350700",
                            "fullCodes": "350000,350700,350725",
                            "fullNames": "福建省,南平市,政和县",
                            "children": []
                        },
                        {
                            "id": "350781",
                            "code": "350781",
                            "name": "邵武市",
                            "parentCode": "350700",
                            "fullCodes": "350000,350700,350781",
                            "fullNames": "福建省,南平市,邵武市",
                            "children": []
                        },
                        {
                            "id": "350782",
                            "code": "350782",
                            "name": "武夷山市",
                            "parentCode": "350700",
                            "fullCodes": "350000,350700,350782",
                            "fullNames": "福建省,南平市,武夷山市",
                            "children": []
                        },
                        {
                            "id": "350783",
                            "code": "350783",
                            "name": "建瓯市",
                            "parentCode": "350700",
                            "fullCodes": "350000,350700,350783",
                            "fullNames": "福建省,南平市,建瓯市",
                            "children": []
                        },
                        {
                            "id": "350784",
                            "code": "350784",
                            "name": "建阳市",
                            "parentCode": "350700",
                            "fullCodes": "350000,350700,350784",
                            "fullNames": "福建省,南平市,建阳市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "350800",
                    "code": "350800",
                    "name": "龙岩市",
                    "parentCode": "350000",
                    "fullCodes": "350000,350800",
                    "fullNames": "福建省,龙岩市",
                    "children": [
                        {
                            "id": "350802",
                            "code": "350802",
                            "name": "新罗区",
                            "parentCode": "350800",
                            "fullCodes": "350000,350800,350802",
                            "fullNames": "福建省,龙岩市,新罗区",
                            "children": []
                        },
                        {
                            "id": "350821",
                            "code": "350821",
                            "name": "长汀县",
                            "parentCode": "350800",
                            "fullCodes": "350000,350800,350821",
                            "fullNames": "福建省,龙岩市,长汀县",
                            "children": []
                        },
                        {
                            "id": "350822",
                            "code": "350822",
                            "name": "永定县",
                            "parentCode": "350800",
                            "fullCodes": "350000,350800,350822",
                            "fullNames": "福建省,龙岩市,永定县",
                            "children": []
                        },
                        {
                            "id": "350823",
                            "code": "350823",
                            "name": "上杭县",
                            "parentCode": "350800",
                            "fullCodes": "350000,350800,350823",
                            "fullNames": "福建省,龙岩市,上杭县",
                            "children": []
                        },
                        {
                            "id": "350824",
                            "code": "350824",
                            "name": "武平县",
                            "parentCode": "350800",
                            "fullCodes": "350000,350800,350824",
                            "fullNames": "福建省,龙岩市,武平县",
                            "children": []
                        },
                        {
                            "id": "350825",
                            "code": "350825",
                            "name": "连城县",
                            "parentCode": "350800",
                            "fullCodes": "350000,350800,350825",
                            "fullNames": "福建省,龙岩市,连城县",
                            "children": []
                        },
                        {
                            "id": "350881",
                            "code": "350881",
                            "name": "漳平市",
                            "parentCode": "350800",
                            "fullCodes": "350000,350800,350881",
                            "fullNames": "福建省,龙岩市,漳平市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "350900",
                    "code": "350900",
                    "name": "宁德市",
                    "parentCode": "350000",
                    "fullCodes": "350000,350900",
                    "fullNames": "福建省,宁德市",
                    "children": [
                        {
                            "id": "350902",
                            "code": "350902",
                            "name": "蕉城区",
                            "parentCode": "350900",
                            "fullCodes": "350000,350900,350902",
                            "fullNames": "福建省,宁德市,蕉城区",
                            "children": []
                        },
                        {
                            "id": "350921",
                            "code": "350921",
                            "name": "霞浦县",
                            "parentCode": "350900",
                            "fullCodes": "350000,350900,350921",
                            "fullNames": "福建省,宁德市,霞浦县",
                            "children": []
                        },
                        {
                            "id": "350922",
                            "code": "350922",
                            "name": "古田县",
                            "parentCode": "350900",
                            "fullCodes": "350000,350900,350922",
                            "fullNames": "福建省,宁德市,古田县",
                            "children": []
                        },
                        {
                            "id": "350923",
                            "code": "350923",
                            "name": "屏南县",
                            "parentCode": "350900",
                            "fullCodes": "350000,350900,350923",
                            "fullNames": "福建省,宁德市,屏南县",
                            "children": []
                        },
                        {
                            "id": "350924",
                            "code": "350924",
                            "name": "寿宁县",
                            "parentCode": "350900",
                            "fullCodes": "350000,350900,350924",
                            "fullNames": "福建省,宁德市,寿宁县",
                            "children": []
                        },
                        {
                            "id": "350925",
                            "code": "350925",
                            "name": "周宁县",
                            "parentCode": "350900",
                            "fullCodes": "350000,350900,350925",
                            "fullNames": "福建省,宁德市,周宁县",
                            "children": []
                        },
                        {
                            "id": "350926",
                            "code": "350926",
                            "name": "柘荣县",
                            "parentCode": "350900",
                            "fullCodes": "350000,350900,350926",
                            "fullNames": "福建省,宁德市,柘荣县",
                            "children": []
                        },
                        {
                            "id": "350981",
                            "code": "350981",
                            "name": "福安市",
                            "parentCode": "350900",
                            "fullCodes": "350000,350900,350981",
                            "fullNames": "福建省,宁德市,福安市",
                            "children": []
                        },
                        {
                            "id": "350982",
                            "code": "350982",
                            "name": "福鼎市",
                            "parentCode": "350900",
                            "fullCodes": "350000,350900,350982",
                            "fullNames": "福建省,宁德市,福鼎市",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "360000",
            "code": "360000",
            "name": "江西省",
            "parentCode": "-1",
            "fullCodes": "360000",
            "fullNames": "江西省",
            "children": [
                {
                    "id": "360100",
                    "code": "360100",
                    "name": "南昌市",
                    "parentCode": "360000",
                    "fullCodes": "360000,360100",
                    "fullNames": "江西省,南昌市",
                    "children": [
                        {
                            "id": "360102",
                            "code": "360102",
                            "name": "东湖区",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360102",
                            "fullNames": "江西省,南昌市,东湖区",
                            "children": []
                        },
                        {
                            "id": "360103",
                            "code": "360103",
                            "name": "西湖区",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360103",
                            "fullNames": "江西省,南昌市,西湖区",
                            "children": []
                        },
                        {
                            "id": "360104",
                            "code": "360104",
                            "name": "青云谱区",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360104",
                            "fullNames": "江西省,南昌市,青云谱区",
                            "children": []
                        },
                        {
                            "id": "360105",
                            "code": "360105",
                            "name": "湾里区",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360105",
                            "fullNames": "江西省,南昌市,湾里区",
                            "children": []
                        },
                        {
                            "id": "360111",
                            "code": "360111",
                            "name": "青山湖区",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360111",
                            "fullNames": "江西省,南昌市,青山湖区",
                            "children": []
                        },
                        {
                            "id": "360121",
                            "code": "360121",
                            "name": "南昌县",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360121",
                            "fullNames": "江西省,南昌市,南昌县",
                            "children": []
                        },
                        {
                            "id": "360122",
                            "code": "360122",
                            "name": "新建县",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360122",
                            "fullNames": "江西省,南昌市,新建县",
                            "children": []
                        },
                        {
                            "id": "360123",
                            "code": "360123",
                            "name": "安义县",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360123",
                            "fullNames": "江西省,南昌市,安义县",
                            "children": []
                        },
                        {
                            "id": "360124",
                            "code": "360124",
                            "name": "进贤县",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360124",
                            "fullNames": "江西省,南昌市,进贤县",
                            "children": []
                        },
                        {
                            "id": "360125",
                            "code": "360125",
                            "name": "红谷滩新区",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360125",
                            "fullNames": "江西省,南昌市,红谷滩新区",
                            "children": []
                        },
                        {
                            "id": "360126",
                            "code": "360126",
                            "name": "经济技术开发区",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360126",
                            "fullNames": "江西省,南昌市,经济技术开发区",
                            "children": []
                        },
                        {
                            "id": "360127",
                            "code": "360127",
                            "name": "昌北区",
                            "parentCode": "360100",
                            "fullCodes": "360000,360100,360127",
                            "fullNames": "江西省,南昌市,昌北区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "360200",
                    "code": "360200",
                    "name": "景德镇市",
                    "parentCode": "360000",
                    "fullCodes": "360000,360200",
                    "fullNames": "江西省,景德镇市",
                    "children": [
                        {
                            "id": "360202",
                            "code": "360202",
                            "name": "昌江区",
                            "parentCode": "360200",
                            "fullCodes": "360000,360200,360202",
                            "fullNames": "江西省,景德镇市,昌江区",
                            "children": []
                        },
                        {
                            "id": "360203",
                            "code": "360203",
                            "name": "珠山区",
                            "parentCode": "360200",
                            "fullCodes": "360000,360200,360203",
                            "fullNames": "江西省,景德镇市,珠山区",
                            "children": []
                        },
                        {
                            "id": "360222",
                            "code": "360222",
                            "name": "浮梁县",
                            "parentCode": "360200",
                            "fullCodes": "360000,360200,360222",
                            "fullNames": "江西省,景德镇市,浮梁县",
                            "children": []
                        },
                        {
                            "id": "360281",
                            "code": "360281",
                            "name": "乐平市",
                            "parentCode": "360200",
                            "fullCodes": "360000,360200,360281",
                            "fullNames": "江西省,景德镇市,乐平市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "360300",
                    "code": "360300",
                    "name": "萍乡市",
                    "parentCode": "360000",
                    "fullCodes": "360000,360300",
                    "fullNames": "江西省,萍乡市",
                    "children": [
                        {
                            "id": "360302",
                            "code": "360302",
                            "name": "安源区",
                            "parentCode": "360300",
                            "fullCodes": "360000,360300,360302",
                            "fullNames": "江西省,萍乡市,安源区",
                            "children": []
                        },
                        {
                            "id": "360313",
                            "code": "360313",
                            "name": "湘东区",
                            "parentCode": "360300",
                            "fullCodes": "360000,360300,360313",
                            "fullNames": "江西省,萍乡市,湘东区",
                            "children": []
                        },
                        {
                            "id": "360321",
                            "code": "360321",
                            "name": "莲花县",
                            "parentCode": "360300",
                            "fullCodes": "360000,360300,360321",
                            "fullNames": "江西省,萍乡市,莲花县",
                            "children": []
                        },
                        {
                            "id": "360322",
                            "code": "360322",
                            "name": "上栗县",
                            "parentCode": "360300",
                            "fullCodes": "360000,360300,360322",
                            "fullNames": "江西省,萍乡市,上栗县",
                            "children": []
                        },
                        {
                            "id": "360323",
                            "code": "360323",
                            "name": "芦溪县",
                            "parentCode": "360300",
                            "fullCodes": "360000,360300,360323",
                            "fullNames": "江西省,萍乡市,芦溪县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "360400",
                    "code": "360400",
                    "name": "九江市",
                    "parentCode": "360000",
                    "fullCodes": "360000,360400",
                    "fullNames": "江西省,九江市",
                    "children": [
                        {
                            "id": "360402",
                            "code": "360402",
                            "name": "庐山区",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360402",
                            "fullNames": "江西省,九江市,庐山区",
                            "children": []
                        },
                        {
                            "id": "360403",
                            "code": "360403",
                            "name": "浔阳区",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360403",
                            "fullNames": "江西省,九江市,浔阳区",
                            "children": []
                        },
                        {
                            "id": "360421",
                            "code": "360421",
                            "name": "九江县",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360421",
                            "fullNames": "江西省,九江市,九江县",
                            "children": []
                        },
                        {
                            "id": "360423",
                            "code": "360423",
                            "name": "武宁县",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360423",
                            "fullNames": "江西省,九江市,武宁县",
                            "children": []
                        },
                        {
                            "id": "360424",
                            "code": "360424",
                            "name": "修水县",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360424",
                            "fullNames": "江西省,九江市,修水县",
                            "children": []
                        },
                        {
                            "id": "360425",
                            "code": "360425",
                            "name": "永修县",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360425",
                            "fullNames": "江西省,九江市,永修县",
                            "children": []
                        },
                        {
                            "id": "360426",
                            "code": "360426",
                            "name": "德安县",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360426",
                            "fullNames": "江西省,九江市,德安县",
                            "children": []
                        },
                        {
                            "id": "360427",
                            "code": "360427",
                            "name": "星子县",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360427",
                            "fullNames": "江西省,九江市,星子县",
                            "children": []
                        },
                        {
                            "id": "360428",
                            "code": "360428",
                            "name": "都昌县",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360428",
                            "fullNames": "江西省,九江市,都昌县",
                            "children": []
                        },
                        {
                            "id": "360429",
                            "code": "360429",
                            "name": "湖口县",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360429",
                            "fullNames": "江西省,九江市,湖口县",
                            "children": []
                        },
                        {
                            "id": "360430",
                            "code": "360430",
                            "name": "彭泽县",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360430",
                            "fullNames": "江西省,九江市,彭泽县",
                            "children": []
                        },
                        {
                            "id": "360481",
                            "code": "360481",
                            "name": "瑞昌市",
                            "parentCode": "360400",
                            "fullCodes": "360000,360400,360481",
                            "fullNames": "江西省,九江市,瑞昌市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "360500",
                    "code": "360500",
                    "name": "新余市",
                    "parentCode": "360000",
                    "fullCodes": "360000,360500",
                    "fullNames": "江西省,新余市",
                    "children": [
                        {
                            "id": "360502",
                            "code": "360502",
                            "name": "渝水区",
                            "parentCode": "360500",
                            "fullCodes": "360000,360500,360502",
                            "fullNames": "江西省,新余市,渝水区",
                            "children": []
                        },
                        {
                            "id": "360521",
                            "code": "360521",
                            "name": "分宜县",
                            "parentCode": "360500",
                            "fullCodes": "360000,360500,360521",
                            "fullNames": "江西省,新余市,分宜县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "360600",
                    "code": "360600",
                    "name": "鹰潭市",
                    "parentCode": "360000",
                    "fullCodes": "360000,360600",
                    "fullNames": "江西省,鹰潭市",
                    "children": [
                        {
                            "id": "360602",
                            "code": "360602",
                            "name": "月湖区",
                            "parentCode": "360600",
                            "fullCodes": "360000,360600,360602",
                            "fullNames": "江西省,鹰潭市,月湖区",
                            "children": []
                        },
                        {
                            "id": "360622",
                            "code": "360622",
                            "name": "余江县",
                            "parentCode": "360600",
                            "fullCodes": "360000,360600,360622",
                            "fullNames": "江西省,鹰潭市,余江县",
                            "children": []
                        },
                        {
                            "id": "360681",
                            "code": "360681",
                            "name": "贵溪市",
                            "parentCode": "360600",
                            "fullCodes": "360000,360600,360681",
                            "fullNames": "江西省,鹰潭市,贵溪市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "360700",
                    "code": "360700",
                    "name": "赣州市",
                    "parentCode": "360000",
                    "fullCodes": "360000,360700",
                    "fullNames": "江西省,赣州市",
                    "children": [
                        {
                            "id": "360702",
                            "code": "360702",
                            "name": "章贡区",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360702",
                            "fullNames": "江西省,赣州市,章贡区",
                            "children": []
                        },
                        {
                            "id": "360721",
                            "code": "360721",
                            "name": "赣县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360721",
                            "fullNames": "江西省,赣州市,赣县",
                            "children": []
                        },
                        {
                            "id": "360722",
                            "code": "360722",
                            "name": "信丰县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360722",
                            "fullNames": "江西省,赣州市,信丰县",
                            "children": []
                        },
                        {
                            "id": "360723",
                            "code": "360723",
                            "name": "大余县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360723",
                            "fullNames": "江西省,赣州市,大余县",
                            "children": []
                        },
                        {
                            "id": "360724",
                            "code": "360724",
                            "name": "上犹县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360724",
                            "fullNames": "江西省,赣州市,上犹县",
                            "children": []
                        },
                        {
                            "id": "360725",
                            "code": "360725",
                            "name": "崇义县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360725",
                            "fullNames": "江西省,赣州市,崇义县",
                            "children": []
                        },
                        {
                            "id": "360726",
                            "code": "360726",
                            "name": "安远县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360726",
                            "fullNames": "江西省,赣州市,安远县",
                            "children": []
                        },
                        {
                            "id": "360727",
                            "code": "360727",
                            "name": "龙南县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360727",
                            "fullNames": "江西省,赣州市,龙南县",
                            "children": []
                        },
                        {
                            "id": "360728",
                            "code": "360728",
                            "name": "定南县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360728",
                            "fullNames": "江西省,赣州市,定南县",
                            "children": []
                        },
                        {
                            "id": "360729",
                            "code": "360729",
                            "name": "全南县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360729",
                            "fullNames": "江西省,赣州市,全南县",
                            "children": []
                        },
                        {
                            "id": "360730",
                            "code": "360730",
                            "name": "宁都县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360730",
                            "fullNames": "江西省,赣州市,宁都县",
                            "children": []
                        },
                        {
                            "id": "360731",
                            "code": "360731",
                            "name": "于都县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360731",
                            "fullNames": "江西省,赣州市,于都县",
                            "children": []
                        },
                        {
                            "id": "360732",
                            "code": "360732",
                            "name": "兴国县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360732",
                            "fullNames": "江西省,赣州市,兴国县",
                            "children": []
                        },
                        {
                            "id": "360733",
                            "code": "360733",
                            "name": "会昌县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360733",
                            "fullNames": "江西省,赣州市,会昌县",
                            "children": []
                        },
                        {
                            "id": "360734",
                            "code": "360734",
                            "name": "寻乌县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360734",
                            "fullNames": "江西省,赣州市,寻乌县",
                            "children": []
                        },
                        {
                            "id": "360735",
                            "code": "360735",
                            "name": "石城县",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360735",
                            "fullNames": "江西省,赣州市,石城县",
                            "children": []
                        },
                        {
                            "id": "360751",
                            "code": "360751",
                            "name": "黄金区",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360751",
                            "fullNames": "江西省,赣州市,黄金区",
                            "children": []
                        },
                        {
                            "id": "360781",
                            "code": "360781",
                            "name": "瑞金市",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360781",
                            "fullNames": "江西省,赣州市,瑞金市",
                            "children": []
                        },
                        {
                            "id": "360782",
                            "code": "360782",
                            "name": "南康市",
                            "parentCode": "360700",
                            "fullCodes": "360000,360700,360782",
                            "fullNames": "江西省,赣州市,南康市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "360800",
                    "code": "360800",
                    "name": "吉安市",
                    "parentCode": "360000",
                    "fullCodes": "360000,360800",
                    "fullNames": "江西省,吉安市",
                    "children": [
                        {
                            "id": "360802",
                            "code": "360802",
                            "name": "吉州区",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360802",
                            "fullNames": "江西省,吉安市,吉州区",
                            "children": []
                        },
                        {
                            "id": "360803",
                            "code": "360803",
                            "name": "青原区",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360803",
                            "fullNames": "江西省,吉安市,青原区",
                            "children": []
                        },
                        {
                            "id": "360821",
                            "code": "360821",
                            "name": "吉安县",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360821",
                            "fullNames": "江西省,吉安市,吉安县",
                            "children": []
                        },
                        {
                            "id": "360822",
                            "code": "360822",
                            "name": "吉水县",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360822",
                            "fullNames": "江西省,吉安市,吉水县",
                            "children": []
                        },
                        {
                            "id": "360823",
                            "code": "360823",
                            "name": "峡江县",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360823",
                            "fullNames": "江西省,吉安市,峡江县",
                            "children": []
                        },
                        {
                            "id": "360824",
                            "code": "360824",
                            "name": "新干县",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360824",
                            "fullNames": "江西省,吉安市,新干县",
                            "children": []
                        },
                        {
                            "id": "360825",
                            "code": "360825",
                            "name": "永丰县",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360825",
                            "fullNames": "江西省,吉安市,永丰县",
                            "children": []
                        },
                        {
                            "id": "360826",
                            "code": "360826",
                            "name": "泰和县",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360826",
                            "fullNames": "江西省,吉安市,泰和县",
                            "children": []
                        },
                        {
                            "id": "360827",
                            "code": "360827",
                            "name": "遂川县",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360827",
                            "fullNames": "江西省,吉安市,遂川县",
                            "children": []
                        },
                        {
                            "id": "360828",
                            "code": "360828",
                            "name": "万安县",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360828",
                            "fullNames": "江西省,吉安市,万安县",
                            "children": []
                        },
                        {
                            "id": "360829",
                            "code": "360829",
                            "name": "安福县",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360829",
                            "fullNames": "江西省,吉安市,安福县",
                            "children": []
                        },
                        {
                            "id": "360830",
                            "code": "360830",
                            "name": "永新县",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360830",
                            "fullNames": "江西省,吉安市,永新县",
                            "children": []
                        },
                        {
                            "id": "360881",
                            "code": "360881",
                            "name": "井冈山市",
                            "parentCode": "360800",
                            "fullCodes": "360000,360800,360881",
                            "fullNames": "江西省,吉安市,井冈山市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "360900",
                    "code": "360900",
                    "name": "宜春市",
                    "parentCode": "360000",
                    "fullCodes": "360000,360900",
                    "fullNames": "江西省,宜春市",
                    "children": [
                        {
                            "id": "360902",
                            "code": "360902",
                            "name": "袁州区",
                            "parentCode": "360900",
                            "fullCodes": "360000,360900,360902",
                            "fullNames": "江西省,宜春市,袁州区",
                            "children": []
                        },
                        {
                            "id": "360921",
                            "code": "360921",
                            "name": "奉新县",
                            "parentCode": "360900",
                            "fullCodes": "360000,360900,360921",
                            "fullNames": "江西省,宜春市,奉新县",
                            "children": []
                        },
                        {
                            "id": "360922",
                            "code": "360922",
                            "name": "万载县",
                            "parentCode": "360900",
                            "fullCodes": "360000,360900,360922",
                            "fullNames": "江西省,宜春市,万载县",
                            "children": []
                        },
                        {
                            "id": "360923",
                            "code": "360923",
                            "name": "上高县",
                            "parentCode": "360900",
                            "fullCodes": "360000,360900,360923",
                            "fullNames": "江西省,宜春市,上高县",
                            "children": []
                        },
                        {
                            "id": "360924",
                            "code": "360924",
                            "name": "宜丰县",
                            "parentCode": "360900",
                            "fullCodes": "360000,360900,360924",
                            "fullNames": "江西省,宜春市,宜丰县",
                            "children": []
                        },
                        {
                            "id": "360925",
                            "code": "360925",
                            "name": "靖安县",
                            "parentCode": "360900",
                            "fullCodes": "360000,360900,360925",
                            "fullNames": "江西省,宜春市,靖安县",
                            "children": []
                        },
                        {
                            "id": "360926",
                            "code": "360926",
                            "name": "铜鼓县",
                            "parentCode": "360900",
                            "fullCodes": "360000,360900,360926",
                            "fullNames": "江西省,宜春市,铜鼓县",
                            "children": []
                        },
                        {
                            "id": "360981",
                            "code": "360981",
                            "name": "丰城市",
                            "parentCode": "360900",
                            "fullCodes": "360000,360900,360981",
                            "fullNames": "江西省,宜春市,丰城市",
                            "children": []
                        },
                        {
                            "id": "360982",
                            "code": "360982",
                            "name": "樟树市",
                            "parentCode": "360900",
                            "fullCodes": "360000,360900,360982",
                            "fullNames": "江西省,宜春市,樟树市",
                            "children": []
                        },
                        {
                            "id": "360983",
                            "code": "360983",
                            "name": "高安市",
                            "parentCode": "360900",
                            "fullCodes": "360000,360900,360983",
                            "fullNames": "江西省,宜春市,高安市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "361000",
                    "code": "361000",
                    "name": "抚州市",
                    "parentCode": "360000",
                    "fullCodes": "360000,361000",
                    "fullNames": "江西省,抚州市",
                    "children": [
                        {
                            "id": "361002",
                            "code": "361002",
                            "name": "临川区",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361002",
                            "fullNames": "江西省,抚州市,临川区",
                            "children": []
                        },
                        {
                            "id": "361021",
                            "code": "361021",
                            "name": "南城县",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361021",
                            "fullNames": "江西省,抚州市,南城县",
                            "children": []
                        },
                        {
                            "id": "361022",
                            "code": "361022",
                            "name": "黎川县",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361022",
                            "fullNames": "江西省,抚州市,黎川县",
                            "children": []
                        },
                        {
                            "id": "361023",
                            "code": "361023",
                            "name": "南丰县",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361023",
                            "fullNames": "江西省,抚州市,南丰县",
                            "children": []
                        },
                        {
                            "id": "361024",
                            "code": "361024",
                            "name": "崇仁县",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361024",
                            "fullNames": "江西省,抚州市,崇仁县",
                            "children": []
                        },
                        {
                            "id": "361025",
                            "code": "361025",
                            "name": "乐安县",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361025",
                            "fullNames": "江西省,抚州市,乐安县",
                            "children": []
                        },
                        {
                            "id": "361026",
                            "code": "361026",
                            "name": "宜黄县",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361026",
                            "fullNames": "江西省,抚州市,宜黄县",
                            "children": []
                        },
                        {
                            "id": "361027",
                            "code": "361027",
                            "name": "金溪县",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361027",
                            "fullNames": "江西省,抚州市,金溪县",
                            "children": []
                        },
                        {
                            "id": "361028",
                            "code": "361028",
                            "name": "资溪县",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361028",
                            "fullNames": "江西省,抚州市,资溪县",
                            "children": []
                        },
                        {
                            "id": "361029",
                            "code": "361029",
                            "name": "东乡县",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361029",
                            "fullNames": "江西省,抚州市,东乡县",
                            "children": []
                        },
                        {
                            "id": "361030",
                            "code": "361030",
                            "name": "广昌县",
                            "parentCode": "361000",
                            "fullCodes": "360000,361000,361030",
                            "fullNames": "江西省,抚州市,广昌县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "361100",
                    "code": "361100",
                    "name": "上饶市",
                    "parentCode": "360000",
                    "fullCodes": "360000,361100",
                    "fullNames": "江西省,上饶市",
                    "children": [
                        {
                            "id": "361102",
                            "code": "361102",
                            "name": "信州区",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361102",
                            "fullNames": "江西省,上饶市,信州区",
                            "children": []
                        },
                        {
                            "id": "361121",
                            "code": "361121",
                            "name": "上饶县",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361121",
                            "fullNames": "江西省,上饶市,上饶县",
                            "children": []
                        },
                        {
                            "id": "361122",
                            "code": "361122",
                            "name": "广丰县",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361122",
                            "fullNames": "江西省,上饶市,广丰县",
                            "children": []
                        },
                        {
                            "id": "361123",
                            "code": "361123",
                            "name": "玉山县",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361123",
                            "fullNames": "江西省,上饶市,玉山县",
                            "children": []
                        },
                        {
                            "id": "361124",
                            "code": "361124",
                            "name": "铅山县",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361124",
                            "fullNames": "江西省,上饶市,铅山县",
                            "children": []
                        },
                        {
                            "id": "361125",
                            "code": "361125",
                            "name": "横峰县",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361125",
                            "fullNames": "江西省,上饶市,横峰县",
                            "children": []
                        },
                        {
                            "id": "361126",
                            "code": "361126",
                            "name": "弋阳县",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361126",
                            "fullNames": "江西省,上饶市,弋阳县",
                            "children": []
                        },
                        {
                            "id": "361127",
                            "code": "361127",
                            "name": "余干县",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361127",
                            "fullNames": "江西省,上饶市,余干县",
                            "children": []
                        },
                        {
                            "id": "361128",
                            "code": "361128",
                            "name": "鄱阳县",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361128",
                            "fullNames": "江西省,上饶市,鄱阳县",
                            "children": []
                        },
                        {
                            "id": "361129",
                            "code": "361129",
                            "name": "万年县",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361129",
                            "fullNames": "江西省,上饶市,万年县",
                            "children": []
                        },
                        {
                            "id": "361130",
                            "code": "361130",
                            "name": "婺源县",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361130",
                            "fullNames": "江西省,上饶市,婺源县",
                            "children": []
                        },
                        {
                            "id": "361181",
                            "code": "361181",
                            "name": "德兴市",
                            "parentCode": "361100",
                            "fullCodes": "360000,361100,361181",
                            "fullNames": "江西省,上饶市,德兴市",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "370000",
            "code": "370000",
            "name": "山东省",
            "parentCode": "-1",
            "fullCodes": "370000",
            "fullNames": "山东省",
            "children": [
                {
                    "id": "370100",
                    "code": "370100",
                    "name": "济南市",
                    "parentCode": "370000",
                    "fullCodes": "370000,370100",
                    "fullNames": "山东省,济南市",
                    "children": [
                        {
                            "id": "370102",
                            "code": "370102",
                            "name": "历下区",
                            "parentCode": "370100",
                            "fullCodes": "370000,370100,370102",
                            "fullNames": "山东省,济南市,历下区",
                            "children": []
                        },
                        {
                            "id": "370103",
                            "code": "370103",
                            "name": "市中区",
                            "parentCode": "370100",
                            "fullCodes": "370000,370100,370103",
                            "fullNames": "山东省,济南市,市中区",
                            "children": []
                        },
                        {
                            "id": "370104",
                            "code": "370104",
                            "name": "槐荫区",
                            "parentCode": "370100",
                            "fullCodes": "370000,370100,370104",
                            "fullNames": "山东省,济南市,槐荫区",
                            "children": []
                        },
                        {
                            "id": "370105",
                            "code": "370105",
                            "name": "天桥区",
                            "parentCode": "370100",
                            "fullCodes": "370000,370100,370105",
                            "fullNames": "山东省,济南市,天桥区",
                            "children": []
                        },
                        {
                            "id": "370112",
                            "code": "370112",
                            "name": "历城区",
                            "parentCode": "370100",
                            "fullCodes": "370000,370100,370112",
                            "fullNames": "山东省,济南市,历城区",
                            "children": []
                        },
                        {
                            "id": "370113",
                            "code": "370113",
                            "name": "长清区",
                            "parentCode": "370100",
                            "fullCodes": "370000,370100,370113",
                            "fullNames": "山东省,济南市,长清区",
                            "children": []
                        },
                        {
                            "id": "370124",
                            "code": "370124",
                            "name": "平阴县",
                            "parentCode": "370100",
                            "fullCodes": "370000,370100,370124",
                            "fullNames": "山东省,济南市,平阴县",
                            "children": []
                        },
                        {
                            "id": "370125",
                            "code": "370125",
                            "name": "济阳县",
                            "parentCode": "370100",
                            "fullCodes": "370000,370100,370125",
                            "fullNames": "山东省,济南市,济阳县",
                            "children": []
                        },
                        {
                            "id": "370126",
                            "code": "370126",
                            "name": "商河县",
                            "parentCode": "370100",
                            "fullCodes": "370000,370100,370126",
                            "fullNames": "山东省,济南市,商河县",
                            "children": []
                        },
                        {
                            "id": "370181",
                            "code": "370181",
                            "name": "章丘市",
                            "parentCode": "370100",
                            "fullCodes": "370000,370100,370181",
                            "fullNames": "山东省,济南市,章丘市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "370200",
                    "code": "370200",
                    "name": "青岛市",
                    "parentCode": "370000",
                    "fullCodes": "370000,370200",
                    "fullNames": "山东省,青岛市",
                    "children": [
                        {
                            "id": "370202",
                            "code": "370202",
                            "name": "市南区",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370202",
                            "fullNames": "山东省,青岛市,市南区",
                            "children": []
                        },
                        {
                            "id": "370203",
                            "code": "370203",
                            "name": "市北区",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370203",
                            "fullNames": "山东省,青岛市,市北区",
                            "children": []
                        },
                        {
                            "id": "370205",
                            "code": "370205",
                            "name": "四方区",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370205",
                            "fullNames": "山东省,青岛市,四方区",
                            "children": []
                        },
                        {
                            "id": "370211",
                            "code": "370211",
                            "name": "黄岛区",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370211",
                            "fullNames": "山东省,青岛市,黄岛区",
                            "children": []
                        },
                        {
                            "id": "370212",
                            "code": "370212",
                            "name": "崂山区",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370212",
                            "fullNames": "山东省,青岛市,崂山区",
                            "children": []
                        },
                        {
                            "id": "370213",
                            "code": "370213",
                            "name": "李沧区",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370213",
                            "fullNames": "山东省,青岛市,李沧区",
                            "children": []
                        },
                        {
                            "id": "370214",
                            "code": "370214",
                            "name": "城阳区",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370214",
                            "fullNames": "山东省,青岛市,城阳区",
                            "children": []
                        },
                        {
                            "id": "370251",
                            "code": "370251",
                            "name": "开发区",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370251",
                            "fullNames": "山东省,青岛市,开发区",
                            "children": []
                        },
                        {
                            "id": "370281",
                            "code": "370281",
                            "name": "胶州市",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370281",
                            "fullNames": "山东省,青岛市,胶州市",
                            "children": []
                        },
                        {
                            "id": "370282",
                            "code": "370282",
                            "name": "即墨市",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370282",
                            "fullNames": "山东省,青岛市,即墨市",
                            "children": []
                        },
                        {
                            "id": "370283",
                            "code": "370283",
                            "name": "平度市",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370283",
                            "fullNames": "山东省,青岛市,平度市",
                            "children": []
                        },
                        {
                            "id": "370284",
                            "code": "370284",
                            "name": "胶南市",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370284",
                            "fullNames": "山东省,青岛市,胶南市",
                            "children": []
                        },
                        {
                            "id": "370285",
                            "code": "370285",
                            "name": "莱西市",
                            "parentCode": "370200",
                            "fullCodes": "370000,370200,370285",
                            "fullNames": "山东省,青岛市,莱西市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "370300",
                    "code": "370300",
                    "name": "淄博市",
                    "parentCode": "370000",
                    "fullCodes": "370000,370300",
                    "fullNames": "山东省,淄博市",
                    "children": [
                        {
                            "id": "370302",
                            "code": "370302",
                            "name": "淄川区",
                            "parentCode": "370300",
                            "fullCodes": "370000,370300,370302",
                            "fullNames": "山东省,淄博市,淄川区",
                            "children": []
                        },
                        {
                            "id": "370303",
                            "code": "370303",
                            "name": "张店区",
                            "parentCode": "370300",
                            "fullCodes": "370000,370300,370303",
                            "fullNames": "山东省,淄博市,张店区",
                            "children": []
                        },
                        {
                            "id": "370304",
                            "code": "370304",
                            "name": "博山区",
                            "parentCode": "370300",
                            "fullCodes": "370000,370300,370304",
                            "fullNames": "山东省,淄博市,博山区",
                            "children": []
                        },
                        {
                            "id": "370305",
                            "code": "370305",
                            "name": "临淄区",
                            "parentCode": "370300",
                            "fullCodes": "370000,370300,370305",
                            "fullNames": "山东省,淄博市,临淄区",
                            "children": []
                        },
                        {
                            "id": "370306",
                            "code": "370306",
                            "name": "周村区",
                            "parentCode": "370300",
                            "fullCodes": "370000,370300,370306",
                            "fullNames": "山东省,淄博市,周村区",
                            "children": []
                        },
                        {
                            "id": "370321",
                            "code": "370321",
                            "name": "桓台县",
                            "parentCode": "370300",
                            "fullCodes": "370000,370300,370321",
                            "fullNames": "山东省,淄博市,桓台县",
                            "children": []
                        },
                        {
                            "id": "370322",
                            "code": "370322",
                            "name": "高青县",
                            "parentCode": "370300",
                            "fullCodes": "370000,370300,370322",
                            "fullNames": "山东省,淄博市,高青县",
                            "children": []
                        },
                        {
                            "id": "370323",
                            "code": "370323",
                            "name": "沂源县",
                            "parentCode": "370300",
                            "fullCodes": "370000,370300,370323",
                            "fullNames": "山东省,淄博市,沂源县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "370400",
                    "code": "370400",
                    "name": "枣庄市",
                    "parentCode": "370000",
                    "fullCodes": "370000,370400",
                    "fullNames": "山东省,枣庄市",
                    "children": [
                        {
                            "id": "370402",
                            "code": "370402",
                            "name": "市中区",
                            "parentCode": "370400",
                            "fullCodes": "370000,370400,370402",
                            "fullNames": "山东省,枣庄市,市中区",
                            "children": []
                        },
                        {
                            "id": "370403",
                            "code": "370403",
                            "name": "薛城区",
                            "parentCode": "370400",
                            "fullCodes": "370000,370400,370403",
                            "fullNames": "山东省,枣庄市,薛城区",
                            "children": []
                        },
                        {
                            "id": "370404",
                            "code": "370404",
                            "name": "峄城区",
                            "parentCode": "370400",
                            "fullCodes": "370000,370400,370404",
                            "fullNames": "山东省,枣庄市,峄城区",
                            "children": []
                        },
                        {
                            "id": "370405",
                            "code": "370405",
                            "name": "台儿庄区",
                            "parentCode": "370400",
                            "fullCodes": "370000,370400,370405",
                            "fullNames": "山东省,枣庄市,台儿庄区",
                            "children": []
                        },
                        {
                            "id": "370406",
                            "code": "370406",
                            "name": "山亭区",
                            "parentCode": "370400",
                            "fullCodes": "370000,370400,370406",
                            "fullNames": "山东省,枣庄市,山亭区",
                            "children": []
                        },
                        {
                            "id": "370481",
                            "code": "370481",
                            "name": "滕州市",
                            "parentCode": "370400",
                            "fullCodes": "370000,370400,370481",
                            "fullNames": "山东省,枣庄市,滕州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "370500",
                    "code": "370500",
                    "name": "东营市",
                    "parentCode": "370000",
                    "fullCodes": "370000,370500",
                    "fullNames": "山东省,东营市",
                    "children": [
                        {
                            "id": "370502",
                            "code": "370502",
                            "name": "东营区",
                            "parentCode": "370500",
                            "fullCodes": "370000,370500,370502",
                            "fullNames": "山东省,东营市,东营区",
                            "children": []
                        },
                        {
                            "id": "370503",
                            "code": "370503",
                            "name": "河口区",
                            "parentCode": "370500",
                            "fullCodes": "370000,370500,370503",
                            "fullNames": "山东省,东营市,河口区",
                            "children": []
                        },
                        {
                            "id": "370521",
                            "code": "370521",
                            "name": "垦利县",
                            "parentCode": "370500",
                            "fullCodes": "370000,370500,370521",
                            "fullNames": "山东省,东营市,垦利县",
                            "children": []
                        },
                        {
                            "id": "370522",
                            "code": "370522",
                            "name": "利津县",
                            "parentCode": "370500",
                            "fullCodes": "370000,370500,370522",
                            "fullNames": "山东省,东营市,利津县",
                            "children": []
                        },
                        {
                            "id": "370523",
                            "code": "370523",
                            "name": "广饶县",
                            "parentCode": "370500",
                            "fullCodes": "370000,370500,370523",
                            "fullNames": "山东省,东营市,广饶县",
                            "children": []
                        },
                        {
                            "id": "370589",
                            "code": "370589",
                            "name": "西城区",
                            "parentCode": "370500",
                            "fullCodes": "370000,370500,370589",
                            "fullNames": "山东省,东营市,西城区",
                            "children": []
                        },
                        {
                            "id": "370590",
                            "code": "370590",
                            "name": "东城区",
                            "parentCode": "370500",
                            "fullCodes": "370000,370500,370590",
                            "fullNames": "山东省,东营市,东城区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "370600",
                    "code": "370600",
                    "name": "烟台市",
                    "parentCode": "370000",
                    "fullCodes": "370000,370600",
                    "fullNames": "山东省,烟台市",
                    "children": [
                        {
                            "id": "370602",
                            "code": "370602",
                            "name": "芝罘区",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370602",
                            "fullNames": "山东省,烟台市,芝罘区",
                            "children": []
                        },
                        {
                            "id": "370611",
                            "code": "370611",
                            "name": "福山区",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370611",
                            "fullNames": "山东省,烟台市,福山区",
                            "children": []
                        },
                        {
                            "id": "370612",
                            "code": "370612",
                            "name": "牟平区",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370612",
                            "fullNames": "山东省,烟台市,牟平区",
                            "children": []
                        },
                        {
                            "id": "370613",
                            "code": "370613",
                            "name": "莱山区",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370613",
                            "fullNames": "山东省,烟台市,莱山区",
                            "children": []
                        },
                        {
                            "id": "370634",
                            "code": "370634",
                            "name": "长岛县",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370634",
                            "fullNames": "山东省,烟台市,长岛县",
                            "children": []
                        },
                        {
                            "id": "370681",
                            "code": "370681",
                            "name": "龙口市",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370681",
                            "fullNames": "山东省,烟台市,龙口市",
                            "children": []
                        },
                        {
                            "id": "370682",
                            "code": "370682",
                            "name": "莱阳市",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370682",
                            "fullNames": "山东省,烟台市,莱阳市",
                            "children": []
                        },
                        {
                            "id": "370683",
                            "code": "370683",
                            "name": "莱州市",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370683",
                            "fullNames": "山东省,烟台市,莱州市",
                            "children": []
                        },
                        {
                            "id": "370684",
                            "code": "370684",
                            "name": "蓬莱市",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370684",
                            "fullNames": "山东省,烟台市,蓬莱市",
                            "children": []
                        },
                        {
                            "id": "370685",
                            "code": "370685",
                            "name": "招远市",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370685",
                            "fullNames": "山东省,烟台市,招远市",
                            "children": []
                        },
                        {
                            "id": "370686",
                            "code": "370686",
                            "name": "栖霞市",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370686",
                            "fullNames": "山东省,烟台市,栖霞市",
                            "children": []
                        },
                        {
                            "id": "370687",
                            "code": "370687",
                            "name": "海阳市",
                            "parentCode": "370600",
                            "fullCodes": "370000,370600,370687",
                            "fullNames": "山东省,烟台市,海阳市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "370700",
                    "code": "370700",
                    "name": "潍坊市",
                    "parentCode": "370000",
                    "fullCodes": "370000,370700",
                    "fullNames": "山东省,潍坊市",
                    "children": [
                        {
                            "id": "370702",
                            "code": "370702",
                            "name": "潍城区",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370702",
                            "fullNames": "山东省,潍坊市,潍城区",
                            "children": []
                        },
                        {
                            "id": "370703",
                            "code": "370703",
                            "name": "寒亭区",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370703",
                            "fullNames": "山东省,潍坊市,寒亭区",
                            "children": []
                        },
                        {
                            "id": "370704",
                            "code": "370704",
                            "name": "坊子区",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370704",
                            "fullNames": "山东省,潍坊市,坊子区",
                            "children": []
                        },
                        {
                            "id": "370705",
                            "code": "370705",
                            "name": "奎文区",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370705",
                            "fullNames": "山东省,潍坊市,奎文区",
                            "children": []
                        },
                        {
                            "id": "370724",
                            "code": "370724",
                            "name": "临朐县",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370724",
                            "fullNames": "山东省,潍坊市,临朐县",
                            "children": []
                        },
                        {
                            "id": "370725",
                            "code": "370725",
                            "name": "昌乐县",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370725",
                            "fullNames": "山东省,潍坊市,昌乐县",
                            "children": []
                        },
                        {
                            "id": "370751",
                            "code": "370751",
                            "name": "开发区",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370751",
                            "fullNames": "山东省,潍坊市,开发区",
                            "children": []
                        },
                        {
                            "id": "370781",
                            "code": "370781",
                            "name": "青州市",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370781",
                            "fullNames": "山东省,潍坊市,青州市",
                            "children": []
                        },
                        {
                            "id": "370782",
                            "code": "370782",
                            "name": "诸城市",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370782",
                            "fullNames": "山东省,潍坊市,诸城市",
                            "children": []
                        },
                        {
                            "id": "370783",
                            "code": "370783",
                            "name": "寿光市",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370783",
                            "fullNames": "山东省,潍坊市,寿光市",
                            "children": []
                        },
                        {
                            "id": "370784",
                            "code": "370784",
                            "name": "安丘市",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370784",
                            "fullNames": "山东省,潍坊市,安丘市",
                            "children": []
                        },
                        {
                            "id": "370785",
                            "code": "370785",
                            "name": "高密市",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370785",
                            "fullNames": "山东省,潍坊市,高密市",
                            "children": []
                        },
                        {
                            "id": "370786",
                            "code": "370786",
                            "name": "昌邑市",
                            "parentCode": "370700",
                            "fullCodes": "370000,370700,370786",
                            "fullNames": "山东省,潍坊市,昌邑市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "370800",
                    "code": "370800",
                    "name": "济宁市",
                    "parentCode": "370000",
                    "fullCodes": "370000,370800",
                    "fullNames": "山东省,济宁市",
                    "children": [
                        {
                            "id": "370802",
                            "code": "370802",
                            "name": "市中区",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370802",
                            "fullNames": "山东省,济宁市,市中区",
                            "children": []
                        },
                        {
                            "id": "370811",
                            "code": "370811",
                            "name": "任城区",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370811",
                            "fullNames": "山东省,济宁市,任城区",
                            "children": []
                        },
                        {
                            "id": "370826",
                            "code": "370826",
                            "name": "微山县",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370826",
                            "fullNames": "山东省,济宁市,微山县",
                            "children": []
                        },
                        {
                            "id": "370827",
                            "code": "370827",
                            "name": "鱼台县",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370827",
                            "fullNames": "山东省,济宁市,鱼台县",
                            "children": []
                        },
                        {
                            "id": "370828",
                            "code": "370828",
                            "name": "金乡县",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370828",
                            "fullNames": "山东省,济宁市,金乡县",
                            "children": []
                        },
                        {
                            "id": "370829",
                            "code": "370829",
                            "name": "嘉祥县",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370829",
                            "fullNames": "山东省,济宁市,嘉祥县",
                            "children": []
                        },
                        {
                            "id": "370830",
                            "code": "370830",
                            "name": "汶上县",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370830",
                            "fullNames": "山东省,济宁市,汶上县",
                            "children": []
                        },
                        {
                            "id": "370831",
                            "code": "370831",
                            "name": "泗水县",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370831",
                            "fullNames": "山东省,济宁市,泗水县",
                            "children": []
                        },
                        {
                            "id": "370832",
                            "code": "370832",
                            "name": "梁山县",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370832",
                            "fullNames": "山东省,济宁市,梁山县",
                            "children": []
                        },
                        {
                            "id": "370881",
                            "code": "370881",
                            "name": "曲阜市",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370881",
                            "fullNames": "山东省,济宁市,曲阜市",
                            "children": []
                        },
                        {
                            "id": "370882",
                            "code": "370882",
                            "name": "兖州市",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370882",
                            "fullNames": "山东省,济宁市,兖州市",
                            "children": []
                        },
                        {
                            "id": "370883",
                            "code": "370883",
                            "name": "邹城市",
                            "parentCode": "370800",
                            "fullCodes": "370000,370800,370883",
                            "fullNames": "山东省,济宁市,邹城市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "370900",
                    "code": "370900",
                    "name": "泰安市",
                    "parentCode": "370000",
                    "fullCodes": "370000,370900",
                    "fullNames": "山东省,泰安市",
                    "children": [
                        {
                            "id": "370902",
                            "code": "370902",
                            "name": "泰山区",
                            "parentCode": "370900",
                            "fullCodes": "370000,370900,370902",
                            "fullNames": "山东省,泰安市,泰山区",
                            "children": []
                        },
                        {
                            "id": "370903",
                            "code": "370903",
                            "name": "岱岳区",
                            "parentCode": "370900",
                            "fullCodes": "370000,370900,370903",
                            "fullNames": "山东省,泰安市,岱岳区",
                            "children": []
                        },
                        {
                            "id": "370921",
                            "code": "370921",
                            "name": "宁阳县",
                            "parentCode": "370900",
                            "fullCodes": "370000,370900,370921",
                            "fullNames": "山东省,泰安市,宁阳县",
                            "children": []
                        },
                        {
                            "id": "370923",
                            "code": "370923",
                            "name": "东平县",
                            "parentCode": "370900",
                            "fullCodes": "370000,370900,370923",
                            "fullNames": "山东省,泰安市,东平县",
                            "children": []
                        },
                        {
                            "id": "370982",
                            "code": "370982",
                            "name": "新泰市",
                            "parentCode": "370900",
                            "fullCodes": "370000,370900,370982",
                            "fullNames": "山东省,泰安市,新泰市",
                            "children": []
                        },
                        {
                            "id": "370983",
                            "code": "370983",
                            "name": "肥城市",
                            "parentCode": "370900",
                            "fullCodes": "370000,370900,370983",
                            "fullNames": "山东省,泰安市,肥城市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "371000",
                    "code": "371000",
                    "name": "威海市",
                    "parentCode": "370000",
                    "fullCodes": "370000,371000",
                    "fullNames": "山东省,威海市",
                    "children": [
                        {
                            "id": "371002",
                            "code": "371002",
                            "name": "环翠区",
                            "parentCode": "371000",
                            "fullCodes": "370000,371000,371002",
                            "fullNames": "山东省,威海市,环翠区",
                            "children": []
                        },
                        {
                            "id": "371081",
                            "code": "371081",
                            "name": "文登市",
                            "parentCode": "371000",
                            "fullCodes": "370000,371000,371081",
                            "fullNames": "山东省,威海市,文登市",
                            "children": []
                        },
                        {
                            "id": "371082",
                            "code": "371082",
                            "name": "荣成市",
                            "parentCode": "371000",
                            "fullCodes": "370000,371000,371082",
                            "fullNames": "山东省,威海市,荣成市",
                            "children": []
                        },
                        {
                            "id": "371083",
                            "code": "371083",
                            "name": "乳山市",
                            "parentCode": "371000",
                            "fullCodes": "370000,371000,371083",
                            "fullNames": "山东省,威海市,乳山市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "371100",
                    "code": "371100",
                    "name": "日照市",
                    "parentCode": "370000",
                    "fullCodes": "370000,371100",
                    "fullNames": "山东省,日照市",
                    "children": [
                        {
                            "id": "371102",
                            "code": "371102",
                            "name": "东港区",
                            "parentCode": "371100",
                            "fullCodes": "370000,371100,371102",
                            "fullNames": "山东省,日照市,东港区",
                            "children": []
                        },
                        {
                            "id": "371103",
                            "code": "371103",
                            "name": "岚山区",
                            "parentCode": "371100",
                            "fullCodes": "370000,371100,371103",
                            "fullNames": "山东省,日照市,岚山区",
                            "children": []
                        },
                        {
                            "id": "371121",
                            "code": "371121",
                            "name": "五莲县",
                            "parentCode": "371100",
                            "fullCodes": "370000,371100,371121",
                            "fullNames": "山东省,日照市,五莲县",
                            "children": []
                        },
                        {
                            "id": "371122",
                            "code": "371122",
                            "name": "莒县",
                            "parentCode": "371100",
                            "fullCodes": "370000,371100,371122",
                            "fullNames": "山东省,日照市,莒县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "371200",
                    "code": "371200",
                    "name": "莱芜市",
                    "parentCode": "370000",
                    "fullCodes": "370000,371200",
                    "fullNames": "山东省,莱芜市",
                    "children": [
                        {
                            "id": "371202",
                            "code": "371202",
                            "name": "莱城区",
                            "parentCode": "371200",
                            "fullCodes": "370000,371200,371202",
                            "fullNames": "山东省,莱芜市,莱城区",
                            "children": []
                        },
                        {
                            "id": "371203",
                            "code": "371203",
                            "name": "钢城区",
                            "parentCode": "371200",
                            "fullCodes": "370000,371200,371203",
                            "fullNames": "山东省,莱芜市,钢城区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "371300",
                    "code": "371300",
                    "name": "临沂市",
                    "parentCode": "370000",
                    "fullCodes": "370000,371300",
                    "fullNames": "山东省,临沂市",
                    "children": [
                        {
                            "id": "371302",
                            "code": "371302",
                            "name": "兰山区",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371302",
                            "fullNames": "山东省,临沂市,兰山区",
                            "children": []
                        },
                        {
                            "id": "371311",
                            "code": "371311",
                            "name": "罗庄区",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371311",
                            "fullNames": "山东省,临沂市,罗庄区",
                            "children": []
                        },
                        {
                            "id": "371312",
                            "code": "371312",
                            "name": "河东区",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371312",
                            "fullNames": "山东省,临沂市,河东区",
                            "children": []
                        },
                        {
                            "id": "371321",
                            "code": "371321",
                            "name": "沂南县",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371321",
                            "fullNames": "山东省,临沂市,沂南县",
                            "children": []
                        },
                        {
                            "id": "371322",
                            "code": "371322",
                            "name": "郯城县",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371322",
                            "fullNames": "山东省,临沂市,郯城县",
                            "children": []
                        },
                        {
                            "id": "371323",
                            "code": "371323",
                            "name": "沂水县",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371323",
                            "fullNames": "山东省,临沂市,沂水县",
                            "children": []
                        },
                        {
                            "id": "371324",
                            "code": "371324",
                            "name": "苍山县",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371324",
                            "fullNames": "山东省,临沂市,苍山县",
                            "children": []
                        },
                        {
                            "id": "371325",
                            "code": "371325",
                            "name": "费县",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371325",
                            "fullNames": "山东省,临沂市,费县",
                            "children": []
                        },
                        {
                            "id": "371326",
                            "code": "371326",
                            "name": "平邑县",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371326",
                            "fullNames": "山东省,临沂市,平邑县",
                            "children": []
                        },
                        {
                            "id": "371327",
                            "code": "371327",
                            "name": "莒南县",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371327",
                            "fullNames": "山东省,临沂市,莒南县",
                            "children": []
                        },
                        {
                            "id": "371328",
                            "code": "371328",
                            "name": "蒙阴县",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371328",
                            "fullNames": "山东省,临沂市,蒙阴县",
                            "children": []
                        },
                        {
                            "id": "371329",
                            "code": "371329",
                            "name": "临沭县",
                            "parentCode": "371300",
                            "fullCodes": "370000,371300,371329",
                            "fullNames": "山东省,临沂市,临沭县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "371400",
                    "code": "371400",
                    "name": "德州市",
                    "parentCode": "370000",
                    "fullCodes": "370000,371400",
                    "fullNames": "山东省,德州市",
                    "children": [
                        {
                            "id": "371402",
                            "code": "371402",
                            "name": "德城区",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371402",
                            "fullNames": "山东省,德州市,德城区",
                            "children": []
                        },
                        {
                            "id": "371421",
                            "code": "371421",
                            "name": "陵县",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371421",
                            "fullNames": "山东省,德州市,陵县",
                            "children": []
                        },
                        {
                            "id": "371422",
                            "code": "371422",
                            "name": "宁津县",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371422",
                            "fullNames": "山东省,德州市,宁津县",
                            "children": []
                        },
                        {
                            "id": "371423",
                            "code": "371423",
                            "name": "庆云县",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371423",
                            "fullNames": "山东省,德州市,庆云县",
                            "children": []
                        },
                        {
                            "id": "371424",
                            "code": "371424",
                            "name": "临邑县",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371424",
                            "fullNames": "山东省,德州市,临邑县",
                            "children": []
                        },
                        {
                            "id": "371425",
                            "code": "371425",
                            "name": "齐河县",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371425",
                            "fullNames": "山东省,德州市,齐河县",
                            "children": []
                        },
                        {
                            "id": "371426",
                            "code": "371426",
                            "name": "平原县",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371426",
                            "fullNames": "山东省,德州市,平原县",
                            "children": []
                        },
                        {
                            "id": "371427",
                            "code": "371427",
                            "name": "夏津县",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371427",
                            "fullNames": "山东省,德州市,夏津县",
                            "children": []
                        },
                        {
                            "id": "371428",
                            "code": "371428",
                            "name": "武城县",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371428",
                            "fullNames": "山东省,德州市,武城县",
                            "children": []
                        },
                        {
                            "id": "371451",
                            "code": "371451",
                            "name": "开发区",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371451",
                            "fullNames": "山东省,德州市,开发区",
                            "children": []
                        },
                        {
                            "id": "371481",
                            "code": "371481",
                            "name": "乐陵市",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371481",
                            "fullNames": "山东省,德州市,乐陵市",
                            "children": []
                        },
                        {
                            "id": "371482",
                            "code": "371482",
                            "name": "禹城市",
                            "parentCode": "371400",
                            "fullCodes": "370000,371400,371482",
                            "fullNames": "山东省,德州市,禹城市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "371500",
                    "code": "371500",
                    "name": "聊城市",
                    "parentCode": "370000",
                    "fullCodes": "370000,371500",
                    "fullNames": "山东省,聊城市",
                    "children": [
                        {
                            "id": "371502",
                            "code": "371502",
                            "name": "东昌府区",
                            "parentCode": "371500",
                            "fullCodes": "370000,371500,371502",
                            "fullNames": "山东省,聊城市,东昌府区",
                            "children": []
                        },
                        {
                            "id": "371521",
                            "code": "371521",
                            "name": "阳谷县",
                            "parentCode": "371500",
                            "fullCodes": "370000,371500,371521",
                            "fullNames": "山东省,聊城市,阳谷县",
                            "children": []
                        },
                        {
                            "id": "371522",
                            "code": "371522",
                            "name": "莘县",
                            "parentCode": "371500",
                            "fullCodes": "370000,371500,371522",
                            "fullNames": "山东省,聊城市,莘县",
                            "children": []
                        },
                        {
                            "id": "371523",
                            "code": "371523",
                            "name": "茌平县",
                            "parentCode": "371500",
                            "fullCodes": "370000,371500,371523",
                            "fullNames": "山东省,聊城市,茌平县",
                            "children": []
                        },
                        {
                            "id": "371524",
                            "code": "371524",
                            "name": "东阿县",
                            "parentCode": "371500",
                            "fullCodes": "370000,371500,371524",
                            "fullNames": "山东省,聊城市,东阿县",
                            "children": []
                        },
                        {
                            "id": "371525",
                            "code": "371525",
                            "name": "冠县",
                            "parentCode": "371500",
                            "fullCodes": "370000,371500,371525",
                            "fullNames": "山东省,聊城市,冠县",
                            "children": []
                        },
                        {
                            "id": "371526",
                            "code": "371526",
                            "name": "高唐县",
                            "parentCode": "371500",
                            "fullCodes": "370000,371500,371526",
                            "fullNames": "山东省,聊城市,高唐县",
                            "children": []
                        },
                        {
                            "id": "371581",
                            "code": "371581",
                            "name": "临清市",
                            "parentCode": "371500",
                            "fullCodes": "370000,371500,371581",
                            "fullNames": "山东省,聊城市,临清市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "371600",
                    "code": "371600",
                    "name": "滨州市",
                    "parentCode": "370000",
                    "fullCodes": "370000,371600",
                    "fullNames": "山东省,滨州市",
                    "children": [
                        {
                            "id": "371602",
                            "code": "371602",
                            "name": "滨城区",
                            "parentCode": "371600",
                            "fullCodes": "370000,371600,371602",
                            "fullNames": "山东省,滨州市,滨城区",
                            "children": []
                        },
                        {
                            "id": "371621",
                            "code": "371621",
                            "name": "惠民县",
                            "parentCode": "371600",
                            "fullCodes": "370000,371600,371621",
                            "fullNames": "山东省,滨州市,惠民县",
                            "children": []
                        },
                        {
                            "id": "371622",
                            "code": "371622",
                            "name": "阳信县",
                            "parentCode": "371600",
                            "fullCodes": "370000,371600,371622",
                            "fullNames": "山东省,滨州市,阳信县",
                            "children": []
                        },
                        {
                            "id": "371623",
                            "code": "371623",
                            "name": "无棣县",
                            "parentCode": "371600",
                            "fullCodes": "370000,371600,371623",
                            "fullNames": "山东省,滨州市,无棣县",
                            "children": []
                        },
                        {
                            "id": "371624",
                            "code": "371624",
                            "name": "沾化县",
                            "parentCode": "371600",
                            "fullCodes": "370000,371600,371624",
                            "fullNames": "山东省,滨州市,沾化县",
                            "children": []
                        },
                        {
                            "id": "371625",
                            "code": "371625",
                            "name": "博兴县",
                            "parentCode": "371600",
                            "fullCodes": "370000,371600,371625",
                            "fullNames": "山东省,滨州市,博兴县",
                            "children": []
                        },
                        {
                            "id": "371626",
                            "code": "371626",
                            "name": "邹平县",
                            "parentCode": "371600",
                            "fullCodes": "370000,371600,371626",
                            "fullNames": "山东省,滨州市,邹平县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "371700",
                    "code": "371700",
                    "name": "菏泽市",
                    "parentCode": "370000",
                    "fullCodes": "370000,371700",
                    "fullNames": "山东省,菏泽市",
                    "children": [
                        {
                            "id": "371702",
                            "code": "371702",
                            "name": "牡丹区",
                            "parentCode": "371700",
                            "fullCodes": "370000,371700,371702",
                            "fullNames": "山东省,菏泽市,牡丹区",
                            "children": []
                        },
                        {
                            "id": "371721",
                            "code": "371721",
                            "name": "曹县",
                            "parentCode": "371700",
                            "fullCodes": "370000,371700,371721",
                            "fullNames": "山东省,菏泽市,曹县",
                            "children": []
                        },
                        {
                            "id": "371722",
                            "code": "371722",
                            "name": "单县",
                            "parentCode": "371700",
                            "fullCodes": "370000,371700,371722",
                            "fullNames": "山东省,菏泽市,单县",
                            "children": []
                        },
                        {
                            "id": "371723",
                            "code": "371723",
                            "name": "成武县",
                            "parentCode": "371700",
                            "fullCodes": "370000,371700,371723",
                            "fullNames": "山东省,菏泽市,成武县",
                            "children": []
                        },
                        {
                            "id": "371724",
                            "code": "371724",
                            "name": "巨野县",
                            "parentCode": "371700",
                            "fullCodes": "370000,371700,371724",
                            "fullNames": "山东省,菏泽市,巨野县",
                            "children": []
                        },
                        {
                            "id": "371725",
                            "code": "371725",
                            "name": "郓城县",
                            "parentCode": "371700",
                            "fullCodes": "370000,371700,371725",
                            "fullNames": "山东省,菏泽市,郓城县",
                            "children": []
                        },
                        {
                            "id": "371726",
                            "code": "371726",
                            "name": "鄄城县",
                            "parentCode": "371700",
                            "fullCodes": "370000,371700,371726",
                            "fullNames": "山东省,菏泽市,鄄城县",
                            "children": []
                        },
                        {
                            "id": "371727",
                            "code": "371727",
                            "name": "定陶县",
                            "parentCode": "371700",
                            "fullCodes": "370000,371700,371727",
                            "fullNames": "山东省,菏泽市,定陶县",
                            "children": []
                        },
                        {
                            "id": "371728",
                            "code": "371728",
                            "name": "东明县",
                            "parentCode": "371700",
                            "fullCodes": "370000,371700,371728",
                            "fullNames": "山东省,菏泽市,东明县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "410000",
            "code": "410000",
            "name": "河南省",
            "parentCode": "-1",
            "fullCodes": "410000",
            "fullNames": "河南省",
            "children": [
                {
                    "id": "410100",
                    "code": "410100",
                    "name": "郑州市",
                    "parentCode": "410000",
                    "fullCodes": "410000,410100",
                    "fullNames": "河南省,郑州市",
                    "children": [
                        {
                            "id": "410102",
                            "code": "410102",
                            "name": "中原区",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410102",
                            "fullNames": "河南省,郑州市,中原区",
                            "children": []
                        },
                        {
                            "id": "410103",
                            "code": "410103",
                            "name": "二七区",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410103",
                            "fullNames": "河南省,郑州市,二七区",
                            "children": []
                        },
                        {
                            "id": "410104",
                            "code": "410104",
                            "name": "管城回族区",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410104",
                            "fullNames": "河南省,郑州市,管城回族区",
                            "children": []
                        },
                        {
                            "id": "410105",
                            "code": "410105",
                            "name": "金水区",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410105",
                            "fullNames": "河南省,郑州市,金水区",
                            "children": []
                        },
                        {
                            "id": "410106",
                            "code": "410106",
                            "name": "上街区",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410106",
                            "fullNames": "河南省,郑州市,上街区",
                            "children": []
                        },
                        {
                            "id": "410108",
                            "code": "410108",
                            "name": "惠济区",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410108",
                            "fullNames": "河南省,郑州市,惠济区",
                            "children": []
                        },
                        {
                            "id": "410122",
                            "code": "410122",
                            "name": "中牟县",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410122",
                            "fullNames": "河南省,郑州市,中牟县",
                            "children": []
                        },
                        {
                            "id": "410181",
                            "code": "410181",
                            "name": "巩义市",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410181",
                            "fullNames": "河南省,郑州市,巩义市",
                            "children": []
                        },
                        {
                            "id": "410182",
                            "code": "410182",
                            "name": "荥阳市",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410182",
                            "fullNames": "河南省,郑州市,荥阳市",
                            "children": []
                        },
                        {
                            "id": "410183",
                            "code": "410183",
                            "name": "新密市",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410183",
                            "fullNames": "河南省,郑州市,新密市",
                            "children": []
                        },
                        {
                            "id": "410184",
                            "code": "410184",
                            "name": "新郑市",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410184",
                            "fullNames": "河南省,郑州市,新郑市",
                            "children": []
                        },
                        {
                            "id": "410185",
                            "code": "410185",
                            "name": "登封市",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410185",
                            "fullNames": "河南省,郑州市,登封市",
                            "children": []
                        },
                        {
                            "id": "410186",
                            "code": "410186",
                            "name": "郑东新区",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410186",
                            "fullNames": "河南省,郑州市,郑东新区",
                            "children": []
                        },
                        {
                            "id": "410187",
                            "code": "410187",
                            "name": "高新区",
                            "parentCode": "410100",
                            "fullCodes": "410000,410100,410187",
                            "fullNames": "河南省,郑州市,高新区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "410200",
                    "code": "410200",
                    "name": "开封市",
                    "parentCode": "410000",
                    "fullCodes": "410000,410200",
                    "fullNames": "河南省,开封市",
                    "children": [
                        {
                            "id": "410202",
                            "code": "410202",
                            "name": "龙亭区",
                            "parentCode": "410200",
                            "fullCodes": "410000,410200,410202",
                            "fullNames": "河南省,开封市,龙亭区",
                            "children": []
                        },
                        {
                            "id": "410203",
                            "code": "410203",
                            "name": "顺河回族区",
                            "parentCode": "410200",
                            "fullCodes": "410000,410200,410203",
                            "fullNames": "河南省,开封市,顺河回族区",
                            "children": []
                        },
                        {
                            "id": "410204",
                            "code": "410204",
                            "name": "鼓楼区",
                            "parentCode": "410200",
                            "fullCodes": "410000,410200,410204",
                            "fullNames": "河南省,开封市,鼓楼区",
                            "children": []
                        },
                        {
                            "id": "410205",
                            "code": "410205",
                            "name": "禹王台区",
                            "parentCode": "410200",
                            "fullCodes": "410000,410200,410205",
                            "fullNames": "河南省,开封市,禹王台区",
                            "children": []
                        },
                        {
                            "id": "410211",
                            "code": "410211",
                            "name": "金明区",
                            "parentCode": "410200",
                            "fullCodes": "410000,410200,410211",
                            "fullNames": "河南省,开封市,金明区",
                            "children": []
                        },
                        {
                            "id": "410221",
                            "code": "410221",
                            "name": "杞县",
                            "parentCode": "410200",
                            "fullCodes": "410000,410200,410221",
                            "fullNames": "河南省,开封市,杞县",
                            "children": []
                        },
                        {
                            "id": "410222",
                            "code": "410222",
                            "name": "通许县",
                            "parentCode": "410200",
                            "fullCodes": "410000,410200,410222",
                            "fullNames": "河南省,开封市,通许县",
                            "children": []
                        },
                        {
                            "id": "410223",
                            "code": "410223",
                            "name": "尉氏县",
                            "parentCode": "410200",
                            "fullCodes": "410000,410200,410223",
                            "fullNames": "河南省,开封市,尉氏县",
                            "children": []
                        },
                        {
                            "id": "410224",
                            "code": "410224",
                            "name": "开封县",
                            "parentCode": "410200",
                            "fullCodes": "410000,410200,410224",
                            "fullNames": "河南省,开封市,开封县",
                            "children": []
                        },
                        {
                            "id": "410225",
                            "code": "410225",
                            "name": "兰考县",
                            "parentCode": "410200",
                            "fullCodes": "410000,410200,410225",
                            "fullNames": "河南省,开封市,兰考县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "410300",
                    "code": "410300",
                    "name": "洛阳市",
                    "parentCode": "410000",
                    "fullCodes": "410000,410300",
                    "fullNames": "河南省,洛阳市",
                    "children": [
                        {
                            "id": "410302",
                            "code": "410302",
                            "name": "老城区",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410302",
                            "fullNames": "河南省,洛阳市,老城区",
                            "children": []
                        },
                        {
                            "id": "410303",
                            "code": "410303",
                            "name": "西工区",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410303",
                            "fullNames": "河南省,洛阳市,西工区",
                            "children": []
                        },
                        {
                            "id": "410304",
                            "code": "410304",
                            "name": "廛河回族区",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410304",
                            "fullNames": "河南省,洛阳市,廛河回族区",
                            "children": []
                        },
                        {
                            "id": "410305",
                            "code": "410305",
                            "name": "涧西区",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410305",
                            "fullNames": "河南省,洛阳市,涧西区",
                            "children": []
                        },
                        {
                            "id": "410306",
                            "code": "410306",
                            "name": "吉利区",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410306",
                            "fullNames": "河南省,洛阳市,吉利区",
                            "children": []
                        },
                        {
                            "id": "410307",
                            "code": "410307",
                            "name": "洛龙区",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410307",
                            "fullNames": "河南省,洛阳市,洛龙区",
                            "children": []
                        },
                        {
                            "id": "410322",
                            "code": "410322",
                            "name": "孟津县",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410322",
                            "fullNames": "河南省,洛阳市,孟津县",
                            "children": []
                        },
                        {
                            "id": "410323",
                            "code": "410323",
                            "name": "新安县",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410323",
                            "fullNames": "河南省,洛阳市,新安县",
                            "children": []
                        },
                        {
                            "id": "410324",
                            "code": "410324",
                            "name": "栾川县",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410324",
                            "fullNames": "河南省,洛阳市,栾川县",
                            "children": []
                        },
                        {
                            "id": "410325",
                            "code": "410325",
                            "name": "嵩县",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410325",
                            "fullNames": "河南省,洛阳市,嵩县",
                            "children": []
                        },
                        {
                            "id": "410326",
                            "code": "410326",
                            "name": "汝阳县",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410326",
                            "fullNames": "河南省,洛阳市,汝阳县",
                            "children": []
                        },
                        {
                            "id": "410327",
                            "code": "410327",
                            "name": "宜阳县",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410327",
                            "fullNames": "河南省,洛阳市,宜阳县",
                            "children": []
                        },
                        {
                            "id": "410328",
                            "code": "410328",
                            "name": "洛宁县",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410328",
                            "fullNames": "河南省,洛阳市,洛宁县",
                            "children": []
                        },
                        {
                            "id": "410329",
                            "code": "410329",
                            "name": "伊川县",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410329",
                            "fullNames": "河南省,洛阳市,伊川县",
                            "children": []
                        },
                        {
                            "id": "410381",
                            "code": "410381",
                            "name": "偃师市",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,410381",
                            "fullNames": "河南省,洛阳市,偃师市",
                            "children": []
                        },
                        {
                            "id": "471004",
                            "code": "471004",
                            "name": "高新区",
                            "parentCode": "410300",
                            "fullCodes": "410000,410300,471004",
                            "fullNames": "河南省,洛阳市,高新区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "410400",
                    "code": "410400",
                    "name": "平顶山市",
                    "parentCode": "410000",
                    "fullCodes": "410000,410400",
                    "fullNames": "河南省,平顶山市",
                    "children": [
                        {
                            "id": "410402",
                            "code": "410402",
                            "name": "新华区",
                            "parentCode": "410400",
                            "fullCodes": "410000,410400,410402",
                            "fullNames": "河南省,平顶山市,新华区",
                            "children": []
                        },
                        {
                            "id": "410403",
                            "code": "410403",
                            "name": "卫东区",
                            "parentCode": "410400",
                            "fullCodes": "410000,410400,410403",
                            "fullNames": "河南省,平顶山市,卫东区",
                            "children": []
                        },
                        {
                            "id": "410404",
                            "code": "410404",
                            "name": "石龙区",
                            "parentCode": "410400",
                            "fullCodes": "410000,410400,410404",
                            "fullNames": "河南省,平顶山市,石龙区",
                            "children": []
                        },
                        {
                            "id": "410411",
                            "code": "410411",
                            "name": "湛河区",
                            "parentCode": "410400",
                            "fullCodes": "410000,410400,410411",
                            "fullNames": "河南省,平顶山市,湛河区",
                            "children": []
                        },
                        {
                            "id": "410421",
                            "code": "410421",
                            "name": "宝丰县",
                            "parentCode": "410400",
                            "fullCodes": "410000,410400,410421",
                            "fullNames": "河南省,平顶山市,宝丰县",
                            "children": []
                        },
                        {
                            "id": "410422",
                            "code": "410422",
                            "name": "叶县",
                            "parentCode": "410400",
                            "fullCodes": "410000,410400,410422",
                            "fullNames": "河南省,平顶山市,叶县",
                            "children": []
                        },
                        {
                            "id": "410423",
                            "code": "410423",
                            "name": "鲁山县",
                            "parentCode": "410400",
                            "fullCodes": "410000,410400,410423",
                            "fullNames": "河南省,平顶山市,鲁山县",
                            "children": []
                        },
                        {
                            "id": "410425",
                            "code": "410425",
                            "name": "郏县",
                            "parentCode": "410400",
                            "fullCodes": "410000,410400,410425",
                            "fullNames": "河南省,平顶山市,郏县",
                            "children": []
                        },
                        {
                            "id": "410481",
                            "code": "410481",
                            "name": "舞钢市",
                            "parentCode": "410400",
                            "fullCodes": "410000,410400,410481",
                            "fullNames": "河南省,平顶山市,舞钢市",
                            "children": []
                        },
                        {
                            "id": "410482",
                            "code": "410482",
                            "name": "汝州市",
                            "parentCode": "410400",
                            "fullCodes": "410000,410400,410482",
                            "fullNames": "河南省,平顶山市,汝州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "410500",
                    "code": "410500",
                    "name": "安阳市",
                    "parentCode": "410000",
                    "fullCodes": "410000,410500",
                    "fullNames": "河南省,安阳市",
                    "children": [
                        {
                            "id": "410502",
                            "code": "410502",
                            "name": "文峰区",
                            "parentCode": "410500",
                            "fullCodes": "410000,410500,410502",
                            "fullNames": "河南省,安阳市,文峰区",
                            "children": []
                        },
                        {
                            "id": "410503",
                            "code": "410503",
                            "name": "北关区",
                            "parentCode": "410500",
                            "fullCodes": "410000,410500,410503",
                            "fullNames": "河南省,安阳市,北关区",
                            "children": []
                        },
                        {
                            "id": "410505",
                            "code": "410505",
                            "name": "殷都区",
                            "parentCode": "410500",
                            "fullCodes": "410000,410500,410505",
                            "fullNames": "河南省,安阳市,殷都区",
                            "children": []
                        },
                        {
                            "id": "410506",
                            "code": "410506",
                            "name": "龙安区",
                            "parentCode": "410500",
                            "fullCodes": "410000,410500,410506",
                            "fullNames": "河南省,安阳市,龙安区",
                            "children": []
                        },
                        {
                            "id": "410522",
                            "code": "410522",
                            "name": "安阳县",
                            "parentCode": "410500",
                            "fullCodes": "410000,410500,410522",
                            "fullNames": "河南省,安阳市,安阳县",
                            "children": []
                        },
                        {
                            "id": "410523",
                            "code": "410523",
                            "name": "汤阴县",
                            "parentCode": "410500",
                            "fullCodes": "410000,410500,410523",
                            "fullNames": "河南省,安阳市,汤阴县",
                            "children": []
                        },
                        {
                            "id": "410526",
                            "code": "410526",
                            "name": "滑县",
                            "parentCode": "410500",
                            "fullCodes": "410000,410500,410526",
                            "fullNames": "河南省,安阳市,滑县",
                            "children": []
                        },
                        {
                            "id": "410527",
                            "code": "410527",
                            "name": "内黄县",
                            "parentCode": "410500",
                            "fullCodes": "410000,410500,410527",
                            "fullNames": "河南省,安阳市,内黄县",
                            "children": []
                        },
                        {
                            "id": "410581",
                            "code": "410581",
                            "name": "林州市",
                            "parentCode": "410500",
                            "fullCodes": "410000,410500,410581",
                            "fullNames": "河南省,安阳市,林州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "410600",
                    "code": "410600",
                    "name": "鹤壁市",
                    "parentCode": "410000",
                    "fullCodes": "410000,410600",
                    "fullNames": "河南省,鹤壁市",
                    "children": [
                        {
                            "id": "410602",
                            "code": "410602",
                            "name": "鹤山区",
                            "parentCode": "410600",
                            "fullCodes": "410000,410600,410602",
                            "fullNames": "河南省,鹤壁市,鹤山区",
                            "children": []
                        },
                        {
                            "id": "410603",
                            "code": "410603",
                            "name": "山城区",
                            "parentCode": "410600",
                            "fullCodes": "410000,410600,410603",
                            "fullNames": "河南省,鹤壁市,山城区",
                            "children": []
                        },
                        {
                            "id": "410611",
                            "code": "410611",
                            "name": "淇滨区",
                            "parentCode": "410600",
                            "fullCodes": "410000,410600,410611",
                            "fullNames": "河南省,鹤壁市,淇滨区",
                            "children": []
                        },
                        {
                            "id": "410621",
                            "code": "410621",
                            "name": "浚县",
                            "parentCode": "410600",
                            "fullCodes": "410000,410600,410621",
                            "fullNames": "河南省,鹤壁市,浚县",
                            "children": []
                        },
                        {
                            "id": "410622",
                            "code": "410622",
                            "name": "淇县",
                            "parentCode": "410600",
                            "fullCodes": "410000,410600,410622",
                            "fullNames": "河南省,鹤壁市,淇县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "410700",
                    "code": "410700",
                    "name": "新乡市",
                    "parentCode": "410000",
                    "fullCodes": "410000,410700",
                    "fullNames": "河南省,新乡市",
                    "children": [
                        {
                            "id": "410702",
                            "code": "410702",
                            "name": "红旗区",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410702",
                            "fullNames": "河南省,新乡市,红旗区",
                            "children": []
                        },
                        {
                            "id": "410703",
                            "code": "410703",
                            "name": "卫滨区",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410703",
                            "fullNames": "河南省,新乡市,卫滨区",
                            "children": []
                        },
                        {
                            "id": "410704",
                            "code": "410704",
                            "name": "凤泉区",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410704",
                            "fullNames": "河南省,新乡市,凤泉区",
                            "children": []
                        },
                        {
                            "id": "410711",
                            "code": "410711",
                            "name": "牧野区",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410711",
                            "fullNames": "河南省,新乡市,牧野区",
                            "children": []
                        },
                        {
                            "id": "410721",
                            "code": "410721",
                            "name": "新乡县",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410721",
                            "fullNames": "河南省,新乡市,新乡县",
                            "children": []
                        },
                        {
                            "id": "410724",
                            "code": "410724",
                            "name": "获嘉县",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410724",
                            "fullNames": "河南省,新乡市,获嘉县",
                            "children": []
                        },
                        {
                            "id": "410725",
                            "code": "410725",
                            "name": "原阳县",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410725",
                            "fullNames": "河南省,新乡市,原阳县",
                            "children": []
                        },
                        {
                            "id": "410726",
                            "code": "410726",
                            "name": "延津县",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410726",
                            "fullNames": "河南省,新乡市,延津县",
                            "children": []
                        },
                        {
                            "id": "410727",
                            "code": "410727",
                            "name": "封丘县",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410727",
                            "fullNames": "河南省,新乡市,封丘县",
                            "children": []
                        },
                        {
                            "id": "410728",
                            "code": "410728",
                            "name": "长垣县",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410728",
                            "fullNames": "河南省,新乡市,长垣县",
                            "children": []
                        },
                        {
                            "id": "410781",
                            "code": "410781",
                            "name": "卫辉市",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410781",
                            "fullNames": "河南省,新乡市,卫辉市",
                            "children": []
                        },
                        {
                            "id": "410782",
                            "code": "410782",
                            "name": "辉县市",
                            "parentCode": "410700",
                            "fullCodes": "410000,410700,410782",
                            "fullNames": "河南省,新乡市,辉县市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "410800",
                    "code": "410800",
                    "name": "焦作市",
                    "parentCode": "410000",
                    "fullCodes": "410000,410800",
                    "fullNames": "河南省,焦作市",
                    "children": [
                        {
                            "id": "410802",
                            "code": "410802",
                            "name": "解放区",
                            "parentCode": "410800",
                            "fullCodes": "410000,410800,410802",
                            "fullNames": "河南省,焦作市,解放区",
                            "children": []
                        },
                        {
                            "id": "410803",
                            "code": "410803",
                            "name": "中站区",
                            "parentCode": "410800",
                            "fullCodes": "410000,410800,410803",
                            "fullNames": "河南省,焦作市,中站区",
                            "children": []
                        },
                        {
                            "id": "410804",
                            "code": "410804",
                            "name": "马村区",
                            "parentCode": "410800",
                            "fullCodes": "410000,410800,410804",
                            "fullNames": "河南省,焦作市,马村区",
                            "children": []
                        },
                        {
                            "id": "410811",
                            "code": "410811",
                            "name": "山阳区",
                            "parentCode": "410800",
                            "fullCodes": "410000,410800,410811",
                            "fullNames": "河南省,焦作市,山阳区",
                            "children": []
                        },
                        {
                            "id": "410821",
                            "code": "410821",
                            "name": "修武县",
                            "parentCode": "410800",
                            "fullCodes": "410000,410800,410821",
                            "fullNames": "河南省,焦作市,修武县",
                            "children": []
                        },
                        {
                            "id": "410822",
                            "code": "410822",
                            "name": "博爱县",
                            "parentCode": "410800",
                            "fullCodes": "410000,410800,410822",
                            "fullNames": "河南省,焦作市,博爱县",
                            "children": []
                        },
                        {
                            "id": "410823",
                            "code": "410823",
                            "name": "武陟县",
                            "parentCode": "410800",
                            "fullCodes": "410000,410800,410823",
                            "fullNames": "河南省,焦作市,武陟县",
                            "children": []
                        },
                        {
                            "id": "410825",
                            "code": "410825",
                            "name": "温县",
                            "parentCode": "410800",
                            "fullCodes": "410000,410800,410825",
                            "fullNames": "河南省,焦作市,温县",
                            "children": []
                        },
                        {
                            "id": "410882",
                            "code": "410882",
                            "name": "沁阳市",
                            "parentCode": "410800",
                            "fullCodes": "410000,410800,410882",
                            "fullNames": "河南省,焦作市,沁阳市",
                            "children": []
                        },
                        {
                            "id": "410883",
                            "code": "410883",
                            "name": "孟州市",
                            "parentCode": "410800",
                            "fullCodes": "410000,410800,410883",
                            "fullNames": "河南省,焦作市,孟州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "410881",
                    "code": "410881",
                    "name": "济源市",
                    "parentCode": "410000",
                    "fullCodes": "410000,410881",
                    "fullNames": "河南省,济源市",
                    "children": []
                },
                {
                    "id": "410900",
                    "code": "410900",
                    "name": "濮阳市",
                    "parentCode": "410000",
                    "fullCodes": "410000,410900",
                    "fullNames": "河南省,濮阳市",
                    "children": [
                        {
                            "id": "410902",
                            "code": "410902",
                            "name": "华龙区",
                            "parentCode": "410900",
                            "fullCodes": "410000,410900,410902",
                            "fullNames": "河南省,濮阳市,华龙区",
                            "children": []
                        },
                        {
                            "id": "410922",
                            "code": "410922",
                            "name": "清丰县",
                            "parentCode": "410900",
                            "fullCodes": "410000,410900,410922",
                            "fullNames": "河南省,濮阳市,清丰县",
                            "children": []
                        },
                        {
                            "id": "410923",
                            "code": "410923",
                            "name": "南乐县",
                            "parentCode": "410900",
                            "fullCodes": "410000,410900,410923",
                            "fullNames": "河南省,濮阳市,南乐县",
                            "children": []
                        },
                        {
                            "id": "410926",
                            "code": "410926",
                            "name": "范县",
                            "parentCode": "410900",
                            "fullCodes": "410000,410900,410926",
                            "fullNames": "河南省,濮阳市,范县",
                            "children": []
                        },
                        {
                            "id": "410927",
                            "code": "410927",
                            "name": "台前县",
                            "parentCode": "410900",
                            "fullCodes": "410000,410900,410927",
                            "fullNames": "河南省,濮阳市,台前县",
                            "children": []
                        },
                        {
                            "id": "410928",
                            "code": "410928",
                            "name": "濮阳县",
                            "parentCode": "410900",
                            "fullCodes": "410000,410900,410928",
                            "fullNames": "河南省,濮阳市,濮阳县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "411000",
                    "code": "411000",
                    "name": "许昌市",
                    "parentCode": "410000",
                    "fullCodes": "410000,411000",
                    "fullNames": "河南省,许昌市",
                    "children": [
                        {
                            "id": "411002",
                            "code": "411002",
                            "name": "魏都区",
                            "parentCode": "411000",
                            "fullCodes": "410000,411000,411002",
                            "fullNames": "河南省,许昌市,魏都区",
                            "children": []
                        },
                        {
                            "id": "411023",
                            "code": "411023",
                            "name": "许昌县",
                            "parentCode": "411000",
                            "fullCodes": "410000,411000,411023",
                            "fullNames": "河南省,许昌市,许昌县",
                            "children": []
                        },
                        {
                            "id": "411024",
                            "code": "411024",
                            "name": "鄢陵县",
                            "parentCode": "411000",
                            "fullCodes": "410000,411000,411024",
                            "fullNames": "河南省,许昌市,鄢陵县",
                            "children": []
                        },
                        {
                            "id": "411025",
                            "code": "411025",
                            "name": "襄城县",
                            "parentCode": "411000",
                            "fullCodes": "410000,411000,411025",
                            "fullNames": "河南省,许昌市,襄城县",
                            "children": []
                        },
                        {
                            "id": "411081",
                            "code": "411081",
                            "name": "禹州市",
                            "parentCode": "411000",
                            "fullCodes": "410000,411000,411081",
                            "fullNames": "河南省,许昌市,禹州市",
                            "children": []
                        },
                        {
                            "id": "411082",
                            "code": "411082",
                            "name": "长葛市",
                            "parentCode": "411000",
                            "fullCodes": "410000,411000,411082",
                            "fullNames": "河南省,许昌市,长葛市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "411100",
                    "code": "411100",
                    "name": "漯河市",
                    "parentCode": "410000",
                    "fullCodes": "410000,411100",
                    "fullNames": "河南省,漯河市",
                    "children": [
                        {
                            "id": "411102",
                            "code": "411102",
                            "name": "源汇区",
                            "parentCode": "411100",
                            "fullCodes": "410000,411100,411102",
                            "fullNames": "河南省,漯河市,源汇区",
                            "children": []
                        },
                        {
                            "id": "411103",
                            "code": "411103",
                            "name": "郾城区",
                            "parentCode": "411100",
                            "fullCodes": "410000,411100,411103",
                            "fullNames": "河南省,漯河市,郾城区",
                            "children": []
                        },
                        {
                            "id": "411104",
                            "code": "411104",
                            "name": "召陵区",
                            "parentCode": "411100",
                            "fullCodes": "410000,411100,411104",
                            "fullNames": "河南省,漯河市,召陵区",
                            "children": []
                        },
                        {
                            "id": "411121",
                            "code": "411121",
                            "name": "舞阳县",
                            "parentCode": "411100",
                            "fullCodes": "410000,411100,411121",
                            "fullNames": "河南省,漯河市,舞阳县",
                            "children": []
                        },
                        {
                            "id": "411122",
                            "code": "411122",
                            "name": "临颍县",
                            "parentCode": "411100",
                            "fullCodes": "410000,411100,411122",
                            "fullNames": "河南省,漯河市,临颍县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "411200",
                    "code": "411200",
                    "name": "三门峡市",
                    "parentCode": "410000",
                    "fullCodes": "410000,411200",
                    "fullNames": "河南省,三门峡市",
                    "children": [
                        {
                            "id": "411202",
                            "code": "411202",
                            "name": "湖滨区",
                            "parentCode": "411200",
                            "fullCodes": "410000,411200,411202",
                            "fullNames": "河南省,三门峡市,湖滨区",
                            "children": []
                        },
                        {
                            "id": "411221",
                            "code": "411221",
                            "name": "渑池县",
                            "parentCode": "411200",
                            "fullCodes": "410000,411200,411221",
                            "fullNames": "河南省,三门峡市,渑池县",
                            "children": []
                        },
                        {
                            "id": "411222",
                            "code": "411222",
                            "name": "陕县",
                            "parentCode": "411200",
                            "fullCodes": "410000,411200,411222",
                            "fullNames": "河南省,三门峡市,陕县",
                            "children": []
                        },
                        {
                            "id": "411224",
                            "code": "411224",
                            "name": "卢氏县",
                            "parentCode": "411200",
                            "fullCodes": "410000,411200,411224",
                            "fullNames": "河南省,三门峡市,卢氏县",
                            "children": []
                        },
                        {
                            "id": "411281",
                            "code": "411281",
                            "name": "义马市",
                            "parentCode": "411200",
                            "fullCodes": "410000,411200,411281",
                            "fullNames": "河南省,三门峡市,义马市",
                            "children": []
                        },
                        {
                            "id": "411282",
                            "code": "411282",
                            "name": "灵宝市",
                            "parentCode": "411200",
                            "fullCodes": "410000,411200,411282",
                            "fullNames": "河南省,三门峡市,灵宝市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "411300",
                    "code": "411300",
                    "name": "南阳市",
                    "parentCode": "410000",
                    "fullCodes": "410000,411300",
                    "fullNames": "河南省,南阳市",
                    "children": [
                        {
                            "id": "411302",
                            "code": "411302",
                            "name": "宛城区",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411302",
                            "fullNames": "河南省,南阳市,宛城区",
                            "children": []
                        },
                        {
                            "id": "411303",
                            "code": "411303",
                            "name": "卧龙区",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411303",
                            "fullNames": "河南省,南阳市,卧龙区",
                            "children": []
                        },
                        {
                            "id": "411321",
                            "code": "411321",
                            "name": "南召县",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411321",
                            "fullNames": "河南省,南阳市,南召县",
                            "children": []
                        },
                        {
                            "id": "411322",
                            "code": "411322",
                            "name": "方城县",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411322",
                            "fullNames": "河南省,南阳市,方城县",
                            "children": []
                        },
                        {
                            "id": "411323",
                            "code": "411323",
                            "name": "西峡县",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411323",
                            "fullNames": "河南省,南阳市,西峡县",
                            "children": []
                        },
                        {
                            "id": "411324",
                            "code": "411324",
                            "name": "镇平县",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411324",
                            "fullNames": "河南省,南阳市,镇平县",
                            "children": []
                        },
                        {
                            "id": "411325",
                            "code": "411325",
                            "name": "内乡县",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411325",
                            "fullNames": "河南省,南阳市,内乡县",
                            "children": []
                        },
                        {
                            "id": "411326",
                            "code": "411326",
                            "name": "淅川县",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411326",
                            "fullNames": "河南省,南阳市,淅川县",
                            "children": []
                        },
                        {
                            "id": "411327",
                            "code": "411327",
                            "name": "社旗县",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411327",
                            "fullNames": "河南省,南阳市,社旗县",
                            "children": []
                        },
                        {
                            "id": "411328",
                            "code": "411328",
                            "name": "唐河县",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411328",
                            "fullNames": "河南省,南阳市,唐河县",
                            "children": []
                        },
                        {
                            "id": "411329",
                            "code": "411329",
                            "name": "新野县",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411329",
                            "fullNames": "河南省,南阳市,新野县",
                            "children": []
                        },
                        {
                            "id": "411330",
                            "code": "411330",
                            "name": "桐柏县",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411330",
                            "fullNames": "河南省,南阳市,桐柏县",
                            "children": []
                        },
                        {
                            "id": "411381",
                            "code": "411381",
                            "name": "邓州市",
                            "parentCode": "411300",
                            "fullCodes": "410000,411300,411381",
                            "fullNames": "河南省,南阳市,邓州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "411400",
                    "code": "411400",
                    "name": "商丘市",
                    "parentCode": "410000",
                    "fullCodes": "410000,411400",
                    "fullNames": "河南省,商丘市",
                    "children": [
                        {
                            "id": "411402",
                            "code": "411402",
                            "name": "梁园区",
                            "parentCode": "411400",
                            "fullCodes": "410000,411400,411402",
                            "fullNames": "河南省,商丘市,梁园区",
                            "children": []
                        },
                        {
                            "id": "411403",
                            "code": "411403",
                            "name": "睢阳区",
                            "parentCode": "411400",
                            "fullCodes": "410000,411400,411403",
                            "fullNames": "河南省,商丘市,睢阳区",
                            "children": []
                        },
                        {
                            "id": "411421",
                            "code": "411421",
                            "name": "民权县",
                            "parentCode": "411400",
                            "fullCodes": "410000,411400,411421",
                            "fullNames": "河南省,商丘市,民权县",
                            "children": []
                        },
                        {
                            "id": "411422",
                            "code": "411422",
                            "name": "睢县",
                            "parentCode": "411400",
                            "fullCodes": "410000,411400,411422",
                            "fullNames": "河南省,商丘市,睢县",
                            "children": []
                        },
                        {
                            "id": "411423",
                            "code": "411423",
                            "name": "宁陵县",
                            "parentCode": "411400",
                            "fullCodes": "410000,411400,411423",
                            "fullNames": "河南省,商丘市,宁陵县",
                            "children": []
                        },
                        {
                            "id": "411424",
                            "code": "411424",
                            "name": "柘城县",
                            "parentCode": "411400",
                            "fullCodes": "410000,411400,411424",
                            "fullNames": "河南省,商丘市,柘城县",
                            "children": []
                        },
                        {
                            "id": "411425",
                            "code": "411425",
                            "name": "虞城县",
                            "parentCode": "411400",
                            "fullCodes": "410000,411400,411425",
                            "fullNames": "河南省,商丘市,虞城县",
                            "children": []
                        },
                        {
                            "id": "411426",
                            "code": "411426",
                            "name": "夏邑县",
                            "parentCode": "411400",
                            "fullCodes": "410000,411400,411426",
                            "fullNames": "河南省,商丘市,夏邑县",
                            "children": []
                        },
                        {
                            "id": "411481",
                            "code": "411481",
                            "name": "永城市",
                            "parentCode": "411400",
                            "fullCodes": "410000,411400,411481",
                            "fullNames": "河南省,商丘市,永城市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "411500",
                    "code": "411500",
                    "name": "信阳市",
                    "parentCode": "410000",
                    "fullCodes": "410000,411500",
                    "fullNames": "河南省,信阳市",
                    "children": [
                        {
                            "id": "411502",
                            "code": "411502",
                            "name": "浉河区",
                            "parentCode": "411500",
                            "fullCodes": "410000,411500,411502",
                            "fullNames": "河南省,信阳市,浉河区",
                            "children": []
                        },
                        {
                            "id": "411503",
                            "code": "411503",
                            "name": "平桥区",
                            "parentCode": "411500",
                            "fullCodes": "410000,411500,411503",
                            "fullNames": "河南省,信阳市,平桥区",
                            "children": []
                        },
                        {
                            "id": "411521",
                            "code": "411521",
                            "name": "罗山县",
                            "parentCode": "411500",
                            "fullCodes": "410000,411500,411521",
                            "fullNames": "河南省,信阳市,罗山县",
                            "children": []
                        },
                        {
                            "id": "411522",
                            "code": "411522",
                            "name": "光山县",
                            "parentCode": "411500",
                            "fullCodes": "410000,411500,411522",
                            "fullNames": "河南省,信阳市,光山县",
                            "children": []
                        },
                        {
                            "id": "411523",
                            "code": "411523",
                            "name": "新县",
                            "parentCode": "411500",
                            "fullCodes": "410000,411500,411523",
                            "fullNames": "河南省,信阳市,新县",
                            "children": []
                        },
                        {
                            "id": "411524",
                            "code": "411524",
                            "name": "商城县",
                            "parentCode": "411500",
                            "fullCodes": "410000,411500,411524",
                            "fullNames": "河南省,信阳市,商城县",
                            "children": []
                        },
                        {
                            "id": "411525",
                            "code": "411525",
                            "name": "固始县",
                            "parentCode": "411500",
                            "fullCodes": "410000,411500,411525",
                            "fullNames": "河南省,信阳市,固始县",
                            "children": []
                        },
                        {
                            "id": "411526",
                            "code": "411526",
                            "name": "潢川县",
                            "parentCode": "411500",
                            "fullCodes": "410000,411500,411526",
                            "fullNames": "河南省,信阳市,潢川县",
                            "children": []
                        },
                        {
                            "id": "411527",
                            "code": "411527",
                            "name": "淮滨县",
                            "parentCode": "411500",
                            "fullCodes": "410000,411500,411527",
                            "fullNames": "河南省,信阳市,淮滨县",
                            "children": []
                        },
                        {
                            "id": "411528",
                            "code": "411528",
                            "name": "息县",
                            "parentCode": "411500",
                            "fullCodes": "410000,411500,411528",
                            "fullNames": "河南省,信阳市,息县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "411600",
                    "code": "411600",
                    "name": "周口市",
                    "parentCode": "410000",
                    "fullCodes": "410000,411600",
                    "fullNames": "河南省,周口市",
                    "children": [
                        {
                            "id": "411602",
                            "code": "411602",
                            "name": "川汇区",
                            "parentCode": "411600",
                            "fullCodes": "410000,411600,411602",
                            "fullNames": "河南省,周口市,川汇区",
                            "children": []
                        },
                        {
                            "id": "411621",
                            "code": "411621",
                            "name": "扶沟县",
                            "parentCode": "411600",
                            "fullCodes": "410000,411600,411621",
                            "fullNames": "河南省,周口市,扶沟县",
                            "children": []
                        },
                        {
                            "id": "411622",
                            "code": "411622",
                            "name": "西华县",
                            "parentCode": "411600",
                            "fullCodes": "410000,411600,411622",
                            "fullNames": "河南省,周口市,西华县",
                            "children": []
                        },
                        {
                            "id": "411623",
                            "code": "411623",
                            "name": "商水县",
                            "parentCode": "411600",
                            "fullCodes": "410000,411600,411623",
                            "fullNames": "河南省,周口市,商水县",
                            "children": []
                        },
                        {
                            "id": "411624",
                            "code": "411624",
                            "name": "沈丘县",
                            "parentCode": "411600",
                            "fullCodes": "410000,411600,411624",
                            "fullNames": "河南省,周口市,沈丘县",
                            "children": []
                        },
                        {
                            "id": "411625",
                            "code": "411625",
                            "name": "郸城县",
                            "parentCode": "411600",
                            "fullCodes": "410000,411600,411625",
                            "fullNames": "河南省,周口市,郸城县",
                            "children": []
                        },
                        {
                            "id": "411626",
                            "code": "411626",
                            "name": "淮阳县",
                            "parentCode": "411600",
                            "fullCodes": "410000,411600,411626",
                            "fullNames": "河南省,周口市,淮阳县",
                            "children": []
                        },
                        {
                            "id": "411627",
                            "code": "411627",
                            "name": "太康县",
                            "parentCode": "411600",
                            "fullCodes": "410000,411600,411627",
                            "fullNames": "河南省,周口市,太康县",
                            "children": []
                        },
                        {
                            "id": "411628",
                            "code": "411628",
                            "name": "鹿邑县",
                            "parentCode": "411600",
                            "fullCodes": "410000,411600,411628",
                            "fullNames": "河南省,周口市,鹿邑县",
                            "children": []
                        },
                        {
                            "id": "411681",
                            "code": "411681",
                            "name": "项城市",
                            "parentCode": "411600",
                            "fullCodes": "410000,411600,411681",
                            "fullNames": "河南省,周口市,项城市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "411700",
                    "code": "411700",
                    "name": "驻马店市",
                    "parentCode": "410000",
                    "fullCodes": "410000,411700",
                    "fullNames": "河南省,驻马店市",
                    "children": [
                        {
                            "id": "411702",
                            "code": "411702",
                            "name": "驿城区",
                            "parentCode": "411700",
                            "fullCodes": "410000,411700,411702",
                            "fullNames": "河南省,驻马店市,驿城区",
                            "children": []
                        },
                        {
                            "id": "411721",
                            "code": "411721",
                            "name": "西平县",
                            "parentCode": "411700",
                            "fullCodes": "410000,411700,411721",
                            "fullNames": "河南省,驻马店市,西平县",
                            "children": []
                        },
                        {
                            "id": "411722",
                            "code": "411722",
                            "name": "上蔡县",
                            "parentCode": "411700",
                            "fullCodes": "410000,411700,411722",
                            "fullNames": "河南省,驻马店市,上蔡县",
                            "children": []
                        },
                        {
                            "id": "411723",
                            "code": "411723",
                            "name": "平舆县",
                            "parentCode": "411700",
                            "fullCodes": "410000,411700,411723",
                            "fullNames": "河南省,驻马店市,平舆县",
                            "children": []
                        },
                        {
                            "id": "411724",
                            "code": "411724",
                            "name": "正阳县",
                            "parentCode": "411700",
                            "fullCodes": "410000,411700,411724",
                            "fullNames": "河南省,驻马店市,正阳县",
                            "children": []
                        },
                        {
                            "id": "411725",
                            "code": "411725",
                            "name": "确山县",
                            "parentCode": "411700",
                            "fullCodes": "410000,411700,411725",
                            "fullNames": "河南省,驻马店市,确山县",
                            "children": []
                        },
                        {
                            "id": "411726",
                            "code": "411726",
                            "name": "泌阳县",
                            "parentCode": "411700",
                            "fullCodes": "410000,411700,411726",
                            "fullNames": "河南省,驻马店市,泌阳县",
                            "children": []
                        },
                        {
                            "id": "411727",
                            "code": "411727",
                            "name": "汝南县",
                            "parentCode": "411700",
                            "fullCodes": "410000,411700,411727",
                            "fullNames": "河南省,驻马店市,汝南县",
                            "children": []
                        },
                        {
                            "id": "411728",
                            "code": "411728",
                            "name": "遂平县",
                            "parentCode": "411700",
                            "fullCodes": "410000,411700,411728",
                            "fullNames": "河南省,驻马店市,遂平县",
                            "children": []
                        },
                        {
                            "id": "411729",
                            "code": "411729",
                            "name": "新蔡县",
                            "parentCode": "411700",
                            "fullCodes": "410000,411700,411729",
                            "fullNames": "河南省,驻马店市,新蔡县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "420000",
            "code": "420000",
            "name": "湖北省",
            "parentCode": "-1",
            "fullCodes": "420000",
            "fullNames": "湖北省",
            "children": [
                {
                    "id": "420100",
                    "code": "420100",
                    "name": "武汉市",
                    "parentCode": "420000",
                    "fullCodes": "420000,420100",
                    "fullNames": "湖北省,武汉市",
                    "children": [
                        {
                            "id": "420102",
                            "code": "420102",
                            "name": "江岸区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420102",
                            "fullNames": "湖北省,武汉市,江岸区",
                            "children": []
                        },
                        {
                            "id": "420103",
                            "code": "420103",
                            "name": "江汉区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420103",
                            "fullNames": "湖北省,武汉市,江汉区",
                            "children": []
                        },
                        {
                            "id": "420104",
                            "code": "420104",
                            "name": "硚口区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420104",
                            "fullNames": "湖北省,武汉市,硚口区",
                            "children": []
                        },
                        {
                            "id": "420105",
                            "code": "420105",
                            "name": "汉阳区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420105",
                            "fullNames": "湖北省,武汉市,汉阳区",
                            "children": []
                        },
                        {
                            "id": "420106",
                            "code": "420106",
                            "name": "武昌区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420106",
                            "fullNames": "湖北省,武汉市,武昌区",
                            "children": []
                        },
                        {
                            "id": "420107",
                            "code": "420107",
                            "name": "青山区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420107",
                            "fullNames": "湖北省,武汉市,青山区",
                            "children": []
                        },
                        {
                            "id": "420111",
                            "code": "420111",
                            "name": "洪山区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420111",
                            "fullNames": "湖北省,武汉市,洪山区",
                            "children": []
                        },
                        {
                            "id": "420112",
                            "code": "420112",
                            "name": "东西湖区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420112",
                            "fullNames": "湖北省,武汉市,东西湖区",
                            "children": []
                        },
                        {
                            "id": "420113",
                            "code": "420113",
                            "name": "汉南区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420113",
                            "fullNames": "湖北省,武汉市,汉南区",
                            "children": []
                        },
                        {
                            "id": "420114",
                            "code": "420114",
                            "name": "蔡甸区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420114",
                            "fullNames": "湖北省,武汉市,蔡甸区",
                            "children": []
                        },
                        {
                            "id": "420115",
                            "code": "420115",
                            "name": "江夏区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420115",
                            "fullNames": "湖北省,武汉市,江夏区",
                            "children": []
                        },
                        {
                            "id": "420116",
                            "code": "420116",
                            "name": "黄陂区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420116",
                            "fullNames": "湖北省,武汉市,黄陂区",
                            "children": []
                        },
                        {
                            "id": "420117",
                            "code": "420117",
                            "name": "新洲区",
                            "parentCode": "420100",
                            "fullCodes": "420000,420100,420117",
                            "fullNames": "湖北省,武汉市,新洲区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "420200",
                    "code": "420200",
                    "name": "黄石市",
                    "parentCode": "420000",
                    "fullCodes": "420000,420200",
                    "fullNames": "湖北省,黄石市",
                    "children": [
                        {
                            "id": "420202",
                            "code": "420202",
                            "name": "黄石港区",
                            "parentCode": "420200",
                            "fullCodes": "420000,420200,420202",
                            "fullNames": "湖北省,黄石市,黄石港区",
                            "children": []
                        },
                        {
                            "id": "420203",
                            "code": "420203",
                            "name": "西塞山区",
                            "parentCode": "420200",
                            "fullCodes": "420000,420200,420203",
                            "fullNames": "湖北省,黄石市,西塞山区",
                            "children": []
                        },
                        {
                            "id": "420204",
                            "code": "420204",
                            "name": "下陆区",
                            "parentCode": "420200",
                            "fullCodes": "420000,420200,420204",
                            "fullNames": "湖北省,黄石市,下陆区",
                            "children": []
                        },
                        {
                            "id": "420205",
                            "code": "420205",
                            "name": "铁山区",
                            "parentCode": "420200",
                            "fullCodes": "420000,420200,420205",
                            "fullNames": "湖北省,黄石市,铁山区",
                            "children": []
                        },
                        {
                            "id": "420222",
                            "code": "420222",
                            "name": "阳新县",
                            "parentCode": "420200",
                            "fullCodes": "420000,420200,420222",
                            "fullNames": "湖北省,黄石市,阳新县",
                            "children": []
                        },
                        {
                            "id": "420281",
                            "code": "420281",
                            "name": "大冶市",
                            "parentCode": "420200",
                            "fullCodes": "420000,420200,420281",
                            "fullNames": "湖北省,黄石市,大冶市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "420300",
                    "code": "420300",
                    "name": "十堰市",
                    "parentCode": "420000",
                    "fullCodes": "420000,420300",
                    "fullNames": "湖北省,十堰市",
                    "children": [
                        {
                            "id": "420302",
                            "code": "420302",
                            "name": "茅箭区",
                            "parentCode": "420300",
                            "fullCodes": "420000,420300,420302",
                            "fullNames": "湖北省,十堰市,茅箭区",
                            "children": []
                        },
                        {
                            "id": "420303",
                            "code": "420303",
                            "name": "张湾区",
                            "parentCode": "420300",
                            "fullCodes": "420000,420300,420303",
                            "fullNames": "湖北省,十堰市,张湾区",
                            "children": []
                        },
                        {
                            "id": "420321",
                            "code": "420321",
                            "name": "郧县",
                            "parentCode": "420300",
                            "fullCodes": "420000,420300,420321",
                            "fullNames": "湖北省,十堰市,郧县",
                            "children": []
                        },
                        {
                            "id": "420322",
                            "code": "420322",
                            "name": "郧西县",
                            "parentCode": "420300",
                            "fullCodes": "420000,420300,420322",
                            "fullNames": "湖北省,十堰市,郧西县",
                            "children": []
                        },
                        {
                            "id": "420323",
                            "code": "420323",
                            "name": "竹山县",
                            "parentCode": "420300",
                            "fullCodes": "420000,420300,420323",
                            "fullNames": "湖北省,十堰市,竹山县",
                            "children": []
                        },
                        {
                            "id": "420324",
                            "code": "420324",
                            "name": "竹溪县",
                            "parentCode": "420300",
                            "fullCodes": "420000,420300,420324",
                            "fullNames": "湖北省,十堰市,竹溪县",
                            "children": []
                        },
                        {
                            "id": "420325",
                            "code": "420325",
                            "name": "房县",
                            "parentCode": "420300",
                            "fullCodes": "420000,420300,420325",
                            "fullNames": "湖北省,十堰市,房县",
                            "children": []
                        },
                        {
                            "id": "420381",
                            "code": "420381",
                            "name": "丹江口市",
                            "parentCode": "420300",
                            "fullCodes": "420000,420300,420381",
                            "fullNames": "湖北省,十堰市,丹江口市",
                            "children": []
                        },
                        {
                            "id": "420382",
                            "code": "420382",
                            "name": "城区",
                            "parentCode": "420300",
                            "fullCodes": "420000,420300,420382",
                            "fullNames": "湖北省,十堰市,城区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "420500",
                    "code": "420500",
                    "name": "宜昌市",
                    "parentCode": "420000",
                    "fullCodes": "420000,420500",
                    "fullNames": "湖北省,宜昌市",
                    "children": [
                        {
                            "id": "420502",
                            "code": "420502",
                            "name": "西陵区",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420502",
                            "fullNames": "湖北省,宜昌市,西陵区",
                            "children": []
                        },
                        {
                            "id": "420503",
                            "code": "420503",
                            "name": "伍家岗区",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420503",
                            "fullNames": "湖北省,宜昌市,伍家岗区",
                            "children": []
                        },
                        {
                            "id": "420504",
                            "code": "420504",
                            "name": "点军区",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420504",
                            "fullNames": "湖北省,宜昌市,点军区",
                            "children": []
                        },
                        {
                            "id": "420505",
                            "code": "420505",
                            "name": "猇亭区",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420505",
                            "fullNames": "湖北省,宜昌市,猇亭区",
                            "children": []
                        },
                        {
                            "id": "420506",
                            "code": "420506",
                            "name": "夷陵区",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420506",
                            "fullNames": "湖北省,宜昌市,夷陵区",
                            "children": []
                        },
                        {
                            "id": "420525",
                            "code": "420525",
                            "name": "远安县",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420525",
                            "fullNames": "湖北省,宜昌市,远安县",
                            "children": []
                        },
                        {
                            "id": "420526",
                            "code": "420526",
                            "name": "兴山县",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420526",
                            "fullNames": "湖北省,宜昌市,兴山县",
                            "children": []
                        },
                        {
                            "id": "420527",
                            "code": "420527",
                            "name": "秭归县",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420527",
                            "fullNames": "湖北省,宜昌市,秭归县",
                            "children": []
                        },
                        {
                            "id": "420528",
                            "code": "420528",
                            "name": "长阳土家族自治县",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420528",
                            "fullNames": "湖北省,宜昌市,长阳土家族自治县",
                            "children": []
                        },
                        {
                            "id": "420529",
                            "code": "420529",
                            "name": "五峰土家族自治县",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420529",
                            "fullNames": "湖北省,宜昌市,五峰土家族自治县",
                            "children": []
                        },
                        {
                            "id": "420551",
                            "code": "420551",
                            "name": "葛洲坝区",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420551",
                            "fullNames": "湖北省,宜昌市,葛洲坝区",
                            "children": []
                        },
                        {
                            "id": "420552",
                            "code": "420552",
                            "name": "开发区",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420552",
                            "fullNames": "湖北省,宜昌市,开发区",
                            "children": []
                        },
                        {
                            "id": "420581",
                            "code": "420581",
                            "name": "宜都市",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420581",
                            "fullNames": "湖北省,宜昌市,宜都市",
                            "children": []
                        },
                        {
                            "id": "420582",
                            "code": "420582",
                            "name": "当阳市",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420582",
                            "fullNames": "湖北省,宜昌市,当阳市",
                            "children": []
                        },
                        {
                            "id": "420583",
                            "code": "420583",
                            "name": "枝江市",
                            "parentCode": "420500",
                            "fullCodes": "420000,420500,420583",
                            "fullNames": "湖北省,宜昌市,枝江市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "420600",
                    "code": "420600",
                    "name": "襄阳市",
                    "parentCode": "420000",
                    "fullCodes": "420000,420600",
                    "fullNames": "湖北省,襄阳市",
                    "children": [
                        {
                            "id": "420602",
                            "code": "420602",
                            "name": "襄城区",
                            "parentCode": "420600",
                            "fullCodes": "420000,420600,420602",
                            "fullNames": "湖北省,襄阳市,襄城区",
                            "children": []
                        },
                        {
                            "id": "420606",
                            "code": "420606",
                            "name": "樊城区",
                            "parentCode": "420600",
                            "fullCodes": "420000,420600,420606",
                            "fullNames": "湖北省,襄阳市,樊城区",
                            "children": []
                        },
                        {
                            "id": "420607",
                            "code": "420607",
                            "name": "襄州区",
                            "parentCode": "420600",
                            "fullCodes": "420000,420600,420607",
                            "fullNames": "湖北省,襄阳市,襄州区",
                            "children": []
                        },
                        {
                            "id": "420624",
                            "code": "420624",
                            "name": "南漳县",
                            "parentCode": "420600",
                            "fullCodes": "420000,420600,420624",
                            "fullNames": "湖北省,襄阳市,南漳县",
                            "children": []
                        },
                        {
                            "id": "420625",
                            "code": "420625",
                            "name": "谷城县",
                            "parentCode": "420600",
                            "fullCodes": "420000,420600,420625",
                            "fullNames": "湖北省,襄阳市,谷城县",
                            "children": []
                        },
                        {
                            "id": "420626",
                            "code": "420626",
                            "name": "保康县",
                            "parentCode": "420600",
                            "fullCodes": "420000,420600,420626",
                            "fullNames": "湖北省,襄阳市,保康县",
                            "children": []
                        },
                        {
                            "id": "420682",
                            "code": "420682",
                            "name": "老河口市",
                            "parentCode": "420600",
                            "fullCodes": "420000,420600,420682",
                            "fullNames": "湖北省,襄阳市,老河口市",
                            "children": []
                        },
                        {
                            "id": "420683",
                            "code": "420683",
                            "name": "枣阳市",
                            "parentCode": "420600",
                            "fullCodes": "420000,420600,420683",
                            "fullNames": "湖北省,襄阳市,枣阳市",
                            "children": []
                        },
                        {
                            "id": "420684",
                            "code": "420684",
                            "name": "宜城市",
                            "parentCode": "420600",
                            "fullCodes": "420000,420600,420684",
                            "fullNames": "湖北省,襄阳市,宜城市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "420700",
                    "code": "420700",
                    "name": "鄂州市",
                    "parentCode": "420000",
                    "fullCodes": "420000,420700",
                    "fullNames": "湖北省,鄂州市",
                    "children": [
                        {
                            "id": "420702",
                            "code": "420702",
                            "name": "梁子湖区",
                            "parentCode": "420700",
                            "fullCodes": "420000,420700,420702",
                            "fullNames": "湖北省,鄂州市,梁子湖区",
                            "children": []
                        },
                        {
                            "id": "420703",
                            "code": "420703",
                            "name": "华容区",
                            "parentCode": "420700",
                            "fullCodes": "420000,420700,420703",
                            "fullNames": "湖北省,鄂州市,华容区",
                            "children": []
                        },
                        {
                            "id": "420704",
                            "code": "420704",
                            "name": "鄂城区",
                            "parentCode": "420700",
                            "fullCodes": "420000,420700,420704",
                            "fullNames": "湖北省,鄂州市,鄂城区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "420800",
                    "code": "420800",
                    "name": "荆门市",
                    "parentCode": "420000",
                    "fullCodes": "420000,420800",
                    "fullNames": "湖北省,荆门市",
                    "children": [
                        {
                            "id": "420802",
                            "code": "420802",
                            "name": "东宝区",
                            "parentCode": "420800",
                            "fullCodes": "420000,420800,420802",
                            "fullNames": "湖北省,荆门市,东宝区",
                            "children": []
                        },
                        {
                            "id": "420804",
                            "code": "420804",
                            "name": "掇刀区",
                            "parentCode": "420800",
                            "fullCodes": "420000,420800,420804",
                            "fullNames": "湖北省,荆门市,掇刀区",
                            "children": []
                        },
                        {
                            "id": "420821",
                            "code": "420821",
                            "name": "京山县",
                            "parentCode": "420800",
                            "fullCodes": "420000,420800,420821",
                            "fullNames": "湖北省,荆门市,京山县",
                            "children": []
                        },
                        {
                            "id": "420822",
                            "code": "420822",
                            "name": "沙洋县",
                            "parentCode": "420800",
                            "fullCodes": "420000,420800,420822",
                            "fullNames": "湖北省,荆门市,沙洋县",
                            "children": []
                        },
                        {
                            "id": "420881",
                            "code": "420881",
                            "name": "钟祥市",
                            "parentCode": "420800",
                            "fullCodes": "420000,420800,420881",
                            "fullNames": "湖北省,荆门市,钟祥市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "420900",
                    "code": "420900",
                    "name": "孝感市",
                    "parentCode": "420000",
                    "fullCodes": "420000,420900",
                    "fullNames": "湖北省,孝感市",
                    "children": [
                        {
                            "id": "420902",
                            "code": "420902",
                            "name": "孝南区",
                            "parentCode": "420900",
                            "fullCodes": "420000,420900,420902",
                            "fullNames": "湖北省,孝感市,孝南区",
                            "children": []
                        },
                        {
                            "id": "420921",
                            "code": "420921",
                            "name": "孝昌县",
                            "parentCode": "420900",
                            "fullCodes": "420000,420900,420921",
                            "fullNames": "湖北省,孝感市,孝昌县",
                            "children": []
                        },
                        {
                            "id": "420922",
                            "code": "420922",
                            "name": "大悟县",
                            "parentCode": "420900",
                            "fullCodes": "420000,420900,420922",
                            "fullNames": "湖北省,孝感市,大悟县",
                            "children": []
                        },
                        {
                            "id": "420923",
                            "code": "420923",
                            "name": "云梦县",
                            "parentCode": "420900",
                            "fullCodes": "420000,420900,420923",
                            "fullNames": "湖北省,孝感市,云梦县",
                            "children": []
                        },
                        {
                            "id": "420981",
                            "code": "420981",
                            "name": "应城市",
                            "parentCode": "420900",
                            "fullCodes": "420000,420900,420981",
                            "fullNames": "湖北省,孝感市,应城市",
                            "children": []
                        },
                        {
                            "id": "420982",
                            "code": "420982",
                            "name": "安陆市",
                            "parentCode": "420900",
                            "fullCodes": "420000,420900,420982",
                            "fullNames": "湖北省,孝感市,安陆市",
                            "children": []
                        },
                        {
                            "id": "420984",
                            "code": "420984",
                            "name": "汉川市",
                            "parentCode": "420900",
                            "fullCodes": "420000,420900,420984",
                            "fullNames": "湖北省,孝感市,汉川市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "421000",
                    "code": "421000",
                    "name": "荆州市",
                    "parentCode": "420000",
                    "fullCodes": "420000,421000",
                    "fullNames": "湖北省,荆州市",
                    "children": [
                        {
                            "id": "421002",
                            "code": "421002",
                            "name": "沙市区",
                            "parentCode": "421000",
                            "fullCodes": "420000,421000,421002",
                            "fullNames": "湖北省,荆州市,沙市区",
                            "children": []
                        },
                        {
                            "id": "421003",
                            "code": "421003",
                            "name": "荆州区",
                            "parentCode": "421000",
                            "fullCodes": "420000,421000,421003",
                            "fullNames": "湖北省,荆州市,荆州区",
                            "children": []
                        },
                        {
                            "id": "421022",
                            "code": "421022",
                            "name": "公安县",
                            "parentCode": "421000",
                            "fullCodes": "420000,421000,421022",
                            "fullNames": "湖北省,荆州市,公安县",
                            "children": []
                        },
                        {
                            "id": "421023",
                            "code": "421023",
                            "name": "监利县",
                            "parentCode": "421000",
                            "fullCodes": "420000,421000,421023",
                            "fullNames": "湖北省,荆州市,监利县",
                            "children": []
                        },
                        {
                            "id": "421024",
                            "code": "421024",
                            "name": "江陵县",
                            "parentCode": "421000",
                            "fullCodes": "420000,421000,421024",
                            "fullNames": "湖北省,荆州市,江陵县",
                            "children": []
                        },
                        {
                            "id": "421081",
                            "code": "421081",
                            "name": "石首市",
                            "parentCode": "421000",
                            "fullCodes": "420000,421000,421081",
                            "fullNames": "湖北省,荆州市,石首市",
                            "children": []
                        },
                        {
                            "id": "421083",
                            "code": "421083",
                            "name": "洪湖市",
                            "parentCode": "421000",
                            "fullCodes": "420000,421000,421083",
                            "fullNames": "湖北省,荆州市,洪湖市",
                            "children": []
                        },
                        {
                            "id": "421087",
                            "code": "421087",
                            "name": "松滋市",
                            "parentCode": "421000",
                            "fullCodes": "420000,421000,421087",
                            "fullNames": "湖北省,荆州市,松滋市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "421100",
                    "code": "421100",
                    "name": "黄冈市",
                    "parentCode": "420000",
                    "fullCodes": "420000,421100",
                    "fullNames": "湖北省,黄冈市",
                    "children": [
                        {
                            "id": "421102",
                            "code": "421102",
                            "name": "黄州区",
                            "parentCode": "421100",
                            "fullCodes": "420000,421100,421102",
                            "fullNames": "湖北省,黄冈市,黄州区",
                            "children": []
                        },
                        {
                            "id": "421121",
                            "code": "421121",
                            "name": "团风县",
                            "parentCode": "421100",
                            "fullCodes": "420000,421100,421121",
                            "fullNames": "湖北省,黄冈市,团风县",
                            "children": []
                        },
                        {
                            "id": "421122",
                            "code": "421122",
                            "name": "红安县",
                            "parentCode": "421100",
                            "fullCodes": "420000,421100,421122",
                            "fullNames": "湖北省,黄冈市,红安县",
                            "children": []
                        },
                        {
                            "id": "421123",
                            "code": "421123",
                            "name": "罗田县",
                            "parentCode": "421100",
                            "fullCodes": "420000,421100,421123",
                            "fullNames": "湖北省,黄冈市,罗田县",
                            "children": []
                        },
                        {
                            "id": "421124",
                            "code": "421124",
                            "name": "英山县",
                            "parentCode": "421100",
                            "fullCodes": "420000,421100,421124",
                            "fullNames": "湖北省,黄冈市,英山县",
                            "children": []
                        },
                        {
                            "id": "421125",
                            "code": "421125",
                            "name": "浠水县",
                            "parentCode": "421100",
                            "fullCodes": "420000,421100,421125",
                            "fullNames": "湖北省,黄冈市,浠水县",
                            "children": []
                        },
                        {
                            "id": "421126",
                            "code": "421126",
                            "name": "蕲春县",
                            "parentCode": "421100",
                            "fullCodes": "420000,421100,421126",
                            "fullNames": "湖北省,黄冈市,蕲春县",
                            "children": []
                        },
                        {
                            "id": "421127",
                            "code": "421127",
                            "name": "黄梅县",
                            "parentCode": "421100",
                            "fullCodes": "420000,421100,421127",
                            "fullNames": "湖北省,黄冈市,黄梅县",
                            "children": []
                        },
                        {
                            "id": "421181",
                            "code": "421181",
                            "name": "麻城市",
                            "parentCode": "421100",
                            "fullCodes": "420000,421100,421181",
                            "fullNames": "湖北省,黄冈市,麻城市",
                            "children": []
                        },
                        {
                            "id": "421182",
                            "code": "421182",
                            "name": "武穴市",
                            "parentCode": "421100",
                            "fullCodes": "420000,421100,421182",
                            "fullNames": "湖北省,黄冈市,武穴市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "421200",
                    "code": "421200",
                    "name": "咸宁市",
                    "parentCode": "420000",
                    "fullCodes": "420000,421200",
                    "fullNames": "湖北省,咸宁市",
                    "children": [
                        {
                            "id": "421202",
                            "code": "421202",
                            "name": "咸安区",
                            "parentCode": "421200",
                            "fullCodes": "420000,421200,421202",
                            "fullNames": "湖北省,咸宁市,咸安区",
                            "children": []
                        },
                        {
                            "id": "421221",
                            "code": "421221",
                            "name": "嘉鱼县",
                            "parentCode": "421200",
                            "fullCodes": "420000,421200,421221",
                            "fullNames": "湖北省,咸宁市,嘉鱼县",
                            "children": []
                        },
                        {
                            "id": "421222",
                            "code": "421222",
                            "name": "通城县",
                            "parentCode": "421200",
                            "fullCodes": "420000,421200,421222",
                            "fullNames": "湖北省,咸宁市,通城县",
                            "children": []
                        },
                        {
                            "id": "421223",
                            "code": "421223",
                            "name": "崇阳县",
                            "parentCode": "421200",
                            "fullCodes": "420000,421200,421223",
                            "fullNames": "湖北省,咸宁市,崇阳县",
                            "children": []
                        },
                        {
                            "id": "421224",
                            "code": "421224",
                            "name": "通山县",
                            "parentCode": "421200",
                            "fullCodes": "420000,421200,421224",
                            "fullNames": "湖北省,咸宁市,通山县",
                            "children": []
                        },
                        {
                            "id": "421281",
                            "code": "421281",
                            "name": "赤壁市",
                            "parentCode": "421200",
                            "fullCodes": "420000,421200,421281",
                            "fullNames": "湖北省,咸宁市,赤壁市",
                            "children": []
                        },
                        {
                            "id": "421282",
                            "code": "421282",
                            "name": "温泉城区",
                            "parentCode": "421200",
                            "fullCodes": "420000,421200,421282",
                            "fullNames": "湖北省,咸宁市,温泉城区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "421300",
                    "code": "421300",
                    "name": "随州市",
                    "parentCode": "420000",
                    "fullCodes": "420000,421300",
                    "fullNames": "湖北省,随州市",
                    "children": [
                        {
                            "id": "421302",
                            "code": "421302",
                            "name": "曾都区",
                            "parentCode": "421300",
                            "fullCodes": "420000,421300,421302",
                            "fullNames": "湖北省,随州市,曾都区",
                            "children": []
                        },
                        {
                            "id": "421321",
                            "code": "421321",
                            "name": "随县",
                            "parentCode": "421300",
                            "fullCodes": "420000,421300,421321",
                            "fullNames": "湖北省,随州市,随县",
                            "children": []
                        },
                        {
                            "id": "421381",
                            "code": "421381",
                            "name": "广水市",
                            "parentCode": "421300",
                            "fullCodes": "420000,421300,421381",
                            "fullNames": "湖北省,随州市,广水市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "422800",
                    "code": "422800",
                    "name": "恩施土家族苗族自治州",
                    "parentCode": "420000",
                    "fullCodes": "420000,422800",
                    "fullNames": "湖北省,恩施土家族苗族自治州",
                    "children": [
                        {
                            "id": "422801",
                            "code": "422801",
                            "name": "恩施市",
                            "parentCode": "422800",
                            "fullCodes": "420000,422800,422801",
                            "fullNames": "湖北省,恩施土家族苗族自治州,恩施市",
                            "children": []
                        },
                        {
                            "id": "422802",
                            "code": "422802",
                            "name": "利川市",
                            "parentCode": "422800",
                            "fullCodes": "420000,422800,422802",
                            "fullNames": "湖北省,恩施土家族苗族自治州,利川市",
                            "children": []
                        },
                        {
                            "id": "422822",
                            "code": "422822",
                            "name": "建始县",
                            "parentCode": "422800",
                            "fullCodes": "420000,422800,422822",
                            "fullNames": "湖北省,恩施土家族苗族自治州,建始县",
                            "children": []
                        },
                        {
                            "id": "422823",
                            "code": "422823",
                            "name": "巴东县",
                            "parentCode": "422800",
                            "fullCodes": "420000,422800,422823",
                            "fullNames": "湖北省,恩施土家族苗族自治州,巴东县",
                            "children": []
                        },
                        {
                            "id": "422825",
                            "code": "422825",
                            "name": "宣恩县",
                            "parentCode": "422800",
                            "fullCodes": "420000,422800,422825",
                            "fullNames": "湖北省,恩施土家族苗族自治州,宣恩县",
                            "children": []
                        },
                        {
                            "id": "422826",
                            "code": "422826",
                            "name": "咸丰县",
                            "parentCode": "422800",
                            "fullCodes": "420000,422800,422826",
                            "fullNames": "湖北省,恩施土家族苗族自治州,咸丰县",
                            "children": []
                        },
                        {
                            "id": "422827",
                            "code": "422827",
                            "name": "来凤县",
                            "parentCode": "422800",
                            "fullCodes": "420000,422800,422827",
                            "fullNames": "湖北省,恩施土家族苗族自治州,来凤县",
                            "children": []
                        },
                        {
                            "id": "422828",
                            "code": "422828",
                            "name": "鹤峰县",
                            "parentCode": "422800",
                            "fullCodes": "420000,422800,422828",
                            "fullNames": "湖北省,恩施土家族苗族自治州,鹤峰县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "429004",
                    "code": "429004",
                    "name": "仙桃市",
                    "parentCode": "420000",
                    "fullCodes": "420000,429004",
                    "fullNames": "湖北省,仙桃市",
                    "children": []
                },
                {
                    "id": "429005",
                    "code": "429005",
                    "name": "潜江市",
                    "parentCode": "420000",
                    "fullCodes": "420000,429005",
                    "fullNames": "湖北省,潜江市",
                    "children": []
                },
                {
                    "id": "429006",
                    "code": "429006",
                    "name": "天门市",
                    "parentCode": "420000",
                    "fullCodes": "420000,429006",
                    "fullNames": "湖北省,天门市",
                    "children": []
                },
                {
                    "id": "429021",
                    "code": "429021",
                    "name": "神农架林区",
                    "parentCode": "420000",
                    "fullCodes": "420000,429021",
                    "fullNames": "湖北省,神农架林区",
                    "children": []
                }
            ]
        },
        {
            "id": "430000",
            "code": "430000",
            "name": "湖南省",
            "parentCode": "-1",
            "fullCodes": "430000",
            "fullNames": "湖南省",
            "children": [
                {
                    "id": "430100",
                    "code": "430100",
                    "name": "长沙市",
                    "parentCode": "430000",
                    "fullCodes": "430000,430100",
                    "fullNames": "湖南省,长沙市",
                    "children": [
                        {
                            "id": "430102",
                            "code": "430102",
                            "name": "芙蓉区",
                            "parentCode": "430100",
                            "fullCodes": "430000,430100,430102",
                            "fullNames": "湖南省,长沙市,芙蓉区",
                            "children": []
                        },
                        {
                            "id": "430103",
                            "code": "430103",
                            "name": "天心区",
                            "parentCode": "430100",
                            "fullCodes": "430000,430100,430103",
                            "fullNames": "湖南省,长沙市,天心区",
                            "children": []
                        },
                        {
                            "id": "430104",
                            "code": "430104",
                            "name": "岳麓区",
                            "parentCode": "430100",
                            "fullCodes": "430000,430100,430104",
                            "fullNames": "湖南省,长沙市,岳麓区",
                            "children": []
                        },
                        {
                            "id": "430105",
                            "code": "430105",
                            "name": "开福区",
                            "parentCode": "430100",
                            "fullCodes": "430000,430100,430105",
                            "fullNames": "湖南省,长沙市,开福区",
                            "children": []
                        },
                        {
                            "id": "430111",
                            "code": "430111",
                            "name": "雨花区",
                            "parentCode": "430100",
                            "fullCodes": "430000,430100,430111",
                            "fullNames": "湖南省,长沙市,雨花区",
                            "children": []
                        },
                        {
                            "id": "430121",
                            "code": "430121",
                            "name": "长沙县",
                            "parentCode": "430100",
                            "fullCodes": "430000,430100,430121",
                            "fullNames": "湖南省,长沙市,长沙县",
                            "children": []
                        },
                        {
                            "id": "430122",
                            "code": "430122",
                            "name": "望城县",
                            "parentCode": "430100",
                            "fullCodes": "430000,430100,430122",
                            "fullNames": "湖南省,长沙市,望城县",
                            "children": []
                        },
                        {
                            "id": "430124",
                            "code": "430124",
                            "name": "宁乡县",
                            "parentCode": "430100",
                            "fullCodes": "430000,430100,430124",
                            "fullNames": "湖南省,长沙市,宁乡县",
                            "children": []
                        },
                        {
                            "id": "430181",
                            "code": "430181",
                            "name": "浏阳市",
                            "parentCode": "430100",
                            "fullCodes": "430000,430100,430181",
                            "fullNames": "湖南省,长沙市,浏阳市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "430200",
                    "code": "430200",
                    "name": "株洲市",
                    "parentCode": "430000",
                    "fullCodes": "430000,430200",
                    "fullNames": "湖南省,株洲市",
                    "children": [
                        {
                            "id": "430202",
                            "code": "430202",
                            "name": "荷塘区",
                            "parentCode": "430200",
                            "fullCodes": "430000,430200,430202",
                            "fullNames": "湖南省,株洲市,荷塘区",
                            "children": []
                        },
                        {
                            "id": "430203",
                            "code": "430203",
                            "name": "芦淞区",
                            "parentCode": "430200",
                            "fullCodes": "430000,430200,430203",
                            "fullNames": "湖南省,株洲市,芦淞区",
                            "children": []
                        },
                        {
                            "id": "430204",
                            "code": "430204",
                            "name": "石峰区",
                            "parentCode": "430200",
                            "fullCodes": "430000,430200,430204",
                            "fullNames": "湖南省,株洲市,石峰区",
                            "children": []
                        },
                        {
                            "id": "430211",
                            "code": "430211",
                            "name": "天元区",
                            "parentCode": "430200",
                            "fullCodes": "430000,430200,430211",
                            "fullNames": "湖南省,株洲市,天元区",
                            "children": []
                        },
                        {
                            "id": "430221",
                            "code": "430221",
                            "name": "株洲县",
                            "parentCode": "430200",
                            "fullCodes": "430000,430200,430221",
                            "fullNames": "湖南省,株洲市,株洲县",
                            "children": []
                        },
                        {
                            "id": "430223",
                            "code": "430223",
                            "name": "攸县",
                            "parentCode": "430200",
                            "fullCodes": "430000,430200,430223",
                            "fullNames": "湖南省,株洲市,攸县",
                            "children": []
                        },
                        {
                            "id": "430224",
                            "code": "430224",
                            "name": "茶陵县",
                            "parentCode": "430200",
                            "fullCodes": "430000,430200,430224",
                            "fullNames": "湖南省,株洲市,茶陵县",
                            "children": []
                        },
                        {
                            "id": "430225",
                            "code": "430225",
                            "name": "炎陵县",
                            "parentCode": "430200",
                            "fullCodes": "430000,430200,430225",
                            "fullNames": "湖南省,株洲市,炎陵县",
                            "children": []
                        },
                        {
                            "id": "430281",
                            "code": "430281",
                            "name": "醴陵市",
                            "parentCode": "430200",
                            "fullCodes": "430000,430200,430281",
                            "fullNames": "湖南省,株洲市,醴陵市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "430300",
                    "code": "430300",
                    "name": "湘潭市",
                    "parentCode": "430000",
                    "fullCodes": "430000,430300",
                    "fullNames": "湖南省,湘潭市",
                    "children": [
                        {
                            "id": "430302",
                            "code": "430302",
                            "name": "雨湖区",
                            "parentCode": "430300",
                            "fullCodes": "430000,430300,430302",
                            "fullNames": "湖南省,湘潭市,雨湖区",
                            "children": []
                        },
                        {
                            "id": "430304",
                            "code": "430304",
                            "name": "岳塘区",
                            "parentCode": "430300",
                            "fullCodes": "430000,430300,430304",
                            "fullNames": "湖南省,湘潭市,岳塘区",
                            "children": []
                        },
                        {
                            "id": "430321",
                            "code": "430321",
                            "name": "湘潭县",
                            "parentCode": "430300",
                            "fullCodes": "430000,430300,430321",
                            "fullNames": "湖南省,湘潭市,湘潭县",
                            "children": []
                        },
                        {
                            "id": "430381",
                            "code": "430381",
                            "name": "湘乡市",
                            "parentCode": "430300",
                            "fullCodes": "430000,430300,430381",
                            "fullNames": "湖南省,湘潭市,湘乡市",
                            "children": []
                        },
                        {
                            "id": "430382",
                            "code": "430382",
                            "name": "韶山市",
                            "parentCode": "430300",
                            "fullCodes": "430000,430300,430382",
                            "fullNames": "湖南省,湘潭市,韶山市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "430400",
                    "code": "430400",
                    "name": "衡阳市",
                    "parentCode": "430000",
                    "fullCodes": "430000,430400",
                    "fullNames": "湖南省,衡阳市",
                    "children": [
                        {
                            "id": "430405",
                            "code": "430405",
                            "name": "珠晖区",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430405",
                            "fullNames": "湖南省,衡阳市,珠晖区",
                            "children": []
                        },
                        {
                            "id": "430406",
                            "code": "430406",
                            "name": "雁峰区",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430406",
                            "fullNames": "湖南省,衡阳市,雁峰区",
                            "children": []
                        },
                        {
                            "id": "430407",
                            "code": "430407",
                            "name": "石鼓区",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430407",
                            "fullNames": "湖南省,衡阳市,石鼓区",
                            "children": []
                        },
                        {
                            "id": "430408",
                            "code": "430408",
                            "name": "蒸湘区",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430408",
                            "fullNames": "湖南省,衡阳市,蒸湘区",
                            "children": []
                        },
                        {
                            "id": "430412",
                            "code": "430412",
                            "name": "南岳区",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430412",
                            "fullNames": "湖南省,衡阳市,南岳区",
                            "children": []
                        },
                        {
                            "id": "430421",
                            "code": "430421",
                            "name": "衡阳县",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430421",
                            "fullNames": "湖南省,衡阳市,衡阳县",
                            "children": []
                        },
                        {
                            "id": "430422",
                            "code": "430422",
                            "name": "衡南县",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430422",
                            "fullNames": "湖南省,衡阳市,衡南县",
                            "children": []
                        },
                        {
                            "id": "430423",
                            "code": "430423",
                            "name": "衡山县",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430423",
                            "fullNames": "湖南省,衡阳市,衡山县",
                            "children": []
                        },
                        {
                            "id": "430424",
                            "code": "430424",
                            "name": "衡东县",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430424",
                            "fullNames": "湖南省,衡阳市,衡东县",
                            "children": []
                        },
                        {
                            "id": "430426",
                            "code": "430426",
                            "name": "祁东县",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430426",
                            "fullNames": "湖南省,衡阳市,祁东县",
                            "children": []
                        },
                        {
                            "id": "430481",
                            "code": "430481",
                            "name": "耒阳市",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430481",
                            "fullNames": "湖南省,衡阳市,耒阳市",
                            "children": []
                        },
                        {
                            "id": "430482",
                            "code": "430482",
                            "name": "常宁市",
                            "parentCode": "430400",
                            "fullCodes": "430000,430400,430482",
                            "fullNames": "湖南省,衡阳市,常宁市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "430500",
                    "code": "430500",
                    "name": "邵阳市",
                    "parentCode": "430000",
                    "fullCodes": "430000,430500",
                    "fullNames": "湖南省,邵阳市",
                    "children": [
                        {
                            "id": "430502",
                            "code": "430502",
                            "name": "双清区",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430502",
                            "fullNames": "湖南省,邵阳市,双清区",
                            "children": []
                        },
                        {
                            "id": "430503",
                            "code": "430503",
                            "name": "大祥区",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430503",
                            "fullNames": "湖南省,邵阳市,大祥区",
                            "children": []
                        },
                        {
                            "id": "430511",
                            "code": "430511",
                            "name": "北塔区",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430511",
                            "fullNames": "湖南省,邵阳市,北塔区",
                            "children": []
                        },
                        {
                            "id": "430521",
                            "code": "430521",
                            "name": "邵东县",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430521",
                            "fullNames": "湖南省,邵阳市,邵东县",
                            "children": []
                        },
                        {
                            "id": "430522",
                            "code": "430522",
                            "name": "新邵县",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430522",
                            "fullNames": "湖南省,邵阳市,新邵县",
                            "children": []
                        },
                        {
                            "id": "430523",
                            "code": "430523",
                            "name": "邵阳县",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430523",
                            "fullNames": "湖南省,邵阳市,邵阳县",
                            "children": []
                        },
                        {
                            "id": "430524",
                            "code": "430524",
                            "name": "隆回县",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430524",
                            "fullNames": "湖南省,邵阳市,隆回县",
                            "children": []
                        },
                        {
                            "id": "430525",
                            "code": "430525",
                            "name": "洞口县",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430525",
                            "fullNames": "湖南省,邵阳市,洞口县",
                            "children": []
                        },
                        {
                            "id": "430527",
                            "code": "430527",
                            "name": "绥宁县",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430527",
                            "fullNames": "湖南省,邵阳市,绥宁县",
                            "children": []
                        },
                        {
                            "id": "430528",
                            "code": "430528",
                            "name": "新宁县",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430528",
                            "fullNames": "湖南省,邵阳市,新宁县",
                            "children": []
                        },
                        {
                            "id": "430529",
                            "code": "430529",
                            "name": "城步苗族自治县",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430529",
                            "fullNames": "湖南省,邵阳市,城步苗族自治县",
                            "children": []
                        },
                        {
                            "id": "430581",
                            "code": "430581",
                            "name": "武冈市",
                            "parentCode": "430500",
                            "fullCodes": "430000,430500,430581",
                            "fullNames": "湖南省,邵阳市,武冈市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "430600",
                    "code": "430600",
                    "name": "岳阳市",
                    "parentCode": "430000",
                    "fullCodes": "430000,430600",
                    "fullNames": "湖南省,岳阳市",
                    "children": [
                        {
                            "id": "430602",
                            "code": "430602",
                            "name": "岳阳楼区",
                            "parentCode": "430600",
                            "fullCodes": "430000,430600,430602",
                            "fullNames": "湖南省,岳阳市,岳阳楼区",
                            "children": []
                        },
                        {
                            "id": "430603",
                            "code": "430603",
                            "name": "云溪区",
                            "parentCode": "430600",
                            "fullCodes": "430000,430600,430603",
                            "fullNames": "湖南省,岳阳市,云溪区",
                            "children": []
                        },
                        {
                            "id": "430611",
                            "code": "430611",
                            "name": "君山区",
                            "parentCode": "430600",
                            "fullCodes": "430000,430600,430611",
                            "fullNames": "湖南省,岳阳市,君山区",
                            "children": []
                        },
                        {
                            "id": "430621",
                            "code": "430621",
                            "name": "岳阳县",
                            "parentCode": "430600",
                            "fullCodes": "430000,430600,430621",
                            "fullNames": "湖南省,岳阳市,岳阳县",
                            "children": []
                        },
                        {
                            "id": "430623",
                            "code": "430623",
                            "name": "华容县",
                            "parentCode": "430600",
                            "fullCodes": "430000,430600,430623",
                            "fullNames": "湖南省,岳阳市,华容县",
                            "children": []
                        },
                        {
                            "id": "430624",
                            "code": "430624",
                            "name": "湘阴县",
                            "parentCode": "430600",
                            "fullCodes": "430000,430600,430624",
                            "fullNames": "湖南省,岳阳市,湘阴县",
                            "children": []
                        },
                        {
                            "id": "430626",
                            "code": "430626",
                            "name": "平江县",
                            "parentCode": "430600",
                            "fullCodes": "430000,430600,430626",
                            "fullNames": "湖南省,岳阳市,平江县",
                            "children": []
                        },
                        {
                            "id": "430681",
                            "code": "430681",
                            "name": "汨罗市",
                            "parentCode": "430600",
                            "fullCodes": "430000,430600,430681",
                            "fullNames": "湖南省,岳阳市,汨罗市",
                            "children": []
                        },
                        {
                            "id": "430682",
                            "code": "430682",
                            "name": "临湘市",
                            "parentCode": "430600",
                            "fullCodes": "430000,430600,430682",
                            "fullNames": "湖南省,岳阳市,临湘市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "430700",
                    "code": "430700",
                    "name": "常德市",
                    "parentCode": "430000",
                    "fullCodes": "430000,430700",
                    "fullNames": "湖南省,常德市",
                    "children": [
                        {
                            "id": "430702",
                            "code": "430702",
                            "name": "武陵区",
                            "parentCode": "430700",
                            "fullCodes": "430000,430700,430702",
                            "fullNames": "湖南省,常德市,武陵区",
                            "children": []
                        },
                        {
                            "id": "430703",
                            "code": "430703",
                            "name": "鼎城区",
                            "parentCode": "430700",
                            "fullCodes": "430000,430700,430703",
                            "fullNames": "湖南省,常德市,鼎城区",
                            "children": []
                        },
                        {
                            "id": "430721",
                            "code": "430721",
                            "name": "安乡县",
                            "parentCode": "430700",
                            "fullCodes": "430000,430700,430721",
                            "fullNames": "湖南省,常德市,安乡县",
                            "children": []
                        },
                        {
                            "id": "430722",
                            "code": "430722",
                            "name": "汉寿县",
                            "parentCode": "430700",
                            "fullCodes": "430000,430700,430722",
                            "fullNames": "湖南省,常德市,汉寿县",
                            "children": []
                        },
                        {
                            "id": "430723",
                            "code": "430723",
                            "name": "澧县",
                            "parentCode": "430700",
                            "fullCodes": "430000,430700,430723",
                            "fullNames": "湖南省,常德市,澧县",
                            "children": []
                        },
                        {
                            "id": "430724",
                            "code": "430724",
                            "name": "临澧县",
                            "parentCode": "430700",
                            "fullCodes": "430000,430700,430724",
                            "fullNames": "湖南省,常德市,临澧县",
                            "children": []
                        },
                        {
                            "id": "430725",
                            "code": "430725",
                            "name": "桃源县",
                            "parentCode": "430700",
                            "fullCodes": "430000,430700,430725",
                            "fullNames": "湖南省,常德市,桃源县",
                            "children": []
                        },
                        {
                            "id": "430726",
                            "code": "430726",
                            "name": "石门县",
                            "parentCode": "430700",
                            "fullCodes": "430000,430700,430726",
                            "fullNames": "湖南省,常德市,石门县",
                            "children": []
                        },
                        {
                            "id": "430781",
                            "code": "430781",
                            "name": "津市市",
                            "parentCode": "430700",
                            "fullCodes": "430000,430700,430781",
                            "fullNames": "湖南省,常德市,津市市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "430800",
                    "code": "430800",
                    "name": "张家界市",
                    "parentCode": "430000",
                    "fullCodes": "430000,430800",
                    "fullNames": "湖南省,张家界市",
                    "children": [
                        {
                            "id": "430802",
                            "code": "430802",
                            "name": "永定区",
                            "parentCode": "430800",
                            "fullCodes": "430000,430800,430802",
                            "fullNames": "湖南省,张家界市,永定区",
                            "children": []
                        },
                        {
                            "id": "430811",
                            "code": "430811",
                            "name": "武陵源区",
                            "parentCode": "430800",
                            "fullCodes": "430000,430800,430811",
                            "fullNames": "湖南省,张家界市,武陵源区",
                            "children": []
                        },
                        {
                            "id": "430821",
                            "code": "430821",
                            "name": "慈利县",
                            "parentCode": "430800",
                            "fullCodes": "430000,430800,430821",
                            "fullNames": "湖南省,张家界市,慈利县",
                            "children": []
                        },
                        {
                            "id": "430822",
                            "code": "430822",
                            "name": "桑植县",
                            "parentCode": "430800",
                            "fullCodes": "430000,430800,430822",
                            "fullNames": "湖南省,张家界市,桑植县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "430900",
                    "code": "430900",
                    "name": "益阳市",
                    "parentCode": "430000",
                    "fullCodes": "430000,430900",
                    "fullNames": "湖南省,益阳市",
                    "children": [
                        {
                            "id": "430902",
                            "code": "430902",
                            "name": "资阳区",
                            "parentCode": "430900",
                            "fullCodes": "430000,430900,430902",
                            "fullNames": "湖南省,益阳市,资阳区",
                            "children": []
                        },
                        {
                            "id": "430903",
                            "code": "430903",
                            "name": "赫山区",
                            "parentCode": "430900",
                            "fullCodes": "430000,430900,430903",
                            "fullNames": "湖南省,益阳市,赫山区",
                            "children": []
                        },
                        {
                            "id": "430921",
                            "code": "430921",
                            "name": "南县",
                            "parentCode": "430900",
                            "fullCodes": "430000,430900,430921",
                            "fullNames": "湖南省,益阳市,南县",
                            "children": []
                        },
                        {
                            "id": "430922",
                            "code": "430922",
                            "name": "桃江县",
                            "parentCode": "430900",
                            "fullCodes": "430000,430900,430922",
                            "fullNames": "湖南省,益阳市,桃江县",
                            "children": []
                        },
                        {
                            "id": "430923",
                            "code": "430923",
                            "name": "安化县",
                            "parentCode": "430900",
                            "fullCodes": "430000,430900,430923",
                            "fullNames": "湖南省,益阳市,安化县",
                            "children": []
                        },
                        {
                            "id": "430981",
                            "code": "430981",
                            "name": "沅江市",
                            "parentCode": "430900",
                            "fullCodes": "430000,430900,430981",
                            "fullNames": "湖南省,益阳市,沅江市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "431000",
                    "code": "431000",
                    "name": "郴州市",
                    "parentCode": "430000",
                    "fullCodes": "430000,431000",
                    "fullNames": "湖南省,郴州市",
                    "children": [
                        {
                            "id": "431002",
                            "code": "431002",
                            "name": "北湖区",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431002",
                            "fullNames": "湖南省,郴州市,北湖区",
                            "children": []
                        },
                        {
                            "id": "431003",
                            "code": "431003",
                            "name": "苏仙区",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431003",
                            "fullNames": "湖南省,郴州市,苏仙区",
                            "children": []
                        },
                        {
                            "id": "431021",
                            "code": "431021",
                            "name": "桂阳县",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431021",
                            "fullNames": "湖南省,郴州市,桂阳县",
                            "children": []
                        },
                        {
                            "id": "431022",
                            "code": "431022",
                            "name": "宜章县",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431022",
                            "fullNames": "湖南省,郴州市,宜章县",
                            "children": []
                        },
                        {
                            "id": "431023",
                            "code": "431023",
                            "name": "永兴县",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431023",
                            "fullNames": "湖南省,郴州市,永兴县",
                            "children": []
                        },
                        {
                            "id": "431024",
                            "code": "431024",
                            "name": "嘉禾县",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431024",
                            "fullNames": "湖南省,郴州市,嘉禾县",
                            "children": []
                        },
                        {
                            "id": "431025",
                            "code": "431025",
                            "name": "临武县",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431025",
                            "fullNames": "湖南省,郴州市,临武县",
                            "children": []
                        },
                        {
                            "id": "431026",
                            "code": "431026",
                            "name": "汝城县",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431026",
                            "fullNames": "湖南省,郴州市,汝城县",
                            "children": []
                        },
                        {
                            "id": "431027",
                            "code": "431027",
                            "name": "桂东县",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431027",
                            "fullNames": "湖南省,郴州市,桂东县",
                            "children": []
                        },
                        {
                            "id": "431028",
                            "code": "431028",
                            "name": "安仁县",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431028",
                            "fullNames": "湖南省,郴州市,安仁县",
                            "children": []
                        },
                        {
                            "id": "431081",
                            "code": "431081",
                            "name": "资兴市",
                            "parentCode": "431000",
                            "fullCodes": "430000,431000,431081",
                            "fullNames": "湖南省,郴州市,资兴市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "431100",
                    "code": "431100",
                    "name": "永州市",
                    "parentCode": "430000",
                    "fullCodes": "430000,431100",
                    "fullNames": "湖南省,永州市",
                    "children": [
                        {
                            "id": "431102",
                            "code": "431102",
                            "name": "零陵区",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431102",
                            "fullNames": "湖南省,永州市,零陵区",
                            "children": []
                        },
                        {
                            "id": "431103",
                            "code": "431103",
                            "name": "冷水滩区",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431103",
                            "fullNames": "湖南省,永州市,冷水滩区",
                            "children": []
                        },
                        {
                            "id": "431121",
                            "code": "431121",
                            "name": "祁阳县",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431121",
                            "fullNames": "湖南省,永州市,祁阳县",
                            "children": []
                        },
                        {
                            "id": "431122",
                            "code": "431122",
                            "name": "东安县",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431122",
                            "fullNames": "湖南省,永州市,东安县",
                            "children": []
                        },
                        {
                            "id": "431123",
                            "code": "431123",
                            "name": "双牌县",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431123",
                            "fullNames": "湖南省,永州市,双牌县",
                            "children": []
                        },
                        {
                            "id": "431124",
                            "code": "431124",
                            "name": "道县",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431124",
                            "fullNames": "湖南省,永州市,道县",
                            "children": []
                        },
                        {
                            "id": "431125",
                            "code": "431125",
                            "name": "江永县",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431125",
                            "fullNames": "湖南省,永州市,江永县",
                            "children": []
                        },
                        {
                            "id": "431126",
                            "code": "431126",
                            "name": "宁远县",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431126",
                            "fullNames": "湖南省,永州市,宁远县",
                            "children": []
                        },
                        {
                            "id": "431127",
                            "code": "431127",
                            "name": "蓝山县",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431127",
                            "fullNames": "湖南省,永州市,蓝山县",
                            "children": []
                        },
                        {
                            "id": "431128",
                            "code": "431128",
                            "name": "新田县",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431128",
                            "fullNames": "湖南省,永州市,新田县",
                            "children": []
                        },
                        {
                            "id": "431129",
                            "code": "431129",
                            "name": "江华瑶族自治县",
                            "parentCode": "431100",
                            "fullCodes": "430000,431100,431129",
                            "fullNames": "湖南省,永州市,江华瑶族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "431200",
                    "code": "431200",
                    "name": "怀化市",
                    "parentCode": "430000",
                    "fullCodes": "430000,431200",
                    "fullNames": "湖南省,怀化市",
                    "children": [
                        {
                            "id": "431202",
                            "code": "431202",
                            "name": "鹤城区",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431202",
                            "fullNames": "湖南省,怀化市,鹤城区",
                            "children": []
                        },
                        {
                            "id": "431221",
                            "code": "431221",
                            "name": "中方县",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431221",
                            "fullNames": "湖南省,怀化市,中方县",
                            "children": []
                        },
                        {
                            "id": "431222",
                            "code": "431222",
                            "name": "沅陵县",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431222",
                            "fullNames": "湖南省,怀化市,沅陵县",
                            "children": []
                        },
                        {
                            "id": "431223",
                            "code": "431223",
                            "name": "辰溪县",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431223",
                            "fullNames": "湖南省,怀化市,辰溪县",
                            "children": []
                        },
                        {
                            "id": "431224",
                            "code": "431224",
                            "name": "溆浦县",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431224",
                            "fullNames": "湖南省,怀化市,溆浦县",
                            "children": []
                        },
                        {
                            "id": "431225",
                            "code": "431225",
                            "name": "会同县",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431225",
                            "fullNames": "湖南省,怀化市,会同县",
                            "children": []
                        },
                        {
                            "id": "431226",
                            "code": "431226",
                            "name": "麻阳苗族自治县",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431226",
                            "fullNames": "湖南省,怀化市,麻阳苗族自治县",
                            "children": []
                        },
                        {
                            "id": "431227",
                            "code": "431227",
                            "name": "新晃侗族自治县",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431227",
                            "fullNames": "湖南省,怀化市,新晃侗族自治县",
                            "children": []
                        },
                        {
                            "id": "431228",
                            "code": "431228",
                            "name": "芷江侗族自治县",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431228",
                            "fullNames": "湖南省,怀化市,芷江侗族自治县",
                            "children": []
                        },
                        {
                            "id": "431229",
                            "code": "431229",
                            "name": "靖州苗族侗族自治县",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431229",
                            "fullNames": "湖南省,怀化市,靖州苗族侗族自治县",
                            "children": []
                        },
                        {
                            "id": "431230",
                            "code": "431230",
                            "name": "通道侗族自治县",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431230",
                            "fullNames": "湖南省,怀化市,通道侗族自治县",
                            "children": []
                        },
                        {
                            "id": "431281",
                            "code": "431281",
                            "name": "洪江市",
                            "parentCode": "431200",
                            "fullCodes": "430000,431200,431281",
                            "fullNames": "湖南省,怀化市,洪江市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "431300",
                    "code": "431300",
                    "name": "娄底市",
                    "parentCode": "430000",
                    "fullCodes": "430000,431300",
                    "fullNames": "湖南省,娄底市",
                    "children": [
                        {
                            "id": "431302",
                            "code": "431302",
                            "name": "娄星区",
                            "parentCode": "431300",
                            "fullCodes": "430000,431300,431302",
                            "fullNames": "湖南省,娄底市,娄星区",
                            "children": []
                        },
                        {
                            "id": "431321",
                            "code": "431321",
                            "name": "双峰县",
                            "parentCode": "431300",
                            "fullCodes": "430000,431300,431321",
                            "fullNames": "湖南省,娄底市,双峰县",
                            "children": []
                        },
                        {
                            "id": "431322",
                            "code": "431322",
                            "name": "新化县",
                            "parentCode": "431300",
                            "fullCodes": "430000,431300,431322",
                            "fullNames": "湖南省,娄底市,新化县",
                            "children": []
                        },
                        {
                            "id": "431381",
                            "code": "431381",
                            "name": "冷水江市",
                            "parentCode": "431300",
                            "fullCodes": "430000,431300,431381",
                            "fullNames": "湖南省,娄底市,冷水江市",
                            "children": []
                        },
                        {
                            "id": "431382",
                            "code": "431382",
                            "name": "涟源市",
                            "parentCode": "431300",
                            "fullCodes": "430000,431300,431382",
                            "fullNames": "湖南省,娄底市,涟源市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "433100",
                    "code": "433100",
                    "name": "湘西土家族苗族自治州",
                    "parentCode": "430000",
                    "fullCodes": "430000,433100",
                    "fullNames": "湖南省,湘西土家族苗族自治州",
                    "children": [
                        {
                            "id": "433101",
                            "code": "433101",
                            "name": "吉首市",
                            "parentCode": "433100",
                            "fullCodes": "430000,433100,433101",
                            "fullNames": "湖南省,湘西土家族苗族自治州,吉首市",
                            "children": []
                        },
                        {
                            "id": "433122",
                            "code": "433122",
                            "name": "泸溪县",
                            "parentCode": "433100",
                            "fullCodes": "430000,433100,433122",
                            "fullNames": "湖南省,湘西土家族苗族自治州,泸溪县",
                            "children": []
                        },
                        {
                            "id": "433123",
                            "code": "433123",
                            "name": "凤凰县",
                            "parentCode": "433100",
                            "fullCodes": "430000,433100,433123",
                            "fullNames": "湖南省,湘西土家族苗族自治州,凤凰县",
                            "children": []
                        },
                        {
                            "id": "433124",
                            "code": "433124",
                            "name": "花垣县",
                            "parentCode": "433100",
                            "fullCodes": "430000,433100,433124",
                            "fullNames": "湖南省,湘西土家族苗族自治州,花垣县",
                            "children": []
                        },
                        {
                            "id": "433125",
                            "code": "433125",
                            "name": "保靖县",
                            "parentCode": "433100",
                            "fullCodes": "430000,433100,433125",
                            "fullNames": "湖南省,湘西土家族苗族自治州,保靖县",
                            "children": []
                        },
                        {
                            "id": "433126",
                            "code": "433126",
                            "name": "古丈县",
                            "parentCode": "433100",
                            "fullCodes": "430000,433100,433126",
                            "fullNames": "湖南省,湘西土家族苗族自治州,古丈县",
                            "children": []
                        },
                        {
                            "id": "433127",
                            "code": "433127",
                            "name": "永顺县",
                            "parentCode": "433100",
                            "fullCodes": "430000,433100,433127",
                            "fullNames": "湖南省,湘西土家族苗族自治州,永顺县",
                            "children": []
                        },
                        {
                            "id": "433130",
                            "code": "433130",
                            "name": "龙山县",
                            "parentCode": "433100",
                            "fullCodes": "430000,433100,433130",
                            "fullNames": "湖南省,湘西土家族苗族自治州,龙山县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "440000",
            "code": "440000",
            "name": "广东省",
            "parentCode": "-1",
            "fullCodes": "440000",
            "fullNames": "广东省",
            "children": [
                {
                    "id": "440100",
                    "code": "440100",
                    "name": "广州市",
                    "parentCode": "440000",
                    "fullCodes": "440000,440100",
                    "fullNames": "广东省,广州市",
                    "children": [
                        {
                            "id": "440103",
                            "code": "440103",
                            "name": "荔湾区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440103",
                            "fullNames": "广东省,广州市,荔湾区",
                            "children": []
                        },
                        {
                            "id": "440104",
                            "code": "440104",
                            "name": "越秀区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440104",
                            "fullNames": "广东省,广州市,越秀区",
                            "children": []
                        },
                        {
                            "id": "440105",
                            "code": "440105",
                            "name": "海珠区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440105",
                            "fullNames": "广东省,广州市,海珠区",
                            "children": []
                        },
                        {
                            "id": "440106",
                            "code": "440106",
                            "name": "天河区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440106",
                            "fullNames": "广东省,广州市,天河区",
                            "children": []
                        },
                        {
                            "id": "440111",
                            "code": "440111",
                            "name": "白云区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440111",
                            "fullNames": "广东省,广州市,白云区",
                            "children": []
                        },
                        {
                            "id": "440112",
                            "code": "440112",
                            "name": "黄埔区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440112",
                            "fullNames": "广东省,广州市,黄埔区",
                            "children": []
                        },
                        {
                            "id": "440113",
                            "code": "440113",
                            "name": "番禺区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440113",
                            "fullNames": "广东省,广州市,番禺区",
                            "children": []
                        },
                        {
                            "id": "440114",
                            "code": "440114",
                            "name": "花都区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440114",
                            "fullNames": "广东省,广州市,花都区",
                            "children": []
                        },
                        {
                            "id": "440115",
                            "code": "440115",
                            "name": "南沙区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440115",
                            "fullNames": "广东省,广州市,南沙区",
                            "children": []
                        },
                        {
                            "id": "440116",
                            "code": "440116",
                            "name": "萝岗区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440116",
                            "fullNames": "广东省,广州市,萝岗区",
                            "children": []
                        },
                        {
                            "id": "440183",
                            "code": "440183",
                            "name": "增城市",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440183",
                            "fullNames": "广东省,广州市,增城市",
                            "children": []
                        },
                        {
                            "id": "440184",
                            "code": "440184",
                            "name": "从化市",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440184",
                            "fullNames": "广东省,广州市,从化市",
                            "children": []
                        },
                        {
                            "id": "440188",
                            "code": "440188",
                            "name": "东山区",
                            "parentCode": "440100",
                            "fullCodes": "440000,440100,440188",
                            "fullNames": "广东省,广州市,东山区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "440200",
                    "code": "440200",
                    "name": "韶关市",
                    "parentCode": "440000",
                    "fullCodes": "440000,440200",
                    "fullNames": "广东省,韶关市",
                    "children": [
                        {
                            "id": "440203",
                            "code": "440203",
                            "name": "武江区",
                            "parentCode": "440200",
                            "fullCodes": "440000,440200,440203",
                            "fullNames": "广东省,韶关市,武江区",
                            "children": []
                        },
                        {
                            "id": "440204",
                            "code": "440204",
                            "name": "浈江区",
                            "parentCode": "440200",
                            "fullCodes": "440000,440200,440204",
                            "fullNames": "广东省,韶关市,浈江区",
                            "children": []
                        },
                        {
                            "id": "440205",
                            "code": "440205",
                            "name": "曲江区",
                            "parentCode": "440200",
                            "fullCodes": "440000,440200,440205",
                            "fullNames": "广东省,韶关市,曲江区",
                            "children": []
                        },
                        {
                            "id": "440222",
                            "code": "440222",
                            "name": "始兴县",
                            "parentCode": "440200",
                            "fullCodes": "440000,440200,440222",
                            "fullNames": "广东省,韶关市,始兴县",
                            "children": []
                        },
                        {
                            "id": "440224",
                            "code": "440224",
                            "name": "仁化县",
                            "parentCode": "440200",
                            "fullCodes": "440000,440200,440224",
                            "fullNames": "广东省,韶关市,仁化县",
                            "children": []
                        },
                        {
                            "id": "440229",
                            "code": "440229",
                            "name": "翁源县",
                            "parentCode": "440200",
                            "fullCodes": "440000,440200,440229",
                            "fullNames": "广东省,韶关市,翁源县",
                            "children": []
                        },
                        {
                            "id": "440232",
                            "code": "440232",
                            "name": "乳源瑶族自治县",
                            "parentCode": "440200",
                            "fullCodes": "440000,440200,440232",
                            "fullNames": "广东省,韶关市,乳源瑶族自治县",
                            "children": []
                        },
                        {
                            "id": "440233",
                            "code": "440233",
                            "name": "新丰县",
                            "parentCode": "440200",
                            "fullCodes": "440000,440200,440233",
                            "fullNames": "广东省,韶关市,新丰县",
                            "children": []
                        },
                        {
                            "id": "440281",
                            "code": "440281",
                            "name": "乐昌市",
                            "parentCode": "440200",
                            "fullCodes": "440000,440200,440281",
                            "fullNames": "广东省,韶关市,乐昌市",
                            "children": []
                        },
                        {
                            "id": "440282",
                            "code": "440282",
                            "name": "南雄市",
                            "parentCode": "440200",
                            "fullCodes": "440000,440200,440282",
                            "fullNames": "广东省,韶关市,南雄市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "440300",
                    "code": "440300",
                    "name": "深圳市",
                    "parentCode": "440000",
                    "fullCodes": "440000,440300",
                    "fullNames": "广东省,深圳市",
                    "children": [
                        {
                            "id": "440303",
                            "code": "440303",
                            "name": "罗湖区",
                            "parentCode": "440300",
                            "fullCodes": "440000,440300,440303",
                            "fullNames": "广东省,深圳市,罗湖区",
                            "children": []
                        },
                        {
                            "id": "440304",
                            "code": "440304",
                            "name": "福田区",
                            "parentCode": "440300",
                            "fullCodes": "440000,440300,440304",
                            "fullNames": "广东省,深圳市,福田区",
                            "children": []
                        },
                        {
                            "id": "440305",
                            "code": "440305",
                            "name": "南山区",
                            "parentCode": "440300",
                            "fullCodes": "440000,440300,440305",
                            "fullNames": "广东省,深圳市,南山区",
                            "children": []
                        },
                        {
                            "id": "440306",
                            "code": "440306",
                            "name": "宝安区",
                            "parentCode": "440300",
                            "fullCodes": "440000,440300,440306",
                            "fullNames": "广东省,深圳市,宝安区",
                            "children": []
                        },
                        {
                            "id": "440307",
                            "code": "440307",
                            "name": "龙岗区",
                            "parentCode": "440300",
                            "fullCodes": "440000,440300,440307",
                            "fullNames": "广东省,深圳市,龙岗区",
                            "children": []
                        },
                        {
                            "id": "440308",
                            "code": "440308",
                            "name": "盐田区",
                            "parentCode": "440300",
                            "fullCodes": "440000,440300,440308",
                            "fullNames": "广东省,深圳市,盐田区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "440400",
                    "code": "440400",
                    "name": "珠海市",
                    "parentCode": "440000",
                    "fullCodes": "440000,440400",
                    "fullNames": "广东省,珠海市",
                    "children": [
                        {
                            "id": "440402",
                            "code": "440402",
                            "name": "香洲区",
                            "parentCode": "440400",
                            "fullCodes": "440000,440400,440402",
                            "fullNames": "广东省,珠海市,香洲区",
                            "children": []
                        },
                        {
                            "id": "440403",
                            "code": "440403",
                            "name": "斗门区",
                            "parentCode": "440400",
                            "fullCodes": "440000,440400,440403",
                            "fullNames": "广东省,珠海市,斗门区",
                            "children": []
                        },
                        {
                            "id": "440404",
                            "code": "440404",
                            "name": "金湾区",
                            "parentCode": "440400",
                            "fullCodes": "440000,440400,440404",
                            "fullNames": "广东省,珠海市,金湾区",
                            "children": []
                        },
                        {
                            "id": "440486",
                            "code": "440486",
                            "name": "金唐区",
                            "parentCode": "440400",
                            "fullCodes": "440000,440400,440486",
                            "fullNames": "广东省,珠海市,金唐区",
                            "children": []
                        },
                        {
                            "id": "440487",
                            "code": "440487",
                            "name": "南湾区",
                            "parentCode": "440400",
                            "fullCodes": "440000,440400,440487",
                            "fullNames": "广东省,珠海市,南湾区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "440500",
                    "code": "440500",
                    "name": "汕头市",
                    "parentCode": "440000",
                    "fullCodes": "440000,440500",
                    "fullNames": "广东省,汕头市",
                    "children": [
                        {
                            "id": "440507",
                            "code": "440507",
                            "name": "龙湖区",
                            "parentCode": "440500",
                            "fullCodes": "440000,440500,440507",
                            "fullNames": "广东省,汕头市,龙湖区",
                            "children": []
                        },
                        {
                            "id": "440511",
                            "code": "440511",
                            "name": "金平区",
                            "parentCode": "440500",
                            "fullCodes": "440000,440500,440511",
                            "fullNames": "广东省,汕头市,金平区",
                            "children": []
                        },
                        {
                            "id": "440512",
                            "code": "440512",
                            "name": "濠江区",
                            "parentCode": "440500",
                            "fullCodes": "440000,440500,440512",
                            "fullNames": "广东省,汕头市,濠江区",
                            "children": []
                        },
                        {
                            "id": "440513",
                            "code": "440513",
                            "name": "潮阳区",
                            "parentCode": "440500",
                            "fullCodes": "440000,440500,440513",
                            "fullNames": "广东省,汕头市,潮阳区",
                            "children": []
                        },
                        {
                            "id": "440514",
                            "code": "440514",
                            "name": "潮南区",
                            "parentCode": "440500",
                            "fullCodes": "440000,440500,440514",
                            "fullNames": "广东省,汕头市,潮南区",
                            "children": []
                        },
                        {
                            "id": "440515",
                            "code": "440515",
                            "name": "澄海区",
                            "parentCode": "440500",
                            "fullCodes": "440000,440500,440515",
                            "fullNames": "广东省,汕头市,澄海区",
                            "children": []
                        },
                        {
                            "id": "440523",
                            "code": "440523",
                            "name": "南澳县",
                            "parentCode": "440500",
                            "fullCodes": "440000,440500,440523",
                            "fullNames": "广东省,汕头市,南澳县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "440600",
                    "code": "440600",
                    "name": "佛山市",
                    "parentCode": "440000",
                    "fullCodes": "440000,440600",
                    "fullNames": "广东省,佛山市",
                    "children": [
                        {
                            "id": "440604",
                            "code": "440604",
                            "name": "禅城区",
                            "parentCode": "440600",
                            "fullCodes": "440000,440600,440604",
                            "fullNames": "广东省,佛山市,禅城区",
                            "children": []
                        },
                        {
                            "id": "440605",
                            "code": "440605",
                            "name": "南海区",
                            "parentCode": "440600",
                            "fullCodes": "440000,440600,440605",
                            "fullNames": "广东省,佛山市,南海区",
                            "children": []
                        },
                        {
                            "id": "440606",
                            "code": "440606",
                            "name": "顺德区",
                            "parentCode": "440600",
                            "fullCodes": "440000,440600,440606",
                            "fullNames": "广东省,佛山市,顺德区",
                            "children": []
                        },
                        {
                            "id": "440607",
                            "code": "440607",
                            "name": "三水区",
                            "parentCode": "440600",
                            "fullCodes": "440000,440600,440607",
                            "fullNames": "广东省,佛山市,三水区",
                            "children": []
                        },
                        {
                            "id": "440608",
                            "code": "440608",
                            "name": "高明区",
                            "parentCode": "440600",
                            "fullCodes": "440000,440600,440608",
                            "fullNames": "广东省,佛山市,高明区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "440700",
                    "code": "440700",
                    "name": "江门市",
                    "parentCode": "440000",
                    "fullCodes": "440000,440700",
                    "fullNames": "广东省,江门市",
                    "children": [
                        {
                            "id": "440703",
                            "code": "440703",
                            "name": "蓬江区",
                            "parentCode": "440700",
                            "fullCodes": "440000,440700,440703",
                            "fullNames": "广东省,江门市,蓬江区",
                            "children": []
                        },
                        {
                            "id": "440704",
                            "code": "440704",
                            "name": "江海区",
                            "parentCode": "440700",
                            "fullCodes": "440000,440700,440704",
                            "fullNames": "广东省,江门市,江海区",
                            "children": []
                        },
                        {
                            "id": "440705",
                            "code": "440705",
                            "name": "新会区",
                            "parentCode": "440700",
                            "fullCodes": "440000,440700,440705",
                            "fullNames": "广东省,江门市,新会区",
                            "children": []
                        },
                        {
                            "id": "440781",
                            "code": "440781",
                            "name": "台山市",
                            "parentCode": "440700",
                            "fullCodes": "440000,440700,440781",
                            "fullNames": "广东省,江门市,台山市",
                            "children": []
                        },
                        {
                            "id": "440783",
                            "code": "440783",
                            "name": "开平市",
                            "parentCode": "440700",
                            "fullCodes": "440000,440700,440783",
                            "fullNames": "广东省,江门市,开平市",
                            "children": []
                        },
                        {
                            "id": "440784",
                            "code": "440784",
                            "name": "鹤山市",
                            "parentCode": "440700",
                            "fullCodes": "440000,440700,440784",
                            "fullNames": "广东省,江门市,鹤山市",
                            "children": []
                        },
                        {
                            "id": "440785",
                            "code": "440785",
                            "name": "恩平市",
                            "parentCode": "440700",
                            "fullCodes": "440000,440700,440785",
                            "fullNames": "广东省,江门市,恩平市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "440800",
                    "code": "440800",
                    "name": "湛江市",
                    "parentCode": "440000",
                    "fullCodes": "440000,440800",
                    "fullNames": "广东省,湛江市",
                    "children": [
                        {
                            "id": "440802",
                            "code": "440802",
                            "name": "赤坎区",
                            "parentCode": "440800",
                            "fullCodes": "440000,440800,440802",
                            "fullNames": "广东省,湛江市,赤坎区",
                            "children": []
                        },
                        {
                            "id": "440803",
                            "code": "440803",
                            "name": "霞山区",
                            "parentCode": "440800",
                            "fullCodes": "440000,440800,440803",
                            "fullNames": "广东省,湛江市,霞山区",
                            "children": []
                        },
                        {
                            "id": "440804",
                            "code": "440804",
                            "name": "坡头区",
                            "parentCode": "440800",
                            "fullCodes": "440000,440800,440804",
                            "fullNames": "广东省,湛江市,坡头区",
                            "children": []
                        },
                        {
                            "id": "440811",
                            "code": "440811",
                            "name": "麻章区",
                            "parentCode": "440800",
                            "fullCodes": "440000,440800,440811",
                            "fullNames": "广东省,湛江市,麻章区",
                            "children": []
                        },
                        {
                            "id": "440823",
                            "code": "440823",
                            "name": "遂溪县",
                            "parentCode": "440800",
                            "fullCodes": "440000,440800,440823",
                            "fullNames": "广东省,湛江市,遂溪县",
                            "children": []
                        },
                        {
                            "id": "440825",
                            "code": "440825",
                            "name": "徐闻县",
                            "parentCode": "440800",
                            "fullCodes": "440000,440800,440825",
                            "fullNames": "广东省,湛江市,徐闻县",
                            "children": []
                        },
                        {
                            "id": "440881",
                            "code": "440881",
                            "name": "廉江市",
                            "parentCode": "440800",
                            "fullCodes": "440000,440800,440881",
                            "fullNames": "广东省,湛江市,廉江市",
                            "children": []
                        },
                        {
                            "id": "440882",
                            "code": "440882",
                            "name": "雷州市",
                            "parentCode": "440800",
                            "fullCodes": "440000,440800,440882",
                            "fullNames": "广东省,湛江市,雷州市",
                            "children": []
                        },
                        {
                            "id": "440883",
                            "code": "440883",
                            "name": "吴川市",
                            "parentCode": "440800",
                            "fullCodes": "440000,440800,440883",
                            "fullNames": "广东省,湛江市,吴川市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "440900",
                    "code": "440900",
                    "name": "茂名市",
                    "parentCode": "440000",
                    "fullCodes": "440000,440900",
                    "fullNames": "广东省,茂名市",
                    "children": [
                        {
                            "id": "440902",
                            "code": "440902",
                            "name": "茂南区",
                            "parentCode": "440900",
                            "fullCodes": "440000,440900,440902",
                            "fullNames": "广东省,茂名市,茂南区",
                            "children": []
                        },
                        {
                            "id": "440903",
                            "code": "440903",
                            "name": "茂港区",
                            "parentCode": "440900",
                            "fullCodes": "440000,440900,440903",
                            "fullNames": "广东省,茂名市,茂港区",
                            "children": []
                        },
                        {
                            "id": "440923",
                            "code": "440923",
                            "name": "电白县",
                            "parentCode": "440900",
                            "fullCodes": "440000,440900,440923",
                            "fullNames": "广东省,茂名市,电白县",
                            "children": []
                        },
                        {
                            "id": "440981",
                            "code": "440981",
                            "name": "高州市",
                            "parentCode": "440900",
                            "fullCodes": "440000,440900,440981",
                            "fullNames": "广东省,茂名市,高州市",
                            "children": []
                        },
                        {
                            "id": "440982",
                            "code": "440982",
                            "name": "化州市",
                            "parentCode": "440900",
                            "fullCodes": "440000,440900,440982",
                            "fullNames": "广东省,茂名市,化州市",
                            "children": []
                        },
                        {
                            "id": "440983",
                            "code": "440983",
                            "name": "信宜市",
                            "parentCode": "440900",
                            "fullCodes": "440000,440900,440983",
                            "fullNames": "广东省,茂名市,信宜市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "441200",
                    "code": "441200",
                    "name": "肇庆市",
                    "parentCode": "440000",
                    "fullCodes": "440000,441200",
                    "fullNames": "广东省,肇庆市",
                    "children": [
                        {
                            "id": "441202",
                            "code": "441202",
                            "name": "端州区",
                            "parentCode": "441200",
                            "fullCodes": "440000,441200,441202",
                            "fullNames": "广东省,肇庆市,端州区",
                            "children": []
                        },
                        {
                            "id": "441203",
                            "code": "441203",
                            "name": "鼎湖区",
                            "parentCode": "441200",
                            "fullCodes": "440000,441200,441203",
                            "fullNames": "广东省,肇庆市,鼎湖区",
                            "children": []
                        },
                        {
                            "id": "441223",
                            "code": "441223",
                            "name": "广宁县",
                            "parentCode": "441200",
                            "fullCodes": "440000,441200,441223",
                            "fullNames": "广东省,肇庆市,广宁县",
                            "children": []
                        },
                        {
                            "id": "441224",
                            "code": "441224",
                            "name": "怀集县",
                            "parentCode": "441200",
                            "fullCodes": "440000,441200,441224",
                            "fullNames": "广东省,肇庆市,怀集县",
                            "children": []
                        },
                        {
                            "id": "441225",
                            "code": "441225",
                            "name": "封开县",
                            "parentCode": "441200",
                            "fullCodes": "440000,441200,441225",
                            "fullNames": "广东省,肇庆市,封开县",
                            "children": []
                        },
                        {
                            "id": "441226",
                            "code": "441226",
                            "name": "德庆县",
                            "parentCode": "441200",
                            "fullCodes": "440000,441200,441226",
                            "fullNames": "广东省,肇庆市,德庆县",
                            "children": []
                        },
                        {
                            "id": "441283",
                            "code": "441283",
                            "name": "高要市",
                            "parentCode": "441200",
                            "fullCodes": "440000,441200,441283",
                            "fullNames": "广东省,肇庆市,高要市",
                            "children": []
                        },
                        {
                            "id": "441284",
                            "code": "441284",
                            "name": "四会市",
                            "parentCode": "441200",
                            "fullCodes": "440000,441200,441284",
                            "fullNames": "广东省,肇庆市,四会市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "441300",
                    "code": "441300",
                    "name": "惠州市",
                    "parentCode": "440000",
                    "fullCodes": "440000,441300",
                    "fullNames": "广东省,惠州市",
                    "children": [
                        {
                            "id": "441302",
                            "code": "441302",
                            "name": "惠城区",
                            "parentCode": "441300",
                            "fullCodes": "440000,441300,441302",
                            "fullNames": "广东省,惠州市,惠城区",
                            "children": []
                        },
                        {
                            "id": "441303",
                            "code": "441303",
                            "name": "惠阳区",
                            "parentCode": "441300",
                            "fullCodes": "440000,441300,441303",
                            "fullNames": "广东省,惠州市,惠阳区",
                            "children": []
                        },
                        {
                            "id": "441322",
                            "code": "441322",
                            "name": "博罗县",
                            "parentCode": "441300",
                            "fullCodes": "440000,441300,441322",
                            "fullNames": "广东省,惠州市,博罗县",
                            "children": []
                        },
                        {
                            "id": "441323",
                            "code": "441323",
                            "name": "惠东县",
                            "parentCode": "441300",
                            "fullCodes": "440000,441300,441323",
                            "fullNames": "广东省,惠州市,惠东县",
                            "children": []
                        },
                        {
                            "id": "441324",
                            "code": "441324",
                            "name": "龙门县",
                            "parentCode": "441300",
                            "fullCodes": "440000,441300,441324",
                            "fullNames": "广东省,惠州市,龙门县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "441400",
                    "code": "441400",
                    "name": "梅州市",
                    "parentCode": "440000",
                    "fullCodes": "440000,441400",
                    "fullNames": "广东省,梅州市",
                    "children": [
                        {
                            "id": "441402",
                            "code": "441402",
                            "name": "梅江区",
                            "parentCode": "441400",
                            "fullCodes": "440000,441400,441402",
                            "fullNames": "广东省,梅州市,梅江区",
                            "children": []
                        },
                        {
                            "id": "441421",
                            "code": "441421",
                            "name": "梅县",
                            "parentCode": "441400",
                            "fullCodes": "440000,441400,441421",
                            "fullNames": "广东省,梅州市,梅县",
                            "children": []
                        },
                        {
                            "id": "441422",
                            "code": "441422",
                            "name": "大埔县",
                            "parentCode": "441400",
                            "fullCodes": "440000,441400,441422",
                            "fullNames": "广东省,梅州市,大埔县",
                            "children": []
                        },
                        {
                            "id": "441423",
                            "code": "441423",
                            "name": "丰顺县",
                            "parentCode": "441400",
                            "fullCodes": "440000,441400,441423",
                            "fullNames": "广东省,梅州市,丰顺县",
                            "children": []
                        },
                        {
                            "id": "441424",
                            "code": "441424",
                            "name": "五华县",
                            "parentCode": "441400",
                            "fullCodes": "440000,441400,441424",
                            "fullNames": "广东省,梅州市,五华县",
                            "children": []
                        },
                        {
                            "id": "441426",
                            "code": "441426",
                            "name": "平远县",
                            "parentCode": "441400",
                            "fullCodes": "440000,441400,441426",
                            "fullNames": "广东省,梅州市,平远县",
                            "children": []
                        },
                        {
                            "id": "441427",
                            "code": "441427",
                            "name": "蕉岭县",
                            "parentCode": "441400",
                            "fullCodes": "440000,441400,441427",
                            "fullNames": "广东省,梅州市,蕉岭县",
                            "children": []
                        },
                        {
                            "id": "441481",
                            "code": "441481",
                            "name": "兴宁市",
                            "parentCode": "441400",
                            "fullCodes": "440000,441400,441481",
                            "fullNames": "广东省,梅州市,兴宁市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "441500",
                    "code": "441500",
                    "name": "汕尾市",
                    "parentCode": "440000",
                    "fullCodes": "440000,441500",
                    "fullNames": "广东省,汕尾市",
                    "children": [
                        {
                            "id": "441502",
                            "code": "441502",
                            "name": "城区",
                            "parentCode": "441500",
                            "fullCodes": "440000,441500,441502",
                            "fullNames": "广东省,汕尾市,城区",
                            "children": []
                        },
                        {
                            "id": "441521",
                            "code": "441521",
                            "name": "海丰县",
                            "parentCode": "441500",
                            "fullCodes": "440000,441500,441521",
                            "fullNames": "广东省,汕尾市,海丰县",
                            "children": []
                        },
                        {
                            "id": "441523",
                            "code": "441523",
                            "name": "陆河县",
                            "parentCode": "441500",
                            "fullCodes": "440000,441500,441523",
                            "fullNames": "广东省,汕尾市,陆河县",
                            "children": []
                        },
                        {
                            "id": "441581",
                            "code": "441581",
                            "name": "陆丰市",
                            "parentCode": "441500",
                            "fullCodes": "440000,441500,441581",
                            "fullNames": "广东省,汕尾市,陆丰市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "441600",
                    "code": "441600",
                    "name": "河源市",
                    "parentCode": "440000",
                    "fullCodes": "440000,441600",
                    "fullNames": "广东省,河源市",
                    "children": [
                        {
                            "id": "441602",
                            "code": "441602",
                            "name": "源城区",
                            "parentCode": "441600",
                            "fullCodes": "440000,441600,441602",
                            "fullNames": "广东省,河源市,源城区",
                            "children": []
                        },
                        {
                            "id": "441621",
                            "code": "441621",
                            "name": "紫金县",
                            "parentCode": "441600",
                            "fullCodes": "440000,441600,441621",
                            "fullNames": "广东省,河源市,紫金县",
                            "children": []
                        },
                        {
                            "id": "441622",
                            "code": "441622",
                            "name": "龙川县",
                            "parentCode": "441600",
                            "fullCodes": "440000,441600,441622",
                            "fullNames": "广东省,河源市,龙川县",
                            "children": []
                        },
                        {
                            "id": "441623",
                            "code": "441623",
                            "name": "连平县",
                            "parentCode": "441600",
                            "fullCodes": "440000,441600,441623",
                            "fullNames": "广东省,河源市,连平县",
                            "children": []
                        },
                        {
                            "id": "441624",
                            "code": "441624",
                            "name": "和平县",
                            "parentCode": "441600",
                            "fullCodes": "440000,441600,441624",
                            "fullNames": "广东省,河源市,和平县",
                            "children": []
                        },
                        {
                            "id": "441625",
                            "code": "441625",
                            "name": "东源县",
                            "parentCode": "441600",
                            "fullCodes": "440000,441600,441625",
                            "fullNames": "广东省,河源市,东源县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "441700",
                    "code": "441700",
                    "name": "阳江市",
                    "parentCode": "440000",
                    "fullCodes": "440000,441700",
                    "fullNames": "广东省,阳江市",
                    "children": [
                        {
                            "id": "441702",
                            "code": "441702",
                            "name": "江城区",
                            "parentCode": "441700",
                            "fullCodes": "440000,441700,441702",
                            "fullNames": "广东省,阳江市,江城区",
                            "children": []
                        },
                        {
                            "id": "441721",
                            "code": "441721",
                            "name": "阳西县",
                            "parentCode": "441700",
                            "fullCodes": "440000,441700,441721",
                            "fullNames": "广东省,阳江市,阳西县",
                            "children": []
                        },
                        {
                            "id": "441723",
                            "code": "441723",
                            "name": "阳东县",
                            "parentCode": "441700",
                            "fullCodes": "440000,441700,441723",
                            "fullNames": "广东省,阳江市,阳东县",
                            "children": []
                        },
                        {
                            "id": "441781",
                            "code": "441781",
                            "name": "阳春市",
                            "parentCode": "441700",
                            "fullCodes": "440000,441700,441781",
                            "fullNames": "广东省,阳江市,阳春市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "441800",
                    "code": "441800",
                    "name": "清远市",
                    "parentCode": "440000",
                    "fullCodes": "440000,441800",
                    "fullNames": "广东省,清远市",
                    "children": [
                        {
                            "id": "441802",
                            "code": "441802",
                            "name": "清城区",
                            "parentCode": "441800",
                            "fullCodes": "440000,441800,441802",
                            "fullNames": "广东省,清远市,清城区",
                            "children": []
                        },
                        {
                            "id": "441821",
                            "code": "441821",
                            "name": "佛冈县",
                            "parentCode": "441800",
                            "fullCodes": "440000,441800,441821",
                            "fullNames": "广东省,清远市,佛冈县",
                            "children": []
                        },
                        {
                            "id": "441823",
                            "code": "441823",
                            "name": "阳山县",
                            "parentCode": "441800",
                            "fullCodes": "440000,441800,441823",
                            "fullNames": "广东省,清远市,阳山县",
                            "children": []
                        },
                        {
                            "id": "441825",
                            "code": "441825",
                            "name": "连山壮族瑶族自治县",
                            "parentCode": "441800",
                            "fullCodes": "440000,441800,441825",
                            "fullNames": "广东省,清远市,连山壮族瑶族自治县",
                            "children": []
                        },
                        {
                            "id": "441826",
                            "code": "441826",
                            "name": "连南瑶族自治县",
                            "parentCode": "441800",
                            "fullCodes": "440000,441800,441826",
                            "fullNames": "广东省,清远市,连南瑶族自治县",
                            "children": []
                        },
                        {
                            "id": "441827",
                            "code": "441827",
                            "name": "清新县",
                            "parentCode": "441800",
                            "fullCodes": "440000,441800,441827",
                            "fullNames": "广东省,清远市,清新县",
                            "children": []
                        },
                        {
                            "id": "441881",
                            "code": "441881",
                            "name": "英德市",
                            "parentCode": "441800",
                            "fullCodes": "440000,441800,441881",
                            "fullNames": "广东省,清远市,英德市",
                            "children": []
                        },
                        {
                            "id": "441882",
                            "code": "441882",
                            "name": "连州市",
                            "parentCode": "441800",
                            "fullCodes": "440000,441800,441882",
                            "fullNames": "广东省,清远市,连州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "441900",
                    "code": "441900",
                    "name": "东莞市",
                    "parentCode": "440000",
                    "fullCodes": "440000,441900",
                    "fullNames": "广东省,东莞市",
                    "children": []
                },
                {
                    "id": "442000",
                    "code": "442000",
                    "name": "中山市",
                    "parentCode": "440000",
                    "fullCodes": "440000,442000",
                    "fullNames": "广东省,中山市",
                    "children": []
                },
                {
                    "id": "445100",
                    "code": "445100",
                    "name": "潮州市",
                    "parentCode": "440000",
                    "fullCodes": "440000,445100",
                    "fullNames": "广东省,潮州市",
                    "children": [
                        {
                            "id": "445102",
                            "code": "445102",
                            "name": "湘桥区",
                            "parentCode": "445100",
                            "fullCodes": "440000,445100,445102",
                            "fullNames": "广东省,潮州市,湘桥区",
                            "children": []
                        },
                        {
                            "id": "445121",
                            "code": "445121",
                            "name": "潮安县",
                            "parentCode": "445100",
                            "fullCodes": "440000,445100,445121",
                            "fullNames": "广东省,潮州市,潮安县",
                            "children": []
                        },
                        {
                            "id": "445122",
                            "code": "445122",
                            "name": "饶平县",
                            "parentCode": "445100",
                            "fullCodes": "440000,445100,445122",
                            "fullNames": "广东省,潮州市,饶平县",
                            "children": []
                        },
                        {
                            "id": "445185",
                            "code": "445185",
                            "name": "枫溪区",
                            "parentCode": "445100",
                            "fullCodes": "440000,445100,445185",
                            "fullNames": "广东省,潮州市,枫溪区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "445200",
                    "code": "445200",
                    "name": "揭阳市",
                    "parentCode": "440000",
                    "fullCodes": "440000,445200",
                    "fullNames": "广东省,揭阳市",
                    "children": [
                        {
                            "id": "445202",
                            "code": "445202",
                            "name": "榕城区",
                            "parentCode": "445200",
                            "fullCodes": "440000,445200,445202",
                            "fullNames": "广东省,揭阳市,榕城区",
                            "children": []
                        },
                        {
                            "id": "445221",
                            "code": "445221",
                            "name": "揭东县",
                            "parentCode": "445200",
                            "fullCodes": "440000,445200,445221",
                            "fullNames": "广东省,揭阳市,揭东县",
                            "children": []
                        },
                        {
                            "id": "445222",
                            "code": "445222",
                            "name": "揭西县",
                            "parentCode": "445200",
                            "fullCodes": "440000,445200,445222",
                            "fullNames": "广东省,揭阳市,揭西县",
                            "children": []
                        },
                        {
                            "id": "445224",
                            "code": "445224",
                            "name": "惠来县",
                            "parentCode": "445200",
                            "fullCodes": "440000,445200,445224",
                            "fullNames": "广东省,揭阳市,惠来县",
                            "children": []
                        },
                        {
                            "id": "445281",
                            "code": "445281",
                            "name": "普宁市",
                            "parentCode": "445200",
                            "fullCodes": "440000,445200,445281",
                            "fullNames": "广东省,揭阳市,普宁市",
                            "children": []
                        },
                        {
                            "id": "445284",
                            "code": "445284",
                            "name": "东山区",
                            "parentCode": "445200",
                            "fullCodes": "440000,445200,445284",
                            "fullNames": "广东省,揭阳市,东山区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "445300",
                    "code": "445300",
                    "name": "云浮市",
                    "parentCode": "440000",
                    "fullCodes": "440000,445300",
                    "fullNames": "广东省,云浮市",
                    "children": [
                        {
                            "id": "445302",
                            "code": "445302",
                            "name": "云城区",
                            "parentCode": "445300",
                            "fullCodes": "440000,445300,445302",
                            "fullNames": "广东省,云浮市,云城区",
                            "children": []
                        },
                        {
                            "id": "445321",
                            "code": "445321",
                            "name": "新兴县",
                            "parentCode": "445300",
                            "fullCodes": "440000,445300,445321",
                            "fullNames": "广东省,云浮市,新兴县",
                            "children": []
                        },
                        {
                            "id": "445322",
                            "code": "445322",
                            "name": "郁南县",
                            "parentCode": "445300",
                            "fullCodes": "440000,445300,445322",
                            "fullNames": "广东省,云浮市,郁南县",
                            "children": []
                        },
                        {
                            "id": "445323",
                            "code": "445323",
                            "name": "云安县",
                            "parentCode": "445300",
                            "fullCodes": "440000,445300,445323",
                            "fullNames": "广东省,云浮市,云安县",
                            "children": []
                        },
                        {
                            "id": "445381",
                            "code": "445381",
                            "name": "罗定市",
                            "parentCode": "445300",
                            "fullCodes": "440000,445300,445381",
                            "fullNames": "广东省,云浮市,罗定市",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "450000",
            "code": "450000",
            "name": "广西省",
            "parentCode": "-1",
            "fullCodes": "450000",
            "fullNames": "广西省",
            "children": [
                {
                    "id": "450100",
                    "code": "450100",
                    "name": "南宁市",
                    "parentCode": "450000",
                    "fullCodes": "450000,450100",
                    "fullNames": "广西省,南宁市",
                    "children": [
                        {
                            "id": "450102",
                            "code": "450102",
                            "name": "兴宁区",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450102",
                            "fullNames": "广西省,南宁市,兴宁区",
                            "children": []
                        },
                        {
                            "id": "450103",
                            "code": "450103",
                            "name": "青秀区",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450103",
                            "fullNames": "广西省,南宁市,青秀区",
                            "children": []
                        },
                        {
                            "id": "450105",
                            "code": "450105",
                            "name": "江南区",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450105",
                            "fullNames": "广西省,南宁市,江南区",
                            "children": []
                        },
                        {
                            "id": "450107",
                            "code": "450107",
                            "name": "西乡塘区",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450107",
                            "fullNames": "广西省,南宁市,西乡塘区",
                            "children": []
                        },
                        {
                            "id": "450108",
                            "code": "450108",
                            "name": "良庆区",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450108",
                            "fullNames": "广西省,南宁市,良庆区",
                            "children": []
                        },
                        {
                            "id": "450109",
                            "code": "450109",
                            "name": "邕宁区",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450109",
                            "fullNames": "广西省,南宁市,邕宁区",
                            "children": []
                        },
                        {
                            "id": "450122",
                            "code": "450122",
                            "name": "武鸣县",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450122",
                            "fullNames": "广西省,南宁市,武鸣县",
                            "children": []
                        },
                        {
                            "id": "450123",
                            "code": "450123",
                            "name": "隆安县",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450123",
                            "fullNames": "广西省,南宁市,隆安县",
                            "children": []
                        },
                        {
                            "id": "450124",
                            "code": "450124",
                            "name": "马山县",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450124",
                            "fullNames": "广西省,南宁市,马山县",
                            "children": []
                        },
                        {
                            "id": "450125",
                            "code": "450125",
                            "name": "上林县",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450125",
                            "fullNames": "广西省,南宁市,上林县",
                            "children": []
                        },
                        {
                            "id": "450126",
                            "code": "450126",
                            "name": "宾阳县",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450126",
                            "fullNames": "广西省,南宁市,宾阳县",
                            "children": []
                        },
                        {
                            "id": "450127",
                            "code": "450127",
                            "name": "横县",
                            "parentCode": "450100",
                            "fullCodes": "450000,450100,450127",
                            "fullNames": "广西省,南宁市,横县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "450200",
                    "code": "450200",
                    "name": "柳州市",
                    "parentCode": "450000",
                    "fullCodes": "450000,450200",
                    "fullNames": "广西省,柳州市",
                    "children": [
                        {
                            "id": "450202",
                            "code": "450202",
                            "name": "城中区",
                            "parentCode": "450200",
                            "fullCodes": "450000,450200,450202",
                            "fullNames": "广西省,柳州市,城中区",
                            "children": []
                        },
                        {
                            "id": "450203",
                            "code": "450203",
                            "name": "鱼峰区",
                            "parentCode": "450200",
                            "fullCodes": "450000,450200,450203",
                            "fullNames": "广西省,柳州市,鱼峰区",
                            "children": []
                        },
                        {
                            "id": "450204",
                            "code": "450204",
                            "name": "柳南区",
                            "parentCode": "450200",
                            "fullCodes": "450000,450200,450204",
                            "fullNames": "广西省,柳州市,柳南区",
                            "children": []
                        },
                        {
                            "id": "450205",
                            "code": "450205",
                            "name": "柳北区",
                            "parentCode": "450200",
                            "fullCodes": "450000,450200,450205",
                            "fullNames": "广西省,柳州市,柳北区",
                            "children": []
                        },
                        {
                            "id": "450221",
                            "code": "450221",
                            "name": "柳江县",
                            "parentCode": "450200",
                            "fullCodes": "450000,450200,450221",
                            "fullNames": "广西省,柳州市,柳江县",
                            "children": []
                        },
                        {
                            "id": "450222",
                            "code": "450222",
                            "name": "柳城县",
                            "parentCode": "450200",
                            "fullCodes": "450000,450200,450222",
                            "fullNames": "广西省,柳州市,柳城县",
                            "children": []
                        },
                        {
                            "id": "450223",
                            "code": "450223",
                            "name": "鹿寨县",
                            "parentCode": "450200",
                            "fullCodes": "450000,450200,450223",
                            "fullNames": "广西省,柳州市,鹿寨县",
                            "children": []
                        },
                        {
                            "id": "450224",
                            "code": "450224",
                            "name": "融安县",
                            "parentCode": "450200",
                            "fullCodes": "450000,450200,450224",
                            "fullNames": "广西省,柳州市,融安县",
                            "children": []
                        },
                        {
                            "id": "450225",
                            "code": "450225",
                            "name": "融水苗族自治县",
                            "parentCode": "450200",
                            "fullCodes": "450000,450200,450225",
                            "fullNames": "广西省,柳州市,融水苗族自治县",
                            "children": []
                        },
                        {
                            "id": "450226",
                            "code": "450226",
                            "name": "三江侗族自治县",
                            "parentCode": "450200",
                            "fullCodes": "450000,450200,450226",
                            "fullNames": "广西省,柳州市,三江侗族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "450300",
                    "code": "450300",
                    "name": "桂林市",
                    "parentCode": "450000",
                    "fullCodes": "450000,450300",
                    "fullNames": "广西省,桂林市",
                    "children": [
                        {
                            "id": "450302",
                            "code": "450302",
                            "name": "秀峰区",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450302",
                            "fullNames": "广西省,桂林市,秀峰区",
                            "children": []
                        },
                        {
                            "id": "450303",
                            "code": "450303",
                            "name": "叠彩区",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450303",
                            "fullNames": "广西省,桂林市,叠彩区",
                            "children": []
                        },
                        {
                            "id": "450304",
                            "code": "450304",
                            "name": "象山区",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450304",
                            "fullNames": "广西省,桂林市,象山区",
                            "children": []
                        },
                        {
                            "id": "450305",
                            "code": "450305",
                            "name": "七星区",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450305",
                            "fullNames": "广西省,桂林市,七星区",
                            "children": []
                        },
                        {
                            "id": "450311",
                            "code": "450311",
                            "name": "雁山区",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450311",
                            "fullNames": "广西省,桂林市,雁山区",
                            "children": []
                        },
                        {
                            "id": "450321",
                            "code": "450321",
                            "name": "阳朔县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450321",
                            "fullNames": "广西省,桂林市,阳朔县",
                            "children": []
                        },
                        {
                            "id": "450322",
                            "code": "450322",
                            "name": "临桂县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450322",
                            "fullNames": "广西省,桂林市,临桂县",
                            "children": []
                        },
                        {
                            "id": "450323",
                            "code": "450323",
                            "name": "灵川县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450323",
                            "fullNames": "广西省,桂林市,灵川县",
                            "children": []
                        },
                        {
                            "id": "450324",
                            "code": "450324",
                            "name": "全州县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450324",
                            "fullNames": "广西省,桂林市,全州县",
                            "children": []
                        },
                        {
                            "id": "450325",
                            "code": "450325",
                            "name": "兴安县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450325",
                            "fullNames": "广西省,桂林市,兴安县",
                            "children": []
                        },
                        {
                            "id": "450326",
                            "code": "450326",
                            "name": "永福县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450326",
                            "fullNames": "广西省,桂林市,永福县",
                            "children": []
                        },
                        {
                            "id": "450327",
                            "code": "450327",
                            "name": "灌阳县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450327",
                            "fullNames": "广西省,桂林市,灌阳县",
                            "children": []
                        },
                        {
                            "id": "450328",
                            "code": "450328",
                            "name": "龙胜各族自治县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450328",
                            "fullNames": "广西省,桂林市,龙胜各族自治县",
                            "children": []
                        },
                        {
                            "id": "450329",
                            "code": "450329",
                            "name": "资源县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450329",
                            "fullNames": "广西省,桂林市,资源县",
                            "children": []
                        },
                        {
                            "id": "450330",
                            "code": "450330",
                            "name": "平乐县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450330",
                            "fullNames": "广西省,桂林市,平乐县",
                            "children": []
                        },
                        {
                            "id": "450331",
                            "code": "450331",
                            "name": "荔浦县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450331",
                            "fullNames": "广西省,桂林市,荔浦县",
                            "children": []
                        },
                        {
                            "id": "450332",
                            "code": "450332",
                            "name": "恭城瑶族自治县",
                            "parentCode": "450300",
                            "fullCodes": "450000,450300,450332",
                            "fullNames": "广西省,桂林市,恭城瑶族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "450400",
                    "code": "450400",
                    "name": "梧州市",
                    "parentCode": "450000",
                    "fullCodes": "450000,450400",
                    "fullNames": "广西省,梧州市",
                    "children": [
                        {
                            "id": "450403",
                            "code": "450403",
                            "name": "万秀区",
                            "parentCode": "450400",
                            "fullCodes": "450000,450400,450403",
                            "fullNames": "广西省,梧州市,万秀区",
                            "children": []
                        },
                        {
                            "id": "450404",
                            "code": "450404",
                            "name": "蝶山区",
                            "parentCode": "450400",
                            "fullCodes": "450000,450400,450404",
                            "fullNames": "广西省,梧州市,蝶山区",
                            "children": []
                        },
                        {
                            "id": "450405",
                            "code": "450405",
                            "name": "长洲区",
                            "parentCode": "450400",
                            "fullCodes": "450000,450400,450405",
                            "fullNames": "广西省,梧州市,长洲区",
                            "children": []
                        },
                        {
                            "id": "450421",
                            "code": "450421",
                            "name": "苍梧县",
                            "parentCode": "450400",
                            "fullCodes": "450000,450400,450421",
                            "fullNames": "广西省,梧州市,苍梧县",
                            "children": []
                        },
                        {
                            "id": "450422",
                            "code": "450422",
                            "name": "藤县",
                            "parentCode": "450400",
                            "fullCodes": "450000,450400,450422",
                            "fullNames": "广西省,梧州市,藤县",
                            "children": []
                        },
                        {
                            "id": "450423",
                            "code": "450423",
                            "name": "蒙山县",
                            "parentCode": "450400",
                            "fullCodes": "450000,450400,450423",
                            "fullNames": "广西省,梧州市,蒙山县",
                            "children": []
                        },
                        {
                            "id": "450481",
                            "code": "450481",
                            "name": "岑溪市",
                            "parentCode": "450400",
                            "fullCodes": "450000,450400,450481",
                            "fullNames": "广西省,梧州市,岑溪市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "450500",
                    "code": "450500",
                    "name": "北海市",
                    "parentCode": "450000",
                    "fullCodes": "450000,450500",
                    "fullNames": "广西省,北海市",
                    "children": [
                        {
                            "id": "450502",
                            "code": "450502",
                            "name": "海城区",
                            "parentCode": "450500",
                            "fullCodes": "450000,450500,450502",
                            "fullNames": "广西省,北海市,海城区",
                            "children": []
                        },
                        {
                            "id": "450503",
                            "code": "450503",
                            "name": "银海区",
                            "parentCode": "450500",
                            "fullCodes": "450000,450500,450503",
                            "fullNames": "广西省,北海市,银海区",
                            "children": []
                        },
                        {
                            "id": "450512",
                            "code": "450512",
                            "name": "铁山港区",
                            "parentCode": "450500",
                            "fullCodes": "450000,450500,450512",
                            "fullNames": "广西省,北海市,铁山港区",
                            "children": []
                        },
                        {
                            "id": "450521",
                            "code": "450521",
                            "name": "合浦县",
                            "parentCode": "450500",
                            "fullCodes": "450000,450500,450521",
                            "fullNames": "广西省,北海市,合浦县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "450600",
                    "code": "450600",
                    "name": "防城港市",
                    "parentCode": "450000",
                    "fullCodes": "450000,450600",
                    "fullNames": "广西省,防城港市",
                    "children": [
                        {
                            "id": "450602",
                            "code": "450602",
                            "name": "港口区",
                            "parentCode": "450600",
                            "fullCodes": "450000,450600,450602",
                            "fullNames": "广西省,防城港市,港口区",
                            "children": []
                        },
                        {
                            "id": "450603",
                            "code": "450603",
                            "name": "防城区",
                            "parentCode": "450600",
                            "fullCodes": "450000,450600,450603",
                            "fullNames": "广西省,防城港市,防城区",
                            "children": []
                        },
                        {
                            "id": "450621",
                            "code": "450621",
                            "name": "上思县",
                            "parentCode": "450600",
                            "fullCodes": "450000,450600,450621",
                            "fullNames": "广西省,防城港市,上思县",
                            "children": []
                        },
                        {
                            "id": "450681",
                            "code": "450681",
                            "name": "东兴市",
                            "parentCode": "450600",
                            "fullCodes": "450000,450600,450681",
                            "fullNames": "广西省,防城港市,东兴市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "450700",
                    "code": "450700",
                    "name": "钦州市",
                    "parentCode": "450000",
                    "fullCodes": "450000,450700",
                    "fullNames": "广西省,钦州市",
                    "children": [
                        {
                            "id": "450702",
                            "code": "450702",
                            "name": "钦南区",
                            "parentCode": "450700",
                            "fullCodes": "450000,450700,450702",
                            "fullNames": "广西省,钦州市,钦南区",
                            "children": []
                        },
                        {
                            "id": "450703",
                            "code": "450703",
                            "name": "钦北区",
                            "parentCode": "450700",
                            "fullCodes": "450000,450700,450703",
                            "fullNames": "广西省,钦州市,钦北区",
                            "children": []
                        },
                        {
                            "id": "450721",
                            "code": "450721",
                            "name": "灵山县",
                            "parentCode": "450700",
                            "fullCodes": "450000,450700,450721",
                            "fullNames": "广西省,钦州市,灵山县",
                            "children": []
                        },
                        {
                            "id": "450722",
                            "code": "450722",
                            "name": "浦北县",
                            "parentCode": "450700",
                            "fullCodes": "450000,450700,450722",
                            "fullNames": "广西省,钦州市,浦北县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "450800",
                    "code": "450800",
                    "name": "贵港市",
                    "parentCode": "450000",
                    "fullCodes": "450000,450800",
                    "fullNames": "广西省,贵港市",
                    "children": [
                        {
                            "id": "450802",
                            "code": "450802",
                            "name": "港北区",
                            "parentCode": "450800",
                            "fullCodes": "450000,450800,450802",
                            "fullNames": "广西省,贵港市,港北区",
                            "children": []
                        },
                        {
                            "id": "450803",
                            "code": "450803",
                            "name": "港南区",
                            "parentCode": "450800",
                            "fullCodes": "450000,450800,450803",
                            "fullNames": "广西省,贵港市,港南区",
                            "children": []
                        },
                        {
                            "id": "450804",
                            "code": "450804",
                            "name": "覃塘区",
                            "parentCode": "450800",
                            "fullCodes": "450000,450800,450804",
                            "fullNames": "广西省,贵港市,覃塘区",
                            "children": []
                        },
                        {
                            "id": "450821",
                            "code": "450821",
                            "name": "平南县",
                            "parentCode": "450800",
                            "fullCodes": "450000,450800,450821",
                            "fullNames": "广西省,贵港市,平南县",
                            "children": []
                        },
                        {
                            "id": "450881",
                            "code": "450881",
                            "name": "桂平市",
                            "parentCode": "450800",
                            "fullCodes": "450000,450800,450881",
                            "fullNames": "广西省,贵港市,桂平市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "450900",
                    "code": "450900",
                    "name": "玉林市",
                    "parentCode": "450000",
                    "fullCodes": "450000,450900",
                    "fullNames": "广西省,玉林市",
                    "children": [
                        {
                            "id": "450902",
                            "code": "450902",
                            "name": "玉州区",
                            "parentCode": "450900",
                            "fullCodes": "450000,450900,450902",
                            "fullNames": "广西省,玉林市,玉州区",
                            "children": []
                        },
                        {
                            "id": "450921",
                            "code": "450921",
                            "name": "容县",
                            "parentCode": "450900",
                            "fullCodes": "450000,450900,450921",
                            "fullNames": "广西省,玉林市,容县",
                            "children": []
                        },
                        {
                            "id": "450922",
                            "code": "450922",
                            "name": "陆川县",
                            "parentCode": "450900",
                            "fullCodes": "450000,450900,450922",
                            "fullNames": "广西省,玉林市,陆川县",
                            "children": []
                        },
                        {
                            "id": "450923",
                            "code": "450923",
                            "name": "博白县",
                            "parentCode": "450900",
                            "fullCodes": "450000,450900,450923",
                            "fullNames": "广西省,玉林市,博白县",
                            "children": []
                        },
                        {
                            "id": "450924",
                            "code": "450924",
                            "name": "兴业县",
                            "parentCode": "450900",
                            "fullCodes": "450000,450900,450924",
                            "fullNames": "广西省,玉林市,兴业县",
                            "children": []
                        },
                        {
                            "id": "450981",
                            "code": "450981",
                            "name": "北流市",
                            "parentCode": "450900",
                            "fullCodes": "450000,450900,450981",
                            "fullNames": "广西省,玉林市,北流市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "451000",
                    "code": "451000",
                    "name": "百色市",
                    "parentCode": "450000",
                    "fullCodes": "450000,451000",
                    "fullNames": "广西省,百色市",
                    "children": [
                        {
                            "id": "451002",
                            "code": "451002",
                            "name": "右江区",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451002",
                            "fullNames": "广西省,百色市,右江区",
                            "children": []
                        },
                        {
                            "id": "451021",
                            "code": "451021",
                            "name": "田阳县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451021",
                            "fullNames": "广西省,百色市,田阳县",
                            "children": []
                        },
                        {
                            "id": "451022",
                            "code": "451022",
                            "name": "田东县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451022",
                            "fullNames": "广西省,百色市,田东县",
                            "children": []
                        },
                        {
                            "id": "451023",
                            "code": "451023",
                            "name": "平果县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451023",
                            "fullNames": "广西省,百色市,平果县",
                            "children": []
                        },
                        {
                            "id": "451024",
                            "code": "451024",
                            "name": "德保县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451024",
                            "fullNames": "广西省,百色市,德保县",
                            "children": []
                        },
                        {
                            "id": "451025",
                            "code": "451025",
                            "name": "靖西县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451025",
                            "fullNames": "广西省,百色市,靖西县",
                            "children": []
                        },
                        {
                            "id": "451026",
                            "code": "451026",
                            "name": "那坡县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451026",
                            "fullNames": "广西省,百色市,那坡县",
                            "children": []
                        },
                        {
                            "id": "451027",
                            "code": "451027",
                            "name": "凌云县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451027",
                            "fullNames": "广西省,百色市,凌云县",
                            "children": []
                        },
                        {
                            "id": "451028",
                            "code": "451028",
                            "name": "乐业县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451028",
                            "fullNames": "广西省,百色市,乐业县",
                            "children": []
                        },
                        {
                            "id": "451029",
                            "code": "451029",
                            "name": "田林县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451029",
                            "fullNames": "广西省,百色市,田林县",
                            "children": []
                        },
                        {
                            "id": "451030",
                            "code": "451030",
                            "name": "西林县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451030",
                            "fullNames": "广西省,百色市,西林县",
                            "children": []
                        },
                        {
                            "id": "451031",
                            "code": "451031",
                            "name": "隆林各族自治县",
                            "parentCode": "451000",
                            "fullCodes": "450000,451000,451031",
                            "fullNames": "广西省,百色市,隆林各族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "451100",
                    "code": "451100",
                    "name": "贺州市",
                    "parentCode": "450000",
                    "fullCodes": "450000,451100",
                    "fullNames": "广西省,贺州市",
                    "children": [
                        {
                            "id": "451102",
                            "code": "451102",
                            "name": "八步区",
                            "parentCode": "451100",
                            "fullCodes": "450000,451100,451102",
                            "fullNames": "广西省,贺州市,八步区",
                            "children": []
                        },
                        {
                            "id": "451121",
                            "code": "451121",
                            "name": "昭平县",
                            "parentCode": "451100",
                            "fullCodes": "450000,451100,451121",
                            "fullNames": "广西省,贺州市,昭平县",
                            "children": []
                        },
                        {
                            "id": "451122",
                            "code": "451122",
                            "name": "钟山县",
                            "parentCode": "451100",
                            "fullCodes": "450000,451100,451122",
                            "fullNames": "广西省,贺州市,钟山县",
                            "children": []
                        },
                        {
                            "id": "451123",
                            "code": "451123",
                            "name": "富川瑶族自治县",
                            "parentCode": "451100",
                            "fullCodes": "450000,451100,451123",
                            "fullNames": "广西省,贺州市,富川瑶族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "451200",
                    "code": "451200",
                    "name": "河池市",
                    "parentCode": "450000",
                    "fullCodes": "450000,451200",
                    "fullNames": "广西省,河池市",
                    "children": [
                        {
                            "id": "451202",
                            "code": "451202",
                            "name": "金城江区",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451202",
                            "fullNames": "广西省,河池市,金城江区",
                            "children": []
                        },
                        {
                            "id": "451221",
                            "code": "451221",
                            "name": "南丹县",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451221",
                            "fullNames": "广西省,河池市,南丹县",
                            "children": []
                        },
                        {
                            "id": "451222",
                            "code": "451222",
                            "name": "天峨县",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451222",
                            "fullNames": "广西省,河池市,天峨县",
                            "children": []
                        },
                        {
                            "id": "451223",
                            "code": "451223",
                            "name": "凤山县",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451223",
                            "fullNames": "广西省,河池市,凤山县",
                            "children": []
                        },
                        {
                            "id": "451224",
                            "code": "451224",
                            "name": "东兰县",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451224",
                            "fullNames": "广西省,河池市,东兰县",
                            "children": []
                        },
                        {
                            "id": "451225",
                            "code": "451225",
                            "name": "罗城仫佬族自治县",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451225",
                            "fullNames": "广西省,河池市,罗城仫佬族自治县",
                            "children": []
                        },
                        {
                            "id": "451226",
                            "code": "451226",
                            "name": "环江毛南族自治县",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451226",
                            "fullNames": "广西省,河池市,环江毛南族自治县",
                            "children": []
                        },
                        {
                            "id": "451227",
                            "code": "451227",
                            "name": "巴马瑶族自治县",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451227",
                            "fullNames": "广西省,河池市,巴马瑶族自治县",
                            "children": []
                        },
                        {
                            "id": "451228",
                            "code": "451228",
                            "name": "都安瑶族自治县",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451228",
                            "fullNames": "广西省,河池市,都安瑶族自治县",
                            "children": []
                        },
                        {
                            "id": "451229",
                            "code": "451229",
                            "name": "大化瑶族自治县",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451229",
                            "fullNames": "广西省,河池市,大化瑶族自治县",
                            "children": []
                        },
                        {
                            "id": "451281",
                            "code": "451281",
                            "name": "宜州市",
                            "parentCode": "451200",
                            "fullCodes": "450000,451200,451281",
                            "fullNames": "广西省,河池市,宜州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "451300",
                    "code": "451300",
                    "name": "来宾市",
                    "parentCode": "450000",
                    "fullCodes": "450000,451300",
                    "fullNames": "广西省,来宾市",
                    "children": [
                        {
                            "id": "451302",
                            "code": "451302",
                            "name": "兴宾区",
                            "parentCode": "451300",
                            "fullCodes": "450000,451300,451302",
                            "fullNames": "广西省,来宾市,兴宾区",
                            "children": []
                        },
                        {
                            "id": "451321",
                            "code": "451321",
                            "name": "忻城县",
                            "parentCode": "451300",
                            "fullCodes": "450000,451300,451321",
                            "fullNames": "广西省,来宾市,忻城县",
                            "children": []
                        },
                        {
                            "id": "451322",
                            "code": "451322",
                            "name": "象州县",
                            "parentCode": "451300",
                            "fullCodes": "450000,451300,451322",
                            "fullNames": "广西省,来宾市,象州县",
                            "children": []
                        },
                        {
                            "id": "451323",
                            "code": "451323",
                            "name": "武宣县",
                            "parentCode": "451300",
                            "fullCodes": "450000,451300,451323",
                            "fullNames": "广西省,来宾市,武宣县",
                            "children": []
                        },
                        {
                            "id": "451324",
                            "code": "451324",
                            "name": "金秀瑶族自治县",
                            "parentCode": "451300",
                            "fullCodes": "450000,451300,451324",
                            "fullNames": "广西省,来宾市,金秀瑶族自治县",
                            "children": []
                        },
                        {
                            "id": "451381",
                            "code": "451381",
                            "name": "合山市",
                            "parentCode": "451300",
                            "fullCodes": "450000,451300,451381",
                            "fullNames": "广西省,来宾市,合山市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "451400",
                    "code": "451400",
                    "name": "崇左市",
                    "parentCode": "450000",
                    "fullCodes": "450000,451400",
                    "fullNames": "广西省,崇左市",
                    "children": [
                        {
                            "id": "451402",
                            "code": "451402",
                            "name": "江州区",
                            "parentCode": "451400",
                            "fullCodes": "450000,451400,451402",
                            "fullNames": "广西省,崇左市,江州区",
                            "children": []
                        },
                        {
                            "id": "451421",
                            "code": "451421",
                            "name": "扶绥县",
                            "parentCode": "451400",
                            "fullCodes": "450000,451400,451421",
                            "fullNames": "广西省,崇左市,扶绥县",
                            "children": []
                        },
                        {
                            "id": "451422",
                            "code": "451422",
                            "name": "宁明县",
                            "parentCode": "451400",
                            "fullCodes": "450000,451400,451422",
                            "fullNames": "广西省,崇左市,宁明县",
                            "children": []
                        },
                        {
                            "id": "451423",
                            "code": "451423",
                            "name": "龙州县",
                            "parentCode": "451400",
                            "fullCodes": "450000,451400,451423",
                            "fullNames": "广西省,崇左市,龙州县",
                            "children": []
                        },
                        {
                            "id": "451424",
                            "code": "451424",
                            "name": "大新县",
                            "parentCode": "451400",
                            "fullCodes": "450000,451400,451424",
                            "fullNames": "广西省,崇左市,大新县",
                            "children": []
                        },
                        {
                            "id": "451425",
                            "code": "451425",
                            "name": "天等县",
                            "parentCode": "451400",
                            "fullCodes": "450000,451400,451425",
                            "fullNames": "广西省,崇左市,天等县",
                            "children": []
                        },
                        {
                            "id": "451481",
                            "code": "451481",
                            "name": "凭祥市",
                            "parentCode": "451400",
                            "fullCodes": "450000,451400,451481",
                            "fullNames": "广西省,崇左市,凭祥市",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "460000",
            "code": "460000",
            "name": "海南省",
            "parentCode": "-1",
            "fullCodes": "460000",
            "fullNames": "海南省",
            "children": [
                {
                    "id": "460100",
                    "code": "460100",
                    "name": "海口市",
                    "parentCode": "460000",
                    "fullCodes": "460000,460100",
                    "fullNames": "海南省,海口市",
                    "children": [
                        {
                            "id": "460105",
                            "code": "460105",
                            "name": "秀英区",
                            "parentCode": "460100",
                            "fullCodes": "460000,460100,460105",
                            "fullNames": "海南省,海口市,秀英区",
                            "children": []
                        },
                        {
                            "id": "460106",
                            "code": "460106",
                            "name": "龙华区",
                            "parentCode": "460100",
                            "fullCodes": "460000,460100,460106",
                            "fullNames": "海南省,海口市,龙华区",
                            "children": []
                        },
                        {
                            "id": "460107",
                            "code": "460107",
                            "name": "琼山区",
                            "parentCode": "460100",
                            "fullCodes": "460000,460100,460107",
                            "fullNames": "海南省,海口市,琼山区",
                            "children": []
                        },
                        {
                            "id": "460108",
                            "code": "460108",
                            "name": "美兰区",
                            "parentCode": "460100",
                            "fullCodes": "460000,460100,460108",
                            "fullNames": "海南省,海口市,美兰区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "460200",
                    "code": "460200",
                    "name": "三亚市",
                    "parentCode": "460000",
                    "fullCodes": "460000,460200",
                    "fullNames": "海南省,三亚市",
                    "children": []
                },
                {
                    "id": "469001",
                    "code": "469001",
                    "name": "五指山市",
                    "parentCode": "460000",
                    "fullCodes": "460000,469001",
                    "fullNames": "海南省,五指山市",
                    "children": []
                },
                {
                    "id": "469002",
                    "code": "469002",
                    "name": "琼海市",
                    "parentCode": "460000",
                    "fullCodes": "460000,469002",
                    "fullNames": "海南省,琼海市",
                    "children": []
                },
                {
                    "id": "469003",
                    "code": "469003",
                    "name": "儋州市",
                    "parentCode": "460000",
                    "fullCodes": "460000,469003",
                    "fullNames": "海南省,儋州市",
                    "children": []
                },
                {
                    "id": "469005",
                    "code": "469005",
                    "name": "文昌市",
                    "parentCode": "460000",
                    "fullCodes": "460000,469005",
                    "fullNames": "海南省,文昌市",
                    "children": []
                },
                {
                    "id": "469006",
                    "code": "469006",
                    "name": "万宁市",
                    "parentCode": "460000",
                    "fullCodes": "460000,469006",
                    "fullNames": "海南省,万宁市",
                    "children": []
                },
                {
                    "id": "469007",
                    "code": "469007",
                    "name": "东方市",
                    "parentCode": "460000",
                    "fullCodes": "460000,469007",
                    "fullNames": "海南省,东方市",
                    "children": []
                },
                {
                    "id": "469025",
                    "code": "469025",
                    "name": "定安县",
                    "parentCode": "460000",
                    "fullCodes": "460000,469025",
                    "fullNames": "海南省,定安县",
                    "children": []
                },
                {
                    "id": "469026",
                    "code": "469026",
                    "name": "屯昌县",
                    "parentCode": "460000",
                    "fullCodes": "460000,469026",
                    "fullNames": "海南省,屯昌县",
                    "children": []
                },
                {
                    "id": "469027",
                    "code": "469027",
                    "name": "澄迈县",
                    "parentCode": "460000",
                    "fullCodes": "460000,469027",
                    "fullNames": "海南省,澄迈县",
                    "children": []
                },
                {
                    "id": "469028",
                    "code": "469028",
                    "name": "临高县",
                    "parentCode": "460000",
                    "fullCodes": "460000,469028",
                    "fullNames": "海南省,临高县",
                    "children": []
                },
                {
                    "id": "469030",
                    "code": "469030",
                    "name": "白沙黎族自治县",
                    "parentCode": "460000",
                    "fullCodes": "460000,469030",
                    "fullNames": "海南省,白沙黎族自治县",
                    "children": []
                },
                {
                    "id": "469031",
                    "code": "469031",
                    "name": "昌江黎族自治县",
                    "parentCode": "460000",
                    "fullCodes": "460000,469031",
                    "fullNames": "海南省,昌江黎族自治县",
                    "children": []
                },
                {
                    "id": "469033",
                    "code": "469033",
                    "name": "乐东黎族自治县",
                    "parentCode": "460000",
                    "fullCodes": "460000,469033",
                    "fullNames": "海南省,乐东黎族自治县",
                    "children": []
                },
                {
                    "id": "469034",
                    "code": "469034",
                    "name": "陵水黎族自治县",
                    "parentCode": "460000",
                    "fullCodes": "460000,469034",
                    "fullNames": "海南省,陵水黎族自治县",
                    "children": []
                },
                {
                    "id": "469035",
                    "code": "469035",
                    "name": "保亭黎族苗族自治县",
                    "parentCode": "460000",
                    "fullCodes": "460000,469035",
                    "fullNames": "海南省,保亭黎族苗族自治县",
                    "children": []
                },
                {
                    "id": "469036",
                    "code": "469036",
                    "name": "琼中黎族苗族自治县",
                    "parentCode": "460000",
                    "fullCodes": "460000,469036",
                    "fullNames": "海南省,琼中黎族苗族自治县",
                    "children": []
                },
                {
                    "id": "469037",
                    "code": "469037",
                    "name": "西沙群岛",
                    "parentCode": "460000",
                    "fullCodes": "460000,469037",
                    "fullNames": "海南省,西沙群岛",
                    "children": []
                },
                {
                    "id": "469038",
                    "code": "469038",
                    "name": "南沙群岛",
                    "parentCode": "460000",
                    "fullCodes": "460000,469038",
                    "fullNames": "海南省,南沙群岛",
                    "children": []
                },
                {
                    "id": "469039",
                    "code": "469039",
                    "name": "中沙群岛的岛礁及其海域",
                    "parentCode": "460000",
                    "fullCodes": "460000,469039",
                    "fullNames": "海南省,中沙群岛的岛礁及其海域",
                    "children": []
                }
            ]
        },
        {
            "id": "500100",
            "code": "500100",
            "name": "重庆市",
            "parentCode": "-1",
            "fullCodes": "500100",
            "fullNames": "重庆市",
            "children": [
                {
                    "id": "500101",
                    "code": "500101",
                    "name": "万州区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500101",
                    "fullNames": "重庆市,万州区",
                    "children": []
                },
                {
                    "id": "500102",
                    "code": "500102",
                    "name": "涪陵区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500102",
                    "fullNames": "重庆市,涪陵区",
                    "children": []
                },
                {
                    "id": "500103",
                    "code": "500103",
                    "name": "渝中区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500103",
                    "fullNames": "重庆市,渝中区",
                    "children": []
                },
                {
                    "id": "500104",
                    "code": "500104",
                    "name": "大渡口区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500104",
                    "fullNames": "重庆市,大渡口区",
                    "children": []
                },
                {
                    "id": "500105",
                    "code": "500105",
                    "name": "江北区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500105",
                    "fullNames": "重庆市,江北区",
                    "children": []
                },
                {
                    "id": "500106",
                    "code": "500106",
                    "name": "沙坪坝区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500106",
                    "fullNames": "重庆市,沙坪坝区",
                    "children": []
                },
                {
                    "id": "500107",
                    "code": "500107",
                    "name": "九龙坡区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500107",
                    "fullNames": "重庆市,九龙坡区",
                    "children": []
                },
                {
                    "id": "500108",
                    "code": "500108",
                    "name": "南岸区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500108",
                    "fullNames": "重庆市,南岸区",
                    "children": []
                },
                {
                    "id": "500109",
                    "code": "500109",
                    "name": "北碚区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500109",
                    "fullNames": "重庆市,北碚区",
                    "children": []
                },
                {
                    "id": "500110",
                    "code": "500110",
                    "name": "万盛区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500110",
                    "fullNames": "重庆市,万盛区",
                    "children": []
                },
                {
                    "id": "500111",
                    "code": "500111",
                    "name": "双桥区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500111",
                    "fullNames": "重庆市,双桥区",
                    "children": []
                },
                {
                    "id": "500112",
                    "code": "500112",
                    "name": "渝北区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500112",
                    "fullNames": "重庆市,渝北区",
                    "children": []
                },
                {
                    "id": "500113",
                    "code": "500113",
                    "name": "巴南区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500113",
                    "fullNames": "重庆市,巴南区",
                    "children": []
                },
                {
                    "id": "500114",
                    "code": "500114",
                    "name": "黔江区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500114",
                    "fullNames": "重庆市,黔江区",
                    "children": []
                },
                {
                    "id": "500115",
                    "code": "500115",
                    "name": "长寿区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500115",
                    "fullNames": "重庆市,长寿区",
                    "children": []
                },
                {
                    "id": "500222",
                    "code": "500222",
                    "name": "綦江县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500222",
                    "fullNames": "重庆市,綦江县",
                    "children": []
                },
                {
                    "id": "500223",
                    "code": "500223",
                    "name": "潼南县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500223",
                    "fullNames": "重庆市,潼南县",
                    "children": []
                },
                {
                    "id": "500224",
                    "code": "500224",
                    "name": "铜梁县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500224",
                    "fullNames": "重庆市,铜梁县",
                    "children": []
                },
                {
                    "id": "500225",
                    "code": "500225",
                    "name": "大足县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500225",
                    "fullNames": "重庆市,大足县",
                    "children": []
                },
                {
                    "id": "500226",
                    "code": "500226",
                    "name": "荣昌县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500226",
                    "fullNames": "重庆市,荣昌县",
                    "children": []
                },
                {
                    "id": "500227",
                    "code": "500227",
                    "name": "璧山县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500227",
                    "fullNames": "重庆市,璧山县",
                    "children": []
                },
                {
                    "id": "500228",
                    "code": "500228",
                    "name": "梁平县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500228",
                    "fullNames": "重庆市,梁平县",
                    "children": []
                },
                {
                    "id": "500229",
                    "code": "500229",
                    "name": "城口县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500229",
                    "fullNames": "重庆市,城口县",
                    "children": []
                },
                {
                    "id": "500230",
                    "code": "500230",
                    "name": "丰都县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500230",
                    "fullNames": "重庆市,丰都县",
                    "children": []
                },
                {
                    "id": "500231",
                    "code": "500231",
                    "name": "垫江县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500231",
                    "fullNames": "重庆市,垫江县",
                    "children": []
                },
                {
                    "id": "500232",
                    "code": "500232",
                    "name": "武隆县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500232",
                    "fullNames": "重庆市,武隆县",
                    "children": []
                },
                {
                    "id": "500233",
                    "code": "500233",
                    "name": "忠县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500233",
                    "fullNames": "重庆市,忠县",
                    "children": []
                },
                {
                    "id": "500234",
                    "code": "500234",
                    "name": "开县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500234",
                    "fullNames": "重庆市,开县",
                    "children": []
                },
                {
                    "id": "500235",
                    "code": "500235",
                    "name": "云阳县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500235",
                    "fullNames": "重庆市,云阳县",
                    "children": []
                },
                {
                    "id": "500236",
                    "code": "500236",
                    "name": "奉节县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500236",
                    "fullNames": "重庆市,奉节县",
                    "children": []
                },
                {
                    "id": "500237",
                    "code": "500237",
                    "name": "巫山县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500237",
                    "fullNames": "重庆市,巫山县",
                    "children": []
                },
                {
                    "id": "500238",
                    "code": "500238",
                    "name": "巫溪县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500238",
                    "fullNames": "重庆市,巫溪县",
                    "children": []
                },
                {
                    "id": "500240",
                    "code": "500240",
                    "name": "石柱土家族自治县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500240",
                    "fullNames": "重庆市,石柱土家族自治县",
                    "children": []
                },
                {
                    "id": "500241",
                    "code": "500241",
                    "name": "秀山土家族苗族自治县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500241",
                    "fullNames": "重庆市,秀山土家族苗族自治县",
                    "children": []
                },
                {
                    "id": "500242",
                    "code": "500242",
                    "name": "酉阳土家族苗族自治县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500242",
                    "fullNames": "重庆市,酉阳土家族苗族自治县",
                    "children": []
                },
                {
                    "id": "500243",
                    "code": "500243",
                    "name": "彭水苗族土家族自治县",
                    "parentCode": "500100",
                    "fullCodes": "500100,500243",
                    "fullNames": "重庆市,彭水苗族土家族自治县",
                    "children": []
                },
                {
                    "id": "500381",
                    "code": "500381",
                    "name": "江津区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500381",
                    "fullNames": "重庆市,江津区",
                    "children": []
                },
                {
                    "id": "500382",
                    "code": "500382",
                    "name": "合川区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500382",
                    "fullNames": "重庆市,合川区",
                    "children": []
                },
                {
                    "id": "500383",
                    "code": "500383",
                    "name": "永川区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500383",
                    "fullNames": "重庆市,永川区",
                    "children": []
                },
                {
                    "id": "500384",
                    "code": "500384",
                    "name": "南川区",
                    "parentCode": "500100",
                    "fullCodes": "500100,500384",
                    "fullNames": "重庆市,南川区",
                    "children": []
                }
            ]
        },
        {
            "id": "510000",
            "code": "510000",
            "name": "四川省",
            "parentCode": "-1",
            "fullCodes": "510000",
            "fullNames": "四川省",
            "children": [
                {
                    "id": "510100",
                    "code": "510100",
                    "name": "成都市",
                    "parentCode": "510000",
                    "fullCodes": "510000,510100",
                    "fullNames": "四川省,成都市",
                    "children": [
                        {
                            "id": "510104",
                            "code": "510104",
                            "name": "锦江区",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510104",
                            "fullNames": "四川省,成都市,锦江区",
                            "children": []
                        },
                        {
                            "id": "510105",
                            "code": "510105",
                            "name": "青羊区",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510105",
                            "fullNames": "四川省,成都市,青羊区",
                            "children": []
                        },
                        {
                            "id": "510106",
                            "code": "510106",
                            "name": "金牛区",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510106",
                            "fullNames": "四川省,成都市,金牛区",
                            "children": []
                        },
                        {
                            "id": "510107",
                            "code": "510107",
                            "name": "武侯区",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510107",
                            "fullNames": "四川省,成都市,武侯区",
                            "children": []
                        },
                        {
                            "id": "510108",
                            "code": "510108",
                            "name": "成华区",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510108",
                            "fullNames": "四川省,成都市,成华区",
                            "children": []
                        },
                        {
                            "id": "510112",
                            "code": "510112",
                            "name": "龙泉驿区",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510112",
                            "fullNames": "四川省,成都市,龙泉驿区",
                            "children": []
                        },
                        {
                            "id": "510113",
                            "code": "510113",
                            "name": "青白江区",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510113",
                            "fullNames": "四川省,成都市,青白江区",
                            "children": []
                        },
                        {
                            "id": "510114",
                            "code": "510114",
                            "name": "新都区",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510114",
                            "fullNames": "四川省,成都市,新都区",
                            "children": []
                        },
                        {
                            "id": "510115",
                            "code": "510115",
                            "name": "温江区",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510115",
                            "fullNames": "四川省,成都市,温江区",
                            "children": []
                        },
                        {
                            "id": "510121",
                            "code": "510121",
                            "name": "金堂县",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510121",
                            "fullNames": "四川省,成都市,金堂县",
                            "children": []
                        },
                        {
                            "id": "510122",
                            "code": "510122",
                            "name": "双流县",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510122",
                            "fullNames": "四川省,成都市,双流县",
                            "children": []
                        },
                        {
                            "id": "510124",
                            "code": "510124",
                            "name": "郫县",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510124",
                            "fullNames": "四川省,成都市,郫县",
                            "children": []
                        },
                        {
                            "id": "510129",
                            "code": "510129",
                            "name": "大邑县",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510129",
                            "fullNames": "四川省,成都市,大邑县",
                            "children": []
                        },
                        {
                            "id": "510131",
                            "code": "510131",
                            "name": "蒲江县",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510131",
                            "fullNames": "四川省,成都市,蒲江县",
                            "children": []
                        },
                        {
                            "id": "510132",
                            "code": "510132",
                            "name": "新津县",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510132",
                            "fullNames": "四川省,成都市,新津县",
                            "children": []
                        },
                        {
                            "id": "510181",
                            "code": "510181",
                            "name": "都江堰市",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510181",
                            "fullNames": "四川省,成都市,都江堰市",
                            "children": []
                        },
                        {
                            "id": "510182",
                            "code": "510182",
                            "name": "彭州市",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510182",
                            "fullNames": "四川省,成都市,彭州市",
                            "children": []
                        },
                        {
                            "id": "510183",
                            "code": "510183",
                            "name": "邛崃市",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510183",
                            "fullNames": "四川省,成都市,邛崃市",
                            "children": []
                        },
                        {
                            "id": "510184",
                            "code": "510184",
                            "name": "崇州市",
                            "parentCode": "510100",
                            "fullCodes": "510000,510100,510184",
                            "fullNames": "四川省,成都市,崇州市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "510300",
                    "code": "510300",
                    "name": "自贡市",
                    "parentCode": "510000",
                    "fullCodes": "510000,510300",
                    "fullNames": "四川省,自贡市",
                    "children": [
                        {
                            "id": "510302",
                            "code": "510302",
                            "name": "自流井区",
                            "parentCode": "510300",
                            "fullCodes": "510000,510300,510302",
                            "fullNames": "四川省,自贡市,自流井区",
                            "children": []
                        },
                        {
                            "id": "510303",
                            "code": "510303",
                            "name": "贡井区",
                            "parentCode": "510300",
                            "fullCodes": "510000,510300,510303",
                            "fullNames": "四川省,自贡市,贡井区",
                            "children": []
                        },
                        {
                            "id": "510304",
                            "code": "510304",
                            "name": "大安区",
                            "parentCode": "510300",
                            "fullCodes": "510000,510300,510304",
                            "fullNames": "四川省,自贡市,大安区",
                            "children": []
                        },
                        {
                            "id": "510311",
                            "code": "510311",
                            "name": "沿滩区",
                            "parentCode": "510300",
                            "fullCodes": "510000,510300,510311",
                            "fullNames": "四川省,自贡市,沿滩区",
                            "children": []
                        },
                        {
                            "id": "510321",
                            "code": "510321",
                            "name": "荣县",
                            "parentCode": "510300",
                            "fullCodes": "510000,510300,510321",
                            "fullNames": "四川省,自贡市,荣县",
                            "children": []
                        },
                        {
                            "id": "510322",
                            "code": "510322",
                            "name": "富顺县",
                            "parentCode": "510300",
                            "fullCodes": "510000,510300,510322",
                            "fullNames": "四川省,自贡市,富顺县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "510400",
                    "code": "510400",
                    "name": "攀枝花市",
                    "parentCode": "510000",
                    "fullCodes": "510000,510400",
                    "fullNames": "四川省,攀枝花市",
                    "children": [
                        {
                            "id": "510402",
                            "code": "510402",
                            "name": "东区",
                            "parentCode": "510400",
                            "fullCodes": "510000,510400,510402",
                            "fullNames": "四川省,攀枝花市,东区",
                            "children": []
                        },
                        {
                            "id": "510403",
                            "code": "510403",
                            "name": "西区",
                            "parentCode": "510400",
                            "fullCodes": "510000,510400,510403",
                            "fullNames": "四川省,攀枝花市,西区",
                            "children": []
                        },
                        {
                            "id": "510411",
                            "code": "510411",
                            "name": "仁和区",
                            "parentCode": "510400",
                            "fullCodes": "510000,510400,510411",
                            "fullNames": "四川省,攀枝花市,仁和区",
                            "children": []
                        },
                        {
                            "id": "510421",
                            "code": "510421",
                            "name": "米易县",
                            "parentCode": "510400",
                            "fullCodes": "510000,510400,510421",
                            "fullNames": "四川省,攀枝花市,米易县",
                            "children": []
                        },
                        {
                            "id": "510422",
                            "code": "510422",
                            "name": "盐边县",
                            "parentCode": "510400",
                            "fullCodes": "510000,510400,510422",
                            "fullNames": "四川省,攀枝花市,盐边县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "510500",
                    "code": "510500",
                    "name": "泸州市",
                    "parentCode": "510000",
                    "fullCodes": "510000,510500",
                    "fullNames": "四川省,泸州市",
                    "children": [
                        {
                            "id": "510502",
                            "code": "510502",
                            "name": "江阳区",
                            "parentCode": "510500",
                            "fullCodes": "510000,510500,510502",
                            "fullNames": "四川省,泸州市,江阳区",
                            "children": []
                        },
                        {
                            "id": "510503",
                            "code": "510503",
                            "name": "纳溪区",
                            "parentCode": "510500",
                            "fullCodes": "510000,510500,510503",
                            "fullNames": "四川省,泸州市,纳溪区",
                            "children": []
                        },
                        {
                            "id": "510504",
                            "code": "510504",
                            "name": "龙马潭区",
                            "parentCode": "510500",
                            "fullCodes": "510000,510500,510504",
                            "fullNames": "四川省,泸州市,龙马潭区",
                            "children": []
                        },
                        {
                            "id": "510521",
                            "code": "510521",
                            "name": "泸县",
                            "parentCode": "510500",
                            "fullCodes": "510000,510500,510521",
                            "fullNames": "四川省,泸州市,泸县",
                            "children": []
                        },
                        {
                            "id": "510522",
                            "code": "510522",
                            "name": "合江县",
                            "parentCode": "510500",
                            "fullCodes": "510000,510500,510522",
                            "fullNames": "四川省,泸州市,合江县",
                            "children": []
                        },
                        {
                            "id": "510524",
                            "code": "510524",
                            "name": "叙永县",
                            "parentCode": "510500",
                            "fullCodes": "510000,510500,510524",
                            "fullNames": "四川省,泸州市,叙永县",
                            "children": []
                        },
                        {
                            "id": "510525",
                            "code": "510525",
                            "name": "古蔺县",
                            "parentCode": "510500",
                            "fullCodes": "510000,510500,510525",
                            "fullNames": "四川省,泸州市,古蔺县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "510600",
                    "code": "510600",
                    "name": "德阳市",
                    "parentCode": "510000",
                    "fullCodes": "510000,510600",
                    "fullNames": "四川省,德阳市",
                    "children": [
                        {
                            "id": "510603",
                            "code": "510603",
                            "name": "旌阳区",
                            "parentCode": "510600",
                            "fullCodes": "510000,510600,510603",
                            "fullNames": "四川省,德阳市,旌阳区",
                            "children": []
                        },
                        {
                            "id": "510623",
                            "code": "510623",
                            "name": "中江县",
                            "parentCode": "510600",
                            "fullCodes": "510000,510600,510623",
                            "fullNames": "四川省,德阳市,中江县",
                            "children": []
                        },
                        {
                            "id": "510626",
                            "code": "510626",
                            "name": "罗江县",
                            "parentCode": "510600",
                            "fullCodes": "510000,510600,510626",
                            "fullNames": "四川省,德阳市,罗江县",
                            "children": []
                        },
                        {
                            "id": "510681",
                            "code": "510681",
                            "name": "广汉市",
                            "parentCode": "510600",
                            "fullCodes": "510000,510600,510681",
                            "fullNames": "四川省,德阳市,广汉市",
                            "children": []
                        },
                        {
                            "id": "510682",
                            "code": "510682",
                            "name": "什邡市",
                            "parentCode": "510600",
                            "fullCodes": "510000,510600,510682",
                            "fullNames": "四川省,德阳市,什邡市",
                            "children": []
                        },
                        {
                            "id": "510683",
                            "code": "510683",
                            "name": "绵竹市",
                            "parentCode": "510600",
                            "fullCodes": "510000,510600,510683",
                            "fullNames": "四川省,德阳市,绵竹市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "510700",
                    "code": "510700",
                    "name": "绵阳市",
                    "parentCode": "510000",
                    "fullCodes": "510000,510700",
                    "fullNames": "四川省,绵阳市",
                    "children": [
                        {
                            "id": "510703",
                            "code": "510703",
                            "name": "涪城区",
                            "parentCode": "510700",
                            "fullCodes": "510000,510700,510703",
                            "fullNames": "四川省,绵阳市,涪城区",
                            "children": []
                        },
                        {
                            "id": "510704",
                            "code": "510704",
                            "name": "游仙区",
                            "parentCode": "510700",
                            "fullCodes": "510000,510700,510704",
                            "fullNames": "四川省,绵阳市,游仙区",
                            "children": []
                        },
                        {
                            "id": "510722",
                            "code": "510722",
                            "name": "三台县",
                            "parentCode": "510700",
                            "fullCodes": "510000,510700,510722",
                            "fullNames": "四川省,绵阳市,三台县",
                            "children": []
                        },
                        {
                            "id": "510723",
                            "code": "510723",
                            "name": "盐亭县",
                            "parentCode": "510700",
                            "fullCodes": "510000,510700,510723",
                            "fullNames": "四川省,绵阳市,盐亭县",
                            "children": []
                        },
                        {
                            "id": "510724",
                            "code": "510724",
                            "name": "安县",
                            "parentCode": "510700",
                            "fullCodes": "510000,510700,510724",
                            "fullNames": "四川省,绵阳市,安县",
                            "children": []
                        },
                        {
                            "id": "510725",
                            "code": "510725",
                            "name": "梓潼县",
                            "parentCode": "510700",
                            "fullCodes": "510000,510700,510725",
                            "fullNames": "四川省,绵阳市,梓潼县",
                            "children": []
                        },
                        {
                            "id": "510726",
                            "code": "510726",
                            "name": "北川羌族自治县",
                            "parentCode": "510700",
                            "fullCodes": "510000,510700,510726",
                            "fullNames": "四川省,绵阳市,北川羌族自治县",
                            "children": []
                        },
                        {
                            "id": "510727",
                            "code": "510727",
                            "name": "平武县",
                            "parentCode": "510700",
                            "fullCodes": "510000,510700,510727",
                            "fullNames": "四川省,绵阳市,平武县",
                            "children": []
                        },
                        {
                            "id": "510751",
                            "code": "510751",
                            "name": "高新区",
                            "parentCode": "510700",
                            "fullCodes": "510000,510700,510751",
                            "fullNames": "四川省,绵阳市,高新区",
                            "children": []
                        },
                        {
                            "id": "510781",
                            "code": "510781",
                            "name": "江油市",
                            "parentCode": "510700",
                            "fullCodes": "510000,510700,510781",
                            "fullNames": "四川省,绵阳市,江油市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "510800",
                    "code": "510800",
                    "name": "广元市",
                    "parentCode": "510000",
                    "fullCodes": "510000,510800",
                    "fullNames": "四川省,广元市",
                    "children": [
                        {
                            "id": "510802",
                            "code": "510802",
                            "name": "利州区",
                            "parentCode": "510800",
                            "fullCodes": "510000,510800,510802",
                            "fullNames": "四川省,广元市,利州区",
                            "children": []
                        },
                        {
                            "id": "510811",
                            "code": "510811",
                            "name": "元坝区",
                            "parentCode": "510800",
                            "fullCodes": "510000,510800,510811",
                            "fullNames": "四川省,广元市,元坝区",
                            "children": []
                        },
                        {
                            "id": "510812",
                            "code": "510812",
                            "name": "朝天区",
                            "parentCode": "510800",
                            "fullCodes": "510000,510800,510812",
                            "fullNames": "四川省,广元市,朝天区",
                            "children": []
                        },
                        {
                            "id": "510821",
                            "code": "510821",
                            "name": "旺苍县",
                            "parentCode": "510800",
                            "fullCodes": "510000,510800,510821",
                            "fullNames": "四川省,广元市,旺苍县",
                            "children": []
                        },
                        {
                            "id": "510822",
                            "code": "510822",
                            "name": "青川县",
                            "parentCode": "510800",
                            "fullCodes": "510000,510800,510822",
                            "fullNames": "四川省,广元市,青川县",
                            "children": []
                        },
                        {
                            "id": "510823",
                            "code": "510823",
                            "name": "剑阁县",
                            "parentCode": "510800",
                            "fullCodes": "510000,510800,510823",
                            "fullNames": "四川省,广元市,剑阁县",
                            "children": []
                        },
                        {
                            "id": "510824",
                            "code": "510824",
                            "name": "苍溪县",
                            "parentCode": "510800",
                            "fullCodes": "510000,510800,510824",
                            "fullNames": "四川省,广元市,苍溪县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "510900",
                    "code": "510900",
                    "name": "遂宁市",
                    "parentCode": "510000",
                    "fullCodes": "510000,510900",
                    "fullNames": "四川省,遂宁市",
                    "children": [
                        {
                            "id": "510903",
                            "code": "510903",
                            "name": "船山区",
                            "parentCode": "510900",
                            "fullCodes": "510000,510900,510903",
                            "fullNames": "四川省,遂宁市,船山区",
                            "children": []
                        },
                        {
                            "id": "510904",
                            "code": "510904",
                            "name": "安居区",
                            "parentCode": "510900",
                            "fullCodes": "510000,510900,510904",
                            "fullNames": "四川省,遂宁市,安居区",
                            "children": []
                        },
                        {
                            "id": "510921",
                            "code": "510921",
                            "name": "蓬溪县",
                            "parentCode": "510900",
                            "fullCodes": "510000,510900,510921",
                            "fullNames": "四川省,遂宁市,蓬溪县",
                            "children": []
                        },
                        {
                            "id": "510922",
                            "code": "510922",
                            "name": "射洪县",
                            "parentCode": "510900",
                            "fullCodes": "510000,510900,510922",
                            "fullNames": "四川省,遂宁市,射洪县",
                            "children": []
                        },
                        {
                            "id": "510923",
                            "code": "510923",
                            "name": "大英县",
                            "parentCode": "510900",
                            "fullCodes": "510000,510900,510923",
                            "fullNames": "四川省,遂宁市,大英县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "511000",
                    "code": "511000",
                    "name": "内江市",
                    "parentCode": "510000",
                    "fullCodes": "510000,511000",
                    "fullNames": "四川省,内江市",
                    "children": [
                        {
                            "id": "511002",
                            "code": "511002",
                            "name": "市中区",
                            "parentCode": "511000",
                            "fullCodes": "510000,511000,511002",
                            "fullNames": "四川省,内江市,市中区",
                            "children": []
                        },
                        {
                            "id": "511011",
                            "code": "511011",
                            "name": "东兴区",
                            "parentCode": "511000",
                            "fullCodes": "510000,511000,511011",
                            "fullNames": "四川省,内江市,东兴区",
                            "children": []
                        },
                        {
                            "id": "511024",
                            "code": "511024",
                            "name": "威远县",
                            "parentCode": "511000",
                            "fullCodes": "510000,511000,511024",
                            "fullNames": "四川省,内江市,威远县",
                            "children": []
                        },
                        {
                            "id": "511025",
                            "code": "511025",
                            "name": "资中县",
                            "parentCode": "511000",
                            "fullCodes": "510000,511000,511025",
                            "fullNames": "四川省,内江市,资中县",
                            "children": []
                        },
                        {
                            "id": "511028",
                            "code": "511028",
                            "name": "隆昌县",
                            "parentCode": "511000",
                            "fullCodes": "510000,511000,511028",
                            "fullNames": "四川省,内江市,隆昌县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "511100",
                    "code": "511100",
                    "name": "乐山市",
                    "parentCode": "510000",
                    "fullCodes": "510000,511100",
                    "fullNames": "四川省,乐山市",
                    "children": [
                        {
                            "id": "511102",
                            "code": "511102",
                            "name": "市中区",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511102",
                            "fullNames": "四川省,乐山市,市中区",
                            "children": []
                        },
                        {
                            "id": "511111",
                            "code": "511111",
                            "name": "沙湾区",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511111",
                            "fullNames": "四川省,乐山市,沙湾区",
                            "children": []
                        },
                        {
                            "id": "511112",
                            "code": "511112",
                            "name": "五通桥区",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511112",
                            "fullNames": "四川省,乐山市,五通桥区",
                            "children": []
                        },
                        {
                            "id": "511113",
                            "code": "511113",
                            "name": "金口河区",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511113",
                            "fullNames": "四川省,乐山市,金口河区",
                            "children": []
                        },
                        {
                            "id": "511123",
                            "code": "511123",
                            "name": "犍为县",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511123",
                            "fullNames": "四川省,乐山市,犍为县",
                            "children": []
                        },
                        {
                            "id": "511124",
                            "code": "511124",
                            "name": "井研县",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511124",
                            "fullNames": "四川省,乐山市,井研县",
                            "children": []
                        },
                        {
                            "id": "511126",
                            "code": "511126",
                            "name": "夹江县",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511126",
                            "fullNames": "四川省,乐山市,夹江县",
                            "children": []
                        },
                        {
                            "id": "511129",
                            "code": "511129",
                            "name": "沐川县",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511129",
                            "fullNames": "四川省,乐山市,沐川县",
                            "children": []
                        },
                        {
                            "id": "511132",
                            "code": "511132",
                            "name": "峨边彝族自治县",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511132",
                            "fullNames": "四川省,乐山市,峨边彝族自治县",
                            "children": []
                        },
                        {
                            "id": "511133",
                            "code": "511133",
                            "name": "马边彝族自治县",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511133",
                            "fullNames": "四川省,乐山市,马边彝族自治县",
                            "children": []
                        },
                        {
                            "id": "511181",
                            "code": "511181",
                            "name": "峨眉山市",
                            "parentCode": "511100",
                            "fullCodes": "510000,511100,511181",
                            "fullNames": "四川省,乐山市,峨眉山市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "511300",
                    "code": "511300",
                    "name": "南充市",
                    "parentCode": "510000",
                    "fullCodes": "510000,511300",
                    "fullNames": "四川省,南充市",
                    "children": [
                        {
                            "id": "511302",
                            "code": "511302",
                            "name": "顺庆区",
                            "parentCode": "511300",
                            "fullCodes": "510000,511300,511302",
                            "fullNames": "四川省,南充市,顺庆区",
                            "children": []
                        },
                        {
                            "id": "511303",
                            "code": "511303",
                            "name": "高坪区",
                            "parentCode": "511300",
                            "fullCodes": "510000,511300,511303",
                            "fullNames": "四川省,南充市,高坪区",
                            "children": []
                        },
                        {
                            "id": "511304",
                            "code": "511304",
                            "name": "嘉陵区",
                            "parentCode": "511300",
                            "fullCodes": "510000,511300,511304",
                            "fullNames": "四川省,南充市,嘉陵区",
                            "children": []
                        },
                        {
                            "id": "511321",
                            "code": "511321",
                            "name": "南部县",
                            "parentCode": "511300",
                            "fullCodes": "510000,511300,511321",
                            "fullNames": "四川省,南充市,南部县",
                            "children": []
                        },
                        {
                            "id": "511322",
                            "code": "511322",
                            "name": "营山县",
                            "parentCode": "511300",
                            "fullCodes": "510000,511300,511322",
                            "fullNames": "四川省,南充市,营山县",
                            "children": []
                        },
                        {
                            "id": "511323",
                            "code": "511323",
                            "name": "蓬安县",
                            "parentCode": "511300",
                            "fullCodes": "510000,511300,511323",
                            "fullNames": "四川省,南充市,蓬安县",
                            "children": []
                        },
                        {
                            "id": "511324",
                            "code": "511324",
                            "name": "仪陇县",
                            "parentCode": "511300",
                            "fullCodes": "510000,511300,511324",
                            "fullNames": "四川省,南充市,仪陇县",
                            "children": []
                        },
                        {
                            "id": "511325",
                            "code": "511325",
                            "name": "西充县",
                            "parentCode": "511300",
                            "fullCodes": "510000,511300,511325",
                            "fullNames": "四川省,南充市,西充县",
                            "children": []
                        },
                        {
                            "id": "511381",
                            "code": "511381",
                            "name": "阆中市",
                            "parentCode": "511300",
                            "fullCodes": "510000,511300,511381",
                            "fullNames": "四川省,南充市,阆中市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "511400",
                    "code": "511400",
                    "name": "眉山市",
                    "parentCode": "510000",
                    "fullCodes": "510000,511400",
                    "fullNames": "四川省,眉山市",
                    "children": [
                        {
                            "id": "511402",
                            "code": "511402",
                            "name": "东坡区",
                            "parentCode": "511400",
                            "fullCodes": "510000,511400,511402",
                            "fullNames": "四川省,眉山市,东坡区",
                            "children": []
                        },
                        {
                            "id": "511421",
                            "code": "511421",
                            "name": "仁寿县",
                            "parentCode": "511400",
                            "fullCodes": "510000,511400,511421",
                            "fullNames": "四川省,眉山市,仁寿县",
                            "children": []
                        },
                        {
                            "id": "511422",
                            "code": "511422",
                            "name": "彭山县",
                            "parentCode": "511400",
                            "fullCodes": "510000,511400,511422",
                            "fullNames": "四川省,眉山市,彭山县",
                            "children": []
                        },
                        {
                            "id": "511423",
                            "code": "511423",
                            "name": "洪雅县",
                            "parentCode": "511400",
                            "fullCodes": "510000,511400,511423",
                            "fullNames": "四川省,眉山市,洪雅县",
                            "children": []
                        },
                        {
                            "id": "511424",
                            "code": "511424",
                            "name": "丹棱县",
                            "parentCode": "511400",
                            "fullCodes": "510000,511400,511424",
                            "fullNames": "四川省,眉山市,丹棱县",
                            "children": []
                        },
                        {
                            "id": "511425",
                            "code": "511425",
                            "name": "青神县",
                            "parentCode": "511400",
                            "fullCodes": "510000,511400,511425",
                            "fullNames": "四川省,眉山市,青神县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "511500",
                    "code": "511500",
                    "name": "宜宾市",
                    "parentCode": "510000",
                    "fullCodes": "510000,511500",
                    "fullNames": "四川省,宜宾市",
                    "children": [
                        {
                            "id": "511502",
                            "code": "511502",
                            "name": "翠屏区",
                            "parentCode": "511500",
                            "fullCodes": "510000,511500,511502",
                            "fullNames": "四川省,宜宾市,翠屏区",
                            "children": []
                        },
                        {
                            "id": "511521",
                            "code": "511521",
                            "name": "宜宾县",
                            "parentCode": "511500",
                            "fullCodes": "510000,511500,511521",
                            "fullNames": "四川省,宜宾市,宜宾县",
                            "children": []
                        },
                        {
                            "id": "511522",
                            "code": "511522",
                            "name": "南溪县",
                            "parentCode": "511500",
                            "fullCodes": "510000,511500,511522",
                            "fullNames": "四川省,宜宾市,南溪县",
                            "children": []
                        },
                        {
                            "id": "511523",
                            "code": "511523",
                            "name": "江安县",
                            "parentCode": "511500",
                            "fullCodes": "510000,511500,511523",
                            "fullNames": "四川省,宜宾市,江安县",
                            "children": []
                        },
                        {
                            "id": "511524",
                            "code": "511524",
                            "name": "长宁县",
                            "parentCode": "511500",
                            "fullCodes": "510000,511500,511524",
                            "fullNames": "四川省,宜宾市,长宁县",
                            "children": []
                        },
                        {
                            "id": "511525",
                            "code": "511525",
                            "name": "高县",
                            "parentCode": "511500",
                            "fullCodes": "510000,511500,511525",
                            "fullNames": "四川省,宜宾市,高县",
                            "children": []
                        },
                        {
                            "id": "511526",
                            "code": "511526",
                            "name": "珙县",
                            "parentCode": "511500",
                            "fullCodes": "510000,511500,511526",
                            "fullNames": "四川省,宜宾市,珙县",
                            "children": []
                        },
                        {
                            "id": "511527",
                            "code": "511527",
                            "name": "筠连县",
                            "parentCode": "511500",
                            "fullCodes": "510000,511500,511527",
                            "fullNames": "四川省,宜宾市,筠连县",
                            "children": []
                        },
                        {
                            "id": "511528",
                            "code": "511528",
                            "name": "兴文县",
                            "parentCode": "511500",
                            "fullCodes": "510000,511500,511528",
                            "fullNames": "四川省,宜宾市,兴文县",
                            "children": []
                        },
                        {
                            "id": "511529",
                            "code": "511529",
                            "name": "屏山县",
                            "parentCode": "511500",
                            "fullCodes": "510000,511500,511529",
                            "fullNames": "四川省,宜宾市,屏山县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "511600",
                    "code": "511600",
                    "name": "广安市",
                    "parentCode": "510000",
                    "fullCodes": "510000,511600",
                    "fullNames": "四川省,广安市",
                    "children": [
                        {
                            "id": "511602",
                            "code": "511602",
                            "name": "广安区",
                            "parentCode": "511600",
                            "fullCodes": "510000,511600,511602",
                            "fullNames": "四川省,广安市,广安区",
                            "children": []
                        },
                        {
                            "id": "511621",
                            "code": "511621",
                            "name": "岳池县",
                            "parentCode": "511600",
                            "fullCodes": "510000,511600,511621",
                            "fullNames": "四川省,广安市,岳池县",
                            "children": []
                        },
                        {
                            "id": "511622",
                            "code": "511622",
                            "name": "武胜县",
                            "parentCode": "511600",
                            "fullCodes": "510000,511600,511622",
                            "fullNames": "四川省,广安市,武胜县",
                            "children": []
                        },
                        {
                            "id": "511623",
                            "code": "511623",
                            "name": "邻水县",
                            "parentCode": "511600",
                            "fullCodes": "510000,511600,511623",
                            "fullNames": "四川省,广安市,邻水县",
                            "children": []
                        },
                        {
                            "id": "511681",
                            "code": "511681",
                            "name": "华蓥市",
                            "parentCode": "511600",
                            "fullCodes": "510000,511600,511681",
                            "fullNames": "四川省,广安市,华蓥市",
                            "children": []
                        },
                        {
                            "id": "511682",
                            "code": "511682",
                            "name": "市辖区",
                            "parentCode": "511600",
                            "fullCodes": "510000,511600,511682",
                            "fullNames": "四川省,广安市,市辖区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "511700",
                    "code": "511700",
                    "name": "达州市",
                    "parentCode": "510000",
                    "fullCodes": "510000,511700",
                    "fullNames": "四川省,达州市",
                    "children": [
                        {
                            "id": "511702",
                            "code": "511702",
                            "name": "通川区",
                            "parentCode": "511700",
                            "fullCodes": "510000,511700,511702",
                            "fullNames": "四川省,达州市,通川区",
                            "children": []
                        },
                        {
                            "id": "511721",
                            "code": "511721",
                            "name": "达县",
                            "parentCode": "511700",
                            "fullCodes": "510000,511700,511721",
                            "fullNames": "四川省,达州市,达县",
                            "children": []
                        },
                        {
                            "id": "511722",
                            "code": "511722",
                            "name": "宣汉县",
                            "parentCode": "511700",
                            "fullCodes": "510000,511700,511722",
                            "fullNames": "四川省,达州市,宣汉县",
                            "children": []
                        },
                        {
                            "id": "511723",
                            "code": "511723",
                            "name": "开江县",
                            "parentCode": "511700",
                            "fullCodes": "510000,511700,511723",
                            "fullNames": "四川省,达州市,开江县",
                            "children": []
                        },
                        {
                            "id": "511724",
                            "code": "511724",
                            "name": "大竹县",
                            "parentCode": "511700",
                            "fullCodes": "510000,511700,511724",
                            "fullNames": "四川省,达州市,大竹县",
                            "children": []
                        },
                        {
                            "id": "511725",
                            "code": "511725",
                            "name": "渠县",
                            "parentCode": "511700",
                            "fullCodes": "510000,511700,511725",
                            "fullNames": "四川省,达州市,渠县",
                            "children": []
                        },
                        {
                            "id": "511781",
                            "code": "511781",
                            "name": "万源市",
                            "parentCode": "511700",
                            "fullCodes": "510000,511700,511781",
                            "fullNames": "四川省,达州市,万源市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "511800",
                    "code": "511800",
                    "name": "雅安市",
                    "parentCode": "510000",
                    "fullCodes": "510000,511800",
                    "fullNames": "四川省,雅安市",
                    "children": [
                        {
                            "id": "511802",
                            "code": "511802",
                            "name": "雨城区",
                            "parentCode": "511800",
                            "fullCodes": "510000,511800,511802",
                            "fullNames": "四川省,雅安市,雨城区",
                            "children": []
                        },
                        {
                            "id": "511821",
                            "code": "511821",
                            "name": "名山县",
                            "parentCode": "511800",
                            "fullCodes": "510000,511800,511821",
                            "fullNames": "四川省,雅安市,名山县",
                            "children": []
                        },
                        {
                            "id": "511822",
                            "code": "511822",
                            "name": "荥经县",
                            "parentCode": "511800",
                            "fullCodes": "510000,511800,511822",
                            "fullNames": "四川省,雅安市,荥经县",
                            "children": []
                        },
                        {
                            "id": "511823",
                            "code": "511823",
                            "name": "汉源县",
                            "parentCode": "511800",
                            "fullCodes": "510000,511800,511823",
                            "fullNames": "四川省,雅安市,汉源县",
                            "children": []
                        },
                        {
                            "id": "511824",
                            "code": "511824",
                            "name": "石棉县",
                            "parentCode": "511800",
                            "fullCodes": "510000,511800,511824",
                            "fullNames": "四川省,雅安市,石棉县",
                            "children": []
                        },
                        {
                            "id": "511825",
                            "code": "511825",
                            "name": "天全县",
                            "parentCode": "511800",
                            "fullCodes": "510000,511800,511825",
                            "fullNames": "四川省,雅安市,天全县",
                            "children": []
                        },
                        {
                            "id": "511826",
                            "code": "511826",
                            "name": "芦山县",
                            "parentCode": "511800",
                            "fullCodes": "510000,511800,511826",
                            "fullNames": "四川省,雅安市,芦山县",
                            "children": []
                        },
                        {
                            "id": "511827",
                            "code": "511827",
                            "name": "宝兴县",
                            "parentCode": "511800",
                            "fullCodes": "510000,511800,511827",
                            "fullNames": "四川省,雅安市,宝兴县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "511900",
                    "code": "511900",
                    "name": "巴中市",
                    "parentCode": "510000",
                    "fullCodes": "510000,511900",
                    "fullNames": "四川省,巴中市",
                    "children": [
                        {
                            "id": "511902",
                            "code": "511902",
                            "name": "巴州区",
                            "parentCode": "511900",
                            "fullCodes": "510000,511900,511902",
                            "fullNames": "四川省,巴中市,巴州区",
                            "children": []
                        },
                        {
                            "id": "511921",
                            "code": "511921",
                            "name": "通江县",
                            "parentCode": "511900",
                            "fullCodes": "510000,511900,511921",
                            "fullNames": "四川省,巴中市,通江县",
                            "children": []
                        },
                        {
                            "id": "511922",
                            "code": "511922",
                            "name": "南江县",
                            "parentCode": "511900",
                            "fullCodes": "510000,511900,511922",
                            "fullNames": "四川省,巴中市,南江县",
                            "children": []
                        },
                        {
                            "id": "511923",
                            "code": "511923",
                            "name": "平昌县",
                            "parentCode": "511900",
                            "fullCodes": "510000,511900,511923",
                            "fullNames": "四川省,巴中市,平昌县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "512000",
                    "code": "512000",
                    "name": "资阳市",
                    "parentCode": "510000",
                    "fullCodes": "510000,512000",
                    "fullNames": "四川省,资阳市",
                    "children": [
                        {
                            "id": "512002",
                            "code": "512002",
                            "name": "雁江区",
                            "parentCode": "512000",
                            "fullCodes": "510000,512000,512002",
                            "fullNames": "四川省,资阳市,雁江区",
                            "children": []
                        },
                        {
                            "id": "512021",
                            "code": "512021",
                            "name": "安岳县",
                            "parentCode": "512000",
                            "fullCodes": "510000,512000,512021",
                            "fullNames": "四川省,资阳市,安岳县",
                            "children": []
                        },
                        {
                            "id": "512022",
                            "code": "512022",
                            "name": "乐至县",
                            "parentCode": "512000",
                            "fullCodes": "510000,512000,512022",
                            "fullNames": "四川省,资阳市,乐至县",
                            "children": []
                        },
                        {
                            "id": "512081",
                            "code": "512081",
                            "name": "简阳市",
                            "parentCode": "512000",
                            "fullCodes": "510000,512000,512081",
                            "fullNames": "四川省,资阳市,简阳市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "513200",
                    "code": "513200",
                    "name": "阿坝藏族羌族自治州",
                    "parentCode": "510000",
                    "fullCodes": "510000,513200",
                    "fullNames": "四川省,阿坝藏族羌族自治州",
                    "children": [
                        {
                            "id": "513221",
                            "code": "513221",
                            "name": "汶川县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513221",
                            "fullNames": "四川省,阿坝藏族羌族自治州,汶川县",
                            "children": []
                        },
                        {
                            "id": "513222",
                            "code": "513222",
                            "name": "理县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513222",
                            "fullNames": "四川省,阿坝藏族羌族自治州,理县",
                            "children": []
                        },
                        {
                            "id": "513223",
                            "code": "513223",
                            "name": "茂县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513223",
                            "fullNames": "四川省,阿坝藏族羌族自治州,茂县",
                            "children": []
                        },
                        {
                            "id": "513224",
                            "code": "513224",
                            "name": "松潘县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513224",
                            "fullNames": "四川省,阿坝藏族羌族自治州,松潘县",
                            "children": []
                        },
                        {
                            "id": "513225",
                            "code": "513225",
                            "name": "九寨沟县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513225",
                            "fullNames": "四川省,阿坝藏族羌族自治州,九寨沟县",
                            "children": []
                        },
                        {
                            "id": "513226",
                            "code": "513226",
                            "name": "金川县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513226",
                            "fullNames": "四川省,阿坝藏族羌族自治州,金川县",
                            "children": []
                        },
                        {
                            "id": "513227",
                            "code": "513227",
                            "name": "小金县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513227",
                            "fullNames": "四川省,阿坝藏族羌族自治州,小金县",
                            "children": []
                        },
                        {
                            "id": "513228",
                            "code": "513228",
                            "name": "黑水县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513228",
                            "fullNames": "四川省,阿坝藏族羌族自治州,黑水县",
                            "children": []
                        },
                        {
                            "id": "513229",
                            "code": "513229",
                            "name": "马尔康县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513229",
                            "fullNames": "四川省,阿坝藏族羌族自治州,马尔康县",
                            "children": []
                        },
                        {
                            "id": "513230",
                            "code": "513230",
                            "name": "壤塘县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513230",
                            "fullNames": "四川省,阿坝藏族羌族自治州,壤塘县",
                            "children": []
                        },
                        {
                            "id": "513231",
                            "code": "513231",
                            "name": "阿坝县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513231",
                            "fullNames": "四川省,阿坝藏族羌族自治州,阿坝县",
                            "children": []
                        },
                        {
                            "id": "513232",
                            "code": "513232",
                            "name": "若尔盖县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513232",
                            "fullNames": "四川省,阿坝藏族羌族自治州,若尔盖县",
                            "children": []
                        },
                        {
                            "id": "513233",
                            "code": "513233",
                            "name": "红原县",
                            "parentCode": "513200",
                            "fullCodes": "510000,513200,513233",
                            "fullNames": "四川省,阿坝藏族羌族自治州,红原县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "513300",
                    "code": "513300",
                    "name": "甘孜藏族自治州",
                    "parentCode": "510000",
                    "fullCodes": "510000,513300",
                    "fullNames": "四川省,甘孜藏族自治州",
                    "children": [
                        {
                            "id": "513321",
                            "code": "513321",
                            "name": "康定县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513321",
                            "fullNames": "四川省,甘孜藏族自治州,康定县",
                            "children": []
                        },
                        {
                            "id": "513322",
                            "code": "513322",
                            "name": "泸定县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513322",
                            "fullNames": "四川省,甘孜藏族自治州,泸定县",
                            "children": []
                        },
                        {
                            "id": "513323",
                            "code": "513323",
                            "name": "丹巴县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513323",
                            "fullNames": "四川省,甘孜藏族自治州,丹巴县",
                            "children": []
                        },
                        {
                            "id": "513324",
                            "code": "513324",
                            "name": "九龙县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513324",
                            "fullNames": "四川省,甘孜藏族自治州,九龙县",
                            "children": []
                        },
                        {
                            "id": "513325",
                            "code": "513325",
                            "name": "雅江县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513325",
                            "fullNames": "四川省,甘孜藏族自治州,雅江县",
                            "children": []
                        },
                        {
                            "id": "513326",
                            "code": "513326",
                            "name": "道孚县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513326",
                            "fullNames": "四川省,甘孜藏族自治州,道孚县",
                            "children": []
                        },
                        {
                            "id": "513327",
                            "code": "513327",
                            "name": "炉霍县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513327",
                            "fullNames": "四川省,甘孜藏族自治州,炉霍县",
                            "children": []
                        },
                        {
                            "id": "513328",
                            "code": "513328",
                            "name": "甘孜县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513328",
                            "fullNames": "四川省,甘孜藏族自治州,甘孜县",
                            "children": []
                        },
                        {
                            "id": "513329",
                            "code": "513329",
                            "name": "新龙县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513329",
                            "fullNames": "四川省,甘孜藏族自治州,新龙县",
                            "children": []
                        },
                        {
                            "id": "513330",
                            "code": "513330",
                            "name": "德格县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513330",
                            "fullNames": "四川省,甘孜藏族自治州,德格县",
                            "children": []
                        },
                        {
                            "id": "513331",
                            "code": "513331",
                            "name": "白玉县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513331",
                            "fullNames": "四川省,甘孜藏族自治州,白玉县",
                            "children": []
                        },
                        {
                            "id": "513332",
                            "code": "513332",
                            "name": "石渠县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513332",
                            "fullNames": "四川省,甘孜藏族自治州,石渠县",
                            "children": []
                        },
                        {
                            "id": "513333",
                            "code": "513333",
                            "name": "色达县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513333",
                            "fullNames": "四川省,甘孜藏族自治州,色达县",
                            "children": []
                        },
                        {
                            "id": "513334",
                            "code": "513334",
                            "name": "理塘县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513334",
                            "fullNames": "四川省,甘孜藏族自治州,理塘县",
                            "children": []
                        },
                        {
                            "id": "513335",
                            "code": "513335",
                            "name": "巴塘县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513335",
                            "fullNames": "四川省,甘孜藏族自治州,巴塘县",
                            "children": []
                        },
                        {
                            "id": "513336",
                            "code": "513336",
                            "name": "乡城县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513336",
                            "fullNames": "四川省,甘孜藏族自治州,乡城县",
                            "children": []
                        },
                        {
                            "id": "513337",
                            "code": "513337",
                            "name": "稻城县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513337",
                            "fullNames": "四川省,甘孜藏族自治州,稻城县",
                            "children": []
                        },
                        {
                            "id": "513338",
                            "code": "513338",
                            "name": "得荣县",
                            "parentCode": "513300",
                            "fullCodes": "510000,513300,513338",
                            "fullNames": "四川省,甘孜藏族自治州,得荣县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "513400",
                    "code": "513400",
                    "name": "凉山彝族自治州",
                    "parentCode": "510000",
                    "fullCodes": "510000,513400",
                    "fullNames": "四川省,凉山彝族自治州",
                    "children": [
                        {
                            "id": "513401",
                            "code": "513401",
                            "name": "西昌市",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513401",
                            "fullNames": "四川省,凉山彝族自治州,西昌市",
                            "children": []
                        },
                        {
                            "id": "513422",
                            "code": "513422",
                            "name": "木里藏族自治县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513422",
                            "fullNames": "四川省,凉山彝族自治州,木里藏族自治县",
                            "children": []
                        },
                        {
                            "id": "513423",
                            "code": "513423",
                            "name": "盐源县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513423",
                            "fullNames": "四川省,凉山彝族自治州,盐源县",
                            "children": []
                        },
                        {
                            "id": "513424",
                            "code": "513424",
                            "name": "德昌县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513424",
                            "fullNames": "四川省,凉山彝族自治州,德昌县",
                            "children": []
                        },
                        {
                            "id": "513425",
                            "code": "513425",
                            "name": "会理县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513425",
                            "fullNames": "四川省,凉山彝族自治州,会理县",
                            "children": []
                        },
                        {
                            "id": "513426",
                            "code": "513426",
                            "name": "会东县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513426",
                            "fullNames": "四川省,凉山彝族自治州,会东县",
                            "children": []
                        },
                        {
                            "id": "513427",
                            "code": "513427",
                            "name": "宁南县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513427",
                            "fullNames": "四川省,凉山彝族自治州,宁南县",
                            "children": []
                        },
                        {
                            "id": "513428",
                            "code": "513428",
                            "name": "普格县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513428",
                            "fullNames": "四川省,凉山彝族自治州,普格县",
                            "children": []
                        },
                        {
                            "id": "513429",
                            "code": "513429",
                            "name": "布拖县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513429",
                            "fullNames": "四川省,凉山彝族自治州,布拖县",
                            "children": []
                        },
                        {
                            "id": "513430",
                            "code": "513430",
                            "name": "金阳县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513430",
                            "fullNames": "四川省,凉山彝族自治州,金阳县",
                            "children": []
                        },
                        {
                            "id": "513431",
                            "code": "513431",
                            "name": "昭觉县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513431",
                            "fullNames": "四川省,凉山彝族自治州,昭觉县",
                            "children": []
                        },
                        {
                            "id": "513432",
                            "code": "513432",
                            "name": "喜德县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513432",
                            "fullNames": "四川省,凉山彝族自治州,喜德县",
                            "children": []
                        },
                        {
                            "id": "513433",
                            "code": "513433",
                            "name": "冕宁县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513433",
                            "fullNames": "四川省,凉山彝族自治州,冕宁县",
                            "children": []
                        },
                        {
                            "id": "513434",
                            "code": "513434",
                            "name": "越西县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513434",
                            "fullNames": "四川省,凉山彝族自治州,越西县",
                            "children": []
                        },
                        {
                            "id": "513435",
                            "code": "513435",
                            "name": "甘洛县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513435",
                            "fullNames": "四川省,凉山彝族自治州,甘洛县",
                            "children": []
                        },
                        {
                            "id": "513436",
                            "code": "513436",
                            "name": "美姑县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513436",
                            "fullNames": "四川省,凉山彝族自治州,美姑县",
                            "children": []
                        },
                        {
                            "id": "513437",
                            "code": "513437",
                            "name": "雷波县",
                            "parentCode": "513400",
                            "fullCodes": "510000,513400,513437",
                            "fullNames": "四川省,凉山彝族自治州,雷波县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "520000",
            "code": "520000",
            "name": "贵州省",
            "parentCode": "-1",
            "fullCodes": "520000",
            "fullNames": "贵州省",
            "children": [
                {
                    "id": "520100",
                    "code": "520100",
                    "name": "贵阳市",
                    "parentCode": "520000",
                    "fullCodes": "520000,520100",
                    "fullNames": "贵州省,贵阳市",
                    "children": [
                        {
                            "id": "520102",
                            "code": "520102",
                            "name": "南明区",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520102",
                            "fullNames": "贵州省,贵阳市,南明区",
                            "children": []
                        },
                        {
                            "id": "520103",
                            "code": "520103",
                            "name": "云岩区",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520103",
                            "fullNames": "贵州省,贵阳市,云岩区",
                            "children": []
                        },
                        {
                            "id": "520111",
                            "code": "520111",
                            "name": "花溪区",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520111",
                            "fullNames": "贵州省,贵阳市,花溪区",
                            "children": []
                        },
                        {
                            "id": "520112",
                            "code": "520112",
                            "name": "乌当区",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520112",
                            "fullNames": "贵州省,贵阳市,乌当区",
                            "children": []
                        },
                        {
                            "id": "520113",
                            "code": "520113",
                            "name": "白云区",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520113",
                            "fullNames": "贵州省,贵阳市,白云区",
                            "children": []
                        },
                        {
                            "id": "520114",
                            "code": "520114",
                            "name": "小河区",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520114",
                            "fullNames": "贵州省,贵阳市,小河区",
                            "children": []
                        },
                        {
                            "id": "520121",
                            "code": "520121",
                            "name": "开阳县",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520121",
                            "fullNames": "贵州省,贵阳市,开阳县",
                            "children": []
                        },
                        {
                            "id": "520122",
                            "code": "520122",
                            "name": "息烽县",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520122",
                            "fullNames": "贵州省,贵阳市,息烽县",
                            "children": []
                        },
                        {
                            "id": "520123",
                            "code": "520123",
                            "name": "修文县",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520123",
                            "fullNames": "贵州省,贵阳市,修文县",
                            "children": []
                        },
                        {
                            "id": "520151",
                            "code": "520151",
                            "name": "金阳开发区",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520151",
                            "fullNames": "贵州省,贵阳市,金阳开发区",
                            "children": []
                        },
                        {
                            "id": "520181",
                            "code": "520181",
                            "name": "清镇市",
                            "parentCode": "520100",
                            "fullCodes": "520000,520100,520181",
                            "fullNames": "贵州省,贵阳市,清镇市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "520200",
                    "code": "520200",
                    "name": "六盘水市",
                    "parentCode": "520000",
                    "fullCodes": "520000,520200",
                    "fullNames": "贵州省,六盘水市",
                    "children": [
                        {
                            "id": "520201",
                            "code": "520201",
                            "name": "钟山区",
                            "parentCode": "520200",
                            "fullCodes": "520000,520200,520201",
                            "fullNames": "贵州省,六盘水市,钟山区",
                            "children": []
                        },
                        {
                            "id": "520203",
                            "code": "520203",
                            "name": "六枝特区",
                            "parentCode": "520200",
                            "fullCodes": "520000,520200,520203",
                            "fullNames": "贵州省,六盘水市,六枝特区",
                            "children": []
                        },
                        {
                            "id": "520221",
                            "code": "520221",
                            "name": "水城县",
                            "parentCode": "520200",
                            "fullCodes": "520000,520200,520221",
                            "fullNames": "贵州省,六盘水市,水城县",
                            "children": []
                        },
                        {
                            "id": "520222",
                            "code": "520222",
                            "name": "盘县",
                            "parentCode": "520200",
                            "fullCodes": "520000,520200,520222",
                            "fullNames": "贵州省,六盘水市,盘县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "520300",
                    "code": "520300",
                    "name": "遵义市",
                    "parentCode": "520000",
                    "fullCodes": "520000,520300",
                    "fullNames": "贵州省,遵义市",
                    "children": [
                        {
                            "id": "520302",
                            "code": "520302",
                            "name": "红花岗区",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520302",
                            "fullNames": "贵州省,遵义市,红花岗区",
                            "children": []
                        },
                        {
                            "id": "520303",
                            "code": "520303",
                            "name": "汇川区",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520303",
                            "fullNames": "贵州省,遵义市,汇川区",
                            "children": []
                        },
                        {
                            "id": "520321",
                            "code": "520321",
                            "name": "遵义县",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520321",
                            "fullNames": "贵州省,遵义市,遵义县",
                            "children": []
                        },
                        {
                            "id": "520322",
                            "code": "520322",
                            "name": "桐梓县",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520322",
                            "fullNames": "贵州省,遵义市,桐梓县",
                            "children": []
                        },
                        {
                            "id": "520323",
                            "code": "520323",
                            "name": "绥阳县",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520323",
                            "fullNames": "贵州省,遵义市,绥阳县",
                            "children": []
                        },
                        {
                            "id": "520324",
                            "code": "520324",
                            "name": "正安县",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520324",
                            "fullNames": "贵州省,遵义市,正安县",
                            "children": []
                        },
                        {
                            "id": "520325",
                            "code": "520325",
                            "name": "道真仡佬族苗族自治县",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520325",
                            "fullNames": "贵州省,遵义市,道真仡佬族苗族自治县",
                            "children": []
                        },
                        {
                            "id": "520326",
                            "code": "520326",
                            "name": "务川仡佬族苗族自治县",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520326",
                            "fullNames": "贵州省,遵义市,务川仡佬族苗族自治县",
                            "children": []
                        },
                        {
                            "id": "520327",
                            "code": "520327",
                            "name": "凤冈县",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520327",
                            "fullNames": "贵州省,遵义市,凤冈县",
                            "children": []
                        },
                        {
                            "id": "520328",
                            "code": "520328",
                            "name": "湄潭县",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520328",
                            "fullNames": "贵州省,遵义市,湄潭县",
                            "children": []
                        },
                        {
                            "id": "520329",
                            "code": "520329",
                            "name": "余庆县",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520329",
                            "fullNames": "贵州省,遵义市,余庆县",
                            "children": []
                        },
                        {
                            "id": "520330",
                            "code": "520330",
                            "name": "习水县",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520330",
                            "fullNames": "贵州省,遵义市,习水县",
                            "children": []
                        },
                        {
                            "id": "520381",
                            "code": "520381",
                            "name": "赤水市",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520381",
                            "fullNames": "贵州省,遵义市,赤水市",
                            "children": []
                        },
                        {
                            "id": "520382",
                            "code": "520382",
                            "name": "仁怀市",
                            "parentCode": "520300",
                            "fullCodes": "520000,520300,520382",
                            "fullNames": "贵州省,遵义市,仁怀市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "520400",
                    "code": "520400",
                    "name": "安顺市",
                    "parentCode": "520000",
                    "fullCodes": "520000,520400",
                    "fullNames": "贵州省,安顺市",
                    "children": [
                        {
                            "id": "520402",
                            "code": "520402",
                            "name": "西秀区",
                            "parentCode": "520400",
                            "fullCodes": "520000,520400,520402",
                            "fullNames": "贵州省,安顺市,西秀区",
                            "children": []
                        },
                        {
                            "id": "520421",
                            "code": "520421",
                            "name": "平坝县",
                            "parentCode": "520400",
                            "fullCodes": "520000,520400,520421",
                            "fullNames": "贵州省,安顺市,平坝县",
                            "children": []
                        },
                        {
                            "id": "520422",
                            "code": "520422",
                            "name": "普定县",
                            "parentCode": "520400",
                            "fullCodes": "520000,520400,520422",
                            "fullNames": "贵州省,安顺市,普定县",
                            "children": []
                        },
                        {
                            "id": "520423",
                            "code": "520423",
                            "name": "镇宁布依族苗族自治县",
                            "parentCode": "520400",
                            "fullCodes": "520000,520400,520423",
                            "fullNames": "贵州省,安顺市,镇宁布依族苗族自治县",
                            "children": []
                        },
                        {
                            "id": "520424",
                            "code": "520424",
                            "name": "关岭布依族苗族自治县",
                            "parentCode": "520400",
                            "fullCodes": "520000,520400,520424",
                            "fullNames": "贵州省,安顺市,关岭布依族苗族自治县",
                            "children": []
                        },
                        {
                            "id": "520425",
                            "code": "520425",
                            "name": "紫云苗族布依族自治县",
                            "parentCode": "520400",
                            "fullCodes": "520000,520400,520425",
                            "fullNames": "贵州省,安顺市,紫云苗族布依族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "522200",
                    "code": "522200",
                    "name": "铜仁地区",
                    "parentCode": "520000",
                    "fullCodes": "520000,522200",
                    "fullNames": "贵州省,铜仁地区",
                    "children": [
                        {
                            "id": "522201",
                            "code": "522201",
                            "name": "铜仁市",
                            "parentCode": "522200",
                            "fullCodes": "520000,522200,522201",
                            "fullNames": "贵州省,铜仁地区,铜仁市",
                            "children": []
                        },
                        {
                            "id": "522222",
                            "code": "522222",
                            "name": "江口县",
                            "parentCode": "522200",
                            "fullCodes": "520000,522200,522222",
                            "fullNames": "贵州省,铜仁地区,江口县",
                            "children": []
                        },
                        {
                            "id": "522223",
                            "code": "522223",
                            "name": "玉屏侗族自治县",
                            "parentCode": "522200",
                            "fullCodes": "520000,522200,522223",
                            "fullNames": "贵州省,铜仁地区,玉屏侗族自治县",
                            "children": []
                        },
                        {
                            "id": "522224",
                            "code": "522224",
                            "name": "石阡县",
                            "parentCode": "522200",
                            "fullCodes": "520000,522200,522224",
                            "fullNames": "贵州省,铜仁地区,石阡县",
                            "children": []
                        },
                        {
                            "id": "522225",
                            "code": "522225",
                            "name": "思南县",
                            "parentCode": "522200",
                            "fullCodes": "520000,522200,522225",
                            "fullNames": "贵州省,铜仁地区,思南县",
                            "children": []
                        },
                        {
                            "id": "522226",
                            "code": "522226",
                            "name": "印江土家族苗族自治县",
                            "parentCode": "522200",
                            "fullCodes": "520000,522200,522226",
                            "fullNames": "贵州省,铜仁地区,印江土家族苗族自治县",
                            "children": []
                        },
                        {
                            "id": "522227",
                            "code": "522227",
                            "name": "德江县",
                            "parentCode": "522200",
                            "fullCodes": "520000,522200,522227",
                            "fullNames": "贵州省,铜仁地区,德江县",
                            "children": []
                        },
                        {
                            "id": "522228",
                            "code": "522228",
                            "name": "沿河土家族自治县",
                            "parentCode": "522200",
                            "fullCodes": "520000,522200,522228",
                            "fullNames": "贵州省,铜仁地区,沿河土家族自治县",
                            "children": []
                        },
                        {
                            "id": "522229",
                            "code": "522229",
                            "name": "松桃苗族自治县",
                            "parentCode": "522200",
                            "fullCodes": "520000,522200,522229",
                            "fullNames": "贵州省,铜仁地区,松桃苗族自治县",
                            "children": []
                        },
                        {
                            "id": "522230",
                            "code": "522230",
                            "name": "万山特区",
                            "parentCode": "522200",
                            "fullCodes": "520000,522200,522230",
                            "fullNames": "贵州省,铜仁地区,万山特区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "522300",
                    "code": "522300",
                    "name": "黔西南布依族苗族自治州",
                    "parentCode": "520000",
                    "fullCodes": "520000,522300",
                    "fullNames": "贵州省,黔西南布依族苗族自治州",
                    "children": [
                        {
                            "id": "522301",
                            "code": "522301",
                            "name": "兴义市",
                            "parentCode": "522300",
                            "fullCodes": "520000,522300,522301",
                            "fullNames": "贵州省,黔西南布依族苗族自治州,兴义市",
                            "children": []
                        },
                        {
                            "id": "522322",
                            "code": "522322",
                            "name": "兴仁县",
                            "parentCode": "522300",
                            "fullCodes": "520000,522300,522322",
                            "fullNames": "贵州省,黔西南布依族苗族自治州,兴仁县",
                            "children": []
                        },
                        {
                            "id": "522323",
                            "code": "522323",
                            "name": "普安县",
                            "parentCode": "522300",
                            "fullCodes": "520000,522300,522323",
                            "fullNames": "贵州省,黔西南布依族苗族自治州,普安县",
                            "children": []
                        },
                        {
                            "id": "522324",
                            "code": "522324",
                            "name": "晴隆县",
                            "parentCode": "522300",
                            "fullCodes": "520000,522300,522324",
                            "fullNames": "贵州省,黔西南布依族苗族自治州,晴隆县",
                            "children": []
                        },
                        {
                            "id": "522325",
                            "code": "522325",
                            "name": "贞丰县",
                            "parentCode": "522300",
                            "fullCodes": "520000,522300,522325",
                            "fullNames": "贵州省,黔西南布依族苗族自治州,贞丰县",
                            "children": []
                        },
                        {
                            "id": "522326",
                            "code": "522326",
                            "name": "望谟县",
                            "parentCode": "522300",
                            "fullCodes": "520000,522300,522326",
                            "fullNames": "贵州省,黔西南布依族苗族自治州,望谟县",
                            "children": []
                        },
                        {
                            "id": "522327",
                            "code": "522327",
                            "name": "册亨县",
                            "parentCode": "522300",
                            "fullCodes": "520000,522300,522327",
                            "fullNames": "贵州省,黔西南布依族苗族自治州,册亨县",
                            "children": []
                        },
                        {
                            "id": "522328",
                            "code": "522328",
                            "name": "安龙县",
                            "parentCode": "522300",
                            "fullCodes": "520000,522300,522328",
                            "fullNames": "贵州省,黔西南布依族苗族自治州,安龙县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "522400",
                    "code": "522400",
                    "name": "毕节地区",
                    "parentCode": "520000",
                    "fullCodes": "520000,522400",
                    "fullNames": "贵州省,毕节地区",
                    "children": [
                        {
                            "id": "522401",
                            "code": "522401",
                            "name": "毕节市",
                            "parentCode": "522400",
                            "fullCodes": "520000,522400,522401",
                            "fullNames": "贵州省,毕节地区,毕节市",
                            "children": []
                        },
                        {
                            "id": "522422",
                            "code": "522422",
                            "name": "大方县",
                            "parentCode": "522400",
                            "fullCodes": "520000,522400,522422",
                            "fullNames": "贵州省,毕节地区,大方县",
                            "children": []
                        },
                        {
                            "id": "522423",
                            "code": "522423",
                            "name": "黔西县",
                            "parentCode": "522400",
                            "fullCodes": "520000,522400,522423",
                            "fullNames": "贵州省,毕节地区,黔西县",
                            "children": []
                        },
                        {
                            "id": "522424",
                            "code": "522424",
                            "name": "金沙县",
                            "parentCode": "522400",
                            "fullCodes": "520000,522400,522424",
                            "fullNames": "贵州省,毕节地区,金沙县",
                            "children": []
                        },
                        {
                            "id": "522425",
                            "code": "522425",
                            "name": "织金县",
                            "parentCode": "522400",
                            "fullCodes": "520000,522400,522425",
                            "fullNames": "贵州省,毕节地区,织金县",
                            "children": []
                        },
                        {
                            "id": "522426",
                            "code": "522426",
                            "name": "纳雍县",
                            "parentCode": "522400",
                            "fullCodes": "520000,522400,522426",
                            "fullNames": "贵州省,毕节地区,纳雍县",
                            "children": []
                        },
                        {
                            "id": "522427",
                            "code": "522427",
                            "name": "威宁彝族回族苗族自治县",
                            "parentCode": "522400",
                            "fullCodes": "520000,522400,522427",
                            "fullNames": "贵州省,毕节地区,威宁彝族回族苗族自治县",
                            "children": []
                        },
                        {
                            "id": "522428",
                            "code": "522428",
                            "name": "赫章县",
                            "parentCode": "522400",
                            "fullCodes": "520000,522400,522428",
                            "fullNames": "贵州省,毕节地区,赫章县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "522600",
                    "code": "522600",
                    "name": "黔东南苗族侗族自治州",
                    "parentCode": "520000",
                    "fullCodes": "520000,522600",
                    "fullNames": "贵州省,黔东南苗族侗族自治州",
                    "children": [
                        {
                            "id": "522601",
                            "code": "522601",
                            "name": "凯里市",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522601",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,凯里市",
                            "children": []
                        },
                        {
                            "id": "522622",
                            "code": "522622",
                            "name": "黄平县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522622",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,黄平县",
                            "children": []
                        },
                        {
                            "id": "522623",
                            "code": "522623",
                            "name": "施秉县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522623",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,施秉县",
                            "children": []
                        },
                        {
                            "id": "522624",
                            "code": "522624",
                            "name": "三穗县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522624",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,三穗县",
                            "children": []
                        },
                        {
                            "id": "522625",
                            "code": "522625",
                            "name": "镇远县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522625",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,镇远县",
                            "children": []
                        },
                        {
                            "id": "522626",
                            "code": "522626",
                            "name": "岑巩县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522626",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,岑巩县",
                            "children": []
                        },
                        {
                            "id": "522627",
                            "code": "522627",
                            "name": "天柱县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522627",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,天柱县",
                            "children": []
                        },
                        {
                            "id": "522628",
                            "code": "522628",
                            "name": "锦屏县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522628",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,锦屏县",
                            "children": []
                        },
                        {
                            "id": "522629",
                            "code": "522629",
                            "name": "剑河县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522629",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,剑河县",
                            "children": []
                        },
                        {
                            "id": "522630",
                            "code": "522630",
                            "name": "台江县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522630",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,台江县",
                            "children": []
                        },
                        {
                            "id": "522631",
                            "code": "522631",
                            "name": "黎平县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522631",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,黎平县",
                            "children": []
                        },
                        {
                            "id": "522632",
                            "code": "522632",
                            "name": "榕江县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522632",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,榕江县",
                            "children": []
                        },
                        {
                            "id": "522633",
                            "code": "522633",
                            "name": "从江县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522633",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,从江县",
                            "children": []
                        },
                        {
                            "id": "522634",
                            "code": "522634",
                            "name": "雷山县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522634",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,雷山县",
                            "children": []
                        },
                        {
                            "id": "522635",
                            "code": "522635",
                            "name": "麻江县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522635",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,麻江县",
                            "children": []
                        },
                        {
                            "id": "522636",
                            "code": "522636",
                            "name": "丹寨县",
                            "parentCode": "522600",
                            "fullCodes": "520000,522600,522636",
                            "fullNames": "贵州省,黔东南苗族侗族自治州,丹寨县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "522700",
                    "code": "522700",
                    "name": "黔南布依族苗族自治州",
                    "parentCode": "520000",
                    "fullCodes": "520000,522700",
                    "fullNames": "贵州省,黔南布依族苗族自治州",
                    "children": [
                        {
                            "id": "522701",
                            "code": "522701",
                            "name": "都匀市",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522701",
                            "fullNames": "贵州省,黔南布依族苗族自治州,都匀市",
                            "children": []
                        },
                        {
                            "id": "522702",
                            "code": "522702",
                            "name": "福泉市",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522702",
                            "fullNames": "贵州省,黔南布依族苗族自治州,福泉市",
                            "children": []
                        },
                        {
                            "id": "522722",
                            "code": "522722",
                            "name": "荔波县",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522722",
                            "fullNames": "贵州省,黔南布依族苗族自治州,荔波县",
                            "children": []
                        },
                        {
                            "id": "522723",
                            "code": "522723",
                            "name": "贵定县",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522723",
                            "fullNames": "贵州省,黔南布依族苗族自治州,贵定县",
                            "children": []
                        },
                        {
                            "id": "522725",
                            "code": "522725",
                            "name": "瓮安县",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522725",
                            "fullNames": "贵州省,黔南布依族苗族自治州,瓮安县",
                            "children": []
                        },
                        {
                            "id": "522726",
                            "code": "522726",
                            "name": "独山县",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522726",
                            "fullNames": "贵州省,黔南布依族苗族自治州,独山县",
                            "children": []
                        },
                        {
                            "id": "522727",
                            "code": "522727",
                            "name": "平塘县",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522727",
                            "fullNames": "贵州省,黔南布依族苗族自治州,平塘县",
                            "children": []
                        },
                        {
                            "id": "522728",
                            "code": "522728",
                            "name": "罗甸县",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522728",
                            "fullNames": "贵州省,黔南布依族苗族自治州,罗甸县",
                            "children": []
                        },
                        {
                            "id": "522729",
                            "code": "522729",
                            "name": "长顺县",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522729",
                            "fullNames": "贵州省,黔南布依族苗族自治州,长顺县",
                            "children": []
                        },
                        {
                            "id": "522730",
                            "code": "522730",
                            "name": "龙里县",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522730",
                            "fullNames": "贵州省,黔南布依族苗族自治州,龙里县",
                            "children": []
                        },
                        {
                            "id": "522731",
                            "code": "522731",
                            "name": "惠水县",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522731",
                            "fullNames": "贵州省,黔南布依族苗族自治州,惠水县",
                            "children": []
                        },
                        {
                            "id": "522732",
                            "code": "522732",
                            "name": "三都水族自治县",
                            "parentCode": "522700",
                            "fullCodes": "520000,522700,522732",
                            "fullNames": "贵州省,黔南布依族苗族自治州,三都水族自治县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "530000",
            "code": "530000",
            "name": "云南省",
            "parentCode": "-1",
            "fullCodes": "530000",
            "fullNames": "云南省",
            "children": [
                {
                    "id": "530100",
                    "code": "530100",
                    "name": "昆明市",
                    "parentCode": "530000",
                    "fullCodes": "530000,530100",
                    "fullNames": "云南省,昆明市",
                    "children": [
                        {
                            "id": "530102",
                            "code": "530102",
                            "name": "五华区",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530102",
                            "fullNames": "云南省,昆明市,五华区",
                            "children": []
                        },
                        {
                            "id": "530103",
                            "code": "530103",
                            "name": "盘龙区",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530103",
                            "fullNames": "云南省,昆明市,盘龙区",
                            "children": []
                        },
                        {
                            "id": "530111",
                            "code": "530111",
                            "name": "官渡区",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530111",
                            "fullNames": "云南省,昆明市,官渡区",
                            "children": []
                        },
                        {
                            "id": "530112",
                            "code": "530112",
                            "name": "西山区",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530112",
                            "fullNames": "云南省,昆明市,西山区",
                            "children": []
                        },
                        {
                            "id": "530113",
                            "code": "530113",
                            "name": "东川区",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530113",
                            "fullNames": "云南省,昆明市,东川区",
                            "children": []
                        },
                        {
                            "id": "530121",
                            "code": "530121",
                            "name": "呈贡县",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530121",
                            "fullNames": "云南省,昆明市,呈贡县",
                            "children": []
                        },
                        {
                            "id": "530122",
                            "code": "530122",
                            "name": "晋宁县",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530122",
                            "fullNames": "云南省,昆明市,晋宁县",
                            "children": []
                        },
                        {
                            "id": "530124",
                            "code": "530124",
                            "name": "富民县",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530124",
                            "fullNames": "云南省,昆明市,富民县",
                            "children": []
                        },
                        {
                            "id": "530125",
                            "code": "530125",
                            "name": "宜良县",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530125",
                            "fullNames": "云南省,昆明市,宜良县",
                            "children": []
                        },
                        {
                            "id": "530126",
                            "code": "530126",
                            "name": "石林彝族自治县",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530126",
                            "fullNames": "云南省,昆明市,石林彝族自治县",
                            "children": []
                        },
                        {
                            "id": "530127",
                            "code": "530127",
                            "name": "嵩明县",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530127",
                            "fullNames": "云南省,昆明市,嵩明县",
                            "children": []
                        },
                        {
                            "id": "530128",
                            "code": "530128",
                            "name": "禄劝彝族苗族自治县",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530128",
                            "fullNames": "云南省,昆明市,禄劝彝族苗族自治县",
                            "children": []
                        },
                        {
                            "id": "530129",
                            "code": "530129",
                            "name": "寻甸回族彝族自治县",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530129",
                            "fullNames": "云南省,昆明市,寻甸回族彝族自治县",
                            "children": []
                        },
                        {
                            "id": "530181",
                            "code": "530181",
                            "name": "安宁市",
                            "parentCode": "530100",
                            "fullCodes": "530000,530100,530181",
                            "fullNames": "云南省,昆明市,安宁市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "530300",
                    "code": "530300",
                    "name": "曲靖市",
                    "parentCode": "530000",
                    "fullCodes": "530000,530300",
                    "fullNames": "云南省,曲靖市",
                    "children": [
                        {
                            "id": "530302",
                            "code": "530302",
                            "name": "麒麟区",
                            "parentCode": "530300",
                            "fullCodes": "530000,530300,530302",
                            "fullNames": "云南省,曲靖市,麒麟区",
                            "children": []
                        },
                        {
                            "id": "530321",
                            "code": "530321",
                            "name": "马龙县",
                            "parentCode": "530300",
                            "fullCodes": "530000,530300,530321",
                            "fullNames": "云南省,曲靖市,马龙县",
                            "children": []
                        },
                        {
                            "id": "530322",
                            "code": "530322",
                            "name": "陆良县",
                            "parentCode": "530300",
                            "fullCodes": "530000,530300,530322",
                            "fullNames": "云南省,曲靖市,陆良县",
                            "children": []
                        },
                        {
                            "id": "530323",
                            "code": "530323",
                            "name": "师宗县",
                            "parentCode": "530300",
                            "fullCodes": "530000,530300,530323",
                            "fullNames": "云南省,曲靖市,师宗县",
                            "children": []
                        },
                        {
                            "id": "530324",
                            "code": "530324",
                            "name": "罗平县",
                            "parentCode": "530300",
                            "fullCodes": "530000,530300,530324",
                            "fullNames": "云南省,曲靖市,罗平县",
                            "children": []
                        },
                        {
                            "id": "530325",
                            "code": "530325",
                            "name": "富源县",
                            "parentCode": "530300",
                            "fullCodes": "530000,530300,530325",
                            "fullNames": "云南省,曲靖市,富源县",
                            "children": []
                        },
                        {
                            "id": "530326",
                            "code": "530326",
                            "name": "会泽县",
                            "parentCode": "530300",
                            "fullCodes": "530000,530300,530326",
                            "fullNames": "云南省,曲靖市,会泽县",
                            "children": []
                        },
                        {
                            "id": "530328",
                            "code": "530328",
                            "name": "沾益县",
                            "parentCode": "530300",
                            "fullCodes": "530000,530300,530328",
                            "fullNames": "云南省,曲靖市,沾益县",
                            "children": []
                        },
                        {
                            "id": "530381",
                            "code": "530381",
                            "name": "宣威市",
                            "parentCode": "530300",
                            "fullCodes": "530000,530300,530381",
                            "fullNames": "云南省,曲靖市,宣威市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "530400",
                    "code": "530400",
                    "name": "玉溪市",
                    "parentCode": "530000",
                    "fullCodes": "530000,530400",
                    "fullNames": "云南省,玉溪市",
                    "children": [
                        {
                            "id": "530402",
                            "code": "530402",
                            "name": "红塔区",
                            "parentCode": "530400",
                            "fullCodes": "530000,530400,530402",
                            "fullNames": "云南省,玉溪市,红塔区",
                            "children": []
                        },
                        {
                            "id": "530421",
                            "code": "530421",
                            "name": "江川县",
                            "parentCode": "530400",
                            "fullCodes": "530000,530400,530421",
                            "fullNames": "云南省,玉溪市,江川县",
                            "children": []
                        },
                        {
                            "id": "530422",
                            "code": "530422",
                            "name": "澄江县",
                            "parentCode": "530400",
                            "fullCodes": "530000,530400,530422",
                            "fullNames": "云南省,玉溪市,澄江县",
                            "children": []
                        },
                        {
                            "id": "530423",
                            "code": "530423",
                            "name": "通海县",
                            "parentCode": "530400",
                            "fullCodes": "530000,530400,530423",
                            "fullNames": "云南省,玉溪市,通海县",
                            "children": []
                        },
                        {
                            "id": "530424",
                            "code": "530424",
                            "name": "华宁县",
                            "parentCode": "530400",
                            "fullCodes": "530000,530400,530424",
                            "fullNames": "云南省,玉溪市,华宁县",
                            "children": []
                        },
                        {
                            "id": "530425",
                            "code": "530425",
                            "name": "易门县",
                            "parentCode": "530400",
                            "fullCodes": "530000,530400,530425",
                            "fullNames": "云南省,玉溪市,易门县",
                            "children": []
                        },
                        {
                            "id": "530426",
                            "code": "530426",
                            "name": "峨山彝族自治县",
                            "parentCode": "530400",
                            "fullCodes": "530000,530400,530426",
                            "fullNames": "云南省,玉溪市,峨山彝族自治县",
                            "children": []
                        },
                        {
                            "id": "530427",
                            "code": "530427",
                            "name": "新平彝族傣族自治县",
                            "parentCode": "530400",
                            "fullCodes": "530000,530400,530427",
                            "fullNames": "云南省,玉溪市,新平彝族傣族自治县",
                            "children": []
                        },
                        {
                            "id": "530428",
                            "code": "530428",
                            "name": "元江哈尼族彝族傣族自治县",
                            "parentCode": "530400",
                            "fullCodes": "530000,530400,530428",
                            "fullNames": "云南省,玉溪市,元江哈尼族彝族傣族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "530500",
                    "code": "530500",
                    "name": "保山市",
                    "parentCode": "530000",
                    "fullCodes": "530000,530500",
                    "fullNames": "云南省,保山市",
                    "children": [
                        {
                            "id": "530502",
                            "code": "530502",
                            "name": "隆阳区",
                            "parentCode": "530500",
                            "fullCodes": "530000,530500,530502",
                            "fullNames": "云南省,保山市,隆阳区",
                            "children": []
                        },
                        {
                            "id": "530521",
                            "code": "530521",
                            "name": "施甸县",
                            "parentCode": "530500",
                            "fullCodes": "530000,530500,530521",
                            "fullNames": "云南省,保山市,施甸县",
                            "children": []
                        },
                        {
                            "id": "530522",
                            "code": "530522",
                            "name": "腾冲县",
                            "parentCode": "530500",
                            "fullCodes": "530000,530500,530522",
                            "fullNames": "云南省,保山市,腾冲县",
                            "children": []
                        },
                        {
                            "id": "530523",
                            "code": "530523",
                            "name": "龙陵县",
                            "parentCode": "530500",
                            "fullCodes": "530000,530500,530523",
                            "fullNames": "云南省,保山市,龙陵县",
                            "children": []
                        },
                        {
                            "id": "530524",
                            "code": "530524",
                            "name": "昌宁县",
                            "parentCode": "530500",
                            "fullCodes": "530000,530500,530524",
                            "fullNames": "云南省,保山市,昌宁县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "530600",
                    "code": "530600",
                    "name": "昭通市",
                    "parentCode": "530000",
                    "fullCodes": "530000,530600",
                    "fullNames": "云南省,昭通市",
                    "children": [
                        {
                            "id": "530602",
                            "code": "530602",
                            "name": "昭阳区",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530602",
                            "fullNames": "云南省,昭通市,昭阳区",
                            "children": []
                        },
                        {
                            "id": "530621",
                            "code": "530621",
                            "name": "鲁甸县",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530621",
                            "fullNames": "云南省,昭通市,鲁甸县",
                            "children": []
                        },
                        {
                            "id": "530622",
                            "code": "530622",
                            "name": "巧家县",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530622",
                            "fullNames": "云南省,昭通市,巧家县",
                            "children": []
                        },
                        {
                            "id": "530623",
                            "code": "530623",
                            "name": "盐津县",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530623",
                            "fullNames": "云南省,昭通市,盐津县",
                            "children": []
                        },
                        {
                            "id": "530624",
                            "code": "530624",
                            "name": "大关县",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530624",
                            "fullNames": "云南省,昭通市,大关县",
                            "children": []
                        },
                        {
                            "id": "530625",
                            "code": "530625",
                            "name": "永善县",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530625",
                            "fullNames": "云南省,昭通市,永善县",
                            "children": []
                        },
                        {
                            "id": "530626",
                            "code": "530626",
                            "name": "绥江县",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530626",
                            "fullNames": "云南省,昭通市,绥江县",
                            "children": []
                        },
                        {
                            "id": "530627",
                            "code": "530627",
                            "name": "镇雄县",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530627",
                            "fullNames": "云南省,昭通市,镇雄县",
                            "children": []
                        },
                        {
                            "id": "530628",
                            "code": "530628",
                            "name": "彝良县",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530628",
                            "fullNames": "云南省,昭通市,彝良县",
                            "children": []
                        },
                        {
                            "id": "530629",
                            "code": "530629",
                            "name": "威信县",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530629",
                            "fullNames": "云南省,昭通市,威信县",
                            "children": []
                        },
                        {
                            "id": "530630",
                            "code": "530630",
                            "name": "水富县",
                            "parentCode": "530600",
                            "fullCodes": "530000,530600,530630",
                            "fullNames": "云南省,昭通市,水富县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "530700",
                    "code": "530700",
                    "name": "丽江市",
                    "parentCode": "530000",
                    "fullCodes": "530000,530700",
                    "fullNames": "云南省,丽江市",
                    "children": [
                        {
                            "id": "530702",
                            "code": "530702",
                            "name": "古城区",
                            "parentCode": "530700",
                            "fullCodes": "530000,530700,530702",
                            "fullNames": "云南省,丽江市,古城区",
                            "children": []
                        },
                        {
                            "id": "530721",
                            "code": "530721",
                            "name": "玉龙纳西族自治县",
                            "parentCode": "530700",
                            "fullCodes": "530000,530700,530721",
                            "fullNames": "云南省,丽江市,玉龙纳西族自治县",
                            "children": []
                        },
                        {
                            "id": "530722",
                            "code": "530722",
                            "name": "永胜县",
                            "parentCode": "530700",
                            "fullCodes": "530000,530700,530722",
                            "fullNames": "云南省,丽江市,永胜县",
                            "children": []
                        },
                        {
                            "id": "530723",
                            "code": "530723",
                            "name": "华坪县",
                            "parentCode": "530700",
                            "fullCodes": "530000,530700,530723",
                            "fullNames": "云南省,丽江市,华坪县",
                            "children": []
                        },
                        {
                            "id": "530724",
                            "code": "530724",
                            "name": "宁蒗彝族自治县",
                            "parentCode": "530700",
                            "fullCodes": "530000,530700,530724",
                            "fullNames": "云南省,丽江市,宁蒗彝族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "530800",
                    "code": "530800",
                    "name": "普洱市",
                    "parentCode": "530000",
                    "fullCodes": "530000,530800",
                    "fullNames": "云南省,普洱市",
                    "children": [
                        {
                            "id": "530802",
                            "code": "530802",
                            "name": "思茅区",
                            "parentCode": "530800",
                            "fullCodes": "530000,530800,530802",
                            "fullNames": "云南省,普洱市,思茅区",
                            "children": []
                        },
                        {
                            "id": "530821",
                            "code": "530821",
                            "name": "宁洱哈尼族彝族自治县",
                            "parentCode": "530800",
                            "fullCodes": "530000,530800,530821",
                            "fullNames": "云南省,普洱市,宁洱哈尼族彝族自治县",
                            "children": []
                        },
                        {
                            "id": "530822",
                            "code": "530822",
                            "name": "墨江哈尼族自治县",
                            "parentCode": "530800",
                            "fullCodes": "530000,530800,530822",
                            "fullNames": "云南省,普洱市,墨江哈尼族自治县",
                            "children": []
                        },
                        {
                            "id": "530823",
                            "code": "530823",
                            "name": "景东彝族自治县",
                            "parentCode": "530800",
                            "fullCodes": "530000,530800,530823",
                            "fullNames": "云南省,普洱市,景东彝族自治县",
                            "children": []
                        },
                        {
                            "id": "530824",
                            "code": "530824",
                            "name": "景谷傣族彝族自治县",
                            "parentCode": "530800",
                            "fullCodes": "530000,530800,530824",
                            "fullNames": "云南省,普洱市,景谷傣族彝族自治县",
                            "children": []
                        },
                        {
                            "id": "530825",
                            "code": "530825",
                            "name": "镇沅彝族哈尼族拉祜族自治县",
                            "parentCode": "530800",
                            "fullCodes": "530000,530800,530825",
                            "fullNames": "云南省,普洱市,镇沅彝族哈尼族拉祜族自治县",
                            "children": []
                        },
                        {
                            "id": "530826",
                            "code": "530826",
                            "name": "江城哈尼族彝族自治县",
                            "parentCode": "530800",
                            "fullCodes": "530000,530800,530826",
                            "fullNames": "云南省,普洱市,江城哈尼族彝族自治县",
                            "children": []
                        },
                        {
                            "id": "530827",
                            "code": "530827",
                            "name": "孟连傣族拉祜族佤族自治县",
                            "parentCode": "530800",
                            "fullCodes": "530000,530800,530827",
                            "fullNames": "云南省,普洱市,孟连傣族拉祜族佤族自治县",
                            "children": []
                        },
                        {
                            "id": "530828",
                            "code": "530828",
                            "name": "澜沧拉祜族自治县",
                            "parentCode": "530800",
                            "fullCodes": "530000,530800,530828",
                            "fullNames": "云南省,普洱市,澜沧拉祜族自治县",
                            "children": []
                        },
                        {
                            "id": "530829",
                            "code": "530829",
                            "name": "西盟佤族自治县",
                            "parentCode": "530800",
                            "fullCodes": "530000,530800,530829",
                            "fullNames": "云南省,普洱市,西盟佤族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "530900",
                    "code": "530900",
                    "name": "临沧市",
                    "parentCode": "530000",
                    "fullCodes": "530000,530900",
                    "fullNames": "云南省,临沧市",
                    "children": [
                        {
                            "id": "530902",
                            "code": "530902",
                            "name": "临翔区",
                            "parentCode": "530900",
                            "fullCodes": "530000,530900,530902",
                            "fullNames": "云南省,临沧市,临翔区",
                            "children": []
                        },
                        {
                            "id": "530921",
                            "code": "530921",
                            "name": "凤庆县",
                            "parentCode": "530900",
                            "fullCodes": "530000,530900,530921",
                            "fullNames": "云南省,临沧市,凤庆县",
                            "children": []
                        },
                        {
                            "id": "530922",
                            "code": "530922",
                            "name": "云县",
                            "parentCode": "530900",
                            "fullCodes": "530000,530900,530922",
                            "fullNames": "云南省,临沧市,云县",
                            "children": []
                        },
                        {
                            "id": "530923",
                            "code": "530923",
                            "name": "永德县",
                            "parentCode": "530900",
                            "fullCodes": "530000,530900,530923",
                            "fullNames": "云南省,临沧市,永德县",
                            "children": []
                        },
                        {
                            "id": "530924",
                            "code": "530924",
                            "name": "镇康县",
                            "parentCode": "530900",
                            "fullCodes": "530000,530900,530924",
                            "fullNames": "云南省,临沧市,镇康县",
                            "children": []
                        },
                        {
                            "id": "530925",
                            "code": "530925",
                            "name": "双江拉祜族佤族布朗族傣族自治县",
                            "parentCode": "530900",
                            "fullCodes": "530000,530900,530925",
                            "fullNames": "云南省,临沧市,双江拉祜族佤族布朗族傣族自治县",
                            "children": []
                        },
                        {
                            "id": "530926",
                            "code": "530926",
                            "name": "耿马傣族佤族自治县",
                            "parentCode": "530900",
                            "fullCodes": "530000,530900,530926",
                            "fullNames": "云南省,临沧市,耿马傣族佤族自治县",
                            "children": []
                        },
                        {
                            "id": "530927",
                            "code": "530927",
                            "name": "沧源佤族自治县",
                            "parentCode": "530900",
                            "fullCodes": "530000,530900,530927",
                            "fullNames": "云南省,临沧市,沧源佤族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "532300",
                    "code": "532300",
                    "name": "楚雄彝族自治州",
                    "parentCode": "530000",
                    "fullCodes": "530000,532300",
                    "fullNames": "云南省,楚雄彝族自治州",
                    "children": [
                        {
                            "id": "532301",
                            "code": "532301",
                            "name": "楚雄市",
                            "parentCode": "532300",
                            "fullCodes": "530000,532300,532301",
                            "fullNames": "云南省,楚雄彝族自治州,楚雄市",
                            "children": []
                        },
                        {
                            "id": "532322",
                            "code": "532322",
                            "name": "双柏县",
                            "parentCode": "532300",
                            "fullCodes": "530000,532300,532322",
                            "fullNames": "云南省,楚雄彝族自治州,双柏县",
                            "children": []
                        },
                        {
                            "id": "532323",
                            "code": "532323",
                            "name": "牟定县",
                            "parentCode": "532300",
                            "fullCodes": "530000,532300,532323",
                            "fullNames": "云南省,楚雄彝族自治州,牟定县",
                            "children": []
                        },
                        {
                            "id": "532324",
                            "code": "532324",
                            "name": "南华县",
                            "parentCode": "532300",
                            "fullCodes": "530000,532300,532324",
                            "fullNames": "云南省,楚雄彝族自治州,南华县",
                            "children": []
                        },
                        {
                            "id": "532325",
                            "code": "532325",
                            "name": "姚安县",
                            "parentCode": "532300",
                            "fullCodes": "530000,532300,532325",
                            "fullNames": "云南省,楚雄彝族自治州,姚安县",
                            "children": []
                        },
                        {
                            "id": "532326",
                            "code": "532326",
                            "name": "大姚县",
                            "parentCode": "532300",
                            "fullCodes": "530000,532300,532326",
                            "fullNames": "云南省,楚雄彝族自治州,大姚县",
                            "children": []
                        },
                        {
                            "id": "532327",
                            "code": "532327",
                            "name": "永仁县",
                            "parentCode": "532300",
                            "fullCodes": "530000,532300,532327",
                            "fullNames": "云南省,楚雄彝族自治州,永仁县",
                            "children": []
                        },
                        {
                            "id": "532328",
                            "code": "532328",
                            "name": "元谋县",
                            "parentCode": "532300",
                            "fullCodes": "530000,532300,532328",
                            "fullNames": "云南省,楚雄彝族自治州,元谋县",
                            "children": []
                        },
                        {
                            "id": "532329",
                            "code": "532329",
                            "name": "武定县",
                            "parentCode": "532300",
                            "fullCodes": "530000,532300,532329",
                            "fullNames": "云南省,楚雄彝族自治州,武定县",
                            "children": []
                        },
                        {
                            "id": "532331",
                            "code": "532331",
                            "name": "禄丰县",
                            "parentCode": "532300",
                            "fullCodes": "530000,532300,532331",
                            "fullNames": "云南省,楚雄彝族自治州,禄丰县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "532500",
                    "code": "532500",
                    "name": "红河哈尼族彝族自治州",
                    "parentCode": "530000",
                    "fullCodes": "530000,532500",
                    "fullNames": "云南省,红河哈尼族彝族自治州",
                    "children": [
                        {
                            "id": "532501",
                            "code": "532501",
                            "name": "个旧市",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532501",
                            "fullNames": "云南省,红河哈尼族彝族自治州,个旧市",
                            "children": []
                        },
                        {
                            "id": "532502",
                            "code": "532502",
                            "name": "开远市",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532502",
                            "fullNames": "云南省,红河哈尼族彝族自治州,开远市",
                            "children": []
                        },
                        {
                            "id": "532522",
                            "code": "532522",
                            "name": "蒙自县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532522",
                            "fullNames": "云南省,红河哈尼族彝族自治州,蒙自县",
                            "children": []
                        },
                        {
                            "id": "532523",
                            "code": "532523",
                            "name": "屏边苗族自治县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532523",
                            "fullNames": "云南省,红河哈尼族彝族自治州,屏边苗族自治县",
                            "children": []
                        },
                        {
                            "id": "532524",
                            "code": "532524",
                            "name": "建水县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532524",
                            "fullNames": "云南省,红河哈尼族彝族自治州,建水县",
                            "children": []
                        },
                        {
                            "id": "532525",
                            "code": "532525",
                            "name": "石屏县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532525",
                            "fullNames": "云南省,红河哈尼族彝族自治州,石屏县",
                            "children": []
                        },
                        {
                            "id": "532526",
                            "code": "532526",
                            "name": "弥勒县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532526",
                            "fullNames": "云南省,红河哈尼族彝族自治州,弥勒县",
                            "children": []
                        },
                        {
                            "id": "532527",
                            "code": "532527",
                            "name": "泸西县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532527",
                            "fullNames": "云南省,红河哈尼族彝族自治州,泸西县",
                            "children": []
                        },
                        {
                            "id": "532528",
                            "code": "532528",
                            "name": "元阳县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532528",
                            "fullNames": "云南省,红河哈尼族彝族自治州,元阳县",
                            "children": []
                        },
                        {
                            "id": "532529",
                            "code": "532529",
                            "name": "红河县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532529",
                            "fullNames": "云南省,红河哈尼族彝族自治州,红河县",
                            "children": []
                        },
                        {
                            "id": "532530",
                            "code": "532530",
                            "name": "金平苗族瑶族傣族自治县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532530",
                            "fullNames": "云南省,红河哈尼族彝族自治州,金平苗族瑶族傣族自治县",
                            "children": []
                        },
                        {
                            "id": "532531",
                            "code": "532531",
                            "name": "绿春县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532531",
                            "fullNames": "云南省,红河哈尼族彝族自治州,绿春县",
                            "children": []
                        },
                        {
                            "id": "532532",
                            "code": "532532",
                            "name": "河口瑶族自治县",
                            "parentCode": "532500",
                            "fullCodes": "530000,532500,532532",
                            "fullNames": "云南省,红河哈尼族彝族自治州,河口瑶族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "532600",
                    "code": "532600",
                    "name": "文山壮族苗族自治州",
                    "parentCode": "530000",
                    "fullCodes": "530000,532600",
                    "fullNames": "云南省,文山壮族苗族自治州",
                    "children": [
                        {
                            "id": "532621",
                            "code": "532621",
                            "name": "文山县",
                            "parentCode": "532600",
                            "fullCodes": "530000,532600,532621",
                            "fullNames": "云南省,文山壮族苗族自治州,文山县",
                            "children": []
                        },
                        {
                            "id": "532622",
                            "code": "532622",
                            "name": "砚山县",
                            "parentCode": "532600",
                            "fullCodes": "530000,532600,532622",
                            "fullNames": "云南省,文山壮族苗族自治州,砚山县",
                            "children": []
                        },
                        {
                            "id": "532623",
                            "code": "532623",
                            "name": "西畴县",
                            "parentCode": "532600",
                            "fullCodes": "530000,532600,532623",
                            "fullNames": "云南省,文山壮族苗族自治州,西畴县",
                            "children": []
                        },
                        {
                            "id": "532624",
                            "code": "532624",
                            "name": "麻栗坡县",
                            "parentCode": "532600",
                            "fullCodes": "530000,532600,532624",
                            "fullNames": "云南省,文山壮族苗族自治州,麻栗坡县",
                            "children": []
                        },
                        {
                            "id": "532625",
                            "code": "532625",
                            "name": "马关县",
                            "parentCode": "532600",
                            "fullCodes": "530000,532600,532625",
                            "fullNames": "云南省,文山壮族苗族自治州,马关县",
                            "children": []
                        },
                        {
                            "id": "532626",
                            "code": "532626",
                            "name": "丘北县",
                            "parentCode": "532600",
                            "fullCodes": "530000,532600,532626",
                            "fullNames": "云南省,文山壮族苗族自治州,丘北县",
                            "children": []
                        },
                        {
                            "id": "532627",
                            "code": "532627",
                            "name": "广南县",
                            "parentCode": "532600",
                            "fullCodes": "530000,532600,532627",
                            "fullNames": "云南省,文山壮族苗族自治州,广南县",
                            "children": []
                        },
                        {
                            "id": "532628",
                            "code": "532628",
                            "name": "富宁县",
                            "parentCode": "532600",
                            "fullCodes": "530000,532600,532628",
                            "fullNames": "云南省,文山壮族苗族自治州,富宁县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "532800",
                    "code": "532800",
                    "name": "西双版纳傣族自治州",
                    "parentCode": "530000",
                    "fullCodes": "530000,532800",
                    "fullNames": "云南省,西双版纳傣族自治州",
                    "children": [
                        {
                            "id": "532801",
                            "code": "532801",
                            "name": "景洪市",
                            "parentCode": "532800",
                            "fullCodes": "530000,532800,532801",
                            "fullNames": "云南省,西双版纳傣族自治州,景洪市",
                            "children": []
                        },
                        {
                            "id": "532822",
                            "code": "532822",
                            "name": "勐海县",
                            "parentCode": "532800",
                            "fullCodes": "530000,532800,532822",
                            "fullNames": "云南省,西双版纳傣族自治州,勐海县",
                            "children": []
                        },
                        {
                            "id": "532823",
                            "code": "532823",
                            "name": "勐腊县",
                            "parentCode": "532800",
                            "fullCodes": "530000,532800,532823",
                            "fullNames": "云南省,西双版纳傣族自治州,勐腊县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "532900",
                    "code": "532900",
                    "name": "大理白族自治州",
                    "parentCode": "530000",
                    "fullCodes": "530000,532900",
                    "fullNames": "云南省,大理白族自治州",
                    "children": [
                        {
                            "id": "532901",
                            "code": "532901",
                            "name": "大理市",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532901",
                            "fullNames": "云南省,大理白族自治州,大理市",
                            "children": []
                        },
                        {
                            "id": "532922",
                            "code": "532922",
                            "name": "漾濞彝族自治县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532922",
                            "fullNames": "云南省,大理白族自治州,漾濞彝族自治县",
                            "children": []
                        },
                        {
                            "id": "532923",
                            "code": "532923",
                            "name": "祥云县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532923",
                            "fullNames": "云南省,大理白族自治州,祥云县",
                            "children": []
                        },
                        {
                            "id": "532924",
                            "code": "532924",
                            "name": "宾川县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532924",
                            "fullNames": "云南省,大理白族自治州,宾川县",
                            "children": []
                        },
                        {
                            "id": "532925",
                            "code": "532925",
                            "name": "弥渡县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532925",
                            "fullNames": "云南省,大理白族自治州,弥渡县",
                            "children": []
                        },
                        {
                            "id": "532926",
                            "code": "532926",
                            "name": "南涧彝族自治县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532926",
                            "fullNames": "云南省,大理白族自治州,南涧彝族自治县",
                            "children": []
                        },
                        {
                            "id": "532927",
                            "code": "532927",
                            "name": "巍山彝族回族自治县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532927",
                            "fullNames": "云南省,大理白族自治州,巍山彝族回族自治县",
                            "children": []
                        },
                        {
                            "id": "532928",
                            "code": "532928",
                            "name": "永平县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532928",
                            "fullNames": "云南省,大理白族自治州,永平县",
                            "children": []
                        },
                        {
                            "id": "532929",
                            "code": "532929",
                            "name": "云龙县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532929",
                            "fullNames": "云南省,大理白族自治州,云龙县",
                            "children": []
                        },
                        {
                            "id": "532930",
                            "code": "532930",
                            "name": "洱源县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532930",
                            "fullNames": "云南省,大理白族自治州,洱源县",
                            "children": []
                        },
                        {
                            "id": "532931",
                            "code": "532931",
                            "name": "剑川县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532931",
                            "fullNames": "云南省,大理白族自治州,剑川县",
                            "children": []
                        },
                        {
                            "id": "532932",
                            "code": "532932",
                            "name": "鹤庆县",
                            "parentCode": "532900",
                            "fullCodes": "530000,532900,532932",
                            "fullNames": "云南省,大理白族自治州,鹤庆县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "533100",
                    "code": "533100",
                    "name": "德宏傣族景颇族自治州",
                    "parentCode": "530000",
                    "fullCodes": "530000,533100",
                    "fullNames": "云南省,德宏傣族景颇族自治州",
                    "children": [
                        {
                            "id": "533102",
                            "code": "533102",
                            "name": "瑞丽市",
                            "parentCode": "533100",
                            "fullCodes": "530000,533100,533102",
                            "fullNames": "云南省,德宏傣族景颇族自治州,瑞丽市",
                            "children": []
                        },
                        {
                            "id": "533103",
                            "code": "533103",
                            "name": "潞西市",
                            "parentCode": "533100",
                            "fullCodes": "530000,533100,533103",
                            "fullNames": "云南省,德宏傣族景颇族自治州,潞西市",
                            "children": []
                        },
                        {
                            "id": "533122",
                            "code": "533122",
                            "name": "梁河县",
                            "parentCode": "533100",
                            "fullCodes": "530000,533100,533122",
                            "fullNames": "云南省,德宏傣族景颇族自治州,梁河县",
                            "children": []
                        },
                        {
                            "id": "533123",
                            "code": "533123",
                            "name": "盈江县",
                            "parentCode": "533100",
                            "fullCodes": "530000,533100,533123",
                            "fullNames": "云南省,德宏傣族景颇族自治州,盈江县",
                            "children": []
                        },
                        {
                            "id": "533124",
                            "code": "533124",
                            "name": "陇川县",
                            "parentCode": "533100",
                            "fullCodes": "530000,533100,533124",
                            "fullNames": "云南省,德宏傣族景颇族自治州,陇川县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "533300",
                    "code": "533300",
                    "name": "怒江傈僳族自治州",
                    "parentCode": "530000",
                    "fullCodes": "530000,533300",
                    "fullNames": "云南省,怒江傈僳族自治州",
                    "children": [
                        {
                            "id": "533321",
                            "code": "533321",
                            "name": "泸水县",
                            "parentCode": "533300",
                            "fullCodes": "530000,533300,533321",
                            "fullNames": "云南省,怒江傈僳族自治州,泸水县",
                            "children": []
                        },
                        {
                            "id": "533323",
                            "code": "533323",
                            "name": "福贡县",
                            "parentCode": "533300",
                            "fullCodes": "530000,533300,533323",
                            "fullNames": "云南省,怒江傈僳族自治州,福贡县",
                            "children": []
                        },
                        {
                            "id": "533324",
                            "code": "533324",
                            "name": "贡山独龙族怒族自治县",
                            "parentCode": "533300",
                            "fullCodes": "530000,533300,533324",
                            "fullNames": "云南省,怒江傈僳族自治州,贡山独龙族怒族自治县",
                            "children": []
                        },
                        {
                            "id": "533325",
                            "code": "533325",
                            "name": "兰坪白族普米族自治县",
                            "parentCode": "533300",
                            "fullCodes": "530000,533300,533325",
                            "fullNames": "云南省,怒江傈僳族自治州,兰坪白族普米族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "533400",
                    "code": "533400",
                    "name": "迪庆藏族自治州",
                    "parentCode": "530000",
                    "fullCodes": "530000,533400",
                    "fullNames": "云南省,迪庆藏族自治州",
                    "children": [
                        {
                            "id": "533421",
                            "code": "533421",
                            "name": "香格里拉县",
                            "parentCode": "533400",
                            "fullCodes": "530000,533400,533421",
                            "fullNames": "云南省,迪庆藏族自治州,香格里拉县",
                            "children": []
                        },
                        {
                            "id": "533422",
                            "code": "533422",
                            "name": "德钦县",
                            "parentCode": "533400",
                            "fullCodes": "530000,533400,533422",
                            "fullNames": "云南省,迪庆藏族自治州,德钦县",
                            "children": []
                        },
                        {
                            "id": "533423",
                            "code": "533423",
                            "name": "维西傈僳族自治县",
                            "parentCode": "533400",
                            "fullCodes": "530000,533400,533423",
                            "fullNames": "云南省,迪庆藏族自治州,维西傈僳族自治县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "540000",
            "code": "540000",
            "name": "西藏自治区",
            "parentCode": "-1",
            "fullCodes": "540000",
            "fullNames": "西藏自治区",
            "children": [
                {
                    "id": "540100",
                    "code": "540100",
                    "name": "拉萨市",
                    "parentCode": "540000",
                    "fullCodes": "540000,540100",
                    "fullNames": "西藏自治区,拉萨市",
                    "children": [
                        {
                            "id": "540102",
                            "code": "540102",
                            "name": "城关区",
                            "parentCode": "540100",
                            "fullCodes": "540000,540100,540102",
                            "fullNames": "西藏自治区,拉萨市,城关区",
                            "children": []
                        },
                        {
                            "id": "540121",
                            "code": "540121",
                            "name": "林周县",
                            "parentCode": "540100",
                            "fullCodes": "540000,540100,540121",
                            "fullNames": "西藏自治区,拉萨市,林周县",
                            "children": []
                        },
                        {
                            "id": "540122",
                            "code": "540122",
                            "name": "当雄县",
                            "parentCode": "540100",
                            "fullCodes": "540000,540100,540122",
                            "fullNames": "西藏自治区,拉萨市,当雄县",
                            "children": []
                        },
                        {
                            "id": "540123",
                            "code": "540123",
                            "name": "尼木县",
                            "parentCode": "540100",
                            "fullCodes": "540000,540100,540123",
                            "fullNames": "西藏自治区,拉萨市,尼木县",
                            "children": []
                        },
                        {
                            "id": "540124",
                            "code": "540124",
                            "name": "曲水县",
                            "parentCode": "540100",
                            "fullCodes": "540000,540100,540124",
                            "fullNames": "西藏自治区,拉萨市,曲水县",
                            "children": []
                        },
                        {
                            "id": "540125",
                            "code": "540125",
                            "name": "堆龙德庆县",
                            "parentCode": "540100",
                            "fullCodes": "540000,540100,540125",
                            "fullNames": "西藏自治区,拉萨市,堆龙德庆县",
                            "children": []
                        },
                        {
                            "id": "540126",
                            "code": "540126",
                            "name": "达孜县",
                            "parentCode": "540100",
                            "fullCodes": "540000,540100,540126",
                            "fullNames": "西藏自治区,拉萨市,达孜县",
                            "children": []
                        },
                        {
                            "id": "540127",
                            "code": "540127",
                            "name": "墨竹工卡县",
                            "parentCode": "540100",
                            "fullCodes": "540000,540100,540127",
                            "fullNames": "西藏自治区,拉萨市,墨竹工卡县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "542100",
                    "code": "542100",
                    "name": "昌都地区",
                    "parentCode": "540000",
                    "fullCodes": "540000,542100",
                    "fullNames": "西藏自治区,昌都地区",
                    "children": [
                        {
                            "id": "542121",
                            "code": "542121",
                            "name": "昌都县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542121",
                            "fullNames": "西藏自治区,昌都地区,昌都县",
                            "children": []
                        },
                        {
                            "id": "542122",
                            "code": "542122",
                            "name": "江达县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542122",
                            "fullNames": "西藏自治区,昌都地区,江达县",
                            "children": []
                        },
                        {
                            "id": "542123",
                            "code": "542123",
                            "name": "贡觉县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542123",
                            "fullNames": "西藏自治区,昌都地区,贡觉县",
                            "children": []
                        },
                        {
                            "id": "542124",
                            "code": "542124",
                            "name": "类乌齐县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542124",
                            "fullNames": "西藏自治区,昌都地区,类乌齐县",
                            "children": []
                        },
                        {
                            "id": "542125",
                            "code": "542125",
                            "name": "丁青县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542125",
                            "fullNames": "西藏自治区,昌都地区,丁青县",
                            "children": []
                        },
                        {
                            "id": "542126",
                            "code": "542126",
                            "name": "察雅县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542126",
                            "fullNames": "西藏自治区,昌都地区,察雅县",
                            "children": []
                        },
                        {
                            "id": "542127",
                            "code": "542127",
                            "name": "八宿县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542127",
                            "fullNames": "西藏自治区,昌都地区,八宿县",
                            "children": []
                        },
                        {
                            "id": "542128",
                            "code": "542128",
                            "name": "左贡县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542128",
                            "fullNames": "西藏自治区,昌都地区,左贡县",
                            "children": []
                        },
                        {
                            "id": "542129",
                            "code": "542129",
                            "name": "芒康县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542129",
                            "fullNames": "西藏自治区,昌都地区,芒康县",
                            "children": []
                        },
                        {
                            "id": "542132",
                            "code": "542132",
                            "name": "洛隆县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542132",
                            "fullNames": "西藏自治区,昌都地区,洛隆县",
                            "children": []
                        },
                        {
                            "id": "542133",
                            "code": "542133",
                            "name": "边坝县",
                            "parentCode": "542100",
                            "fullCodes": "540000,542100,542133",
                            "fullNames": "西藏自治区,昌都地区,边坝县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "542200",
                    "code": "542200",
                    "name": "山南地区",
                    "parentCode": "540000",
                    "fullCodes": "540000,542200",
                    "fullNames": "西藏自治区,山南地区",
                    "children": [
                        {
                            "id": "542221",
                            "code": "542221",
                            "name": "乃东县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542221",
                            "fullNames": "西藏自治区,山南地区,乃东县",
                            "children": []
                        },
                        {
                            "id": "542222",
                            "code": "542222",
                            "name": "扎囊县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542222",
                            "fullNames": "西藏自治区,山南地区,扎囊县",
                            "children": []
                        },
                        {
                            "id": "542223",
                            "code": "542223",
                            "name": "贡嘎县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542223",
                            "fullNames": "西藏自治区,山南地区,贡嘎县",
                            "children": []
                        },
                        {
                            "id": "542224",
                            "code": "542224",
                            "name": "桑日县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542224",
                            "fullNames": "西藏自治区,山南地区,桑日县",
                            "children": []
                        },
                        {
                            "id": "542225",
                            "code": "542225",
                            "name": "琼结县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542225",
                            "fullNames": "西藏自治区,山南地区,琼结县",
                            "children": []
                        },
                        {
                            "id": "542226",
                            "code": "542226",
                            "name": "曲松县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542226",
                            "fullNames": "西藏自治区,山南地区,曲松县",
                            "children": []
                        },
                        {
                            "id": "542227",
                            "code": "542227",
                            "name": "措美县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542227",
                            "fullNames": "西藏自治区,山南地区,措美县",
                            "children": []
                        },
                        {
                            "id": "542228",
                            "code": "542228",
                            "name": "洛扎县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542228",
                            "fullNames": "西藏自治区,山南地区,洛扎县",
                            "children": []
                        },
                        {
                            "id": "542229",
                            "code": "542229",
                            "name": "加查县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542229",
                            "fullNames": "西藏自治区,山南地区,加查县",
                            "children": []
                        },
                        {
                            "id": "542231",
                            "code": "542231",
                            "name": "隆子县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542231",
                            "fullNames": "西藏自治区,山南地区,隆子县",
                            "children": []
                        },
                        {
                            "id": "542232",
                            "code": "542232",
                            "name": "错那县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542232",
                            "fullNames": "西藏自治区,山南地区,错那县",
                            "children": []
                        },
                        {
                            "id": "542233",
                            "code": "542233",
                            "name": "浪卡子县",
                            "parentCode": "542200",
                            "fullCodes": "540000,542200,542233",
                            "fullNames": "西藏自治区,山南地区,浪卡子县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "542300",
                    "code": "542300",
                    "name": "日喀则地区",
                    "parentCode": "540000",
                    "fullCodes": "540000,542300",
                    "fullNames": "西藏自治区,日喀则地区",
                    "children": [
                        {
                            "id": "542301",
                            "code": "542301",
                            "name": "日喀则市",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542301",
                            "fullNames": "西藏自治区,日喀则地区,日喀则市",
                            "children": []
                        },
                        {
                            "id": "542322",
                            "code": "542322",
                            "name": "南木林县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542322",
                            "fullNames": "西藏自治区,日喀则地区,南木林县",
                            "children": []
                        },
                        {
                            "id": "542323",
                            "code": "542323",
                            "name": "江孜县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542323",
                            "fullNames": "西藏自治区,日喀则地区,江孜县",
                            "children": []
                        },
                        {
                            "id": "542324",
                            "code": "542324",
                            "name": "定日县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542324",
                            "fullNames": "西藏自治区,日喀则地区,定日县",
                            "children": []
                        },
                        {
                            "id": "542325",
                            "code": "542325",
                            "name": "萨迦县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542325",
                            "fullNames": "西藏自治区,日喀则地区,萨迦县",
                            "children": []
                        },
                        {
                            "id": "542326",
                            "code": "542326",
                            "name": "拉孜县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542326",
                            "fullNames": "西藏自治区,日喀则地区,拉孜县",
                            "children": []
                        },
                        {
                            "id": "542327",
                            "code": "542327",
                            "name": "昂仁县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542327",
                            "fullNames": "西藏自治区,日喀则地区,昂仁县",
                            "children": []
                        },
                        {
                            "id": "542328",
                            "code": "542328",
                            "name": "谢通门县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542328",
                            "fullNames": "西藏自治区,日喀则地区,谢通门县",
                            "children": []
                        },
                        {
                            "id": "542329",
                            "code": "542329",
                            "name": "白朗县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542329",
                            "fullNames": "西藏自治区,日喀则地区,白朗县",
                            "children": []
                        },
                        {
                            "id": "542330",
                            "code": "542330",
                            "name": "仁布县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542330",
                            "fullNames": "西藏自治区,日喀则地区,仁布县",
                            "children": []
                        },
                        {
                            "id": "542331",
                            "code": "542331",
                            "name": "康马县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542331",
                            "fullNames": "西藏自治区,日喀则地区,康马县",
                            "children": []
                        },
                        {
                            "id": "542332",
                            "code": "542332",
                            "name": "定结县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542332",
                            "fullNames": "西藏自治区,日喀则地区,定结县",
                            "children": []
                        },
                        {
                            "id": "542333",
                            "code": "542333",
                            "name": "仲巴县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542333",
                            "fullNames": "西藏自治区,日喀则地区,仲巴县",
                            "children": []
                        },
                        {
                            "id": "542334",
                            "code": "542334",
                            "name": "亚东县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542334",
                            "fullNames": "西藏自治区,日喀则地区,亚东县",
                            "children": []
                        },
                        {
                            "id": "542335",
                            "code": "542335",
                            "name": "吉隆县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542335",
                            "fullNames": "西藏自治区,日喀则地区,吉隆县",
                            "children": []
                        },
                        {
                            "id": "542336",
                            "code": "542336",
                            "name": "聂拉木县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542336",
                            "fullNames": "西藏自治区,日喀则地区,聂拉木县",
                            "children": []
                        },
                        {
                            "id": "542337",
                            "code": "542337",
                            "name": "萨嘎县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542337",
                            "fullNames": "西藏自治区,日喀则地区,萨嘎县",
                            "children": []
                        },
                        {
                            "id": "542338",
                            "code": "542338",
                            "name": "岗巴县",
                            "parentCode": "542300",
                            "fullCodes": "540000,542300,542338",
                            "fullNames": "西藏自治区,日喀则地区,岗巴县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "542400",
                    "code": "542400",
                    "name": "那曲地区",
                    "parentCode": "540000",
                    "fullCodes": "540000,542400",
                    "fullNames": "西藏自治区,那曲地区",
                    "children": [
                        {
                            "id": "542421",
                            "code": "542421",
                            "name": "那曲县",
                            "parentCode": "542400",
                            "fullCodes": "540000,542400,542421",
                            "fullNames": "西藏自治区,那曲地区,那曲县",
                            "children": []
                        },
                        {
                            "id": "542422",
                            "code": "542422",
                            "name": "嘉黎县",
                            "parentCode": "542400",
                            "fullCodes": "540000,542400,542422",
                            "fullNames": "西藏自治区,那曲地区,嘉黎县",
                            "children": []
                        },
                        {
                            "id": "542423",
                            "code": "542423",
                            "name": "比如县",
                            "parentCode": "542400",
                            "fullCodes": "540000,542400,542423",
                            "fullNames": "西藏自治区,那曲地区,比如县",
                            "children": []
                        },
                        {
                            "id": "542424",
                            "code": "542424",
                            "name": "聂荣县",
                            "parentCode": "542400",
                            "fullCodes": "540000,542400,542424",
                            "fullNames": "西藏自治区,那曲地区,聂荣县",
                            "children": []
                        },
                        {
                            "id": "542425",
                            "code": "542425",
                            "name": "安多县",
                            "parentCode": "542400",
                            "fullCodes": "540000,542400,542425",
                            "fullNames": "西藏自治区,那曲地区,安多县",
                            "children": []
                        },
                        {
                            "id": "542426",
                            "code": "542426",
                            "name": "申扎县",
                            "parentCode": "542400",
                            "fullCodes": "540000,542400,542426",
                            "fullNames": "西藏自治区,那曲地区,申扎县",
                            "children": []
                        },
                        {
                            "id": "542427",
                            "code": "542427",
                            "name": "索县",
                            "parentCode": "542400",
                            "fullCodes": "540000,542400,542427",
                            "fullNames": "西藏自治区,那曲地区,索县",
                            "children": []
                        },
                        {
                            "id": "542428",
                            "code": "542428",
                            "name": "班戈县",
                            "parentCode": "542400",
                            "fullCodes": "540000,542400,542428",
                            "fullNames": "西藏自治区,那曲地区,班戈县",
                            "children": []
                        },
                        {
                            "id": "542429",
                            "code": "542429",
                            "name": "巴青县",
                            "parentCode": "542400",
                            "fullCodes": "540000,542400,542429",
                            "fullNames": "西藏自治区,那曲地区,巴青县",
                            "children": []
                        },
                        {
                            "id": "542430",
                            "code": "542430",
                            "name": "尼玛县",
                            "parentCode": "542400",
                            "fullCodes": "540000,542400,542430",
                            "fullNames": "西藏自治区,那曲地区,尼玛县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "542500",
                    "code": "542500",
                    "name": "阿里地区",
                    "parentCode": "540000",
                    "fullCodes": "540000,542500",
                    "fullNames": "西藏自治区,阿里地区",
                    "children": [
                        {
                            "id": "542521",
                            "code": "542521",
                            "name": "普兰县",
                            "parentCode": "542500",
                            "fullCodes": "540000,542500,542521",
                            "fullNames": "西藏自治区,阿里地区,普兰县",
                            "children": []
                        },
                        {
                            "id": "542522",
                            "code": "542522",
                            "name": "札达县",
                            "parentCode": "542500",
                            "fullCodes": "540000,542500,542522",
                            "fullNames": "西藏自治区,阿里地区,札达县",
                            "children": []
                        },
                        {
                            "id": "542523",
                            "code": "542523",
                            "name": "噶尔县",
                            "parentCode": "542500",
                            "fullCodes": "540000,542500,542523",
                            "fullNames": "西藏自治区,阿里地区,噶尔县",
                            "children": []
                        },
                        {
                            "id": "542524",
                            "code": "542524",
                            "name": "日土县",
                            "parentCode": "542500",
                            "fullCodes": "540000,542500,542524",
                            "fullNames": "西藏自治区,阿里地区,日土县",
                            "children": []
                        },
                        {
                            "id": "542525",
                            "code": "542525",
                            "name": "革吉县",
                            "parentCode": "542500",
                            "fullCodes": "540000,542500,542525",
                            "fullNames": "西藏自治区,阿里地区,革吉县",
                            "children": []
                        },
                        {
                            "id": "542526",
                            "code": "542526",
                            "name": "改则县",
                            "parentCode": "542500",
                            "fullCodes": "540000,542500,542526",
                            "fullNames": "西藏自治区,阿里地区,改则县",
                            "children": []
                        },
                        {
                            "id": "542527",
                            "code": "542527",
                            "name": "措勤县",
                            "parentCode": "542500",
                            "fullCodes": "540000,542500,542527",
                            "fullNames": "西藏自治区,阿里地区,措勤县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "542600",
                    "code": "542600",
                    "name": "林芝地区",
                    "parentCode": "540000",
                    "fullCodes": "540000,542600",
                    "fullNames": "西藏自治区,林芝地区",
                    "children": [
                        {
                            "id": "542621",
                            "code": "542621",
                            "name": "林芝县",
                            "parentCode": "542600",
                            "fullCodes": "540000,542600,542621",
                            "fullNames": "西藏自治区,林芝地区,林芝县",
                            "children": []
                        },
                        {
                            "id": "542622",
                            "code": "542622",
                            "name": "工布江达县",
                            "parentCode": "542600",
                            "fullCodes": "540000,542600,542622",
                            "fullNames": "西藏自治区,林芝地区,工布江达县",
                            "children": []
                        },
                        {
                            "id": "542623",
                            "code": "542623",
                            "name": "米林县",
                            "parentCode": "542600",
                            "fullCodes": "540000,542600,542623",
                            "fullNames": "西藏自治区,林芝地区,米林县",
                            "children": []
                        },
                        {
                            "id": "542624",
                            "code": "542624",
                            "name": "墨脱县",
                            "parentCode": "542600",
                            "fullCodes": "540000,542600,542624",
                            "fullNames": "西藏自治区,林芝地区,墨脱县",
                            "children": []
                        },
                        {
                            "id": "542625",
                            "code": "542625",
                            "name": "波密县",
                            "parentCode": "542600",
                            "fullCodes": "540000,542600,542625",
                            "fullNames": "西藏自治区,林芝地区,波密县",
                            "children": []
                        },
                        {
                            "id": "542626",
                            "code": "542626",
                            "name": "察隅县",
                            "parentCode": "542600",
                            "fullCodes": "540000,542600,542626",
                            "fullNames": "西藏自治区,林芝地区,察隅县",
                            "children": []
                        },
                        {
                            "id": "542627",
                            "code": "542627",
                            "name": "朗县",
                            "parentCode": "542600",
                            "fullCodes": "540000,542600,542627",
                            "fullNames": "西藏自治区,林芝地区,朗县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "610000",
            "code": "610000",
            "name": "陕西省",
            "parentCode": "-1",
            "fullCodes": "610000",
            "fullNames": "陕西省",
            "children": [
                {
                    "id": "610100",
                    "code": "610100",
                    "name": "西安市",
                    "parentCode": "610000",
                    "fullCodes": "610000,610100",
                    "fullNames": "陕西省,西安市",
                    "children": [
                        {
                            "id": "610102",
                            "code": "610102",
                            "name": "新城区",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610102",
                            "fullNames": "陕西省,西安市,新城区",
                            "children": []
                        },
                        {
                            "id": "610103",
                            "code": "610103",
                            "name": "碑林区",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610103",
                            "fullNames": "陕西省,西安市,碑林区",
                            "children": []
                        },
                        {
                            "id": "610104",
                            "code": "610104",
                            "name": "莲湖区",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610104",
                            "fullNames": "陕西省,西安市,莲湖区",
                            "children": []
                        },
                        {
                            "id": "610111",
                            "code": "610111",
                            "name": "灞桥区",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610111",
                            "fullNames": "陕西省,西安市,灞桥区",
                            "children": []
                        },
                        {
                            "id": "610112",
                            "code": "610112",
                            "name": "未央区",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610112",
                            "fullNames": "陕西省,西安市,未央区",
                            "children": []
                        },
                        {
                            "id": "610113",
                            "code": "610113",
                            "name": "雁塔区",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610113",
                            "fullNames": "陕西省,西安市,雁塔区",
                            "children": []
                        },
                        {
                            "id": "610114",
                            "code": "610114",
                            "name": "阎良区",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610114",
                            "fullNames": "陕西省,西安市,阎良区",
                            "children": []
                        },
                        {
                            "id": "610115",
                            "code": "610115",
                            "name": "临潼区",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610115",
                            "fullNames": "陕西省,西安市,临潼区",
                            "children": []
                        },
                        {
                            "id": "610116",
                            "code": "610116",
                            "name": "长安区",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610116",
                            "fullNames": "陕西省,西安市,长安区",
                            "children": []
                        },
                        {
                            "id": "610122",
                            "code": "610122",
                            "name": "蓝田县",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610122",
                            "fullNames": "陕西省,西安市,蓝田县",
                            "children": []
                        },
                        {
                            "id": "610124",
                            "code": "610124",
                            "name": "周至县",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610124",
                            "fullNames": "陕西省,西安市,周至县",
                            "children": []
                        },
                        {
                            "id": "610125",
                            "code": "610125",
                            "name": "户县",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610125",
                            "fullNames": "陕西省,西安市,户县",
                            "children": []
                        },
                        {
                            "id": "610126",
                            "code": "610126",
                            "name": "高陵县",
                            "parentCode": "610100",
                            "fullCodes": "610000,610100,610126",
                            "fullNames": "陕西省,西安市,高陵县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "610200",
                    "code": "610200",
                    "name": "铜川市",
                    "parentCode": "610000",
                    "fullCodes": "610000,610200",
                    "fullNames": "陕西省,铜川市",
                    "children": [
                        {
                            "id": "610202",
                            "code": "610202",
                            "name": "王益区",
                            "parentCode": "610200",
                            "fullCodes": "610000,610200,610202",
                            "fullNames": "陕西省,铜川市,王益区",
                            "children": []
                        },
                        {
                            "id": "610203",
                            "code": "610203",
                            "name": "印台区",
                            "parentCode": "610200",
                            "fullCodes": "610000,610200,610203",
                            "fullNames": "陕西省,铜川市,印台区",
                            "children": []
                        },
                        {
                            "id": "610204",
                            "code": "610204",
                            "name": "耀州区",
                            "parentCode": "610200",
                            "fullCodes": "610000,610200,610204",
                            "fullNames": "陕西省,铜川市,耀州区",
                            "children": []
                        },
                        {
                            "id": "610222",
                            "code": "610222",
                            "name": "宜君县",
                            "parentCode": "610200",
                            "fullCodes": "610000,610200,610222",
                            "fullNames": "陕西省,铜川市,宜君县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "610300",
                    "code": "610300",
                    "name": "宝鸡市",
                    "parentCode": "610000",
                    "fullCodes": "610000,610300",
                    "fullNames": "陕西省,宝鸡市",
                    "children": [
                        {
                            "id": "610302",
                            "code": "610302",
                            "name": "渭滨区",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610302",
                            "fullNames": "陕西省,宝鸡市,渭滨区",
                            "children": []
                        },
                        {
                            "id": "610303",
                            "code": "610303",
                            "name": "金台区",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610303",
                            "fullNames": "陕西省,宝鸡市,金台区",
                            "children": []
                        },
                        {
                            "id": "610304",
                            "code": "610304",
                            "name": "陈仓区",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610304",
                            "fullNames": "陕西省,宝鸡市,陈仓区",
                            "children": []
                        },
                        {
                            "id": "610322",
                            "code": "610322",
                            "name": "凤翔县",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610322",
                            "fullNames": "陕西省,宝鸡市,凤翔县",
                            "children": []
                        },
                        {
                            "id": "610323",
                            "code": "610323",
                            "name": "岐山县",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610323",
                            "fullNames": "陕西省,宝鸡市,岐山县",
                            "children": []
                        },
                        {
                            "id": "610324",
                            "code": "610324",
                            "name": "扶风县",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610324",
                            "fullNames": "陕西省,宝鸡市,扶风县",
                            "children": []
                        },
                        {
                            "id": "610326",
                            "code": "610326",
                            "name": "眉县",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610326",
                            "fullNames": "陕西省,宝鸡市,眉县",
                            "children": []
                        },
                        {
                            "id": "610327",
                            "code": "610327",
                            "name": "陇县",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610327",
                            "fullNames": "陕西省,宝鸡市,陇县",
                            "children": []
                        },
                        {
                            "id": "610328",
                            "code": "610328",
                            "name": "千阳县",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610328",
                            "fullNames": "陕西省,宝鸡市,千阳县",
                            "children": []
                        },
                        {
                            "id": "610329",
                            "code": "610329",
                            "name": "麟游县",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610329",
                            "fullNames": "陕西省,宝鸡市,麟游县",
                            "children": []
                        },
                        {
                            "id": "610330",
                            "code": "610330",
                            "name": "凤县",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610330",
                            "fullNames": "陕西省,宝鸡市,凤县",
                            "children": []
                        },
                        {
                            "id": "610331",
                            "code": "610331",
                            "name": "太白县",
                            "parentCode": "610300",
                            "fullCodes": "610000,610300,610331",
                            "fullNames": "陕西省,宝鸡市,太白县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "610400",
                    "code": "610400",
                    "name": "咸阳市",
                    "parentCode": "610000",
                    "fullCodes": "610000,610400",
                    "fullNames": "陕西省,咸阳市",
                    "children": [
                        {
                            "id": "610402",
                            "code": "610402",
                            "name": "秦都区",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610402",
                            "fullNames": "陕西省,咸阳市,秦都区",
                            "children": []
                        },
                        {
                            "id": "610403",
                            "code": "610403",
                            "name": "杨凌区",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610403",
                            "fullNames": "陕西省,咸阳市,杨凌区",
                            "children": []
                        },
                        {
                            "id": "610404",
                            "code": "610404",
                            "name": "渭城区",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610404",
                            "fullNames": "陕西省,咸阳市,渭城区",
                            "children": []
                        },
                        {
                            "id": "610422",
                            "code": "610422",
                            "name": "三原县",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610422",
                            "fullNames": "陕西省,咸阳市,三原县",
                            "children": []
                        },
                        {
                            "id": "610423",
                            "code": "610423",
                            "name": "泾阳县",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610423",
                            "fullNames": "陕西省,咸阳市,泾阳县",
                            "children": []
                        },
                        {
                            "id": "610424",
                            "code": "610424",
                            "name": "乾县",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610424",
                            "fullNames": "陕西省,咸阳市,乾县",
                            "children": []
                        },
                        {
                            "id": "610425",
                            "code": "610425",
                            "name": "礼泉县",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610425",
                            "fullNames": "陕西省,咸阳市,礼泉县",
                            "children": []
                        },
                        {
                            "id": "610426",
                            "code": "610426",
                            "name": "永寿县",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610426",
                            "fullNames": "陕西省,咸阳市,永寿县",
                            "children": []
                        },
                        {
                            "id": "610427",
                            "code": "610427",
                            "name": "彬县",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610427",
                            "fullNames": "陕西省,咸阳市,彬县",
                            "children": []
                        },
                        {
                            "id": "610428",
                            "code": "610428",
                            "name": "长武县",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610428",
                            "fullNames": "陕西省,咸阳市,长武县",
                            "children": []
                        },
                        {
                            "id": "610429",
                            "code": "610429",
                            "name": "旬邑县",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610429",
                            "fullNames": "陕西省,咸阳市,旬邑县",
                            "children": []
                        },
                        {
                            "id": "610430",
                            "code": "610430",
                            "name": "淳化县",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610430",
                            "fullNames": "陕西省,咸阳市,淳化县",
                            "children": []
                        },
                        {
                            "id": "610431",
                            "code": "610431",
                            "name": "武功县",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610431",
                            "fullNames": "陕西省,咸阳市,武功县",
                            "children": []
                        },
                        {
                            "id": "610481",
                            "code": "610481",
                            "name": "兴平市",
                            "parentCode": "610400",
                            "fullCodes": "610000,610400,610481",
                            "fullNames": "陕西省,咸阳市,兴平市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "610500",
                    "code": "610500",
                    "name": "渭南市",
                    "parentCode": "610000",
                    "fullCodes": "610000,610500",
                    "fullNames": "陕西省,渭南市",
                    "children": [
                        {
                            "id": "610502",
                            "code": "610502",
                            "name": "临渭区",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610502",
                            "fullNames": "陕西省,渭南市,临渭区",
                            "children": []
                        },
                        {
                            "id": "610521",
                            "code": "610521",
                            "name": "华县",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610521",
                            "fullNames": "陕西省,渭南市,华县",
                            "children": []
                        },
                        {
                            "id": "610522",
                            "code": "610522",
                            "name": "潼关县",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610522",
                            "fullNames": "陕西省,渭南市,潼关县",
                            "children": []
                        },
                        {
                            "id": "610523",
                            "code": "610523",
                            "name": "大荔县",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610523",
                            "fullNames": "陕西省,渭南市,大荔县",
                            "children": []
                        },
                        {
                            "id": "610524",
                            "code": "610524",
                            "name": "合阳县",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610524",
                            "fullNames": "陕西省,渭南市,合阳县",
                            "children": []
                        },
                        {
                            "id": "610525",
                            "code": "610525",
                            "name": "澄城县",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610525",
                            "fullNames": "陕西省,渭南市,澄城县",
                            "children": []
                        },
                        {
                            "id": "610526",
                            "code": "610526",
                            "name": "蒲城县",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610526",
                            "fullNames": "陕西省,渭南市,蒲城县",
                            "children": []
                        },
                        {
                            "id": "610527",
                            "code": "610527",
                            "name": "白水县",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610527",
                            "fullNames": "陕西省,渭南市,白水县",
                            "children": []
                        },
                        {
                            "id": "610528",
                            "code": "610528",
                            "name": "富平县",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610528",
                            "fullNames": "陕西省,渭南市,富平县",
                            "children": []
                        },
                        {
                            "id": "610581",
                            "code": "610581",
                            "name": "韩城市",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610581",
                            "fullNames": "陕西省,渭南市,韩城市",
                            "children": []
                        },
                        {
                            "id": "610582",
                            "code": "610582",
                            "name": "华阴市",
                            "parentCode": "610500",
                            "fullCodes": "610000,610500,610582",
                            "fullNames": "陕西省,渭南市,华阴市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "610600",
                    "code": "610600",
                    "name": "延安市",
                    "parentCode": "610000",
                    "fullCodes": "610000,610600",
                    "fullNames": "陕西省,延安市",
                    "children": [
                        {
                            "id": "610602",
                            "code": "610602",
                            "name": "宝塔区",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610602",
                            "fullNames": "陕西省,延安市,宝塔区",
                            "children": []
                        },
                        {
                            "id": "610621",
                            "code": "610621",
                            "name": "延长县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610621",
                            "fullNames": "陕西省,延安市,延长县",
                            "children": []
                        },
                        {
                            "id": "610622",
                            "code": "610622",
                            "name": "延川县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610622",
                            "fullNames": "陕西省,延安市,延川县",
                            "children": []
                        },
                        {
                            "id": "610623",
                            "code": "610623",
                            "name": "子长县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610623",
                            "fullNames": "陕西省,延安市,子长县",
                            "children": []
                        },
                        {
                            "id": "610624",
                            "code": "610624",
                            "name": "安塞县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610624",
                            "fullNames": "陕西省,延安市,安塞县",
                            "children": []
                        },
                        {
                            "id": "610625",
                            "code": "610625",
                            "name": "志丹县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610625",
                            "fullNames": "陕西省,延安市,志丹县",
                            "children": []
                        },
                        {
                            "id": "610626",
                            "code": "610626",
                            "name": "吴起县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610626",
                            "fullNames": "陕西省,延安市,吴起县",
                            "children": []
                        },
                        {
                            "id": "610627",
                            "code": "610627",
                            "name": "甘泉县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610627",
                            "fullNames": "陕西省,延安市,甘泉县",
                            "children": []
                        },
                        {
                            "id": "610628",
                            "code": "610628",
                            "name": "富县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610628",
                            "fullNames": "陕西省,延安市,富县",
                            "children": []
                        },
                        {
                            "id": "610629",
                            "code": "610629",
                            "name": "洛川县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610629",
                            "fullNames": "陕西省,延安市,洛川县",
                            "children": []
                        },
                        {
                            "id": "610630",
                            "code": "610630",
                            "name": "宜川县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610630",
                            "fullNames": "陕西省,延安市,宜川县",
                            "children": []
                        },
                        {
                            "id": "610631",
                            "code": "610631",
                            "name": "黄龙县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610631",
                            "fullNames": "陕西省,延安市,黄龙县",
                            "children": []
                        },
                        {
                            "id": "610632",
                            "code": "610632",
                            "name": "黄陵县",
                            "parentCode": "610600",
                            "fullCodes": "610000,610600,610632",
                            "fullNames": "陕西省,延安市,黄陵县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "610700",
                    "code": "610700",
                    "name": "汉中市",
                    "parentCode": "610000",
                    "fullCodes": "610000,610700",
                    "fullNames": "陕西省,汉中市",
                    "children": [
                        {
                            "id": "610702",
                            "code": "610702",
                            "name": "汉台区",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610702",
                            "fullNames": "陕西省,汉中市,汉台区",
                            "children": []
                        },
                        {
                            "id": "610721",
                            "code": "610721",
                            "name": "南郑县",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610721",
                            "fullNames": "陕西省,汉中市,南郑县",
                            "children": []
                        },
                        {
                            "id": "610722",
                            "code": "610722",
                            "name": "城固县",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610722",
                            "fullNames": "陕西省,汉中市,城固县",
                            "children": []
                        },
                        {
                            "id": "610723",
                            "code": "610723",
                            "name": "洋县",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610723",
                            "fullNames": "陕西省,汉中市,洋县",
                            "children": []
                        },
                        {
                            "id": "610724",
                            "code": "610724",
                            "name": "西乡县",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610724",
                            "fullNames": "陕西省,汉中市,西乡县",
                            "children": []
                        },
                        {
                            "id": "610725",
                            "code": "610725",
                            "name": "勉县",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610725",
                            "fullNames": "陕西省,汉中市,勉县",
                            "children": []
                        },
                        {
                            "id": "610726",
                            "code": "610726",
                            "name": "宁强县",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610726",
                            "fullNames": "陕西省,汉中市,宁强县",
                            "children": []
                        },
                        {
                            "id": "610727",
                            "code": "610727",
                            "name": "略阳县",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610727",
                            "fullNames": "陕西省,汉中市,略阳县",
                            "children": []
                        },
                        {
                            "id": "610728",
                            "code": "610728",
                            "name": "镇巴县",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610728",
                            "fullNames": "陕西省,汉中市,镇巴县",
                            "children": []
                        },
                        {
                            "id": "610729",
                            "code": "610729",
                            "name": "留坝县",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610729",
                            "fullNames": "陕西省,汉中市,留坝县",
                            "children": []
                        },
                        {
                            "id": "610730",
                            "code": "610730",
                            "name": "佛坪县",
                            "parentCode": "610700",
                            "fullCodes": "610000,610700,610730",
                            "fullNames": "陕西省,汉中市,佛坪县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "610800",
                    "code": "610800",
                    "name": "榆林市",
                    "parentCode": "610000",
                    "fullCodes": "610000,610800",
                    "fullNames": "陕西省,榆林市",
                    "children": [
                        {
                            "id": "610802",
                            "code": "610802",
                            "name": "榆阳区",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610802",
                            "fullNames": "陕西省,榆林市,榆阳区",
                            "children": []
                        },
                        {
                            "id": "610821",
                            "code": "610821",
                            "name": "神木县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610821",
                            "fullNames": "陕西省,榆林市,神木县",
                            "children": []
                        },
                        {
                            "id": "610822",
                            "code": "610822",
                            "name": "府谷县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610822",
                            "fullNames": "陕西省,榆林市,府谷县",
                            "children": []
                        },
                        {
                            "id": "610823",
                            "code": "610823",
                            "name": "横山县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610823",
                            "fullNames": "陕西省,榆林市,横山县",
                            "children": []
                        },
                        {
                            "id": "610824",
                            "code": "610824",
                            "name": "靖边县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610824",
                            "fullNames": "陕西省,榆林市,靖边县",
                            "children": []
                        },
                        {
                            "id": "610825",
                            "code": "610825",
                            "name": "定边县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610825",
                            "fullNames": "陕西省,榆林市,定边县",
                            "children": []
                        },
                        {
                            "id": "610826",
                            "code": "610826",
                            "name": "绥德县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610826",
                            "fullNames": "陕西省,榆林市,绥德县",
                            "children": []
                        },
                        {
                            "id": "610827",
                            "code": "610827",
                            "name": "米脂县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610827",
                            "fullNames": "陕西省,榆林市,米脂县",
                            "children": []
                        },
                        {
                            "id": "610828",
                            "code": "610828",
                            "name": "佳县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610828",
                            "fullNames": "陕西省,榆林市,佳县",
                            "children": []
                        },
                        {
                            "id": "610829",
                            "code": "610829",
                            "name": "吴堡县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610829",
                            "fullNames": "陕西省,榆林市,吴堡县",
                            "children": []
                        },
                        {
                            "id": "610830",
                            "code": "610830",
                            "name": "清涧县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610830",
                            "fullNames": "陕西省,榆林市,清涧县",
                            "children": []
                        },
                        {
                            "id": "610831",
                            "code": "610831",
                            "name": "子洲县",
                            "parentCode": "610800",
                            "fullCodes": "610000,610800,610831",
                            "fullNames": "陕西省,榆林市,子洲县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "610900",
                    "code": "610900",
                    "name": "安康市",
                    "parentCode": "610000",
                    "fullCodes": "610000,610900",
                    "fullNames": "陕西省,安康市",
                    "children": [
                        {
                            "id": "610902",
                            "code": "610902",
                            "name": "汉滨区",
                            "parentCode": "610900",
                            "fullCodes": "610000,610900,610902",
                            "fullNames": "陕西省,安康市,汉滨区",
                            "children": []
                        },
                        {
                            "id": "610921",
                            "code": "610921",
                            "name": "汉阴县",
                            "parentCode": "610900",
                            "fullCodes": "610000,610900,610921",
                            "fullNames": "陕西省,安康市,汉阴县",
                            "children": []
                        },
                        {
                            "id": "610922",
                            "code": "610922",
                            "name": "石泉县",
                            "parentCode": "610900",
                            "fullCodes": "610000,610900,610922",
                            "fullNames": "陕西省,安康市,石泉县",
                            "children": []
                        },
                        {
                            "id": "610923",
                            "code": "610923",
                            "name": "宁陕县",
                            "parentCode": "610900",
                            "fullCodes": "610000,610900,610923",
                            "fullNames": "陕西省,安康市,宁陕县",
                            "children": []
                        },
                        {
                            "id": "610924",
                            "code": "610924",
                            "name": "紫阳县",
                            "parentCode": "610900",
                            "fullCodes": "610000,610900,610924",
                            "fullNames": "陕西省,安康市,紫阳县",
                            "children": []
                        },
                        {
                            "id": "610925",
                            "code": "610925",
                            "name": "岚皋县",
                            "parentCode": "610900",
                            "fullCodes": "610000,610900,610925",
                            "fullNames": "陕西省,安康市,岚皋县",
                            "children": []
                        },
                        {
                            "id": "610926",
                            "code": "610926",
                            "name": "平利县",
                            "parentCode": "610900",
                            "fullCodes": "610000,610900,610926",
                            "fullNames": "陕西省,安康市,平利县",
                            "children": []
                        },
                        {
                            "id": "610927",
                            "code": "610927",
                            "name": "镇坪县",
                            "parentCode": "610900",
                            "fullCodes": "610000,610900,610927",
                            "fullNames": "陕西省,安康市,镇坪县",
                            "children": []
                        },
                        {
                            "id": "610928",
                            "code": "610928",
                            "name": "旬阳县",
                            "parentCode": "610900",
                            "fullCodes": "610000,610900,610928",
                            "fullNames": "陕西省,安康市,旬阳县",
                            "children": []
                        },
                        {
                            "id": "610929",
                            "code": "610929",
                            "name": "白河县",
                            "parentCode": "610900",
                            "fullCodes": "610000,610900,610929",
                            "fullNames": "陕西省,安康市,白河县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "611000",
                    "code": "611000",
                    "name": "商洛市",
                    "parentCode": "610000",
                    "fullCodes": "610000,611000",
                    "fullNames": "陕西省,商洛市",
                    "children": [
                        {
                            "id": "611002",
                            "code": "611002",
                            "name": "商州区",
                            "parentCode": "611000",
                            "fullCodes": "610000,611000,611002",
                            "fullNames": "陕西省,商洛市,商州区",
                            "children": []
                        },
                        {
                            "id": "611021",
                            "code": "611021",
                            "name": "洛南县",
                            "parentCode": "611000",
                            "fullCodes": "610000,611000,611021",
                            "fullNames": "陕西省,商洛市,洛南县",
                            "children": []
                        },
                        {
                            "id": "611022",
                            "code": "611022",
                            "name": "丹凤县",
                            "parentCode": "611000",
                            "fullCodes": "610000,611000,611022",
                            "fullNames": "陕西省,商洛市,丹凤县",
                            "children": []
                        },
                        {
                            "id": "611023",
                            "code": "611023",
                            "name": "商南县",
                            "parentCode": "611000",
                            "fullCodes": "610000,611000,611023",
                            "fullNames": "陕西省,商洛市,商南县",
                            "children": []
                        },
                        {
                            "id": "611024",
                            "code": "611024",
                            "name": "山阳县",
                            "parentCode": "611000",
                            "fullCodes": "610000,611000,611024",
                            "fullNames": "陕西省,商洛市,山阳县",
                            "children": []
                        },
                        {
                            "id": "611025",
                            "code": "611025",
                            "name": "镇安县",
                            "parentCode": "611000",
                            "fullCodes": "610000,611000,611025",
                            "fullNames": "陕西省,商洛市,镇安县",
                            "children": []
                        },
                        {
                            "id": "611026",
                            "code": "611026",
                            "name": "柞水县",
                            "parentCode": "611000",
                            "fullCodes": "610000,611000,611026",
                            "fullNames": "陕西省,商洛市,柞水县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "620000",
            "code": "620000",
            "name": "甘肃省",
            "parentCode": "-1",
            "fullCodes": "620000",
            "fullNames": "甘肃省",
            "children": [
                {
                    "id": "620100",
                    "code": "620100",
                    "name": "兰州市",
                    "parentCode": "620000",
                    "fullCodes": "620000,620100",
                    "fullNames": "甘肃省,兰州市",
                    "children": [
                        {
                            "id": "620102",
                            "code": "620102",
                            "name": "城关区",
                            "parentCode": "620100",
                            "fullCodes": "620000,620100,620102",
                            "fullNames": "甘肃省,兰州市,城关区",
                            "children": []
                        },
                        {
                            "id": "620103",
                            "code": "620103",
                            "name": "七里河区",
                            "parentCode": "620100",
                            "fullCodes": "620000,620100,620103",
                            "fullNames": "甘肃省,兰州市,七里河区",
                            "children": []
                        },
                        {
                            "id": "620104",
                            "code": "620104",
                            "name": "西固区",
                            "parentCode": "620100",
                            "fullCodes": "620000,620100,620104",
                            "fullNames": "甘肃省,兰州市,西固区",
                            "children": []
                        },
                        {
                            "id": "620105",
                            "code": "620105",
                            "name": "安宁区",
                            "parentCode": "620100",
                            "fullCodes": "620000,620100,620105",
                            "fullNames": "甘肃省,兰州市,安宁区",
                            "children": []
                        },
                        {
                            "id": "620111",
                            "code": "620111",
                            "name": "红古区",
                            "parentCode": "620100",
                            "fullCodes": "620000,620100,620111",
                            "fullNames": "甘肃省,兰州市,红古区",
                            "children": []
                        },
                        {
                            "id": "620121",
                            "code": "620121",
                            "name": "永登县",
                            "parentCode": "620100",
                            "fullCodes": "620000,620100,620121",
                            "fullNames": "甘肃省,兰州市,永登县",
                            "children": []
                        },
                        {
                            "id": "620122",
                            "code": "620122",
                            "name": "皋兰县",
                            "parentCode": "620100",
                            "fullCodes": "620000,620100,620122",
                            "fullNames": "甘肃省,兰州市,皋兰县",
                            "children": []
                        },
                        {
                            "id": "620123",
                            "code": "620123",
                            "name": "榆中县",
                            "parentCode": "620100",
                            "fullCodes": "620000,620100,620123",
                            "fullNames": "甘肃省,兰州市,榆中县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "620200",
                    "code": "620200",
                    "name": "嘉峪关市",
                    "parentCode": "620000",
                    "fullCodes": "620000,620200",
                    "fullNames": "甘肃省,嘉峪关市",
                    "children": []
                },
                {
                    "id": "620300",
                    "code": "620300",
                    "name": "金昌市",
                    "parentCode": "620000",
                    "fullCodes": "620000,620300",
                    "fullNames": "甘肃省,金昌市",
                    "children": [
                        {
                            "id": "620302",
                            "code": "620302",
                            "name": "金川区",
                            "parentCode": "620300",
                            "fullCodes": "620000,620300,620302",
                            "fullNames": "甘肃省,金昌市,金川区",
                            "children": []
                        },
                        {
                            "id": "620321",
                            "code": "620321",
                            "name": "永昌县",
                            "parentCode": "620300",
                            "fullCodes": "620000,620300,620321",
                            "fullNames": "甘肃省,金昌市,永昌县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "620400",
                    "code": "620400",
                    "name": "白银市",
                    "parentCode": "620000",
                    "fullCodes": "620000,620400",
                    "fullNames": "甘肃省,白银市",
                    "children": [
                        {
                            "id": "620402",
                            "code": "620402",
                            "name": "白银区",
                            "parentCode": "620400",
                            "fullCodes": "620000,620400,620402",
                            "fullNames": "甘肃省,白银市,白银区",
                            "children": []
                        },
                        {
                            "id": "620403",
                            "code": "620403",
                            "name": "平川区",
                            "parentCode": "620400",
                            "fullCodes": "620000,620400,620403",
                            "fullNames": "甘肃省,白银市,平川区",
                            "children": []
                        },
                        {
                            "id": "620421",
                            "code": "620421",
                            "name": "靖远县",
                            "parentCode": "620400",
                            "fullCodes": "620000,620400,620421",
                            "fullNames": "甘肃省,白银市,靖远县",
                            "children": []
                        },
                        {
                            "id": "620422",
                            "code": "620422",
                            "name": "会宁县",
                            "parentCode": "620400",
                            "fullCodes": "620000,620400,620422",
                            "fullNames": "甘肃省,白银市,会宁县",
                            "children": []
                        },
                        {
                            "id": "620423",
                            "code": "620423",
                            "name": "景泰县",
                            "parentCode": "620400",
                            "fullCodes": "620000,620400,620423",
                            "fullNames": "甘肃省,白银市,景泰县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "620500",
                    "code": "620500",
                    "name": "天水市",
                    "parentCode": "620000",
                    "fullCodes": "620000,620500",
                    "fullNames": "甘肃省,天水市",
                    "children": [
                        {
                            "id": "620502",
                            "code": "620502",
                            "name": "秦州区",
                            "parentCode": "620500",
                            "fullCodes": "620000,620500,620502",
                            "fullNames": "甘肃省,天水市,秦州区",
                            "children": []
                        },
                        {
                            "id": "620503",
                            "code": "620503",
                            "name": "麦积区",
                            "parentCode": "620500",
                            "fullCodes": "620000,620500,620503",
                            "fullNames": "甘肃省,天水市,麦积区",
                            "children": []
                        },
                        {
                            "id": "620521",
                            "code": "620521",
                            "name": "清水县",
                            "parentCode": "620500",
                            "fullCodes": "620000,620500,620521",
                            "fullNames": "甘肃省,天水市,清水县",
                            "children": []
                        },
                        {
                            "id": "620522",
                            "code": "620522",
                            "name": "秦安县",
                            "parentCode": "620500",
                            "fullCodes": "620000,620500,620522",
                            "fullNames": "甘肃省,天水市,秦安县",
                            "children": []
                        },
                        {
                            "id": "620523",
                            "code": "620523",
                            "name": "甘谷县",
                            "parentCode": "620500",
                            "fullCodes": "620000,620500,620523",
                            "fullNames": "甘肃省,天水市,甘谷县",
                            "children": []
                        },
                        {
                            "id": "620524",
                            "code": "620524",
                            "name": "武山县",
                            "parentCode": "620500",
                            "fullCodes": "620000,620500,620524",
                            "fullNames": "甘肃省,天水市,武山县",
                            "children": []
                        },
                        {
                            "id": "620525",
                            "code": "620525",
                            "name": "张家川回族自治县",
                            "parentCode": "620500",
                            "fullCodes": "620000,620500,620525",
                            "fullNames": "甘肃省,天水市,张家川回族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "620600",
                    "code": "620600",
                    "name": "武威市",
                    "parentCode": "620000",
                    "fullCodes": "620000,620600",
                    "fullNames": "甘肃省,武威市",
                    "children": [
                        {
                            "id": "620602",
                            "code": "620602",
                            "name": "凉州区",
                            "parentCode": "620600",
                            "fullCodes": "620000,620600,620602",
                            "fullNames": "甘肃省,武威市,凉州区",
                            "children": []
                        },
                        {
                            "id": "620621",
                            "code": "620621",
                            "name": "民勤县",
                            "parentCode": "620600",
                            "fullCodes": "620000,620600,620621",
                            "fullNames": "甘肃省,武威市,民勤县",
                            "children": []
                        },
                        {
                            "id": "620622",
                            "code": "620622",
                            "name": "古浪县",
                            "parentCode": "620600",
                            "fullCodes": "620000,620600,620622",
                            "fullNames": "甘肃省,武威市,古浪县",
                            "children": []
                        },
                        {
                            "id": "620623",
                            "code": "620623",
                            "name": "天祝藏族自治县",
                            "parentCode": "620600",
                            "fullCodes": "620000,620600,620623",
                            "fullNames": "甘肃省,武威市,天祝藏族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "620700",
                    "code": "620700",
                    "name": "张掖市",
                    "parentCode": "620000",
                    "fullCodes": "620000,620700",
                    "fullNames": "甘肃省,张掖市",
                    "children": [
                        {
                            "id": "620702",
                            "code": "620702",
                            "name": "甘州区",
                            "parentCode": "620700",
                            "fullCodes": "620000,620700,620702",
                            "fullNames": "甘肃省,张掖市,甘州区",
                            "children": []
                        },
                        {
                            "id": "620721",
                            "code": "620721",
                            "name": "肃南裕固族自治县",
                            "parentCode": "620700",
                            "fullCodes": "620000,620700,620721",
                            "fullNames": "甘肃省,张掖市,肃南裕固族自治县",
                            "children": []
                        },
                        {
                            "id": "620722",
                            "code": "620722",
                            "name": "民乐县",
                            "parentCode": "620700",
                            "fullCodes": "620000,620700,620722",
                            "fullNames": "甘肃省,张掖市,民乐县",
                            "children": []
                        },
                        {
                            "id": "620723",
                            "code": "620723",
                            "name": "临泽县",
                            "parentCode": "620700",
                            "fullCodes": "620000,620700,620723",
                            "fullNames": "甘肃省,张掖市,临泽县",
                            "children": []
                        },
                        {
                            "id": "620724",
                            "code": "620724",
                            "name": "高台县",
                            "parentCode": "620700",
                            "fullCodes": "620000,620700,620724",
                            "fullNames": "甘肃省,张掖市,高台县",
                            "children": []
                        },
                        {
                            "id": "620725",
                            "code": "620725",
                            "name": "山丹县",
                            "parentCode": "620700",
                            "fullCodes": "620000,620700,620725",
                            "fullNames": "甘肃省,张掖市,山丹县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "620800",
                    "code": "620800",
                    "name": "平凉市",
                    "parentCode": "620000",
                    "fullCodes": "620000,620800",
                    "fullNames": "甘肃省,平凉市",
                    "children": [
                        {
                            "id": "620802",
                            "code": "620802",
                            "name": "崆峒区",
                            "parentCode": "620800",
                            "fullCodes": "620000,620800,620802",
                            "fullNames": "甘肃省,平凉市,崆峒区",
                            "children": []
                        },
                        {
                            "id": "620821",
                            "code": "620821",
                            "name": "泾川县",
                            "parentCode": "620800",
                            "fullCodes": "620000,620800,620821",
                            "fullNames": "甘肃省,平凉市,泾川县",
                            "children": []
                        },
                        {
                            "id": "620822",
                            "code": "620822",
                            "name": "灵台县",
                            "parentCode": "620800",
                            "fullCodes": "620000,620800,620822",
                            "fullNames": "甘肃省,平凉市,灵台县",
                            "children": []
                        },
                        {
                            "id": "620823",
                            "code": "620823",
                            "name": "崇信县",
                            "parentCode": "620800",
                            "fullCodes": "620000,620800,620823",
                            "fullNames": "甘肃省,平凉市,崇信县",
                            "children": []
                        },
                        {
                            "id": "620824",
                            "code": "620824",
                            "name": "华亭县",
                            "parentCode": "620800",
                            "fullCodes": "620000,620800,620824",
                            "fullNames": "甘肃省,平凉市,华亭县",
                            "children": []
                        },
                        {
                            "id": "620825",
                            "code": "620825",
                            "name": "庄浪县",
                            "parentCode": "620800",
                            "fullCodes": "620000,620800,620825",
                            "fullNames": "甘肃省,平凉市,庄浪县",
                            "children": []
                        },
                        {
                            "id": "620826",
                            "code": "620826",
                            "name": "静宁县",
                            "parentCode": "620800",
                            "fullCodes": "620000,620800,620826",
                            "fullNames": "甘肃省,平凉市,静宁县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "620900",
                    "code": "620900",
                    "name": "酒泉市",
                    "parentCode": "620000",
                    "fullCodes": "620000,620900",
                    "fullNames": "甘肃省,酒泉市",
                    "children": [
                        {
                            "id": "620902",
                            "code": "620902",
                            "name": "肃州区",
                            "parentCode": "620900",
                            "fullCodes": "620000,620900,620902",
                            "fullNames": "甘肃省,酒泉市,肃州区",
                            "children": []
                        },
                        {
                            "id": "620921",
                            "code": "620921",
                            "name": "金塔县",
                            "parentCode": "620900",
                            "fullCodes": "620000,620900,620921",
                            "fullNames": "甘肃省,酒泉市,金塔县",
                            "children": []
                        },
                        {
                            "id": "620922",
                            "code": "620922",
                            "name": "安西县",
                            "parentCode": "620900",
                            "fullCodes": "620000,620900,620922",
                            "fullNames": "甘肃省,酒泉市,安西县",
                            "children": []
                        },
                        {
                            "id": "620923",
                            "code": "620923",
                            "name": "肃北蒙古族自治县",
                            "parentCode": "620900",
                            "fullCodes": "620000,620900,620923",
                            "fullNames": "甘肃省,酒泉市,肃北蒙古族自治县",
                            "children": []
                        },
                        {
                            "id": "620924",
                            "code": "620924",
                            "name": "阿克塞哈萨克族自治县",
                            "parentCode": "620900",
                            "fullCodes": "620000,620900,620924",
                            "fullNames": "甘肃省,酒泉市,阿克塞哈萨克族自治县",
                            "children": []
                        },
                        {
                            "id": "620981",
                            "code": "620981",
                            "name": "玉门市",
                            "parentCode": "620900",
                            "fullCodes": "620000,620900,620981",
                            "fullNames": "甘肃省,酒泉市,玉门市",
                            "children": []
                        },
                        {
                            "id": "620982",
                            "code": "620982",
                            "name": "敦煌市",
                            "parentCode": "620900",
                            "fullCodes": "620000,620900,620982",
                            "fullNames": "甘肃省,酒泉市,敦煌市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "621000",
                    "code": "621000",
                    "name": "庆阳市",
                    "parentCode": "620000",
                    "fullCodes": "620000,621000",
                    "fullNames": "甘肃省,庆阳市",
                    "children": [
                        {
                            "id": "621002",
                            "code": "621002",
                            "name": "西峰区",
                            "parentCode": "621000",
                            "fullCodes": "620000,621000,621002",
                            "fullNames": "甘肃省,庆阳市,西峰区",
                            "children": []
                        },
                        {
                            "id": "621021",
                            "code": "621021",
                            "name": "庆城县",
                            "parentCode": "621000",
                            "fullCodes": "620000,621000,621021",
                            "fullNames": "甘肃省,庆阳市,庆城县",
                            "children": []
                        },
                        {
                            "id": "621022",
                            "code": "621022",
                            "name": "环县",
                            "parentCode": "621000",
                            "fullCodes": "620000,621000,621022",
                            "fullNames": "甘肃省,庆阳市,环县",
                            "children": []
                        },
                        {
                            "id": "621023",
                            "code": "621023",
                            "name": "华池县",
                            "parentCode": "621000",
                            "fullCodes": "620000,621000,621023",
                            "fullNames": "甘肃省,庆阳市,华池县",
                            "children": []
                        },
                        {
                            "id": "621024",
                            "code": "621024",
                            "name": "合水县",
                            "parentCode": "621000",
                            "fullCodes": "620000,621000,621024",
                            "fullNames": "甘肃省,庆阳市,合水县",
                            "children": []
                        },
                        {
                            "id": "621025",
                            "code": "621025",
                            "name": "正宁县",
                            "parentCode": "621000",
                            "fullCodes": "620000,621000,621025",
                            "fullNames": "甘肃省,庆阳市,正宁县",
                            "children": []
                        },
                        {
                            "id": "621026",
                            "code": "621026",
                            "name": "宁县",
                            "parentCode": "621000",
                            "fullCodes": "620000,621000,621026",
                            "fullNames": "甘肃省,庆阳市,宁县",
                            "children": []
                        },
                        {
                            "id": "621027",
                            "code": "621027",
                            "name": "镇原县",
                            "parentCode": "621000",
                            "fullCodes": "620000,621000,621027",
                            "fullNames": "甘肃省,庆阳市,镇原县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "621100",
                    "code": "621100",
                    "name": "定西市",
                    "parentCode": "620000",
                    "fullCodes": "620000,621100",
                    "fullNames": "甘肃省,定西市",
                    "children": [
                        {
                            "id": "621102",
                            "code": "621102",
                            "name": "安定区",
                            "parentCode": "621100",
                            "fullCodes": "620000,621100,621102",
                            "fullNames": "甘肃省,定西市,安定区",
                            "children": []
                        },
                        {
                            "id": "621121",
                            "code": "621121",
                            "name": "通渭县",
                            "parentCode": "621100",
                            "fullCodes": "620000,621100,621121",
                            "fullNames": "甘肃省,定西市,通渭县",
                            "children": []
                        },
                        {
                            "id": "621122",
                            "code": "621122",
                            "name": "陇西县",
                            "parentCode": "621100",
                            "fullCodes": "620000,621100,621122",
                            "fullNames": "甘肃省,定西市,陇西县",
                            "children": []
                        },
                        {
                            "id": "621123",
                            "code": "621123",
                            "name": "渭源县",
                            "parentCode": "621100",
                            "fullCodes": "620000,621100,621123",
                            "fullNames": "甘肃省,定西市,渭源县",
                            "children": []
                        },
                        {
                            "id": "621124",
                            "code": "621124",
                            "name": "临洮县",
                            "parentCode": "621100",
                            "fullCodes": "620000,621100,621124",
                            "fullNames": "甘肃省,定西市,临洮县",
                            "children": []
                        },
                        {
                            "id": "621125",
                            "code": "621125",
                            "name": "漳县",
                            "parentCode": "621100",
                            "fullCodes": "620000,621100,621125",
                            "fullNames": "甘肃省,定西市,漳县",
                            "children": []
                        },
                        {
                            "id": "621126",
                            "code": "621126",
                            "name": "岷县",
                            "parentCode": "621100",
                            "fullCodes": "620000,621100,621126",
                            "fullNames": "甘肃省,定西市,岷县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "621200",
                    "code": "621200",
                    "name": "陇南市",
                    "parentCode": "620000",
                    "fullCodes": "620000,621200",
                    "fullNames": "甘肃省,陇南市",
                    "children": [
                        {
                            "id": "621202",
                            "code": "621202",
                            "name": "武都区",
                            "parentCode": "621200",
                            "fullCodes": "620000,621200,621202",
                            "fullNames": "甘肃省,陇南市,武都区",
                            "children": []
                        },
                        {
                            "id": "621221",
                            "code": "621221",
                            "name": "成县",
                            "parentCode": "621200",
                            "fullCodes": "620000,621200,621221",
                            "fullNames": "甘肃省,陇南市,成县",
                            "children": []
                        },
                        {
                            "id": "621222",
                            "code": "621222",
                            "name": "文县",
                            "parentCode": "621200",
                            "fullCodes": "620000,621200,621222",
                            "fullNames": "甘肃省,陇南市,文县",
                            "children": []
                        },
                        {
                            "id": "621223",
                            "code": "621223",
                            "name": "宕昌县",
                            "parentCode": "621200",
                            "fullCodes": "620000,621200,621223",
                            "fullNames": "甘肃省,陇南市,宕昌县",
                            "children": []
                        },
                        {
                            "id": "621224",
                            "code": "621224",
                            "name": "康县",
                            "parentCode": "621200",
                            "fullCodes": "620000,621200,621224",
                            "fullNames": "甘肃省,陇南市,康县",
                            "children": []
                        },
                        {
                            "id": "621225",
                            "code": "621225",
                            "name": "西和县",
                            "parentCode": "621200",
                            "fullCodes": "620000,621200,621225",
                            "fullNames": "甘肃省,陇南市,西和县",
                            "children": []
                        },
                        {
                            "id": "621226",
                            "code": "621226",
                            "name": "礼县",
                            "parentCode": "621200",
                            "fullCodes": "620000,621200,621226",
                            "fullNames": "甘肃省,陇南市,礼县",
                            "children": []
                        },
                        {
                            "id": "621227",
                            "code": "621227",
                            "name": "徽县",
                            "parentCode": "621200",
                            "fullCodes": "620000,621200,621227",
                            "fullNames": "甘肃省,陇南市,徽县",
                            "children": []
                        },
                        {
                            "id": "621228",
                            "code": "621228",
                            "name": "两当县",
                            "parentCode": "621200",
                            "fullCodes": "620000,621200,621228",
                            "fullNames": "甘肃省,陇南市,两当县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "622900",
                    "code": "622900",
                    "name": "临夏回族自治州",
                    "parentCode": "620000",
                    "fullCodes": "620000,622900",
                    "fullNames": "甘肃省,临夏回族自治州",
                    "children": [
                        {
                            "id": "622901",
                            "code": "622901",
                            "name": "临夏市",
                            "parentCode": "622900",
                            "fullCodes": "620000,622900,622901",
                            "fullNames": "甘肃省,临夏回族自治州,临夏市",
                            "children": []
                        },
                        {
                            "id": "622921",
                            "code": "622921",
                            "name": "临夏县",
                            "parentCode": "622900",
                            "fullCodes": "620000,622900,622921",
                            "fullNames": "甘肃省,临夏回族自治州,临夏县",
                            "children": []
                        },
                        {
                            "id": "622922",
                            "code": "622922",
                            "name": "康乐县",
                            "parentCode": "622900",
                            "fullCodes": "620000,622900,622922",
                            "fullNames": "甘肃省,临夏回族自治州,康乐县",
                            "children": []
                        },
                        {
                            "id": "622923",
                            "code": "622923",
                            "name": "永靖县",
                            "parentCode": "622900",
                            "fullCodes": "620000,622900,622923",
                            "fullNames": "甘肃省,临夏回族自治州,永靖县",
                            "children": []
                        },
                        {
                            "id": "622924",
                            "code": "622924",
                            "name": "广河县",
                            "parentCode": "622900",
                            "fullCodes": "620000,622900,622924",
                            "fullNames": "甘肃省,临夏回族自治州,广河县",
                            "children": []
                        },
                        {
                            "id": "622925",
                            "code": "622925",
                            "name": "和政县",
                            "parentCode": "622900",
                            "fullCodes": "620000,622900,622925",
                            "fullNames": "甘肃省,临夏回族自治州,和政县",
                            "children": []
                        },
                        {
                            "id": "622926",
                            "code": "622926",
                            "name": "东乡族自治县",
                            "parentCode": "622900",
                            "fullCodes": "620000,622900,622926",
                            "fullNames": "甘肃省,临夏回族自治州,东乡族自治县",
                            "children": []
                        },
                        {
                            "id": "622927",
                            "code": "622927",
                            "name": "积石山保安族东乡族撒拉族自治县",
                            "parentCode": "622900",
                            "fullCodes": "620000,622900,622927",
                            "fullNames": "甘肃省,临夏回族自治州,积石山保安族东乡族撒拉族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "623000",
                    "code": "623000",
                    "name": "甘南藏族自治州",
                    "parentCode": "620000",
                    "fullCodes": "620000,623000",
                    "fullNames": "甘肃省,甘南藏族自治州",
                    "children": [
                        {
                            "id": "623001",
                            "code": "623001",
                            "name": "合作市",
                            "parentCode": "623000",
                            "fullCodes": "620000,623000,623001",
                            "fullNames": "甘肃省,甘南藏族自治州,合作市",
                            "children": []
                        },
                        {
                            "id": "623021",
                            "code": "623021",
                            "name": "临潭县",
                            "parentCode": "623000",
                            "fullCodes": "620000,623000,623021",
                            "fullNames": "甘肃省,甘南藏族自治州,临潭县",
                            "children": []
                        },
                        {
                            "id": "623022",
                            "code": "623022",
                            "name": "卓尼县",
                            "parentCode": "623000",
                            "fullCodes": "620000,623000,623022",
                            "fullNames": "甘肃省,甘南藏族自治州,卓尼县",
                            "children": []
                        },
                        {
                            "id": "623023",
                            "code": "623023",
                            "name": "舟曲县",
                            "parentCode": "623000",
                            "fullCodes": "620000,623000,623023",
                            "fullNames": "甘肃省,甘南藏族自治州,舟曲县",
                            "children": []
                        },
                        {
                            "id": "623024",
                            "code": "623024",
                            "name": "迭部县",
                            "parentCode": "623000",
                            "fullCodes": "620000,623000,623024",
                            "fullNames": "甘肃省,甘南藏族自治州,迭部县",
                            "children": []
                        },
                        {
                            "id": "623025",
                            "code": "623025",
                            "name": "玛曲县",
                            "parentCode": "623000",
                            "fullCodes": "620000,623000,623025",
                            "fullNames": "甘肃省,甘南藏族自治州,玛曲县",
                            "children": []
                        },
                        {
                            "id": "623026",
                            "code": "623026",
                            "name": "碌曲县",
                            "parentCode": "623000",
                            "fullCodes": "620000,623000,623026",
                            "fullNames": "甘肃省,甘南藏族自治州,碌曲县",
                            "children": []
                        },
                        {
                            "id": "623027",
                            "code": "623027",
                            "name": "夏河县",
                            "parentCode": "623000",
                            "fullCodes": "620000,623000,623027",
                            "fullNames": "甘肃省,甘南藏族自治州,夏河县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "630000",
            "code": "630000",
            "name": "青海省",
            "parentCode": "-1",
            "fullCodes": "630000",
            "fullNames": "青海省",
            "children": [
                {
                    "id": "630100",
                    "code": "630100",
                    "name": "西宁市",
                    "parentCode": "630000",
                    "fullCodes": "630000,630100",
                    "fullNames": "青海省,西宁市",
                    "children": [
                        {
                            "id": "630102",
                            "code": "630102",
                            "name": "城东区",
                            "parentCode": "630100",
                            "fullCodes": "630000,630100,630102",
                            "fullNames": "青海省,西宁市,城东区",
                            "children": []
                        },
                        {
                            "id": "630103",
                            "code": "630103",
                            "name": "城中区",
                            "parentCode": "630100",
                            "fullCodes": "630000,630100,630103",
                            "fullNames": "青海省,西宁市,城中区",
                            "children": []
                        },
                        {
                            "id": "630104",
                            "code": "630104",
                            "name": "城西区",
                            "parentCode": "630100",
                            "fullCodes": "630000,630100,630104",
                            "fullNames": "青海省,西宁市,城西区",
                            "children": []
                        },
                        {
                            "id": "630105",
                            "code": "630105",
                            "name": "城北区",
                            "parentCode": "630100",
                            "fullCodes": "630000,630100,630105",
                            "fullNames": "青海省,西宁市,城北区",
                            "children": []
                        },
                        {
                            "id": "630121",
                            "code": "630121",
                            "name": "大通回族土族自治县",
                            "parentCode": "630100",
                            "fullCodes": "630000,630100,630121",
                            "fullNames": "青海省,西宁市,大通回族土族自治县",
                            "children": []
                        },
                        {
                            "id": "630122",
                            "code": "630122",
                            "name": "湟中县",
                            "parentCode": "630100",
                            "fullCodes": "630000,630100,630122",
                            "fullNames": "青海省,西宁市,湟中县",
                            "children": []
                        },
                        {
                            "id": "630123",
                            "code": "630123",
                            "name": "湟源县",
                            "parentCode": "630100",
                            "fullCodes": "630000,630100,630123",
                            "fullNames": "青海省,西宁市,湟源县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "632100",
                    "code": "632100",
                    "name": "海东地区",
                    "parentCode": "630000",
                    "fullCodes": "630000,632100",
                    "fullNames": "青海省,海东地区",
                    "children": [
                        {
                            "id": "632121",
                            "code": "632121",
                            "name": "平安县",
                            "parentCode": "632100",
                            "fullCodes": "630000,632100,632121",
                            "fullNames": "青海省,海东地区,平安县",
                            "children": []
                        },
                        {
                            "id": "632122",
                            "code": "632122",
                            "name": "民和回族土族自治县",
                            "parentCode": "632100",
                            "fullCodes": "630000,632100,632122",
                            "fullNames": "青海省,海东地区,民和回族土族自治县",
                            "children": []
                        },
                        {
                            "id": "632123",
                            "code": "632123",
                            "name": "乐都县",
                            "parentCode": "632100",
                            "fullCodes": "630000,632100,632123",
                            "fullNames": "青海省,海东地区,乐都县",
                            "children": []
                        },
                        {
                            "id": "632126",
                            "code": "632126",
                            "name": "互助土族自治县",
                            "parentCode": "632100",
                            "fullCodes": "630000,632100,632126",
                            "fullNames": "青海省,海东地区,互助土族自治县",
                            "children": []
                        },
                        {
                            "id": "632127",
                            "code": "632127",
                            "name": "化隆回族自治县",
                            "parentCode": "632100",
                            "fullCodes": "630000,632100,632127",
                            "fullNames": "青海省,海东地区,化隆回族自治县",
                            "children": []
                        },
                        {
                            "id": "632128",
                            "code": "632128",
                            "name": "循化撒拉族自治县",
                            "parentCode": "632100",
                            "fullCodes": "630000,632100,632128",
                            "fullNames": "青海省,海东地区,循化撒拉族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "632200",
                    "code": "632200",
                    "name": "海北藏族自治州",
                    "parentCode": "630000",
                    "fullCodes": "630000,632200",
                    "fullNames": "青海省,海北藏族自治州",
                    "children": [
                        {
                            "id": "632221",
                            "code": "632221",
                            "name": "门源回族自治县",
                            "parentCode": "632200",
                            "fullCodes": "630000,632200,632221",
                            "fullNames": "青海省,海北藏族自治州,门源回族自治县",
                            "children": []
                        },
                        {
                            "id": "632222",
                            "code": "632222",
                            "name": "祁连县",
                            "parentCode": "632200",
                            "fullCodes": "630000,632200,632222",
                            "fullNames": "青海省,海北藏族自治州,祁连县",
                            "children": []
                        },
                        {
                            "id": "632223",
                            "code": "632223",
                            "name": "海晏县",
                            "parentCode": "632200",
                            "fullCodes": "630000,632200,632223",
                            "fullNames": "青海省,海北藏族自治州,海晏县",
                            "children": []
                        },
                        {
                            "id": "632224",
                            "code": "632224",
                            "name": "刚察县",
                            "parentCode": "632200",
                            "fullCodes": "630000,632200,632224",
                            "fullNames": "青海省,海北藏族自治州,刚察县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "632300",
                    "code": "632300",
                    "name": "黄南藏族自治州",
                    "parentCode": "630000",
                    "fullCodes": "630000,632300",
                    "fullNames": "青海省,黄南藏族自治州",
                    "children": [
                        {
                            "id": "632321",
                            "code": "632321",
                            "name": "同仁县",
                            "parentCode": "632300",
                            "fullCodes": "630000,632300,632321",
                            "fullNames": "青海省,黄南藏族自治州,同仁县",
                            "children": []
                        },
                        {
                            "id": "632322",
                            "code": "632322",
                            "name": "尖扎县",
                            "parentCode": "632300",
                            "fullCodes": "630000,632300,632322",
                            "fullNames": "青海省,黄南藏族自治州,尖扎县",
                            "children": []
                        },
                        {
                            "id": "632323",
                            "code": "632323",
                            "name": "泽库县",
                            "parentCode": "632300",
                            "fullCodes": "630000,632300,632323",
                            "fullNames": "青海省,黄南藏族自治州,泽库县",
                            "children": []
                        },
                        {
                            "id": "632324",
                            "code": "632324",
                            "name": "河南蒙古族自治县",
                            "parentCode": "632300",
                            "fullCodes": "630000,632300,632324",
                            "fullNames": "青海省,黄南藏族自治州,河南蒙古族自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "632500",
                    "code": "632500",
                    "name": "海南藏族自治州",
                    "parentCode": "630000",
                    "fullCodes": "630000,632500",
                    "fullNames": "青海省,海南藏族自治州",
                    "children": [
                        {
                            "id": "632521",
                            "code": "632521",
                            "name": "共和县",
                            "parentCode": "632500",
                            "fullCodes": "630000,632500,632521",
                            "fullNames": "青海省,海南藏族自治州,共和县",
                            "children": []
                        },
                        {
                            "id": "632522",
                            "code": "632522",
                            "name": "同德县",
                            "parentCode": "632500",
                            "fullCodes": "630000,632500,632522",
                            "fullNames": "青海省,海南藏族自治州,同德县",
                            "children": []
                        },
                        {
                            "id": "632523",
                            "code": "632523",
                            "name": "贵德县",
                            "parentCode": "632500",
                            "fullCodes": "630000,632500,632523",
                            "fullNames": "青海省,海南藏族自治州,贵德县",
                            "children": []
                        },
                        {
                            "id": "632524",
                            "code": "632524",
                            "name": "兴海县",
                            "parentCode": "632500",
                            "fullCodes": "630000,632500,632524",
                            "fullNames": "青海省,海南藏族自治州,兴海县",
                            "children": []
                        },
                        {
                            "id": "632525",
                            "code": "632525",
                            "name": "贵南县",
                            "parentCode": "632500",
                            "fullCodes": "630000,632500,632525",
                            "fullNames": "青海省,海南藏族自治州,贵南县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "632600",
                    "code": "632600",
                    "name": "果洛藏族自治州",
                    "parentCode": "630000",
                    "fullCodes": "630000,632600",
                    "fullNames": "青海省,果洛藏族自治州",
                    "children": [
                        {
                            "id": "632621",
                            "code": "632621",
                            "name": "玛沁县",
                            "parentCode": "632600",
                            "fullCodes": "630000,632600,632621",
                            "fullNames": "青海省,果洛藏族自治州,玛沁县",
                            "children": []
                        },
                        {
                            "id": "632622",
                            "code": "632622",
                            "name": "班玛县",
                            "parentCode": "632600",
                            "fullCodes": "630000,632600,632622",
                            "fullNames": "青海省,果洛藏族自治州,班玛县",
                            "children": []
                        },
                        {
                            "id": "632623",
                            "code": "632623",
                            "name": "甘德县",
                            "parentCode": "632600",
                            "fullCodes": "630000,632600,632623",
                            "fullNames": "青海省,果洛藏族自治州,甘德县",
                            "children": []
                        },
                        {
                            "id": "632624",
                            "code": "632624",
                            "name": "达日县",
                            "parentCode": "632600",
                            "fullCodes": "630000,632600,632624",
                            "fullNames": "青海省,果洛藏族自治州,达日县",
                            "children": []
                        },
                        {
                            "id": "632625",
                            "code": "632625",
                            "name": "久治县",
                            "parentCode": "632600",
                            "fullCodes": "630000,632600,632625",
                            "fullNames": "青海省,果洛藏族自治州,久治县",
                            "children": []
                        },
                        {
                            "id": "632626",
                            "code": "632626",
                            "name": "玛多县",
                            "parentCode": "632600",
                            "fullCodes": "630000,632600,632626",
                            "fullNames": "青海省,果洛藏族自治州,玛多县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "632700",
                    "code": "632700",
                    "name": "玉树藏族自治州",
                    "parentCode": "630000",
                    "fullCodes": "630000,632700",
                    "fullNames": "青海省,玉树藏族自治州",
                    "children": [
                        {
                            "id": "632721",
                            "code": "632721",
                            "name": "玉树县",
                            "parentCode": "632700",
                            "fullCodes": "630000,632700,632721",
                            "fullNames": "青海省,玉树藏族自治州,玉树县",
                            "children": []
                        },
                        {
                            "id": "632722",
                            "code": "632722",
                            "name": "杂多县",
                            "parentCode": "632700",
                            "fullCodes": "630000,632700,632722",
                            "fullNames": "青海省,玉树藏族自治州,杂多县",
                            "children": []
                        },
                        {
                            "id": "632723",
                            "code": "632723",
                            "name": "称多县",
                            "parentCode": "632700",
                            "fullCodes": "630000,632700,632723",
                            "fullNames": "青海省,玉树藏族自治州,称多县",
                            "children": []
                        },
                        {
                            "id": "632724",
                            "code": "632724",
                            "name": "治多县",
                            "parentCode": "632700",
                            "fullCodes": "630000,632700,632724",
                            "fullNames": "青海省,玉树藏族自治州,治多县",
                            "children": []
                        },
                        {
                            "id": "632725",
                            "code": "632725",
                            "name": "囊谦县",
                            "parentCode": "632700",
                            "fullCodes": "630000,632700,632725",
                            "fullNames": "青海省,玉树藏族自治州,囊谦县",
                            "children": []
                        },
                        {
                            "id": "632726",
                            "code": "632726",
                            "name": "曲麻莱县",
                            "parentCode": "632700",
                            "fullCodes": "630000,632700,632726",
                            "fullNames": "青海省,玉树藏族自治州,曲麻莱县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "632800",
                    "code": "632800",
                    "name": "海西蒙古族藏族自治州",
                    "parentCode": "630000",
                    "fullCodes": "630000,632800",
                    "fullNames": "青海省,海西蒙古族藏族自治州",
                    "children": [
                        {
                            "id": "632801",
                            "code": "632801",
                            "name": "格尔木市",
                            "parentCode": "632800",
                            "fullCodes": "630000,632800,632801",
                            "fullNames": "青海省,海西蒙古族藏族自治州,格尔木市",
                            "children": []
                        },
                        {
                            "id": "632802",
                            "code": "632802",
                            "name": "德令哈市",
                            "parentCode": "632800",
                            "fullCodes": "630000,632800,632802",
                            "fullNames": "青海省,海西蒙古族藏族自治州,德令哈市",
                            "children": []
                        },
                        {
                            "id": "632821",
                            "code": "632821",
                            "name": "乌兰县",
                            "parentCode": "632800",
                            "fullCodes": "630000,632800,632821",
                            "fullNames": "青海省,海西蒙古族藏族自治州,乌兰县",
                            "children": []
                        },
                        {
                            "id": "632822",
                            "code": "632822",
                            "name": "都兰县",
                            "parentCode": "632800",
                            "fullCodes": "630000,632800,632822",
                            "fullNames": "青海省,海西蒙古族藏族自治州,都兰县",
                            "children": []
                        },
                        {
                            "id": "632823",
                            "code": "632823",
                            "name": "天峻县",
                            "parentCode": "632800",
                            "fullCodes": "630000,632800,632823",
                            "fullNames": "青海省,海西蒙古族藏族自治州,天峻县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "640000",
            "code": "640000",
            "name": "宁夏回族自治区",
            "parentCode": "-1",
            "fullCodes": "640000",
            "fullNames": "宁夏回族自治区",
            "children": [
                {
                    "id": "640100",
                    "code": "640100",
                    "name": "银川市",
                    "parentCode": "640000",
                    "fullCodes": "640000,640100",
                    "fullNames": "宁夏回族自治区,银川市",
                    "children": [
                        {
                            "id": "640104",
                            "code": "640104",
                            "name": "兴庆区",
                            "parentCode": "640100",
                            "fullCodes": "640000,640100,640104",
                            "fullNames": "宁夏回族自治区,银川市,兴庆区",
                            "children": []
                        },
                        {
                            "id": "640105",
                            "code": "640105",
                            "name": "西夏区",
                            "parentCode": "640100",
                            "fullCodes": "640000,640100,640105",
                            "fullNames": "宁夏回族自治区,银川市,西夏区",
                            "children": []
                        },
                        {
                            "id": "640106",
                            "code": "640106",
                            "name": "金凤区",
                            "parentCode": "640100",
                            "fullCodes": "640000,640100,640106",
                            "fullNames": "宁夏回族自治区,银川市,金凤区",
                            "children": []
                        },
                        {
                            "id": "640121",
                            "code": "640121",
                            "name": "永宁县",
                            "parentCode": "640100",
                            "fullCodes": "640000,640100,640121",
                            "fullNames": "宁夏回族自治区,银川市,永宁县",
                            "children": []
                        },
                        {
                            "id": "640122",
                            "code": "640122",
                            "name": "贺兰县",
                            "parentCode": "640100",
                            "fullCodes": "640000,640100,640122",
                            "fullNames": "宁夏回族自治区,银川市,贺兰县",
                            "children": []
                        },
                        {
                            "id": "640181",
                            "code": "640181",
                            "name": "灵武市",
                            "parentCode": "640100",
                            "fullCodes": "640000,640100,640181",
                            "fullNames": "宁夏回族自治区,银川市,灵武市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "640200",
                    "code": "640200",
                    "name": "石嘴山市",
                    "parentCode": "640000",
                    "fullCodes": "640000,640200",
                    "fullNames": "宁夏回族自治区,石嘴山市",
                    "children": [
                        {
                            "id": "640202",
                            "code": "640202",
                            "name": "大武口区",
                            "parentCode": "640200",
                            "fullCodes": "640000,640200,640202",
                            "fullNames": "宁夏回族自治区,石嘴山市,大武口区",
                            "children": []
                        },
                        {
                            "id": "640205",
                            "code": "640205",
                            "name": "惠农区",
                            "parentCode": "640200",
                            "fullCodes": "640000,640200,640205",
                            "fullNames": "宁夏回族自治区,石嘴山市,惠农区",
                            "children": []
                        },
                        {
                            "id": "640221",
                            "code": "640221",
                            "name": "平罗县",
                            "parentCode": "640200",
                            "fullCodes": "640000,640200,640221",
                            "fullNames": "宁夏回族自治区,石嘴山市,平罗县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "640300",
                    "code": "640300",
                    "name": "吴忠市",
                    "parentCode": "640000",
                    "fullCodes": "640000,640300",
                    "fullNames": "宁夏回族自治区,吴忠市",
                    "children": [
                        {
                            "id": "640302",
                            "code": "640302",
                            "name": "利通区",
                            "parentCode": "640300",
                            "fullCodes": "640000,640300,640302",
                            "fullNames": "宁夏回族自治区,吴忠市,利通区",
                            "children": []
                        },
                        {
                            "id": "640303",
                            "code": "640303",
                            "name": "红寺堡区",
                            "parentCode": "640300",
                            "fullCodes": "640000,640300,640303",
                            "fullNames": "宁夏回族自治区,吴忠市,红寺堡区",
                            "children": []
                        },
                        {
                            "id": "640323",
                            "code": "640323",
                            "name": "盐池县",
                            "parentCode": "640300",
                            "fullCodes": "640000,640300,640323",
                            "fullNames": "宁夏回族自治区,吴忠市,盐池县",
                            "children": []
                        },
                        {
                            "id": "640324",
                            "code": "640324",
                            "name": "同心县",
                            "parentCode": "640300",
                            "fullCodes": "640000,640300,640324",
                            "fullNames": "宁夏回族自治区,吴忠市,同心县",
                            "children": []
                        },
                        {
                            "id": "640381",
                            "code": "640381",
                            "name": "青铜峡市",
                            "parentCode": "640300",
                            "fullCodes": "640000,640300,640381",
                            "fullNames": "宁夏回族自治区,吴忠市,青铜峡市",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "640400",
                    "code": "640400",
                    "name": "固原市",
                    "parentCode": "640000",
                    "fullCodes": "640000,640400",
                    "fullNames": "宁夏回族自治区,固原市",
                    "children": [
                        {
                            "id": "640402",
                            "code": "640402",
                            "name": "原州区",
                            "parentCode": "640400",
                            "fullCodes": "640000,640400,640402",
                            "fullNames": "宁夏回族自治区,固原市,原州区",
                            "children": []
                        },
                        {
                            "id": "640422",
                            "code": "640422",
                            "name": "西吉县",
                            "parentCode": "640400",
                            "fullCodes": "640000,640400,640422",
                            "fullNames": "宁夏回族自治区,固原市,西吉县",
                            "children": []
                        },
                        {
                            "id": "640423",
                            "code": "640423",
                            "name": "隆德县",
                            "parentCode": "640400",
                            "fullCodes": "640000,640400,640423",
                            "fullNames": "宁夏回族自治区,固原市,隆德县",
                            "children": []
                        },
                        {
                            "id": "640424",
                            "code": "640424",
                            "name": "泾源县",
                            "parentCode": "640400",
                            "fullCodes": "640000,640400,640424",
                            "fullNames": "宁夏回族自治区,固原市,泾源县",
                            "children": []
                        },
                        {
                            "id": "640425",
                            "code": "640425",
                            "name": "彭阳县",
                            "parentCode": "640400",
                            "fullCodes": "640000,640400,640425",
                            "fullNames": "宁夏回族自治区,固原市,彭阳县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "640500",
                    "code": "640500",
                    "name": "中卫市",
                    "parentCode": "640000",
                    "fullCodes": "640000,640500",
                    "fullNames": "宁夏回族自治区,中卫市",
                    "children": [
                        {
                            "id": "640502",
                            "code": "640502",
                            "name": "沙坡头区",
                            "parentCode": "640500",
                            "fullCodes": "640000,640500,640502",
                            "fullNames": "宁夏回族自治区,中卫市,沙坡头区",
                            "children": []
                        },
                        {
                            "id": "640521",
                            "code": "640521",
                            "name": "中宁县",
                            "parentCode": "640500",
                            "fullCodes": "640000,640500,640521",
                            "fullNames": "宁夏回族自治区,中卫市,中宁县",
                            "children": []
                        },
                        {
                            "id": "640522",
                            "code": "640522",
                            "name": "海原县",
                            "parentCode": "640500",
                            "fullCodes": "640000,640500,640522",
                            "fullNames": "宁夏回族自治区,中卫市,海原县",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "650000",
            "code": "650000",
            "name": "新疆维吾尔自治区",
            "parentCode": "-1",
            "fullCodes": "650000",
            "fullNames": "新疆维吾尔自治区",
            "children": [
                {
                    "id": "650100",
                    "code": "650100",
                    "name": "乌鲁木齐市",
                    "parentCode": "650000",
                    "fullCodes": "650000,650100",
                    "fullNames": "新疆维吾尔自治区,乌鲁木齐市",
                    "children": [
                        {
                            "id": "650102",
                            "code": "650102",
                            "name": "天山区",
                            "parentCode": "650100",
                            "fullCodes": "650000,650100,650102",
                            "fullNames": "新疆维吾尔自治区,乌鲁木齐市,天山区",
                            "children": []
                        },
                        {
                            "id": "650103",
                            "code": "650103",
                            "name": "沙依巴克区",
                            "parentCode": "650100",
                            "fullCodes": "650000,650100,650103",
                            "fullNames": "新疆维吾尔自治区,乌鲁木齐市,沙依巴克区",
                            "children": []
                        },
                        {
                            "id": "650104",
                            "code": "650104",
                            "name": "新市区",
                            "parentCode": "650100",
                            "fullCodes": "650000,650100,650104",
                            "fullNames": "新疆维吾尔自治区,乌鲁木齐市,新市区",
                            "children": []
                        },
                        {
                            "id": "650105",
                            "code": "650105",
                            "name": "水磨沟区",
                            "parentCode": "650100",
                            "fullCodes": "650000,650100,650105",
                            "fullNames": "新疆维吾尔自治区,乌鲁木齐市,水磨沟区",
                            "children": []
                        },
                        {
                            "id": "650106",
                            "code": "650106",
                            "name": "头屯河区",
                            "parentCode": "650100",
                            "fullCodes": "650000,650100,650106",
                            "fullNames": "新疆维吾尔自治区,乌鲁木齐市,头屯河区",
                            "children": []
                        },
                        {
                            "id": "650107",
                            "code": "650107",
                            "name": "达坂城区",
                            "parentCode": "650100",
                            "fullCodes": "650000,650100,650107",
                            "fullNames": "新疆维吾尔自治区,乌鲁木齐市,达坂城区",
                            "children": []
                        },
                        {
                            "id": "650108",
                            "code": "650108",
                            "name": "东山区",
                            "parentCode": "650100",
                            "fullCodes": "650000,650100,650108",
                            "fullNames": "新疆维吾尔自治区,乌鲁木齐市,东山区",
                            "children": []
                        },
                        {
                            "id": "650109",
                            "code": "650109",
                            "name": "米东区",
                            "parentCode": "650100",
                            "fullCodes": "650000,650100,650109",
                            "fullNames": "新疆维吾尔自治区,乌鲁木齐市,米东区",
                            "children": []
                        },
                        {
                            "id": "650121",
                            "code": "650121",
                            "name": "乌鲁木齐县",
                            "parentCode": "650100",
                            "fullCodes": "650000,650100,650121",
                            "fullNames": "新疆维吾尔自治区,乌鲁木齐市,乌鲁木齐县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "650200",
                    "code": "650200",
                    "name": "克拉玛依市",
                    "parentCode": "650000",
                    "fullCodes": "650000,650200",
                    "fullNames": "新疆维吾尔自治区,克拉玛依市",
                    "children": [
                        {
                            "id": "650202",
                            "code": "650202",
                            "name": "独山子区",
                            "parentCode": "650200",
                            "fullCodes": "650000,650200,650202",
                            "fullNames": "新疆维吾尔自治区,克拉玛依市,独山子区",
                            "children": []
                        },
                        {
                            "id": "650203",
                            "code": "650203",
                            "name": "克拉玛依区",
                            "parentCode": "650200",
                            "fullCodes": "650000,650200,650203",
                            "fullNames": "新疆维吾尔自治区,克拉玛依市,克拉玛依区",
                            "children": []
                        },
                        {
                            "id": "650204",
                            "code": "650204",
                            "name": "白碱滩区",
                            "parentCode": "650200",
                            "fullCodes": "650000,650200,650204",
                            "fullNames": "新疆维吾尔自治区,克拉玛依市,白碱滩区",
                            "children": []
                        },
                        {
                            "id": "650205",
                            "code": "650205",
                            "name": "乌尔禾区",
                            "parentCode": "650200",
                            "fullCodes": "650000,650200,650205",
                            "fullNames": "新疆维吾尔自治区,克拉玛依市,乌尔禾区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "652100",
                    "code": "652100",
                    "name": "吐鲁番地区",
                    "parentCode": "650000",
                    "fullCodes": "650000,652100",
                    "fullNames": "新疆维吾尔自治区,吐鲁番地区",
                    "children": [
                        {
                            "id": "652101",
                            "code": "652101",
                            "name": "吐鲁番市",
                            "parentCode": "652100",
                            "fullCodes": "650000,652100,652101",
                            "fullNames": "新疆维吾尔自治区,吐鲁番地区,吐鲁番市",
                            "children": []
                        },
                        {
                            "id": "652122",
                            "code": "652122",
                            "name": "鄯善县",
                            "parentCode": "652100",
                            "fullCodes": "650000,652100,652122",
                            "fullNames": "新疆维吾尔自治区,吐鲁番地区,鄯善县",
                            "children": []
                        },
                        {
                            "id": "652123",
                            "code": "652123",
                            "name": "托克逊县",
                            "parentCode": "652100",
                            "fullCodes": "650000,652100,652123",
                            "fullNames": "新疆维吾尔自治区,吐鲁番地区,托克逊县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "652200",
                    "code": "652200",
                    "name": "哈密地区",
                    "parentCode": "650000",
                    "fullCodes": "650000,652200",
                    "fullNames": "新疆维吾尔自治区,哈密地区",
                    "children": [
                        {
                            "id": "652201",
                            "code": "652201",
                            "name": "哈密市",
                            "parentCode": "652200",
                            "fullCodes": "650000,652200,652201",
                            "fullNames": "新疆维吾尔自治区,哈密地区,哈密市",
                            "children": []
                        },
                        {
                            "id": "652222",
                            "code": "652222",
                            "name": "巴里坤哈萨克自治县",
                            "parentCode": "652200",
                            "fullCodes": "650000,652200,652222",
                            "fullNames": "新疆维吾尔自治区,哈密地区,巴里坤哈萨克自治县",
                            "children": []
                        },
                        {
                            "id": "652223",
                            "code": "652223",
                            "name": "伊吾县",
                            "parentCode": "652200",
                            "fullCodes": "650000,652200,652223",
                            "fullNames": "新疆维吾尔自治区,哈密地区,伊吾县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "652300",
                    "code": "652300",
                    "name": "昌吉回族自治州",
                    "parentCode": "650000",
                    "fullCodes": "650000,652300",
                    "fullNames": "新疆维吾尔自治区,昌吉回族自治州",
                    "children": [
                        {
                            "id": "652301",
                            "code": "652301",
                            "name": "昌吉市",
                            "parentCode": "652300",
                            "fullCodes": "650000,652300,652301",
                            "fullNames": "新疆维吾尔自治区,昌吉回族自治州,昌吉市",
                            "children": []
                        },
                        {
                            "id": "652302",
                            "code": "652302",
                            "name": "阜康市",
                            "parentCode": "652300",
                            "fullCodes": "650000,652300,652302",
                            "fullNames": "新疆维吾尔自治区,昌吉回族自治州,阜康市",
                            "children": []
                        },
                        {
                            "id": "652303",
                            "code": "652303",
                            "name": "米泉市",
                            "parentCode": "652300",
                            "fullCodes": "650000,652300,652303",
                            "fullNames": "新疆维吾尔自治区,昌吉回族自治州,米泉市",
                            "children": []
                        },
                        {
                            "id": "652323",
                            "code": "652323",
                            "name": "呼图壁县",
                            "parentCode": "652300",
                            "fullCodes": "650000,652300,652323",
                            "fullNames": "新疆维吾尔自治区,昌吉回族自治州,呼图壁县",
                            "children": []
                        },
                        {
                            "id": "652324",
                            "code": "652324",
                            "name": "玛纳斯县",
                            "parentCode": "652300",
                            "fullCodes": "650000,652300,652324",
                            "fullNames": "新疆维吾尔自治区,昌吉回族自治州,玛纳斯县",
                            "children": []
                        },
                        {
                            "id": "652325",
                            "code": "652325",
                            "name": "奇台县",
                            "parentCode": "652300",
                            "fullCodes": "650000,652300,652325",
                            "fullNames": "新疆维吾尔自治区,昌吉回族自治州,奇台县",
                            "children": []
                        },
                        {
                            "id": "652327",
                            "code": "652327",
                            "name": "吉木萨尔县",
                            "parentCode": "652300",
                            "fullCodes": "650000,652300,652327",
                            "fullNames": "新疆维吾尔自治区,昌吉回族自治州,吉木萨尔县",
                            "children": []
                        },
                        {
                            "id": "652328",
                            "code": "652328",
                            "name": "木垒哈萨克自治县",
                            "parentCode": "652300",
                            "fullCodes": "650000,652300,652328",
                            "fullNames": "新疆维吾尔自治区,昌吉回族自治州,木垒哈萨克自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "652700",
                    "code": "652700",
                    "name": "博尔塔拉蒙古自治州",
                    "parentCode": "650000",
                    "fullCodes": "650000,652700",
                    "fullNames": "新疆维吾尔自治区,博尔塔拉蒙古自治州",
                    "children": [
                        {
                            "id": "652701",
                            "code": "652701",
                            "name": "博乐市",
                            "parentCode": "652700",
                            "fullCodes": "650000,652700,652701",
                            "fullNames": "新疆维吾尔自治区,博尔塔拉蒙古自治州,博乐市",
                            "children": []
                        },
                        {
                            "id": "652722",
                            "code": "652722",
                            "name": "精河县",
                            "parentCode": "652700",
                            "fullCodes": "650000,652700,652722",
                            "fullNames": "新疆维吾尔自治区,博尔塔拉蒙古自治州,精河县",
                            "children": []
                        },
                        {
                            "id": "652723",
                            "code": "652723",
                            "name": "温泉县",
                            "parentCode": "652700",
                            "fullCodes": "650000,652700,652723",
                            "fullNames": "新疆维吾尔自治区,博尔塔拉蒙古自治州,温泉县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "652800",
                    "code": "652800",
                    "name": "巴音郭楞蒙古自治州",
                    "parentCode": "650000",
                    "fullCodes": "650000,652800",
                    "fullNames": "新疆维吾尔自治区,巴音郭楞蒙古自治州",
                    "children": [
                        {
                            "id": "652801",
                            "code": "652801",
                            "name": "库尔勒市",
                            "parentCode": "652800",
                            "fullCodes": "650000,652800,652801",
                            "fullNames": "新疆维吾尔自治区,巴音郭楞蒙古自治州,库尔勒市",
                            "children": []
                        },
                        {
                            "id": "652822",
                            "code": "652822",
                            "name": "轮台县",
                            "parentCode": "652800",
                            "fullCodes": "650000,652800,652822",
                            "fullNames": "新疆维吾尔自治区,巴音郭楞蒙古自治州,轮台县",
                            "children": []
                        },
                        {
                            "id": "652823",
                            "code": "652823",
                            "name": "尉犁县",
                            "parentCode": "652800",
                            "fullCodes": "650000,652800,652823",
                            "fullNames": "新疆维吾尔自治区,巴音郭楞蒙古自治州,尉犁县",
                            "children": []
                        },
                        {
                            "id": "652824",
                            "code": "652824",
                            "name": "若羌县",
                            "parentCode": "652800",
                            "fullCodes": "650000,652800,652824",
                            "fullNames": "新疆维吾尔自治区,巴音郭楞蒙古自治州,若羌县",
                            "children": []
                        },
                        {
                            "id": "652825",
                            "code": "652825",
                            "name": "且末县",
                            "parentCode": "652800",
                            "fullCodes": "650000,652800,652825",
                            "fullNames": "新疆维吾尔自治区,巴音郭楞蒙古自治州,且末县",
                            "children": []
                        },
                        {
                            "id": "652826",
                            "code": "652826",
                            "name": "焉耆回族自治县",
                            "parentCode": "652800",
                            "fullCodes": "650000,652800,652826",
                            "fullNames": "新疆维吾尔自治区,巴音郭楞蒙古自治州,焉耆回族自治县",
                            "children": []
                        },
                        {
                            "id": "652827",
                            "code": "652827",
                            "name": "和静县",
                            "parentCode": "652800",
                            "fullCodes": "650000,652800,652827",
                            "fullNames": "新疆维吾尔自治区,巴音郭楞蒙古自治州,和静县",
                            "children": []
                        },
                        {
                            "id": "652828",
                            "code": "652828",
                            "name": "和硕县",
                            "parentCode": "652800",
                            "fullCodes": "650000,652800,652828",
                            "fullNames": "新疆维吾尔自治区,巴音郭楞蒙古自治州,和硕县",
                            "children": []
                        },
                        {
                            "id": "652829",
                            "code": "652829",
                            "name": "博湖县",
                            "parentCode": "652800",
                            "fullCodes": "650000,652800,652829",
                            "fullNames": "新疆维吾尔自治区,巴音郭楞蒙古自治州,博湖县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "652900",
                    "code": "652900",
                    "name": "阿克苏地区",
                    "parentCode": "650000",
                    "fullCodes": "650000,652900",
                    "fullNames": "新疆维吾尔自治区,阿克苏地区",
                    "children": [
                        {
                            "id": "652901",
                            "code": "652901",
                            "name": "阿克苏市",
                            "parentCode": "652900",
                            "fullCodes": "650000,652900,652901",
                            "fullNames": "新疆维吾尔自治区,阿克苏地区,阿克苏市",
                            "children": []
                        },
                        {
                            "id": "652922",
                            "code": "652922",
                            "name": "温宿县",
                            "parentCode": "652900",
                            "fullCodes": "650000,652900,652922",
                            "fullNames": "新疆维吾尔自治区,阿克苏地区,温宿县",
                            "children": []
                        },
                        {
                            "id": "652923",
                            "code": "652923",
                            "name": "库车县",
                            "parentCode": "652900",
                            "fullCodes": "650000,652900,652923",
                            "fullNames": "新疆维吾尔自治区,阿克苏地区,库车县",
                            "children": []
                        },
                        {
                            "id": "652924",
                            "code": "652924",
                            "name": "沙雅县",
                            "parentCode": "652900",
                            "fullCodes": "650000,652900,652924",
                            "fullNames": "新疆维吾尔自治区,阿克苏地区,沙雅县",
                            "children": []
                        },
                        {
                            "id": "652925",
                            "code": "652925",
                            "name": "新和县",
                            "parentCode": "652900",
                            "fullCodes": "650000,652900,652925",
                            "fullNames": "新疆维吾尔自治区,阿克苏地区,新和县",
                            "children": []
                        },
                        {
                            "id": "652926",
                            "code": "652926",
                            "name": "拜城县",
                            "parentCode": "652900",
                            "fullCodes": "650000,652900,652926",
                            "fullNames": "新疆维吾尔自治区,阿克苏地区,拜城县",
                            "children": []
                        },
                        {
                            "id": "652927",
                            "code": "652927",
                            "name": "乌什县",
                            "parentCode": "652900",
                            "fullCodes": "650000,652900,652927",
                            "fullNames": "新疆维吾尔自治区,阿克苏地区,乌什县",
                            "children": []
                        },
                        {
                            "id": "652928",
                            "code": "652928",
                            "name": "阿瓦提县",
                            "parentCode": "652900",
                            "fullCodes": "650000,652900,652928",
                            "fullNames": "新疆维吾尔自治区,阿克苏地区,阿瓦提县",
                            "children": []
                        },
                        {
                            "id": "652929",
                            "code": "652929",
                            "name": "柯坪县",
                            "parentCode": "652900",
                            "fullCodes": "650000,652900,652929",
                            "fullNames": "新疆维吾尔自治区,阿克苏地区,柯坪县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "653000",
                    "code": "653000",
                    "name": "克孜勒苏柯尔克孜自治州",
                    "parentCode": "650000",
                    "fullCodes": "650000,653000",
                    "fullNames": "新疆维吾尔自治区,克孜勒苏柯尔克孜自治州",
                    "children": [
                        {
                            "id": "653001",
                            "code": "653001",
                            "name": "阿图什市",
                            "parentCode": "653000",
                            "fullCodes": "650000,653000,653001",
                            "fullNames": "新疆维吾尔自治区,克孜勒苏柯尔克孜自治州,阿图什市",
                            "children": []
                        },
                        {
                            "id": "653022",
                            "code": "653022",
                            "name": "阿克陶县",
                            "parentCode": "653000",
                            "fullCodes": "650000,653000,653022",
                            "fullNames": "新疆维吾尔自治区,克孜勒苏柯尔克孜自治州,阿克陶县",
                            "children": []
                        },
                        {
                            "id": "653023",
                            "code": "653023",
                            "name": "阿合奇县",
                            "parentCode": "653000",
                            "fullCodes": "650000,653000,653023",
                            "fullNames": "新疆维吾尔自治区,克孜勒苏柯尔克孜自治州,阿合奇县",
                            "children": []
                        },
                        {
                            "id": "653024",
                            "code": "653024",
                            "name": "乌恰县",
                            "parentCode": "653000",
                            "fullCodes": "650000,653000,653024",
                            "fullNames": "新疆维吾尔自治区,克孜勒苏柯尔克孜自治州,乌恰县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "653100",
                    "code": "653100",
                    "name": "喀什地区",
                    "parentCode": "650000",
                    "fullCodes": "650000,653100",
                    "fullNames": "新疆维吾尔自治区,喀什地区",
                    "children": [
                        {
                            "id": "653101",
                            "code": "653101",
                            "name": "喀什市",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653101",
                            "fullNames": "新疆维吾尔自治区,喀什地区,喀什市",
                            "children": []
                        },
                        {
                            "id": "653121",
                            "code": "653121",
                            "name": "疏附县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653121",
                            "fullNames": "新疆维吾尔自治区,喀什地区,疏附县",
                            "children": []
                        },
                        {
                            "id": "653122",
                            "code": "653122",
                            "name": "疏勒县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653122",
                            "fullNames": "新疆维吾尔自治区,喀什地区,疏勒县",
                            "children": []
                        },
                        {
                            "id": "653123",
                            "code": "653123",
                            "name": "英吉沙县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653123",
                            "fullNames": "新疆维吾尔自治区,喀什地区,英吉沙县",
                            "children": []
                        },
                        {
                            "id": "653124",
                            "code": "653124",
                            "name": "泽普县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653124",
                            "fullNames": "新疆维吾尔自治区,喀什地区,泽普县",
                            "children": []
                        },
                        {
                            "id": "653125",
                            "code": "653125",
                            "name": "莎车县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653125",
                            "fullNames": "新疆维吾尔自治区,喀什地区,莎车县",
                            "children": []
                        },
                        {
                            "id": "653126",
                            "code": "653126",
                            "name": "叶城县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653126",
                            "fullNames": "新疆维吾尔自治区,喀什地区,叶城县",
                            "children": []
                        },
                        {
                            "id": "653127",
                            "code": "653127",
                            "name": "麦盖提县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653127",
                            "fullNames": "新疆维吾尔自治区,喀什地区,麦盖提县",
                            "children": []
                        },
                        {
                            "id": "653128",
                            "code": "653128",
                            "name": "岳普湖县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653128",
                            "fullNames": "新疆维吾尔自治区,喀什地区,岳普湖县",
                            "children": []
                        },
                        {
                            "id": "653129",
                            "code": "653129",
                            "name": "伽师县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653129",
                            "fullNames": "新疆维吾尔自治区,喀什地区,伽师县",
                            "children": []
                        },
                        {
                            "id": "653130",
                            "code": "653130",
                            "name": "巴楚县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653130",
                            "fullNames": "新疆维吾尔自治区,喀什地区,巴楚县",
                            "children": []
                        },
                        {
                            "id": "653131",
                            "code": "653131",
                            "name": "塔什库尔干塔吉克自治县",
                            "parentCode": "653100",
                            "fullCodes": "650000,653100,653131",
                            "fullNames": "新疆维吾尔自治区,喀什地区,塔什库尔干塔吉克自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "653200",
                    "code": "653200",
                    "name": "和田地区",
                    "parentCode": "650000",
                    "fullCodes": "650000,653200",
                    "fullNames": "新疆维吾尔自治区,和田地区",
                    "children": [
                        {
                            "id": "653201",
                            "code": "653201",
                            "name": "和田市",
                            "parentCode": "653200",
                            "fullCodes": "650000,653200,653201",
                            "fullNames": "新疆维吾尔自治区,和田地区,和田市",
                            "children": []
                        },
                        {
                            "id": "653221",
                            "code": "653221",
                            "name": "和田县",
                            "parentCode": "653200",
                            "fullCodes": "650000,653200,653221",
                            "fullNames": "新疆维吾尔自治区,和田地区,和田县",
                            "children": []
                        },
                        {
                            "id": "653222",
                            "code": "653222",
                            "name": "墨玉县",
                            "parentCode": "653200",
                            "fullCodes": "650000,653200,653222",
                            "fullNames": "新疆维吾尔自治区,和田地区,墨玉县",
                            "children": []
                        },
                        {
                            "id": "653223",
                            "code": "653223",
                            "name": "皮山县",
                            "parentCode": "653200",
                            "fullCodes": "650000,653200,653223",
                            "fullNames": "新疆维吾尔自治区,和田地区,皮山县",
                            "children": []
                        },
                        {
                            "id": "653224",
                            "code": "653224",
                            "name": "洛浦县",
                            "parentCode": "653200",
                            "fullCodes": "650000,653200,653224",
                            "fullNames": "新疆维吾尔自治区,和田地区,洛浦县",
                            "children": []
                        },
                        {
                            "id": "653225",
                            "code": "653225",
                            "name": "策勒县",
                            "parentCode": "653200",
                            "fullCodes": "650000,653200,653225",
                            "fullNames": "新疆维吾尔自治区,和田地区,策勒县",
                            "children": []
                        },
                        {
                            "id": "653226",
                            "code": "653226",
                            "name": "于田县",
                            "parentCode": "653200",
                            "fullCodes": "650000,653200,653226",
                            "fullNames": "新疆维吾尔自治区,和田地区,于田县",
                            "children": []
                        },
                        {
                            "id": "653227",
                            "code": "653227",
                            "name": "民丰县",
                            "parentCode": "653200",
                            "fullCodes": "650000,653200,653227",
                            "fullNames": "新疆维吾尔自治区,和田地区,民丰县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "654000",
                    "code": "654000",
                    "name": "伊犁哈萨克自治州",
                    "parentCode": "650000",
                    "fullCodes": "650000,654000",
                    "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州",
                    "children": [
                        {
                            "id": "654002",
                            "code": "654002",
                            "name": "伊宁市",
                            "parentCode": "654000",
                            "fullCodes": "650000,654000,654002",
                            "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州,伊宁市",
                            "children": []
                        },
                        {
                            "id": "654003",
                            "code": "654003",
                            "name": "奎屯市",
                            "parentCode": "654000",
                            "fullCodes": "650000,654000,654003",
                            "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州,奎屯市",
                            "children": []
                        },
                        {
                            "id": "654021",
                            "code": "654021",
                            "name": "伊宁县",
                            "parentCode": "654000",
                            "fullCodes": "650000,654000,654021",
                            "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州,伊宁县",
                            "children": []
                        },
                        {
                            "id": "654022",
                            "code": "654022",
                            "name": "察布查尔锡伯自治县",
                            "parentCode": "654000",
                            "fullCodes": "650000,654000,654022",
                            "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州,察布查尔锡伯自治县",
                            "children": []
                        },
                        {
                            "id": "654023",
                            "code": "654023",
                            "name": "霍城县",
                            "parentCode": "654000",
                            "fullCodes": "650000,654000,654023",
                            "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州,霍城县",
                            "children": []
                        },
                        {
                            "id": "654024",
                            "code": "654024",
                            "name": "巩留县",
                            "parentCode": "654000",
                            "fullCodes": "650000,654000,654024",
                            "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州,巩留县",
                            "children": []
                        },
                        {
                            "id": "654025",
                            "code": "654025",
                            "name": "新源县",
                            "parentCode": "654000",
                            "fullCodes": "650000,654000,654025",
                            "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州,新源县",
                            "children": []
                        },
                        {
                            "id": "654026",
                            "code": "654026",
                            "name": "昭苏县",
                            "parentCode": "654000",
                            "fullCodes": "650000,654000,654026",
                            "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州,昭苏县",
                            "children": []
                        },
                        {
                            "id": "654027",
                            "code": "654027",
                            "name": "特克斯县",
                            "parentCode": "654000",
                            "fullCodes": "650000,654000,654027",
                            "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州,特克斯县",
                            "children": []
                        },
                        {
                            "id": "654028",
                            "code": "654028",
                            "name": "尼勒克县",
                            "parentCode": "654000",
                            "fullCodes": "650000,654000,654028",
                            "fullNames": "新疆维吾尔自治区,伊犁哈萨克自治州,尼勒克县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "654200",
                    "code": "654200",
                    "name": "塔城地区",
                    "parentCode": "650000",
                    "fullCodes": "650000,654200",
                    "fullNames": "新疆维吾尔自治区,塔城地区",
                    "children": [
                        {
                            "id": "654201",
                            "code": "654201",
                            "name": "塔城市",
                            "parentCode": "654200",
                            "fullCodes": "650000,654200,654201",
                            "fullNames": "新疆维吾尔自治区,塔城地区,塔城市",
                            "children": []
                        },
                        {
                            "id": "654202",
                            "code": "654202",
                            "name": "乌苏市",
                            "parentCode": "654200",
                            "fullCodes": "650000,654200,654202",
                            "fullNames": "新疆维吾尔自治区,塔城地区,乌苏市",
                            "children": []
                        },
                        {
                            "id": "654221",
                            "code": "654221",
                            "name": "额敏县",
                            "parentCode": "654200",
                            "fullCodes": "650000,654200,654221",
                            "fullNames": "新疆维吾尔自治区,塔城地区,额敏县",
                            "children": []
                        },
                        {
                            "id": "654223",
                            "code": "654223",
                            "name": "沙湾县",
                            "parentCode": "654200",
                            "fullCodes": "650000,654200,654223",
                            "fullNames": "新疆维吾尔自治区,塔城地区,沙湾县",
                            "children": []
                        },
                        {
                            "id": "654224",
                            "code": "654224",
                            "name": "托里县",
                            "parentCode": "654200",
                            "fullCodes": "650000,654200,654224",
                            "fullNames": "新疆维吾尔自治区,塔城地区,托里县",
                            "children": []
                        },
                        {
                            "id": "654225",
                            "code": "654225",
                            "name": "裕民县",
                            "parentCode": "654200",
                            "fullCodes": "650000,654200,654225",
                            "fullNames": "新疆维吾尔自治区,塔城地区,裕民县",
                            "children": []
                        },
                        {
                            "id": "654226",
                            "code": "654226",
                            "name": "和布克赛尔蒙古自治县",
                            "parentCode": "654200",
                            "fullCodes": "650000,654200,654226",
                            "fullNames": "新疆维吾尔自治区,塔城地区,和布克赛尔蒙古自治县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "654300",
                    "code": "654300",
                    "name": "阿勒泰地区",
                    "parentCode": "650000",
                    "fullCodes": "650000,654300",
                    "fullNames": "新疆维吾尔自治区,阿勒泰地区",
                    "children": [
                        {
                            "id": "654301",
                            "code": "654301",
                            "name": "阿勒泰市",
                            "parentCode": "654300",
                            "fullCodes": "650000,654300,654301",
                            "fullNames": "新疆维吾尔自治区,阿勒泰地区,阿勒泰市",
                            "children": []
                        },
                        {
                            "id": "654321",
                            "code": "654321",
                            "name": "布尔津县",
                            "parentCode": "654300",
                            "fullCodes": "650000,654300,654321",
                            "fullNames": "新疆维吾尔自治区,阿勒泰地区,布尔津县",
                            "children": []
                        },
                        {
                            "id": "654322",
                            "code": "654322",
                            "name": "富蕴县",
                            "parentCode": "654300",
                            "fullCodes": "650000,654300,654322",
                            "fullNames": "新疆维吾尔自治区,阿勒泰地区,富蕴县",
                            "children": []
                        },
                        {
                            "id": "654323",
                            "code": "654323",
                            "name": "福海县",
                            "parentCode": "654300",
                            "fullCodes": "650000,654300,654323",
                            "fullNames": "新疆维吾尔自治区,阿勒泰地区,福海县",
                            "children": []
                        },
                        {
                            "id": "654324",
                            "code": "654324",
                            "name": "哈巴河县",
                            "parentCode": "654300",
                            "fullCodes": "650000,654300,654324",
                            "fullNames": "新疆维吾尔自治区,阿勒泰地区,哈巴河县",
                            "children": []
                        },
                        {
                            "id": "654325",
                            "code": "654325",
                            "name": "青河县",
                            "parentCode": "654300",
                            "fullCodes": "650000,654300,654325",
                            "fullNames": "新疆维吾尔自治区,阿勒泰地区,青河县",
                            "children": []
                        },
                        {
                            "id": "654326",
                            "code": "654326",
                            "name": "吉木乃县",
                            "parentCode": "654300",
                            "fullCodes": "650000,654300,654326",
                            "fullNames": "新疆维吾尔自治区,阿勒泰地区,吉木乃县",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "659001",
                    "code": "659001",
                    "name": "石河子市",
                    "parentCode": "650000",
                    "fullCodes": "650000,659001",
                    "fullNames": "新疆维吾尔自治区,石河子市",
                    "children": []
                },
                {
                    "id": "659002",
                    "code": "659002",
                    "name": "阿拉尔市",
                    "parentCode": "650000",
                    "fullCodes": "650000,659002",
                    "fullNames": "新疆维吾尔自治区,阿拉尔市",
                    "children": []
                },
                {
                    "id": "659003",
                    "code": "659003",
                    "name": "图木舒克市",
                    "parentCode": "650000",
                    "fullCodes": "650000,659003",
                    "fullNames": "新疆维吾尔自治区,图木舒克市",
                    "children": []
                },
                {
                    "id": "659004",
                    "code": "659004",
                    "name": "五家渠市",
                    "parentCode": "650000",
                    "fullCodes": "650000,659004",
                    "fullNames": "新疆维吾尔自治区,五家渠市",
                    "children": []
                }
            ]
        },
        {
            "id": "710000",
            "code": "710000",
            "name": "台湾省",
            "parentCode": "-1",
            "fullCodes": "710000",
            "fullNames": "台湾省",
            "children": [
                {
                    "id": "710100",
                    "code": "710100",
                    "name": "台北市",
                    "parentCode": "710000",
                    "fullCodes": "710000,710100",
                    "fullNames": "台湾省,台北市",
                    "children": [
                        {
                            "id": "710101",
                            "code": "710101",
                            "name": "中正区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710101",
                            "fullNames": "台湾省,台北市,中正区",
                            "children": []
                        },
                        {
                            "id": "710102",
                            "code": "710102",
                            "name": "大同区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710102",
                            "fullNames": "台湾省,台北市,大同区",
                            "children": []
                        },
                        {
                            "id": "710103",
                            "code": "710103",
                            "name": "中山区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710103",
                            "fullNames": "台湾省,台北市,中山区",
                            "children": []
                        },
                        {
                            "id": "710104",
                            "code": "710104",
                            "name": "松山区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710104",
                            "fullNames": "台湾省,台北市,松山区",
                            "children": []
                        },
                        {
                            "id": "710105",
                            "code": "710105",
                            "name": "大安区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710105",
                            "fullNames": "台湾省,台北市,大安区",
                            "children": []
                        },
                        {
                            "id": "710106",
                            "code": "710106",
                            "name": "万华区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710106",
                            "fullNames": "台湾省,台北市,万华区",
                            "children": []
                        },
                        {
                            "id": "710107",
                            "code": "710107",
                            "name": "信义区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710107",
                            "fullNames": "台湾省,台北市,信义区",
                            "children": []
                        },
                        {
                            "id": "710108",
                            "code": "710108",
                            "name": "士林区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710108",
                            "fullNames": "台湾省,台北市,士林区",
                            "children": []
                        },
                        {
                            "id": "710109",
                            "code": "710109",
                            "name": "北投区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710109",
                            "fullNames": "台湾省,台北市,北投区",
                            "children": []
                        },
                        {
                            "id": "710110",
                            "code": "710110",
                            "name": "内湖区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710110",
                            "fullNames": "台湾省,台北市,内湖区",
                            "children": []
                        },
                        {
                            "id": "710111",
                            "code": "710111",
                            "name": "南港区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710111",
                            "fullNames": "台湾省,台北市,南港区",
                            "children": []
                        },
                        {
                            "id": "710112",
                            "code": "710112",
                            "name": "文山区",
                            "parentCode": "710100",
                            "fullCodes": "710000,710100,710112",
                            "fullNames": "台湾省,台北市,文山区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "710200",
                    "code": "710200",
                    "name": "高雄市",
                    "parentCode": "710000",
                    "fullCodes": "710000,710200",
                    "fullNames": "台湾省,高雄市",
                    "children": [
                        {
                            "id": "710201",
                            "code": "710201",
                            "name": "新兴区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710201",
                            "fullNames": "台湾省,高雄市,新兴区",
                            "children": []
                        },
                        {
                            "id": "710202",
                            "code": "710202",
                            "name": "前金区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710202",
                            "fullNames": "台湾省,高雄市,前金区",
                            "children": []
                        },
                        {
                            "id": "710203",
                            "code": "710203",
                            "name": "芩雅区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710203",
                            "fullNames": "台湾省,高雄市,芩雅区",
                            "children": []
                        },
                        {
                            "id": "710204",
                            "code": "710204",
                            "name": "盐埕区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710204",
                            "fullNames": "台湾省,高雄市,盐埕区",
                            "children": []
                        },
                        {
                            "id": "710205",
                            "code": "710205",
                            "name": "鼓山区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710205",
                            "fullNames": "台湾省,高雄市,鼓山区",
                            "children": []
                        },
                        {
                            "id": "710206",
                            "code": "710206",
                            "name": "旗津区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710206",
                            "fullNames": "台湾省,高雄市,旗津区",
                            "children": []
                        },
                        {
                            "id": "710207",
                            "code": "710207",
                            "name": "前镇区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710207",
                            "fullNames": "台湾省,高雄市,前镇区",
                            "children": []
                        },
                        {
                            "id": "710208",
                            "code": "710208",
                            "name": "三民区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710208",
                            "fullNames": "台湾省,高雄市,三民区",
                            "children": []
                        },
                        {
                            "id": "710209",
                            "code": "710209",
                            "name": "左营区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710209",
                            "fullNames": "台湾省,高雄市,左营区",
                            "children": []
                        },
                        {
                            "id": "710210",
                            "code": "710210",
                            "name": "楠梓区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710210",
                            "fullNames": "台湾省,高雄市,楠梓区",
                            "children": []
                        },
                        {
                            "id": "710211",
                            "code": "710211",
                            "name": "小港区",
                            "parentCode": "710200",
                            "fullCodes": "710000,710200,710211",
                            "fullNames": "台湾省,高雄市,小港区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "710300",
                    "code": "710300",
                    "name": "台南市",
                    "parentCode": "710000",
                    "fullCodes": "710000,710300",
                    "fullNames": "台湾省,台南市",
                    "children": [
                        {
                            "id": "710301",
                            "code": "710301",
                            "name": "中西区",
                            "parentCode": "710300",
                            "fullCodes": "710000,710300,710301",
                            "fullNames": "台湾省,台南市,中西区",
                            "children": []
                        },
                        {
                            "id": "710302",
                            "code": "710302",
                            "name": "东区",
                            "parentCode": "710300",
                            "fullCodes": "710000,710300,710302",
                            "fullNames": "台湾省,台南市,东区",
                            "children": []
                        },
                        {
                            "id": "710303",
                            "code": "710303",
                            "name": "南区",
                            "parentCode": "710300",
                            "fullCodes": "710000,710300,710303",
                            "fullNames": "台湾省,台南市,南区",
                            "children": []
                        },
                        {
                            "id": "710304",
                            "code": "710304",
                            "name": "北区",
                            "parentCode": "710300",
                            "fullCodes": "710000,710300,710304",
                            "fullNames": "台湾省,台南市,北区",
                            "children": []
                        },
                        {
                            "id": "710305",
                            "code": "710305",
                            "name": "安平区",
                            "parentCode": "710300",
                            "fullCodes": "710000,710300,710305",
                            "fullNames": "台湾省,台南市,安平区",
                            "children": []
                        },
                        {
                            "id": "710306",
                            "code": "710306",
                            "name": "安南区",
                            "parentCode": "710300",
                            "fullCodes": "710000,710300,710306",
                            "fullNames": "台湾省,台南市,安南区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "710400",
                    "code": "710400",
                    "name": "台中市",
                    "parentCode": "710000",
                    "fullCodes": "710000,710400",
                    "fullNames": "台湾省,台中市",
                    "children": [
                        {
                            "id": "710401",
                            "code": "710401",
                            "name": "中区",
                            "parentCode": "710400",
                            "fullCodes": "710000,710400,710401",
                            "fullNames": "台湾省,台中市,中区",
                            "children": []
                        },
                        {
                            "id": "710402",
                            "code": "710402",
                            "name": "东区",
                            "parentCode": "710400",
                            "fullCodes": "710000,710400,710402",
                            "fullNames": "台湾省,台中市,东区",
                            "children": []
                        },
                        {
                            "id": "710403",
                            "code": "710403",
                            "name": "南区",
                            "parentCode": "710400",
                            "fullCodes": "710000,710400,710403",
                            "fullNames": "台湾省,台中市,南区",
                            "children": []
                        },
                        {
                            "id": "710404",
                            "code": "710404",
                            "name": "西区",
                            "parentCode": "710400",
                            "fullCodes": "710000,710400,710404",
                            "fullNames": "台湾省,台中市,西区",
                            "children": []
                        },
                        {
                            "id": "710405",
                            "code": "710405",
                            "name": "北区",
                            "parentCode": "710400",
                            "fullCodes": "710000,710400,710405",
                            "fullNames": "台湾省,台中市,北区",
                            "children": []
                        },
                        {
                            "id": "710406",
                            "code": "710406",
                            "name": "北屯区",
                            "parentCode": "710400",
                            "fullCodes": "710000,710400,710406",
                            "fullNames": "台湾省,台中市,北屯区",
                            "children": []
                        },
                        {
                            "id": "710407",
                            "code": "710407",
                            "name": "西屯区",
                            "parentCode": "710400",
                            "fullCodes": "710000,710400,710407",
                            "fullNames": "台湾省,台中市,西屯区",
                            "children": []
                        },
                        {
                            "id": "710408",
                            "code": "710408",
                            "name": "南屯区",
                            "parentCode": "710400",
                            "fullCodes": "710000,710400,710408",
                            "fullNames": "台湾省,台中市,南屯区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "710500",
                    "code": "710500",
                    "name": "金门县",
                    "parentCode": "710000",
                    "fullCodes": "710000,710500",
                    "fullNames": "台湾省,金门县",
                    "children": []
                },
                {
                    "id": "710600",
                    "code": "710600",
                    "name": "南投县",
                    "parentCode": "710000",
                    "fullCodes": "710000,710600",
                    "fullNames": "台湾省,南投县",
                    "children": []
                },
                {
                    "id": "710700",
                    "code": "710700",
                    "name": "基隆市",
                    "parentCode": "710000",
                    "fullCodes": "710000,710700",
                    "fullNames": "台湾省,基隆市",
                    "children": [
                        {
                            "id": "710701",
                            "code": "710701",
                            "name": "仁爱区",
                            "parentCode": "710700",
                            "fullCodes": "710000,710700,710701",
                            "fullNames": "台湾省,基隆市,仁爱区",
                            "children": []
                        },
                        {
                            "id": "710702",
                            "code": "710702",
                            "name": "信义区",
                            "parentCode": "710700",
                            "fullCodes": "710000,710700,710702",
                            "fullNames": "台湾省,基隆市,信义区",
                            "children": []
                        },
                        {
                            "id": "710703",
                            "code": "710703",
                            "name": "中正区",
                            "parentCode": "710700",
                            "fullCodes": "710000,710700,710703",
                            "fullNames": "台湾省,基隆市,中正区",
                            "children": []
                        },
                        {
                            "id": "710704",
                            "code": "710704",
                            "name": "中山区",
                            "parentCode": "710700",
                            "fullCodes": "710000,710700,710704",
                            "fullNames": "台湾省,基隆市,中山区",
                            "children": []
                        },
                        {
                            "id": "710705",
                            "code": "710705",
                            "name": "安乐区",
                            "parentCode": "710700",
                            "fullCodes": "710000,710700,710705",
                            "fullNames": "台湾省,基隆市,安乐区",
                            "children": []
                        },
                        {
                            "id": "710706",
                            "code": "710706",
                            "name": "暖暖区",
                            "parentCode": "710700",
                            "fullCodes": "710000,710700,710706",
                            "fullNames": "台湾省,基隆市,暖暖区",
                            "children": []
                        },
                        {
                            "id": "710707",
                            "code": "710707",
                            "name": "七堵区",
                            "parentCode": "710700",
                            "fullCodes": "710000,710700,710707",
                            "fullNames": "台湾省,基隆市,七堵区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "710800",
                    "code": "710800",
                    "name": "新竹市",
                    "parentCode": "710000",
                    "fullCodes": "710000,710800",
                    "fullNames": "台湾省,新竹市",
                    "children": [
                        {
                            "id": "710801",
                            "code": "710801",
                            "name": "东区",
                            "parentCode": "710800",
                            "fullCodes": "710000,710800,710801",
                            "fullNames": "台湾省,新竹市,东区",
                            "children": []
                        },
                        {
                            "id": "710802",
                            "code": "710802",
                            "name": "北区",
                            "parentCode": "710800",
                            "fullCodes": "710000,710800,710802",
                            "fullNames": "台湾省,新竹市,北区",
                            "children": []
                        },
                        {
                            "id": "710803",
                            "code": "710803",
                            "name": "香山区",
                            "parentCode": "710800",
                            "fullCodes": "710000,710800,710803",
                            "fullNames": "台湾省,新竹市,香山区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "710900",
                    "code": "710900",
                    "name": "嘉义市",
                    "parentCode": "710000",
                    "fullCodes": "710000,710900",
                    "fullNames": "台湾省,嘉义市",
                    "children": [
                        {
                            "id": "710901",
                            "code": "710901",
                            "name": "东区",
                            "parentCode": "710900",
                            "fullCodes": "710000,710900,710901",
                            "fullNames": "台湾省,嘉义市,东区",
                            "children": []
                        },
                        {
                            "id": "710902",
                            "code": "710902",
                            "name": "西区",
                            "parentCode": "710900",
                            "fullCodes": "710000,710900,710902",
                            "fullNames": "台湾省,嘉义市,西区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "711100",
                    "code": "711100",
                    "name": "新北市",
                    "parentCode": "710000",
                    "fullCodes": "710000,711100",
                    "fullNames": "台湾省,新北市",
                    "children": []
                },
                {
                    "id": "711200",
                    "code": "711200",
                    "name": "宜兰县",
                    "parentCode": "710000",
                    "fullCodes": "710000,711200",
                    "fullNames": "台湾省,宜兰县",
                    "children": []
                },
                {
                    "id": "711300",
                    "code": "711300",
                    "name": "新竹县",
                    "parentCode": "710000",
                    "fullCodes": "710000,711300",
                    "fullNames": "台湾省,新竹县",
                    "children": []
                },
                {
                    "id": "711400",
                    "code": "711400",
                    "name": "桃园县",
                    "parentCode": "710000",
                    "fullCodes": "710000,711400",
                    "fullNames": "台湾省,桃园县",
                    "children": []
                },
                {
                    "id": "711500",
                    "code": "711500",
                    "name": "苗栗县",
                    "parentCode": "710000",
                    "fullCodes": "710000,711500",
                    "fullNames": "台湾省,苗栗县",
                    "children": []
                },
                {
                    "id": "711700",
                    "code": "711700",
                    "name": "彰化县",
                    "parentCode": "710000",
                    "fullCodes": "710000,711700",
                    "fullNames": "台湾省,彰化县",
                    "children": []
                },
                {
                    "id": "711900",
                    "code": "711900",
                    "name": "嘉义县",
                    "parentCode": "710000",
                    "fullCodes": "710000,711900",
                    "fullNames": "台湾省,嘉义县",
                    "children": []
                },
                {
                    "id": "712100",
                    "code": "712100",
                    "name": "云林县",
                    "parentCode": "710000",
                    "fullCodes": "710000,712100",
                    "fullNames": "台湾省,云林县",
                    "children": []
                },
                {
                    "id": "712400",
                    "code": "712400",
                    "name": "屏东县",
                    "parentCode": "710000",
                    "fullCodes": "710000,712400",
                    "fullNames": "台湾省,屏东县",
                    "children": []
                },
                {
                    "id": "712500",
                    "code": "712500",
                    "name": "台东县",
                    "parentCode": "710000",
                    "fullCodes": "710000,712500",
                    "fullNames": "台湾省,台东县",
                    "children": []
                },
                {
                    "id": "712600",
                    "code": "712600",
                    "name": "花莲县",
                    "parentCode": "710000",
                    "fullCodes": "710000,712600",
                    "fullNames": "台湾省,花莲县",
                    "children": []
                },
                {
                    "id": "712700",
                    "code": "712700",
                    "name": "澎湖县",
                    "parentCode": "710000",
                    "fullCodes": "710000,712700",
                    "fullNames": "台湾省,澎湖县",
                    "children": []
                }
            ]
        },
        {
            "id": "810000",
            "code": "810000",
            "name": "香港特别行政区",
            "parentCode": "-1",
            "fullCodes": "810000",
            "fullNames": "香港特别行政区",
            "children": [
                {
                    "id": "810100",
                    "code": "810100",
                    "name": "香港岛",
                    "parentCode": "810000",
                    "fullCodes": "810000,810100",
                    "fullNames": "香港特别行政区,香港岛",
                    "children": [
                        {
                            "id": "810101",
                            "code": "810101",
                            "name": "中西区",
                            "parentCode": "810100",
                            "fullCodes": "810000,810100,810101",
                            "fullNames": "香港特别行政区,香港岛,中西区",
                            "children": []
                        },
                        {
                            "id": "810102",
                            "code": "810102",
                            "name": "湾仔",
                            "parentCode": "810100",
                            "fullCodes": "810000,810100,810102",
                            "fullNames": "香港特别行政区,香港岛,湾仔",
                            "children": []
                        },
                        {
                            "id": "810103",
                            "code": "810103",
                            "name": "东区",
                            "parentCode": "810100",
                            "fullCodes": "810000,810100,810103",
                            "fullNames": "香港特别行政区,香港岛,东区",
                            "children": []
                        },
                        {
                            "id": "810104",
                            "code": "810104",
                            "name": "南区",
                            "parentCode": "810100",
                            "fullCodes": "810000,810100,810104",
                            "fullNames": "香港特别行政区,香港岛,南区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "810200",
                    "code": "810200",
                    "name": "九龙",
                    "parentCode": "810000",
                    "fullCodes": "810000,810200",
                    "fullNames": "香港特别行政区,九龙",
                    "children": [
                        {
                            "id": "810201",
                            "code": "810201",
                            "name": "九龙城区",
                            "parentCode": "810200",
                            "fullCodes": "810000,810200,810201",
                            "fullNames": "香港特别行政区,九龙,九龙城区",
                            "children": []
                        },
                        {
                            "id": "810202",
                            "code": "810202",
                            "name": "油尖旺区",
                            "parentCode": "810200",
                            "fullCodes": "810000,810200,810202",
                            "fullNames": "香港特别行政区,九龙,油尖旺区",
                            "children": []
                        },
                        {
                            "id": "810203",
                            "code": "810203",
                            "name": "深水埗区",
                            "parentCode": "810200",
                            "fullCodes": "810000,810200,810203",
                            "fullNames": "香港特别行政区,九龙,深水埗区",
                            "children": []
                        },
                        {
                            "id": "810204",
                            "code": "810204",
                            "name": "黄大仙区",
                            "parentCode": "810200",
                            "fullCodes": "810000,810200,810204",
                            "fullNames": "香港特别行政区,九龙,黄大仙区",
                            "children": []
                        },
                        {
                            "id": "810205",
                            "code": "810205",
                            "name": "观塘区",
                            "parentCode": "810200",
                            "fullCodes": "810000,810200,810205",
                            "fullNames": "香港特别行政区,九龙,观塘区",
                            "children": []
                        }
                    ]
                },
                {
                    "id": "810300",
                    "code": "810300",
                    "name": "新界",
                    "parentCode": "810000",
                    "fullCodes": "810000,810300",
                    "fullNames": "香港特别行政区,新界",
                    "children": [
                        {
                            "id": "810301",
                            "code": "810301",
                            "name": "北区",
                            "parentCode": "810300",
                            "fullCodes": "810000,810300,810301",
                            "fullNames": "香港特别行政区,新界,北区",
                            "children": []
                        },
                        {
                            "id": "810302",
                            "code": "810302",
                            "name": "大埔区",
                            "parentCode": "810300",
                            "fullCodes": "810000,810300,810302",
                            "fullNames": "香港特别行政区,新界,大埔区",
                            "children": []
                        },
                        {
                            "id": "810303",
                            "code": "810303",
                            "name": "沙田区",
                            "parentCode": "810300",
                            "fullCodes": "810000,810300,810303",
                            "fullNames": "香港特别行政区,新界,沙田区",
                            "children": []
                        },
                        {
                            "id": "810304",
                            "code": "810304",
                            "name": "西贡区",
                            "parentCode": "810300",
                            "fullCodes": "810000,810300,810304",
                            "fullNames": "香港特别行政区,新界,西贡区",
                            "children": []
                        },
                        {
                            "id": "810305",
                            "code": "810305",
                            "name": "元朗区",
                            "parentCode": "810300",
                            "fullCodes": "810000,810300,810305",
                            "fullNames": "香港特别行政区,新界,元朗区",
                            "children": []
                        },
                        {
                            "id": "810306",
                            "code": "810306",
                            "name": "屯门区",
                            "parentCode": "810300",
                            "fullCodes": "810000,810300,810306",
                            "fullNames": "香港特别行政区,新界,屯门区",
                            "children": []
                        },
                        {
                            "id": "810307",
                            "code": "810307",
                            "name": "荃湾区",
                            "parentCode": "810300",
                            "fullCodes": "810000,810300,810307",
                            "fullNames": "香港特别行政区,新界,荃湾区",
                            "children": []
                        },
                        {
                            "id": "810308",
                            "code": "810308",
                            "name": "葵青区",
                            "parentCode": "810300",
                            "fullCodes": "810000,810300,810308",
                            "fullNames": "香港特别行政区,新界,葵青区",
                            "children": []
                        },
                        {
                            "id": "810309",
                            "code": "810309",
                            "name": "离岛区",
                            "parentCode": "810300",
                            "fullCodes": "810000,810300,810309",
                            "fullNames": "香港特别行政区,新界,离岛区",
                            "children": []
                        }
                    ]
                }
            ]
        },
        {
            "id": "820000",
            "code": "820000",
            "name": "澳门特别行政区",
            "parentCode": "-1",
            "fullCodes": "820000",
            "fullNames": "澳门特别行政区",
            "children": [
                {
                    "id": "820100",
                    "code": "820100",
                    "name": "澳门半岛",
                    "parentCode": "820000",
                    "fullCodes": "820000,820100",
                    "fullNames": "澳门特别行政区,澳门半岛",
                    "children": []
                },
                {
                    "id": "820200",
                    "code": "820200",
                    "name": "离岛",
                    "parentCode": "820000",
                    "fullCodes": "820000,820200",
                    "fullNames": "澳门特别行政区,离岛",
                    "children": []
                }
            ]
        }
    ]
};

const regionMap = {};
const initRegionMap = (data) => {
    data.forEach((item) => {
        regionMap[item.code] = item.name;
        if (item.children && item.children.length > 0) {
            initRegionMap(item.children);
        }
    });
};
initRegionMap(regionData.data);
export const getRegionName = (code) => {
    return regionMap[code];
};
export default regionData.data;

//export 多个