import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Image } from 'antd';
import { post } from 'util/http';
import module from 'util/module';

import { useNavigate } from 'react-router-dom';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import moment from 'moment'

import Approvaling from './approvaling'


import RoomDrawer from './room/index'


import { getMetaLabel } from 'util/meta';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "xspaceSpace";
    const [param, setParam] = useState({
        params: [{
            attrCode: "status",
            operator: "in",
            value: "normal,offline"
        }],
        metaCode: metaCode,
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }]
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });

    const [roomParam, setRoomParam] = useState({});

    const [searchForm] = Form.useForm();

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'name',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }

    const toppingProject = (id, showOrder) => {
        post('/api/recruit/job/v1.0/topping', {
            id: id,
            showOrder: showOrder
        }, {
            loading: true
        }).then(res => {
            setParam({ ...param });
        })
    }
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                    {/* <Button type="primary" onClick={() => {
                        setFormParam({
                            id: '',
                            visible: true
                        })
                    }}>创建</Button> */}
                </Space>


            </Form>

            <SearchTable url={"/api/app/public/meta/data/search"}
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '图片',
                        dataIndex: 'nameImg',
                        key: 'nameImg',
                        render: (_, record) => {
                            return (<Image src={_} width={40} />)
                        }
                    },
                    {
                        title: '开放时间',
                        dataIndex: 'startTime',
                        key: 'startTime',
                        render: (_, record) => {
                            return _ + "-" + record.endTime;
                        }
                    },
                    {
                        title: '联系电话',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                    {
                        title: '地址',
                        dataIndex: 'address',
                        key: 'address',
                    }, {
                        title: '备注',
                        dataIndex: 'remark',
                        key: 'remark',
                    }, {
                        title: '创建人',
                        dataIndex: 'creatorName',
                        key: 'creatorName',
                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: 'status',
                        render: (_, record) => {
                            return getMetaLabel('SPACE_STATUS', _);
                        }
                    }, {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '房间数量',
                        dataIndex: 'roomCount',
                        key: 'roomCount',
                        render: (_, record) => {
                            return <a onClick={() => {
                                setRoomParam({
                                    id: record.id,
                                    spaceCode: record.code,
                                    visible: true
                                })
                            }}>{_}</a>
                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">

                                <a onClick={() => {
                                    setFormParam({
                                        id: record.id,
                                        visible: true
                                    })
                                }}>详情</a>

                                {/* <a onClick={() => {
                                    setFormParam({
                                        id: record.id,
                                        visible: true
                                    })
                                }}>修改</a>*/
                                    <Popconfirm
                                        title="你确认删除此空间么，同时会删除对应房间数据?"
                                        onConfirm={() => {
                                            post('/api/xspace/space/delete', {
                                                id: record.id
                                            }).then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                        }}
                                        okText="确认"
                                        cancelText="取消" >
                                        <a href="#">删除</a>
                                    </Popconfirm>}

                            </Space>
                        )
                    }
                ]} />
            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />

            <RoomDrawer param={roomParam} setParam={setRoomParam} reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/xspace/space/manager", Page);
export default Page;