import React, { useCallback, useEffect, useState } from 'react'
import { post } from 'util/http';
import module from 'util/module';
import "antd/dist/antd.css";
import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    message,
    Upload,
    Image
} from 'antd';

const Page = ({
    value,
    onChange,
    readOnly = false,
    maxCount = 1,
    uploadData = {},
    uploadUrl = "/api/app/resource/v1.0/image/upload",
    imageHeight = 100,
    ...nextProps
}) => {

    const [images, setImages] = useState([]);

    useEffect(() => {
        if (value != null) {
            if (typeof value == 'string') {
                if (value.indexOf(',') > -1) {
                    setImages(value.split(','))
                } else {
                    setImages([value])
                }
            } else if (Array.isArray(value)) {
                setImages(value)
            } else {
                setImages([value])
            }
        }
        // if (value != null && value != '' && value.indexOf(',') > -1) {
        //     setImages(value.split(','))

        // } else if (value != null && value != '') {
        //     setImages([value])
        // }
    }, [value])

    return (
        <div style={{ display: 'flex' }}>
            {maxCount == 1 && value != null && value != '' && (
                <Image src={value} height={imageHeight} />
            )}
            {maxCount > 1 && value != null && (
                images.map((v) => {
                    return <div style={{ marginRight: 20 }} key={v} >
                        <Image src={v} height={imageHeight} preview={false} />
                    </div>
                })
            )}
            {/* {images.length > 0 && (
                images.map((v) => {
                    return <div style={{ marginLeft: 20 }} key={v} ><Image src={v} height={imageHeight} preview={false} /></div>
                })
            )} */}
            <Upload
                action={uploadUrl}
                showUploadList={false}
                maxCount={maxCount}
                name='file'
                data={uploadData}
                onChange={(info) => {
                    if (info.file.status === 'done') {
                        if (maxCount == 1) {
                            onChange(info.file.response.data);
                        } else {
                            const newValue = [...value];
                            info.fileList.map((file) => {
                                newValue.push(file.response.data);
                            })
                            onChange(newValue);
                        }
                    } else if (info.file.status === 'error') {
                        message.error(`${info.file.name} 上传失败`);
                    }
                }}
                {...nextProps}
            >
                {!readOnly && <Button icon={<UploadOutlined />}  >选择图片</Button>}
            </Upload>
        </div>)
}

export default Page;