import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import ListTable from 'component/ListTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import TreeTable from 'component/TreeTable'
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';


import { getMetaLabel } from 'util/meta';
import SearchSelect from 'component/SearchSelect.js';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "bmsBattery";
    const [param, setParam] = useState({
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });

    const [regionParam, setRegionParam] = useState({
        metaCode: "cmdbRegion"
    })
    const [searchForm] = Form.useForm();

    const pauseJob = (record) => {
        post('/api/cron/job/pause', {
            code: record.code,
        }).then(res => {
            message.success("暂停成功");
            reload();
        })
    }
    const resumeJob = (record) => {
        post('/api/cron/job/resume', {
            code: record.code,
        }).then(res => {
            message.success("恢复成功");
            reload();
        })
    }

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.fullTypeCode != null) {
            newParam.push({
                attrCode: 'fullTypeCode',
                operator: "like",
                value: datas.fullTypeCode + "%"
            })
        }
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'title',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.vendorCode != null) {
            newParam.push({
                attrCode: 'vendorCode',
                operator: "=",
                value: datas.vendorCode
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({
                            id: '',
                            visible: true
                        })
                    }}>创建</Button>

                </Space>


            </Form>

            <ListTable url={"/api/cron/job/list"}
                keyData="jobs"
                param={param}
                keyCode="code"
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '表达式',
                        dataIndex: 'cron',
                        key: 'cron'
                    }, {
                        title: '处理类',
                        dataIndex: 'className',
                        key: 'className'
                    }, {
                        title: '状态',
                        dataIndex: 'state',
                        key: 'state',
                        render: (_, record) => {
                            if (record.state == "NORMAL") {
                                return (
                                    <>
                                        <a onClick={() => {
                                            pauseJob(record)
                                        }}>{getMetaLabel('JOB_STATUS', _)}</a>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <a onClick={() => {
                                            resumeJob(record)
                                        }}>{getMetaLabel('JOB_STATUS', _)}</a>
                                    </>
                                )
                            }

                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setFormParam({
                                        code: record.code,
                                        visible: true
                                    })
                                }}>修改</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post('/api/cron/job/delete', {
                                            code: record.code,
                                        }).then(res => {
                                            setParam({ ...param });
                                            message.info("删除成功");
                                        })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />
            < FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />

        </div >
    );
}
addPage("/cron/job/manager", Page);
export default Page;