import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import DictSelect from "component/DictSelect";

const { Option } = Select;
const { TextArea } = Input;

const Page = ({
  param,
  setParam,
  reload
}) => {


  const [pageForm] = Form.useForm();

  useEffect(() => {
    if (param.visible) {
      if (param.clusterId != '') {
        post(module.cloud + '/my/cluster/get', { id: param.clusterId })
          .then(res => {
            pageForm.setFieldsValue({
              ...res.data
            });
          })
      } else {
        pageForm.resetFields();
      }
    }
  }, [param])

  return (<Drawer title="创建集群"
    placement="right"
    width={660}
    maskClosable={false}
    onClose={() => {
      setParam({ ...param, visible: false })
    }}
    visible={param.visible}
    extra={
      <Space>
        <Button onClick={() => {
          setParam({ ...param, visible: false })
        }}>取消</Button>
        <Button type="primary" onClick={() => {
          const data = pageForm.getFieldsValue();
          const url = data.id ? module.cloud + '/my/cluster/update'
            : module.cloud + '/my/cluster/create';
          post(url, data)
            .then(res => {
              setParam({ ...param, visible: false });
              reload();
              message.info("保存成功");
            })
        }}>
          确认
        </Button>
      </Space>
    }
  >
    <Form form={pageForm} layout="horizontal" hideRequiredMark
      labelCol={{
        style: { width: 80 }
      }}
      initialValues={{
        attrs: []
      }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item style={{ display: 'none' }}
            name="id">
            <Input type="hidden"
            />
          </Form.Item>
          <Form.Item
            name="name"
            label="名称"
            rules={[{ required: true, message: '请输入名称' }]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入名称"
            />
          </Form.Item>

        </Col>
        <Col span={24}>
          <Form.Item
            name="domain"
            label="域名"
            rules={[{ required: true, message: '请输入域名前缀' }]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入域名前缀"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="scope"
            label="范围"
            rules={[{ required: true, message: '请输入范围' }]}
          >
            <DictSelect dictCode="CLUSTER_SCOPE" />
          </Form.Item>

        </Col>
      </Row>


    </Form>
  </Drawer>)
}

export default Page;