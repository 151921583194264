import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import { post } from 'util/http';
import module from 'util/module';
import moment from 'moment'
import { getMetaLabel } from 'util/meta';
const { TextArea } = Input;


const Page = ({
    param,
    setParam,
    reload,
}) => {

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();

    const [questions, setQuestions] = useState([]);

    return (<Drawer title="协议问题"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
    >

        <Modal width={860} title="问题维护" visible={param.questionVisible}
            onCancel={() => {
                setParam({ ...param, questionVisible: false })
            }}
            footer={[
                <Button key="cancel" onClick={() => {
                    setParam({ ...param, questionVisible: false })
                }}>
                    取消
                </Button>,
                <Button key="save" type="primary" onClick={() => {
                    pageForm.validateFields().then((data) => {
                        data.protocolCode = param.protocolCode;
                        const url = '/api/app/meta/v1.0/data/save';
                        post(url, {
                            metaCode: param.metaCode,
                            datas: data
                        }).then(res => {
                            setParam({ ...param, questionVisible: false });
                            message.info("保存成功");
                        })
                    })
                }}>
                    保存
                </Button>,

            ]}
        >

            <Form form={pageForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 120 }
                }}
                initialValues={{
                    items: []
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="question"
                            label="问题"
                        >
                            <TextArea />
                        </Form.Item>
                        <Form.Item
                            name="showOrder"
                            label="排序"
                        >
                            <Input type='number' />
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }}
                            name="id">
                            <Input type="hidden" />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>


        </Modal>

        <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
            <Space >
                <Button type="primary" onClick={() => {
                    setParam({ ...param, questionVisible: true })
                    pageForm.resetFields();
                }}>新增</Button>
            </Space>
        </Form>

        <SearchTable url={"/api/app/meta/v1.0/data/search"}
            param={param}
            columns={[
                {
                    title: '问题',
                    dataIndex: 'question',
                    key: 'question',
                },
                {
                    title: '排序',
                    dataIndex: 'showOrder',
                    key: 'showOrder',
                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                    render: (_, record) => {
                        return moment(_).format("yyyy-MM-DD HH:mm:ss")
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'code',
                    key: 'code',
                    render: (_, record) => (
                        <Space size="middle">
                            <a onClick={() => {
                                setParam({ ...param, questionVisible: true })
                                pageForm.resetFields();
                                pageForm.setFieldsValue({
                                    ...record
                                });
                            }}>修改</a>
                            <Popconfirm
                                title="你确认删除?"
                                onConfirm={() => {
                                    post('/api/app/meta/v1.0/data/delete', {
                                        metaCode: param.metaCode,
                                        id: record.id,
                                    }).then(res => {
                                        setParam({ ...param });
                                        message.info("删除成功");
                                    })
                                }}
                                okText="确认"
                                cancelText="取消" >
                                <a href="#">删除</a>
                            </Popconfirm>

                        </Space>
                    )

                }
            ]} />
    </Drawer>)
}

export default Page;