import React, { useState } from 'react'
import { Button, Form, Input, message, Popconfirm, Space } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { post } from 'util/http';
import module from 'util/module';
import { getMetaLabel } from 'util/meta';

import K8sSearchTable from 'component/K8sSearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import FormDrawer from './form.js'

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Page = () => {
  const query = useQuery();

  const clusterCode = query.get("cluster") ? query.get("cluster") : "test";

  const [param, setParam] = useState({
    clusterCode: clusterCode,
    namespace: "kube-system",
    searchName: ""
  });
  const [formParam, setFormParam] = useState({
    visible: false,
    moduleId: ""
  });
  const [versionParam, setVersionParam] = useState({
    visible: false,
    moduleCode: "-1"
  });

  const [searchForm] = Form.useForm();

  return (
    <div>
      <Form form={searchForm} layout="inline" style={{ padding: 15 }}>
        <Form.Item name="searchName" >
          <Input
            style={{ width: '100%' }}
            placeholder="请输入名称"
          />
        </Form.Item>
        <Space>
          <Button onClick={() => {
            setParam(searchForm.getFieldsValue())
          }}>搜索</Button>
          <Button type="primary" onClick={() => {
            setFormParam({
              clusterCode: clusterCode,
              visible: true
            })
          }}>创建</Button>
        </Space>


      </Form>

      <K8sSearchTable url={"/api/k8s/namespace/v1.0/list"}
        param={param}
        columns={[
          {
            title: '名称',
            dataIndex: ['metadata', 'name'],
            key: 'metadataName',
          }, {
            title: '状态',
            dataIndex: ['status', 'phase'],
            key: 'statusPhase',
          }, {
            title: '描述',
            key: 'statusPhase',
          }, {
            title: '创建时间',
            key: 'statusReplicas'
          }, {
            title: '操作',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
              <Space size="middle">
                <a onClick={() => {
                }}>配额管理</a>

                <Popconfirm
                  title="你确认删除此数据么?"
                  onConfirm={() => {
                    post('/api/k8s/namespace/v1.0/delete',
                      {
                        clusterCode: clusterCode,
                        name: record.metadata.name
                      })
                      .then(res => {
                        setParam({ ...param });
                        message.info("删除成功");
                      })
                  }}
                  okText="确认"
                  cancelText="取消">
                  <a href="#">删除</a>
                </Popconfirm>

              </Space>
            )
          }
        ]} />
      <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />


    </div>
  );
}

addPage("/k8s/namespace/manager", Page);

export default Page;