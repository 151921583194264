import React, { useEffect, useState, useContext } from 'react';
import { post } from 'util/http';
import module from 'util/module';

import { addRoute, DynamicPages, setBreadcrumbs } from 'component/DynamicRotes';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Image, Avatar, Space, Dropdown, Button } from 'antd';


const { Header, Content, Sider } = Layout;


const ALL_MENU_MAP = {
    "/public/template": {
        "key": "/public/template",
        "label": "应用市场"
    }
};

function Page() {

    const [menuData, updateMenuData] = useState({
        items: [],
        selectedKeys: [],
        openKeys: []
    });

    const [breadcrumbData, setBreadcrumbData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const updateBreadcrumbData = (keyPath) => {
        var newData = [];
        for (var i = keyPath.length - 1; i >= 0; i--) {
            const m = ALL_MENU_MAP[keyPath[i]];
            if (m != null) {
                newData.push(m.label);
            }
        }
        setBreadcrumbData(newData);
    }

    const loadMenuTree = (data, menuInitData, parentUrls) => {
        var res = {
            key: `${data.url}`,
            label: data.name,
        }
        ALL_MENU_MAP[res.key] = res;
        if (data.children != null && data.children.length > 0) {
            res.children = [];
            for (var i in data.children) {
                res.children.push(loadMenuTree(data.children[i], menuInitData, [...parentUrls, data.url]))
            }
        }
        if (data.url === location.pathname) {
            menuInitData.selectedKeys.push(data.url);
            menuInitData.openKeys = parentUrls;
        }
        return res;
    }

    useEffect(() => {
        post(module.app + '/menu/v1.0/auth/tree', { showRoot: false })
            .then(res => {
                const menuInitData = {
                    items: [],
                    selectedKeys: [],
                    openKeys: []
                };
                res.data.map((item) => {
                    const t = loadMenuTree(item, menuInitData, []);
                    menuInitData.items.push(t);
                });
                updateMenuData(menuInitData);
            })
    }, []);


    useEffect(() => {
        updateBreadcrumbData([location.pathname]);
    }, [location.pathname]);


    return (
        <Layout className="xcloud-body" >
            <Sider width={200} className="site-layout-background" style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 64,
                bottom: 0,
            }}>
                <Menu
                    mode="inline"
                    selectedKeys={menuData.selectedKeys}
                    openKeys={menuData.openKeys}
                    style={{
                        height: '100%',
                        borderRight: 0,
                    }}
                    onOpenChange={(e) => {
                        updateMenuData({
                            ...menuData,
                            openKeys: e
                        });
                    }}
                    onClick={(e) => {
                        updateMenuData({
                            ...menuData,
                            selectedKeys: e.keyPath,
                            openKeys: e.keyPath
                        });
                        navigate(e.key);
                    }}
                    items={menuData.items}
                >

                </Menu>
            </Sider>
            <Layout className='xcloud-content-wrapper'
            >
                <Breadcrumb className="breadcrumb" >
                    {
                        breadcrumbData.map((item) =>
                            (<Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>)
                        )
                    }
                </Breadcrumb>
                <Content
                    className="xcloud-content"
                    id="xcloud-content"
                >
                    <DynamicPages />
                </Content>
            </Layout>
        </Layout>
    );
}
export default Page;
