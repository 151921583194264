import React, { useState } from 'react'
import { Button, Form, Input, message, Popconfirm, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { post } from 'util/http';
import module from 'util/module';
import { getMetaLabel } from 'util/meta';

import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import FormDrawer from './form.js'
import VersionDrawer from './version.js'


const Page = () => {
  const [param, setParam] = useState({
    searchName: ""
  });
  const [formParam, setFormParam] = useState({
    visible: false,
    moduleId: ""
  });
  const [versionParam, setVersionParam] = useState({
    visible: false,
    moduleCode: "-1"
  });

  const [searchForm] = Form.useForm();

  return (
    <div>
      <Form form={searchForm} layout="inline" style={{ padding: 15 }}>
        <Form.Item name="searchName" >
          <Input
            style={{ width: '100%' }}
            placeholder="请输入名称"
          />
        </Form.Item>
        <Space>
          <Button onClick={() => {
            setParam(searchForm.getFieldsValue())
          }}>搜索</Button>
          <Button type="primary" onClick={() => {
            setFormParam({ moduleId: "", visible: true })
          }}>创建</Button>
        </Space>


      </Form>

      <SearchTable url={module.cloud + "/module/v1.0/search"}
        param={param}
        columns={[
          {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => {
              return getMetaLabel('MODULE_TYPE', _);
            }

          },
          {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
          }, {
            title: 'code',
            dataIndex: 'code',
            key: 'code',
          }, {
            title: '最新版本',
            dataIndex: 'latestVersion',
            key: 'latestVersion',
          }, {
            title: '范围',
            dataIndex: 'scope',
            key: 'scope',
            render: (_, record) => {
              return getMetaLabel('MODULE_SCOPE', _);
            }
          }, {
            title: '操作',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
              <Space size="middle">
                <a onClick={() => {
                  setVersionParam({
                    moduleCode: record.code,
                    visible: true
                  })
                }}>版本</a>
                <a onClick={() => {
                  setFormParam({ moduleId: record.id, visible: true })
                }}>修改</a>


                <Popconfirm
                  title="你确认删除此数据么?"
                  onConfirm={() => {
                    post(module.cloud + '/module/v1.0/delete',
                      { id: record.id })
                      .then(res => {
                        setParam({ ...param });
                        message.info("删除成功");
                      })
                  }}
                  okText="确认"
                  cancelText="取消">
                  <a href="#">删除</a>
                </Popconfirm>

              </Space>
            )
          }
        ]} />
      <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />

      <VersionDrawer param={versionParam} setParam={setVersionParam} reload={() => { setParam({ ...param }) }} />

    </div>
  );
}

addPage("/cloud/module/manager", Page);

export default Page;