import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Row, Col, Modal, Dropdown, Menu } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import TreeTable from 'component/TreeTable'
import SyncTree from 'component/SyncTree'
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import ServiceTree from '../service';
import moment from 'moment'
import { getMetaLabel } from 'util/meta';
import {
    SettingOutlined,
} from '@ant-design/icons';
const { Search } = Input;
const Page = () => {

    const metaCode = "hcsServer";
    const [param, setParam] = useState({
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "title"
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });


    const [domainParam, setDomainParam] = useState({
        visible: false,
        applicationCode: "-1",
        clusterCode: "-1"
    })

    const [permissionParam, setPermissionParam] = useState({});
    const [searchForm] = Form.useForm();

    const destroyServer = (record) => {
        post('/api/hcs/server/v1.0/destroy', {
            id: record.id,
        }).then(res => {
            setParam({ ...param });
            message.info("下线成功");
        })
    }

    const startServer = (record) => {
        post('/api/hcs/server/v1.0/start', {
            id: record.id,
        }).then(res => {
            setParam({ ...param });
            message.info("启动成功");
        })
    }
    const stopServer = (record) => {
        post('/api/hcs/server/v1.0/stop', {
            id: record.id,
        }).then(res => {
            setParam({ ...param });
            message.info("关机成功");
        })
    }
    const restartServer = (record) => {
        post('/api/hcs/server/v1.0/restart', {
            id: record.id,
        }).then(res => {
            setParam({ ...param });
            message.info("重启成功");
        })
    }
    const deleteServer = (record) => {
        post(module.app + '/meta/v1.0/data/delete', {
            metaCode: metaCode,
            id: record.id,
        }).then(res => {
            setParam({ ...param });
            message.info("删除成功");
        })
    }
    const confirm = (text, fn, record) => {
        Modal.confirm({
            title: '操作提示',
            content: '确认' + text,
            okText: '确认',
            cancelText: '取消',
            onOk: function () {
                fn(record);
                Modal.destroyAll();
            }
        });
    }

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.fullChannelCode != null) {
            newParam.push({
                attrCode: 'fullChannelCode',
                operator: "like",
                value: datas.fullChannelCode + "%"
            })
        }
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'title',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.status != null) {
            newParam.push({
                attrCode: 'status',
                operator: "=",
                value: datas.status
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }
    return (
        <div style={{ padding: 10 }}>

            <Row gutter={16} >
                <Col style={{ width: 280 }}>
                    <ServiceTree></ServiceTree>
                </Col>
                <Col flex={"1"}>
                    <Form form={searchForm} layout="inline"  >

                        <Form.Item
                            name="status"
                        >
                            <DictSelect
                                dictCode={"CMS_STATUS"}
                                style={{ width: 100 }}
                                placeholder="状态"
                            />
                        </Form.Item>

                        <Form.Item
                            name="searchName"
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="请输入名称"
                            />
                        </Form.Item>
                        <Space >
                            <Button onClick={() => {
                                reload();
                            }}>搜索</Button>
                            <Button type="primary" onClick={() => {
                                setFormParam({
                                    id: '',
                                    visible: true
                                })
                            }}>创建</Button>

                        </Space>

                    </Form>
                    <SearchTable url={"/api/nginx/server/v1.0/search"}
                        param={param}
                        columns={[
                            {
                                title: '名称',
                                dataIndex: 'name',
                                key: 'name',
                            }, {
                                title: '集群',
                                dataIndex: 'clusterName',
                                key: 'clusterName',
                            }, {
                                title: '版本',
                                dataIndex: 'version',
                                key: 'version',
                            }, {
                                title: '创建时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                            }, {
                                title: '操作',
                                dataIndex: 'name',
                                key: 'name',
                                render: (_, record) => (
                                    <Space size="middle">
                                        <a onClick={() => {
                                            //setFormParam({ visible: true })
                                            //pageForm.setFieldsValue(record);
                                            // setModuleParam({ clusterCode: record.code, visible: true })
                                        }}>修改</a>
                                        <a onClick={() => {
                                            setDomainParam({
                                                visible: true,
                                                serverCode: record.code,
                                                domain: ""
                                            })
                                        }}>接入域名</a>
                                        <Popconfirm
                                            title="你确认删除此数据么?"
                                            onConfirm={() => {
                                                post('/api/nginx/server/v1.0/delete',
                                                    { id: record.id })
                                                    .then(res => {
                                                        setParam({ ...param });
                                                        message.info("删除成功");
                                                    })
                                            }}
                                            okText="确认"
                                            cancelText="取消">
                                            <a href="#">删除</a>
                                        </Popconfirm>

                                    </Space>
                                )
                            }
                        ]} />
                </Col>
            </Row>

            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/sre/k8s/manager", Page);

export default Page;