import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import { post } from 'util/http';
import module from 'util/module';
import FileUpload from 'component/FileUpload';
import moment from 'moment'
const { TextArea } = Input;


const Page = ({
    param,
    setParam,
    reload,
}) => {

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();

    const [knowledgeType, setKnowledgeType] = useState('text');

    useEffect(() => {
        if (param.modalVisible) {
            if (param.id != null && param.id != '') {
                post('/api/app/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: param.metaCode
                }).then(res => {
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                    setKnowledgeType(res.data.type)
                })
            } else {
                pageForm.resetFields();
            }
        }
    }, [param])

    return (<Drawer title="知识库"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
    >

        <Modal width={960} title="添加知识" visible={param.modalVisible} onOk={() => {
            const data = pageForm.getFieldsValue();
            data.robotCode = param.robotCode;
            data.id = param.id;
            const url = '/api/app/meta/v1.0/data/save';
            post(url, {
                metaCode: param.metaCode,
                datas: data
            }).then(res => {
                setParam({ ...param, modalVisible: false })
                message.info("保存成功");
            })

        }} onCancel={() => { setParam({ ...param, modalVisible: false }) }}>

            <Form form={pageForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 80 }
                }}
                initialValues={{
                    items: []
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="title"
                            label="标题"
                            rules={[{ required: true, message: '请输入标题' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="type"
                            label="类型"
                            rules={[{ required: true, message: '请选择知识类型' }]}
                        >
                            <DictSelect dictCode="AiRobotKnowledgeType" onChange={(v) => {
                                setKnowledgeType(v);
                            }} />
                        </Form.Item>
                        {
                            knowledgeType == 'text' && <Form.Item
                                name="content"
                                label="文本内容"
                                rules={[{ required: true, message: '请输入文本内容' }]}
                            >
                                <TextArea rows={4} />
                            </Form.Item>
                        }
                        {
                            knowledgeType == 'document' && <Form.Item
                                name="content"
                                label="上传附件"
                                rules={[{ required: true, message: '请输入文本内容' }]}
                            >
                                <FileUpload />
                            </Form.Item>
                        }
                        {
                            knowledgeType == 'url' && <Form.Item
                                name="content"
                                label="网页地址"
                                rules={[{ required: true, message: '请输入文本内容' }]}
                            >
                                <Input />
                            </Form.Item>
                        }

                    </Col>
                </Row>

            </Form>


        </Modal>

        <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
            <Space >
                <Button type="primary" onClick={() => {
                    pageForm.resetFields();
                    setParam({ ...param, modalVisible: true })
                }}>添加知识</Button>
            </Space>
        </Form>

        <SearchTable url={"/api/app/meta/v1.0/data/search"}
            param={param}
            columns={[
                {
                    title: '标题',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                    render: (_, record) => {
                        return moment(_).format("yyyy-MM-DD HH:mm:ss")
                    }
                }, {
                    title: '操作',
                    dataIndex: 'name',
                    key: 'name',
                    render: (_, record) => (
                        <Space size="middle">
                            <a onClick={() => {
                                setParam({
                                    ...param,
                                    id: record.id,
                                    modalVisible: true
                                })
                            }}>修改</a>
                            <Popconfirm
                                title="你确认删除此数据么?"
                                onConfirm={() => {
                                    post(module.app + '/meta/v1.0/data/delete', {
                                        metaCode: param.metaCode,
                                        id: record.id,
                                    }).then(res => {
                                        setParam({ ...param });
                                        message.info("删除成功");
                                    })
                                }}
                                okText="确认"
                                cancelText="取消" >
                                <a href="#">删除</a>
                            </Popconfirm>

                        </Space>
                    )
                }
            ]} />
    </Drawer>)
}

export default Page;