import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import DictSelect from 'component/DictSelect'
import FormDrawer from './approvaling-form';

import moment from 'moment'
import { getMetaLabel } from 'util/meta';
const Page = () => {

    const metaCode = "xspaceSpace";
    const [param, setParam] = useState({
        metaCode: metaCode,
        params: [{
            attrCode: "status",
            operator: "=",
            value: "approvaling"
        }]
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });
    const [searchForm] = Form.useForm();

    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >


                <Form.Item
                    name="status"
                >
                    <DictSelect
                        dictCode={"PROJECT_STATUS"}
                        style={{ width: 100 }}
                        placeholder="状态"
                    />
                </Form.Item>

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        var newParam = searchForm.getFieldsValue();
                        setParam({
                            ...param,
                            params: newParam,
                            searchName: newParam.searchName
                        })
                    }}>搜索</Button>
                </Space>


            </Form>

            <SearchTable url={module.app + "/meta/v1.0/data/search"}
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '开放时间',
                        dataIndex: 'startTime',
                        key: 'startTime',
                        render: (_, record) => {
                            return _ + "-" + record.endTime;
                        }
                    },
                    {
                        title: '联系电话',
                        dataIndex: 'phone',
                        key: 'phone',
                    },
                    {
                        title: '地址',
                        dataIndex: 'address',
                        key: 'address',
                    }, {
                        title: '备注',
                        dataIndex: 'remark',
                        key: 'remark',
                    }, {
                        title: '发布人',
                        dataIndex: 'creatorName',
                        key: 'creatorName',
                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: 'status',
                        render: (_, record) => {
                            return getMetaLabel('SPACE_STATUS', _);
                        }
                    }, {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setFormParam({
                                        id: record.id,
                                        visible: true
                                    })
                                }}>审批</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.app + '/meta/v1.0/data/delete', {
                                            metaCode: metaCode,
                                            id: record.id
                                        }).then(res => {
                                            setParam({ ...param });
                                            message.info("删除成功");
                                        })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />
            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/xspace/space/approvaling", Page);
export default Page;