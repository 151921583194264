import { Input, DatePicker } from 'antd';
import locale from 'antd/lib/locale/zh_CN';

const DynamicFormItem = ({
    editType,
    ...nextProps
}) => {
    switch (editType) {
        case 'number':
            return (<Input type='number' {...nextProps} />);
        case 'date':
            return (<DatePicker locale={locale} format="yyyy-MM-DD"   {...nextProps} />);
        default:
            return (<Input {...nextProps} />)
    }
}
export default DynamicFormItem;