import React, { useEffect } from 'react'
import {
    Form,
    Input,
    message,
    Modal,
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';

import TextArea from 'antd/lib/input/TextArea';

const Page = ({
    param,
    setParam,
    reload
}) => {

    const metaCode = "cmsApp";

    const [pageForm] = Form.useForm();

    useEffect(() => {
        if (param.visible) {
            pageForm.resetFields();
            if (param.id != '') {
                post(module.app + '/my/meta/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            }
        }


    }, [param])

    return (<Modal title={param.title}
        placement="right"
        width={560}
        maskClosable={false}
        onOk={() => {
            pageForm.validateFields().then((data) => {
                const url = data.id ? '/api/cms/my/app/update'
                    : '/api/cms/my/app/create';
                post(url, data).then(res => {
                    setParam({ ...param, visible: false });
                    reload();
                    message.info("创建成功");
                })
            })
        }}
        onCancel={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}

    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 120 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name='name' label="应用名称">
                <Input />
            </Form.Item>
            <Form.Item name='intro' label="应用简介">
                <TextArea />
            </Form.Item>
        </Form>
    </Modal>)
}

export default Page;