import React, { useEffect, useState, useContext } from 'react';
import { post } from 'util/http';
import module from 'util/module';
import { LoginContext } from 'util/context';
import { getMetaLabel } from 'util/meta';


import { addRoute, DynamicPages, setBreadcrumbs } from 'component/DynamicRotes';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Image, Avatar, Space, Dropdown, Button } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';

import _ from 'lodash';

const { Header, Content, Sider } = Layout;

const ALL_MENU_MAP = {};

function Page() {


    const [topMenu, setTopMenu] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.APP_CONFIG.headMenu && setTopMenu(JSON.parse(window.APP_CONFIG.headMenu))
    }, []);


    const goLogin = () => {
        if (window.APP_CONFIG.loginUrl) {
            window.location.href = window.APP_CONFIG.loginUrl;
        } else {
            navigate("/login");
        }
    }

    const menu = (
        <Menu
            onClick={(item) => {
                if (item.key == 'logout') {
                    post(module.auth + '/auth/v1.0/logout', {})
                        .then(res => {
                            if (window.APP_CONFIG.loginUrl) {
                                window.location.href = window.APP_CONFIG.loginUrl;
                            } else {
                                window.location.href = "/login";
                            }
                        })
                }
            }}
            items={[
                {
                    label: '退出登录',
                    key: 'logout',
                }
            ]}
        />
    );


    return (
        <Header className="xcloud-header">
            <div className="logo" style={{ cursor: 'pointer' }} onClick={() => {
                navigate("/");
            }}>
                {window.APP_CONFIG.logo ? (<img src={window.APP_CONFIG.logo} />) : <img src={require('../asserts/images/logo2.png')} />}
                {window.APP_CONFIG.appName ? window.APP_CONFIG.appName : "希禾云"}
            </div>
            <Menu theme="light" mode="horizontal"
                selectedKeys={[location.pathname]}
                items={topMenu}
                onClick={(e) => {
                    if (e.key.startsWith("http")) {
                        window.open(e.key);
                    } else {
                        navigate(e.key);
                    }
                }}
                style={{ float: 'left' }} />

            <div style={{ float: 'right' }}>
                <Button type="text" style={{ marginRight: 20 }}>控制台</Button>
                {window.LOGIN_INFO != null && <Dropdown overlay={menu} >
                    <Space style={{ cursor: "pointer" }}>
                        <Avatar icon={<img src={window.LOGIN_INFO.attributes.headimg} />} />
                        <span>{window.LOGIN_INFO.name}</span>
                        <DownOutlined />
                    </Space>
                </Dropdown>
                }
                {window.LOGIN_INFO == null &&
                    <Button type="text" onClick={() => { goLogin(); }}>登录</Button>
                }
            </div>
        </Header>
    );
}
export default Page;
