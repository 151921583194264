import React, { useState } from 'react'
import { Button, Form, Input, message, Popconfirm, Space } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import PageDrawer from './form.js'
import ModuleDrawer from './module.js'
import { getMetaLabel } from 'util/meta';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import PageForm from '../application/form.js';

const Page = () => {
  const [pageStatus, setPageStatus] = useState({
    clusterCode: null,
    clusterId: null,
    drawerId: null
  })

  const [formParam, setFormParam] = useState({
    visible: false,
    clusterId: ""
  });

  const [moduleParam, setModuleParam] = useState({
    visible: false,
    clusterCode: "-1"
  });

  const [param, setParam] = useState({ searchName: "", size: 10, current: 1 });
  const [searchForm] = Form.useForm();

  return (
    <div>
      <Form form={searchForm} layout="inline" style={{ padding: 15 }}>
        <Form.Item
          name="searchName"
        >
          <Input
            style={{ width: '100%' }}
            placeholder="请输入名称"
          />
        </Form.Item>
        <Space>
          <Button onClick={() => {
            setParam(searchForm.getFieldsValue())
          }}>搜索</Button>
          <Button type="primary" onClick={() => {
            setFormParam({ clusterId: '', visible: true })
          }}>创建</Button>

        </Space>


      </Form>

      <SearchTable url={module.cloud + "/cluster/v1.0/search"}
        param={param}
        columns={[
          {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
          }, {
            title: '域名',
            dataIndex: 'domain',
            key: 'domain',
          }, {
            title: '范围',
            dataIndex: 'scope',
            key: 'scope',
            render: (_, record) => {
              return getMetaLabel('CLUSTER_SCOPE', _);
            }
          }, {
            title: '操作',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
              <Space size="middle">
                <a onClick={() => {
                  setModuleParam({ clusterCode: record.code, visible: true })
                }}>模块</a>
                <a onClick={() => {
                  setFormParam({ clusterId: record.id, visible: true })
                }}>修改</a>
                <Popconfirm
                  title="你确认删除此数据么?"
                  onConfirm={() => {
                    post(module.cloud + '/cluster/v1.0/delete',
                      { id: record.id })
                      .then(res => {
                        setParam({ ...param });
                        message.info("删除成功");
                      })
                  }}
                  okText="确认"
                  cancelText="取消">
                  <a href="#">删除</a>
                </Popconfirm>

              </Space>
            )
          }
        ]} />

      <PageDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
      <ModuleDrawer param={moduleParam} setParam={setModuleParam} />
    </div>
  );
}

addPage("/cloud/cluster/manager", Page);

export default Page;