import React, { useState } from 'react'
import { Button, Form, Input, message, Popconfirm, Space, Modal, Col, Row, } from 'antd';
import { post } from 'util/http';
import { getMetaLabel } from 'util/meta';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchSelect from 'component/SearchSelect';
import DomainDrawer from './domain'

const Page = () => {

  const [pageForm] = Form.useForm();

  const [pageStatus, setPageStatus] = useState({
    clusterCode: null,
    clusterId: null,
    drawerId: null
  })

  const [formParam, setFormParam] = useState({
    visible: false,
    clusterId: ""
  });

  const [domainParam, setDomainParam] = useState({
    visible: false,
    applicationCode: "-1",
    clusterCode: "-1"
  })


  const [moduleParam, setModuleParam] = useState({
    visible: false,
    clusterCode: "-1"
  });

  const [param, setParam] = useState({ searchName: "", size: 10, current: 1 });
  const [searchForm] = Form.useForm();

  return (
    <div>
      <Form form={searchForm} layout="inline" style={{ padding: 15 }}>
        <Form.Item
          name="searchName"
        >
          <Input
            style={{ width: '100%' }}
            placeholder="请输入名称"
          />
        </Form.Item>
        <Space>
          <Button onClick={() => {
            setParam(searchForm.getFieldsValue())
          }}>搜索</Button>
          <Button type="primary" onClick={() => {
            setFormParam({ visible: true })
            pageForm.resetFields();
          }}>创建</Button>

        </Space>


      </Form>

      <SearchTable url={"/api/nginx/server/v1.0/search"}
        param={param}
        columns={[
          {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
          }, {
            title: '集群',
            dataIndex: 'clusterName',
            key: 'clusterName',
          }, {
            title: '版本',
            dataIndex: 'version',
            key: 'version',
          }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
          }, {
            title: '操作',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
              <Space size="middle">
                <a onClick={() => {
                  setFormParam({ visible: true })
                  pageForm.setFieldsValue(record);
                  // setModuleParam({ clusterCode: record.code, visible: true })
                }}>修改</a>
                <a onClick={() => {
                  setDomainParam({
                    visible: true,
                    serverCode: record.code,
                    domain: ""
                  })
                }}>接入域名</a>
                <Popconfirm
                  title="你确认删除此数据么?"
                  onConfirm={() => {
                    post('/api/nginx/server/v1.0/delete',
                      { id: record.id })
                      .then(res => {
                        setParam({ ...param });
                        message.info("删除成功");
                      })
                  }}
                  okText="确认"
                  cancelText="取消">
                  <a href="#">删除</a>
                </Popconfirm>

              </Space>
            )
          }
        ]} />

      <DomainDrawer setParam={setDomainParam} param={domainParam} />


      <Modal width={560} title="添加实例" visible={formParam.visible} onOk={() => {
        const data = pageForm.getFieldsValue();
        const url = data.id ? '/api/nginx/server/v1.0/update'
          : '/api/nginx/server/v1.0/create';;
        post(url, data)
          .then(res => {
            message.info("操作成功");
            setFormParam({ ...formParam, visible: false });
            setParam({ ...param });
          })

      }} onCancel={() => { setFormParam({ ...formParam, visible: false }) }}>

        <Form form={pageForm} layout="horizontal" hideRequiredMark
          labelCol={{
            style: { width: 80 }
          }}
          initialValues={{
            items: []
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden"
                />
              </Form.Item>
              <Form.Item style={{ display: 'none' }}
                name="applicationCode">
                <Input type="hidden"
                />
              </Form.Item>

              <Form.Item
                name="clusterCode"
                label="所属集群"
                rules={[{ required: true, message: '请选择集群' }]}
              >
                <SearchSelect url={"/api/nginx/cluster/v1.0/search"} labelCode="name" valueCode="code" />
              </Form.Item>
              <Form.Item
                name="name"
                label="名称"
                rules={[{ required: true, message: '请输入名称' }]}
              >
                <Input />
              </Form.Item>

            </Col>
          </Row>

        </Form>


      </Modal>
    </div>
  );
}
addPage("/nginx/server/manager", Page);


export default Page;