import React, { useEffect, useState } from 'react'
import { Button, Modal, Space, Popconfirm, Row, Col, message, Empty } from 'antd';
import { post } from 'util/http';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import moment from 'moment'
import pay from 'util/pay';
const Page = () => {

    const [account, setAccount] = useState(null);

    useEffect(() => {
        post('/api/ac/my/account/info', {})
            .then(res => {
                setAccount(res.data)
            })
    }, [])

    const buyProduct = (productCode, description) => {
        post("/api/ec/public/order/create", {
            description: description,
            orderProducts: [{
                productCode: productCode,
                number: 1
            }]
        }).then(res => {
            const orderCode = res.data.code;
            pay.showWeixinPay(orderCode);
        })
    }

    return (
        <div>

            < Empty
                style={{ paddingTop: 100, fontSize: 23 }}
                image={require('../../../asserts/images/develop.jpg')}
                imageStyle={{ height: 400 }}
                description={""}
            >
                <Button type="primary" onClick={() => {
                    buyProduct("custom-develop", "1888网站定制开发")
                }}>立即购买</Button>
            </Empty>



        </div >
    );
}
addPage("/cms/custom/develop", Page);

export default Page;