
import { Button, Input, Space, Drawer, Form, Col, Row, Select, message, Switch } from 'antd';
import { addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import module from 'util/module';
import { useNavigate } from 'react-router-dom';
import './login.css'

import ImChat from 'util/Im';

function Login() {

    const navigate = useNavigate();
    const [loginForm] = Form.useForm();

    return (<div id="loginPage">
        <div className="login-poster"></div>
        <div className="login-area">
            <Form form={loginForm} layout="horizontal" hideRequiredMark
                style={{
                    marginTop: 200,
                    paddingLeft: 70
                }}
                labelCol={{
                    style: {
                        width: 80,
                    }
                }}
                initialValues={{
                    items: []
                }}
            >
                <Row gutter={16}>
                    <Col span={24} style={{ marginBottom: 15 }}>
                        <Form.Item
                            name="accountCode"
                            rules={[{ required: true, message: '请输入账号' }]}
                        >
                            <Input
                                style={{ width: '100%' }} size="large"
                                placeholder="请输入账号"
                            />
                        </Form.Item>

                    </Col>
                    <Col span={24} style={{ marginBottom: 15 }}>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: '请选输入密码' }]}
                        >
                            <Input
                                type="password" size="large"
                                style={{ width: '100%' }}
                                placeholder="请选输入密码"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Button block type='primary' size="large" onClick={() => {
                            loginForm.validateFields().then((values) => {
                                post(module.auth + "/auth/v1.0/login", values)
                                    .then(res => {
                                        message.info("登录成功");
                                        try {
                                            ImChat.login({
                                                loginAccount: values.accountCode,
                                                loginPassword: values.password
                                            })
                                        } catch (e) {
                                            console.log(e)
                                        }
                                        window.location.href = "/";
                                    })
                            })
                        }} >提交</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    </div>);
}
addRoute("/login", Login);
export default Login;
