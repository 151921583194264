import React, { useCallback, useEffect, useState } from 'react'
import { Table, Pagination } from 'antd';
import { post } from 'util/http';


const Page = ({
    columns,
    url,
    param,
    keyCode = "id",
    ...nextProps
}) => {
    const [pageData, setPageData] = useState({
        size: 1
    });

    const loadData = useCallback((url, param) => {
        post(url, param || { size: 1 })
            .then(res => {
                setPageData(res.data || {
                    items: []
                });
            })
    }, [param])

    useEffect(() => {
        loadData(url, param);
    }, [param])


    return (
        <>
            <Table dataSource={pageData.items} columns={columns}  {...nextProps}
                pagination={{
                    pageSize: pageData.size,
                    total: pageData.total,
                    showTotal: (total) => `总共 ${total} 条数据`,
                    onChange: (current, size) => {
                        loadData(url, { ...param, current: current, size: size });
                    }
                }}
                rowKey={record => record.metadata.name}
            >
            </Table >

            {/* <Pagination
                style={{ position: 'absolute', bottom: 10, right: 0 }}
                pageSize={pageData.size}
                total={pageData.total}
                showTotal={(total) => `总共 ${total} 条数据1`}
                onChange={(current, size) => {
                    loadData(url, { ...param, current: current, size: size });
                }}
            /> */}
        </>
    );
}

export default Page;