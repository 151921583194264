import { Modal } from 'antd';
import { QRCodeCanvas } from 'qrcode.react'
import { post } from 'util/http';

const pay = {
    intervalId: null,

    startCheckOrderStatus: function (orderCode, callback) {
        pay.intervalId = setInterval(function () {
            post("/api/ec/my/order/get", {
                orderCode: orderCode
            }).then(res => {
                if (res.data.status == "paying") {
                    console.log("支付中")
                } else if (res.data.status == "paySuccess") {
                    Modal.destroyAll();
                    Modal.success({
                        title: '支付提醒',
                        content: "支付成功！"
                    })
                    if (pay.intervalId != null) {
                        clearInterval(pay.intervalId);
                    }
                    if (callback) {
                        callback();
                    }
                }
            })
        }, 2000)
    },
    showWeixinPay: function (orderCode, callback) {
        pay.startCheckOrderStatus(orderCode, callback);
        Modal.confirm({
            title: '微信扫码支付',
            content: <QRCodeCanvas
                value={window.APP_CONFIG.consoleDomain + "/api/weixin/auth/v1.0/base/login?targetUrl=/ec/payment/weixin?orderCode=" + orderCode}
            >
            </QRCodeCanvas>,
            afterClose: () => {
                if (pay.intervalId != null) {
                    clearInterval(pay.intervalId);
                }
            }
        });
    }
}

export default pay;