import React, { useCallback, useEffect, useState } from 'react'
import { Table, Pagination, Spin } from 'antd';
import { post } from 'util/http';


const SearchTable = ({
    columns,
    url,
    param,
    keyCode = "id",
    keyData = "records",
    simplePagination = false,
    ...nextProps
}) => {
    const [spinning, setSpinning] = useState(false);
    const [pageData, setPageData] = useState({
        size: 20
    });

    const loadData = useCallback((url, newParam) => {
        setSpinning(true);
        post(url, { ...param, current: pageData.current, ...newParam })
            .then(res => {
                setSpinning(false);
                setPageData({ ...res.data });
            }, err => {
                setSpinning(false);
            })
    }, [param])

    useEffect(() => {
        loadData(url, param);
    }, [param])


    return (
        <Spin tip="加载中..." spinning={spinning} size='large' delay={200}>
            <Table dataSource={pageData[keyData]} columns={columns}
                rowKey={record => record[keyCode]}
                pagination={null}
                {...nextProps}
            >
            </Table >
        </Spin>
    );
}

export default SearchTable;