import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
    Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem, { DateField } from 'component/MetaFormItem';
import RichTextEditor from 'component/RichTextEditor'
import ImageUpload from 'component/ImageUpload'
import DictSelect from 'component/DictSelect'

import SearchTree from 'component/SearchTree';
import TextArea from 'antd/lib/input/TextArea';

const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "cmsArticle"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });


    useEffect(() => {
        post(module.app + '/meta/v1.0/get-by-code', { code: metaCode })
            .then(res => {
                setMeta(res.data);
            })
    }, [])
    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post(module.app + '/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                pageForm.resetFields();
            }
        }


    }, [param])

    return (<Drawer title="创建项目"
        placement="right"
        width={'85%'}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button onClick={() => {
                    setParam({ ...param, visible: false })
                }}>取消</Button>
                <Button type="primary" onClick={() => {
                    pageForm.validateFields().then((data) => {
                        data.typeName = typeName;
                        const url = module.app + '/meta/v1.0/data/save';
                        post(url, {
                            metaCode: metaCode,
                            datas: data
                        }).then(res => {
                            setParam({ ...param, visible: false });
                            reload();
                            message.info("保存成功");
                        })
                    })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={pageForm}
            //  layout="vertical"
            hideRequiredMark
            labelCol={{
                style: { width: 120 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name='typeName' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item name='fullTypeName' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item name='fullChannelCode' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item name='regionName' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item name='fullRegionName' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>
            <Form.Item name='fullRegionCode' style={{ display: 'none' }} >
                <Input type={"hidden"} />
            </Form.Item>



            <Row gutter={16}>
                <Col span={18}>
                    <Form.Item name='channelCode' label="所属栏目">
                        <SearchTree
                            url="/api/app/meta/v1.0/data/tree"
                            valueCode="code"
                            onChange={(_, v, extra) => {
                                pageForm.setFieldsValue({
                                    channelTitle: extra.triggerNode.props.data.name,
                                    // fullTypeName: extra.triggerNode.props.data.fullNames,
                                    fullChannelCode: extra.triggerNode.props.data.fullCodes
                                })
                            }}
                            param={{
                                "nameAttr": "title",
                                "metaCode": "cmsChannel",
                                "showRoot": false
                            }} />
                    </Form.Item>
                    <Form.Item name='title' label="标题"
                        rules={[{ required: true, message: '请输入标题' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='outsideUrl' label="外部链接"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item name='titleImg' label="标题图片">
                        <ImageUpload />
                    </Form.Item>
                    <Form.Item name='intro' label="简介">
                        <TextArea />
                    </Form.Item>
                    <Form.Item name='content' label="内容">
                        <RichTextEditor />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    {/* <Form.Item name='code' label="文章code">
                        <Input />
                    </Form.Item> */}
                    <Form.Item name='channelTitle' label="栏目标题" style={{ display: "none" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='fullChannelCode' label="栏目全路径" style={{ display: "none" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='parentCode' label="上级文章">
                        <SearchTree
                            url="/api/app/meta/v1.0/data/tree"
                            valueCode="code"
                            param={{
                                "nameAttr": "title",
                                "metaCode": "cmsArticle",
                                "showRoot": true
                            }} />
                    </Form.Item>

                    <Form.Item name='articleType' label="文章类型">
                        <DictSelect dictCode={"ARTICLE_TYPE"} />
                    </Form.Item>
                    <Form.Item name='tags' label="标签">
                        <Input />
                    </Form.Item>
                    <Form.Item name='status' label="状态">
                        <DictSelect dictCode={"CMS_STATUS"} />
                    </Form.Item>
                    <Form.Item name='showOrder' label="排序">
                        <Input type='number' />
                    </Form.Item>
                    <Form.Item name='publishTime' label="发布时间">
                        <DateField />
                    </Form.Item>
                </Col>
            </Row>

        </Form>
    </Drawer>)
}

export default Page;