import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Modal } from 'antd';
import { post } from 'util/http';
import { addRoute } from 'component/DynamicRotes.js';
import { Result } from 'antd-mobile'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Page = () => {

    const navigate = useNavigate();

    const query = useQuery();
    const orderCode = query.get("orderCode");

    post("/api/weixin/js/config", {
        url: window.location.href
    }).then(res => {
        window.wx.config({
            debug: false,
            jsApiList: ['showMenuItems'],
            ...res.data
        })
    })


    const createPayment = (orderCode) => {
        post("/api/ec/weixin/payment/create", {
            orderCode: orderCode
        }).then(res => {
            const responseData = JSON.parse(res.data.responseData);
            responseData.timestamp = responseData.timeStamp;
            responseData.success = function (res) {
                navigate("/ec/payment/success?orderCode=" + orderCode)
            }
            window.wx.chooseWXPay(responseData);
        })
    }
    if (orderCode) {
        createPayment(orderCode)
    } else {
        return (
            <div>
                <Result
                    status='error'
                    title='支付失败'
                    description='订单号不存在'
                />
            </div >
        );
    }
    return (
        <div>
            <Result
                status='waiting'
                title='支付请求中'
                description='请在微信中完成支付'
            />
        </div >
    );
}
addRoute("/ec/payment/weixin", Page);

export default Page;