import React, { useEffect, useState } from 'react'
import {
    Button,
    Form,
    message,
    Modal,
    Space
} from 'antd';
import { post } from 'util/http';
import SearchSelect from 'component/SearchSelect';

const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "nginxSsl"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });


    useEffect(() => {
        if (param.visible) {
            pageForm.resetFields();
        }
    }, [param])

    return (<Modal title="部署证书"
        placement="right"
        width={460}
        maskClosable={false}
        onCancel={() => {
            setParam({ ...param, visible: false })
        }}
        onOk={() => [
            pageForm.validateFields().then((data) => {
                data.sslCode = param.sslCode;
                const url = '/api/nginx/ssl/v1.0/deploy';
                post(url, data).then(res => {
                    setParam({ ...param, visible: false });
                    reload();
                    message.info("部署成功");
                })
            })
        ]}
        visible={param.visible}
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 120 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item
                name="clusterCode"
                label="所属集群"
                rules={[{ required: true, message: '请选择集群' }]}
            >
                <SearchSelect url={"/api/nginx/cluster/v1.0/search"} labelCode="name" valueCode="code" />
            </Form.Item>
        </Form>
    </Modal>)
}

export default Page;