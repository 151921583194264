import React, { useEffect, useState } from 'react'
import { Button, Input, Space, Drawer, Form, Col, Row, Select, message } from 'antd';
import SearchSelect from 'component/SearchSelect';
import { post } from 'util/http';
import module from 'util/module';

const RoleForm = ({
    roleId,
    drawerId,
    setParam,
    setDrawerId,
    formatData = (data) => {
        const newData = { ...data, accounts: [] };
        data.accounts.map(item => {
            newData.accounts.push({
                value: item.accountCode,
                label: item.accountName
            })
        });
        return newData;
    }
}) => {

    const [role, setRole] = useState({
        items: []
    });

    const [roleForm] = Form.useForm();


    useEffect(() => {
        if ('roleForm' == drawerId) {
            roleForm.resetFields();
        }
        if (roleId != null && drawerId == 'roleForm') {
            post(module.ac + '/role/v1.0/get', { id: roleId })
                .then(res => {
                    setRole(res.data);
                    roleForm.setFieldsValue(formatData(res.data));
                })
        }
    }, [roleId, drawerId])

    return (<Drawer title="角色配置"
        placement="right"
        width={660}
        maskClosable={false}
        onClose={() => { setDrawerId({}) }}
        visible={drawerId == 'roleForm'}
        extra={
            <Space>
                <Button onClick={() => { setDrawerId({}) }}>取消</Button>
                <Button type="primary" onClick={() => {
                    const param = roleForm.getFieldsValue();
                    const newParam = { ...param };
                    newParam.accounts && newParam.accounts.map(item => {
                        item.accountCode = item.value;
                        item.accountName = item.label;
                    })
                    const url = newParam.id ? module.ac + '/role/v1.0/update' : module.ac + '/role/v1.0/create';
                    post(url, newParam)
                        .then(res => {
                            setParam({});
                            setDrawerId({})
                            message.info("保存成功");
                        })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={roleForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                items: []
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item style={{ display: 'none' }}
                        name="id">
                        <Input type="hidden"
                        />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"
                        />
                    </Form.Item>

                </Col>
                <Col span={24}>
                    <Form.Item
                        name="code"
                        label="code"
                        rules={[{ required: true, message: '请选择父节点' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"
                        />

                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="remark"
                        label="remark"
                        rules={[{ required: true, message: '请输入type' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"
                        />
                    </Form.Item>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="accounts"
                        label="用户"
                    >
                        <SearchSelect url={module.ac + "/account/v1.0/search"} labelCode="name" valueCode="code" mode="multiple" labelInValue={true} />
                    </Form.Item>
                </Col>
                <Col span={24}>

                </Col>
            </Row>



        </Form>
    </Drawer>)
}

export default RoleForm;