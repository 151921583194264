import React, { useCallback, useEffect, useState } from 'react'
import { Input, Avatar, Drawer, message, Col, Row, Button } from 'antd';
import { post } from 'util/http';
import { v4 as uuidv4 } from 'uuid';
import ImChat from 'util/Im';
import AuthUtil from 'util/auth'


if (window.LOGIN_INFO) {
    ImChat.login({
        loginAccount: window.LOGIN_INFO.code
    })
}

const SearchTable = ({
    param,
    setParam,
    keyCode = "id",
    pushMsg,
    simplePagination = false,
    ...nextProps
}) => {
    const [msg, setMsg] = useState('');

    const [chatHistoy, setChatHistoy] = useState({
        records: []
    });

    const loginAccount = window.LOGIN_INFO || {};
    const appendRecord = (data) => {
        if (chatHistoy.records.length == 0 || data.msgId != chatHistoy.records[chatHistoy.records.length - 1].msgId) {
            chatHistoy.records.push(data)
        } else {
            const lastMsg = chatHistoy.records[chatHistoy.records.length - 1];
            lastMsg.content = lastMsg.content + data.content;
        }
        setChatHistoy({ ...chatHistoy });
    }

    const resetChatHistory = (datas) => {
        chatHistoy.records = datas;
        setChatHistoy({ ...chatHistoy });
    }

    const clearChatHistory = () => {
        chatHistoy.records = [];
        setChatHistoy({ ...chatHistoy });
    }

    const getHeadimg = (msg) => {
        if (msg.headimg != null) {
            return msg.headimg;
        } else if (msg.creatorCode != null) {
            return "/api/ac/public/headimg/" + msg.creatorCode;
        } else {
            return "/robot.png"
        }
    }

    const sendMsg = () => {
        const msgId = uuidv4();
        const data = {
            "msgId": msgId,
            "msgType": "text",
            "content": msg,
            "chatCode": param.chatCode,
            "tenantId": window.APP_CONFIG.tenantId,
            "tokenId": AuthUtil.getTokenId(),
            "source": "Human",
            "conversation": 1,
            "creatorCode": window.LOGIN_INFO.code,
            "targetCode": param.robotCode,
            "createTime": new Date().getTime()
        }
        ImChat.client.publish("token/chat", JSON.stringify(data), { qos: 0 }, (error) => {
            if (!error) {
                console.log('发送成功:')
            } else {
                console.log('发送失败')
            }
        })
        appendRecord(data)
        setMsg('');

    }


    useEffect(() => {
        if (!param.visible) {
            clearChatHistory();
        } else {
            if (param.chatCode != '') {
                ImChat.onChat(param.chatCode, (msg) => {
                    appendRecord(msg)
                });
                post('/api/ai/my/chat/history/get', {
                    chatCode: param.chatCode,
                }).then(res => {
                    const newRecords = [];
                    res.data.forEach((item) => {
                        newRecords.push(JSON.parse(item));
                    })
                    resetChatHistory(newRecords);
                })
            }
        }
    }, [param.visible])



    return (
        <Drawer title="会话详情"
            placement="right"
            width={660}
            maskClosable={false}
            onClose={() => {
                setParam({ ...param, visible: false })
            }}
            visible={param.visible}
        >
            <div style={{ paddingBottom: '80px' }}>
                {chatHistoy.records.map((v) =>
                    <div
                        className={v.creatorCode != loginAccount.code ? 'flex-row-left' : 'flex-row-reverse-right'}
                        key={v.msgId}
                        style={{ padding: "0px 0px 20px 0px" }}>
                        <div style={{ width: 80 }}>
                            <Avatar style={{ margin: '0px 10px', }} icon={<img src={getHeadimg(v)} />} />
                        </div>
                        {v.content}
                    </div>
                )}
                <div style={{ position: 'fixed', bottom: 0, width: '640px', padding: "20px", backgroundColor: "#ffffff" }}>
                    <Row gutter={16} >
                        <Col flex={"1"}>
                            <Input style={{ hight: '30px', lineHeight: '30px' }} value={msg} onChange={(e) => { setMsg(e.target.value) }} />
                        </Col>
                        <Col style={{ width: 80 }}>
                            <Button type="primary" size='large' onClick={() => {
                                sendMsg();
                            }}>发送</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Drawer>
    );
}
export default SearchTable;
