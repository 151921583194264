import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import EditTable from 'component/EditTable';
import { post } from 'util/http';
import module from 'util/module';
const { TextArea } = Input;

const Page = ({
    param,
    setParam
}) => {

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();

    return (<Drawer title="域名"
        placement="right"
        width={860}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
    >

        {param.domain == '' &&
            <div>
                <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                    <Form.Item
                        name="searchName"
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"
                        />
                    </Form.Item>
                    <Space >
                        <Button onClick={() => {
                            setParam({ ...param, ...searchForm.getFieldsValue() })
                        }}>搜索</Button>
                        <Button type="primary" onClick={() => {
                            pageForm.resetFields();
                            setParam({ ...param, modalVisible: true })
                        }}>添加域名</Button>
                    </Space>
                </Form>
                <SearchTable url={"/api/nginx/server/v1.0/domain/search"}
                    param={param}
                    columns={[
                        {
                            title: '域名',
                            dataIndex: 'domain',
                            key: 'domain',
                        },
                        {
                            title: '操作',
                            dataIndex: 'name',
                            key: 'name',
                            render: (_, record) => (
                                <Space size="middle">
                                    <a onClick={() => {
                                        setParam({ ...param, modalVisible: true })
                                        pageForm.setFieldsValue(record);
                                    }}>修改</a>

                                    <a onClick={() => {
                                        setParam({ ...param, domain: record.domain });
                                    }}>转发配置</a>


                                    <Popconfirm
                                        title="你确认删除此数据么?"
                                        onConfirm={() => {
                                            post('/api/nginx/server/v1.0/domain/delete', { id: record.id })
                                                .then(res => {
                                                    setParam({ ...param });
                                                    message.info("删除成功");
                                                })
                                        }}
                                        okText="确认"
                                        cancelText="取消" >
                                        <a href="#">删除</a>
                                    </Popconfirm>
                                </Space>
                            )
                        }
                    ]} />

                <Modal width={860} title="添加域名" visible={param.modalVisible} onOk={() => {
                    const data = pageForm.getFieldsValue();
                    data.serverCode = param.serverCode;
                    const url = data.id ? '/api/nginx/server/v1.0/domain/update'
                        : '/api/nginx/server/v1.0/domain/create';
                    post(url, data)
                        .then(res => {
                            message.info("创建成功");
                            setParam({ ...param, modalVisible: false })
                        })

                }} onCancel={() => { setParam({ ...param, modalVisible: false }) }}>

                    <Form form={pageForm} layout="horizontal" hideRequiredMark
                        labelCol={{
                            style: { width: 80 }
                        }}
                        initialValues={{
                            items: []
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item style={{ display: 'none' }}
                                    name="id">
                                    <Input type="hidden"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="domain"
                                    label="域名"
                                    rules={[{ required: true, message: '请输入域名' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="port"
                                    label="端口"
                                    rules={[{ required: true, message: '请输入端口' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="config"
                                    label="配置"
                                >
                                    <TextArea style={{ height: 200 }} />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>


                </Modal>
            </div>
        }

        {
            param.domain != '' &&
            <div>
                <p>  {param.domain}</p>
                <Space>
                    <Button onClick={() => {
                        setParam({ ...param, domain: '' })
                    }}>返回</Button>
                    <Button type="primary" onClick={() => {
                        pageForm.resetFields();
                        setParam({ ...param, modalVisible: true })
                    }}>添加location</Button>
                </Space>

                <SearchTable url={"/api/nginx/server/v1.0/domain/location/search"}
                    param={param}
                    columns={[
                        {
                            title: '路径',
                            dataIndex: 'location',
                            key: 'location',
                        },
                        {
                            title: '操作',
                            dataIndex: 'name',
                            key: 'name',
                            render: (_, record) => (
                                <Space size="middle">
                                    <a onClick={() => {
                                        setParam({ ...param, modalVisible: true });
                                        pageForm.setFieldsValue(record);
                                        if (record.upstreamCode != '') {
                                            post('/api/nginx/server/v1.0/upstream/service/list', {
                                                upstreamCode: record.upstreamCode,
                                                serverCode: param.serverCode
                                            }).then(res => {
                                                pageForm.setFieldValue("services", res.data.services);
                                            })
                                        }
                                    }}>修改</a>


                                    <Popconfirm
                                        title="你确认删除此数据么?"
                                        onConfirm={() => {
                                            post('/api/nginx/server/v1.0/domain/location/delete', { id: record.id })
                                                .then(res => {
                                                    setParam({ ...param });
                                                    message.info("删除成功");
                                                })
                                        }}
                                        okText="确认"
                                        cancelText="取消" >
                                        <a href="#">删除</a>
                                    </Popconfirm>
                                </Space>
                            )
                        }
                    ]} />

                <Modal width={860} title="添加路径" visible={param.modalVisible} onOk={() => {
                    const data = pageForm.getFieldsValue();
                    data.serverCode = param.serverCode;
                    data.domain = param.domain;
                    const url = data.id ? '/api/nginx/server/v1.0/domain/location/update'
                        : '/api/nginx/server/v1.0/domain/location/create';
                    post(url, data).then(res => {
                        message.info("创建成功");
                        setParam({ ...param, modalVisible: false })
                    })

                }} onCancel={() => { setParam({ ...param, modalVisible: false }) }}>

                    <Form form={pageForm} layout="horizontal" hideRequiredMark
                        labelCol={{
                            style: { width: 80 }
                        }}
                        initialValues={{
                            items: []
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item style={{ display: 'none' }}
                                    name="id">
                                    <Input type="hidden"
                                    />
                                </Form.Item>
                                <Form.Item style={{ display: 'none' }}
                                    name="upstreamCode">
                                    <Input type="hidden"
                                    />
                                </Form.Item>
                                <Form.Item style={{ display: 'none' }}
                                    name="applicationCode">
                                    <Input type="hidden"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="location"
                                    label="路径"
                                    rules={[{ required: true, message: '请输入路径' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="config"
                                    label="配置"
                                >
                                    <TextArea style={{ height: 100 }} />
                                </Form.Item>

                                <Form.Item
                                    name="services"
                                    label="后端服务"
                                >
                                    <EditTable
                                        columns={[{
                                            title: "ip地址",
                                            dataIndex: "ip",
                                            key: 'ip',
                                            ellipsis: true,
                                            editable: true,
                                        }, {
                                            title: "端口",
                                            dataIndex: "port",
                                            key: 'port',
                                            ellipsis: true,
                                            editable: true,
                                        }]}
                                    >
                                    </EditTable>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>


                </Modal>

            </div>

        }



    </Drawer>)
}

export default Page;