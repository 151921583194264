import React, { useState, useEffect } from 'react'
import { Button, Input, Space, Popconfirm, Form, Select, message, Drawer } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { post } from 'util/http';
import module from 'util/module';

import SearchTable from 'component/SearchTable.js';

import { addPage } from 'component/DynamicRotes.js';

import FormDrawer from './form.js'
import ModuleDrawer from './module';
import DomainDrawer from './domain';

const Page = () => {

    const [pageStatus, setPageStatus] = useState({
        clusterCode: null,
        applicationCode: null,
        applicationId: null,
        drawerId: null
    })
    const [param, setParam] = useState({ searchName: "", size: 10, current: 1 });
    const [searchForm] = Form.useForm();

    const [moduleParam, setModuleParam] = useState({
        visible: false,
        applicationCode: "-1",
        clusterCode: "-1"
    })


    const [formParam, setFormParam] = useState({
        visible: false,
        applicationCode: ""
    });

    const [domainParam, setDomainParam] = useState({
        visible: false,
        applicationCode: "-1",
        clusterCode: "-1"
    })

    const navigate = useNavigate();
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        var data = searchForm.getFieldsValue();
                        setParam(data);
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({ applicationId: '', visible: true })
                    }}>创建空白应用</Button>
                    <Button type="primary" onClick={() => {
                        navigate("/public/template");
                    }}>从模板创建应用</Button>
                </Space>


            </Form>

            <SearchTable url={module.cloud + "/my/application/search"}
                param={param}
                columns={[
                    {
                        title: '所属集群',
                        dataIndex: 'clusterName',
                        key: 'clusterName',
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: 'appId',
                        dataIndex: 'appId',
                        key: 'appId',
                    }, {
                        title: '应用code',
                        dataIndex: 'code',
                        key: 'code',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">

                                <a onClick={() => {
                                    setModuleParam({
                                        visible: true,
                                        clusterCode: record.clusterCode,
                                        applicationCode: record.code,
                                        domain: record.domain
                                    })
                                }}>模块</a>

                                <a onClick={() => {
                                    setDomainParam({
                                        visible: true,
                                        clusterCode: record.clusterCode,
                                        applicationCode: record.code,
                                    })
                                }}>域名</a>

                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.cloud + '/my/application/delete', { id: record.id })
                                            .then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>


                            </Space>
                        )
                    }
                ]} />

            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
            <ModuleDrawer setParam={setModuleParam} param={moduleParam} />
            <DomainDrawer setParam={setDomainParam} param={domainParam} />
        </div >
    );
}
addPage("/my/application", Page);
export default Page;