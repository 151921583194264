import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Image } from 'antd';
import { post, get } from 'util/http';
import module from 'util/module';

import { useNavigate } from 'react-router-dom';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import ChatDrawer from 'component/ChatDrawer'
import FormDrawer from './form.js';
import FlowDrawer from './flow.js'

import moment from 'moment'



import { getMetaLabel } from 'util/meta';
import { Toast } from 'antd-mobile';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "pcdnNode";
    const [param, setParam] = useState({
        params: [],
        metaCode: metaCode,
        orders: []
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });

    const [flowParam, setFlowParam] = useState({ metaCode: metaCode });

    const [searchForm] = Form.useForm();
    const [chatParam, setChatParam] = useState({
        metaCode: "aiChatContent",
        visible: false
    })

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'name',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }

    const startChat = (robotCode) => {
        post("/api/ai/chat/getOrCreate", {
            robotCode: robotCode
        }).then(res => {
            const chatCode = res.data;
            setChatParam({
                ...chatParam,
                chatCode: chatCode,
                robotCode: robotCode,
                params: [{
                    attrCode: 'chatCode',
                    operator: "=",
                    value: chatCode
                }],
                visible: true
            })
        })
    }


    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>

                </Space>


            </Form>

            <SearchTable url={"/api/app/meta/v1.0/data/search"}
                param={param}
                columns={[
                    {
                        title: '节点',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: '总下载流量',
                        dataIndex: 'totalBandWidth',
                        key: 'totalBandWidth',
                    },
                    {
                        title: '状态',
                        dataIndex: 'lastHeartBeatTime',
                        key: 'lastHeartBeatTime',
                        render: (_, record) => {
                            var diff = new Date().getTime() - new Date(_).getTime();
                            if (diff > 10 * 1000) {
                                return <span style={{ color: "red" }}>离线</span>
                            } else {
                                return <span style={{ color: "green" }}>在线</span>
                            }
                        }
                    },
                    {
                        title: '最新心跳时间',
                        dataIndex: 'lastHeartBeatTime',
                        key: 'lastHeartBeatTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <>
                                <Space size="middle">
                                    <a onClick={() => {
                                        setFlowParam({
                                            id: record.id,
                                            visible: true
                                        })
                                    }}>流量趋势</a>
                                </Space>
                            </>
                        )
                    }
                ]} />
            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
            <FlowDrawer param={flowParam} setParam={setFlowParam} reload={() => { setFlowParam({ ...param }) }} />

        </div >
    );
}
addPage("/pcdn/node/manager", Page);
export default Page;