import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
    Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import DictSelect from "component/DictSelect";
import SearchTree from 'component/SearchTree';
import MetaFormItem from 'component/MetaFormItem'

const { Option } = Select;
const { TextArea } = Input;

const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "recruitWorkerType"

    const [pageForm] = Form.useForm();

    const [meta, setMeta] = useState({
        attrs: []
    });


    useEffect(() => {
        post('/api/app/public/meta/get', { code: metaCode })
            .then(res => {
                setMeta(res.data);
            })
    }, [])
    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post(module.app + '/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                pageForm.resetFields();
            }
        }


    }, [param])

    return (<Drawer title="创建集群"
        placement="right"
        width={660}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button onClick={() => {
                    setParam({ ...param, visible: false })
                }}>取消</Button>
                <Button type="primary" onClick={() => {
                    const data = pageForm.getFieldsValue();
                    const url = '/api/app/public/meta/data/save';
                    post(url, {
                        metaCode: metaCode,
                        datas: data
                    })
                        .then(res => {
                            setParam({ ...param, visible: false });
                            reload();
                            message.info("保存成功");
                        })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden"
                />
            </Form.Item>
            {meta.attrs.map((item) => {
                return item.isSystem == 2 &&
                    (
                        <Form.Item
                            name={item.attrCode}
                            label={item.attrName}
                            key={item.attrCode}
                        // rules={[{ required: true, message: '请输入名称' }]}
                        >
                            <MetaFormItem
                                pageForm={pageForm}
                                metaAttr={item}
                                onChange={(_, v) => {

                                }}
                            />

                        </Form.Item>
                    )
            })}
        </Form>
    </Drawer>)
}

export default Page;