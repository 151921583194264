import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
    Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem';

import SearchTree from 'component/SearchTree';

const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "cmsArticle"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });


    useEffect(() => {
        post(module.app + '/meta/v1.0/get-by-code', { code: metaCode })
            .then(res => {
                setMeta(res.data);
            })
    }, [])
    useEffect(() => {
        if (param.visible) {
            if (param.id != null) {
                post(module.app + '/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                pageForm.resetFields();
                pageForm.setFieldsValue(param);
            }
        }
    }, [param])

    return (<Drawer title="创建项目"
        placement="right"
        width={1160}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button onClick={() => {
                    setParam({ ...param, visible: false })
                }}>取消</Button>
                <Button type="primary" onClick={() => {
                    pageForm.validateFields().then((data) => {
                        const url = module.app + '/meta/v1.0/data/save';
                        post(url, {
                            metaCode: metaCode,
                            datas: data
                        }).then(res => {
                            message.info("保存成功");
                        })
                    })
                }}>
                    保存
                </Button>
                <Button type="primary" onClick={() => {
                    pageForm.validateFields().then((data) => {
                        const url = module.app + '/meta/v1.0/data/save';
                        post(url, {
                            metaCode: metaCode,
                            datas: data
                        }).then(res => {
                            setParam({ ...param, visible: false });
                            reload();
                            message.info("保存成功");
                        })
                    })
                }}>
                    保存并关闭
                </Button>
            </Space>
        }
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 120 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>

            <Form.Item name="parentCode" label="所属文章">
                <SearchTree
                    url="/api/app/meta/v1.0/data/tree"
                    param={{
                        nameAttr: "title",
                        metaCode: "cmsArticle",
                        showRoot: true,
                        params: [{
                            attrCode: 'channelCode',
                            operator: "=",
                            value: param.channelCode
                        }]
                    }}
                    valueCode="code" />
            </Form.Item>


            {meta.attrs.map((item) => {
                return item.isSystem == 2 && item.attrCode != 'parentCode' &&
                    (
                        <Form.Item
                            name={item.attrCode}
                            label={item.attrName}
                            key={item.attrCode}
                            rules={[{ required: item.isRequired == 1, message: '请输入' + item.attrName }]}
                        >
                            <MetaFormItem
                                pageForm={pageForm}
                                metaAttr={item}
                            />
                        </Form.Item>
                    )
            })}
        </Form>
    </Drawer>)
}

export default Page;