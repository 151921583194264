import React, { useState, useEffect } from 'react'
import { Button, Form, Input, message, Popconfirm, Space, Col, Row, Pagination } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { post } from 'util/http';
import module from 'util/module';
import { getMetaLabel } from 'util/meta';

import ApplicationDrawer from './application';

import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import DictSelect from "component/DictSelect";
import { EyeOutlined, EditOutlined, AimOutlined, RollbackOutlined } from '@ant-design/icons';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Page = () => {

    const navigate = useNavigate();
    const query = useQuery();


    const [template, setTemplate] = useState({});

    const [param, setParam] = useState({
        searchName: ""
    });
    const [applicationParam, setApplicationParam] = useState({
        visible: false
    });

    const [pageData, setPageData] = useState({
        size: 1,
        records: []
    });


    useEffect(() => {
        post("/api/cloud/public/template/get-by-code", {
            code: query.get("code")
        }).then(res => {
            setTemplate(res.data);
        })
    }, []);


    const [searchForm] = Form.useForm();

    return (
        <div >
            <Row style={{ padding: 10 }} >
                <Col flex="1" className='doc-content ql-editor'>
                    <div dangerouslySetInnerHTML={{
                        __html: template.content
                    }}
                    />
                </Col>
                <Col flex="300px" className='template-info'>
                    <img src={template.picture} style={{ width: 300 }} />
                    <p style={{ fontWeight: 'bold', paddingTop: 30 }}>{template.name}</p>
                    <p>说明：{template.remark}</p>
                    <p>作者：{template.creatorName}</p>
                    <p>发布时间：{template.creatorName}</p>
                    <p>当前版本：{template.creatorName}</p>
                    <div className='flex-row-center flex-justify-center pt-30'>
                        <Button type="primary" onClick={() => {
                            setApplicationParam({
                                templateCode: template.code,
                                visible: true
                            })
                        }}> 创建应用</Button>
                    </div>
                </Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
                <Button onClick={() => {
                    navigate(-1)
                }}>返回</Button>
            </div>

            <ApplicationDrawer param={applicationParam} setParam={setApplicationParam} reload={() => { setParam({ ...param }) }} />
        </div>
    );
}

addPage("/public/template/detail", Page);

export default Page;