import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
    Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem';
import DictSelect from 'component/DictSelect'

import Xpassword from 'util/xpassword';
import SearchTree from 'component/SearchTree';



const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "itmarkSecPassword"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });



    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post(module.app + '/my/meta/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                pageForm.resetFields();
            }
        }

    }, [param])

    return (<Drawer title="创建密码"
        placement="right"
        width={660}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button onClick={() => {
                    setParam({ ...param, visible: false })
                }}>取消</Button>
                <Button type="primary" onClick={() => {

                    pageForm.validateFields().then((data) => {
                        const url = module.app + '/my/meta/data/save';
                        post(url, {
                            metaCode: metaCode,
                            datas: data
                        }).then(res => {
                            setParam({ ...param, visible: false });
                            reload();
                            message.info("保存成功");
                        })
                    })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>

            <Form.Item name='name' label="名称" >
                <Input />
            </Form.Item>


            <Form.Item name='signMethod' label="加密算法" >
                <DictSelect dictCode={"PASSWORD_SIGN_METHOD"} />
            </Form.Item>

            <Form.Item name='accountCode' label="账号" >
                <Input />
            </Form.Item>

            <Form.Item name='salt' label="加密盐" >
                <Input />
            </Form.Item>

        </Form>
    </Drawer>)
}

export default Page;