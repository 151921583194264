import React, { useCallback, useEffect, useState } from 'react'
import { Table, Pagination, Spin } from 'antd';
import { post } from 'util/http';


const SearchTable = ({
    columns,
    url,
    param,
    keyCode = "id",
    simplePagination = false,
    ...nextProps
}) => {
    const [spinning, setSpinning] = useState(false);
    const [pageData, setPageData] = useState({
        size: 20
    });

    const loadData = useCallback((url, newParam) => {
        setSpinning(true);
        post(url, { ...param, current: pageData.current, ...newParam })
            .then(res => {
                setSpinning(false);
                setPageData({ ...res.data });
            }, err => {
                setSpinning(false);
            })
    }, [param])

    useEffect(() => {
        loadData(url, param);
    }, [param])


    return (
        <Spin tip="加载中..." spinning={spinning} size='large' delay={200}>
            <Table dataSource={pageData.records} columns={columns}
                pagination={{
                    simple: simplePagination,
                    pageSize: pageData.size,
                    total: pageData.total,
                    showTotal: (total) => `总共 ${total} 条数据`,
                    onChange: (current, size) => {
                        if (nextProps.onChange == null) {
                            loadData(url, { ...param, current: current, size: size });
                        }
                    }
                }}
                rowKey={record => record[keyCode]}
                {...nextProps}
            >
            </Table >

            {/* <Pagination
                style={{ position: 'absolute', bottom: 10, right: 0 }}
                pageSize={pageData.size}
                total={pageData.total}
                showTotal={(total) => `总共 ${total} 条数据1`}
                onChange={(current, size) => {
                    loadData(url, { ...param, current: current, size: size });
                }}
            /> */}
        </Spin>
    );
}

export default SearchTable;