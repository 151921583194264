import React, { useEffect, useRef } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Modal,
    Tabs,
    Space,
    Popconfirm
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem';
import FileUpload from 'component/FileUpload';
import DictSelect from 'component/DictSelect';
import regionData from 'util/region';
import ImageUpload from 'component/ImageUpload';
import TextArea from 'antd/lib/input/TextArea';
import ButtonGroup from 'antd/lib/button/button-group';
import SearchTable from 'component/SearchTable.js';
import { set } from 'lodash';
import moment from 'moment'
import * as echarts from 'echarts'


const Page = ({
    param,
    setParam,
    reload
}) => {

    useEffect(() => {
        if (param.visible) {

            var myChart = echarts.init(document.getElementById('echart-main'));
            var charOption = {
                tooltip: {},
                xAxis: {
                    data: []
                },
                yAxis: {},
                series: [
                    {
                        type: 'line',
                        smooth: true,
                        data: []
                    }
                ]
            }
            post('/api/app/meta/v1.0/data/search', {
                metaCode: 'pcdnNodeFlow'
            }).then(res => {
                const records = res.data.records;
                records.forEach(item => {
                    charOption.xAxis.data.push(moment(item.createTime).format("HH:mm:ss"))
                    charOption.series[0].data.push(item.flow)
                })
                myChart.setOption(charOption);
            })
        }
    }, [param])

    return (<Drawer title="流量趋势"
        placement="right"
        width={1024}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
    >

        <div id="echart-main" style={{ width: '100%', height: 300 }}></div>

    </Drawer >)
}

export default Page;