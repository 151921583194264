import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    DatePicker,
    Space
} from 'antd';
import { post } from 'util/http';
import MetaFormItem from 'component/MetaFormItem';
import SearchSelect from 'component/SearchSelect';

import moment from 'moment';

const { TextArea } = Input;
const { RangePicker, TimePicker } = DatePicker;


const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "xspaceAppointment"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });

    const [roomParam, setRoomParam] = useState({
        "metaCode": "xspaceRoom"
    });




    useEffect(() => {
        post('/api/app/public/meta/get', { code: metaCode })
            .then(res => {
                setMeta(res.data);
            })
    }, [])
    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post('/api/app/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                pageForm.resetFields();
            }
        }


    }, [param])

    return (<Drawer title="预约详情"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button onClick={() => {
                    setParam({ ...param, visible: false })
                }}>关闭</Button>
                <Button type="primary" onClick={() => {
                    pageForm.validateFields().then((data) => {
                        data.startTime = data.startTime.format('HH:mm');
                        data.endTime = data.endTime.format('HH:mm');
                        data.useDate = data.useDate.format('yyyy-MM-DD');
                        const url = '/api/xspace/appointment/create';
                        post(url, data).then(res => {
                            setParam({ ...param, visible: false });
                            reload();
                            message.info("创建成功");
                        })
                    })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="spaceCode"
                        label="空间"
                        key="spaceCode"
                        rules={[{ required: true, message: '请选择空间' }]}
                    >
                        <SearchSelect url="/api/app/meta/v1.0/data/search" param={{
                            "metaCode": "xspaceSpace"
                        }} onChange={(res, ext) => {
                            pageForm.setFieldsValue({
                                spaceName: ext.label
                            });
                            setRoomParam({
                                "metaCode": "xspaceRoom",
                                "params": [{
                                    attrCode: 'spaceCode',
                                    operator: "=",
                                    value: res
                                }]
                            });
                        }} />
                    </Form.Item>
                    <Form.Item
                        name="spaceName"
                        key="spaceName"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="roomCode"
                        label="房间"
                        key="roomCode"
                        rules={[{ required: true, message: '请选择空间' }]}
                    >
                        <SearchSelect url={"/api/app/meta/v1.0/data/search"} labelCode="name" valueCode="code" param={roomParam}
                            onChange={(res, ext) => {
                                pageForm.setFieldsValue({
                                    roomName: ext.label
                                });
                            }} />
                    </Form.Item>
                    <Form.Item

                        name="roomName"
                        key="roomName"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label="预约人"
                        name="creatorCode">
                        <SearchSelect url={"/api/ac/account/v1.0/search"} labelCode="name" valueCode="code" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="phone"
                        label="联系电话"
                        key="phone"
                        rules={[{ required: true, message: '请选择空间' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name="useDate"
                        label="使用日期"
                        key="useDate"
                        rules={[{ required: true, message: '请选择使用日期' }]}
                    >
                        <DatePicker format="yyyy-MM-DD" style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Item
                            name="startTime"
                            label="开始时间"
                            key="startTime"
                            rules={[{ required: true, message: '请选择开始时间' }]}
                        >
                            <TimePicker format="HH:mm" minuteStep={30} />
                        </Form.Item>
                        <div style={{ margin: '0px 10px 24px 10px' }}>至</div>
                        <Form.Item
                            name="endTime"
                            // label="结束时间"
                            key="endTime"
                            rules={[{ required: true, message: '请选择开始时间' }]}
                        >
                            <TimePicker format="HH:mm" minuteStep={30} />
                        </Form.Item>
                    </div>

                </Col>
                <Col span={8}>

                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="subject"
                        label="主题"
                        key="subject"
                        rules={[{ required: true, message: '请选择空间' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="remark"
                        label="备注"
                        key="remark"
                    >
                        <TextArea />
                    </Form.Item>
                </Col>

            </Row>


        </Form>
    </Drawer>)
}

export default Page;