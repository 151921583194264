import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Row, Col } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import TreeTable from 'component/TreeTable'
import SyncTree from 'component/SyncTree'
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import moment from 'moment'
import { getMetaLabel } from 'util/meta';
import ChatDrawerView from 'component/ChatDrawerView';


const { Search } = Input;
const Page = () => {

    const metaCode = "aiChat";
    const [param, setParam] = useState({
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "title"
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });
    const [searchForm] = Form.useForm();
    const [contentParam, setContentParam] = useState({
        metaCode: "aiChatContent",
        visible: false
    })

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.fullChannelCode != null) {
            newParam.push({
                attrCode: 'fullChannelCode',
                operator: "like",
                value: datas.fullChannelCode + "%"
            })
        }
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'title',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.status != null) {
            newParam.push({
                attrCode: 'status',
                operator: "=",
                value: datas.status
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }
    return (
        <div style={{ padding: 10 }}>

            <Form form={searchForm} layout="inline"  >

                <Form.Item
                    name="fullChannelCode"
                >
                    <SearchTree
                        url={module.app + "/meta/v1.0/data/tree"}
                        param={{ metaCode: 'ecCategory', showRoot: false }}
                        valueCode='fullCodes'
                        style={{ width: 200 }}
                        placeholder="所属栏目"
                    />
                </Form.Item>

                <Form.Item
                    name="status"
                >
                    <DictSelect
                        dictCode={"CMS_STATUS"}
                        style={{ width: 100 }}
                        placeholder="状态"
                    />
                </Form.Item>

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({
                            id: '',
                            visible: true
                        })
                    }}>创建</Button>

                </Space>

            </Form>
            <SearchTable url={module.app + "/meta/v1.0/data/search"}
                style={{ marginTop: 20 }}
                keyCode="code"
                param={param}
                columns={[
                    {
                        title: '会话',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: '创建人',
                        dataIndex: 'creatorCode',
                        key: 'creatorCode',
                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: 'status',
                        render: (_, record) => {
                            return getMetaLabel('CMS_STATUS', _);
                        }
                    }, {
                        title: '关键字',
                        dataIndex: 'tags',
                        key: 'tags',
                    }, {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '更新时间',
                        dataIndex: 'updateTime',
                        key: 'updateTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setContentParam({
                                        ...contentParam,
                                        chatCode: record.code,
                                        params: [{
                                            attrCode: 'chatCode',
                                            operator: "=",
                                            value: record.code
                                        }],
                                        visible: true
                                    })
                                }}>详情</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post('/api/ai/chat/delete', {
                                            chatCode: record.code,
                                        }).then(res => {
                                            setParam({ ...param });
                                            message.info("删除成功");
                                        })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />

            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />

            <ChatDrawerView setParam={setContentParam} param={contentParam} />
        </div >
    );
}
addPage("/ai/chat/manager", Page);

export default Page;