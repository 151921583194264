import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import AccountForm from './account-form.js'
import MetaDrawer from './meta.js';

const Account = () => {
    const [accountStatus, setAccountStatus] = useState({
        accountId: null,
        drawerId: null
    })

    const [metaParam, setMetaParam] = useState({
        visible: false
    });

    const [param, setParam] = useState({ searchName: "" });
    const [searchForm] = Form.useForm();

    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setAccountStatus({
                            accountId: null,
                            drawerId: 'accountForm'
                        })
                    }}>创建</Button>
                    <Button type="primary" onClick={() => {
                        setMetaParam({ visible: true })
                    }}>元属性配置</Button>
                </Space>


            </Form>

            <SearchTable url={module.ac + "/account/v1.0/search"}
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: 'code',
                        dataIndex: 'code',
                        key: 'code',
                    }, {
                        title: '部门',
                        dataIndex: 'fullOrgNames',
                        key: 'fullOrgNames',
                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: 'status',
                    }, {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setAccountStatus({
                                        accountId: record.id,
                                        drawerId: 'accountForm'
                                    })
                                }}>修改</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.ac + '/account/v1.0/delete', { id: record.id })
                                            .then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />
            <AccountForm accountId={accountStatus.accountId} drawerId={accountStatus.drawerId} setDrawerId={(val) => { setAccountStatus(val) }} setParam={setParam} />
            <MetaDrawer param={metaParam} setParam={setMetaParam} />
        </div >
    );
}
addPage("/ac/account/manager", Account);
export default Account;