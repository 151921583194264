import React, { useEffect, useState } from 'react'
import {
    Table, DatePicker, Button, Input, Space, Popconfirm, Drawer, Form, Col, Row, Select, message, Modal,
    Tree
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import "antd/dist/antd.css";
import { addPage } from 'component/DynamicRotes.js';
import SearchSelect from 'component/SearchSelect';
import SearchTree from 'component/SearchTree';
import DictSelect from 'component/DictSelect';
import PermissionDrawer from './permission';
import { getMetaLabel } from 'util/meta';
import Clipboard from 'util/clipboard.js';
const { TextArea } = Input;
const Menu = () => {

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();
    const [pasteForm] = Form.useForm();
    const [param, setParam] = useState({ searchName: "", showRoot: false });
    const [pageData, setPageData] = useState([]);
    const [permissionParam, setPermissionParam] = useState({ menuId: '' });
    const [formParam, setFormParam] = useState({
    });

    useEffect(() => {
        loadData(param, setPageData);
    }, [param])


    const formatData = (data) => {
        var newData = {
            ...data
        };
        if (newData.children.length == 0) {
            newData.children = null;
        } else {
            var children = data.children;
            newData.children = [];
            children.map(item => {
                newData.children.push(formatData(item));
            })
        }
        return newData;
    }
    const loadData = (param, setPageData) => {
        post(module.app + "/menu/v1.0/tree", { ...param, showRoot: false })
            .then(res => {
                var newData = [];
                res.data.map((item) => {
                    newData.push(formatData(item));
                });
                setPageData(newData)
            })
    }

    const copyMenu = (menu) => {
        post(module.app + '/menu/v1.0/get', {
            id: menu.id
        }).then(res => {
            Clipboard.copy(JSON.stringify(res.data));
            message.success("复制成功");
        })
    }

    const pasteMenu = () => {
        Modal.confirm({
            title: '黏贴菜单',
            width: 900,
            content: <Form form={pasteForm} layout="horizontal" labelCol={{
                style: { width: 80 }
            }}>
                <Form.Item
                    name="parentCode"
                    label="父节点"
                    rules={[{ required: true, message: '请选择父节点' }]}
                >
                    <SearchTree url={module.app + "/menu/v1.0/tree"} placeholder="请选择父节点" valueCode='code' />

                </Form.Item>
                <Form.Item name="metaJson" label="配置" rules={[{ required: true, message: '请填写配置' }]}>
                    <TextArea style={{ height: 200, width: "100%" }} />
                </Form.Item>
            </Form>,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                var metaJson = JSON.parse(pasteForm.getFieldValue("metaJson"));
                metaJson.parentCode = pasteForm.getFieldValue("parentCode");
                return post(module.app + '/menu/v1.0/create', metaJson).then(res => {
                    message.success("黏贴成功");
                    setParam({ ...param });
                })
            }
        });
    }
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({ ...formParam, visible: true });
                        pageForm.resetFields();
                    }}>创建</Button>
                    <Button type="primary" onClick={() => {
                        pasteMenu()
                    }}>黏贴</Button>
                </Space>


            </Form>

            <Table
                dataSource={pageData}
                rowKey={(record) => record.id}
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: '类型',
                        dataIndex: 'type',
                        key: 'type',
                        render: (value) => {
                            return value == 'menu' ? "菜单" : "权限"
                        }
                    }, {
                        title: 'url',
                        dataIndex: 'url',
                        key: 'url',
                    }, {
                        title: 'code',
                        dataIndex: 'code',
                        key: 'code',
                    }, {
                        title: '排序',
                        dataIndex: 'showOrder',
                        key: 'showOrder',
                    }, {
                        title: '权限',
                        dataIndex: 'authScope',
                        key: 'authScope',
                        render: (value) => {
                            return getMetaLabel("RESOURCE_AUTH_SCOPE", value);
                        }
                    }, {
                        title: '图标',
                        dataIndex: 'icon',
                        key: 'icon',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    copyMenu(record)
                                }}>复制</a>
                                <a onClick={() => {
                                    setFormParam({ ...formParam, visible: true });
                                    pageForm.setFieldsValue(record);
                                }}>修改</a>
                                <a onClick={() => {
                                    setPermissionParam({ visible: true, menuId: record.id })
                                }}>权限</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.app + '/menu/v1.0/delete', { id: record.id })
                                            .then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />


            <Modal title="元数据同步"
                visible={formParam.visible}
                width={800}
                onOk={() => {
                    pageForm.validateFields().then((data) => {
                        const url = data.id ? module.app + '/menu/v1.0/update' : module.app + '/menu/v1.0/create';
                        post(url, data).then(res => {
                            setParam({ ...param })
                            setFormParam({ ...formParam, visible: false });
                            message.info("保存成功");
                        })
                    })
                }} onCancel={() => {
                    setFormParam({ ...param, visible: false })
                }}>
                <Form form={pageForm} layout="horizontal" hideRequiredMark
                    labelCol={{
                        style: { width: 80 }
                    }}
                    initialValues={{
                        attrs: []
                    }}
                >
                    <Form.Item style={{ display: 'none' }}
                        name="id">
                        <Input type="hidden"
                        />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="parentCode"
                                label="父节点"
                                rules={[{ required: true, message: '请选择父节点' }]}
                            >
                                <SearchTree url={module.app + "/menu/v1.0/tree"} placeholder="请选择父节点" valueCode='code' />

                            </Form.Item>
                        </Col>
                        <Col span={12}>

                            <Form.Item
                                name="name"
                                label="名称"
                                rules={[{ required: true, message: '请输入名称' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入名称"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="type"
                                label="类型"
                                rules={[{ required: true, message: '请输入type' }]}
                            >
                                <DictSelect dictCode="MENU" placeholder="请选择类型" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="url"
                                label="url"

                                rules={[{ required: true, message: '请输入排序' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入url"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="showOrder"
                                label="排序"
                                rules={[{ required: true, message: '请输入排序' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    type="number"
                                    placeholder="请输入排序"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="authScope"
                                label="授权范围"
                                rules={[{ required: true, message: '请选择授权范围' }]}
                            >
                                <DictSelect dictCode="RESOURCE_AUTH_SCOPE" placeholder="请输入type" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="icon"
                                label="icon"
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入icon"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <PermissionDrawer param={permissionParam} setParam={setPermissionParam} />
        </div >
    );
}
addPage("/app/menu/manager", Menu);
export default Menu;