import React, { useEffect, useState } from 'react'
import { Table, DatePicker, Button, Input, Space, Popconfirm, Form, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import OrgForm from './org-form'
import { addPage } from 'component/DynamicRotes.js';


const Org = () => {
    const [pageStatus, setPageStatus] = useState({
        orgId: null,
        drawerId: null
    })
    const [param, setParam] = useState({ searchName: "", showRoot: false });
    const [searchForm] = Form.useForm();
    const [pageData, setPageData] = useState([]);


    const formatData = (data) => {
        var newData = {
            ...data
        };
        if (newData.children.length == 0) {
            newData.children = null;
        } else {
            var children = data.children;
            newData.children = [];
            children.map(item => {
                newData.children.push(formatData(item));
            })
        }
        return newData;
    }
    const loadData = (param, setPageData) => {
        post(module.ac + "/org/v1.0/tree", { ...param, showRoot: false })
            .then(res => {
                var newData = [];
                res.data.map((item) => {
                    newData.push(formatData(item));
                });
                setPageData(newData)
            })
    }

    useEffect(() => {
        loadData(param, setPageData);
    }, [param])


    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setPageStatus({
                            orgId: null,
                            drawerId: 'orgForm'
                        })
                    }}>创建</Button>
                </Space>


            </Form>


            <Table
                dataSource={pageData}
                rowKey={(record) => record.id}
                param={param}
                columns={[
                    {
                        title: '机构名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: '机构代码',
                        dataIndex: 'code',
                        key: 'code'
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setPageStatus({
                                        orgId: record.id,
                                        drawerId: 'orgForm'
                                    })
                                }}>修改</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.ac + '/org/v1.0/delete', { id: record.id })
                                            .then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />

            <OrgForm orgId={pageStatus.orgId} drawerId={pageStatus.drawerId} setDrawerId={(val) => { setPageStatus(val) }} setParam={setParam} />

        </div >
    );
}

addPage("/ac/org/manager", Org);

export default Org;