import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, Modal, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import { getContentHeight } from 'util/util';
import DictForm from './dict-form.js'
import SearchTable from 'component/SearchTable.js';
import Clipboard from 'util/clipboard.js';
import { addPage, setBreadcrumbs } from 'component/DynamicRotes.js';
const { TextArea } = Input;
const Dict = () => {
    const [pageStatus, setPageStatus] = useState({
        dictId: null,
        drawerId: null
    })
    const [param, setParam] = useState({ searchName: "", size: 10, current: 1 });
    const [pasteForm] = Form.useForm();

    const [searchForm] = Form.useForm();
    const [metaStr, setMetaStr] = useState("");

    const copyMeta = (meta) => {
        post(module.app + '/dict/v1.0/get-by-code', {
            code: meta.code
        }).then(res => {
            Clipboard.copy(JSON.stringify(res.data));
            message.success("复制成功");
        })
    }
    const pasteMeta = () => {
        Modal.confirm({
            title: '黏贴字典',
            content: <Form form={pasteForm}>
                <Form.Item name="metaJson">
                    <TextArea style={{ height: 200, width: "100%" }} />
                </Form.Item>
            </Form>,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                return post(module.app + '/dict/v1.0/save',
                    JSON.parse(pasteForm.getFieldValue("metaJson"))
                ).then(res => {
                    message.success("黏贴成功");
                })
            }
        });
    }
    return (
        <div>


            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setPageStatus({
                            dictId: null,
                            drawerId: 'dictForm'
                        })
                    }}>创建</Button>
                    <Button type="primary" onClick={() => {
                        pasteMeta()
                    }}>黏贴</Button>
                </Space>


            </Form>

            <SearchTable url={module.app + "/dict/v1.0/search"}
                scroll={{ y: getContentHeight() - 190 }}
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: 'code',
                        dataIndex: 'code',
                        key: 'code',
                    }, {
                        title: '排序',
                        dataIndex: 'showOrder',
                        key: 'showOrder',
                    }, {
                        title: '备注',
                        dataIndex: 'remark',
                        key: 'remark',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    copyMeta(record)
                                }}>复制</a>
                                <a onClick={() => {
                                    setPageStatus({
                                        dictId: record.id,
                                        drawerId: 'dictForm'
                                    })
                                }}>修改</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.app + '/dict/v1.0/delete', { id: record.id })
                                            .then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />

            <DictForm dictId={pageStatus.dictId} drawerId={pageStatus.drawerId} setDrawerId={(val) => { setPageStatus(val) }} setParam={setParam} />
        </div >
    );
}
addPage("/app/dict/manager", Dict);
export default Dict;