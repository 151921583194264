import React, { useEffect, useState } from 'react'
import { Table, Button, Input, Space, Popconfirm, Drawer, Form, Col, Row, Select, message, Breadcrumb } from 'antd';
import EditTable from 'component/EditTable';
import SearchSelect from 'component/SearchSelect';
import { post } from 'util/http';
import module from 'util/module';
const { Option } = Select;
const { TextArea } = Input;

const PageForm = ({
    param,
    setParam
}) => {


    const [pageForm] = Form.useForm();


    useEffect(() => {
        pageForm.resetFields();
    }, [])

    return (<Drawer title="创建应用"
        placement="right"
        width={660}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button onClick={() => {
                    setParam({ ...param, visible: false })
                }}>取消</Button>
                <Button type="primary" onClick={() => {
                    const data = pageForm.getFieldsValue();
                    data.templateCode = param.templateCode;
                    const url = module.cloud + '/my/application/create';
                    post(url, data)
                        .then(res => {
                            setParam({ ...param, visible: false });
                            message.info("创建成功");
                        })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Row gutter={16}>
                <Col span={24}>

                    <Form.Item
                        name="clusterCode"
                        label="所属集群"
                        rules={[{ required: true, message: '请选择集群' }]}
                    >
                        <SearchSelect url={module.cloud + "/my/auth/cluster/search"} labelCode="name" valueCode="code"
                        />
                    </Form.Item>

                </Col>
                <Col span={24}>
                    <Form.Item
                        name="appId"
                        label="appId"
                        rules={[{ required: true, message: '请输入appId' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入appId"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"

                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Drawer>)
}

export default PageForm;