import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Modal,
    Tabs,
    Space,
    Popconfirm
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem';
import FileUpload from 'component/FileUpload';
import DictSelect from 'component/DictSelect';
import regionData from 'util/region';
import ImageUpload from 'component/ImageUpload';
import TextArea from 'antd/lib/input/TextArea';
import ButtonGroup from 'antd/lib/button/button-group';
import SearchTable from 'component/SearchTable.js';
import { set } from 'lodash';
import moment from 'moment'


const Page = ({
    param,
    setParam,
    reload
}) => {


    const [aiRobot, setAiRobot] = useState({});


    const [questionParam, setQuestionParam] = useState({
        questionVisible: false,
        questionImportVisible: false,
        metaCode: "ailawyerRobotQuestion",
        params: [{
            attrCode: 'robotCode',
            operator: "=",
            value: "-1"
        }],
    });

    const [fileParam, setFileParam] = useState({
        metaCode: "aiRobotKnowledge",
        params: [{
            attrCode: 'robotCode',
            operator: "=",
            value: "-1"
        }, {
            attrCode: 'type',
            operator: "=",
            value: "document"
        }, {
            attrCode: 'category',
            operator: "=",
            value: "document"
        }],
    });

    const [urlParam, setUrlParam] = useState({
        metaCode: "aiRobotKnowledge",
        params: [{
            attrCode: 'robotCode',
            operator: "=",
            value: "-1"
        }, {
            attrCode: 'type',
            operator: "=",
            value: "url"
        }, {
            attrCode: 'category',
            operator: "=",
            value: "url"
        }],
    });

    const [lawParam, setLawParam] = useState({
        lawVisible: false,
        metaCode: "aiRobotKnowledge",
        params: [{
            attrCode: 'robotCode',
            operator: "=",
            value: "-1"
        }, {
            attrCode: 'category',
            operator: "=",
            value: "law"
        }],
    });


    const metaCode = "ailawyerRobot"

    const [baseForm] = Form.useForm();
    const [lawForm] = Form.useForm();
    const [fileForm] = Form.useForm();
    const [urlForm] = Form.useForm();
    const [questionForm] = Form.useForm();
    const [questionImportForm] = Form.useForm();


    const loadLaw = (code) => {
        setLawParam({
            ...lawParam,
            params: [{
                attrCode: 'robotCode',
                operator: "=",
                value: code
            }, {
                attrCode: 'category',
                operator: "=",
                value: "law"
            }]
        })
    }

    const loadQuestion = (robotCode) => {
        setQuestionParam({
            ...questionParam,
            params: [{
                attrCode: 'robotCode',
                operator: "=",
                value: robotCode
            }]
        })
    }

    const loadFile = (code) => {
        setFileParam({
            ...fileParam,
            params: [{
                attrCode: 'robotCode',
                operator: "=",
                value: code
            }, {
                attrCode: 'type',
                operator: "=",
                value: "document"
            }, {
                attrCode: 'category',
                operator: "=",
                value: "document"
            }]
        })
    }
    const loadUrl = (code) => {
        setUrlParam({
            ...urlParam,
            params: [{
                attrCode: 'robotCode',
                operator: "=",
                value: code
            }, {
                attrCode: 'type',
                operator: "=",
                value: "url"
            }, {
                attrCode: 'category',
                operator: "=",
                value: "url"
            }]
        })
    }


    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post('/api/app/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    baseForm.resetFields();
                    baseForm.setFieldsValue({
                        ...res.data
                    });
                    setAiRobot(res.data);
                    if (res.data.code != null && res.data.code != '') {
                        loadQuestion(res.data.code);
                        loadFile(res.data.code);
                        loadUrl(res.data.code)
                        loadLaw(res.data.code);
                    }
                })
            } else {
                baseForm.resetFields();
            }
        }


    }, [param])

    return (<Drawer title="Ai律师详情"
        placement="right"
        width={1024}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            param.id != '' && <Space>
                <Button type="primary" onClick={() => {
                    Modal.confirm({
                        title: '操作提示',
                        content: '确认发布机器人，发布后将更新线上机器人知识库',
                        okText: '确认',
                        cancelText: '取消',
                        onOk: function () {
                            post("/api/ailawyer/robot/deploy", {
                                code: aiRobot.code,
                            }).then(res => {
                                message.info("发布提交成功");
                                Modal.destroyAll();
                            })
                        }
                    });
                }}>发布</Button>
                {aiRobot.status == 'online' && <Button type="danger" onClick={() => {
                    post("/api/ailawyer/robot/offline", {
                        code: aiRobot.code,
                    }).then(res => {
                        message.info("下架成功");
                        setParam({ ...param, visible: false })
                        reload();
                    })
                }}>下架</Button>}
                {aiRobot.status == 'offline' && <Button type="primary" onClick={() => {
                    post("/api/ailawyer/robot/online", {
                        code: aiRobot.code,
                    }).then(res => {
                        message.info("上架成功");
                        setParam({ ...param, visible: false })
                        reload();
                    })
                }}>上架</Button>}
            </Space>
        }
    >
        {param.id == '' && <>
            <Form form={baseForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 120, fontSize: 14, fontWeight: 'bold' }
                }}
                initialValues={{
                    attrs: []
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="name" label="Ai机器人名称" rules={[{ required: true, message: '请输入名称' }]}>
                            <Input placeholder="请输入名称" />
                        </Form.Item>
                        <Form.Item name="type" label="机器人类型" >
                            <DictSelect
                                dictCode={"AiRobtType"}
                                placeholder="机器人类型" />
                        </Form.Item>
                        <Form.Item name="tags" label="标签" >
                            <Input placeholder="请输入标签" />
                        </Form.Item>
                        <Form.Item name="greeting" label="欢迎词" rules={[{ required: true, message: '请输入欢迎词' }]}>
                            <TextArea />
                        </Form.Item>
                        <Form.Item name="description" label="备注">
                            <TextArea />
                        </Form.Item>
                        <Form.Item name="defaultContext" label="提示词">
                            <TextArea />
                        </Form.Item>

                    </Col>
                </Row>
                <Form.Item style={{ display: 'none' }}
                    name="id">
                    <Input type="hidden" />
                </Form.Item>
                <Space size="middle" style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => {
                        baseForm.validateFields().then((data) => {
                            const url = '/api/ailawyer/robot/create';
                            post(url, data).then(res => {
                                setParam({ ...param, visible: false });
                                reload();
                                message.info("创建成功");
                            })
                        })
                    }} >保存</Button>
                </Space>
            </Form>
        </>}
        {param.id != '' && <Tabs
            defaultActiveKey="1"
            items={[
                {
                    label: `基础设置`,
                    key: '1',
                    children: <Form form={baseForm} layout="horizontal" hideRequiredMark
                        labelCol={{
                            style: { width: 120, fontSize: 14, fontWeight: 'bold' }
                        }}
                        initialValues={{
                            attrs: []
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item name="name" label="Ai机器人名称" rules={[{ required: true, message: '请输入名称' }]}>
                                    <Input placeholder="请输入名称" />
                                </Form.Item>
                                <Form.Item name="headimg" label="头像" rules={[{ required: true, message: '请选择头像' }]}>
                                    {/* <ImageUpload uploadUrl='/api/app/resource/v1.0/headimg/custom/upload' uploadData={{
                                        code: aiRobot.code,
                                    }} ></ImageUpload> */}
                                    <ImageUpload  ></ImageUpload>
                                </Form.Item>
                                <Form.Item name="type" label="机器人类型" >
                                    <DictSelect
                                        dictCode={"AiRobtType"}
                                        placeholder="机器人类型" />
                                </Form.Item>
                                <Form.Item name="tags" label="标签" >
                                    <Input placeholder="请输入标签" />
                                </Form.Item>
                                <Form.Item name="greeting" label="欢迎词" rules={[{ required: true, message: '请输入欢迎词' }]}>
                                    <TextArea />
                                </Form.Item>
                                <Form.Item name="description" label="备注">
                                    <TextArea />
                                </Form.Item>

                                <Form.Item name="defaultContext" label="提示词">
                                    <TextArea />
                                </Form.Item>

                                {/* <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 24 }}>
                                    <label style={{ fontWeight: 'bold', width: 110, margin: '5px 10px 0px 0px', textAlign: 'right' }}>咨询费收费规则:</label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        免费额 &nbsp;&nbsp;
                                        <Form.Item name="freeConsultNum" rules={[{ required: true, message: '请输入免费额' }]} style={{ marginBottom: 0 }}>
                                            <Input style={{ width: '60px' }} />
                                        </Form.Item>
                                        &nbsp;&nbsp;轮  &nbsp;&nbsp;&nbsp;&nbsp;
                                        咨询费&nbsp;&nbsp;
                                        <Form.Item name="consultPrice" label="" rules={[{ required: true, message: '请输入咨询费' }]} style={{ marginBottom: 0 }}>
                                            <Input style={{ width: '60px' }} />
                                        </Form.Item>
                                        &nbsp;&nbsp;元/月（期间不限制咨询次数）
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 24 }}>
                                    <label style={{ fontWeight: 'bold', width: 110, margin: '5px 10px 0px 0px', textAlign: 'right' }}>协议收费规则:</label>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        最多重做次数&nbsp;&nbsp;
                                        <Form.Item name="protocolNumber" rules={[{ required: true, message: '请输入次数' }]} style={{ marginBottom: 0 }} >
                                            <Input style={{ width: '60px' }} />
                                        </Form.Item>
                                        &nbsp;&nbsp;次  &nbsp;&nbsp;&nbsp;&nbsp;
                                        制作费用&nbsp;&nbsp;
                                        <Form.Item name="protocolPrice" label="" rules={[{ required: true, message: '请输入费用' }]} style={{ marginBottom: 0 }} >
                                            <Input style={{ width: '60px' }} />
                                        </Form.Item>
                                        &nbsp;&nbsp;元/协议
                                    </div>
                                </div> */}
                            </Col>
                        </Row>
                        <Form.Item style={{ display: 'none' }}
                            name="id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Space size="middle" style={{ float: 'right' }}>
                            <Button type="primary" onClick={() => {
                                baseForm.validateFields().then((data) => {
                                    const url = '/api/ailawyer/robot/update';
                                    post(url, data).then(res => {
                                        setParam({ ...param, visible: false });
                                        reload();
                                        message.info("保存成功");
                                    })
                                })
                            }} >保存</Button>
                        </Space>
                    </Form>,
                },
                {
                    label: `相关法规`,
                    key: '2',
                    children: <div>
                        <Space style={{ marginBottom: 10 }}>
                            <Button type="primary" onClick={() => {
                                setLawParam({ ...lawParam, lawVisible: true });
                                lawForm.resetFields();
                            }}>添加法律法规</Button>
                        </Space>
                        <Modal title="添加法律法规"
                            width={860}
                            onCancel={() => {
                                setLawParam({ ...lawParam, lawVisible: false })
                            }}
                            onOk={() => {
                                lawForm.validateFields().then((data) => {
                                    const url = module.app + '/meta/v1.0/data/save';
                                    data.robotCode = aiRobot.code;
                                    data.type = "text";
                                    data.category = "law";
                                    post(url, {
                                        metaCode: lawParam.metaCode,
                                        datas: data
                                    }).then(res => {
                                        setLawParam({ ...lawParam, lawVisible: false })
                                        message.info("保存成功");
                                    })
                                })
                            }}
                            visible={lawParam.lawVisible} >
                            <Form form={lawForm} layout="horizontal" hideRequiredMark
                                labelCol={{
                                    style: { width: 80 }
                                }}
                                initialValues={{
                                    attrs: []
                                }}
                            >
                                <Form.Item style={{ display: 'none' }}
                                    name="id">
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item name="title" label="法律法规"  >
                                    <Input />
                                </Form.Item>
                                <Form.Item name="content" label="内容"  >
                                    <TextArea style={{ height: 200 }} />
                                </Form.Item>
                            </Form>
                        </Modal>

                        <SearchTable url={"/api/app/meta/v1.0/data/search"}
                            param={lawParam}
                            columns={[
                                {
                                    title: '法律法规',
                                    dataIndex: 'title',
                                    key: 'question',
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    render: (_, record) => {
                                        return moment(_).format("yyyy-MM-DD HH:mm:ss")
                                    }
                                }, {
                                    title: '操作',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (_, record) => (
                                        <Space size="middle">
                                            <a onClick={() => {
                                                lawForm.setFieldsValue(record);
                                                setLawParam({ ...lawParam, lawVisible: true });
                                            }}>编辑</a>
                                            <Popconfirm
                                                title="你确认删除此数据么?"
                                                onConfirm={() => {
                                                    post(module.app + '/meta/v1.0/data/delete', {
                                                        metaCode: lawParam.metaCode,
                                                        id: record.id,
                                                    }).then(res => {
                                                        setLawParam({ ...lawParam })
                                                        message.info("删除成功");
                                                    })
                                                }}
                                                okText="确认"
                                                cancelText="取消" >
                                                <a href="#">删除</a>
                                            </Popconfirm>

                                        </Space>
                                    )
                                }
                            ]}
                        />
                    </div>,
                },
                {
                    label: `文件`,
                    key: '3',
                    children: <Form form={fileForm} layout="horizontal" hideRequiredMark
                        labelCol={{
                            style: { width: 120, fontSize: 14, fontWeight: 'bold' }
                        }}
                        initialValues={{
                            attrs: []
                        }}
                    >
                        <div style={{ display: 'flex' }}>
                            <Form.Item name="content" rules={[{ required: true, message: '请上传文件' }]}  >
                                <FileUpload onChange={(value, name) => {
                                    if (value != null && value != '') {
                                        console.log("已经上传文件");
                                        fileForm.validateFields().then((data) => {
                                            data.robotCode = aiRobot.code;
                                            data.type = "document";
                                            data.category = "document";
                                            data.title = name;
                                            const url = '/api/app/meta/v1.0/data/save';
                                            post(url, {
                                                metaCode: fileParam.metaCode,
                                                datas: data
                                            }).then(res => {
                                                setFileParam({ ...fileParam, page: 1 });
                                                fileForm.resetFields();
                                                message.info("上传成功");
                                            })
                                        })
                                    }
                                }} />
                            </Form.Item>&nbsp;&nbsp;
                        </div>
                        <SearchTable url={"/api/app/meta/v1.0/data/search"}
                            param={fileParam}
                            columns={[
                                {
                                    title: '文件列表',
                                    dataIndex: 'title',
                                    key: 'title',
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                }, {
                                    title: '操作',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (_, record) => (
                                        <Space size="middle">
                                            <Popconfirm
                                                title="你确认删除此数据么?"
                                                onConfirm={() => {
                                                    post(module.app + '/meta/v1.0/data/delete', {
                                                        metaCode: fileParam.metaCode,
                                                        id: record.id,
                                                    }).then(res => {
                                                        setParam({ ...param });
                                                        message.info("删除成功");
                                                    })
                                                }}
                                                okText="确认"
                                                cancelText="取消" >
                                                <a href="#">删除</a>
                                            </Popconfirm>

                                        </Space>
                                    )
                                }
                            ]}
                        />
                    </Form>,
                },
                {
                    label: `网页链接`,
                    key: '4',
                    children: <Form form={urlForm} layout="horizontal" hideRequiredMark
                        labelCol={{
                            style: { width: 120, fontSize: 14, fontWeight: 'bold' }
                        }}
                        initialValues={{
                            attrs: []
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={18}>
                                <Form.Item name="content" label="网址" rules={[{ required: true, message: '请填写网址' }]}  >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Button onClick={() => {
                                    urlForm.validateFields().then((data) => {
                                        data.robotCode = aiRobot.code;
                                        data.type = "url";
                                        data.category = "url";
                                        data.title = data.content;
                                        const url = '/api/app/meta/v1.0/data/save';
                                        post(url, {
                                            metaCode: urlParam.metaCode,
                                            datas: data
                                        }).then(res => {
                                            setUrlParam({ ...urlParam, page: 1 });
                                            urlForm.resetFields();
                                            message.info("保存成功");
                                        })
                                    })
                                }}>添加</Button>
                            </Col>
                        </Row>
                        <SearchTable url={"/api/app/meta/v1.0/data/search"}
                            param={urlParam}
                            columns={[
                                {
                                    title: '网页地址',
                                    dataIndex: 'content',
                                    key: 'content',
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                }, {
                                    title: '操作',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (_, record) => (
                                        <Space size="middle">
                                            <Popconfirm
                                                title="你确认删除此数据么?"
                                                onConfirm={() => {
                                                    post(module.app + '/meta/v1.0/data/delete', {
                                                        metaCode: fileParam.metaCode,
                                                        id: record.id,
                                                    }).then(res => {
                                                        setParam({ ...param });
                                                        message.info("删除成功");
                                                    })
                                                }}
                                                okText="确认"
                                                cancelText="取消" >
                                                <a href="#">删除</a>
                                            </Popconfirm>

                                        </Space>
                                    )
                                }
                            ]}
                        />
                    </Form>,
                },
                {
                    label: `问题解答库`,
                    key: '5',
                    children: <div>
                        <Space style={{ marginBottom: 10 }}>
                            <Button onClick={() => {
                            }}>搜索</Button>
                            <Button type="primary" onClick={() => {
                                setQuestionParam({ ...questionParam, questionVisible: true })
                                questionForm.resetFields();
                            }}>添加问题</Button>
                            <Button type="primary" onClick={() => {
                                setQuestionParam({ ...questionParam, questionImportVisible: true })
                                questionImportForm.resetFields();
                            }}>批量导入</Button>
                            <a href={window.PUBLIC_URL + "/static/ailawyer/question.xlsx"} target="_blank" >下载模板</a>

                        </Space>


                        <Modal title="问题导入"
                            width={860}
                            onCancel={() => {
                                setQuestionParam({ ...questionParam, questionImportVisible: false })
                            }}
                            onOk={() => {
                                questionImportForm.validateFields().then((data) => {
                                    const url = "/api/ailawyer/robot/question/import"
                                    post(url, {
                                        robotCode: aiRobot.code,
                                        fileId: data.questionFile
                                    }).then(res => {
                                        message.info("导入成功");
                                        setQuestionParam({ ...questionParam, questionImportVisible: false })
                                    })
                                })
                            }}
                            visible={questionParam.questionImportVisible} >
                            <Form form={questionImportForm} layout="horizontal" hideRequiredMark
                                labelCol={{
                                    style: { width: 80 }
                                }}
                                initialValues={{
                                    attrs: []
                                }}
                            >
                                <Form.Item name="questionFile" label=""  >
                                    <FileUpload />
                                </Form.Item>
                            </Form>
                        </Modal>

                        <Modal title="添加问题"
                            width={860}
                            onCancel={() => {
                                setQuestionParam({ ...questionParam, questionVisible: false })
                            }}
                            onOk={() => {
                                questionForm.validateFields().then((data) => {
                                    const url = module.app + '/meta/v1.0/data/save';
                                    data.robotCode = aiRobot.code;
                                    post(url, {
                                        metaCode: questionParam.metaCode,
                                        datas: data
                                    }).then(res => {
                                        setQuestionParam({ ...questionParam, questionVisible: false })
                                        message.info("保存成功");
                                    })
                                })
                            }}
                            visible={questionParam.questionVisible} >
                            <Form form={questionForm} layout="horizontal" hideRequiredMark
                                labelCol={{
                                    style: { width: 80 }
                                }}
                                initialValues={{
                                    attrs: []
                                }}
                            >
                                <Form.Item style={{ display: 'none' }}
                                    name="id">
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item name="question" label="问题"  >
                                    <TextArea style={{ height: 60 }} />
                                </Form.Item>
                                <Form.Item name="answer" label="答案"  >
                                    <TextArea style={{ height: 200 }} />
                                </Form.Item>
                                <Button style={{ marginLeft: '100px' }}>Ai解答</Button>
                            </Form>
                        </Modal>

                        <SearchTable url={"/api/app/meta/v1.0/data/search"}
                            param={questionParam}
                            columns={[
                                {
                                    title: '问题',
                                    dataIndex: 'question',
                                    key: 'question',
                                },
                                {
                                    title: '创建时间',
                                    dataIndex: 'createTime',
                                    key: 'createTime',
                                    render: (_, record) => {
                                        return moment(_).format("yyyy-MM-DD HH:mm:ss")
                                    }
                                }, {
                                    title: '操作',
                                    dataIndex: 'name',
                                    key: 'name',
                                    render: (_, record) => (
                                        <Space size="middle">
                                            <a onClick={() => {
                                                questionForm.setFieldsValue(record);
                                                setQuestionParam({ ...questionParam, questionVisible: true });
                                            }}>编辑</a>
                                            <Popconfirm
                                                title="你确认删除此数据么?"
                                                onConfirm={() => {
                                                    post(module.app + '/meta/v1.0/data/delete', {
                                                        metaCode: questionParam.metaCode,
                                                        id: record.id,
                                                    }).then(res => {
                                                        setQuestionParam({ ...questionParam })
                                                        message.info("删除成功");
                                                    })
                                                }}
                                                okText="确认"
                                                cancelText="取消" >
                                                <a href="#">删除</a>
                                            </Popconfirm>

                                        </Space>
                                    )
                                }
                            ]}
                        />
                    </div>,
                },
                {
                    label: `协议模板`,
                    key: '6',
                    children: `Content of Tab Pane 6`,
                }
            ]}
        />}



    </Drawer >)
}

export default Page;