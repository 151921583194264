import React, { useEffect, useState } from 'react'
import {
    Modal,
    Form,
    Input,
    message,
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem';


const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "nginxCluster"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });


    useEffect(() => {
        post(module.app + '/meta/v1.0/get-by-code', { code: metaCode })
            .then(res => {
                setMeta(res.data);
            })
    }, [])
    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post(module.app + '/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                pageForm.resetFields();
            }
        }


    }, [param])

    return (<Modal title="云厂商"
        placement="right"
        width={860}
        maskClosable={false}
        onCancel={() => {
            setParam({ ...param, visible: false })
        }}
        onOk={() => {
            pageForm.validateFields().then((data) => {
                data.typeName = typeName;
                const url = module.app + '/meta/v1.0/data/save';
                post(url, {
                    metaCode: metaCode,
                    datas: data
                }).then(res => {
                    setParam({ ...param, visible: false });
                    reload();
                    message.info("保存成功");
                })
            })
        }}
        visible={param.visible}
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 120 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>

            {meta.attrs.map((item) => {
                return item.isSystem == 2 &&
                    (
                        <Form.Item
                            name={item.attrCode}
                            label={item.attrName}
                            key={item.attrCode}
                            rules={[{ required: item.isRequired == 1, message: '请输入' + item.attrName }]}
                        >
                            <MetaFormItem
                                pageForm={pageForm}
                                metaAttr={item}
                            />
                        </Form.Item>
                    )
            })}
        </Form>
    </Modal>)
}

export default Page;