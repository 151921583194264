import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Switch, Modal } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import TreeTable from 'component/TreeTable'
import DictSelect from 'component/DictSelect'
import { QRCodeCanvas } from 'qrcode.react'

import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';


import { getMetaLabel } from 'util/meta';
const Page = () => {

    const createOrder = (produtCode) => {

    }

    return (
        <div>
            <Button type="primary" onClick={() => {
                createOrder("9b7490d8aafd4150bc384d3a1593b317")
            }}>购买开通</Button>

        </div>

    );
}
addPage("/my/crm/vip/buy", Page);

export default Page;