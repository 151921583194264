import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import DictSelect from "../../../component/DictSelect";

const { Option } = Select;
const { TextArea } = Input;

const Page = ({
  param,
  setParam,
  reload
}) => {

  const [pageForm] = Form.useForm();

  useEffect(() => {
    if (param.visible) {
      if (param.moduleId != '') {
        post('/api/cloud/my/module/get', { id: param.moduleId })
          .then(res => {
            pageForm.setFieldsValue({
              ...res.data
            });
          })
      } else {
        pageForm.resetFields();
      }
    }
  }, [param])

  return (<Drawer title="模块管理"
    placement="right"
    width={960}
    maskClosable={false}
    onClose={() => {
      setParam({ ...param, visible: false })
    }}
    visible={param.visible}
    extra={
      <Space>
        <Button onClick={() => {
          setParam({ ...param, visible: false })
        }}>取消</Button>
        <Button type="primary" onClick={() => {
          const param = pageForm.getFieldsValue();
          const url = param.id ? '/api/cloud/my/module/update' : '/api/cloud/my/module/create';
          post(url, param)
            .then(res => {
              reload();
              setParam({ ...param, visible: false })
              message.info("保存成功");
            })
        }}>
          确认
        </Button>
      </Space>
    }
  >
    <Form form={pageForm} layout="horizontal" hideRequiredMark
      labelCol={{
        style: { width: 80 }
      }}
      initialValues={{
        attrs: []
      }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item style={{ display: 'none' }}
            name="id">
            <Input type="hidden"
            />
          </Form.Item>

          <Form.Item
            name="type"
            label="类型"
            rules={[{ required: true, message: '请选择类型' }]}
          >
            <DictSelect dictCode="MODULE_TYPE" placeholder="请选择类型" />
          </Form.Item>

        </Col>
        <Col span={24}>
          <Form.Item
            name="name"
            label="名称"
            rules={[{ required: true, message: '请输入名称' }]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入名称"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="code"
            label="编码"
            rules={[{ required: true, message: '请输入编码' }]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入编码"
              disabled={param.moduleId != ''}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="scope"
            label="范围"
          >
            <DictSelect dictCode="MODULE_SCOPE" />
          </Form.Item>
        </Col>
        {param.moduleId == '' && <><Col span={24}>
          <Form.Item
            name="version"
            label="版本"
            rules={[{ required: true, message: '请输入版本' }]}
          >
            <Input
              style={{ width: '100%' }}
              placeholder="请输入版本"
            />
          </Form.Item>
        </Col>
          <Col span={24}>
            <Form.Item
              name="image"
              label="镜像地址"
              rules={[{ required: true, message: '请输入镜像地址' }]}
            >
              <Input
                style={{ width: '100%' }}
                placeholder="请输入镜像地址"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="config"
              label="配置"
            >
              <TextArea />
            </Form.Item>
          </Col>
        </>}
        <Col span={24}>
          <Form.Item
            name="remark"
            label="备注"
          >
            <TextArea />
          </Form.Item>
        </Col>


      </Row>
    </Form>
  </Drawer>)
}

export default Page;