import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Switch } from 'antd';
import { post } from 'util/http';
import module from 'util/module';

import { useNavigate } from 'react-router-dom';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import DictSelect from 'component/DictSelect'
import DetailDrawer from './detail.js';
import FormDrawer from './form.js';
import moment from 'moment'



import { getMetaLabel } from 'util/meta';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "xspaceAppointment";
    const [param, setParam] = useState({
        metaCode: metaCode,
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }]
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });
    const [detailParam, setDetailParam] = useState({ metaCode: metaCode });

    const [searchForm] = Form.useForm();

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.fullTypeCode != null) {
            newParam.push({
                attrCode: 'fullTypeCode',
                operator: "like",
                value: datas.fullTypeCode + "%"
            })
        }
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'title',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.status != null) {
            newParam.push({
                attrCode: 'status',
                operator: "=",
                value: datas.status
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }

    const toppingProject = (id, showOrder) => {
        post('/api/recruit/job/v1.0/topping', {
            id: id,
            showOrder: showOrder
        }, {
            loading: true
        }).then(res => {
            setParam({ ...param });
        })
    }
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >


                <Form.Item
                    name="status"
                >
                    <DictSelect
                        dictCode={"APPOINTMENT_STATUS"}
                        style={{ width: 100 }}
                        placeholder="状态"
                    />
                </Form.Item>

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({
                            id: '',
                            visible: true
                        })
                    }}>创建预约</Button>
                </Space>


            </Form>

            <SearchTable url={"/api/app/public/meta/data/search"}
                param={param}
                columns={[
                    {
                        title: '预约编号',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: '预约空间',
                        dataIndex: 'spaceName',
                        key: 'spaceName',
                    },
                    {
                        title: '预约房间',
                        dataIndex: 'roomName',
                        key: 'roomName',
                    },
                    {
                        title: '预约日期',
                        dataIndex: 'useDate',
                        key: 'useDate',
                    },
                    {
                        title: '预约时段',
                        dataIndex: 'startTime',
                        key: 'startTime',
                        render: (_, record) => {
                            return _ + "-" + record.endTime;
                        }
                    },
                    {
                        title: '主题',
                        dataIndex: 'subject',
                        key: 'subject',
                    }, {
                        title: '联系电话',
                        dataIndex: 'phone',
                        key: 'phone',
                    }, {
                        title: '总费用',
                        dataIndex: 'totalPrice',
                        key: 'totalPrice',
                    }, {
                        title: '状态',
                        dataIndex: 'status',
                        key: 'status',
                        render: (_, record) => {
                            return getMetaLabel('APPOINTMENT_STATUS', _);
                        }
                    }, {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setDetailParam({
                                        id: record.id,
                                        visible: true
                                    })
                                }}>详情</a>

                            </Space>
                        )
                    }
                ]} />
            <DetailDrawer param={detailParam} setParam={setDetailParam} reload={() => { setParam({ ...param }) }} />
            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/xspace/appointment/manager", Page);
export default Page;