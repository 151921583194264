import React, { useEffect, useMemo, useState } from 'react'
import { Button, Input, Space, Drawer, Form, Col, Row, message } from 'antd';
import SearchTree from 'component/SearchTree';
import DynamicFormItem from 'component/DynamicFormItem';
import { post } from 'util/http';
import module from 'util/module';
import moment from 'moment';

const AccountForm = ({
    accountId,
    drawerId,
    setParam,
    setDrawerId
}) => {

    const metaTypeMap = useMemo(() => {
        return {};
    }, []);

    const [metaList, updateMetaList] = useState([]);

    useEffect(() => {
        post(module.ac + '/account/v1.0/meta/search', {})
            .then(res => {
                updateMetaList(res.data.records);
                res.data.records.map((item) => {
                    metaTypeMap[item.code] = item.type;
                });
            })
    }, [])

    const [accountForm] = Form.useForm();

    useEffect(() => {
        if ('accountForm' == drawerId) {
            accountForm.resetFields();
        }
        if (accountId != null && drawerId == 'accountForm') {
            post(module.ac + '/account/v1.0/get', { id: accountId })
                .then(res => {
                    const newData = { ...res.data };
                    for (var i in res.data.attributes) {
                        if (metaTypeMap[i] != 'date') {
                            newData["attributes." + i] = res.data.attributes[i];
                        } else {
                            newData["attributes." + i] = moment(res.data.attributes[i]);
                        }
                    }
                    accountForm.setFieldsValue(newData);
                })
        }
    }, [accountId, drawerId])

    return (<Drawer title="账号管理"
        placement="right"
        width={660}
        getContainer={false}
        maskClosable={false}
        onClose={() => { setDrawerId({}) }}
        visible={drawerId == 'accountForm'}
        extra={
            <Space>
                <Button onClick={() => { setDrawerId({}) }}>取消</Button>
                <Button type="primary" onClick={() => {
                    const param = accountForm.getFieldsValue();
                    let newParam = { ...param, attributes: {} };
                    for (let key in param) {
                        if (key.startsWith("attributes.")) {
                            newParam.attributes[key.substring(11, key.length)] = param[key];
                        }
                    }
                    const url = param.id ? module.ac + '/account/v1.0/update' : module.ac + '/account/v1.0/create';
                    post(url, newParam)
                        .then(res => {
                            setParam({});
                            setDrawerId({})
                            message.info("保存成功");
                        })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={accountForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item style={{ display: 'none' }}
                        name="id">
                        <Input type="hidden"
                        />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="姓名"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"
                        />
                    </Form.Item>

                </Col>
                <Col span={24}>
                    <Form.Item
                        name="code"
                        label="账号"
                        rules={[{ required: true, message: '请输入code' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入code"
                        />
                    </Form.Item>
                </Col>
                {accountId == null && <Col span={24}>
                    <Form.Item
                        name="password"
                        label="密码"
                    >
                        <Input
                            type="password"
                            style={{ width: '100%' }}
                            placeholder="请输入密码"
                        />
                    </Form.Item>
                </Col>
                }
                <Col span={12}>
                    <Form.Item
                        name="orgCode"
                        label="组织机构"
                    >
                        <SearchTree url={module.ac + "/org/v1.0/tree"} placeholder="请选择组织机构" param={{ showRoot: false }} valueCode="code" />
                    </Form.Item>
                </Col>
                {
                    metaList.map((item) => {
                        return (
                            <Col span={24} key={item.id}>
                                <Form.Item
                                    name={"attributes." + item.code}
                                    label={item.name}
                                >
                                    <DynamicFormItem editType={item.type}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                        )
                    })
                }
            </Row>
        </Form>
    </Drawer>)
}

export default AccountForm;