import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import { post } from 'util/http';
import module from 'util/module';
const { TextArea } = Input;


const Page = ({
    param,
    setParam
}) => {

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();

    return (<Drawer title="配置"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
    >

        <Modal width={960} title="添加配置" visible={param.modalVisible} onOk={() => {
            const data = pageForm.getFieldsValue();
            data.applicationCode = param.applicationCode;
            const url = data.id ? module.cloud + '/application/v1.0/config/update'
                : module.cloud + '/application/v1.0/config/create';;
            post(url, data)
                .then(res => {
                    message.info("创建成功");
                    setParam({ ...param, modalVisible: false })
                })

        }} onCancel={() => { setParam({ ...param, modalVisible: false }) }}>

            <Form form={pageForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 80 }
                }}
                initialValues={{
                    items: []
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item style={{ display: 'none' }}
                            name="id">
                            <Input type="hidden"
                            />
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }}
                            name="applicationCode">
                            <Input type="hidden"
                            />
                        </Form.Item>

                        <Form.Item
                            name="code"
                            label="配置项"
                            rules={[{ required: true, message: '请输入名称' }]}
                        >
                            <DictSelect dictCode="APP_CONFIG_CODE" placeholder="请输入type" />
                        </Form.Item>

                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="content"
                            label="配置内容"
                        >
                            <TextArea
                                style={{ width: '100%', height: 500 }}
                                type="number"
                            />
                        </Form.Item>
                    </Col>

                </Row>

            </Form>


        </Modal>

        <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
            <Form.Item
                name="searchName"
            >
                <Input
                    style={{ width: '100%' }}
                    placeholder="请输入名称"
                />
            </Form.Item>
            <Space >
                <Button onClick={() => {
                    setParam({ ...param, ...searchForm.getFieldsValue() })
                }}>搜索</Button>
                <Button type="primary" onClick={() => {
                    pageForm.resetFields();
                    setParam({ ...param, modalVisible: true })
                }}>添加配置</Button>
            </Space>
        </Form>

        <SearchTable url={module.cloud + "/application/v1.0/config/search"}
            param={param}
            columns={[
                {
                    title: '配置项',
                    dataIndex: 'code',
                    key: 'code',
                },
                {
                    title: '操作',
                    dataIndex: 'name',
                    key: 'name',
                    render: (_, record) => (
                        <Space size="middle">
                            <a onClick={() => {
                                setParam({ ...param, modalVisible: true })
                                pageForm.setFieldsValue(record);
                            }}>修改</a>

                            <Popconfirm
                                title="你确认删除此数据么?"
                                onConfirm={() => {
                                    post(module.cloud + '/application/v1.0/config/delete', { id: record.id })
                                        .then(res => {
                                            setParam({ ...param });
                                            message.info("删除成功");
                                        })
                                }}
                                okText="确认"
                                cancelText="取消" >
                                <a href="#">删除</a>
                            </Popconfirm>
                        </Space>
                    )
                }
            ]} />
    </Drawer>)
}

export default Page;