import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Tabs,
    Select,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import SearchTree from 'component/SearchTree';
import DictSelect from 'component/DictSelect';
import { getMetaLabel } from 'util/meta';
import { post } from 'util/http';
import module from 'util/module';
const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;


const Page = ({
    param,
    setParam,
}) => {

    const [formParam, setFormParm] = useState({
        visible: false,
        metaId: ""
    });
    const [permissionTargetType, setPermissionTargetType] = useState('account');

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();


    return (<Drawer title="字段管理"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false });
        }}
        visible={param.visible}
    >

        <Space>

            <Button size='middle' type="primary" onClick={() => {
                setFormParm({ ...formParam, visible: true })
                pageForm.resetFields()
            }}>新增</Button>
        </Space>

        <SearchTable url={"/api/hcs/server/v1.0/permission/search"}
            param={param}
            columns={[
                {
                    title: '权限类型',
                    dataIndex: 'role',
                    key: 'role',
                    render: (_, record) => {
                        return getMetaLabel('SERVER_PERMISSION_ROLE', _);
                    }
                },
                {
                    title: '授权类型',
                    dataIndex: 'targetType',
                    key: 'targetType',
                    render: (_, record) => {
                        return getMetaLabel('PERMISSION_TARGET_TYPE', _);
                    }
                },
                {
                    title: '授权对象',
                    dataIndex: 'targetName',
                    key: 'targetName',
                },
                {
                    title: '操作',
                    dataIndex: 'name',
                    key: 'name',
                    render: (_, record) => (
                        <Space size="middle">
                            <Popconfirm
                                title="你确认删除此数据么?"
                                onConfirm={() => {
                                    post('/api/hcs/server/v1.0/permission/delete', {
                                        code: param.code,
                                        role: record.role,
                                        targetType: record.targetType,
                                        targetCode: record.targetCode
                                    }).then(res => {
                                        setParam({ ...param });
                                        message.info("删除成功");
                                    })
                                }}
                                okText="确认"
                                cancelText="取消">
                                <a href="#">删除</a>
                            </Popconfirm>

                        </Space>
                    )
                }
            ]} />

        <Modal title="新增权限"
            visible={formParam.visible}
            onOk={() => {
                pageForm.validateFields().then((formParam) => {
                    formParam.code = param.code;
                    formParam.targetCode = formParam.target.value;
                    formParam.targetName = formParam.target.label;
                    const url = '/api/hcs/server/v1.0/permission/create';
                    post(url, formParam).then(res => {
                        message.info("创建成功");
                        setParam({ ...param });
                        setFormParm({ ...formParam, visible: false })
                    })
                })
            }} onCancel={() => {
                setFormParm({ ...formParam, visible: false })
            }}>

            <Form form={pageForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 80 }
                }}
                initialValues={{
                    attrs: []
                }}
            >
                <Form.Item
                    label="权限类型"
                    name="role">
                    <DictSelect dictCode={"SERVER_PERMISSION_ROLE"} ></DictSelect>
                </Form.Item>
                <Form.Item
                    label="授权类型"
                    name="targetType">
                    <DictSelect dictCode={"PERMISSION_TARGET_TYPE"} onChange={(v) => {
                        pageForm.setFieldsValue({ target: null })
                        setPermissionTargetType(v);
                    }}></DictSelect>
                </Form.Item>
                {
                    permissionTargetType == 'account' &&
                    <Form.Item
                        label="授权对象"
                        name="target">
                        <SearchSelect url={module.ac + "/account/v1.0/search"} labelCode="name" valueCode="code" labelInValue={true} />
                    </Form.Item>
                }
                {
                    permissionTargetType == 'role' &&
                    <Form.Item
                        label="授权对象"
                        name="target">
                        <SearchSelect url={module.ac + "/role/v1.0/search"} labelCode="name" valueCode="code" labelInValue={true} />
                    </Form.Item>
                }
                {
                    permissionTargetType == 'group' &&
                    <Form.Item
                        label="授权对象"
                        name="target">
                        <SearchSelect url={module.ac + "/group/v1.0/search"} labelCode="name" valueCode="code" labelInValue={true} />
                    </Form.Item>
                }
                {
                    permissionTargetType == 'org' &&
                    <Form.Item
                        label="授权对象"
                        name="target">
                        <SearchTree url={module.ac + "/org/v1.0/tree"} param={{ showRoot: false }} valueCode="code" labelInValue={true} />
                    </Form.Item>
                }
            </Form>
        </Modal>


    </Drawer>)
}

export default Page;