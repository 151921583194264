import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, Select, message } from 'antd';
import { post } from 'util/http';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import GroupForm from './group-form.js'
import module from 'util/module';

const Group = () => {
    const [groupStatus, setGroupStatus] = useState({
        groupId: null,
        drawerId: null
    })
    const [param, setParam] = useState({ searchName: "", size: 10, current: 1 });
    const [searchForm] = Form.useForm();

    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setGroupStatus({
                            groupId: null,
                            drawerId: 'groupForm'
                        })
                    }}>创建</Button>
                </Space>


            </Form>

            <SearchTable url={module.ac + "/group/v1.0/search"}
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: '备注',
                        dataIndex: 'remark',
                        key: 'remark',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setGroupStatus({
                                        groupId: record.id,
                                        drawerId: 'groupForm'
                                    })
                                }}>修改</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.ac + '/group/v1.0/delete', { id: record.id })
                                            .then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />
            <GroupForm groupId={groupStatus.groupId} drawerId={groupStatus.drawerId} setDrawerId={(val) => { setGroupStatus(val) }} setParam={setParam} />


        </div >
    );
}
addPage("/ac/group/manager", Group);
export default Group;