import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import TreeTable from 'component/TreeTable'
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';


import { getMetaLabel } from 'util/meta';
import SearchSelect from 'component/SearchSelect.js';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "tripTrack";
    const [param, setParam] = useState({
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "title"
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });

    const [regionParam, setRegionParam] = useState({
        metaCode: "cmdbRegion"
    })
    const [searchForm] = Form.useForm();

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.fullTypeCode != null) {
            newParam.push({
                attrCode: 'fullTypeCode',
                operator: "like",
                value: datas.fullTypeCode + "%"
            })
        }
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'title',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.vendorCode != null) {
            newParam.push({
                attrCode: 'vendorCode',
                operator: "=",
                value: datas.vendorCode
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({
                            id: '',
                            visible: true
                        })
                    }}>创建</Button>
                    <Button type="primary" onClick={() => {

                    }}>批量导入</Button>

                </Space>


            </Form>

            <SearchTable url={"/api/app/public/meta/data/search"}
                param={param}
                columns={[
                    {
                        title: '电动车编号',
                        dataIndex: 'bikeCode',
                        key: 'bikeCode',
                    },
                    // {
                    //     title: '行程编号',
                    //     dataIndex: 'code',
                    //     key: 'code'
                    // },
                    {
                        title: '行程日期',
                        dataIndex: 'trackDate',
                        key: 'trackDate',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD")
                        }
                    },
                    {
                        title: '开始时间',
                        dataIndex: 'startTime',
                        key: 'startTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    },
                    {
                        title: '结束时间',
                        dataIndex: 'endTime',
                        key: 'endTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '使用电量',
                        dataIndex: 'startSoc',
                        key: 'startSoc'
                    }, {
                        title: '里程',
                        dataIndex: 'mileage',
                        key: 'mileage'
                    }, {
                        title: '平均时速',
                        dataIndex: 'avgSpeed',
                        key: 'avgSpeed'
                    }, {
                        title: '最高时速',
                        dataIndex: 'highSpeed',
                        key: 'highSpeed'
                    }, {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '更新时间',
                        dataIndex: 'updateTime',
                        key: 'updateTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    setFormParam({
                                        id: record.id,
                                        visible: true
                                    })
                                }}>详情</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.app + '/meta/v1.0/data/delete', {
                                            metaCode: metaCode,
                                            id: record.id,
                                        }).then(res => {
                                            setParam({ ...param });
                                            message.info("删除成功");
                                        })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />
            < FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />

        </div >
    );
}
addPage("/trip/track/manager", Page);
export default Page;