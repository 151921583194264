import React from 'react'
import { addRoute } from 'component/DynamicRotes.js';
import { Result } from 'antd-mobile'
import { useLocation } from 'react-router-dom';


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Page = () => {

    const query = useQuery();
    const orderCode = query.get("orderCode");

    return (
        <div>
            <Result
                status='success'
                title='支付成功'
                description='支付成功，您可以关闭此页面了'
            />
        </div >
    );
}
addRoute("/ec/payment/success", Page);

export default Page;