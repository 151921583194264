import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Switch, Empty } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import TreeTable from 'component/TreeTable'
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import DeployModal from './deploy';

import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';


import { getMetaLabel } from 'util/meta';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "nginxSsl";
    const [param, setParam] = useState({
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "title"
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });

    const [deployParam, setDeployParam] = useState({ metaCode: metaCode });

    const [searchForm] = Form.useForm();

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.fullTypeCode != null) {
            newParam.push({
                attrCode: 'fullTypeCode',
                operator: "like",
                value: datas.fullTypeCode + "%"
            })
        }
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'title',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.status != null) {
            newParam.push({
                attrCode: 'status',
                operator: "=",
                value: datas.status
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }

    const stopIde = (record) => {
        const url = '/api/devops/ide/v1.0/stop';
        post(url, {
            code: record.code
        }).then(res => {
            setParam({ ...param, visible: false });
            message.info("操作成功");
        })
    }

    const startIde = (record) => {
        const url = '/api/devops/ide/v1.0/start';
        post(url, {
            code: record.code
        }).then(res => {
            setParam({ ...param, visible: false });
            message.info("操作成功");
        })
    }

    return (
        <div>
            {window.LOGIN_INFO.authCodes.indexOf("menu:view:/my/ssl") == -1 && < Empty
                style={{ paddingTop: 100 }}
                description="自定义域名证书需要开通黄金以上会员"
            >
                <Button type="primary">购买开通</Button>
            </Empty>
            }

            {
                window.LOGIN_INFO.authCodes.indexOf("menu:view:/my/ssl") > -1 &&
                <>
                    <Form form={searchForm} layout="inline" style={{ padding: 15 }} >

                        <Form.Item
                            name="status"
                        >
                            <DictSelect
                                dictCode={"DEVOPS_IDE_STATUS"}
                                style={{ width: 100 }}
                                placeholder="状态"
                            />
                        </Form.Item>

                        <Form.Item
                            name="searchName"
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="请输入名称"
                            />
                        </Form.Item>
                        <Space >
                            <Button onClick={() => {
                                reload();
                            }}>搜索</Button>
                            <Button type="primary" onClick={() => {
                                setFormParam({
                                    id: '',
                                    visible: true
                                })
                            }}>创建</Button>

                        </Space>


                    </Form>

                    <SearchTable url={"/api/app/my/meta/data/search"}
                        param={param}
                        columns={[
                            {
                                title: '名称',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: '域名',
                                dataIndex: 'domain',
                                key: 'domain'
                            },
                            {
                                title: '创建时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                                render: (_, record) => {
                                    return moment(_).format("yyyy-MM-DD HH:mm:ss")
                                }
                            }, {
                                title: '操作',
                                dataIndex: 'name',
                                key: 'name',
                                render: (_, record) => (
                                    <Space size="middle">
                                        <a onClick={() => {
                                            setDeployParam({
                                                sslCode: record.code,
                                                visible: true
                                            })
                                        }}>部署</a>
                                        <a onClick={() => {
                                            setFormParam({
                                                id: record.id,
                                                visible: true
                                            })
                                        }}>修改</a>
                                        <Popconfirm
                                            title="你确认删除此数据么?"
                                            onConfirm={() => {
                                                post('/api/app/my/meta/data/delete', {
                                                    metaCode: metaCode,
                                                    id: record.id,
                                                }).then(res => {
                                                    setParam({ ...param });
                                                    message.info("删除成功");
                                                })
                                            }}
                                            okText="确认"
                                            cancelText="取消" >
                                            <a href="#">删除</a>
                                        </Popconfirm>

                                    </Space>
                                )
                            }
                        ]} />
                    < FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />

                    <DeployModal param={deployParam} setParam={setDeployParam} reload={() => { setParam({ ...param }) }} />
                </>
            }

        </div >
    );
}
addPage("/my/ssl", Page);

export default Page;