import React, { useEffect, useState } from 'react'
import { Button, Input, Space, Drawer, Form, Col, Row, message } from 'antd';
import SearchSelect from 'component/SearchSelect';
import { post } from 'util/http';
import module from 'util/module';

const GroupForm = ({
    groupId,
    drawerId,
    setParam,
    setDrawerId,
    formatData = (data) => {
        const newData = { ...data, owners: [], masters: [], members: [], guests: [] };
        data.accounts.map(item => {
            if (item.role == 'owner') {
                newData.owners.push({
                    value: item.accountCode,
                    label: item.accountName
                })
            } else if (item.role == 'master') {
                newData.masters.push({
                    value: item.accountCode,
                    label: item.accountName
                })
            } else if (item.role == 'member') {
                newData.members.push({
                    value: item.accountCode,
                    label: item.accountName
                })
            } else if (item.role == 'guest') {
                newData.guests.push({
                    value: item.accountCode,
                    label: item.accountName
                })
            }
        });
        return newData;
    }
}) => {

    const [group, setGroup] = useState({
        items: []
    });

    const [groupForm] = Form.useForm();


    useEffect(() => {
        if ('groupForm' == drawerId) {
            groupForm.resetFields();
        }
        if (groupId != null && drawerId == 'groupForm') {
            post(module.ac + '/group/v1.0/get', { id: groupId })
                .then(res => {
                    setGroup(res.data);
                    groupForm.setFieldsValue(formatData(res.data));
                })
        }
    }, [groupId, drawerId])

    return (<Drawer title="用户组配置"
        placement="right"
        width={660}
        maskClosable={false}
        onClose={() => { setDrawerId({}) }}
        visible={drawerId == 'groupForm'}
        extra={
            <Space>
                <Button onClick={() => { setDrawerId({}) }}>取消</Button>
                <Button type="primary" onClick={() => {
                    const param = groupForm.getFieldsValue();
                    const newParam = { ...param, accounts: [] };
                    param.owners && param.owners.map(item => {
                        newParam.accounts.push({
                            accountCode: item.value,
                            accountName: item.label,
                            role: 'owner'
                        })
                    })
                    param.masters && param.masters.map(item => {
                        newParam.accounts.push({
                            accountCode: item.value,
                            accountName: item.label,
                            role: 'master'
                        })
                    })
                    param.members && param.members.map(item => {
                        newParam.accounts.push({
                            accountCode: item.value,
                            accountName: item.label,
                            role: 'member'
                        })
                    })
                    param.guests && param.guests.map(item => {
                        newParam.accounts.push({
                            accountCode: item.value,
                            accountName: item.label,
                            role: 'guest'
                        })
                    })
                    const url = newParam.id ? '/group/v1.0/update' : '/group/v1.0/create';
                    post(module.ac + url, newParam)
                        .then(res => {
                            setParam({});
                            setDrawerId({})
                            message.info("保存成功");
                        })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={groupForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                items: []
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item style={{ display: 'none' }}
                        name="id">
                        <Input type="hidden"
                        />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"
                        />
                    </Form.Item>

                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="remark"
                        label="remark"
                    >
                        <Input
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>

            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="owners"
                        label="负责人"
                    >
                        <SearchSelect url={module.ac + "/account/v1.0/search"} labelCode="name" valueCode="code" mode="multiple" labelInValue={true} />
                    </Form.Item>
                </Col>
                <Col span={24}>

                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="masters"
                        label="管理员"
                    >
                        <SearchSelect url={module.ac + "/account/v1.0/search"} labelCode="name" valueCode="code" mode="multiple" labelInValue={true} />
                    </Form.Item>
                </Col>
                <Col span={24}>

                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="members"
                        label="用户"
                    >
                        <SearchSelect url={module.ac + "/account/v1.0/search"} labelCode="name" valueCode="code" mode="multiple" labelInValue={true} />
                    </Form.Item>
                </Col>
                <Col span={24}>

                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="guests"
                        label="关注人"
                    >
                        <SearchSelect url={module.ac + "/account/v1.0/search"} labelCode="name" valueCode="code" mode="multiple" labelInValue={true} />
                    </Form.Item>
                </Col>
                <Col span={24}>

                </Col>
            </Row>
        </Form>
    </Drawer>)
}

export default GroupForm;