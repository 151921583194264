import ImageUpload from './ImageUpload';


const Page = ({
    maxCount = 5,
    ...nextProps
}) => {
    return (
        <ImageUpload {...nextProps} maxCount={maxCount}>
        </ImageUpload>)
}

export default Page;