import React, { useEffect, useState } from 'react'
import { Button, Modal, Space, Popconfirm, Row, Col, message, Empty } from 'antd';
import { post } from 'util/http';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import moment from 'moment'
import pay from 'util/pay';
const Page = () => {

    const [account, setAccount] = useState(null);

    useEffect(() => {
        post('/api/ac/my/account/info', {})
            .then(res => {
                setAccount(res.data)
            })
    }, [])

    const buyVipAccount = (productCode, description) => {
        post("/api/ec/public/order/create", {
            description: description,
            orderProducts: [{
                productCode: productCode,
                number: 1
            }]
        }).then(res => {
            const orderCode = res.data.code;
            pay.showWeixinPay(orderCode);
        })
    }

    return (
        <div>

            <Row style={{ padding: 10 }} >
                <Col flex="300px" className='template-info'>
                    {account != null && account.roleCodes.indexOf("VIP_ACCOUNT") == -1 && < Empty
                        style={{ paddingTop: 100, fontSize: 23 }}
                        image={require('../../../asserts/images/vip.png')}
                        imageStyle={{ height: 200 }}
                        description="未开通或已失效"
                    >
                    </Empty>
                    }
                    {account != null && account.roleCodes.indexOf("VIP_ACCOUNT") > -1 && < Empty
                        style={{ paddingTop: 100, fontSize: 23 }}
                        image={require('../../../asserts/images/vip.png')}
                        imageStyle={{ height: 200 }}
                        description={"有效期至" + account.attributes.vipEndTime}
                    >
                    </Empty>
                    }
                </Col>
                <Col flex="1" >
                    <Row gutter={16}>
                        <Col span={8}>
                            < Empty
                                style={{ paddingTop: 100, fontSize: 23 }}
                                image={require('../../../asserts/images/vip-month.png')}
                                imageStyle={{ height: 100 }}
                                description={"30元/月"}
                            >
                                <Button type="primary" onClick={() => {
                                    buyVipAccount("vip-month", "包月Vip购买")
                                }}>购买月Vip</Button>
                            </Empty>
                        </Col>
                        <Col span={8}>
                            < Empty
                                style={{ paddingTop: 100, fontSize: 23 }}
                                image={require('../../../asserts/images/vip-quarter.png')}
                                imageStyle={{ height: 100 }}
                                description={"88元/季"}
                            >
                                <Button type="primary" onClick={() => {
                                    buyVipAccount("vip-quarter", "包季Vip购买")
                                }}>购买季Vip</Button>
                            </Empty>
                        </Col>
                        <Col span={8}>
                            < Empty
                                style={{ paddingTop: 100, fontSize: 23 }}
                                image={require('../../../asserts/images/vip-year.png')}
                                imageStyle={{ height: 100 }}
                                description={"288元/年"}
                            >
                                <Button type="primary" onClick={() => {
                                    buyVipAccount("vip-year", "包年Vip购买")
                                }}>购买年Vip</Button>
                            </Empty>
                        </Col>
                    </Row>
                </Col>

            </Row>


        </div >
    );
}
addPage("/crm/vip/my", Page);

export default Page;