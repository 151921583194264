import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Select,
    Space,
    Dropdown
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem';
import SearchSelect from 'component/SearchSelect.js';
import SearchTree from 'component/SearchTree';
import DictSelect from 'component/DictSelect';
import cloudUtil from './cloud-util';

const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "vpsServer"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });

    const [accountParam, setAccountParam] = useState({
        metaCode: "cmdbAccount"
    })
    const [regionParam, setRegionParam] = useState({
        metaCode: "cmdbRegion"
    })
    const [zoneParam, setZoneParam] = useState({
        metaCode: "cmdbZone"
    })
    const [packagesParam, setPackagesParam] = useState({
        metaCode: "hcsZonePackages"
    })
    const [imageParam, setImageParam] = useState({
        metaCode: "cmdbImage"
    })


    useEffect(() => {
        // post(module.app + '/meta/v1.0/get-by-code', { code: metaCode })
        //     .then(res => {
        //         setMeta(res.data);
        //     })
    }, [])
    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post(module.app + '/meta/v1.0/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                pageForm.resetFields();
            }
        }


    }, [param])

    return (<Drawer title="创建项目"
        placement="right"
        width={1160}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button onClick={() => {
                    setParam({ ...param, visible: false })
                }}>取消</Button>
                <Button type="primary" onClick={() => {
                    pageForm.validateFields().then((data) => {
                        const cloudApi = cloudUtil.getCloudApi(data.cloudCode);
                        if (cloudApi != null) {
                            const url = '/api/hcs/server/v1.0/create';
                            post(url, cloudApi.createParam(data)).then(res => {
                                setParam({ ...param, visible: false });
                                reload();
                                message.info("创建成功");
                            })
                        } else {
                            message.warn(data.cloudCode + "未对接");
                        }
                    })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 120 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>

            <Form.Item name="cloudCode" label="云厂商">
                <SearchSelect
                    url="/api/app/public/meta/data/search"
                    param={{
                        "metaCode": "cmdbCloud"
                    }}
                    placeholder="云厂商"
                    onChange={(v) => {
                        setRegionParam({
                            ...regionParam,
                            params: [{
                                attrCode: 'cloudCode',
                                operator: "=",
                                value: v
                            }]
                        })
                        setAccountParam({
                            ...accountParam,
                            params: [{
                                attrCode: 'cloudCode',
                                operator: "=",
                                value: v
                            }]
                        })

                    }}
                >
                </SearchSelect>
            </Form.Item>

            <Form.Item name="chargeType" label="计费模式">
                <DictSelect
                    dictCode="SERVER_CHARGE_TYPE"
                    placeholder="计费模式"
                >
                </DictSelect>
            </Form.Item>



            <Form.Item name="accountCode" label="云账号">
                <SearchSelect
                    url="/api/app/public/meta/data/search"
                    param={accountParam}
                    placeholder="云账号"
                >
                </SearchSelect>
            </Form.Item>

            <Form.Item name="regionCode" label="地区" >
                <SearchSelect
                    url="/api/app/public/meta/data/search"
                    param={regionParam}
                    placeholder="地区"
                    onChange={(v) => {
                        setZoneParam({
                            ...zoneParam,
                            params: [{
                                attrCode: 'regionCode',
                                operator: "=",
                                value: v
                            }]
                        })
                    }}
                >
                </SearchSelect>
            </Form.Item>

            <Form.Item name="zoneCode" label="可用区" >
                <SearchSelect
                    url="/api/app/public/meta/data/search"
                    param={zoneParam}
                    placeholder="可用区"
                    onChange={(v) => {
                        setPackagesParam({
                            ...packagesParam,
                            params: [{
                                attrCode: 'zoneCode',
                                operator: "=",
                                value: v
                            }]
                        })
                    }}
                >
                </SearchSelect>
            </Form.Item>

            <Form.Item name="packagesCode" label="套餐" >
                <SearchSelect
                    url="/api/app/public/meta/data/search"
                    param={packagesParam}
                    placeholder="套餐"
                    labelCode="packagesCode"
                    valueCode="packagesCode"
                >
                </SearchSelect>
            </Form.Item>
            <Form.Item name="imageCode" label="镜像" >
                <SearchSelect
                    url="/api/app/public/meta/data/search"
                    param={imageParam}
                    placeholder="镜像"
                >
                </SearchSelect>
            </Form.Item>
        </Form>
    </Drawer>)
}

export default Page;