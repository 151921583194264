const Clipboard = {
    copy: function (text) {
        var transfer = document.createElement('textarea');
        document.body.appendChild(transfer);
        transfer.value = text;
        transfer.focus();
        transfer.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
        }
        transfer.blur();
        document.body.removeChild(transfer);
    }
}
export default Clipboard;