import React, { useState, useEffect } from 'react'
import { Button, Form, Input, message, Modal, Space, Col, Row, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import { post } from 'util/http';
import module from 'util/module';
import { getMetaLabel } from 'util/meta';


import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import DictSelect from "component/DictSelect";
import { EyeOutlined, EditOutlined, AimOutlined } from '@ant-design/icons';



const Page = () => {

    const navigate = useNavigate();

    const metaCode = "cmsTemplate";
    const [param, setParam] = useState({
        searchName: ""
    });
    const [applicationParam, setApplicationParam] = useState({
        visible: false
    });

    const [pageData, setPageData] = useState({
        size: 1,
        records: []
    });

    const loadData = (newParam) => {
        post("/api/cms/public/template/search", newParam).then(res => {
            setPageData(res.data);
        })
    }

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = {};
        if (datas.searchName != null) {
            newParam.searchName = "%" + datas.searchName + "%";
        }
        loadData(newParam)
    }

    useEffect(() => {
        loadData(param);
    }, []);


    const [searchForm] = Form.useForm();

    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }}>
                <Form.Item name="searchName" >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="关键词"
                    />
                </Form.Item>
                <Space>
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>

                </Space>
            </Form>

            <div className="market" style={{ paddingBottom: 80 }}>
                <Row gutter={[16, 16]}>
                    {pageData.records.map((item) => (
                        <Col span={6} className="item" key={item.id}>
                            <div className="item-img">
                                <img src={item.img} />
                                <div className="bg">
                                    <Button onClick={() => {

                                        window.open(window.APP_CONFIG.webDomain + "/view.html?url=/" + item.indexUrl + "&code=" + item.code)
                                    }}><AimOutlined />访问</Button>
                                    <Button style={{ marginTop: 20 }}
                                        onClick={() => {
                                            post('/api/cms/public/template/download', {
                                                code: item.code
                                            }).then(res => {
                                                if (res.status == 0) {
                                                    window.open(res.data);
                                                } else if (res.status == -1) {
                                                    Modal.confirm({
                                                        title: '提醒',
                                                        content: "未开通Vip服务，是否前往开通？",
                                                        okText: "确认",
                                                        onOk: function () {
                                                            navigate("/crm/vip/my")
                                                        }
                                                    });

                                                }

                                            })
                                        }}
                                    ><EditOutlined /> 下载</Button>
                                </div>
                            </div>
                            <div className="title">
                                <div className="count">
                                    <EyeOutlined /> 100
                                </div>
                                <div className='main'>{item.name}</div>
                                <div className='sub'>{item.remark}</div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <Pagination
                    style={{ position: 'absolute', bottom: 10, right: 0 }}
                    pageSize={pageData.size}
                    total={pageData.total}
                    showTotal={(total) => `总共 ${total} 条数据1`}
                    onChange={(current, size) => {
                        loadData({ ...param, current: current, size: size });
                    }}
                />
            </div>

        </div>
    );
}

addPage("/cms/template/market", Page);

export default Page;