import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, Select, message, Col, Row, Modal } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import AttrDrawer from './attr.js';
import PermissionDrawer from './permission';
import Clipboard from 'util/clipboard.js';
import DataDrawer from './data';
import { addPage } from 'component/DynamicRotes.js';
const { TextArea } = Input;
const Meta = () => {

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();
    const [pasteForm] = Form.useForm();

    const [param, setParam] = useState({ searchName: "" });
    const [formParam, setFormParam] = useState({
    });
    const [syncParam, setSyncParam] = useState({
    });

    const [permissionParam, setPermissionParam] = useState({});

    const [attrParam, setAttrParam] = useState({});

    const [dataParam, setDataParam] = useState({});


    const copyMeta = (meta) => {
        post(module.app + '/meta/v1.0/get-by-code', {
            code: meta.code
        }).then(res => {
            Clipboard.copy(JSON.stringify(res.data));
            message.success("复制成功");
        })
    }

    const pasteMeta = () => {
        Modal.confirm({
            title: '黏贴元数据',
            width: 900,
            content: <Form form={pasteForm} layout="horizontal" labelCol={{
                style: { width: 80 }
            }}>
                <Form.Item
                    name="dataSourceCode"
                    label="数据源"
                    rules={[{ required: true, message: '请选择数据源' }]}
                >
                    <SearchSelect url={module.app + "/data_source/v1.0/search"} />
                </Form.Item>
                <Form.Item name="metaJson" label="配置" rules={[{ required: true, message: '请填写配置' }]}>
                    <TextArea style={{ height: 200, width: "100%" }} />
                </Form.Item>
            </Form>,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                var metaJson = JSON.parse(pasteForm.getFieldValue("metaJson"));
                metaJson.dataSourceCode = pasteForm.getFieldValue("dataSourceCode");
                return post(module.app + '/meta/v1.0/save', metaJson).then(res => {
                    message.success("黏贴成功");
                    setParam({ ...param });
                })
            }
        });
    }

    return (
        <div>

            <Modal title="元数据配置"
                width={980}
                visible={formParam.visible}
                onOk={() => {
                    pageForm.validateFields().then((data) => {
                        const url = data.id ? module.app + '/meta/v1.0/update' : module.app + '/meta/v1.0/create';
                        post(url, data).then(res => {
                            message.info("创建成功");
                            setParam({ ...param });
                            setFormParam({ ...formParam, visible: false });
                        })
                    })
                }} onCancel={() => {
                    setFormParam({ ...param, visible: false })
                }}>
                <Form form={pageForm} layout="horizontal" hideRequiredMark
                    labelCol={{
                        style: { width: 80 }
                    }}
                    initialValues={{
                        attrs: []
                    }}
                >
                    <Form.Item style={{ display: 'none' }}
                        name="id">
                        <Input type="hidden" />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item style={{ display: 'none' }}
                                name="id">
                                <Input type="hidden"
                                />
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label="名称"
                                rules={[{ required: true, message: '请输入名称' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入名称"
                                />
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="code"
                                label="code"
                                rules={[{ required: true, message: '请输入code' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入code"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="tableCode"
                                label="表代码"
                                rules={[{ required: true, message: '请输入表代码' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入表代码"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="dataSourceCode"
                                label="数据源"
                                rules={[{ required: true, message: '请选择数据源' }]}
                            >
                                <SearchSelect url={module.app + "/data_source/v1.0/search"} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="viewAuthScope" label="查看权限" >
                                <DictSelect dictCode="META_AUTHORITY">
                                </DictSelect >
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="editAuthScope" label="编辑权限">
                                <DictSelect dictCode="META_AUTHORITY">
                                </DictSelect >
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal title="元数据同步"
                visible={syncParam.visible}
                onOk={() => {
                    pageForm.validateFields().then((data) => {
                        post(module.app + '/meta/v1.0/sync', data)
                            .then(res => {
                                setSyncParam({ ...syncParam, visible: false });
                                message.info("同步成功");
                            })
                    })
                }} onCancel={() => {
                    setSyncParam({ ...param, visible: false })
                }}>
                <Form form={pageForm} layout="horizontal" hideRequiredMark
                    labelCol={{
                        style: { width: 80 }
                    }}
                    initialValues={{
                        attrs: []
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="dataSourceCode"
                                label="数据源"
                                rules={[{ required: true, message: '请输入code' }]}
                            >
                                <SearchSelect url={module.app + "/data_source/v1.0/search"} placeholder="请输入code" />

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="tableCode"
                                label="表代码"
                                rules={[{ required: true, message: '请输入名称' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入名称"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        setParam(searchForm.getFieldsValue())
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({ ...formParam, visible: true });
                        pageForm.resetFields();
                    }}>创建</Button>
                    <Button type="primary" onClick={() => {
                        setSyncParam({ ...syncParam, visible: true });
                        pageForm.resetFields();
                    }}>同步</Button>
                    <Button type="primary" onClick={() => {
                        pasteMeta()
                    }}>黏贴</Button>
                </Space>


            </Form>

            <SearchTable url={module.app + "/meta/v1.0/search"}
                param={param}
                columns={[
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    }, {
                        title: 'code',
                        dataIndex: 'code',
                        key: 'code',
                    }, {
                        title: '表名',
                        dataIndex: 'tableCode',
                        key: 'tableCode',
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">
                                <a onClick={() => {
                                    copyMeta(record)
                                }}>复制</a>
                                <a onClick={() => {
                                    setFormParam({ ...formParam, visible: true })
                                    post(module.app + '/meta/v1.0/get', {
                                        id: record.id
                                    }).then(res => {
                                        pageForm.setFieldsValue(res.data);
                                    })
                                }}>修改</a>
                                <a onClick={() => {
                                    setPermissionParam({ visible: true, metaId: record.id })
                                }}>权限</a>
                                <a onClick={() => {
                                    setAttrParam({
                                        metaCode: record.code,
                                        visible: true
                                    })
                                }}>字段</a>
                                <a onClick={() => {
                                    setDataParam({
                                        metaCode: record.code,
                                        visible: true
                                    })
                                }}>数据</a>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={() => {
                                        post(module.app + '/meta/v1.0/delete', { id: record.id })
                                            .then(res => {
                                                setParam({ ...param });
                                                message.info("删除成功");
                                            })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />

            <AttrDrawer param={attrParam} setParam={setAttrParam} />
            <PermissionDrawer param={permissionParam} setParam={setPermissionParam} />
            <DataDrawer param={dataParam} setParam={setDataParam} />
        </div >
    );
}

addPage("/app/meta/manager", Meta);

export default Meta;