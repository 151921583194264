import React, { useState, useEffect } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, PageHeader, List, Row, Col, Tag } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import TreeTable from 'component/TreeTable'
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import DictSelect from 'component/DictSelect'
import FormDrawer from './form.js';
import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';
import { getMetaLabel } from 'util/meta';
import SyncTree from 'component/SyncTree.js';
import CodeMirrorBox from 'component/CodeMirrorBox.js';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


const Page = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const metaCode = "devopsJobInstance";
    const [param, setParam] = useState({
        size: 7,
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "name",
        params: [{
            attrCode: "jobCode",
            operator: "=",
            value: query.get("jobCode")
        }],
        orders: [{
            attrCode: "createTime",
            order: "desc"
        }]
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });
    const [job, setJob] = useState({});
    const [jobModel, setJobModel] = useState("")
    // const [jobInstance, setJobInstance] = useState({});
    const [jobInstanceLog, setJobInstanceLog] = useState("");
    const [viewMode, setViewMode] = useState("edit");
    const runJob = () => {
        post('/api/devops/job/v1.0/run', {
            code: query.get("jobCode")
        }).then(res => {
            setParam({ ...param });
            getJobIntance(res.data.jobInstanceCode)
        })
    }

    const getJobIntance = (instanceCode) => {
        setViewMode("log")
        post('/api/devops/job/v1.0/instance/get', {
            instanceCode: instanceCode
        }).then(res => {
            setParam({ ...param });
            let nwJobInstanceLog = "";
            res.data.instanceStages.map(item => {
                for (var i in item.instanceTasks) {
                    item.instanceTasks[i].instanceSteps.map(step => {
                        nwJobInstanceLog = nwJobInstanceLog + step.stdout + "\n" + step.stderr + "\n";
                    })
                }
            })
            setJobInstanceLog(nwJobInstanceLog);
            if (res.data.status == 'start') {
                setTimeout(function () {
                    getJobIntance(instanceCode);
                }, 2000)
            }
        })
    }
    const saveJob = () => {
        setJobModel(job.jobModel)
        const url = '/api/app/meta/v1.0/data/save';
        post(url, {
            metaCode: "devopsJob",
            datas: {
                id: job.id,
                jobModel: job.jobModel
            }
        }).then(res => {
            message.info("保存成功");
        })
    }
    useEffect(() => {
        post('/api/app/meta/v1.0/data/get-by-code', {
            code: query.get("jobCode"),
            metaCode: "devopsJob"
        }).then(res => {
            setJob(res.data);
            setJobModel(res.data.jobModel);
        })

    }, [query.get("jobCode")])

    return (
        <PageHeader className="site-page-header"
            onBack={() => {
                navigate(-1);
            }}
            title={job.name}
            subTitle="模块作业详情">

            <Row gutter={16} wrap={false}>
                <Col flex="300px" style={{ width: 300 }}>
                    <List bordered >
                        <List.Item> <a onClick={() => {
                            setViewMode("edit");
                        }}>配置</a> </List.Item>
                        <List.Item> <a onClick={runJob}>立即构建</a> </List.Item>
                        <List.Item> <a href="#">删除</a> </List.Item>
                    </List>
                    <SearchTable url={"/api/app/meta/v1.0/data/search"}
                        className="job-log-table"
                        param={param}
                        simplePagination={true}
                        onRow={record => {
                            return {
                                onClick: event => {
                                    getJobIntance(record.code);
                                },
                            };
                        }}
                        columns={[
                            {
                                title: '历史构建',
                                dataIndex: 'buildNumber',
                                key: 'buildNumber',
                                render: (_, record) => {
                                    if (_ != null && _ != '') {
                                        return <Space>
                                            {record.status == 'success' && <Tag color={"success"}>成功</Tag>}
                                            {record.status == 'error' && <Tag color={"error"}>失败</Tag>}
                                            {record.status == 'start' && <Tag >runing</Tag>}
                                            <a href="#">#{_}</a>
                                            <span style={{ fontSize: 12, marginLeft: 20 }}>{moment(record.createTime).format("yyyy-MM-DD HH:mm:ss")}</span>
                                        </Space>
                                    }
                                }
                            }
                        ]}
                    />
                </Col>
                <Col flex="auto">
                    {viewMode == 'edit' && <div >

                        <CodeMirrorBox defaultValue={jobModel}

                        > </CodeMirrorBox>
                        <Button type="primary" style={{ marginTop: 10, float: "right" }} onClick={saveJob}>保存</Button>
                    </div>}

                    {viewMode == 'log' && <>
                        <CodeMirrorBox defaultValue={jobInstanceLog} readOnly={true}
                        > </CodeMirrorBox>
                    </>}

                </Col>
            </Row>

            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
        </PageHeader >
    );
}
addPage("/devops/job/detail", Page);

export default Page;