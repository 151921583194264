import React, { useEffect, useState } from 'react'
import { Button, Input, Space, Drawer, Form, Col, Row, Select, message } from 'antd';
import EditTable from 'component/EditTable';
import SearchSelect from 'component/SearchSelect';
import { post } from 'util/http';
import module from 'util/module';
const { Option } = Select;

const DictForm = ({
    dictId,
    drawerId,
    setParam,
    setDrawerId
}) => {

    const [dict, setDict] = useState({
        items: []
    });

    const [dictForm] = Form.useForm();


    useEffect(() => {
        if ('dictForm' == drawerId) {
            dictForm.resetFields();
        }
        if (dictId != null && drawerId == 'dictForm') {
            post(module.app + '/dict/v1.0/get', { id: dictId })
                .then(res => {
                    setDict(res.data);
                    dictForm.setFieldsValue({
                        ...res.data
                    });
                })
        }
    }, [dictId, drawerId])

    return (<Drawer title="创建元数据"
        placement="right"
        width={1224}
        maskClosable={false}
        onClose={() => { setDrawerId({}) }}
        visible={drawerId == 'dictForm'}
        extra={
            <Space>
                <Button onClick={() => { setDrawerId({}) }}>取消</Button>
                <Button type="primary" onClick={() => {
                    const param = dictForm.getFieldsValue();
                    const url = dictId ? module.app + '/dict/v1.0/update' : module.app + '/dict/v1.0/create';
                    post(url, param, {
                        loading: true
                    }).then(res => {
                        setParam({});
                        setDrawerId({})
                        message.info("保存成功");
                    })
                }}>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={dictForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 60 }
            }}
            initialValues={{
                items: []
            }}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item style={{ display: 'none' }}
                        name="id">
                        <Input type="hidden"
                        />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"
                        />
                    </Form.Item>

                </Col>
                <Col span={12}>
                    <Form.Item
                        name="code"
                        label="code"
                        rules={[{ required: true, message: '请输入code' }]}
                    >
                        <Input
                            disabled={dictId != null}
                            style={{ width: '100%' }}
                            placeholder="请输入code"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="showOrder"
                        label="排序"

                        rules={[{ required: true, message: '请输入排序' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            type="number"
                            placeholder="请输入排序"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="remark"
                        label="备注"
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入排序"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>

                <Col span={24} >
                    <Form.Item
                        name="items"
                        label="属性"
                    >
                        <EditTable
                            columns={[{
                                title: '标题',
                                dataIndex: 'itemLabel',
                                key: 'itemLabel',
                                editable: true,
                                ellipsis: true,
                                editRules: [{
                                    required: true,
                                    message: '标题不能为空',
                                }]
                            },
                            {
                                title: '字典值',
                                dataIndex: 'itemValue',
                                key: 'itemValue',
                                editable: true,
                                ellipsis: true,
                                editRules: [{
                                    required: true,
                                    message: '属字典值不能为空',
                                }]
                            },
                            {
                                title: '排序',
                                dataIndex: 'showOrder',
                                key: 'showOrder',
                                editable: true,
                                ellipsis: true,
                                editType: <Input type="number" />,
                                editRules: [{
                                    required: true,
                                    message: '属排序不能为空',
                                }]
                            },
                            {
                                title: '备注',
                                dataIndex: 'remark',
                                key: 'remark',
                                editable: true,
                                ellipsis: true

                            }]}
                        />
                    </Form.Item>

                </Col>
            </Row>
        </Form>
    </Drawer>)
}

export default DictForm;