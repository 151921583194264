import React, { useState } from 'react'
import { Button, Input, Space, Form } from 'antd';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import { getMetaLabel } from 'util/meta';
import moment from 'moment'
import pay from 'util/pay';


const Page = () => {

    const metaCode = "ecOrder";
    const [param, setParam] = useState({
        metaCode: metaCode,
        orders: [{
            attrCode: "createTime",
            order: "desc"
        }]
    });
    const [searchForm] = Form.useForm();

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'code',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }
    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >


                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入订单编号"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                </Space>

            </Form>

            <SearchTable url={"/api/app/my/meta/data/search"}
                param={param}
                columns={[
                    {
                        title: '订单编号',
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: '订单描述',
                        dataIndex: 'description',
                        key: 'description',
                    },
                    {
                        title: '会员',
                        dataIndex: 'memberName',
                        key: 'memberName',
                    },
                    {
                        title: '订单金额',
                        dataIndex: 'totalAmount',
                        key: 'totalAmount',
                        render: (_, record) => {
                            return (_ / 100) + "元"
                        }
                    },
                    {
                        title: '实际金额',
                        dataIndex: 'realAmount',
                        key: 'realAmount',
                        render: (_, record) => {
                            return (_ / 100) + "元"
                        }
                    },
                    {
                        title: '订单状态',
                        dataIndex: 'status',
                        key: 'status',
                        render: (_, record) => {
                            return getMetaLabel('EC_ORDER_STATUS', _);
                        }
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD HH:mm:ss")
                        }
                    }, {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => {
                            if (record.status == 'create' || record.status == 'paying') {
                                return (
                                    <Space size="middle">
                                        <a onClick={() => {
                                            pay.showWeixinPay(record.code, function () {
                                                reload();
                                            });
                                        }}>继续支付</a>
                                    </Space>
                                )
                            }
                        }
                    }
                ]} />
        </div >
    );
}
addPage("/ec/order/my", Page);

export default Page;