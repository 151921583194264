import hex_md5 from 'util/md5';
const Xpassword = {
    encryptByDES: function (message, key) {
        var keyHex = window.CryptoJS.enc.Utf8.parse(key);
        var encrypted = window.CryptoJS.DES.encrypt(message, keyHex, {
            mode: window.CryptoJS.mode.ECB,
            padding: window.CryptoJS.pad.Pkcs7
        });
        return encrypted.ciphertext.toString();
    },
    decryptByDES: function (ciphertext, key) {
        var keyHex = window.CryptoJS.enc.Utf8.parse(key);
        var decrypted = window.CryptoJS.DES.decrypt({
            ciphertext: window.CryptoJS.enc.Hex.parse(ciphertext)
        }, keyHex, {
            mode: window.CryptoJS.mode.ECB,
            padding: window.CryptoJS.pad.Pkcs7
        });
        var result_value = decrypted.toString(window.CryptoJS.enc.Utf8);
        return result_value;
    },
    copy: function (text) {
        var transfer = document.createElement('textarea');
        document.body.appendChild(transfer);
        transfer.value = text;
        transfer.focus();
        transfer.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
        }
        transfer.blur();
        document.body.removeChild(transfer);
    },
    getFirtLetter: function (pwd) {
        for (var i = 0; i < pwd.length; i++) {
            if (isNaN(pwd[i])) {
                return pwd[i];
            }
        }
        return "a";
    },
    subNumberPwd: function (pwd, length) {
        if (pwd.length >= length) {
            return pwd.substring(0, length);
        } else {
            pwd = pwd + pwd + pwd + "1357924680";
            return pwd.substring(0, length);
        }
    },
    getNumberPwd: function (secretKey, salt) {
        var result = "";
        var pwd = hex_md5(secretKey + salt);
        for (var i = 0; i < pwd.length; i++) {
            if (!isNaN(pwd[i])) {
                result = result + pwd[i];
            }
        }
        return result;
    },
    //简单数字（8位）
    simpleNumber: function (secretKey, salt) {
        var pwd = Xpassword.getNumberPwd(secretKey, salt);
        return Xpassword.subNumberPwd(pwd, 8);
    },
    //字母&数字（10位）
    letterNumber: function (secretKey, salt) {
        var pwd = hex_md5(secretKey + salt);
        return pwd.substring(0, 10);
    },
    //大小写字母&数字（12位）
    upperLetterNumber: function (secretKey, salt) {
        var pwd = hex_md5(secretKey + salt);
        return Xpassword.getFirtLetter(pwd).toUpperCase() + pwd.substring(0, 11);
    },
    //特殊字符&字母&数字（14位）
    complexLetterNumber: function (secretKey, salt) {
        var pwd = hex_md5(secretKey + salt);
        return Xpassword.getFirtLetter(pwd).toUpperCase() + pwd.substring(0, 12) + "!";
    }
}
export default Xpassword;