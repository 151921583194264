import React, { useCallback, useEffect, useState } from 'react'
import { Tree } from 'antd';
import { post } from 'util/http';

const AsyncTree = ({
    url,
    value,
    param,
    titleCode = "name",
    valueCode = "id",
    isLeafCode = "isLeaf",
    onChange,
    ...nextProps
}) => {
    const [treeData, setTreeData] = useState([]);

    const updateTreeData = (list, key, children) =>
        list.map((node) => {
            if (node.key === key) {
                return { ...node, children };
            }

            if (node.children) {
                return { ...node, children: updateTreeData(node.children, key, children) };
            }

            return node;
        });

    const formatData = useCallback((data) => {
        var newData = {
            title: data[titleCode],
            key: data[valueCode],
            isLeaf: data[isLeafCode]
        };
        return newData;
    }, [])

    const onLoadData = ({ key, children }) =>
        new Promise((resolve) => {
            if (children) {
                resolve();
                return;
            }
            post(url, { path: key })
                .then(res => {
                    if (Array.isArray(res.data)) {
                        const newTreeData = res.data.map((item) => {
                            return formatData(item);
                        });
                        setTreeData((origin) =>
                            updateTreeData(origin, key, newTreeData),
                        );
                    } else {
                        const newTreeData = formatData(res.data);
                        setTreeData((origin) =>
                            updateTreeData(origin, key, newTreeData),
                        );
                    }
                    resolve();
                })
        });

    useEffect(() => {
        post(url, param || {})
            .then(res => {
                if (Array.isArray(res.data)) {
                    const newTreeData = res.data.map((item) => {
                        return formatData(item);
                    });
                    setTreeData(newTreeData);
                } else {
                    const newTreeData = formatData(res.data);
                    setTreeData([newTreeData]);
                }
            })
    }, [param])

    return (<Tree treeData={treeData}  {...nextProps} loadData={onLoadData} />)
}

export default AsyncTree;