import React, { useState, useEffect } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Switch } from 'antd';
import { post } from 'util/http';
import { addPage } from 'component/DynamicRotes.js';

import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';


import { getMetaLabel } from 'util/meta';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "nginxSsl";
    const [param, setParam] = useState({
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "title"
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });

    const [deployParam, setDeployParam] = useState({ metaCode: metaCode });

    const [searchForm] = Form.useForm();

    useEffect(() => {
        searchForm.setFieldsValue({
            stockCode: "BABA",
            number: 100,
            profitRate: 2,
            lostRate: 1

        })
    }, [])

    const unlock = () => {
        const url = '/api/stock/trade/unlock';
        post(url, {}).then(res => {
            message.info("解锁成功");
        })
    }

    const listPosition = () => {
        const url = '/api/stock/trade/position/list';
        post(url, {}).then(res => {
            message.info("持仓查询成功");
        })
    }
    const buyRise = () => {
        searchForm.validateFields().then((data) => {
            const url = '/api/stock/trade/buy/rise';
            post(url, data).then(res => {
                message.info("下单成功");
            })
        })
    }

    const buyFall = () => {
        searchForm.validateFields().then((data) => {
            const url = '/api/stock/trade/buy/fall';
            post(url, data).then(res => {
                message.info("下单成功");
            })
        })
    }

    const clearance = () => {
        const url = '/api/stock/trade/clearance';
        post(url, {}).then(res => {
            message.info("清仓成功");
        })
    }

    return (
        <div>
            <Form form={searchForm}
                layout="horizontal"
                labelCol={{
                    style: { width: 120 }
                }}
                style={{ padding: 15, width: 500 }} >

                <Form.Item
                    name="stockCode"
                    label="股票代码"
                    rules={[{ required: true, message: '请输入股票代码' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入股票代码"
                    />
                </Form.Item>
                <Form.Item
                    name="price"
                    label="价格"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入价格"
                    />
                </Form.Item>
                <Form.Item
                    name="number"
                    label="数量"
                    rules={[{ required: true, message: '请输入数量' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入数量"
                    />
                </Form.Item>
                <Form.Item
                    name="profitRate"
                    label="止盈比例"
                    rules={[{ required: true, message: '请输入止盈比例' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入止盈比例"
                    />
                </Form.Item>
                <Form.Item
                    name="lostRate"
                    label="止损比例"
                    rules={[{ required: true, message: '请输入止损比例' }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入止损比例"
                    />
                </Form.Item>

                <div className='flex-row-center' style={{ marginTop: 30 }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            buyRise();
                        }}>买涨</Button>
                    <Button type="primary"
                        onClick={() => {
                            buyFall()
                        }}>买跌</Button>
                    <Button type="danger" onClick={() => {
                        clearance();
                    }}>
                        清仓
                    </Button>
                </div>


                <div className='flex-row-center' style={{ marginTop: 30 }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            unlock();
                        }}>解锁</Button>
                    <Button type="primary"
                        onClick={() => {
                            listPosition()
                        }}>持仓查询</Button>

                </div>
            </Form>

        </div >
    );
}
addPage("/stock/trade/manager", Page);

export default Page;