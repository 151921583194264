import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Row, Col, Modal, Dropdown, Menu } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SearchTree from 'component/SearchTree.js';
import TreeTable from 'component/TreeTable'
import SyncTree from 'component/SyncTree'
import DictSelect from 'component/DictSelect'
import ServiceTree from '../service';
import FormDrawer from './form.js';
import moment from 'moment'
import { getMetaLabel } from 'util/meta';
import {
    SettingOutlined,
} from '@ant-design/icons';
const { Search } = Input;
const Page = () => {

    const metaCode = "hcsServer";
    const [param, setParam] = useState({
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "title"
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });

    const [serviceParam, setServiceParam] = useState({
        metaCode: 'devopsService',
        showRoot: false,
    });

    const [expandedKeys, setExpandedKeys] = useState(["0", "1"]);

    const [permissionParam, setPermissionParam] = useState({});
    const [searchForm] = Form.useForm();

    const destroyServer = (record) => {
        post('/api/hcs/server/v1.0/destroy', {
            id: record.id,
        }).then(res => {
            setParam({ ...param });
            message.info("下线成功");
        })
    }

    const startServer = (record) => {
        post('/api/hcs/server/v1.0/start', {
            id: record.id,
        }).then(res => {
            setParam({ ...param });
            message.info("启动成功");
        })
    }
    const stopServer = (record) => {
        post('/api/hcs/server/v1.0/stop', {
            id: record.id,
        }).then(res => {
            setParam({ ...param });
            message.info("关机成功");
        })
    }
    const restartServer = (record) => {
        post('/api/hcs/server/v1.0/restart', {
            id: record.id,
        }).then(res => {
            setParam({ ...param });
            message.info("重启成功");
        })
    }
    const deleteServer = (record) => {
        post(module.app + '/meta/v1.0/data/delete', {
            metaCode: metaCode,
            id: record.id,
        }).then(res => {
            setParam({ ...param });
            message.info("删除成功");
        })
    }
    const confirm = (text, fn, record) => {
        Modal.confirm({
            title: '操作提示',
            content: '确认' + text,
            okText: '确认',
            cancelText: '取消',
            onOk: function () {
                fn(record);
                Modal.destroyAll();
            }
        });
    }

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.fullChannelCode != null) {
            newParam.push({
                attrCode: 'fullChannelCode',
                operator: "like",
                value: datas.fullChannelCode + "%"
            })
        }
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'title',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        if (datas.status != null) {
            newParam.push({
                attrCode: 'status',
                operator: "=",
                value: datas.status
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }
    return (
        <div style={{ padding: 10 }}>

            <Row gutter={16} >
                <Col style={{ width: 280 }}>
                    <ServiceTree></ServiceTree>
                </Col>
                <Col flex={"1"}>
                    <Form form={searchForm} layout="inline"  >

                        <Form.Item
                            name="status"
                        >
                            <DictSelect
                                dictCode={"CMS_STATUS"}
                                style={{ width: 100 }}
                                placeholder="状态"
                            />
                        </Form.Item>

                        <Form.Item
                            name="searchName"
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="请输入名称"
                            />
                        </Form.Item>
                        <Space >
                            <Button onClick={() => {
                                reload();
                            }}>搜索</Button>
                            <Button type="primary" onClick={() => {
                                setFormParam({
                                    id: '',
                                    visible: true
                                })
                            }}>创建</Button>

                        </Space>

                    </Form>
                    <SearchTable url={module.app + "/meta/v1.0/data/search"}
                        style={{ marginTop: 20 }}
                        keyCode="code"
                        param={param}
                        columns={[
                            {
                                title: '云厂商',
                                dataIndex: 'cloudCode',
                                key: 'cloudCode'
                            },
                            {
                                title: '可用区',
                                dataIndex: 'zone',
                                key: 'zone',
                            },
                            {
                                title: '主机SN',
                                dataIndex: 'sn',
                                key: 'sn',
                            },
                            {
                                title: '套餐',
                                dataIndex: 'serverPackage',
                                key: 'serverPackage',
                            },
                            {
                                title: 'Cpu',
                                dataIndex: 'cpu',
                                key: 'cpu',
                            },
                            {
                                title: '内存',
                                dataIndex: 'memory',
                                key: 'memory',
                            },
                            {
                                title: '硬盘',
                                dataIndex: 'disk',
                                key: 'disk',
                            },
                            {
                                title: '操作系统',
                                dataIndex: 'os',
                                key: 'os'
                            },
                            {
                                title: '状态',
                                dataIndex: 'serverState',
                                key: 'serverState'
                            }, {
                                title: '创建时间',
                                dataIndex: 'createTime',
                                key: 'createTime',
                                render: (_, record) => {
                                    return moment(_).format("yyyy-MM-DD HH:mm:ss")
                                }
                            }, {
                                title: '操作',
                                dataIndex: 'name',
                                key: 'name',
                                render: (_, record) => (
                                    <Dropdown
                                        overlay={<Menu style={{ width: 100 }}
                                        >
                                            <Menu.Item onClick={() => {
                                                setPermissionParam({ visible: true, code: record.code })
                                            }}>权限</Menu.Item>
                                            <Menu.SubMenu title="配置" key="config">
                                                <Menu.Item>调整</Menu.Item>
                                            </Menu.SubMenu>
                                            <Menu.SubMenu title="状态" key="status">
                                                <Menu.Item onClick={() => { confirm("开机", startServer, record) }}>开机</Menu.Item>
                                                <Menu.Item onClick={() => { confirm("关机", stopServer, record) }}>关机</Menu.Item>
                                                <Menu.Item onClick={() => { confirm("重启", restartServer, record) }}>重启</Menu.Item>
                                                <Menu.Item>重装</Menu.Item>
                                                <Menu.Item onClick={() => { confirm("下线", destroyServer, record) }}>下线</Menu.Item>
                                            </Menu.SubMenu>
                                            <Menu.SubMenu title="密码">
                                                <Menu.Item>查看密码</Menu.Item>
                                                <Menu.Item>重置密码</Menu.Item>
                                            </Menu.SubMenu>
                                            <Menu.Item onClick={() => { confirm("删除", deleteServer, record) }}>删除</Menu.Item>
                                        </Menu>}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>
                                            更多
                                        </a>
                                    </Dropdown>
                                )
                            }
                        ]} />
                </Col>
            </Row>


            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />
        </div >
    );
}
addPage("/sre/hcs/manager", Page);

export default Page;