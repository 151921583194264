import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import { post } from 'util/http';
import module from 'util/module';
import FileUpload from 'component/FileUpload';
import moment from 'moment'
const { TextArea } = Input;


const Page = ({
    param,
    setParam,
}) => {


    return (<Drawer title="用量列表"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
    >

        <SearchTable url={"/api/app/meta/v1.0/data/search"}
            param={param}
            columns={[
                {
                    title: '模型',
                    dataIndex: 'modelName',
                    key: 'modelName',
                },
                {
                    title: '日期',
                    dataIndex: 'usageDate',
                    key: 'usageDate',
                    render: (_, record) => {
                        return moment(_).format("yyyy-MM-DD")
                    }
                }, {
                    title: '使用量',
                    dataIndex: 'value',
                    key: 'value'
                }
            ]} />
    </Drawer>)
}

export default Page;