import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Image } from 'antd';
import { post } from 'util/http';
import module from 'util/module';

import { useNavigate } from 'react-router-dom';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import ChatDrawer from 'component/ChatDrawer'
import FormDrawer from './form.js';
import moment from 'moment'



import { getMetaLabel } from 'util/meta';
const Page = () => {

    const navigate = useNavigate();

    const metaCode = "appSmsBusiness";
    const [param, setParam] = useState({
        params: [],
        metaCode: metaCode,
        orders: [{
            attrCode: "showOrder",
            order: "desc"
        }, {
            attrCode: "createTime",
            order: "desc"
        }]
    });
    const [formParam, setFormParam] = useState({ metaCode: metaCode });

    const [searchForm] = Form.useForm();
    const [chatParam, setChatParam] = useState({
        metaCode: "aiChatContent",
        visible: false
    })

    const reload = () => {
        var datas = searchForm.getFieldsValue();
        const newParam = [];
        if (datas.searchName != null) {
            newParam.push({
                attrCode: 'name',
                operator: "like",
                value: "%" + datas.searchName + "%"
            })
        }
        setParam({
            ...param,
            params: newParam
        })
    }



    return (
        <div>
            <Form form={searchForm} layout="inline" style={{ padding: 15 }} >

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={() => {
                        reload();
                    }}>搜索</Button>
                    <Button type="primary" onClick={() => {
                        setFormParam({
                            id: '',
                            visible: true
                        })
                    }}>创建</Button>
                </Space>


            </Form>

            <SearchTable url={"/api/app/meta/v1.0/data/search"}
                param={param}
                columns={[
                    {
                        title: '业务',
                        dataIndex: 'business',
                        key: 'business',
                    },
                    {
                        title: '模板id',
                        dataIndex: 'templateId',
                        key: 'templateId',
                    },
                    {
                        title: '签名',
                        dataIndex: 'signName',
                        key: 'signName',
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        render: (_, record) => {
                            return moment(_).format("yyyy-MM-DD")
                        }
                    },
                    {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: (_, record) => (
                            <Space size="middle">

                                <a onClick={() => {
                                    setFormParam({
                                        id: record.id,
                                        visible: true
                                    })
                                }}>修改</a>
                                <Popconfirm
                                    title="你确认删除?"
                                    onConfirm={() => {
                                        post('/api/ailawyer/robot/delete', {
                                            code: record.code
                                        }).then(res => {
                                            setParam({ ...param });
                                            message.info("删除成功");
                                        })
                                    }}
                                    okText="确认"
                                    cancelText="取消" >
                                    <a href="#">删除</a>
                                </Popconfirm>

                            </Space>
                        )
                    }
                ]} />
            <FormDrawer param={formParam} setParam={setFormParam} reload={() => { setParam({ ...param }) }} />

            <ChatDrawer setParam={setChatParam} param={chatParam} />
        </div >
    );
}
addPage("/app/sms/manager", Page);
export default Page;