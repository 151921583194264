import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    message,
    Row,
    Modal,
    Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import MetaFormItem from 'component/MetaFormItem';
import DictSelect from 'component/DictSelect'
import Xpassword from 'util/xpassword';
import SearchTree from 'component/SearchTree';
const { confirm } = Modal;
const Page = ({
    param,
    setParam,
    reload
}) => {


    const metaCode = "itmarkSecPassword"

    const [pageForm] = Form.useForm();
    var typeName = null;

    const [meta, setMeta] = useState({
        attrs: []
    });



    useEffect(() => {
        if (param.visible) {
            if (param.id != '') {
                post(module.app + '/my/meta/data/get', {
                    id: param.id,
                    metaCode: metaCode
                }).then(res => {
                    pageForm.resetFields();
                    pageForm.setFieldsValue({
                        ...res.data
                    });
                })
            } else {
                pageForm.resetFields();
            }
        }

    }, [param])

    return (<Drawer title="创建密码"
        placement="right"
        width={660}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
        extra={
            <Space>
                <Button type="primary" onClick={() => {
                    pageForm.validateFields().then((data) => {
                        const pwd = Xpassword[data.signMethod](data.secretKey, data.salt);
                        Xpassword.copy(pwd);
                        confirm({
                            title: "查看密码(已复制）",
                            content: pwd
                        })
                    })
                }}>
                    查看密码
                </Button>
            </Space>
        }
    >
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Form.Item style={{ display: 'none' }}
                name="id">
                <Input type="hidden" />
            </Form.Item>

            <Form.Item name='name' label="名称" >
                <Input disabled />
            </Form.Item>

            <Form.Item name='accountCode' label="账号" >
                <Input disabled />
            </Form.Item>

            <Form.Item name='signMethod' label="加密算法" >
                <DictSelect disabled dictCode={"PASSWORD_SIGN_METHOD"} />
            </Form.Item>

            <Form.Item name='salt' label="加密盐" >
                <Input disabled />
            </Form.Item>

            <Form.Item name='secretKey' label="秘钥"
                rules={[{ required: true, message: '请输入秘钥' }]}>
                <Input type='password' />
            </Form.Item>

        </Form>

    </Drawer>)
}

export default Page;