import React, { useState, useEffect } from 'react'
import { Button, Form, Input, message, Popconfirm, Space, Menu, Layout, Tree, Row, Col } from 'antd';
import { post, get } from 'util/http';
import module from 'util/module';
import { getMetaLabel } from 'util/meta';
import SearchTable from 'component/SearchTable.js';
import { addPage } from 'component/DynamicRotes.js';
import SyncTree from 'component/SyncTree';
import SearchSelect from 'component/SearchSelect';
const { Header, Content, Sider } = Layout;
const Page = () => {

  const [treeData, setTreeData] = useState([]);

  const [param, setParam] = useState({ searchName: "", size: 10, current: 1 });
  const [moduleParam, setModuleParam] = useState({ applicationCode: "" })
  const [apiParam, setApiParam] = useState({ applicationCode: "", moduleCode: "", showRoot: false, location: "/" });
  const [searchForm] = Form.useForm();
  const [apiForm] = Form.useForm();


  return (
    <div>


      <Form form={searchForm} layout="inline" >
        <Form.Item
          name="applicationCode"
        >
          <SearchSelect url={module.cloud + "/application/v1.0/search"} style={{ width: 120 }} placeholder="请输入项目" onChange={(v) => {
            setApiParam({ ...apiParam, applicationCode: v, moduleCode: "" });
            searchForm.setFieldsValue({ moduleCode: "" })
          }}
          />
        </Form.Item>
        <Form.Item
          name="moduleCode"
        >
          <SearchSelect url={module.cloud + "/application/v1.0/module/search"} style={{ width: 200 }} placeholder="请输入模块" param={apiParam} labelCode="moduleName" valueCode="moduleCode" onChange={(v, _) => {
            setApiParam({ ...apiParam, moduleCode: v, location: _.data.location });
          }} />
        </Form.Item>

        <Space>

          <Button type="primary" onClick={() => {
            var data = searchForm.getFieldsValue();
            get(apiParam.location + '/v3/api-docs', {}, { ignoreParse: true })
              .then(res => {
                data.swaggerDoc = JSON.stringify(res.data);
                post(module.cloud + '/api/v1.0/sync/swagger', data)
                  .then(res => {
                    message.info("同步成功");
                    setApiParam({ ...apiParam });
                  })
              })
          }}
            disabled={apiParam.applicationCode == '' || apiParam.moduleCode == ''}
          >swagger同步</Button>

        </Space>

      </Form>
      <Row style={{ paddingTop: 15 }}>
        <Col flex="250px" style={{ width: 250 }}>
          <SyncTree url={module.cloud + "/api/v1.0/tree"} param={apiParam} onSelect={(v, e) => {
            apiForm.setFieldsValue({
              abc: e.node.title
            });
          }} />
        </Col>
        <Col flex="auto">
          <Form form={apiForm}  >
            <Form.Item
              name="abc"
            >
              <Input />
            </Form.Item>
          </Form>
        </Col>
      </Row>



    </div>
  );
}

addPage("/cloud/api/manager", Page);

export default Page;