import React, { useState, useEffect } from 'react';
import { LoginContext } from 'util/context';

import { addRoute } from 'component/DynamicRotes';
import { Layout } from 'antd';
import { post } from 'util/http';
import module from 'util/module';

import AdminHead from './admin-head';
import AdminBody from './admin-body';



import './admin.css'

import './home/index'
import './public/index'
import './my/index';

import './cos/index'
import './devops/index';
import './cms/index'
import './ac/index';
import './app/index';
import './cloud/index'
import './dev/index'
import './k8s/index';
import './nginx/index';
import './vps/index';
import './crm/index'
import './ec/export'

import './cron/index';

import './idc/index'
import './cmdb/index'

import './stock/index'

import './sre/index'

import './bms/index'
import './trip/index'

import './recruit/index'

import './xcloud/index'

import './xspace/index'

import './weixin/index'


import './auth/password-reset'
import './tool/secpassword/index'

import './ai/export'

import './pcdn/export'

function Page() {


    return (
        <Layout className="xcloud-wrapper">
            <AdminHead></AdminHead>
            <AdminBody></AdminBody>
        </Layout >
    );
}
addRoute("*", Page);
export default Page;
