import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message, Row, Col, Modal, Dropdown, Menu } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SyncTree from 'component/SyncTree'
import SearchTree from 'component/SearchTree';
import PermissionDrawer from './permission';
import {
    SettingOutlined,
} from '@ant-design/icons';
import TreeSelect from 'rc-tree-select';
const { Search } = Input;
const Page = () => {

    const metaCode = "sreService";

    const [pageForm] = Form.useForm();
    const [formParam, setFormParam] = useState({
        visible: false,
        clusterId: ""
    });

    const [serviceParam, setServiceParam] = useState({
        metaCode: metaCode,
        showRoot: false,
    });
    const [permissionParam, setPermissionParam] = useState({});

    const [expandedKeys, setExpandedKeys] = useState(["0", "1"]);

    const [selectKeys, setSelectKeys] = useState([]);

    const updateService = (code) => {
        setFormParam({ visible: true })
        pageForm.resetFields();
        post('/api/app/meta/v1.0/data/get-by-code', {
            code: code,
            metaCode: metaCode
        }).then(res => {
            pageForm.setFieldsValue(res.data);
        })
    }
    const deleteService = (code) => {
        post('/api/app/meta/v1.0/data/delete-by-code', {
            code: code,
            metaCode: metaCode
        }).then(res => {
            setServiceParam({ ...serviceParam })
            setSelectKeys([])
        })
    }

    return (
        <div >
            <Search />
            <div style={{ float: 'right', marginTop: 15 }} >
                <Dropdown
                    overlay={<Menu style={{ width: 100 }}
                    >
                        <Menu.Item onClick={() => {
                            setFormParam({ visible: true })
                            pageForm.resetFields();
                        }}>新增</Menu.Item>
                        <Menu.Item onClick={() => {
                            if (selectKeys.length == 0) {
                                message.warn("请选择服务节点");
                            } else {
                                updateService(selectKeys[0]);

                            }
                        }}>修改</Menu.Item>
                        <Menu.Item onClick={() => {
                            if (selectKeys.length == 0) {
                                message.warn("请选择服务节点");
                            } else {
                                setPermissionParam({ visible: true, code: selectKeys[0] })
                            }
                        }}>权限</Menu.Item>
                        <Menu.Item onClick={() => {
                            if (selectKeys.length == 0) {
                                message.warn("请选择服务节点");
                            } else {
                                deleteService(selectKeys[0]);

                            }
                        }}>删除</Menu.Item>
                    </Menu>}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <SettingOutlined />
                    </a>
                </Dropdown>

            </div>
            <SyncTree
                //url={module.app + "/meta/v1.0/data/tree"}
                url={"/api/sre/service/v1.0/auth/tree"}
                style={{ marginTop: 20, overflow: 'auto', maxHeight: 600 }}
                valueCode="code"
                onSelect={(_, e) => {
                    setSelectKeys(_);
                }}
                onExpand={setExpandedKeys}
                param={serviceParam}
                expandedKeys={expandedKeys}
                titleRender={(nodeData) => {
                    return nodeData.title
                }}
            ></SyncTree>

            <Modal width={560} title="服务管理" visible={formParam.visible} onOk={() => {
                const data = pageForm.getFieldsValue();
                const url = '/api/app/meta/v1.0/data/save';
                post(url, {
                    metaCode: metaCode,
                    datas: data
                }).then(res => {
                    message.info("操作成功");
                    setFormParam({ ...formParam, visible: false });
                })

            }} onCancel={() => { setFormParam({ ...formParam, visible: false }) }}>

                <Form form={pageForm} layout="horizontal" hideRequiredMark
                    labelCol={{
                        style: { width: 80 }
                    }}
                    initialValues={{
                        items: []
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item style={{ display: 'none' }}
                                name="id">
                                <Input type="hidden"
                                />
                            </Form.Item>
                            <Form.Item
                                name="parentCode"
                                label="所属服务"
                                rules={[{ required: true, message: '请选择所属服务' }]}
                            >
                                <SearchTree
                                    url={module.app + "/meta/v1.0/data/tree"}
                                    param={{ metaCode: metaCode, showRoot: true }}
                                    placeholder="所属服务"
                                    valueCode="code"
                                    showSearch={true}
                                    labelCode="name"
                                />
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label="名称"
                                rules={[{ required: true, message: '请输入名称' }]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>
                    </Row>

                </Form>

            </Modal>
            <PermissionDrawer param={permissionParam} setParam={setPermissionParam} />
        </div >
    );
}

export default Page;