import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';
import SearchSelect from 'component/SearchSelect';
import { post } from 'util/http';
import module from 'util/module';
const { TextArea } = Input;


const Page = ({
    param,
    setParam
}) => {

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();

    return (<Drawer title="模块"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
    >

        <Modal
            width={860}
            title="添加模块1" visible={param.modalVisible} onOk={() => {
                const data = pageForm.getFieldsValue();
                data.applicationCode = param.applicationCode;
                const url = data.id ? module.cloud + '/application/v1.0/module/update' : module.cloud + '/application/v1.0/module/create';
                post(url, data)
                    .then(res => {
                        setParam({ ...param })
                        message.info("创建成功");
                        setParam({ ...param, modalVisible: false })
                    })

            }} onCancel={() => { setParam({ ...param, modalVisible: false }) }}>
            <Form form={pageForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 80 }
                }}
                initialValues={{
                    attrs: []
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item style={{ display: 'none' }}
                            name="id">
                            <Input type="hidden"
                            />
                        </Form.Item>

                        <Form.Item
                            name="moduleCode"
                            label="模块"
                            rules={[{ required: true, message: '请选择模块' }]}
                        >
                            <SearchSelect url={module.cloud + "/cluster/v1.0/module/search"} labelCode="moduleName" valueCode="moduleCode" param={{
                                "clusterCode": param.clusterCode
                            }}
                                showSearch={true}
                            />
                        </Form.Item>

                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="location"
                            label="路由"
                        >
                            <Input
                                style={{ width: '100%' }}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="moduleConfig"
                            label="模块配置"
                        >
                            <TextArea
                                style={{ width: '100%', height: 200 }}

                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

        <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
            <Form.Item
                name="searchName"
            >
                <Input
                    style={{ width: '100%' }}
                    placeholder="请输入名称"
                />
            </Form.Item>
            <Space >
                <Button onClick={() => {
                    setParam({ ...param, ...searchForm.getFieldsValue() })
                }}>搜索</Button>
                <Button type="primary" onClick={() => {
                    pageForm.resetFields();
                    setParam({ ...param, modalVisible: true })
                }}>添加模块</Button>
            </Space>
        </Form>

        <SearchTable url={module.cloud + "/application/v1.0/module/search"}
            param={param}
            columns={[
                {
                    title: '模块名称',
                    dataIndex: 'moduleName',
                    key: 'moduleName',
                },
                {
                    title: '模块代码',
                    dataIndex: 'moduleCode',
                    key: 'moduleCode',
                },
                {
                    title: '版本',
                    dataIndex: 'moduleVersion',
                    key: 'moduleVersion',
                }, {
                    title: '操作',
                    dataIndex: 'name',
                    key: 'name',
                    render: (_, record) => (
                        <Space size="middle">

                            <a onClick={() => {
                                window.open("http://" + param.domain + record.location + "/swagger-ui/index.html#/");

                            }}>Api</a>
                            <a onClick={() => {

                                post(module.cloud + '/application/v1.0/module/get',
                                    { id: record.id })
                                    .then(res => {
                                        setParam({ ...param, modalVisible: true })
                                        pageForm.setFieldsValue(res.data);
                                    })

                            }}>修改</a>


                            <Popconfirm
                                title="你确认删除此数据么?"
                                onConfirm={() => {
                                    post(module.cloud + '/application/v1.0/module/delete',
                                        { id: record.id })
                                        .then(res => {
                                            setParam({ ...param })
                                            message.info("删除成功");
                                        })
                                }}
                                okText="确认"
                                cancelText="取消">
                                <a href="#">删除</a>
                            </Popconfirm>



                        </Space>
                    )
                }
            ]} />
    </Drawer >)
}

export default Page;