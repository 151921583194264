import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    message,
    Input,
    Modal,
    Space,
    Col, Row, Popconfirm
} from 'antd';
import SearchTable from 'component/SearchTable.js';

import SearchSelect from 'component/SearchSelect';
import DictSelect from 'component/DictSelect';
import { post } from 'util/http';
import module from 'util/module';
const { TextArea } = Input;


const Page = ({
    param,
    setParam
}) => {

    const [searchForm] = Form.useForm();
    const [pageForm] = Form.useForm();
    const [protocol, setProtocol] = useState("http");

    return (<Drawer title="域名"
        placement="right"
        width={960}
        maskClosable={false}
        onClose={() => {
            setParam({ ...param, visible: false })
        }}
        visible={param.visible}
    >

        <Modal width={960} title="添加域名" visible={param.modalVisible}
            onOk={() => {
                pageForm.validateFields().then((data) => {
                    data.applicationCode = param.applicationCode;
                    const url = data.id ? module.cloud + '/my/application/domain/update'
                        : module.cloud + '/my/application/domain/create';;
                    post(url, data)
                        .then(res => {
                            message.info("创建成功");
                            setParam({ ...param, modalVisible: false })
                        })
                })
            }}
            onCancel={() => {
                setParam({ ...param, modalVisible: false })
            }}>

            <Form form={pageForm} layout="horizontal" hideRequiredMark
                labelCol={{
                    style: { width: 80 }
                }}
                initialValues={{
                    items: []
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item style={{ display: 'none' }}
                            name="id">
                            <Input type="hidden"
                            />
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }}
                            name="applicationCode">
                            <Input type="hidden"
                            />
                        </Form.Item>

                        <Form.Item
                            name="domain"
                            label="域名"
                            rules={[{ required: true, message: '请输入域名' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="protocol"
                            label="协议"
                            rules={[{ required: true, message: '请输入协议' }]}
                        >
                            <DictSelect dictCode="HTTP_PROTOCOL" onChange={setProtocol} />
                        </Form.Item>
                        {protocol == 'https' && <Form.Item
                            name="sslCode"
                            label="ssl证书"
                            rules={[{ required: true, message: '请输入ssl证书' }]}
                        >
                            <SearchSelect url="/api/app/my/meta/data/search"
                                rules={[{ required: true, message: '请选择ssl证书' }]}
                                param={{
                                    "metaCode": "nginxSsl"
                                }} />
                        </Form.Item>}
                        <Form.Item
                            name="proxyConfig"
                            label="代理配置"
                        >
                            <TextArea
                                style={{ width: '100%', height: 200 }}
                                type="number"
                            />
                        </Form.Item>

                    </Col>
                </Row>

            </Form>


        </Modal>

        <Form form={searchForm} layout="inline" style={{ padding: 15 }} >
            <Form.Item
                name="searchName"
            >
                <Input
                    style={{ width: '100%' }}
                    placeholder="请输入名称"
                />
            </Form.Item>
            <Space >
                <Button onClick={() => {
                    setParam({ ...param, ...searchForm.getFieldsValue() })
                }}>搜索</Button>
                <Button type="primary" onClick={() => {
                    pageForm.resetFields();
                    setParam({ ...param, modalVisible: true })
                }}>添加域名</Button>
            </Space>
        </Form>

        <SearchTable url={module.cloud + "/my/application/domain/search"}
            param={param}
            columns={[
                {
                    title: '域名',
                    dataIndex: 'domain',
                    key: 'domain',
                    render: (_) => {
                        return (<a onClick={() => {
                            window.open("http://" + _);
                        }}>{_}</a>);
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'name',
                    key: 'name',
                    render: (_, record) => (
                        <Space size="middle">
                            <a onClick={() => {
                                setParam({ ...param, modalVisible: true })
                                pageForm.setFieldsValue(record);
                            }}>修改</a>

                            <Popconfirm
                                title="你确认删除此数据么?"
                                onConfirm={() => {
                                    post(module.cloud + '/my/application/domain/delete', { id: record.id })
                                        .then(res => {
                                            setParam({ ...param });
                                            message.info("删除成功");
                                        })
                                }}
                                okText="确认"
                                cancelText="取消" >
                                <a href="#">删除</a>
                            </Popconfirm>
                        </Space>
                    )
                }
            ]} />
    </Drawer>)
}

export default Page;